import React, { Component } from 'react';
import { translate } from "react-multi-lang";
import { Link } from 'react-router'
class EmployeerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basePath: window.location.protocol + '//' + window.location.host + '/'
        }
    }

    componentDidMount() {
        document.title = this.props.t('menu.services', { param: 'react' })
    }

    render() {
        console.log(this.state.basePath)
        return (
            <div>
                <section className="top-inner main-content-height animatedParent">
                    <div className="container" >
                        <div className="first-click1 mb-4 mt-4">
                            <div className={"row"}>


                                <div className={"col-md-12 col-sm-12 col-lg-12  pl-4 pr-4 jobseeker-service"}>
                                    <h5 className={"mb-0"}>{this.props.t('employerPage.about.services', { param: 'react' })}</h5>
                                    <hr />
                                    <h5 className=" text-bold">{this.props.t('employerPage.about.ser_title', { param: 'react' })} </h5>
                                    <ul className={"pl-3 pt-2 "} >
                                        <li>{this.props.t('employerPage.about.ser1', { param: 'react' })}</li>
                                        <li>{this.props.t('employerPage.about.ser2', { param: 'react' })}</li>
                                    </ul>
                                    <ol style={{ "listStyleType": "lower-alpha" }} className="pl-4 pt-2 ">
                                        <li>
                                            <div>
                                                <h5 className="mb-0 pt-2">
                                                    Job Helpline
                                        </h5>
                                                <ul className="pl-3 pt-2 ">
                                                    <li>Every District Bureau of Employment and Enterprise has a dedicated helpline number for the jobseekers and employers to call and get assistance without making an effort to visit the Bureau physically.</li>
                                                    <li>The interested applicants may call and get assistance on all working days from 9 AM to 5 PM.</li>
                                                    <li>To see the helpline number of your district, <Link to="/employment">http://pgrkam.com/employment</Link>.</li>

                                                </ul>
                                            </div>


                                        </li>
                                        <li>
                                            <div>
                                                <h6 className="mb-0 pt-2">
                                                    Provide Coaching/Training for Jobs for Government and Private Sector including training in Soft Skills, Communication Skills
                                        </h6>
                                                <ul className="pl-3 pt-2">
                                                    <li>
                                                        Various training providers have been empaneled in the districts to provide Coaching/Training for Jobs for Government and Private Sector including training in Soft Skills, Communication Skills. The courses are of 100-hours duration each followed by placement facilitation.
                                            </li>
                                                    <li>
                                                        Interested candidates may approach the nearest District Bureau of Employment & Enterprise for more information & enrolment.
                                            </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <h6 className="mb-0 pt-2">
                                                    Job Portal
                                        </h6>
                                                <ul className="pl-3 pt-2">
                                                    <li>
                                                        Job Portal has been launched with url <a rel="noopener noreferrer" target="_blank" href="http://pgrkam.com/">http://pgrkam.com/</a>.
                                            </li>
                                                    <li>
                                                        Jobseekers and employers can register themselves free of cost and use the portal for its services including placement facilitation.
                                            </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <h6 className="mb-0 pt-2">
                                                    Social Media
                                        </h6>
                                                <ul className="pl-3 pt-2">
                                                    <li>
                                                        PGRKAM will reach out to the public through social media platforms like facebook, youtube, twitter, Instagram, blogs etc shortly.
                                            </li>

                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <h6 className="mb-0 pt-2">
                                                    Apni Gaddi Apna Rozgar Scheme
                                        </h6>
                                                <ul className="pl-3 pt-2">
                                                    <li>
                                                        The objective of the APNI GADDI APNA ROZGAR  Scheme is to provide self-employment/ entrepreneurial avenues to the youth of economically weaker section of Punjab in the Transport Sector. The scheme intends to create jobs by way of taxis, light commercial vehicles by engaging prominent private players. The goal is to empower the youth from weaker section by creating micro-entrepreneurs and to make commercial driving a more organised profession with increased societal respect for the commercial drivers under the scheme.
                                            </li>
                                                    <li>
                                                        The scheme is being launched initially as PILOT in Amritsar, Ludhiana, Patiala and Ropar cluster including SAS Nagar & Fatehgarh Sahib by calling applications from these districts/cluster. The scheme later will be extended to other districts depending on the success of the PILOT & demand of taxis in other districts.
                                            </li>
                                                    <li>
                                                        Interested candidates of above districts may contact the District Bureau of Employment & Enterprise for further details.
                                            </li>
                                                </ul>
                                            </div>
                                        </li>

                                    </ol>
                                    <h5> DBEEs (District Bureaus of Employment & Enterprise) </h5>
                                    <p>
                                        High Class infrastructure by the name of DBEE created in each district as a One-Stop shop for providing assistance to youth on issues related to employment.
                                        Tasks being done in DBEE.

                                    </p>
                                    <ul className="pl-3 pt-2">
                                        <li>Free internet facility to youth for job/ career related assistance.</li>
                                        <li>Registration and creation of database of assistance seekers including jobseekers and employers. </li>
                                        <li>Counselling – Vocational and Career counselling. </li>
                                        <li>Outreach activities like school/ college talks, career conferences, career exhibitions etc.</li>
                                        <li>Skill Training – through empaneled Training Providers of PGRKAM and District PSDM Training Providers.</li>
                                        <li>Soft Skill Training for Pvt Jobs & Coaching for Govt Jobs.</li>
                                        <li>Placement Camps/ Rozgar Melas @ two per month.</li>
                                        <li>Enterprise development facilitation.</li>
                                        <li>Guidance by Line departments to youth (Industries, PSDM, Guidance Counselling, Agriculture/ Dairy, Labor, Lead Bank). </li>
                                        <li>Foreign Employment & Foreign Study facilitation.</li>
                                        <li>Exclusive Notice boards in DBEE’s for Govt/ Pvt vacancies.</li>
                                        <li>Monthly collection of information w.r.t youth employed/ self-employed in the District.</li>
                                        <li>Regular registrations.</li>
                                        <li>
                                            Identification, skilling, placements, entrepreneurship development of all jobseekers with particular focus on 10 unemployed/ village and those identified in MGSVY.
                                            <p><b>Interested applicants may contact and seek benefit from the District Bureau of Employment & Enterprise. Each district has one DBEE to provide the services. Timing of DBEE is from 9 am to 5 pm on all working days. On all public holidays of Punjab Government, DBEEs remain closed.</b></p>
                                            Every District Bureau of Employment and Enterprise has a dedicated helpline number for the jobseekers and employers to call and get assistance without making an effort to visit the Bureau physically.
                                                The interested applicants may call and get assistance on all working days from 9 AM to 5 PM. To see the helpline number of your district,
                                            <Link to="/employment">http://pgrkam.com/employment</Link>.
                                        </li>
                                    </ul>
                                    <h6>Employment Generation & Training Department (EGT):</h6>
                                            For information please go to <a target="_blank" rel="noopener noreferrer" href="http://www.pbemployment.gov.in/">www.pbemployment.gov.in</a>.
                                            <h6>Maharaja Ranjit Singh Armed Forces Preparatory Institute: </h6>
                                            For information please go to <a target="_blank" rel="noopener noreferrer" href="http://afpipunjab.org/">http://afpipunjab.org/</a>.
                                            <h6>Mai Bhago Armed Forces Preparatory Institute:</h6>
                                            For information please go to <a target="_blank" rel="noopener noreferrer" href="http://www.mbafpigirls.in/">http://www.mbafpigirls.in/</a>.




                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default translate(EmployeerPage);
