import React, { Component } from 'react';
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { chunk } from '../../../helpers'
import Pagination from "react-js-pagination";
import {
    getFeedback
} from '../../../actions/lspAction'
import { Link } from 'react-router'
import StarRatings from 'react-star-ratings'

const RESULT_PER_PAGE = 25

class ServiceFeedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            activePageRemarks: 1,
            remarks: [],
            rating: [],
            open: false
        }
        store.dispatch(getFeedback());
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    handlePageRemarks = (activePage) => {
        this.setState({ activePageRemarks: activePage })
    }

    componentDidMount() {
        document.title = this.props.t('lsp_user.feedback', { param: 'react' })
    }

    render() {
        let Mainprops = this.props;

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        let searchResults = []
        if (Mainprops.LspReducer.feedbackList && Mainprops.LspReducer.feedbackList.length) {
            searchResults = chunk(Mainprops.LspReducer.feedbackList, RESULT_PER_PAGE)
        }

        const { activePage, open, remarks, activePageRemarks, rating } = this.state
        let remarksList = chunk(remarks, RESULT_PER_PAGE)
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div>
                                <div id="home" className=" tab-pane active">
                                    <section className="job-details mt-4">
                                        <div className="container">
                                            <div className="row">

                                                <div className="col-md-12  mx-auto  tab-content  p-0">

                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('lsp_user.feedback', { param: 'react' })}</h6>

                                                    </div>
                                                    <div className="panel-body">
                                                        {open && remarksList && remarksList.length ? remarksList[activePageRemarks - 1].map((r, key) => {
                                                            return (<div className="card mb-2" key={key}>
                                                                <div className="card-body">
                                                                    <h6>&ldquo; {r} &rdquo;</h6>
                                                                    {parseInt(rating[key]) ?
                                                                        <StarRatings
                                                                            rating={parseInt(rating[key])}
                                                                            numberOfStars={5}
                                                                            name='rating'
                                                                            starDimension="20px"
                                                                            starRatedColor="rgb(255, 215, 0)"
                                                                            starHoverColor="rgb(255, 215, 0)"
                                                                        /> : null}
                                                                </div>


                                                            </div>)
                                                        }) : null}
                                                        {remarksList && remarksList.length > 1 ?

                                                            <div className='react-pagination'>
                                                                <Pagination
                                                                    pageRangeDisplayed={10}
                                                                    activePage={activePageRemarks}
                                                                    itemsCountPerPage={RESULT_PER_PAGE}
                                                                    totalItemsCount={remarks.length}
                                                                    onChange={this.handlePageRemarks}
                                                                />
                                                            </div> : ''}
                                                        <>

                                                            {open && <button
                                                                onClick={() => this.setState({ open: false, remarks: [], activePageRemarks: 1, rating: [] })}
                                                                className="btn btn-primary btn-primary2 d-block mx-auto">{this.props.t('lsp_user.goBack', { param: 'react' })}</button>}
                                                        </>
                                                        {!open ? <>
                                                            {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((jobs, key) => {
                                                                return (<div key={key}>
                                                                    <h5>{this.props.t('lsp_user.service_name', { param: 'react' })}: {jobs.service}</h5>
                                                                    <p className="date-clr" style={{ cursor: "pointer" }} onClick={() => this.setState({ remarks: jobs.remarks, rating: jobs.rating, open: true })}>
                                                                        {this.props.t('lsp_user.comments', { param: 'react' })}</p>
                                                                    <hr></hr>
                                                                </div>)
                                                            })}

                                                            {searchResults && searchResults.length > 1 ?
                                                                <div className='react-pagination'>
                                                                    <Pagination
                                                                        pageRangeDisplayed={10}
                                                                        activePage={activePage}
                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                        totalItemsCount={Mainprops.LspReducer.feedbackList.length}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}
                                                        </> : null}
                                                        {Mainprops.LspReducer.feedbackList && !Mainprops.LspReducer.feedbackList.length && <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(ServiceFeedback));
