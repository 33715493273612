import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router' 

class Employment extends Component {

    state = {
        showAdd: true
    }
    componentDidMount() {
        document.title = this.props.t('homeContent.foreignInfo', { param: 'react' })
    }
    render() {
        const { showAdd } = this.state
        const data = [
            {
                "S.No.": "1",
                "DBEE NAME": "Amritsar",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/19p2h8bl",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/4htbar93",
                "HELPLINE NO.": "99157-89068"
            },
            {
                "S.No.": "2",
                "DBEE NAME": "Barnala",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/148xbv34",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/w9hm13kf",
                "HELPLINE NO.": "94170-39072"
            },
            {
                "S.No.": "3",
                "DBEE NAME": "Bathinda",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/foreignstudyBti",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/foreignplacementBti",
                "HELPLINE NO.": "77196-81908"
            },
            {
                "S.No.": "4",
                "DBEE NAME": "Faridkot",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/forgstudyfdk",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/FplacementFDK",
                "HELPLINE NO.": "99883-50193"
            },
            {
                "S.No.": "5",
                "DBEE NAME": "Fatehgarh Sahib",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/Foreignstudyfgs",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/Foreignplacementfgs",
                "HELPLINE NO.": "99156-82436"
            },
            {
                "S.No.": "6",
                "DBEE NAME": "Fazilka",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/1x3vuroj",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/4kqt8jc8",
                "HELPLINE NO.": "89060-22220"
            },
            {
                "S.No.": "7",
                "DBEE NAME": "Ferozepur",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/FSPFZR",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/FSPFZR",
                "HELPLINE NO.": "94654-74122"
            },
            {
                "S.No.": "8",
                "DBEE NAME": "Gurdaspur",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/3ywu3t7e",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/cq1sebtl",
                "HELPLINE NO.": "81960-15208"
            },
            {
                "S.No.": "9",
                "DBEE NAME": "Hoshiarpur",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/foreignstudyhsp",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/foreignplacementhsp",
                "HELPLINE NO.": "62801-97708"
            },
            {
                "S.No.": "10",
                "DBEE NAME": "Jalandhar",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/foreignstudyjal",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/foreignplacementjal",
                "HELPLINE NO.": "90569-20100"
            },
            {
                "S.No.": "11",
                "DBEE NAME": "Kapurthala",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/foreignstudykpt",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/foreignplacementkpt",
                "HELPLINE NO.": "98882-19247"
            },
            {
                "S.No.": "12",
                "DBEE NAME": "Ludhiana",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/foreignstudyLdh",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/foreignplacementLdh",
                "HELPLINE NO.": "77400-01682"
            },
            {
                "S.No.": "13",
                "DBEE NAME": "Mansa",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/575m9pv6",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/9dx3d2rq",
                "HELPLINE NO.": "94641-78030"
            },
            {
                "S.No.": "14",
                "DBEE NAME": "Moga",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/FS-Moga",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/FP-Moga",
                "HELPLINE NO.": "62392-66860"
            },
            {
                "S.No.": "15",
                "DBEE NAME": "Sh. Mukatsar Sahib",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/FS-Muktsar",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/FP-Muktsar",
                "HELPLINE NO.": "98885-62317"
            },
            {
                "S.No.": "16",
                "DBEE NAME": "Patiala",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/f5ekr2lu",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/y7ouadpy",
                "HELPLINE NO.": "98776-10877"
            },
            {
                "S.No.": "17",
                "DBEE NAME": "Pathankot",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/studdyaboard",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/foreignplacementt",
                "HELPLINE NO.": "76578-25214"
            },
            {
                "S.No.": "18",
                "DBEE NAME": "Ropar",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/foreignstudyropar",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/foreignplacementropar",
                "HELPLINE NO.": "85570-10066"
            },
            {
                "S.No.": "19",
                "DBEE NAME": "SBS Nagar",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/1d9cwjyg",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/dbu2dmzc",
                "HELPLINE NO.": "88727-59915"
            },
            {
                "S.No.": "20",
                "DBEE NAME": "Sangrur",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/SangFS",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/SangFP",
                "HELPLINE NO.": "98779-18167"
            },
            {
                "S.No.": "21",
                "DBEE NAME": "SAS Nagar",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/foreignstudyMohali",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/foreignplacementMohali",
                "HELPLINE NO.": "78142-59210"
            },
            {
                "S.No.": "22",
                "DBEE NAME": "Tarn Taran",
                "FOREIGN STUDY COUNSELING": "tinyurl.com/foreignstudyhelpdbeett",
                "FOREIGN PLACEMENT COUNSELING": "tinyurl.com/foreignjobshelpdbeett",
                "HELPLINE NO.": "77173-97013"
            }
        ]
        return (
            <div className="tab-content">
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading mobheading">
                                        <h6 className="mb-0">{this.props.t('homeContent.foreignInfo', { param: 'react' })}
                                        {showAdd ? 
                                        <Link style={{color:"#fff", cursor: "pointer"}} className="pull-right" onClick={() => this.setState({showAdd: false})}>{this.props.t('foreignInfo.clickAdd', { param: 'react' })}</Link>
                                        : <Link style={{color:"#fff", cursor: "pointer"}} className="pull-right" onClick={() => this.setState({showAdd: true})}>{this.props.t('jobSearch.goBack', { param: 'react' })}</Link>}</h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {showAdd ? 
                                                <div className="table-responsive testable">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>{this.props.t('foreignInfo.sno', { param: 'react' })}</th>
                                                                <th>{this.props.t('foreignInfo.dbeeName', { param: 'react' })}</th>
                                                                <th>{this.props.t('foreignInfo.foreignStudy', { param: 'react' })}</th>
                                                                <th>{this.props.t('foreignInfo.foreignPlacement', { param: 'react' })}</th>
                                                                <th>{this.props.t('foreignInfo.helpLine', { param: 'react' })}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data && data.map((d, key) => {
                                                                return (<tr key={key}>
                                                                    <td>{key + 1} </td>
                                                                    <td>{d['DBEE NAME']}</td>
                                                                    <td><a rel="noopener noreferrer" target="_blank" href={'http://'+d['FOREIGN STUDY COUNSELING']}>{d['FOREIGN STUDY COUNSELING']}</a></td>
                                                                    <td><a rel="noopener noreferrer" target="_blank" href={'http://'+d['FOREIGN PLACEMENT COUNSELING']}>{d['FOREIGN PLACEMENT COUNSELING']}</a></td>
                                                                    <td>{d['HELPLINE NO.']}</td>
                                                                </tr>)
                                                            })}
                                                        </tbody>

                                                    </table>
                                                </div>: 
                                                <img className="w-100" src={'/images/IMG-20210218-WA0001.jpeg'} alt={'Advertisement'}/> }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Employment));
