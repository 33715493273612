import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'

class Employment extends Component {

    componentDidMount() {
        document.title = 'Screen Reader';
    }
    render() {
        const data = [{
            name: "Screen Access For All (SAFA)",
            link1: "http://safa-reader.software.informer.com/download/",
            
        },
        {
            name: "Non Visual Desktop Access (NVDA)",
            link1: "http://www.nvda-project.org/",
           
        },
        {
            name: "System Access To Go",
            link1: "http://www.satogo.com/",
        
        },
        {
            name: "Thunder",
            link1: "http://www.screenreader.net/index.php?pageid=2",
            
        }]
        return (
            <div className="tab-content">
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">Screen Reader</h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive testable">
                                                    <h2>Screen Reader Access</h2>
                                                       <p>  This feature will enable people with visual impairments access the website using Assistive Technologies, such as screen readers. 
                                                           The information of this website is accessible with different screen readers, as mentioned below:
                                                        </p>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th width="5%">Sr.no</th>
                                                                <th width="20%">Screen Reader</th>
                                                                <th width="75%">Website</th>
                                                                {/* <th width="20%">If you want to register as a labourer, go to the link of your location/ District.</th>
                                                                <th width="20%">For getting help regarding registration as labourer/ employer, please contact the following Email of your location/ District</th>
                                                                <th width="20%">For getting help regarding registration as labourer/ employer, please contact the following Helpline No. of your location/ District</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data && data.map((d, key) => {
                                                                return (<tr key={key}>
                                                                    <td>{key + 1} </td>
                                                                    <td>{d.name ? d.name : ''}</td>
                                                                    <td><a rel="noopener noreferrer" target="_blank" href={d.link1}>{d.link1}</a></td>
                                                                </tr>)
                                                            })}
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Employment));
