import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Sidebar from './Sidebar';
import store from '../../../store';
import moment from 'moment'
import {
    getAllScheduledSession, getArchieveSession,
    getSessionCandidatesAttended, getShorlistedApplicants,
    setSessionApplicants, getSessionsAppliedBySeeker,
    updateCounselling, cancelSession,getIndividualRequest,getIndividualsRequest,getProfile
} from '../../../actions/counsellingproviderAction'
import{isCounselled} from '../../../actions/accountAction';
import Pagination from "react-js-pagination";
import { chunk } from '../../../helpers'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Link } from 'react-router'
import ReactExport from "react-export-excel";
import DatePicker from "react-datepicker";
import Validation from "../../hoc/Validation";
import SimpleReactValidator from 'simple-react-validator';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const RESULT_PER_PAGE = 25

class MyAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePageScheduled: 1,
            activePageApplied: 1,
            activePageArchieve: 1,
            job: '',
            activePageAtt: 1,
            activePage: 1,
            Profile: [],
            errorDateFrom: '',
            errorDateTo: '',
            sessionId: '',
            reason: '',
            reasonSubmitted: false,
            reason1: '',
            check:[],
            cid:'',
            key:'',
            dis:[],
          
        
        }
        store.dispatch(getAllScheduledSession())
        store.dispatch(getProfile())
        store.dispatch(getArchieveSession())
        store.dispatch(getIndividualRequest())
        store.dispatch(getIndividualsRequest())
        store.dispatch(getSessionsAppliedBySeeker())
        store.dispatch(isCounselled())
        this.reasonValidator = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });
    }

    componentDidMount() {
        document.title = this.props.t('header.my_account', { param: 'react' });
     
       
    }
 
    
    handlePageChangeScheduled = (activePage) => {
        this.setState({ activePageScheduled: activePage })
    }
    handlePageChangeApplied = (activePage) => {
        this.setState({ activePageApplied: activePage })
    }
    handlePageChangeArchieve = (activePage) => {
        this.setState({ activePageArchieve: activePage })
    }

    getAllSession = () => {
        const sessions = []
        this.props.counsellingproviderReducer.archieveSession &&
            this.props.counsellingproviderReducer.archieveSession.length > 0 &&
            this.props.counsellingproviderReducer.archieveSession.forEach((e) => {
                let valid_from = e.valid_from.split("-")
                valid_from = new Date(`${valid_from[0]},${valid_from[1]}, ${valid_from[2]}`)
                let expire_on = e.expire_on.split("-")
                expire_on = new Date(`${expire_on[0]},${expire_on[1]}, ${expire_on[2]}`)
                sessions.push({
                    title: e.title,
                    start: valid_from,
                    end: expire_on,
                    description: e.description
                })

            })
        return sessions
    }

    handleChangeSession = () => {
        if (!this.state.reasonSubmitted && this.reasonValidator.allValid()) {
            const reason = this.state.reason !== '4' ? this.state.reason : this.state.reason1
            store.dispatch(cancelSession(this.state.sessionId, reason))
            document.getElementById("myModal415").click()
            this.setState({ reasonSubmitted: true, reason: '', reason1: '' })
        } else {
            this.reasonValidator.showMessages();
            this.forceUpdate();
        }
    }

    setReason = (e) => {
        this.reasonValidator.purgeFields()
        if (e.target.value && e.target.value !== '4') {
            this.setState({ reason: e.target.value, reason1: '' })
        } else {
            this.setState({ reason: e.target.value })
        }
    }
 handlemodel=(e,keys)=>{
   
    this.setState({'cid':e.target.value})
    this.setState({'key':keys});
    window.$('#counselledmodal').modal('show');

}

handleno=()=>{
    const c_id=this.state.cid
    const key=this.state.key
    this.state.check[key]='0'
    const value={is_counselled: "0", id:c_id,user_id:this.props.counsellingproviderReducer.Profile.user_id};
    store.dispatch(isCounselled(value));
    window.$('#counselledmodal').modal('hide');
}

    handleyes=()=>{
      
        const c_id=this.state.cid
        const key=this.state.key
        // const unchecked=e.target.checked
        this.state.dis[key]='true'
        // if(unchecked!=false){
            this.state.check[key]='1'
        const value={is_counselled: "1", id:c_id,user_id:this.props.counsellingproviderReducer.Profile.user_id};
        store.dispatch(isCounselled(value));
        window.$('#counselledmodal').modal('hide');
    // }else{
            // this.state.check[key]='0'
            // const value={is_counselled: "0", id:c_id,user_id:this.props.counsellingproviderReducer.Profile.user_id};
            // store.dispatch(isCounselled(value));
            // store.dispatch(getSessionsAppliedBySeeker())
            //   window.location.reload();
        // }
        // window.location.reload();
        // window.$('#jobModal').modal('hide');
       
    }

    // handleno=(e)=>{
    //     const c_id=e.target.value
    //     console.log(c_id);
    //     const value={is_counselled: "2", id:c_id,user_id:this.props.counsellingproviderReducer.Profile.user_id};
    //     store.dispatch(isCounselled(value))
    //     // window.location.reload();
    //     // window.$('#jobModal').modal('hide');


    // }

    handleChangeDate = (date, key) => {
        const Profile = this.state.Profile
        let formatedDate = moment(date).format('YYYY-MM-DD').split("-")

        let errorDateTo = ''
        let errorDateFrom = ''
        if (key === 'valid_from' && Profile.expire_on) {
            let newFormatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            if (moment(newFormatedDate).isSameOrAfter(Profile.expire_on)) {
                errorDateFrom = 'Valid from date must not be greater than equal to the expiry date'
            } else {
                errorDateFrom = ''
                this.setDatePickerValues(formatedDate, key)
            }
            this.setState({ errorDateFrom })

        } else if (key === 'expire_on' && Profile.valid_from) {
            let newFormatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`

            if (moment(newFormatedDate).isSameOrBefore(Profile.valid_from)) {
                errorDateTo = 'Expiry date must not be less than or equal to the valid from date'
            } else {
                this.setDatePickerValues(formatedDate, key)
                errorDateTo = ''
            }
            this.setState({ errorDateTo })

        } else {
            this.setDatePickerValues(formatedDate, key)
        }

    }

    setDatePickerValues = (formatedDate, key) => {
        const Profile = this.state.Profile
        if (formatedDate[0].length === 4) {
            formatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            Profile[key] = formatedDate
            this.setState({ Profile, submit: false });
        }
    }

    handleJobInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const Profile = this.state.Profile
        Profile[name] = value
        this.setState({ Profile, submit: false })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { errorDateTo, errorDateFrom } = this.state
        if (!this.state.submit && this.props.validator.allValid() && !errorDateTo && !errorDateFrom) {
            this.setState({ submit: true })
            store.dispatch(updateCounselling(this.state.Profile))
            document.getElementById("myModal414").click()
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }
    
    getDatePicker = (key) => {
        const { Profile } = this.state
        const placeholder = key === 'expire_on' ?
            this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.expire_on', { param: 'react' }) :
            this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.valid_from', { param: 'react' })
        return (
            <DatePicker
                dateFormat="dd/MM/yyyy"
                className="form-control w-100"
                placeholderText={placeholder}
                showYearDropdown={true}
                showMonthDropdown={true}
                selected={Profile[`${key}`] ? Date.parse(Profile[`${key}`]) : ''}
                onChange={(e) => this.handleChangeDate(e, key)}
            />
        )
    }

    getCandidates = (job, count, type = null) => {
        if (parseInt(count) > 0) {
            store.dispatch(setSessionApplicants([]))
            if (type && type === 'shortlisted') {
                store.dispatch(getShorlistedApplicants(job.id))
            } else {
                store.dispatch(getSessionCandidatesAttended(job.id))
            }
            this.setState({ selectedSession: job })
        }
    }

    render() {

       
    
        const localizer = momentLocalizer(moment)
        const sessions = this.getAllSession()
        const { activePageScheduled, activePageApplied, activePageArchieve,
            activePageAtt, activePage, errorDateFrom, errorDateTo, Profile } = this.state
        let Mainprops = this.props;
        const searchResultScheduled = chunk(this.props.counsellingproviderReducer.scheduledSession, RESULT_PER_PAGE)
        const searchResultApplied = chunk(this.props.counsellingproviderReducer.sessionsAppliedBySeeker, RESULT_PER_PAGE)
        const searchResultArchieve = chunk(this.props.counsellingproviderReducer.archieveSession, RESULT_PER_PAGE)
        const searchResultIndividual =chunk(this.props.counsellingproviderReducer.individual_request.data, RESULT_PER_PAGE)
        const searchResultIndividuals =chunk(this.props.counsellingproviderReducer.individuals_request.data, RESULT_PER_PAGE)
            console.log(searchResultIndividuals);
        {searchResultIndividual && searchResultIndividual.length > 0 && searchResultIndividual[activePageArchieve - 1].map((jobs, key,index) => {
            return (
         
            this.state.check.push(jobs.is_Counselled)   
         
        )})}
  
    
        const applicantsResults = chunk(Mainprops.counsellingproviderReducer.applicants, RESULT_PER_PAGE)
        const { validator } = this.props
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }


        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <div className="modal fade" id="myModal415">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h6 className="modal-title">{this.props.t('counsellingProvider.cancelDesc', { param: 'react' })}</h6>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>

                                    <div className="modal-body">
                                        <div className="panel-body mb-0">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-lg-12">
                                                    <label data-placement="bottom">
                                                        {this.props.t('counsellingProvider.reason', { param: 'react' })}<span
                                                            style={{ color: 'red' }}
                                                            className="ml-2">*</span>
                                                    </label>
                                                    <select className="form-control"
                                                        onChange={(e) => this.setReason(e)}>
                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('counsellingProvider.reason', { param: 'react' })}</option>
                                                        <option >Personal Reasons</option>
                                                        <option >Technical Issues</option>
                                                        <option >Low Attendance</option>
                                                        <option value="4">Other</option>
                                                    </select>
                                                    {this.reasonValidator.message('reason', this.state.reason, 'required')}

                                                </div>
                                            </div>
                                            {this.state.reason === '4' ?
                                                <div className="row mt-2">
                                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                                        <label data-placement="bottom">
                                                            {this.props.t('banner.others', { param: 'react' })}<span
                                                                style={{ color: 'red' }}
                                                                className="ml-2">*</span>
                                                        </label>
                                                        <textarea
                                                            name="reason1"
                                                            onChange={(e) => this.setState({ reason1: e.target.value })}
                                                            className="w-100"
                                                            value={this.state.reason1 ? this.state.reason1 : ""}
                                                        />
                                                        {this.reasonValidator.message('other reason', this.state.reason1, 'required')}
                                                    </div>
                                                </div>
                                                : null}
                                            <hr></hr>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-md-2"><button
                                                className="btn btn-primart btn-primary2 print-btn mx-auto d-block"
                                                onClick={this.handleChangeSession}
                                            > {this.props.t('profileDetails.yes', { param: 'react' })}
                                            </button></div>
                                            <div className="col-md-2"><button
                                                className="btn btn-primart btn-primary2 print-btn mx-auto d-block"
                                                data-dismiss="modal"
                                            > {this.props.t('profileDetails.no', { param: 'react' })}
                                            </button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="myModal414">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">{this.props.t('experience.edit', { param: 'react' })}</h6>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="panel-body mb-0 pb-3">
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                                        <div className="row">
                                                            <div className="col-sm-4  text-right">
                                                                <label data-placement="bottom">
                                                                    {this.props.t('counsellingProvider.valid_from', { param: 'react' })}<span
                                                                        style={{ color: 'red' }}
                                                                        className="ml-2">*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                {this.getDatePicker('valid_from')}
                                                                {validator.message('valid from', Profile.valid_from, 'required')}
                                                                {errorDateFrom && <span style={{ "color": "red" }}>{errorDateFrom}</span>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                                        <div className="row">
                                                            <div className="col-sm-4  text-right">
                                                                <label data-placement="bottom">
                                                                    {this.props.t('counsellingProvider.expire_on', { param: 'react' })}<span
                                                                        style={{ color: 'red' }}
                                                                        className="ml-2">*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                {this.getDatePicker('expire_on')}
                                                                {validator.message('expire on', Profile.expire_on, 'required')}
                                                                {errorDateTo && <span style={{ "color": "red" }}>{errorDateTo}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                                        <div className="row">
                                                            <div className="col-sm-4  text-right">
                                                                <label data-placement="bottom">
                                                                    {this.props.t('counsellingProvider.timing_from', { param: 'react' })}<span
                                                                        style={{ color: 'red' }}
                                                                        className="ml-2">*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type="time"
                                                                    value={this.state.Profile.timing_from ? this.state.Profile.timing_from : ''}
                                                                    onChange={this.handleJobInput}
                                                                    className="form-control mb-2"
                                                                    name="timing_from"
                                                                    placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.timing_from', { param: 'react' })} />
                                                                {validator.message('timing from', Profile.timing_from, 'required')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                                        <div className="row">
                                                            <div className="col-sm-4  text-right">
                                                                <label data-placement="bottom">
                                                                    {this.props.t('counsellingProvider.timing_to', { param: 'react' })}<span
                                                                        style={{ color: 'red' }}
                                                                        className="ml-2">*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type="time"
                                                                    value={this.state.Profile.timing_to ? this.state.Profile.timing_to : ''}
                                                                    onChange={this.handleJobInput}
                                                                    className="form-control"
                                                                    name="timing_to"
                                                                    placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.timing_to', { param: 'react' })}
                                                                />
                                                                {validator.message('timing to', Profile.timing_to, 'required')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <button
                                                    className="btn btn-primart btn-primary2 print-btn mx-auto d-block mb-2 mt-2"
                                                > {this.props.t('jobSearch.update', { param: 'react' })}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal top-header2 show" id="myModalShortlistedAtt" aria-modal="true">
                            <div className="modal-dialog">
                                <div className="modal-content" style={{ "marginTop": "30%" }}>
                                    <div className="modal-header ">
                                        <div className="panel-heading w-100">
                                            <span className="mb-0">{this.props.t('counsellingProvider.attendence', { param: 'react' })}</span>
                                            <button type="button" className="close" data-dismiss="modal">×</button>
                                        </div>
                                    </div>
                                    <div className="modal-body p-3">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                        <th>{this.props.t('dashboard.table.name', { param: 'react' })}</th>
                                                        <th>{this.props.t('dashboard.table.gender', { param: 'react' })}</th>
                                                        <th>{this.props.t('dashboard.table.education', { param: 'react' })}</th>
                                                        <th>{this.props.t('dashboard.table.mobile', { param: 'react' })}</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {applicantsResults && applicantsResults.length > 0 && applicantsResults[activePageAtt - 1].map((jobs, key) => {
                                                        return (<tr key={key}>
                                                            <td>{((activePageAtt - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                            <td>{jobs.firstname ? jobs.firstname : ''} </td>
                                                            <td>{jobs.gender ? jobs.gender : ''}</td>
                                                            <td>{jobs.education ? jobs.education : ''}</td>
                                                            <td>{jobs.mobile ? jobs.mobile : ''}</td>
                                                        </tr>)
                                                    })}
                                                    {!this.props.counsellingproviderReducer.applicants.length &&
                                                        <tr>
                                                            <td colSpan="5" style={{ "textAlign": "center" }}>
                                                                <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5></td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                            {applicantsResults && applicantsResults.length > 1 ?
                                                <div className='react-pagination'>
                                                    <Pagination
                                                        pageRangeDisplayed={10}
                                                        activePage={activePageAtt}
                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                        totalItemsCount={this.props.counsellingproviderReducer.applicants.length}
                                                        onChange={this.handlePageChangeAtt}
                                                    />
                                                </div> : ''}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {this.props.counsellingproviderReducer.applicants && this.props.counsellingproviderReducer.applicants.length ?
                                            <ExcelFile filename="Selected candidate" element={<button type="button" className="btn btn-primary btn-primary2">
                                                {this.props.t('counsellingProvider.downloadExcel', { param: 'react' })}</button>}>
                                                <ExcelSheet data={this.props.counsellingproviderReducer.applicants} name="Candidates">
                                                    <ExcelColumn label="Name" value={(jobs) => jobs.firstname ? jobs.firstname : ''} />
                                                    <ExcelColumn label="Gender" value={(jobs) => jobs.gender ? jobs.gender : ''} />
                                                    <ExcelColumn label="Education" value={(jobs) => jobs.education ? jobs.education : ''} />
                                                    <ExcelColumn label="Mobile"
                                                        value={(jobs) => jobs.mobile ? jobs.mobile : ''} />
                                                    <ExcelColumn label="Attendance"
                                                        value={(jobs) => {
                                                            if (jobs.attendence && jobs.attendence === '1') {
                                                                return 'Present'
                                                            } else if (!jobs.attendence) {
                                                                return 'Absent'
                                                            } else {
                                                                return ''
                                                            }
                                                        }} />
                                                </ExcelSheet>
                                            </ExcelFile>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal top-header2 show" id="myModalShortlisted" aria-modal="true">
                            <div className="modal-dialog">
                                <div className="modal-content" style={{ "marginTop": "30%" }}>
                                    <div className="modal-header ">
                                        <div className="panel-heading w-100">
                                            <span className="mb-0">{this.props.t('counsellingProvider.applicants', { param: 'react' })}</span>
                                            <button type="button" className="close" data-dismiss="modal">×</button>
                                        </div>
                                    </div>
                                    <div className="modal-body p-3">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                        <th>{this.props.t('dashboard.table.name', { param: 'react' })}</th>
                                                        <th>{this.props.t('dashboard.table.gender', { param: 'react' })}</th>
                                                        <th>{this.props.t('dashboard.table.education', { param: 'react' })}</th>
                                                        <th>{this.props.t('dashboard.table.mobile', { param: 'react' })}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {applicantsResults && applicantsResults.length > 0 && applicantsResults[activePage - 1].map((jobs, key) => {
                                                        return (<tr key={key} >
                                                            <td>{((activePage - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                            <td>{jobs.firstname ? jobs.firstname : ''} </td>
                                                            <td>{jobs.gender ? jobs.gender : ''}</td>
                                                            <td>{jobs.education ? jobs.education : ''}</td>
                                                            <td>{jobs.mobile ? jobs.mobile : ''}</td>
                                                        </tr>)
                                                    })}
                                                    {!this.props.counsellingproviderReducer.applicants.length &&
                                                        <tr>
                                                            <td colSpan="6" style={{ "textAlign": "center" }}>
                                                                <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5></td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                            {applicantsResults && applicantsResults.length > 1 ?
                                                <div className='react-pagination'>
                                                    <Pagination
                                                        pageRangeDisplayed={10}
                                                        activePage={activePage}
                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                        totalItemsCount={this.props.counsellingproviderReducer.applicants.length}
                                                        onChange={this.handlePageChange}
                                                    />
                                                </div> : ''}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {this.props.counsellingproviderReducer.applicants && this.props.counsellingproviderReducer.applicants.length ?
                                            <ExcelFile filename="Selected candidate" element={<button type="button" className="btn btn-primary btn-primary2">
                                                {this.props.t('counsellingProvider.downloadExcel', { param: 'react' })}</button>}>
                                                <ExcelSheet data={this.props.counsellingproviderReducer.applicants} name="Candidates">
                                                    <ExcelColumn label="Name" value={(jobs) => jobs.firstname ? jobs.firstname : ''} />
                                                    <ExcelColumn label="Gender" value={(jobs) => jobs.gender ? jobs.gender : ''} />
                                                    <ExcelColumn label="Education" value={(jobs) => jobs.education ? jobs.education : ''} />
                                                    <ExcelColumn label="Mobile"
                                                        value={(jobs) => jobs.mobile ? jobs.mobile : ''} />
                                                </ExcelSheet>
                                            </ExcelFile>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-xl">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('counsellingProvider.calender', { param: 'react' })}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Calendar
                                                    localizer={localizer}
                                                    events={sessions}
                                                    startAccessor="start"
                                                    className="counsellor-calendar"
                                                    endAccessor="end"
                                                    style={{ "height": "500", "width": "80%" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary btn-primary2" data-dismiss="modal">
                                            {this.props.t('counsellingProvider.close', { param: 'react' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <div className={"row"}>
                                                            <div className={"col-lg-6"}>
                                                                <h6 className="mb-0">{this.props.t('dashboard.title', { param: 'react' })}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body">
                                                        <form>
                                                            <div className="accordion md-accordion accordion-2 "
                                                                id="accordionEx7" role="tablist"
                                                                aria-multiselectable="true">
                                                                <div className="card border-0">
                                                                    <div
                                                                        className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                        role="tab" id="heading2">
                                                                        <a data-toggle="collapse"
                                                                            data-parent="#accordionEx7" href="#collapse2"
                                                                            aria-expanded="true"
                                                                            aria-controls="collapse2">
                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                {this.props.t('counsellingProvider.interestedParticipants', { param: 'react' })}<i
                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                            </h6>
                                                                        </a>
                                                                    </div>

                                                                    <div id="collapse2" className="collapse"
                                                                        role="tabpanel" aria-labelledby="heading2"
                                                                        data-parent="#accordionEx7">
                                                                        <div
                                                                            className="card-body mb-1 p-2 rgba-grey-light white-text">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.session_name', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.sessionMode', { param: 'react' })}</th>

                                                                                            <th>{this.props.t('dashboard.table.date', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.appliedCandidates', { param: 'react' })}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {searchResultApplied && searchResultApplied.length > 0 && searchResultApplied[activePageApplied - 1].map((jobs, key) => {
                                                                                            return (<tr key={key}>
                                                                                                <td>{((activePageApplied - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                <td>{jobs.title ? jobs.title : ''} </td>
                                                                                                <td>{jobs.mode ? jobs.mode : ''} </td>

                                                                                                <td>{`${jobs.valid_from} - ${jobs.expire_on}`}</td>
                                                                                                <td><Link className="date-clr" to={`applied-candidates/${jobs.id}`}>
                                                                                                    {this.props.t('homeContent.clickHere', { param: 'react' })}
                                                                                                </Link></td>
                                                                                            </tr>)
                                                                                        })}
                                                                                        {!this.props.counsellingproviderReducer.sessionsAppliedBySeeker.length &&
                                                                                            <tr>
                                                                                                <td colSpan="6"
                                                                                                    style={{ "textAlign": "center" }}>
                                                                                                    <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>
                                                                                                </td>
                                                                                            </tr>}
                                                                                    </tbody>
                                                                                </table>
                                                                                {searchResultApplied && searchResultApplied.length > 1 ?
                                                                                    <div className='react-pagination'>
                                                                                        <Pagination
                                                                                            pageRangeDisplayed={10}
                                                                                            activePage={activePageApplied}
                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                            totalItemsCount={this.props.counsellingproviderReducer.sessionsAppliedBySeeker.length}
                                                                                            onChange={this.handlePageChangeApplied}
                                                                                        />
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card border-0">
                                                                    <div
                                                                        className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                        role="tab" id="heading1">
                                                                        <a data-toggle="collapse"
                                                                            data-parent="#accordionEx7"
                                                                            href="#collapse41" aria-expanded="true"
                                                                            aria-controls="collapse1">
                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                {this.props.t('counsellingProvider.scheduledCounselling', { param: 'react' })}<i
                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>

                                                                            </h6>
                                                                        </a>
                                                                    </div>

                                                                    <div id="collapse41" className="collapse "
                                                                        role="tabpanel" aria-labelledby="heading1"
                                                                        data-parent="#accordionEx7">
                                                                        <div
                                                                            className="card-body mb-1 p-2 rgba-grey-light white-text">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.session_name', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.sessionMode', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.date', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.shortlisted', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.seats', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.action', { param: 'react' })}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {searchResultScheduled && searchResultScheduled.length > 0 && searchResultScheduled[activePageScheduled - 1].map((jobs, key) => {
                                                                                            return (<tr key={key}>
                                                                                                <td>{((activePageScheduled - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                <td>{jobs.title ? jobs.title : ''} </td>
                                                                                                <td>{jobs.mode ? jobs.mode : ''} </td>
                                                                                                <td>{`${jobs.valid_from} - ${jobs.expire_on}`}</td>

                                                                                                <td><span className="date-clr"
                                                                                                    data-toggle={parseInt(jobs.shortlistcount) > 0
                                                                                                        ? "modal" : ''} data-target={parseInt(jobs.shortlistcount) > 0 ? "#myModalShortlisted" : ''}
                                                                                                    onClick={() => {
                                                                                                        this.setState({ activePage: 1 });
                                                                                                        this.getCandidates(jobs, jobs.shortlistcount, 'shortlisted')
                                                                                                    }}
                                                                                                    style={{ "cursor": parseInt(jobs.shortlistcount) > 0 ? "pointer" : "" }}>
                                                                                                    {jobs.shortlistcount}
                                                                                                </span>

                                                                                                </td>
                                                                                                <td>{jobs.seat ? jobs.seat : ''} </td>
                                                                                                <td>{jobs.session_cancel !== '1' &&
                                                                                                    <span className="date-clr" data-toggle="modal" style={{ "cursor": "pointer" }}
                                                                                                        onClick={() => {
                                                                                                            this.reasonValidator.hideMessages()
                                                                                                            this.setState({ sessionId: jobs.id, reasonSubmitted: false, reason: '', reason1: '' })
                                                                                                        }}
                                                                                                        data-target="#myModal415">{this.props.t('counsellingProvider.cancel', { param: 'react' })}</span>}
                                                                                                </td>
                                                                                            </tr>)
                                                                                        })}
                                                                                        {this.props.counsellingproviderReducer.scheduledSession && !this.props.counsellingproviderReducer.scheduledSession.length &&
                                                                                            <tr>
                                                                                                <td colSpan="7"
                                                                                                    style={{ "textAlign": "center" }}>
                                                                                                    <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>
                                                                                                </td>
                                                                                            </tr>}
                                                                                    </tbody>
                                                                                </table>
                                                                                {searchResultScheduled && searchResultScheduled.length > 1 ?
                                                                                    <div className='react-pagination'>
                                                                                        <Pagination
                                                                                            pageRangeDisplayed={10}
                                                                                            activePage={activePageScheduled}
                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                            totalItemsCount={this.props.counsellingproviderReducer.scheduledSession.length}
                                                                                            onChange={this.handlePageChangeScheduled}
                                                                                        />
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card border-0">

                                                                    <div
                                                                        className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                        role="tab" id="heading2">
                                                                        <a data-toggle="collapse"
                                                                            data-parent="#accordionEx7" href="#collapse3"
                                                                            aria-expanded="true"
                                                                            aria-controls="collapse3">
                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                {this.props.t('counsellingProvider.session_archieve', { param: 'react' })}<i
                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                            </h6>
                                                                        </a>
                                                                    </div>

                                                                    <div id="collapse3" className="collapse"
                                                                        role="tabpanel" aria-labelledby="heading2"
                                                                        data-parent="#accordionEx7">
                                                                        <div
                                                                            className="card-body mb-1 p-2 rgba-grey-light white-text">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.session_name', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.sessionMode', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.date', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.attendence', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.sessionAttended', { param: 'react' })}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {searchResultArchieve && searchResultArchieve.length > 0 && searchResultArchieve[activePageArchieve - 1].map((jobs, key) => {
                                                                                            return (<tr key={key}>
                                                                                                <td>{((activePageArchieve - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                <td>{jobs.title ? jobs.title : ''} </td>
                                                                                                <td>{jobs.mode ? jobs.mode : ''} </td>
                                                                                                <td>{`${jobs.valid_from} - ${jobs.expire_on}`}</td>
                                                                                                <td><span className="date-clr"
                                                                                                    data-toggle={parseInt(jobs.attendence) > 0
                                                                                                        ? "modal" : ''} data-target={parseInt(jobs.attendence) > 0 ? "#myModalShortlistedAtt" : ''}
                                                                                                    onClick={() => {
                                                                                                        this.setState({ activePage: 1 });
                                                                                                        this.getCandidates(jobs, jobs.attendence)
                                                                                                    }}
                                                                                                    style={{ "cursor": parseInt(jobs.attendence) > 0 ? "pointer" : "" }}>
                                                                                                    {jobs.attendence}
                                                                                                </span>

                                                                                                </td>
                                                                                                <td>{jobs.count}</td>
                                                                                            </tr>)
                                                                                        })}
                                                                                        {!this.props.counsellingproviderReducer.archieveSession.length &&
                                                                                            <tr>
                                                                                                <td colSpan="5"
                                                                                                    style={{ "textAlign": "center" }}>
                                                                                                    <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>
                                                                                                </td>
                                                                                            </tr>}
                                                                                    </tbody>
                                                                                </table>
                                                                                {searchResultArchieve && searchResultArchieve.length > 1 ?
                                                                                    <div className='react-pagination'>
                                                                                        <Pagination
                                                                                            pageRangeDisplayed={10}
                                                                                            activePage={activePageArchieve}
                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                            totalItemsCount={this.props.counsellingproviderReducer.archieveSession.length}
                                                                                            onChange={this.handlePageChangeArchieve}
                                                                                        />
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
 



                                                            </div>
                                                        </form>
                                                        <div className="card border-0">

<div
    className="card-header rgba-stylish-strong z-depth-1 mb-1"
    role="tab" id="heading2">
    <a data-toggle="collapse"
        data-parent="#accordionEx7" href="#collapse4"
        aria-expanded="true"
        aria-controls="collapse4">
        <h6 className="mb-0 white-text font-thin">
        Jobseekers For Individual Session<i
                className="fa fa-angle-down rotate-icon pull-right"></i>
        </h6>
    </a>
</div>

<div id="collapse4" className="collapse"
    role="tabpanel" aria-labelledby="heading2"
    data-parent="#accordionEx7">
    <div
        className="card-body mb-1 p-2 rgba-grey-light white-text">
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Highest Education</th>
                        <th>District</th>
                        <th>Requested on</th>
                        <th>Is Counselled</th>
                    </tr>
                </thead>
                <tbody>
                    {searchResultIndividual && searchResultIndividual.length > 0 && searchResultIndividual[activePageArchieve - 1].map((jobs,key) => {
                       
                        return (
                      
                        <tr key={key}>
                                  
                            <td>{((activePageArchieve - 1) * RESULT_PER_PAGE) + key + 1}</td>
                            <td>{jobs.fname ? jobs.fname : ''} </td>
                            <td>{jobs.mobile ? jobs.mobile : ''} </td>
                            <td>{jobs.highest_educ}</td>
                            <td>{jobs.district}</td>
                            <td>{jobs.requested_on}</td>
                                     
                          
                             <td><input type='checkbox' value={jobs.candidate_id} checked={this.state.check[key]!=null ? this.state.check[key]!='0' ? 'true' : false : console.log('unchecked due to null')}
                             disabled={this.state.dis[key]} onChange={(e) => this.handlemodel(e,key)} /></td>

                            
                        </tr>)
                    })}
            
                
                </tbody>
            </table>
 
            {searchResultIndividual && searchResultIndividual.length > 1 ?
                <div className='react-pagination'>
                    <Pagination
                        pageRangeDisplayed={10}
                        activePage={activePageArchieve}
                        itemsCountPerPage={RESULT_PER_PAGE}
                        totalItemsCount={this.props.counsellingproviderReducer.individual_request.data.length}
                        onChange={this.handlePageChangeArchieve}
                    />
                </div> : ''}
        </div>
    </div>
</div>
</div>

{/* //Himanshu */}
<div className="card border-0">

<div
    className="card-header rgba-stylish-strong z-depth-1 mb-1"
    role="tab" id="heading2">
    <a data-toggle="collapse"
        data-parent="#accordionEx7" href="#collapse5"
        aria-expanded="true"
        aria-controls="collapse5">
        <h6 className="mb-0 white-text font-thin">
        Counselled Jobseekers For Individual Session<i
                className="fa fa-angle-down rotate-icon pull-right"></i>
        </h6>
    </a>
</div>

<div id="collapse5" className="collapse"
    role="tabpanel" aria-labelledby="heading2"
    data-parent="#accordionEx7">
    <div
        className="card-body mb-1 p-2 rgba-grey-light white-text">
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Highest Education</th>
                        <th>District</th>
                        <th>Requested on</th>
                        <th>Is Counselled</th>
                    </tr>
                </thead>
                <tbody>
                    {searchResultIndividuals && searchResultIndividuals.length > 0 && searchResultIndividuals[activePageArchieve - 1].map((jobs,key) => {
                       
                        return (
                      
                        <tr key={key}>
                                  
                            <td>{((activePageArchieve - 1) * RESULT_PER_PAGE) + key + 1}</td>
                            <td>{jobs.fname ? jobs.fname : ''} </td>
                            <td>{jobs.mobile ? jobs.mobile : ''} </td>
                            <td>{jobs.highest_educ}</td>
                            <td>{jobs.district}</td>
                            <td>{jobs.requested_on}</td>
                                     
                          
                             <td><input type='checkbox' value={jobs.candidate_id} checked='true'
                             disabled='true' onChange={(e) => this.handlemodel(e,key)} /></td>

                            
                        </tr>)
                    })}
            
                
                </tbody>
            </table>
 
            {searchResultIndividuals && searchResultIndividual.length > 1 ?
                <div className='react-pagination'>
                    <Pagination
                        pageRangeDisplayed={10}
                        activePage={activePageArchieve}
                        itemsCountPerPage={RESULT_PER_PAGE}
                        totalItemsCount={this.props.counsellingproviderReducer.individuals_request.data.length}
                        onChange={this.handlePageChangeArchieve}
                    />
                </div> : ''}
        </div>
    </div>
</div>
</div>




                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="modal fade intro-modal" id="counselledmodal" style={{ 'zIndex': '99999' }}>
                            <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
                            <div className="modal-content d-block bg-dark2">
                            <div className="modal-body pt-2  pb-1 text-center">
                               
                                <div className="d-flex align-items-center w-100 justify-content-center">
                                    <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                </div>

                                <h5 className="mx-auto  text-uppercase mb-3">{this.props.t('jobMela.pgrkam', { param: 'react' })}</h5>
        
                                <div className="row d-flex">
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                        <div className="first-click">
                                            <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                                            <p>Please confrim if you are accepting the counselling of the jobseeker.</p>
                                         
                                            {/* <Link to="/signin" className="btn btn-danger pt-1 pb-1" onClick={this.closeModal} >{this.props.t('homeContent.clickToLogin', { param: 'react' })}</Link> */}
                                            <button className="btn btn-danger pt-1 pb-1"  onClick={() => this.handleyes()}>Yes</button>
                                        
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                        <div className="first-click">
                                            <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                                            <p className={"pl-2 pr-2"}>Please confrim if you are not accepting the counselling of the jobseeker.</p>
                                            {/* <Link to="/signup" className="btn btn-danger pt-1 pb-1" onClick={this.closeModal}>Click to Register</Link> */}
                                            <button className="btn btn-danger pt-1 pb-1"  onClick={() => this.handleno()}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                      </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(MyAccount)));
