import axios from 'axios';
import qs from 'qs';
import {
    SET_FORGIEN_PROVIDER_PROFILE, SET_FORGIEN_POST_ARCHIVE, SET_FORGIEN_POST_STATUS,
    SET_FORGIEN_SCHEDULED_JOBS, SET_FORGIEN_RECENT_JOBS, SET_APPLIED_FORGIEN_JOB,
    SET_SELECTED_FORGIEN_APPLICANTS, FORGIEN_JOB_UPDATE, SET_SEEKER_FOR_FORGIEN_JOB,
    SET_GENERATE_EXCEL, SET_JOB_TITLES, SET_FOREIGN_JOB_MODES, SET_FOREIGN_JOB_TYPES,
    SET_FOREIGN_UNEXPIRED_JOBS, SET_PROVIDER_TYPES
} from './actionTypes';
import ServerSetting from '../config.json';
import { setNotification } from './notificationAction'
import { set_Loader, unset_Loader } from './accountAction'
import { getJobProviderAddress } from './employerAction'
import { push } from 'react-router-redux';

let environment = (typeof process.env.NODE_ENV !== 'undefined' && process.env.NODE_ENV === 'production') ? 'production' : 'development';
let API_SERVER = ServerSetting[environment].API_SERVER;

export const getProfile = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'foreign-jobprovider-details/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                // debugger
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setProfile(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setGenerateExel = (status) => {
    return {
        type: SET_GENERATE_EXCEL,
        status
    };
};

export const selectCandidatesForJob = (data) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'shorlist-foreign-jobseeker/index', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader())
                // console.log('resp', resp)
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getAppliedApplicants(data.job_id, true))

                }
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};

export const getConfimedCandidates = (job_id) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'confirmed-foreign-job/index', qs.stringify({ auth_key: localStorage.getItem('token'), job_id }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setSelectedApplicants(resp.data.data));
                } else {
                    dispatch(setSelectedApplicants({ notFound: "No Records Found" }));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};



export const setSeekers = (data) => {
    return {
        type: SET_SEEKER_FOR_FORGIEN_JOB,
        data
    }
}

export const setJobTitles = (data) => {
    return {
        type: SET_JOB_TITLES,
        data
    }
}


export const searchSeeker = (data) => {
    return (dispatch) => {
        dispatch(set_Loader());
        // http://151.106.28.89/ggr/api/v1/index.php/
        // http://151.106.28.89/ggr/api/v1/index.php/search-foreign-jobseekers/index
        axios.post(API_SERVER + 'search-foreign-jobseekers/index', qs.stringify(data))
            .then(resp => {
                // console.log('resp', resp.data)
                dispatch(unset_Loader());
                if (resp.data.status !== '401') {
                    dispatch(setSeekers(resp.data.data));
                } else {
                    dispatch(setSeekers(resp.data));
                }

                // dispatch(jobApplied(true))
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};

export const inviteSeeker = (data) => {
    // data.auth_key = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        // http://151.106.28.89/ggr/api/v1/index.php/
        axios.post(API_SERVER + 'invite-foreign-candidates/index', qs.stringify(data))
            .then(resp => {
                // console.log('resp', resp)
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    // dispatch(jobApplied(true))
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(push('/myaccount'))
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // // dispatch(Logout());
            });
    };
};

export const updateProfile = (data) => {
    data['auth_key'] = localStorage.getItem('token')
    let formData = new FormData();

       for (var key in data) {
        formData.append(key, !data[key] ? '' : data[key]);
    }
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'foreign-jobprovider-details/create', formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getProfile())
                    dispatch(getJobProviderAddress())

                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const postJob = (data) => {
    data['auth_key'] = localStorage.getItem('token')

    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'post-foreign-jobs/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    // dispatch(setPostJobStatus(true))
                    dispatch(push('/myaccount'))
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getModesOfSelection = () => {

    return dispatch => {
        dispatch(set_Loader(''))
        axios.get(API_SERVER + 'jobmode/view')
            .then(resp => {
                dispatch(unset_Loader())
                dispatch(setModesOfSelection(resp.data))
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getTypes = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.get(API_SERVER + 'foreignjob-type/view')
            .then(resp => {
                dispatch(unset_Loader())
                dispatch(setTypes(resp.data))
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setModesOfSelection = (data) => {
    return {
        type: SET_FOREIGN_JOB_MODES,
        data
    };
};

export const setTypes = (data) => {
    return {
        type: SET_FOREIGN_JOB_TYPES,
        data
    };
};

export const setUnexpiredJobs = (data) => {
    return {
        type: SET_FOREIGN_UNEXPIRED_JOBS,
        data
    };
};





export const getJobArchieve = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'foreign-jobprovider-details/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                // console.log('vhghgh', resp.data.data)
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setJobArchieve(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getUnexpiredJobs = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'valid-foreignjobs/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                // console.log('vhghgh', resp.data.data)
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setUnexpiredJobs(resp.data.rows));
                } else {
                    dispatch(setUnexpiredJobs({ notFound: true }));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};




export const getJobTitles = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.get(API_SERVER + 'jobs-description/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                // if (resp.data.status === '200') {
                dispatch(setJobTitles(resp.data));
                // } 
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getJobProviderTypes = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.get(API_SERVER + 'foreignjob-type/index')
            .then(resp => {
                dispatch(unset_Loader())
                dispatch(setJobProviderTypes(resp.data));
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};


export const getSelectedApplicants = (job_id) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'selected-foreignjob-candidate/index', qs.stringify({ auth_key: localStorage.getItem('token'), job_id }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setSelectedApplicants(resp.data.rows));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getAcceptedInviteApplicants = (job_id) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'scheduled-foreign-job/update',
            qs.stringify({ auth_key: localStorage.getItem('token'), job_id }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setSelectedApplicants(resp.data.rows));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getAppliedApplicants = (job_id, action = false) => {
    return dispatch => {
        dispatch(set_Loader(''))

        axios.post(API_SERVER + 'scheduled-foreign-job/view', qs.stringify({ auth_key: localStorage.getItem('token'), job_id }))
            .then(resp => {
                // http://151.106.28.89/ggr/api/v1/index.php/
                // console.log('vhghgh 213', resp.data)
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setSelectedApplicants({ "notFound": "No records Found" }))
                } else {

                    dispatch(setSelectedApplicants(resp.data));
                    if (resp.data && resp.data.rows && resp.data.rows.length) {
                        if (action) {
                            dispatch(setGenerateExel(true));
                        }
                    }
                }



            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getShortlistedCandidates = (job_id) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'shortlisted-foreignjob-candidate/view', qs.stringify({ auth_key: localStorage.getItem('token'), job_id }))
            .then(resp => {
                // console.log('vhghgh 213 _____________', resp.data)
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setSelectedApplicants(resp.data.rows));
                } else {
                    dispatch(setSelectedApplicants({ notFound: "No Records Found" }));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};


export const setSelectedApplicants = (data) => {
    return {
        type: SET_SELECTED_FORGIEN_APPLICANTS,
        data
    };
};


export const confirmJob = (data) => {
    // data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        axios.post(API_SERVER + 'confirmed-foreign-job/create', qs.stringify(data))
            .then(resp => {
                //   console.log(resp)
                // debugger
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
                else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getShortlistedCandidates(data.job_id))
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};


export const updateJobStatus = (status, id, applied_user_id) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'selected-foreignjob-candidate/view', qs.stringify({ status, id, applied_user_id }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(jobUpdated(true));
                    dispatch(getAppliedJobs())
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getAppliedJobs = (job_id) => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'scheduled-foreign-job/create', qs.stringify({ auth_key: localStorage.getItem('token'), job_id }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    let data = []
                    resp.data.posted.forEach((r) => {
                        let post = resp.data.selected.filter((sel) => sel.job_id === r.id)
                        post = post ? post[0] : []

                        let applied = resp.data.appliedcount.filter((sel) => sel.job_id === r.id)
                        applied = applied ? applied[0] : []

                        let accepted = resp.data.acceptinvitcount.filter((sel) => sel.job_id === r.id)
                        accepted = accepted ? accepted[0] : []

                        data.push({ ...r, ...post, ...applied, ...accepted })
                    })
                    dispatch(setAppliedJobs(data));
                }
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};

export const setAppliedJobs = (data) => {
    return {
        type: SET_APPLIED_FORGIEN_JOB,
        data
    };
};

export const jobUpdated = (status) => {
    return {
        type: FORGIEN_JOB_UPDATE,
        status
    }
}


export const getInterstedSeekers = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'scheduled-foreign-job/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setScheduledJobs(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};

export const getScheduledJobs = () => {
    return dispatch => {
        dispatch(set_Loader(''))
        axios.post(API_SERVER + 'shortlisted-foreignjob-candidate/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader())
                // console.log('resp.data 11212', resp.data)
                dispatch(setRecentJobs(resp.data));
                if (resp.data.status === '200') {
                    dispatch(setRecentJobs(resp.data.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};



export const setProfile = (data) => {
    return {
        type: SET_FORGIEN_PROVIDER_PROFILE,
        data
    };
};

export const setScheduledJobs = (data) => {
    return {
        type: SET_FORGIEN_SCHEDULED_JOBS,
        data
    };
};
export const setRecentJobs = (data) => {
    return {
        type: SET_FORGIEN_RECENT_JOBS,
        data
    };
};

export const setJobArchieve = (data) => {
    return {
        type: SET_FORGIEN_POST_ARCHIVE,
        data
    };
};
export const setJobProviderTypes = (data) => {
    return {
        type: SET_PROVIDER_TYPES,
        data
    };
};



export const setPostJobStatus = (status) => {
    return {
        type: SET_FORGIEN_POST_STATUS,
        status
    };
};



