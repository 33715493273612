import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import store from "../../store"
import { getEmployers, setRedirectionLink, get_User_District } from "../../actions/accountAction";

import { Link } from 'react-router';
// import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash';
import {useParams} from 'react-router-dom';

class JobFairEmployers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      offset: 1,
      limit: 25,
      isFetching: false,
      searchParams: [],
      interviewDetails: [],

      

    }
    store.dispatch(getEmployers({ offset: this.state.offset, limit: this.state.limit,mela_id:this.props.routeParams.id }));
    store.dispatch(get_User_District())
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    console.log(this.props.routeParams.id);

    
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.actionReducer.employers && nextProps.actionReducer.employers.data &&
      nextProps.actionReducer.employers.data.length) {
      // document.title = nextProps.actionReducer.activeJobMela.jobMela
      document.title = "Employers in Job Fair";
      const isFetching = nextProps.actionReducer.employers.hasMoreRecords ? false : true
      this.setState({ isFetching })
    }
  }



  handlePageChange = (activePage) => {
    this.setState({ activePage: activePage })
  }

  getGender = (gender) => {
    if (gender.indexOf(',') >= 0) {
      return "Any"
    } else {
      return gender === '1' ? 'Female' : 'Male'
    }
  }

  getDistrict = () => {
    let Mainprops = this.props;
    let Distoption = []
    if (Mainprops.actionReducer.District.length > 0) {
      Mainprops.actionReducer.District.map(function (data, index) {
        if (data.state_id === '3') {
          data.dist.map(function (distdata) {
            if ((distdata.id !== '666') && (distdata.id !== '668')) { //for all districts
            // if (distdata.id === '36') { 
              Distoption.push(<option key={distdata.name} value={distdata.name}>{distdata.name}</option>)
            }

            return Distoption
          })
        }
        return Distoption
      })
    }
    return Distoption

  }
  
  search = (e) => {
    let name = e.target.name
    let value = e.target.value
    const searchParams = this.state.searchParams
    if (value) {
      searchParams[name] = value
    } else {
      delete searchParams[name]
    }
    this.setState({ searchParams }, () => {
      this.debouncedSearch()
    })
  }

  setInterviewDetails = (details) => {
    document.getElementById("spanModal").click()
    // document.getElementById("spanModal1").click()
    this.setState({ interviewDetails: details })
  }

  setCourseDetails = (details) => {
    // document.getElementById("spanModal").click()
    document.getElementById("spanModal1").click()
    this.setState({ interviewDetails: details })
  }

  redirectTo = () => {
    store.dispatch(setRedirectionLink(`jobfair`))
    this.props.router.push('/signin?action=jobfair')
  }

  redirectToSignup = () => {
    this.props.router.push('/signup?type=1')
  }

  debouncedSearch = _.debounce(() => {
    this.setState({ offset: 1 })
    const { searchParams, limit, offset } = this.state
    store.dispatch(getEmployers({ offset, limit, searchParams }));
  }, 500)

  loadItems() {
    if (!this.state.isFetching) {
      this.setState((prevState) => {
        return { offset: prevState.offset + prevState.limit }
      })
      let searchParams = this.state.searchParams
      searchParams = Object.keys(searchParams).length ? searchParams : undefined
      store.dispatch(getEmployers({ offset: this.state.offset, limit: this.state.limit, searchParams }, true));
      this.setState({ isFetching: true })
    }
  }

  getSelect = (field) => {
    let options = field === 'gender' ?
        <>
            <option value="">{this.props.t('interviewDetails.selectGender', { param: 'react' })}</option>
            <option value="1">Female</option>
            <option value="2">Male</option>
            <option value="3">Any</option>
        </> :
        <>
            {field === 'district' ? <>
                <option value="">{this.props.t('interviewDetails.selectDistrict', { param: 'react' })}</option>
                {this.getDistrict()}
            </>
                :
                
                
                <>
                    <option value="">{this.props.t('placeholder.select', { param: 'react' })}</option>
                    <option value="1">{this.props.t('dashboard.table.jobs', { param: 'react' })}</option>
                </>}
                
                
        </>
        

    return <select name={field} className="form-control" value={this.state.searchParams[field] ? this.state.searchParams[field] : ''} onChange={this.search}>{options}</select>
}
getSelect2 = (field) => {
  console.log(field);
  let options = field === 'eligibility' ?
      <>
          <option value="">{this.props.t('interviewDetails.selectGender', { param: 'react' })}</option>
          <option value="10">No schooling</option>
          <option value="1">5th</option>
          <option value="2">8th</option>
          <option value="3">10th</option>
          <option value="4">12th</option>
          <option value="5">Diploma after 10th</option>
          <option value="6">Diploma after 12th</option>
          <option value="7">Gradudate</option><option value="1">Female</option>
          <option value="8">Post Graduate</option>
          <option value="9">PHD</option>
          <option value="12">Pg Diploma</option>
          <option value="11">ITI</option>
          <option value="11">M Phill</option>
      </> :
      <>
      
              
              
            
                  <option value="">{this.props.t('placeholder.select', { param: 'react' })}</option>
                  
            
              
              
      </>
      console.log(field);

  return <select name={field} className="form-control" value={this.state.searchParams[field] ? this.state.searchParams[field] : ''} onChange={this.search}>{options}</select>
}

  getTableItems = () => {
    return Object.keys(this.props.actionReducer.employers).length && this.props.actionReducer.employers.data &&
      this.props.actionReducer.employers.data.length ?
      this.props.actionReducer.employers.data.map((job, key) => {
        return (<div key={key} className="mela_row">
          {/* <div>{key + 1}</div> */}
          <div style={{ width: "10%" }}>{job.district}</div>
          <div style={{ width: "10%" }}>{job.org_name}</div>
          <div style={{ width: "10%" }}>{job.job_title}</div>
          <div style={{ width: "10%" }}>{job.vacancies}</div>
          <div style={{ width: "10%", textAlign:'left'}}><span>{job.eligiblity}</span> <i style={{ fontSize: "15px", cursor: "pointer", color: "#ed9017" }}
            className="fa fa-plus-circle " aria-hidden="true" onClick={() => this.setCourseDetails(job)}
          ></i><span data-toggle="modal" data-target="#myModal1" id="spanModal1"></span></div>
          <div style={{ width: "10%" }}>{job.maxage ? job.maxage : 'NA'}</div>
          <div style={{ width: "10%" }}>{this.getGender(job.gender)}</div>
          <div style={{ width: "10%" }}>{job.experience ? job.experience : 'NA'}</div>
          <div>{job.salary}</div>
          <div>{job.posting}</div>
          <div><i style={{ fontSize: "20px", cursor: "pointer", color: "#ed9017" }}
            className="fa fa-info-circle " aria-hidden="true" onClick={() => this.setInterviewDetails(job)}
          ></i><span data-toggle="modal" data-target="#myModal" id="spanModal"></span></div>
        </div>)
      })
      : null
  }

  render() {
    const { interviewDetails } = this.state
    const columns = [
      // {
      //   label: this.props.t('dashboard.table.sr_no', { param: 'react' }),
      //   field: '',
      //   sort: 'asc',
      //   width: 50
      // },
      {
        label: this.props.t('profileDetails.district', { param: 'react' }),
        field: 'district',
        sort: 'asc',
        width: 270,
        html: this.getSelect('district')
      },
      {
        label: 'Employer',
        field: 'employer',
        sort: 'asc',
        width: 370
      },
      {
        label: this.props.t('dashboard.table.job-title', { param: 'react' }),
        field: 'jobTitle',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Vacancies',
        field: '',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Eligiblity',
        field: 'eligibility',
        sort: 'asc',
        width: 250,
        html: this.getSelect2('eligibility')
      },
      {
        label: 'Max Age' + "**",
        field: '',
        sort: 'asc',
        width: 100
      },
      {
        label: this.props.t('dashboard.table.gender', { param: 'react' }),
        field: 'gender',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Exp(yrs)',
        field: '',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Salary',
        field: '',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Posting Place',
        field: 'posting',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Venue',
        field: '',
        sort: 'asc',
        width: 100
      }
    ];

    return (
      <div className="">
        <div className="modal" id="myModal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{this.props.t('JobPost.int', { param: 'react' })}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {interviewDetails && interviewDetails.virtual_interview === '1' ?
                  <>
                    <h6>{this.props.t('hallTicket.virtual', { param: 'react' })}</h6>
                    <span>{this.props.t('JobPost.interviewPlatform', { param: 'react' })}: {interviewDetails && interviewDetails.virtual_venue}</span>
                    <br/><span>{this.props.t('JobPost.int_date', { param: 'react' })}: {interviewDetails && interviewDetails.virtual_interview_date}</span>
                    <hr/>
                  </> : null}
                  
                {interviewDetails && interviewDetails.physical_interview === '1' ?
                  <>
                    <h6>{this.props.t('hallTicket.physical', { param: 'react' })}</h6>
                    <span>{this.props.t('JobPost.int_venue', { param: 'react' })}: {interviewDetails && interviewDetails.physical_venue}</span>
                   <br/> <span>{this.props.t('JobPost.int_date', { param: 'react' })}: {interviewDetails && interviewDetails.physical_interview_date}</span>
                  </> : null}
              </div>
              <span style={{'fontSize':'16px', 'color':'orange', 'marginLeft':'2%'}}>For further details contact <b>{interviewDetails && interviewDetails.district} DBEE</b>. Mob: <b>{interviewDetails && interviewDetails.contact}</b></span>
              <div className="modal-footer">
              {/* <span>For More information contact to {interviewDetails && interviewDetails.district} DBEE. Mob: {interviewDetails && interviewDetails.contact}</span><br/> */}
                <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">{this.props.t('how_to_use.close', { param: 'react' })}</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="myModal1" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Education Details </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                    <h6><u>Course</u></h6>
                    <span>{interviewDetails && interviewDetails.course}</span>
                    <br/>
                    <h6><u>Course Specialization</u></h6>
                    <span> {interviewDetails && interviewDetails.course_specialization}</span>
              </div>
              <span style={{'fontSize':'16px', 'color':'orange', 'marginLeft':'2%'}}>For further details contact <b>{interviewDetails && interviewDetails.district} DBEE</b>. Mob: <b>{interviewDetails && interviewDetails.contact}</b></span>
              <div className="modal-footer">
              {/* <span>For More information contact to {interviewDetails && interviewDetails.district} DBEE. Mob: {interviewDetails && interviewDetails.contact}</span><br/> */}
                <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">{this.props.t('how_to_use.close', { param: 'react' })}</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade intro-modal" id="registerModal1" style={{ 'zIndex': '99999' }}>
          <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
            <div className="modal-content d-block">
              <div className="modal-body pt-2  pb-1 text-center">
                <div className="d-flex align-items-center w-100 justify-content-center">
                  <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                </div>
                <h5 className="mx-auto  text-uppercase mb-3">{this.props.t('homeContent.pgrkamTitle', { param: 'react' })}</h5>
                <div className="row d-flex">
                  <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                    <div className="first-click">
                      <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                      <p> {this.props.t('homeContent.inst1', { param: 'react' })}</p>
                      <Link className="btn btn-danger pt-1 pb-1"
                        onClick={this.redirectTo} data-dismiss="modal" style={{"color":"#ffff"}}>{this.props.t('homeContent.clickToLogin', { param: 'react' })}</Link>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                    <div className="first-click">
                      <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                      <p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst2', { param: 'react' })}</p>
                      <Link onClick={this.redirectToSignup} style={{"color":"#ffff"}} className="btn btn-danger pt-1 pb-1" data-dismiss="modal">{this.props.t('homeContent.clickToRegister', { param: 'react' })}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="testmodal12" className="modal fade">
          <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
            <div className="modal-content d-block">

              <div className="modal-body pt-3  pb-3 text-center" style={{ 'background': 'linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%)' }}>
                <img alt="" src="/images/logo-new2.png"
                  className="img-fluid pb-3 home-top-logo" />

                <h4>September Rozgar Mela Week</h4>
                <ul className={"text-left fair-date pl-3"}>
                  <li>To see the available 90,000 vacancies, <a href="/job-placement-fair-employers">Click here </a></li>
                  <li>To download the list of all available 90,000 vacancies, <a href="/docs/Vacancy_SepRozgar.pdf" target="_blank">Click Here</a></li>
                  <li>To participate in the September Rozgar Mela Week and to apply for the available private vacancies, you have to login to your registered account on pgrkam.com</li>

                </ul>

                <div className="row w-100 mx-auto d-flex">
                  <div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
                    <div className="first-click">
                      <img alt="" src="/images/mod2.png"
                        className="img-fluid pb-3 w-25" />

                      <p> If you are already registered on pgrkam.com</p>
                      <a type="button" href="signin" className="btn btn-danger mt-2">Click here to Login</a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
                    <div className="first-click">
                      <img alt="" src="/images/mod1.png"
                        className="img-fluid pb-3 w-25" />
                      <p> If you want to register on pgrkam.com</p>
                      <a type="button" href="signup?type=1"
                        className="btn btn-danger mt-2">Click here to Register</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="home" className=" tab-pane active">
          <section className="login-form mt-2">
            <div className="container">
              <div className="row">
                <div className="col-md-12  mx-auto  tab-content  p-0">
                  <div className="panel-heading">
                    <div className="row">
                      <div className={"col-md-12 col-sm-12"}><h6 className="mb-0">
                        {/* {this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.jobMela ? this.props.actionReducer.activeJobMela.jobMela : ''}( ** Maximum age is as on 1-1-2021) */}
                        <div className={"col-md-6 col-sm-6"} style={{textAlign:"right",float:"left"}}>
                        {/* <span>Mela Name: <b>{this.props.actionReducer.employers.mela_name ? this.props.actionReducer.employers.mela_name : 'Job Fair' } </b>Rozgar Mela - 07.06.2023</span> */}
                        <span><b style={{fontSize:"20px"}}>Rozgar Mela - 07.06.2023</b></span> 
                        </div>
                        <div className={"col-md-6 col-sm-6"} style={{textAlign:"right",float:"left"}}>
                        {/* <span>Mela Name: <b>{this.props.actionReducer.employers.mela_name ? this.props.actionReducer.employers.mela_name : 'Job Fair' } </b>Rozgar Mela - 07.06.2023</span> */}
                         <span><a href='/signin'>Click Here To Apply</a></span>
                        </div>
                        {/* <div className={"col-md-5 col-sm-5"} style={{float:"left"}}>
                        <span style={{marginLeft:"60%"}}>Start Date:<b> {this.props.actionReducer.employers.start_date ? this.props.actionReducer.employers.start_date :'Contact DBEE'} </b> </span> <br/>
                        </div>
                        <div className={"col-md-12 col-sm-12"}>
                        <span >Mela Venue:<b> {this.props.actionReducer.employers.mela_venue ? this.props.actionReducer.employers.mela_venue : 'DBEE Office'} </b> </span> <br/>
                        </div>
                        <div className={"col-md-12 col-sm-12"}>
                        <span >Mela Description:<b> {this.props.actionReducer.employers.mela_description ? this.props.actionReducer.employers.mela_description : '-NA-'} </b> </span>
                        </div> */}
                       
                        {/* {!this.props.actionReducer.Loginuser.length && !this.props.actionReducer.Loginuser.id ?
                          <Link className="pull-right rite_btn"
                            style={{ color: "#fff", cursor: "pointer"}}
                            data-target="#registerModal1" data-toggle="modal">
                           <img src="/images/apply_online.gif" alt="Apply button"/></Link>
                          :
                          <Link className="pull-right"
                            style={{ color: "#fff" }}
                            onClick={() => this.props.router.push('jobfair')}>
                            <b>Click here to Apply</b></Link>
                        } */}
                      </h6></div>
                    </div>

                  </div>
                  
                  <div className="panel-body" style={{'padding':'0px','margin': '0px 0px 0px 0px'}}>
                    <div id="jobfair" className="overflowouter table-responsive">
                      <div className="testClass" id="scrollableDiv">
                      {this.props.actionReducer.employers && this.props.actionReducer.employers.status === '200' ?
                        <div>
                          <div className="mela_row headertopfixed">
                            {columns.map((col, key) => {
                              return (<div key={key}>{col.label}</div>)
                            })}
                          </div>
                          <div className="mela_row headermidfixed">
                            {columns.map((col, key) => {
                              if (col.field && col.field !== 'gender' && col.field && col.field !== 'district'&& col.field && col.field !== 'eligibility') {
                                return (<div key={key}><input type="text" className="form-control" name={col.field}
                                  onChange={this.search} /></div>)
                              } if (col.field && col.field === 'district') {
                                return (<div key={key}>
                                  <select name={col.field} className="form-control" value={this.state.searchParams[col.field] ? this.state.searchParams[col.field] : ''} onChange={this.search}>
                                    <option value="">{this.props.t('profileDetails.selectDistrict', { param: 'react' })}</option>
                                    {this.getDistrict()}
                                  </select></div>)
                              }  if (col.field && col.field === 'gender') {
                                return (<div key={key}>
                                  <select name={col.field} className="form-control" value={this.state.searchParams[col.field] ? this.state.searchParams[col.field] : ''} onChange={this.search}>
                                    <option value="">{this.props.t('profileDetails.selectGender', { param: 'react' })}</option>
                                    <option value="1">Female</option>
                                    <option value="2">Male</option>
                                    <option value="3">Any</option>
                                  </select>
                                </div>)
                              } 
                              if (col.field && col.field === 'eligibility') {
                                return (<div key={key}>
                                  <select name={col.field} className="form-control" value={this.state.searchParams[col.field] ? this.state.searchParams[col.field] : ''} onChange={this.search}>
                                  <option value="">Select eligibilitys</option>
          <option value="No schooling">No schooling</option>
          <option value="Fifth">5th</option>
          <option value="Eight">8th</option>
          <option value="Tenth">10th</option>
          <option value="Twelth">12th</option>
          <option value="Diploma_after_10th">Diploma After 10th</option>
          <option value="Diploma_after_12th">Diploma After 12th</option>
          <option value="Gradudate">Gradudate</option>
          <option value="Post_Graduate">Post Graduate</option>
          <option value="Phd">Phd</option>
          <option value="PG Diploma">PG Diploma</option>
          <option value="ITI">ITI</option>
          <option value="M Phill">M Phill</option>
                                  </select>
                                </div>)
                              } 
                              else {
                                return <div key={key}></div>
                              }


                            })}
                          </div>

                        </div>:''}
                        {/* <InfiniteScroll
                          initialLoad={false}
                          element="tbody"
                          loadMore={this.loadItems.bind(this)}
                          hasMore={this.props.actionReducer.employers.hasMoreRecords}
                          loader={<div key={0} >
                            <div colSpan="12" style={{ textAlign: "center" }}>
                              <Loader
                                type="Rings"
                                color="#F1911C"
                                height={100}
                                width={100}
                              />
                            </div></div>}
                        > */}
                        {this.props.actionReducer.employers && this.props.actionReducer.employers.hasOwnProperty('data') ?
                          <InfiniteScroll
                            height={500}
                            dataLength={this.props.actionReducer.employers.data.length}
                            next={this.loadItems.bind(this)}
                            hasMore={this.props.actionReducer.employers.hasMoreRecords}
                            loader={<div key={0} >
                              <div colSpan="12" style={{ textAlign: "center" }}>
                                <Loader
                                  type="Rings"
                                  color="#F1911C"
                                  height={100}
                                  width={100}
                                />
                              </div></div>}
                            scrollableTarget="scrollableDiv"
                          >
                            <>
                              {this.getTableItems()}
                              {this.props.actionReducer.employers && this.props.actionReducer.employers.status === '401' ?
                                <div>
                                  <div
                                    className="job-not-found">
                                    {/* <h3>{this.props.t('JobPost.notFound', { param: 'react' })}</h3>
                                     */}
                                     <h4>For more information, contact {this.props.actionReducer.employers.DBEE } at {this.props.actionReducer.employers.contact }    </h4>
                                  </div>
                                </div>
                                : null}
                            </>
                          </InfiniteScroll> : null}

                        {/* </InfiniteScroll> */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    )
  }
}

const mapStateToProps = store => {
  return store;
};
export default connect(mapStateToProps)(translate(JobFairEmployers));
