import React, { Component } from 'react';

class GuestPage extends Component {
    render() {
        return (
            <div>
                <section className="top-inner animatedParent">
                    <div className="container-fluid" >
                      <div className="col-md-12 content-paragraph">
                            <div className="row" style={{'margin-top':'2%', 'margin-bottom':'18%'}}>
                                <div className="col-md-8 col-md-offset-8 pb-8 mx-auto">
                                    <div className="row">
                                        <div className="col-md-12">
                                        	<div className="row d-flex">
                                            <div className="col-md-6 col-lg-6 col-sm-6 mb-6 d-flex ">
                                              <div className="first-click">
                                                  <h5>User can avail following benefits after registration on this Portal.</h5>
                                                  <ul className="jobseeker-benefits">
                                                    <li>User will be provided by a dashboard to track all the latest information.</li>
                                                    <li>User (Jobseekers) can update their detailed information, photo and upload resume using dashboard.</li>
                                                    <li>User (Jobseeker) can search and apply for jobs either in their district and outside.</li>
                                                    <li>User (Job Provider) can Post new Jobs.</li>
                                                    <li>Jobseeker can  Employer information and communicate with them.</li>
                                                    <li>Jobseeker & Employer can track scheduled interviews and get imformation of upcoming job fairs.</li>
                                                  </ul>
                                                    <a type="button" href="signup"  class="btn btn-danger mt-2" >Want to Register</a>
                                                    <a type="button" href="SignIn"  class="btn btn-danger mt-2" style={{'margin-left':'21%'}}>Want to Login</a>
                                              </div>
                                          </div>
                                          <div className="col-md-6 col-lg-6 col-sm-6 mb-6 d-flex ">
                                            <div className="first-click">
                                            <h5>ABOUT PGRKAM</h5>
                                            <ul className="jobseeker-benefits">
                                              <li>PGRKAM- Punjab Ghar Ghar Rozgar and Karobar Mission.</li>
                                              <li>An Inclusive Mission by Dept.of Employment Generation and Training, Punjab towards augmenting employability and development of skills for the people.</li>
                                              <li>It supports Jobseeker to search and apply for jobs either in their district and outside.</li>
                                              <li>PGRKAM organizes Job Fairs in various district of Punjab every year, where lots of jobseekers and Employers got benefits.</li>
                                              <li>It consists of digital platform, employment exchanges, DBEES, skill development centers.</li>
                                            </ul>
                                              <a className="btn btn-danger mt-2" href="/">Go Back</a>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                      </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default GuestPage;
