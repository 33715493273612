import React, { Component } from 'react';
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { getCalendarSessions } from '../../../actions/counsellingproviderAction'
import moment from 'moment'
import { Link } from 'react-router'

class SessionCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formDataPass: [],
            activePage: 1,
            Profile: [],
            job: [],
            submit: false,
            selectedCheckboxes: new Set(),
            candidatesArray: []
        }
        store.dispatch(getCalendarSessions())
    }


    showModal = (event) => {
        this.props.router.push(`/post-counselling?edit=${event.id}`)
    }


    componentDidMount() {
        document.title = this.props.t('counsellingProvider.calender', { param: 'react' })
    }

    getAllSession = () => {
        const sessions = []
        this.props.counsellingproviderReducer.archieveSession &&
            this.props.counsellingproviderReducer.archieveSession.length > 0 &&
            this.props.counsellingproviderReducer.archieveSession.forEach((e) => {
                let valid_from = e.valid_from.split("-")
                valid_from = new Date(`${valid_from[0]},${valid_from[1]}, ${valid_from[2]}`)
                let expire_on = e.expire_on.split("-")
                expire_on = new Date(`${expire_on[0]},${expire_on[1]}, ${expire_on[2]}`)
                sessions.push({
                    id: e.id,
                    title: e.title,
                    start: valid_from,
                    end: expire_on,
                    description: e.description
                })

            })
        return sessions
    }


    render() {
        const localizer = momentLocalizer(moment)
        const sessions = this.getAllSession()
        const Mainprops = this.props
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}


                        <main className="col-md-9 second-side p-0 pb-3 h-100">

                            <div className="clearfix"></div>

                            <div id="home" className=" tab-pane active">
                                <section className="job-details mt-4">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12  mx-auto  tab-content  p-0">
                                                <div className="panel-heading">
                                                    <h6 className="mb-0">{this.props.t('counsellingProvider.calender', { param: 'react' })}</h6>

                                                </div>
                                                <div className="panel-body">
                                                    <div className="col-md-12 col-sm-12 col-lg-12 right-sidebar-scroll">
                                                        <Calendar
                                                            localizer={localizer}
                                                            onSelectEvent={(event) => this.showModal(event)}
                                                            events={sessions}
                                                            startAccessor="start"
                                                            className="counsellor-calendar"
                                                            endAccessor="end"
                                                            style={{ "height": "500", "width": "100%" }}
                                                        />
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(SessionCalendar));
