import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router'
import { get_lsp_profile, 
    // getFeedback
 } from '../../../actions/lspAction'
 import ServerSetting from '../../../config.json';
import store from '../../../store'

// const ALLOWED_FEEDBACK_COUNT = 20

class Sidebar extends Component {

    constructor(props) {
        super(props);
        if (this.props.EmployerReducer.Profile && !Object.keys(this.props.EmployerReducer.Profile).length) {
            store.dispatch(get_lsp_profile());
        }
        // if (this.props.LspReducer.feedbackList && !Object.keys(this.props.LspReducer.feedbackList).length) {
        //     store.dispatch(getFeedback())
        // }
    }

    componentDidMount(){
        window.openCloseSidebar()
    }

    capitalizeFirstLetter = (string) => {
        if(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => {
            this.validateField(name, value)
        });
    }


    render() {
        let Mainprops = this.props;
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;
        // console.log('this.props.LspReducer.feedbackList', this.props.LspReducer.feedbackList)
        return (
            <aside className="col-md-2 px-0 widget-sidebar " id="left">
                <div className="list-group w-100">
                    <ul className="nav flex-column flex-nowrap">
                        {/* <li title={this.props.t('lsp_user.dashboardInst', { param: 'react' })} className={"nav-item list-group-item " + (Mainprops.route.path === '/myaccount' ? 'active' : '')}>
                            <Link className="nav-link" to="/myaccount">
                                {this.props.t('dashboard.welcome', { param: 'react' })} {this.props.EmployerReducer.Profile.org_name ?
                                    <b>{", " + this.props.EmployerReducer.Profile.org_name}</b> : ''}
                            </Link>
                        </li> */}
                        
                            <Link to="/myaccount" className="date-clr">
                                <div className="d-flex align-items-center justify-content-center w-100 pull-left pt-2 outr-profile text-center">
                                    <div className="outr-avtar">
                                        <img alt="avatar"
                                            src={this.props.EmployerReducer.Profile.profile_image ?
                                                `${SERVER_PATH}${this.props.EmployerReducer.Profile.profile_image}`
                                                : '/images/img-1.png'}
                                            className="avatar mr-2 text-center" />
                                    </div>
                                    <div className="mb-2 font-18">
                                        <span className="outr-profile-text">
                                            {this.props.t('login.hi', { param: 'react' }).trimEnd()}, {this.capitalizeFirstLetter(this.props.EmployerReducer.Profile.firstname)}</span>
                                        <br></br>{Mainprops.route.path === "/myaccount" ? this.props.t('dashboard.WelcomeDashboard', { param: 'react' }) : this.props.t('dashboard.clickForDashboard', { param: 'react' })}
                                    </div>
                                </div>
                            </Link> 
                        <li title={this.props.t('lsp_user.profileInst', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/myprofile' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/myprofile"><b>{this.props.t('sidebar.update_profile', { param: 'react' })}</b></Link>
                        </li>
                        <li title={this.props.t('lsp_user.postInst', { param: 'react' })} className={"nav-item list-group-item " + (Mainprops.route.path === '/post-service' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/post-service"><b>{this.props.t('lsp_user.post_services_title', { param: 'react' })}</b></Link>
                        </li>
                        <li title={this.props.t('lsp_user.calendarInst', { param: 'react' })} className={"nav-item list-group-item " + (Mainprops.route.path === '/visit-calendar' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/visit-calendar"><b>{this.props.t('lsp_user.calender', { param: 'react' })}</b>
                            </Link></li>
                        {/* {this.props.LspReducer.feedbackList && this.props.LspReducer.feedbackList.count && parseInt(this.props.LspReducer.feedbackList.count)  >= ALLOWED_FEEDBACK_COUNT ? */}
                            <li title={this.props.t('lsp_user.serviceFeedback', { param: 'react' })} className={"nav-item list-group-item " + (Mainprops.route.path === '/service-feedback' ? 'active' : '')}>
                                <Link className="nav-link"
                                    to="/service-feedback"><b>{this.props.t('lsp_user.feedback', { param: 'react' })}</b>
                                </Link></li>
                                 {/* : null} */}
                        {/*<li className={"nav-item list-group-item " + (Mainprops.route.path === '/jobfair' ? 'active' : '')}>
                            <Link className="nav-link"
                                  to="/jobfair"><b>{this.props.t('jobMela.name', {param: 'react'})}</b>
                                <img src={"/images/BLINK.gif"} alt={"New"} />
                            </Link></li> */}
                        {/* <li className={"nav-item list-group-item " + (Mainprops.route.path === '/portal-feedback' ? 'active' : '')}>
                            <Link className="nav-link"
                                  to="/portal-feedback"><b>{this.props.t('sidebar.portal_feedback', {param: 'react'})}</b></Link>
                        </li> */}

                        {/* <li className={"nav-item list-group-item " + (Mainprops.route.path === '/change-password' ? 'active' : '')}>
                            <a className="nav-link collapsed" href="#submenu1" data-toggle="collapse"
                               data-target="#submenu1"><b>{this.props.t('sidebar.settings', {param: 'react'})} </b></a>
                            <div className="collapse" id="submenu1" aria-expanded="false">
                                <ul className="flex-column pl-2 nav">
                                    <li className="nav-item list-group-item mb-2 p-2"><Link className="nav-link py-0"
                                    to="/change-password"><b>{this.props.t('sidebar.change_password', {param: 'react'})}</b></Link>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                    </ul>
                </div>

            </aside>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Sidebar));
