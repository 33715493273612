import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { getJobTitles } from '../actions/forgienproviderAction'
import Autocomplete from 'react-autocomplete'
// import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import store from "../store";
import ReactHtmlParser from 'react-html-parser';
import {
	get_education, Login, getNews, getNotifications, getJobsCount,
	getExperience, get_org_type, setRedirectionLink, get_User_District,
	getMobileAppData, getAllMelas,getEmployers,getGallaryvedio
} from "../actions/accountAction";
import { getRecommendedSession } from '../actions/counsellingproviderAction'
import { Link } from 'react-router'
import Validation from "./hoc/Validation";
import { Multiselect } from 'multiselect-react-dropdown';
import Slider from "react-slick";
import { getTimeWithAmPm } from '../helpers'
import ServerSetting from '../config.json';
class Home extends Component {

	constructor(props) {
		super(props)
		store.dispatch(get_education());
		store.dispatch(getExperience());
		store.dispatch(get_org_type());
		store.dispatch(getMobileAppData())
		store.dispatch(getAllMelas());
		store.dispatch(getRecommendedSession());
		store.dispatch(getJobsCount());
		store.dispatch(getNews())
		store.dispatch(getNotifications())
		store.dispatch(get_User_District());
		store.dispatch(getJobTitles())
		store.dispatch(getGallaryvedio())

		this.state = {
			formData: [],
			formData1: [],
			basePath: window.location.protocol + '//' + window.location.host + '/',
			hasProfileDetails: false,
			type: '',
			offset: 1,
			limit: 25,
			show: false,
			locations: '',
			select_limit: 3,
			submitLogin: false,
			videoUrl: '',
			district:'',
			end_date:'',
		}
		this.news = React.createRef()
		this.notification = React.createRef()
		this.events = React.createRef()

	}
    
	componentDidMount() {
		document.title = this.props.t('banner.ggr', { param: 'react' })
	}

	redirect = () => {
		this.setState({ show: false })
		document.body.classList.remove('modal-open');
		const data = this.state.eventData
		let link = data.id ? `counselling-details/${data.id}` : 'jobfair'
		link = data.event_id ? `myaccount?event=${data.event_id}` : link
		if (this.props.actionReducer.Loginuser && this.props.actionReducer.Loginuser.id) {
			if (this.props.actionReducer.Loginuser.user_type === '1') {
				this.props.router.push(link)
			}
		} else {
			store.dispatch(setRedirectionLink(link))
			this.props.router.push("/signin")
		}
	}

	getCombinedEvents = () => {
		const sessions = this.getSessions()
		const events = this.getEventList()
		const mela  = this.getJobMelaList()
		return [
			...sessions, ...events, ...mela]
	}

	getLocationFromIds = (location) => {
		const names = []
		if (this.props.actionReducer.District && this.props.actionReducer.District.length > 0 && location && location.length) {
			this.props.actionReducer.District.forEach((data) => {
				if (data.state_id === "3") {
					location.forEach(element => {
						let filterVal = data.dist.filter((district) => district.id === element)
						if (filterVal && filterVal[0]) {
							names.push(filterVal[0].name)
						}
					});
				}
			})
		}
		return names.length ? names.toString() : ''
	}

	getDate = (created_at) => {
		if (created_at) {
			let date = created_at.split(" ")[0]
			date = date.split("-")
			return `${date[2]}-${date[1]}-${date[0]}`
		}

	}

	submitSearch = async (e) => {
		e.preventDefault();
		// await store.dispatch(getSearchResults(this.state.formData));
		let queryString = ''
		// 		education_level: "2"
		// experience: "5"
		// job_type: "1"
		// location: "49"
		// const values = this.state.formData
		// this.state.formData.forEach((val, key) => {
		// 	console.log(val)
		// })
		for (let property in this.state.formData1) {
			// if(this.state.formData1[property] !== '0'){
			let value = this.state.formData1[property] !== '' ? this.state.formData1[property] : ''
			if (value) {
				let prefix = queryString !== '' ? '&' : ''
				if (property === "location" && value.length) {
					// console.log('value', value)
					const district = []
					value.forEach((dist) => {
						district.push(dist.id)
					})
					// console.log('district', district)
					value = JSON.stringify(district)
				}
				queryString += `${prefix}${property}=${value}`
			}

			// }

			// console.log(property + ": " + this.state.formData[property]);
		}

		// console.log('this.state.formData', `${queryString}`)
		this.props.router.push(`/search-results/?${queryString}`)
	}

	clicked = (job) => {
		this.props.router.push(`job-details-home/${job.id}`)
	}

	openVideoModal = videoUrl => {
		this.setState({ videoUrl })
	}

	handleProfileInput = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		let formData = this.state.formData1;
		formData[name] = value;
		if (name === "location" && value) {
			formData[name] = Array.from(e.target.selectedOptions, (item) => item.value)
		}
		if (name === "job_type" && value === '2') {
			delete formData['location']
			delete formData['experience']
		}

		this.setState({ formData1: formData });
		// this.setState({value: });
	}

	getJobMelaList = () => {
		const melas = []
		this.props.actionReducer.allMelas && this.props.actionReducer.allMelas.length
			&& this.props.actionReducer.allMelas.forEach((mela) => {
				if (mela.job_mela_reg_start_date) {
					let valid_from = mela.job_mela_reg_start_date.split("-")
					valid_from = new Date(`${valid_from[0]},${valid_from[1]}, ${valid_from[2]}`)
				melas.push({
					title: mela.job_mela_name.toUpperCase(),
					start: valid_from,
					end: valid_from,
					id: mela.id,
					type:'fair',
					district: mela.district,
					end_date: mela.end_date,
					
				})
			}
		})
	 return melas
	}

	// onSelectChange = (selectedList) => {
	// 	const { formData1 } = this.state
	// 	formData1['location'] = selectedList
	// 	this.setState({ formData1 })
	// }

	onSelectChange = (selectedList) => {
		let { formData1, select_limit } = this.state
		let filterDist1 = selectedList.filter((dist) => dist.id === '667')
		if (filterDist1 && filterDist1.length) {
			formData1['location'] = filterDist1;
			select_limit = 1;
			this.setState({ formData1, select_limit })
			return
		}

		let filterDist2 = selectedList.filter((dist) => dist.id === '666')
		if (filterDist2 && filterDist2.length) {
			formData1['location'] = filterDist2;
			select_limit = 1;
			this.setState({ formData1, select_limit })
			return
		}
		// if(filterDist && filterDist.length){
		// 	formData1['location'] = filterDist
		// } else {}
		// formData1['location'] = selectedList
		formData1['location'] = selectedList
		this.setState({ formData1 })
	}

	showModal = (event) => {
		// document.getElementById("test123").click()

		this.setState({ show: true, eventData: event })
		document.body.classList.add('modal-open');
	}

	gotoInterest = () => {
		if (this.props.actionReducer.Loginuser && this.props.actionReducer.Loginuser.id) {
			if (this.props.actionReducer.Loginuser.user_type === '1') {
				this.props.router.push(`interest`)
			}
		} else {
			store.dispatch(setRedirectionLink(`interest`))
			this.props.router.push("/signin")
		}
	}

	handleProfileInput1 = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		let formData = this.state.formData;
		formData[name] = value;
		this.setState({ formData, submitLogin: false });
	}

	createDistoption = () => {
		let Mainprops = this.props;
		let Distoption = []

		if (Mainprops.actionReducer.District.length > 0) {
			Mainprops.actionReducer.District.map(function (data, index) {
				if (data.state_name === 'Punjab') {
					data.dist.map(function (distdata, distindex) {
						// console.log(distdata)
						// Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
						Distoption.push({ name: distdata.name, id: distdata.id })
						return Distoption
					})
				}
				return Distoption
			})
		}
		return Distoption
	}
	redirectTo = () => {
		store.dispatch(setRedirectionLink(`jobfair`))
		this.props.router.push('/signin?action=jobfair')
	  }
	
	  redirectToSignup = () => {
		this.props.router.push('/signup?type=1')
	  }

	getTooltip = (val) => {
		// console.log(val)
		if (val.event_id) {
			return val.title + " (Event)"
		} else if (val.seat) {
			return val.title + " (Session)"
		} else {
			return val.title + " (Job Fair) " + val.district +" " + val.end_date
		}
	}

	createRows = () => {
		let table = []
		for (let i = 0; i < 20; i++) {
			table.push(<article key={'article_' + i} className="noo_job hentry">
				<div className="loop-item-wrap">

					<div className="loop-item-content">
						<h2 className="loop-item-title">
							<a href="/#" >Sales officer</a>
						</h2>
						<div className="content-meta">
							<span className="job-company">
								<a href="/#" >ECO INFRA</a>
							</span>
							<span className="job-type contract">
								<a href="/#">
									<i className="fa fa-bookmark"></i>Full Time                                                                                </a>
							</span>
							<span className="job-type contract">
								<a href="/#">
									<i className="fa fa-graduation-cap"></i>Any Graduate
																</a>
							</span>
							<span className="job-location">
								<i className="fa fa-map-marker"></i>
								<a href="/#"><em>Patiala</em></a>
							</span>
							<span>
								<time className="entry-date" dateTime="2015-08-18T01:40:23+00:00">
									<i className="fa fa-calendar"></i>Last Date:
																	2108-10-31                                                                                </time>
							</span>
							<span className="job-type contract">
								<a href="/#">
									<i className="fa fa-user" aria-hidden="true"></i>
									Posted By: Vikas Thakur
																</a>
							</span>
							<div className="show-view-more pull-right">
								<a className="btn btn-primary" target="_blank" href="/site/job-detail?id=160">
									View More </a>
							</div>
						</div>
					</div>
				</div>
			</article>)
		}
		return table
	}

	// async UNSAFE_componentWillReceiveProps(nextProps) {
	// 	if(nextProps.actionReducer.Loginuser && nextProps.actionReducer.Loginuser.id
	// 		&& !this.state.hasProfileDetails && this.props.actionReducer.homeLogin) {
	// 		if(this.props.actionReducer.Loginuser.user_type && this.props.actionReducer.Loginuser.user_type === '1') {
	// 			await store.dispatch(get_jobseeker_profile());
	// 		} else if( this.props.actionReducer.Loginuser.user_type && this.props.actionReducer.Loginuser.user_type === '2') {
	// 			store.dispatch(get_jobprovider_profile());
	// 		}
	// 		await this.setState({hasProfileDetails: true})
	// 	}
	// }


	redirectConditionally = () => {
		if (this.props.actionReducer.homeLogin) {
			if (this.props.actionReducer.link && this.props.actionReducer.Loginuser.user_type === '1') {
				window.location.href = this.state.basePath + this.props.actionReducer.link
				return
			} else {
				if (this.props.actionReducer.Loginuser && this.props.actionReducer.Loginuser.user_type &&
					(this.props.actionReducer.Loginuser.user_type === '7' || this.props.actionReducer.Loginuser.user_type === '10' ||
						this.props.actionReducer.Loginuser.user_type === '12')) {
					window.location.href = this.state.basePath + 'myprofile';
					return
				}
				window.location.href = this.state.basePath + 'myprofile'
			}
		}
	}

	getDate = (created_at) => {
		if (created_at) {
			let date = created_at.split(" ")[0]
			date = date.split("-")
			return `${date[2]}-${date[1]}-${date[0]}`
		}

	}

	handleSubmit = (event) => {
		event.preventDefault();
		const {
			username,
			password
		} = this.state.formData

		if (this.props.validator.fieldValid('username') && this.props.validator.fieldValid('password')
			&& !this.state.submitLogin) {
			store.dispatch(Login({ username, password }));
			this.props.validator.purgeFields()
			this.setState({ submitLogin: true })
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		} else {
			this.props.validator.showMessageFor('username');
			this.props.validator.showMessageFor('password');
			this.forceUpdate();
		}
	}

	//  showDescription = (val) => {
	// 	console.log('val', val.description)
	// 	this.setState({description: val.description})
	// 	document.getElementById("descModal").click()
	// }


	change = (id, e) => {
		let inputType = document.getElementById(id).type
		if (inputType === 'password') {
			document.getElementById(id).type = 'text'
			e.target.className = e.target.className.substring(0, e.target.className.lastIndexOf("-"));
		} else {
			document.getElementById(id).type = 'password'
			e.target.className += '-slash'
		}

	}

	getEventList = () => {
		const events = []

		this.props.actionReducer.allEvents && this.props.actionReducer.allEvents.length &&
			this.props.actionReducer.allEvents.forEach((e) => {
				if (e.start_date) {
					// let StrippedString = e.job_mela_description.replace(/(<([^>]+)>)/ig,"");
					let valid_from = e.start_date.split("-")
					valid_from = new Date(`${valid_from[0]},${valid_from[1]}, ${valid_from[2]}`)
					// let expire_on = e.end_date.split("-")
					// expire_on = new Date(`${expire_on[0]},${expire_on[1]}, ${expire_on[2]}`)
					events.push({
						title: e.event_name,
						start: valid_from,
						end: valid_from,
						description: e.description,
						event_id: e.id
					})
				}
			})
		// console.log('events', events)
		return events
	}

	setEducationJobTitle = (value) => {
		const formData1 = this.state.formData1
		let newValue = value
		if (this.props.forgienproviderReducer.jobTitles && this.props.forgienproviderReducer.jobTitles.length) {
			const filterValue = this.props.forgienproviderReducer.jobTitles.filter((job) => job.job_title === value)
			if (filterValue && filterValue.length) {
				newValue = filterValue[0].job_title
			}
		}
		formData1['job_title'] = newValue
		this.setState({ formData1 })
	}

	getAllEvents = () => {
		const events = []
		// console.log('this.props.actionReducer.allMelas', this.props.actionReducer.allMelas)
		this.props.actionReducer.allMelas && this.props.actionReducer.allMelas.length &&
			this.props.actionReducer.allMelas.forEach((e) => {
				if (e.mela_start_date_time && e.mela_end_date_time) {
					// let StrippedString = e.job_mela_description.replace(/(<([^>]+)>)/ig,"");
					let valid_from = e.mela_start_date_time.split("-")
					valid_from = new Date(`${valid_from[0]},${valid_from[1]}, ${valid_from[2]}`)
					let expire_on = e.mela_end_date_time.split("-")
					expire_on = new Date(`${expire_on[0]},${expire_on[1]}, ${expire_on[2]}`)
					events.push({
						title: e.job_mela_name,
						start: valid_from,
						end: expire_on,
						description: e.job_mela_description

					})
				}
			})
		return events
	}

	// redirectToMela = () => this.props.router.push('/job-placement-fair-employers')
	redirectToMela = () => { 
		// console.log(this.props.actionReducer.allMelas) 
	}

	getSessions = () => {
		const events = []
		// console.log('this.props.counsellingproviderReducer.recommendedSession ', this.props.counsellingproviderReducer.recommendedSession )
		this.props.counsellingproviderReducer.recommendedSession && this.props.counsellingproviderReducer.recommendedSession.length &&
			this.props.counsellingproviderReducer.recommendedSession.forEach((e) => {
				// console.log('e -> ', e)
				if (e.valid_from && e.expire_on) {
					let valid_from = e.valid_from.split("-")
					valid_from = new Date(`${valid_from[0]},${valid_from[1]}, ${valid_from[2]}`)
					let expire_on = e.expire_on.split("-")
					expire_on = new Date(`${expire_on[0]},${expire_on[1]}, ${expire_on[2]}`)
					// let StrippedString = e.description.replace(/(<([^>]+)>)/ig,"");
					events.push({
						id: e.id,
						title: e.title,
						start: valid_from,
						end: expire_on,
						description: e.description,
						fees: e.fees,
						seat: e.seat,
						timing_from: e.timing_from,
						timing_to: e.timing_to,
						valid_from: e.valid_from,
						expire_on: e.expire_on,
						district: e.district,
						contact: e.contact


					})
				}

			}

			)
		return events
	}

	showModal = (event) => {
		// console.log(event['type'])
		if(event['type'] ==='fair'){
			// store.dispatch(getAllMelas({ id: event['id'] }));
			store.dispatch(getEmployers({ offset: this.state.offset, start:event['start'], end:event['end'], limit: this.state.limit, mela_id: event['id']  }));
			this.props.router.push('/job-placement-fair-employers/'+event['id'] )
		} else {
			this.setState({ show: true, eventData: event })
			document.body.classList.add('modal-open');
		}
	}




	render() {
		const { validator } = this.props
		const localizer = momentLocalizer(moment)
		this.redirectConditionally()
		const distData = this.createDistoption()
		const combinedEvents = this.getCombinedEvents()
		const { type, show, eventData } = this.state
		const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;
		// const imagesList = [
		// 	"/images/Photos/1st ever Batch of Beekeeping started at RSETI Patiala with 25 budding Entrepreneurs mobilized by DBEE Patiala.jpg",
		// 	"/images/Photos/6th State Level Mega Rozgar Mela - Successful Candidates, Patiala.jpeg",
		// 	"/images/Photos/Address by Hon'ble CM - 5th mega job fair of PGRKAM.jpg",
		// 	"/images/Photos/Address by Hon'ble Minister EGT - 6th State Level Mega Rozgar Mela.jpeg",
		// 	"/images/Photos/Candidate during a Virtual Interview - 6th State Level Mega Job Fair.jpeg",
		// 	"/images/Photos/DBEE visit by the Hon'ble Minister.jpg",
		// 	"/images/Photos/Hon'ble CM giving appointment letters to students.jpg",
		// 	"/images/Photos/PGRKAM in Punjab Invest Summit 2019.jpeg",
		// 	"/images/Photos/Successful candidate receiving Appointment Letter from Hon'ble DC SAS Nagar.jpeg",
		// 	"/images/Photos/Successful Candidates - 6th State Level Mega Job Fair.jpeg",
		// 	"/images/Photos/Successful candidates with Apoointment Letters - 6th State Level Mega Rozgar Mela.jpeg",
		// ]
		const imagesList = [
			"/images/Photos/1.jpg",
			"/images/Photos/2.jpg",
			"/images/Photos/3.jpg",
			"/images/Photos/4.jpg",
			"/images/Photos/5.jpg",
			"/images/Photos/6.jpg",
			"/images/Photos/7.jpg",
			"/images/Photos/8.jpg",
			"/images/Photos/9.jpg",
			"/images/Photos/10.jpg",
			"/images/Photos/11.jpg",
			"/images/Photos/13.jpg",
			"/images/Photos/14.jpg",
			"/images/Photos/15.jpeg",
			"/images/Photos/16.jpeg",


		]

	 var testimonialList = this.props.actionReducer.getvedio
	//  console.log(this.props.actionReducer.getvedio);
			// { name: "Success Story : 7th State Level Mega Job Fair 2021", url: "hhttps://youtu.be/fAhwbBMYtW8" },
			// { name: "7th State Level Mega Job Fair 2021- Candidate Manisha", url: "https://youtu.be/2dDuXinhqFw" },
			// { name: "Success story of 7th State Level Mega Job Fair 2021- Candidate Jyoti", url: "https://youtu.be/anDIiJ8SP5A" },
			// { name: "Success Story of 7th State Level Mega Job Fair 2021- Candidate Radhika", url: "https://youtu.be/mWoEci2Ks1s" },
			// { name: "7th State Level Mega Job Fair 2021- Candidate Manju", url: "https://youtu.be/BMaANFRSFtM" },
			// { name: "7th State Level Mega Job Fair 2021", url: "https://youtu.be/z2dsr0U2A74" },
			// { name: "2500 teachers in Punjab got jobs and received their offer letters", url: "https://youtu.be/hF4xqD8bpTE" },
			// { name: "DBEE Hoshiarpur organised High End Banking/Financial sector Job Fair", url: "https://youtu.be/lie3xUOssMQ" },
			// { name: "Pankaj got placed as an Operator through DBEE Mohali", url: "https://youtu.be/NlEzmrSMXZg" },
			// { name: "Job Fair - DBEE Hoshiarpur", url: "https://youtu.be/UCULmbcOgY8" },
			// { name: "DBEE Rupnagar's candidate placed in Agile Herbals", url: "https://youtu.be/59BGA4Acokk" },
			// { name: "DBEE Sri Muktsar Sahib's success story video", url: "https://youtu.be/72dbFFWWKd8" },
			// { name: "Artificial Intelligence & Data Science Upscalling course from IIT Ropar", url: "https://youtu.be/vHrBupFP_eM" },
			// { name: "Pooja got placed at SBI LIFE by DBEE Sri Muktsar Sahib", url: "https://youtu.be/oH3Xxh5wWV4" },
			// { name: "Sandhya Kumari from Bihar sharing her happiness after visiting DBEE Hoshiarpur.", url: "https://youtu.be/Mofc_6AHCMQ" },
			// { name: "DBEE Hoshiarpur placed 60 candidates in Civil Hospital for Dengue Surveillance Project", url: "https://youtu.be/bQxlCHfZgUg" },

			// { name: "A NEW EMPLOYMENT CREATION INITIATIVE BY DBEE HOSHIARPUR: Mr. Clean (Car Washing Project)", url: "https://youtu.be/alvGiZQqD6U " },

			// { name: "DBEE Hoshiarpur gave Rozgar to 2 Deaf & Dumb Siblings (Sister & Brother)", url: "https://youtu.be/Zu8qNleFRgo" },
			// { name: "Feedback from Prabhjot Kaur for the Personality Development Course", url: "https://youtu.be/XY5F--AgyZ4" },
			// { name: "Rozgar App by DBEE Hoshiarpur -exclusive interview with DC, Hoshiarpur", url: "https://youtu.be/OBC6O3ClOvo" },
			// { name: "DBEE Hoshiarpur placed 60 candidates in Civil Hospital for Dengue Surveillance Project", url: "https://youtu.be/bQxlCHfZgUg" },

			// { name: "A NEW EMPLOYMENT CREATION INITIATIVE BY DBEE HOSHIARPUR: Mr. Clean (Car Washing Project)", url: "https://youtu.be/alvGiZQqD6U " },
		// ]

		const settings = {
			autoplay: true,
			autoplaySpeed: 3000,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 3,
			className: "slick-container",
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						initialSlide: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};
		const tiles = [
			{
				name: this.props.t('homeContent.jobs', { param: 'react' }),
				desc: this.props.t('homeContent.jobsDesc', { param: 'react' }),
				src: '/images/icon7.png',
				id: 'icon-modal'
			},
			{
				name: this.props.t('homeContent.skillTraining', { param: 'react' }),
				desc: this.props.t('homeContent.skillTrainingDesc', { param: 'react' }),
				src: '/images/icon5.png',
				link: '/aboutus',
				id: 'skill'
			},
			{
				name: this.props.t('homeContent.Counseling', { param: 'react' }),
				desc: this.props.t('homeContent.counselingDesc', { param: 'react' }),
				src: '/images/icon2.png',
				link: 'http://psdm.gov.in/',
				id: 'coun'
			},
			{
				name: this.props.t('homeContent.armedForces', { param: 'react' }),
				desc: this.props.t('homeContent.armedForcesDesc', { param: 'react' }),
				src: '/images/icon1.png',
				link: 'http://mbafpigirls.in/',
				id: 'local'
			},
			{
				name: this.props.t('menu.videos', { param: 'react' }),
				// desc: this.props.t('homeContent.armedForcesDesc', { param: 'react' }),
				src: '/images/youtube-icon.png',
				link: '/videos',
				id: 'local'
			},

			// {
			// 	name: this.props.t('homeContent.armedForces', { param: 'react' }),
			// 	desc: this.props.t('homeContent.armedForcesDesc', { param: 'react' }),
			// 	src: '/images/icon1.png'
			// },
			// {
			// 	name: this.props.t('homeContent.foreignEmployment', { param: 'react' }),
			// 	desc: this.props.t('homeContent.foreignEmploymentDesc', { param: 'react' }),
			// 	src: '/images/icon4.png'
			// },
			// {
			// 	name: this.props.t('homeContent.selfAssessment', { param: 'react' }),
			// 	desc: this.props.t('homeContent.selfAssessmentDesc', { param: 'react' }),
			// 	src: '/images/icon8.png'
			// },
			// {
			// 	name: this.props.t('homeContent.googleApp', { param: 'react' }),
			// 	desc: this.props.t('homeContent.googleAppDesc', { param: 'react' }),
			// 	src: '/images/icon3.png'
			// },
		]


		return (
			<div>
				<div className="clearfix"></div>
				<section className="top-slider">
					<div className="container">
						<div className="row">
							<div className="annoncement d-flex">
								<div className="col-md-2 col-sm-4 col-lg-2">
									{/* <h1 >ANNOUNCEMENTS</h1> */}
								</div>
								<div className="col-md-10 col-sm-8 col-lg-10">
									{/* <p><marquee>Welcome to PGRKAM Portal</marquee></p> */}
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="clearfix"></div>
				<section className="bg-banner">
					<div className="header-bg-top" >
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-4 col-sm-12 col-lg-2 mt-3">
									<div className="top-bg-form mt-1" style={{ 'padding': '16px','background':'transparent' }}>
										<img src="/images/art.png" alt={"cm"} style={{ 'width': '100%', 'marginTop':'24%' }} />
										{/* <p className="cm-name text-white mb-0 pt-2">{this.props.t('banner.Capt_Amarinder_Singh', { param: 'react' })}</p>
										<p className="cm-name text-white mb-0 ">{this.props.t('banner.Chief_Minister', { param: 'react' })}</p> */}
									</div>
								</div>

								<div id="icon-modal" className="modal fade">
									<div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
										<div className="modal-content d-block">
											{/*  <!--   <button type="button" className="close " data-dismiss="modal">&times;</button> -->
									<!-- Modal body --> */}
											{/* <div className="modal-body pt-3  pb-3 text-center">
																						<div className="row w-50 mx-auto d-flex">
												<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
													<div className="first-click">
														<img alt="" src="/images/mod2.png"
															 className="img-fluid pb-3 w-50"/>
														<p> If you want to view government jobs</p>
														<a type="button" href="govt-jobs" className="btn btn-danger mt-2">Click here</a>
													</div>
												</div>
												<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
													<div className="first-click">
														<img alt="" src="/images/mod1.png"
															 className="img-fluid pb-3 w-50"/>
														<p> If you want to view private jobs</p>
														<a type="button" href="private-jobs"
														   className="btn btn-danger mt-2">Click here</a>
													</div>
												</div>
											</div>
										</div> */}
										</div>
									</div>
								</div>
								<div className="modal fade" id="videosModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
									<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
										<div className="modal-content">
											<div className="modal-body">
												{/* {this.state.videoUrl && */}
												<iframe width="100%"
													height="400px"
													title="Video"
													src={this.state.videoUrl}
													frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
													allowFullScreen />
												{/* } */}
											</div>
										</div>
									</div>
								</div>
								<div id="testmodal" className="modal fade">
									<div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
										<div className="modal-content d-block">
											{/*  <!--   <button type="button" className="close " data-dismiss="modal">&times;</button> -->
									<!-- Modal body --> */}
											<div className="modal-body pt-3  pb-3 text-center">
												{/* <img alt="" src="/images/logo-new2.png"
												 className="img-fluid pb-3 home-top-logo"/> */}
												<h3 className="mx-auto mb-3 placement">Punjab Placement Fair</h3>
												<div className="abt_mela">
													<p className={"pl-3"}>A State level event ‘Punjab Placement Fair’ has been planned to be organized in the month of March’2020. This Placement Fair will target employment facilitation for jobs
														having annual pay package of INR 3 Lacs or above.</p>
													<a className=" ml-auto d-block edu-add" href={'job-placement-fair-employers'}>{this.props.t('homeContent.apply', { param: 'react' })}</a>

													<ul className={"text-left fair-date pl-3"}>
														<h6>The schedule is as under:</h6>
														<li>  Amritsar 12/13 March Amritsar Group of Colleges</li>
														<li>  Phagwara 17/18 March LPU</li>
														<li>  Bathinda 19/20 March MRSPTU</li>
														<li>  SAS Nagar 23/24 March Govt College; NIPER; ISB; CGC.</li>
														<li>  SBS Nagar 23/24 March Rayat Group of Institutions</li>
													</ul>
												</div>
												<div className="row w-100 mx-auto d-flex">
													<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
														<div className="first-click">
															<img alt="" src="/images/mod2.png"
																className="img-fluid pb-3 w-25" />
															<p> If you are already registered with this portal</p>
															<a type="button" href="signin" className="btn btn-danger mt-2">{this.props.t('homeContent.clickToLogin', { param: 'react' })}</a>
														</div>
													</div>
													<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
														<div className="first-click">
															<img alt="" src="/images/mod1.png"
																className="img-fluid pb-3 w-25" />
															<p> If you want to register with this portal</p>
															<a type="button" href="signup?type=1"
																className="btn btn-danger  mt-2">{this.props.t('homeContent.clickToRegister', { param: 'react' })}</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12 col-sm-12 col-lg-7">
								{/* <a href={'https://play.google.com/store/apps/details?id=com.pgrkam'} className="date-clr" ><img src="images/android-logo.png" style={{'width':'50%','marginLeft':'20%'}}/></a> */}
									<form onSubmit={this.submitSearch}>
										<div className="col-lg-12 col-xl-12 mt-3 mb-3 p-0 ">
											<div className={"mx-auto d-block w-100"}>
												<h4 style={{ 'color': '#fff' }}>{this.props.t('banner.fill_the_form_below_to_search_job', { param: 'react' })}</h4>
												<div className="row m-0">
													<div className="col-lg p-0">
														<select onChange={this.handleProfileInput}
															name={"job_type"} className=" search-slt">
															<option value={""}>{this.props.t('forms.jobType', { param: 'react' })}</option>
															{this.props.actionReducer.Orgtype && this.props.actionReducer.Orgtype.length ?
																this.props.actionReducer.Orgtype.map((org, key) => {
																	return (
																		<option value={org.id} key={key}>{org.name}</option>
																	)
																}) : ''}
														</select>


													</div>
													{this.state.formData1.job_type === '2' &&
														<div className="col-lg p-0">

															<select
																name="govtType"
																className="search-slt"
																value={this.state.formData1.govtType ? this.state.formData1.govtType : ''}
																onChange={this.handleProfileInput}
															>
																<option value={""}>{this.props.t('homeContent.govtType', { param: 'react' })}</option>
																<option value={"1"}>Government Of Punjab</option>
																<option value={"2"}>Government Of India/Other states</option>
															</select>
														</div>}


													<div className="col-lg p-0">

														<select
															onChange={this.handleProfileInput}
															className="search-slt"

															name="education_level">
															<option value={""}>{this.props.t('banner.select_qualification', { param: 'react' })}</option>
															{this.props.actionReducer.Educations.length > 0 &&
																this.props.actionReducer.Educations.map(function (data, index) {
																	return (
																		<option key={index} value={data.id}>{data.name}</option>
																	)
																})
															}
														</select>
													</div>
													{this.state.formData1.job_type !== '2' &&

														<div className="col-lg p-0">

															<select
																name="experience"
																className="search-slt"
																onChange={this.handleProfileInput}
															>
																<option value={""}>{this.props.t('forms.expUpto', { param: 'react' })}</option>
																{this.props.actionReducer.exps && this.props.actionReducer.exps.length &&
																	this.props.actionReducer.exps.map((exp, key) => {
																		return (
																			<option key={key} value={exp.id}>{exp.name}</option>
																		)
																	})
																}
															</select>
														</div>}
													{this.state.formData1.job_type !== '2' &&
														<div className="col-lg p-0">



															<Multiselect
																closeOnSelect={true}
																showCheckbox={true}
																selectionLimit={this.state.select_limit}
																hidePlaceholder={true}
																options={distData} // Options to display in the dropdown
																selectedValues={this.state.formData1.location && this.state.formData1.location.length ? this.state.formData1.location : []} // Preselected value to persist in dropdown
																onSelect={this.onSelectChange} // Function will trigger on select event
																onRemove={this.onSelectChange} // Function will trigger on remove event
																displayValue="name" // Property name to display in the dropdown options
																placeholder={this.props.t('JobPost.posting', { param: 'react' })}
															/>

														</div>
													}

												</div>

												<div className="row m-0">
													<div className="col-md-12 text-center">
														<p className="m-0" style={{ color: "#fff" }}>-----{this.props.t('banner.or', { param: 'react' })}-----</p>
													</div>
												</div>
												<div className="row m-0">
													<div className="col-lg p-0 test">

														<Autocomplete
															className="w-100 form-control"
															items={
																this.props.forgienproviderReducer.jobTitles}
															shouldItemRender={(item, value) =>
																item.job_title.toLowerCase().indexOf(value.toLowerCase()) > -1}


															renderItem={(item, isHighlighted) =>
																<div key={item.id}

																	style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
																	{item.job_title}
																</div>
															}
															renderInput={(props) => {
																return <input className="search-slt" placeholder={this.props.t('JobPost.search_placeholder', { param: 'react' })} {...props} />
															}}
															wrapperStyle={{ "width": "100%" }}
															menuStyle={{
																"zIndex": "9",
																"border": "1px solid #ccc",
																// "overflow": "hidden",
																// "overflowY": "hidden",
																"boxShadow": "1px 2px 10px #ccc",
																"padding": "4px",
																// "maxHeight": "123px",
																"fontSize": "14px"
															}}
															value={this.state.formData1.job_title ? this.state.formData1.job_title : ''}
															onChange={(e) => this.setEducationJobTitle(e.target.value)}
															getItemValue={(item) => item.job_title}
															onSelect={(val) => this.setEducationJobTitle(val)}
														/>
													</div>
												</div>
												<div className="row mt-2 mx-1">
													<div className="col-md-7 p-0 ">
														<button type="submit" className="pull-right btn btn-primary btn-primary2">
															{this.props.t('banner.search_job', { param: 'react' })}
														</button>
													</div>
													{/* Profile Interest */}
													{!Object.keys(this.props.actionReducer.Loginuser).length || (this.props.actionReducer.Loginuser
														&& this.props.actionReducer.Loginuser.user_type &&
														this.props.actionReducer.Loginuser.user_type === '1') ?
														<div className="col-md-5">
															<span className="date-clr pull-right pt-2" style={{ fontSize: "13px" }}> {this.props.t('banner.addProfileInterest', { param: 'react' })} <span style={{ cursor: "pointer", color: "#007bff" }} onClick={this.gotoInterest}>{this.props.t('interest.tellUs', { param: 'react' })} </span></span>
														</div>
														: null}
												</div>
											</div>
										</div>
									</form>
									<div className="row text-center mlr-7">
										<div className="col-6 col-sm-3 p-2 d-flex mt-1 mb-1"
										// onClick={() => this.props.router.push('govt-jobs')}
										>
											<div className="counter clr1 w-100 position-relative">
												{/* <i className="fa fa-university fa-1.5x" aria-hidden="true"></i> */}

												<h2 >{this.props.actionReducer.jobsCount &&
													this.props.actionReducer.jobsCount.govt_jobs}</h2>
												<hr></hr>
												<p className="count-text title-blocks" style={{ "color": "#000", "fontWeight": "700" }}>{this.props.t('banner.government_job', { param: 'react' })}</p>
											</div>
										</div>
										<div className="col-6 col-sm-3 p-2 d-flex mt-1 mb-1"
										// onClick={() => this.props.router.push('private-jobs')}
										>
											<div className="counter clr2 w-100 position-relative">
												{/* <i className="fa fa-briefcase fa-1.5x" aria-hidden="true"></i> */}

												<h2 >{this.props.actionReducer.jobsCount &&
													this.props.actionReducer.jobsCount.pvt_jobs}</h2>
												<hr></hr>
												<p className="count-text title-blocks" style={{ "color": "#000", "fontWeight": "700" }}>{this.props.t('banner.private_job', { param: 'react' })}</p>
											</div>
										</div>
										<div className="col-6 col-sm-3 p-2 d-flex mt-1 mb-1">
											<div className="counter clr3 w-100 position-relative">
												{/* <i className="fa fa-user-o fa-1.5x" aria-hidden="true"></i> */}

												<h2 >{this.props.actionReducer.jobsCount &&
													this.props.actionReducer.jobsCount.seekers}</h2>
												<hr></hr>
												<p className="count-text title-blocks" style={{ "color": "#000", "fontWeight": "700" }}>{this.props.t('banner.self_employment', { param: 'react' })}</p>
											</div>
										</div>
										<div className="col-6 col-sm-3 p-2 d-flex mt-1 mb-1">
											<div className="counter clr3 w-100 position-relative">
												{/* <i className="fa fa-user-o fa-1.5x" aria-hidden="true"></i> */}

												<h2>{this.props.actionReducer.jobsCount && this.props.actionReducer.jobsCount.employer}</h2>
												<hr></hr>
												<p className="count-text title-blocks" style={{ "color": "#000", "fontWeight": "700" }}>{this.props.t('homeContent.regEmp', { param: 'react' })}</p>
											</div>
										</div>
										{/* <div className="col-6 col-sm-2 p-2 d-flex mt-1 mb-1" style={{ "cursor": "pointer" }}>
											<div className="counter clr1 w-100 position-relative">
												{/* <i className="fa fa-university fa-1.5x" aria-hidden="true"></i> */}

										{/* <h6>Jobs For Women</h6>
												<hr></hr>

												<p className="count-text title-blocks" ><Link style={{  "fontWeight": "700" }} to="/search-results/?job_type=1&gender=1">Private</Link>
												<br></br>
												<Link style={{ "fontWeight": "700" }} to="/search-results/?job_type=2&gender=1">Government</Link>
												</p>
												
											</div>
										</div> */}
										{/* <div className="col-6 col-sm-3 p-2 d-flex mt-1 mb-1" style={{ "cursor": "pointer" }}
										 onClick={() => this.props.router.push('/search-results/?job_type=1&gender=1')}>
											<div className="counter clr1 w-100 position-relative">
												

												<h2></h2>
												<hr></hr>
												<p className="count-text title-blocks" style={{ "color": "#000", "fontWeight": "700" }}>{this.props.t('banner.private_job_women', { param: 'react' })}</p>
											</div>
										</div> */}
										{/* <div className="col-6 col-sm-2 p-2 d-flex mt-1 mb-1">
											<div className="counter clr3 w-100 position-relative">

												<h2 >{this.props.actionReducer.jobsCount &&

													this.props.actionReducer.jobsCount.total_govt}</h2>
												<hr></hr>
												<p className="count-text title-blocks" style={{ "color": "#000", "fontWeight": "700" }}>{this.props.t('homeContent.totalGovtJobs', { param: 'react' })}</p>

											</div>
										</div>
										<div className="col-6 col-sm-2 p-2 d-flex mt-1 mb-1">
											<div className="counter clr3 w-100 position-relative">

												<h2 >{this.props.actionReducer.jobsCount && this.props.actionReducer.jobsCount.total_pvt}</h2>
												<hr></hr>
												<p className="count-text title-blocks" style={{ "color": "#000", "fontWeight": "700" }}>{this.props.t('homeContent.totalPvtJobs', { param: 'react' })}</p>
											</div>
										</div> */}
										{/* <div className="pl-2 pt-2 w-100 text-center"><p style={{ "color": "#fff	", "fontSize": "1.3rem" }}>
											{this.props.t('homeContent.labourInst', { param: 'react' })} <Link className="date-clr" to="/labour-info">
												<b>{this.props.t('homeContent.clickHere', { param: 'react' })}</b></Link></p></div> */}
									</div>
									{/* <div id="demo" className="carousel slide" data-ride="carousel">
									<ul className="carousel-indicators">
										<li data-target="#demo" data-slide-to="0" className="active"></li>
										<li data-target="#demo" data-slide-to="1"></li>
										<li data-target="#demo" data-slide-to="2"></li>
									</ul>
									<div className="carousel-inner">
										<div className="carousel-item active">
											<img src="/images/bg1.jpg" alt="Los Angeles" width="100%" height="auto" />
										</div>
										<div className="carousel-item">
											<img src="/images/bg1.jpg" alt="Chicago" width="100%" height="auto" />
										</div>
										<div className="carousel-item">
											<img src="/images/bg1.jpg" alt="New York" width="100%" height="auto" />
										</div>
									</div>
								</div> */}
								</div>
								{!this.props.actionReducer.Loginuser.length && !this.props.actionReducer.Loginuser.id &&
									<div className="col-md-4 col-sm-12 col-lg-3">
										<div className="top-bg-form mt-3">
											<div className="register_button"><Link style={{ 'color': '#000', 'fontWeight': '700', 'textTransform': 'uppercase' }} to="/signup">{this.props.t('banner.register_free', { param: 'react' })}</Link>
												<sup ><img alt="register free" src="/images/FREE.gif" width="40" className="pl-1" /></sup></div>
											<div className="bdr " style={{ 'color': '#000' }}><span className="pull-left"></span>{this.props.t('banner.or', { param: 'react' })}<span className="pull-right"></span></div>
											<form className="form-group" onSubmit={this.handleSubmit}>
												{/* <!-- <label className="form-control" style="background: linear-gradient(135deg,#9c110a 0%,#d8861e 100%);color: #fff;text-align: center;">Login</label> --> */}
												<div className="row">
													<div className="col-lg-12 col-md-12 col-sm-12">
														<input type="text" onChange={this.handleProfileInput1}

															defaultValue={this.state.formData.username} className="form-control form-custom mb-1" name="username" placeholder="Enter username" />
														{validator.message('username', this.state.formData.username, 'required')}
													</div>
													<div className="col-lg-12 col-md-12 col-sm-12">
														<input type="password" onChange={this.handleProfileInput1}
															className="form-control form-custom mb-1 form-eye"
															defaultValue={this.state.formData.password}

															name="password" id={"passswordField"} placeholder="Enter password" />
														{validator.message('password', this.state.formData.password, 'required')}
														<span onClick={(e) => this.change('passswordField', e)}
															className="home-input fa fa-fw field-icon toggle-password fa-eye-slash"
														></span>
													</div>
												</div>
												<div className="d-block login-form-btn ml-auto mt-1">
													<Link to="/resetpassword">{this.props.t('banner.forgot_password', { param: 'react' })}</Link> | <Link to="/signin-with-otp">{this.props.t('banner.login_using_otp', { param: 'react' })}</Link>
												</div>
												<button type="submit" className="cls_register_signin_homepage border-0 text-center mx-auto d-block mt-3" style={{ 'color': '#000' }}>{this.props.t('banner.login', { param: 'react' })}</button>
												{/* <a href={'https://play.google.com/store/apps/details?id=com.pgrkam'} className="date-clr" ><img src="images/android-logo.png" style={{'width':'50%','marginLeft':'20%'}}/></a> */}
											</form>
										</div>


									</div>}
								{/* {(!localStorage.getItem('Islogin') || localStorage.getItem('Islogin') === 'false') &&
								<div className="col-md-4 col-sm-12 col-lg-2 ">
									<div className="carrer-box"></div>
									<div className="addvert1">
										<div className="row">
											<span>Already a member</span> <a href="/signin"><button className="btn btn-warning btn-sm ">Sign In Here</button></a>
										</div>
										<div className="row">
											<span>If you are not a member please register first</span> <a href="/signup"><button className="btn btn-warning btn-sm mx-auto">Register Here</button></a>
										</div>
									</div>
								</div>
							} */}
							</div>
						</div>
					</div>
				</section>
				<div className="clearfix"></div>

				<section className="main-page " id="main-content" >
					<div className="modal fade bd-example-modal-sm" tabIndex="-1" id="locationModal"
						role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-sm">
							<div className="modal-content">
								<div className="modal-header">
									<h4 className="modal-title" id="mySmallModalLabel">{this.props.t('homeContent.locations', { param: 'react' })}</h4>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">×</span>
									</button>
								</div>
								<div className="modal-body">
									<ul className="loc-lisiting">
										{this.state.locations && this.state.locations.length ? this.state.locations.map((loc, key) => {
											return (
												<li key={key}>
													<span >{loc}</span>
												</li>)
										})
											: ''}

									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid">

						<div className="row pt-3 pb-3 ">
							{/* <div className="col-md-9 col-sm-12 col-lg-9 col-xl-9 text-center animated fadeInUp slow"> */}


							<div className="col-md-9 col-sm-12 col-lg-9 col-xl-9 text-center">
								<div className="cover-shadow  mb-4 padingdiv">


									{/* {tiles.map((tile, key) => {
							return (
								<div key={key} id={tile.id} className="col-md-4 col-sm-6 col-lg-3 bg-shadow mt-3 mb-2 p-2 animated bounceIn slow">
									<a href={tile.link} className="ternary_btn" target={'_blank'}><img alt="" src={tile.src} className="img-fluid mx-auto" /></a>
									<div className="quilcklinks_outer">
										<h2>{tile.name}</h2>
									</div>
								</div>
							)
})} */}
									<div id="servicemodal" className="modal fade">
										<div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
											<div className="modal-content d-block" style={{ 'background': 'linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%)' }}>
												{/*  <!--   <button type="button" className="close " data-dismiss="modal">&times;</button> -->
<!-- Modal body --> */}
												<div className="modal-body pt-3  pb-3 text-center">
													{/* <img alt="" src="/images/logo-new2.png"
			 className="img-fluid pb-3 home-top-logo"/> */}
													{/* <h3 className="mx-auto mb-3 placement">Punjab Placement Fair</h3> */}
													{type === 'coun' &&
														<div className="row w-100 mx-auto d-flex">
															<div className="col-md-6 col-lg-12 col-sm-6 mb-2 d-flex ">
																<div className="first-click">
																	<img alt="" src="/images/mod2.png"
																		className="img-fluid pb-3 w-25" />
																	<p> {this.props.t('homeContent.careerCounselling', { param: 'react' })}</p>
																	<a rel="noopener noreferrer" target={'_blank'} type="button" href="http://psdm.gov.in/"
																		className="btn btn-danger mt-2">{this.props.t('homeContent.clickHere', { param: 'react' })} </a>
																</div>
																<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
																	<div className="first-click">
																		<img alt="" src="/images/mod1.png"
																			className="img-fluid pb-3 w-25" />
																		<p> {this.props.t('homeContent.vocationalCounselling', { param: 'react' })}</p>
																		<a rel="noopener noreferrer" target={'_blank'} type="button" href="http://psdm.gov.in/"
																			className="btn btn-danger mt-2">{this.props.t('homeContent.clickHere', { param: 'react' })} </a>
																	</div>
																</div>
															</div>
														</div>
													}
													{type === 'armed' &&
														<div className="row w-100 mx-auto d-flex">
															<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
																<div className="first-click">
																	<img alt="" src="/images/mod2.png"
																		className="img-fluid pb-3 w-25" />
																	<p> {this.props.t('homeContent.armed1', { param: 'react' })}</p>
																	<a rel="noopener noreferrer" target={'_blank'} type="button" href="http://afpipunjab.org/"
																		className="btn btn-danger mt-2">{this.props.t('homeContent.clickHere', { param: 'react' })} </a>
																</div>
															</div>
															<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
																<div className="first-click">
																	<img alt="" src="/images/mod1.png"
																		className="img-fluid pb-3 w-25" />
																	<p> {this.props.t('homeContent.armed2', { param: 'react' })}</p>
																	<a rel="noopener noreferrer" target={'_blank'} type="button" href="http://mbafpigirls.in/"
																		className="btn btn-danger  mt-2">{this.props.t('homeContent.clickHere', { param: 'react' })} </a>
																</div>
															</div>
														</div>}

													{type === 'jobs' &&
														<div className="row w-100 mx-auto d-flex">
															<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
																<div className="first-click">
																	<img alt="" src="/images/mod2.png"
																		className="img-fluid pb-3 w-25" />
																	<p> {this.props.t('homeContent.viewAllGovtJovs', { param: 'react' })}</p>
																	<a rel="noopener noreferrer" target={'_blank'} type="button" href="/govt-jobs"
																		className="btn btn-danger  mt-2">{this.props.t('homeContent.clickHere', { param: 'react' })} </a>
																</div>
															</div>
															<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
																<div className="first-click">
																	<img alt="" src="/images/mod1.png"
																		className="img-fluid pb-3 w-25" />
																	<p> {this.props.t('homeContent.viewAllPvtJobs', { param: 'react' })}</p>
																	<a rel="noopener noreferrer" target={'_blank'} type="button" href="/private-jobs"
																		className="btn btn-danger  mt-2">{this.props.t('homeContent.clickHere', { param: 'react' })} </a>
																</div>
															</div>
														</div>}
													{type === 'women' &&
														<div className="row w-100 mx-auto d-flex">
															<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
																<div className="first-click">
																	<img alt="" src="/images/mod2.png"
																		className="img-fluid pb-3 w-25" />
																	<p> {this.props.t('banner.private_job_women', { param: 'react' })}</p>
																	<a rel="noopener noreferrer" target={'_blank'} type="button" href="/search-results/?job_type=1&gender=1"
																		className="btn btn-danger  mt-2">{this.props.t('homeContent.clickHere', { param: 'react' })} </a>

																</div>
															</div>
															<div className="col-md-6 col-lg-6 col-sm-6 mb-2 d-flex ">
																<div className="first-click">
																	<img alt="" src="/images/mod1.png"
																		className="img-fluid pb-3 w-25" />
																	<p> {this.props.t('banner.government_job_women', { param: 'react' })}</p>
																	<a rel="noopener noreferrer" target={'_blank'} type="button" href="/search-results/?job_type=2&gender=1"
																		className="btn btn-danger  mt-2">{this.props.t('homeContent.clickHere', { param: 'react' })} </a>
																</div>
															</div>
														</div>}
												</div>
											</div>

										</div>
									</div>
									<div className="row">
										<div data-toggle="modal" data-target="#servicemodal"
											onClick={() => this.setState({ type: 'jobs' })} id={tiles[0].id}
											className="col-md-3 col-sm-6 col-lg-3 mt-3 mb-2 p-2">
											<div className="bg-shadow animated bounceIn slow col-md-12">
												<Link className="ternary_btn"><img alt="" src={tiles[0].src} className="img-fluid mx-auto" /></Link>
												<div className="quilcklinks_outer">
													<h2>{tiles[0].name}</h2>
												</div>
											</div>
										</div>

										
										<div id={tiles[1].id} className="col-md-3 col-sm-6 col-lg-3 mt-3 mb-2 p-2">
											<div className="bg-shadow animated bounceIn slow col-md-12">
												<a rel="noopener noreferrer" href={tiles[1].link} className="ternary_btn" target={'_blank'}><img alt="" src={tiles[1].src} className="img-fluid mx-auto" /></a>
												<div className="quilcklinks_outer">
													<h2>{tiles[1].name}</h2>
												</div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-lg-3 mt-3 mb-2 p-2" style={{ cursor: "pointer" }}>
											<div className="bg-shadow animated bounceIn slow col-md-12">
												<a rel="noopener noreferrer" href={"/self-employment"} className="ternary_btn" target={'_blank'}><img alt="" src={tiles[0].src} className="img-fluid mx-auto" /></a>
												<div className="quilcklinks_outer">
													<h2>{this.props.t('homeContent.selfEmp', { param: 'react' })}</h2>
												</div>
											</div>
										</div> 
										
										<div className="col-md-3 col-sm-6 col-lg-3 mt-3 mb-2 p-2" style={{ cursor: "pointer" }}>
										<div className="bg-shadow animated bounceIn slow col-md-12">
											<Link to="/local-services"><img alt="Local services" src={'/images/service-ico.png'}
												className="img-fluid mx-auto" /></Link>
											<div className="quilcklinks_outer">
												<h2>{this.props.t('homeContent.localServices', { param: 'react' })}</h2>
											</div>
											</div>
										</div>

										

										<div onClick={() => this.setState({ type: 'women' })} data-toggle="modal" data-target="#servicemodal"
											className="col-md-3 col-sm-6 col-lg-3 mt-3 mb-2 p-2">
											<div className="bg-shadow animated bounceIn slow col-md-12">
												<Link className="ternary_btn">
													<img alt="job for women" src={'./images/jobforwoman2.png'} className="img-fluid mx-auto" /></Link>
												<div className="quilcklinks_outer">
													<h2>{this.props.t('banner.job_women', { param: 'react' })}</h2>
												</div>
											</div>
										</div>
										<div className="col-md-3 col-sm-6 col-lg-3 mt-3 mb-2 p-2" style={{ cursor: "pointer" }}>
											<div className="bg-shadow animated bounceIn slow col-md-12">
												<a rel="noopener noreferrer" href={"/search-results/?pwd=1"} className="ternary_btn" ><img alt="" src={"/images/disabled.png"} className="img-fluid mx-auto" /></a>
												<div className="quilcklinks_outer">
													<h2>{this.props.t('menu.jobsForPwd', { param: 'react' })}</h2>
												</div>
											</div>
										</div>
										<div onClick={() => this.setState({ type: 'armed' })} data-toggle="modal" data-target="#servicemodal"
											className="col-md-3 col-sm-6 col-lg-3 mt-3 mb-2 p-2">
											<div className="bg-shadow animated bounceIn slow col-md-12">
												<Link className="ternary_btn"><img alt="" src={tiles[3].src} className="img-fluid mx-auto" /></Link>
												<div className="quilcklinks_outer">
													<h2>{tiles[3].name}</h2>
												</div>
											</div></div>
									

										<div onClick={() => this.setState({ type: 'coun' })} id={tiles[2].id} style={{ cursor: "pointer" }}
											className="col-md-3 col-sm-6 col-lg-3 mt-3 mb-2 p-2">
											<div className="bg-shadow animated bounceIn slow col-md-12">
											<a rel="noopener noreferrer" href={'/counselling-sessions'}><img alt="" src={tiles[2].src} className="img-fluid mx-auto" /></a>
											<div className="quilcklinks_outer">
												<h2>{tiles[2].name}</h2>
												</div>
											</div>
										</div>

										{/* <div className="col-md-3 col-sm-6 col-lg-3 mt-3 mb-2 p-2" style={{ cursor: "pointer" }}>
										 <div className="bg-shadow animated bounceIn slow col-md-12">
											<a href="/career-opportunities"><img alt="self-employment" src={'/images/icon4.png'}
												className="img-fluid mx-auto" /></a>
											<div className="quilcklinks_outer">
												<h2>
													{this.props.t('career.title', { param: 'react' })}
												</h2>
												</div>
											</div>
										</div> */}

										
										
									
										
										{/* <div id={tiles[4].id}
											className="col-md-3 col-sm-6 col-lg-3 mt-3 mb-2 p-2">
											<div className="bg-shadow animated bounceIn slow col-md-12">
												<a rel="noopener noreferrer" href={tiles[4].link} className="ternary_btn" target={'_blank'}>
													<img alt="" src={tiles[4].src} className="img-fluid mx-auto" /></a>
												<div className="quilcklinks_outer">
													<h2>{tiles[4].name}</h2>
												</div>
											</div>
										</div> */}

										

									</div>
									<a rel="noopener noreferrer" href="https://pbemployment.punjab.gov.in/" className="service-btn date-clr text-uppercase mx-auto " target={'_blank'}>
										{this.props.t('homeContent.viewMoreEmp', { param: 'react' })}
										<i
											className="fa fa-angle-double-right ml-1" aria-hidden="true"></i></a>



									<div className="clearfix"></div>
						<h4 className="news-heading mb-3 mt-5">{this.props.t('footer.job_mela_and_session_calender', { param: 'react' })}</h4>
						<div className="">
							{show &&
								<div id="modal" className="">
									<div className="overly">
										<div className=" dialog-desc">
											<div className="modal-content">
												<div className="modal-header">
													<h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('counsellingProvider.details', { param: 'react' })}</h5>
													<button type="button" className="close" onClick={() => {
														document.body.classList.remove('modal-open')
														this.setState({ show: false })
													}}>
														<span aria-hidden="true">&times;</span>
													</button>      </div>
												<div className="modal-body" >
													{eventData.hasOwnProperty('fees') ?
														<div>
															<p >{this.props.t('counsellingProvider.session_name', { param: 'react' })}: <b>{eventData.title}</b></p>
															{eventData.valid_from && eventData.expire_on ? <p >{this.props.t('dashboard.table.date', { param: 'react' })}: <b>{this.getDate(eventData.valid_from)} - {this.getDate(eventData.expire_on)}</b></p> : null}
															{eventData.timing_from && eventData.timing_to ? <p >{this.props.t('counsellingProvider.timings', { param: 'react' })}: <b>{getTimeWithAmPm(eventData.timing_from)} - {getTimeWithAmPm(eventData.timing_to)}</b></p> : null}
															<hr></hr>
														</div>

														: null}

													<h6 >{this.props.t('dashboard.table.description', { param: 'react' })}</h6>
													<span style={{'marginRight':'40%'}}>{eventData && eventData.description ? ReactHtmlParser(eventData.description) : null} </span><br/><br/>
													<p style={{'display':'flex', 'alignItems':'center', 'justifyContent':'space-between'}}><span>District: <b> {eventData && eventData.district ? ReactHtmlParser(eventData.district) : null} </b> </span>
													<span >Helpline Number: <b> {eventData && eventData.contact ? ReactHtmlParser(eventData.contact) : null}</b> </span> </p>
												</div>

												<div className="modal-footer">
													{(!Object.keys(this.props.actionReducer.Loginuser).length || this.props.actionReducer.Loginuser.user_type === '1') ?
														<button type="button" className="btn btn-primary btn-primary2" onClick={() => this.redirect()}>{this.props.t('homeContent.apply', { param: 'react' })}
														</button>
														: null}
												</div>
											</div>
										</div>
									</div>
								</div>}
							<Calendar
								localizer={localizer}
								events={combinedEvents}
								startAccessor="start"
								className="jobmelaCal"
								endAccessor="end"
								onSelectEvent={(event) => this.showModal(event)}
								tooltipAccessor={(val) => this.getTooltip(val)}
								style={{ height: 500 }}
							/>
						</div>
									{/* <div className="text-left mt-4">
										<h4 className="news-heading">{this.props.t('jobSeekerPage.about.title', { param: 'react' })}</h4>

										<div className="panel-body3 mt-2">
											<div className="row">
												<div className="col-md-3 col-sm-3 col-lg-3 col-md-3">
													<img alt={"Logo 1"} src="/images/logo-1.png" className="img-fluid mx-auto d-block" />
												</div>
												<div className="col-md-9 col-sm-9 col-lg-9 col-md-9">
													<p className="about-heading text-bold">{this.props.t('jobSeekerPage.about.desc', { param: 'react' })} {this.props.t('jobSeekerPage.about.mou', { param: 'react' })} <a href="docs/moa_of_pgrkam.pdf"
														target="_blank">{this.props.t('jobSeekerPage.about.clickHere', { param: 'react' })}</a>.</p>
													<ul className="pl-3 pt-2 pb-2">
														<li>{this.props.t('jobSeekerPage.about.inst1', { param: 'react' })}</li>
														<li>{this.props.t('jobSeekerPage.about.inst2', { param: 'react' })}</li>
														<li>{this.props.t('jobSeekerPage.about.inst3', { param: 'react' })}</li>
													</ul>
													<p className={""}>{this.props.t('jobSeekerPage.about.footer', { param: 'react' })}</p>
												</div>
											</div>
										</div> */}

									{/* <h4 className="news-heading mb-2">{this.props.t('homeContent.services', { param: 'react' })}</h4> */}

									{/* </div> */}

								</div>

								<div className="clearfix"></div>
							</div>


							<div className="col-md-3 col-sm-12 col-lg-3 col-xl-3">
								{/* <h4 className="news-heading ">{this.props.t('banner.notifications', { param: 'react' })}</h4> */}
								{/* <div className="newstape"> */}
								{/* <marquee behavior="scroll" direction="up"> */}
								{/* <div className="newstape-content"> */}
								{/* <div className="news-block"> */}
								{/* <h3><span className="pin"><img alt={""} src="/images/BLINK.gif" style={{"height":"20px","width": "40px"}}/></span>{this.props.t('sidebar.job_mela', { param: 'react' })}</h3>
												<p>{mela && mela.job_mela_description}</p>
												<div className="text-right">
													<Link to="/job-placement-fair">More</Link>
												</div> */}
								{/* <p>Registration start: {mela && mela.job_mela_reg_start_date ? mela.job_mela_reg_start_date.split(" ")[0] : ''}</p>
					<p>Registration end: {mela && mela.job_mela_reg_end_date ? mela.job_mela_reg_end_date.split(" ")[0] : ''}</p>
					<p>Placement Fair start: {mela && mela.mela_start_date_time ? mela.mela_start_date_time.split(" ")[0] : ''}</p>
					<p>Placement Fair end: {mela && mela.mela_end_date_time ? mela.mela_end_date_time.split(" ")[0] : ''}</p> */}
								{/* <hr/> */}
								{/* </div> */}
								{/* </div> */}

								{/* <div className="newstape-content"> */}
								{/* <div className="news-block">
				<h3><span className="pin"><img src="/images/BLINK.gif" style={{"height":"20px","width": "40px"}}/></span>Corrigendum IV Revised EoI for Coaching/ training for Jobs for Government & Private sector</h3>
				<p><u><strong><a href="https://youtu.be/s9yrmDkiaYk" target="_blank">Click here to watch</a></strong></u></p>
				<div className="text-right">
					<a href="#">More</a>
				</div>
				<hr/>
				</div> */}
								{/* </div> */}

								{/* <div className="newstape-content"> */}
								{/* <div className="news-block">
					<h3><span className="pin"><img src="/images/BLINK.gif" style={{"height":"20px","width": "40px"}}/></span>Corrigendum-4 for Utilizing Social Media Platforms</h3>
					<p><u><strong><a href="https://youtu.be/s9yrmDkiaYk" target="_blank">Click here to watch</a></strong></u></p>
					<div className="text-right">
						<a href="#">More</a>
					</div>
					<hr/>
					</div> */}
								{/* </div> */}

								{/* <div className="newstape-content"> */}
								{/* <div className="news-block">
					<h3><span className="pin"><img src="/images/BLINK.gif" style={{"height":"20px","width": "40px"}}/></span>Corrigendum V for Punjab Job Helpline</h3>
					<p><u><strong><a href="https://youtu.be/s9yrmDkiaYk" target="_blank">Click here to watch</a></strong></u></p>
					<div className="text-right">
						<a href="#">More</a>
					</div>
					<hr/>
					</div> */}
								{/* </div> */}
								{/* </marquee> */}
								{/* </div> */}


								{/* <!-- second start --> */}



								{/* <h4 className="news-heading">{this.props.t('homeContent.disclaimer', { param: 'react' })}</h4>
								<div className="newstape">
									<p className="p-3">{this.props.t('homeContent.disclaimerText', { param: 'react' })}</p>
								</div> */}
								{/* <h4 className="news-heading">{this.props.t('banner.events', { param: 'react' })}</h4>
								<div className="newstape">
									<marquee scrolldelay="150" behavior="scroll" direction="up" ref={this.events}>
										<div className="newstape-content" onMouseLeave={(e) => this.events.current.start()} onMouseEnter={(e) => this.events.current.stop()}>
											{this.props.actionReducer.allMelas && this.props.actionReducer.allMelas.length ?
												<div className="news-block">
													{this.props.actionReducer.allMelas.map((mela, key) => {
														return (
															<div key={key}>
																<h3>{mela.job_mela_name}</h3>
																{mela && mela.job_mela_reg_start_date && <p>{this.props.t('jobMela.reg_date_start', { param: 'react' })}: {this.getDate(mela.job_mela_reg_start_date)}</p>}
																{mela && mela.job_mela_reg_end_date && <p>{this.props.t('jobMela.reg_date_end', { param: 'react' })}: {this.getDate(mela.job_mela_reg_end_date)}</p>}
																{mela && mela.mela_start_date_time && <p>{this.props.t('jobMela.fair_start', { param: 'react' })}: {this.getDate(mela.mela_start_date_time)}</p>}
																{mela && mela.mela_end_date_time && <p>{this.props.t('jobMela.fair_end', { param: 'react' })}: {this.getDate(mela.mela_end_date_time)}</p>}
																<hr />
															</div>
														)
													})
													}
												</div> : ""}
										</div>
									</marquee>
								</div> */}
								{/* <h4 className="news-heading">{this.props.t('banner.latest_news', { param: 'react' })}</h4> */}
								{/* <div className="newstape">
									<marquee scrolldelay="10" behavior="scroll" direction="up" ref={this.news}>
										<div className="newstape-content">
										{this.props.actionReducer.newsData && this.props.actionReducer.newsData.length ?
											<div className="news-block" onMouseLeave={() => this.news.current.start()} onMouseEnter={() => this.news.current.stop()}>

													{this.props.actionReducer.newsData.map((news, key) => {
														return (
															<div key={key}>
																<h3>{news.news_heading}</h3>
																<p>{news.news_description}</p>

																<a className="date-clr" target="_blank"
																	rel="noopener noreferrer" href={news.link}>{news.link}</a>
																<p>{this.props.t('JobPost.postedDate', { param: 'react' })}: {this.getDate(news.news_date)}</p>
																<hr />
															</div>
														)
													})}
												</div> : ""}
										</div>

									</marquee>
								</div> */}
								<h4 className="news-heading mt-1">{this.props.t('banner.notifications', { param: 'react' })}</h4>
								<div className="newstape">
									<marquee scrolldelay="10" behavior="scroll" direction="up" ref={this.notification}>
										<div className="newstape-content">
											
											{this.props.actionReducer.notifications && this.props.actionReducer.notifications.length ?
												<div className="news-block" onMouseLeave={() => this.notification.current.start()} onMouseEnter={() => this.notification.current.stop()}>
													{/* <div><b>Final Result of interviews to the post of Deputy Mission Director (Administration) - PGRKAM held on 23.12.21 at PSDM Chandigarh:</b>
														<p>1. Ms. Ila Sharma - Selected</p>
														<p>2. Ms. Kajal Dhand - First in waiting</p>
														<p>3. Mr. Ramandeep Sharma - Second in waiting </p>				
													</div>
													<hr /> */}
														{this.props.actionReducer.notifications.map((news, key) => {
														return (
															
															<div key={key}>
																<p><strong>{news.name}</strong></p>
																<a className="date-clr d-block" href={news.link} rel="noopener noreferrer" target="_blank">{this.props.t('homeContent.read', { param: 'react' })}</a>
																{news.link2 ? <a className="date-clr" href={news.link2} rel="noopener noreferrer" target="_blank">{this.props.t('homeContent.publicNotice', { param: 'react' })}</a> : null}
																<p>{this.props.t('JobPost.postedDate', { param: 'react' })}: {this.getDate(news.created_at)}</p>
																{/* <h3><span className="pin"><img alt="" src="/images/BLINK.gif" style={{ "height": "20px", "width": "40px" }} /></span>For Job Helpline and Assistance.</h3> */}
																{/* <p><u><strong><a href="tel:76260-76260" rel="noopener noreferrer" target="_blank">Call us at 76260-76260</a></strong></u></p> */}
																<hr />
															</div>
														)
													})}

													{/* } */}

												</div> : ""}
											{/* <div className="news-block">
												<h3><span className="pin"><img alt="" src="/images/BLINK.gif" style={{ "height": "20px", "width": "40px" }} /></span>For Job Helpline and Assistance.</h3>
												<p><u><strong><a href="tel:76260-76260" rel="noopener noreferrer" target="_blank">Call us at 76260-76260</a></strong></u></p>
												<div className="text-right">
													{/* <a href="#">More</a> */}
											{/* </div>
												<hr />
											</div>  */}
										</div>

									</marquee>
								</div>

								{this.props.actionReducer.mobileApp.hasOwnProperty('file_name') && this.props.actionReducer.mobileApp.file_name === 'Android_app' ? <>
									<h4 className="news-heading mt-1">{this.props.t('homeContent.downloads', { param: 'react' })}</h4>
									<div className="newstape">
										<marquee scrolldelay="10" behavior="scroll" direction="up" loop="" ref={this.news}>
										<div className="newstape-content" onMouseLeave={() => this.news.current.start()} onMouseEnter={() => this.news.current.stop()}>
										Emerging Technology- Strategy Blockchain<br/>
												 <a href={'docs/BlockchainStrategymerged.pdf'} className="date-clr" target="_blank">Download Emerging Technology- Strategy Blockchain</a><br/>
						
											</div> <hr/>
											<div className="newstape-content" onMouseLeave={() => this.news.current.start()} onMouseEnter={() => this.news.current.stop()}>
											Emerging Technology- Strategy AI, Responsible AI<br/>
												 <a href={'docs/AIStrategymerged.pdf'} className="date-clr" target="_blank">Download Emerging Technology- Strategy AI, Responsible AI</a><br/>
						
											</div> <hr/>
										<div className="newstape-content" onMouseLeave={() => this.news.current.start()} onMouseEnter={() => this.news.current.stop()}>
										State Holidays -2023<br/>
												 <a href={'docs/Holidays2023.pdf'} className="date-clr" target="_blank">Download State Holidays -2023</a><br/>
						
											</div> <hr/>
											
										<div className="newstape-content" onMouseLeave={() => this.news.current.start()} onMouseEnter={() => this.news.current.stop()}>
												Cyber security policy/ regulations/ guidelines <br/>
												 <a href={'http://punjabpolice.gov.in/PDFViwer.aspx?pdfFileName=~/writereaddata/UploadFiles/WhatsNew/Cyber%20Safety%20Punjabi.pdf '} className="date-clr" target="_blank">Click to Read (in Punjabi)</a><br/>
												 <a href={'http://punjabpolice.gov.in/PDFViwer.aspx?pdfFileName=~/writereaddata/UploadFiles/WhatsNew/Cyber%20Safety%20English.pdf '} className="date-clr" target="_blank">Click to Read (in English)</a>
											</div> <hr/>
											<div className="newstape-content" onMouseLeave={() => this.news.current.start()} onMouseEnter={() => this.news.current.stop()}>
												Downloadable Forms<br/>
												 <a href={'docs/X-1.pdf'} className="date-clr" target="_blank">Download X-1 form</a><br/>
												 <a href={'docs/X-10.pdf'} className="date-clr" target="_blank">Download X-10 form</a><br/>
												 <a href={'docs/ER-I.pdf'} className="date-clr" target="_blank">Download ER-I form</a><br/>
												 <a href={'docs/ER-II.pdf'} className="date-clr" target="_blank">Download ER-II form</a>
											</div> <hr/>
											 <div className="newstape-content" onMouseLeave={() => this.news.current.start()} onMouseEnter={() => this.news.current.stop()}>
											<br/> 
												 <a href={'docs/Annexure-A National Data and Analytics Platform.pdf'} className="date-clr" target="_blank">National Data and Analytics Platform – Vision Document from NITI Aayog 
</a><hr/>
												 <a href={'docs/Annexure-B NationalStrategyBCT_ Jan2021.pdf'} className="date-clr" target="_blank">National Strategy on Blockchain – MeitY, GoI </a><hr/>
												 <a href={'docs/Annexure-C Blockchain.pdf'} className="date-clr" target="_blank">Blockchain: The India Strategy – NITI Aayog</a><hr/>
												 <a href={'docs/Annexure-D NationalStrategy-for-AI.pdf'} className="date-clr" target="_blank">National Strategy for Artificial Intelligence #AIFORALL – NITI Aayog </a><hr/>
												 <a href={'docs/Annexure-E Responsible-AI.pdf'} className="date-clr" target="_blank"> Responsible AI #AIFORALL – NITI Aayog</a>
											</div> 
											<hr/>
											<div className="newstape-content" onMouseLeave={() => this.news.current.start()} onMouseEnter={() => this.news.current.stop()}>
												Web portal is now available on google play store. 
												 <a href={'https://play.google.com/store/apps/details?id=com.pgrkam'} className="date-clr" ><img src="images/android-logo.png" style={{'width':'50%','marginLeft':'20%'}}/></a>
											</div> 
											
											
										</marquee>
									</div>
								</> : null}
								{/* <h4 className="news-heading mt-1">{this.props.t('social.title', { param: 'react' })}</h4> */}
								{/* <div className="newstape"> */}
									{/* <marquee scrolldelay="10" behavior="scroll" direction="up" ref={this.notification}> */}
										<div className="mt-2">
										<div className="fb-page" data-href="https://www.facebook.com/PGRKAM/" data-tabs="timeline" data-width="500" data-height="425" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/PGRKAM/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/PGRKAM/">Punjab Ghar Ghar Rozgar And Karobar Mission</a></blockquote></div>
											
										</div>

									{/* </marquee> */}
								{/* </div>/ */}

							</div>
						</div>
					</div>
				</section>
				<div className="clearfix"></div>
				<section className="home-tabs animatedParent">
					<div className="container-fluid">
					<div className="text-left mt-2">
										{/* <h4 className="news-heading mb-2">{this.props.t('menu.gallery', { param: 'react' })}</h4>
										<div className="px-4 text-center mb-2">
											<Slider {...settings}>
												{imagesList.map((img, key) => {
													return (<img style={{ height: "156px" }} key={key} src={img} alt={`Gallery ${key + 1}`} />)
												})}
											</Slider>
											<a rel="noopener noreferrer" href="/gallery" className="service-btn date-clr text-uppercase" target={'_blank'}>
												{this.props.t('homeContent.viewMore', { param: 'react' })}
												<i
													className="fa fa-angle-double-right ml-1 mt-3" aria-hidden="true"></i></a>
										</div> */}
										<h4 className="news-heading mb-2">{this.props.t('menu.testimonials', { param: 'react' })}</h4>
										<div className="px-4 text-center mb-2 testimonial">
											 <Slider {...settings}>
												{testimonialList.map((video, key) => {
													// console.log(video.vedio)
													const videoId = video.vedio.slice(video.vedio.lastIndexOf('/') + 1)
													// console.log(videoId)
													return (
													// <span key={key}><img
													// 	src={`//img.youtube.com/vi/${videoId}/0.jpg`}
													// 	data-toggle="modal"
													// 	style={{ cursor: "pointer", height: "156px" }}
													// 	data-target="#videosModal"
													// 	onClick={() => this.openVideoModal(video.vedio)}
													// 	alt={`Testimonial ${key + 1}`} /><span className="title">{video.text}</span></span>
													<video width="400" controls >
														<source src={`videos/${videoId}`} type="video/mp4" />
														<source src={`videos/${videoId}`} type="video/ogg" />
													</video>
														
														)
												})}
											</Slider> 
												{/* <video width="400" controls>
												<source src="videos/VID-1.mp4" type="video/mp4"/>
												<source src="videos/VID-1.mp4" type="video/ogg"/>
												</video>
												<video width="400" controls>
												<source src="videos/VID-2.mp4" type="video/mp4"/>
												<source src="videos/VID-2.mp4" type="video/ogg"/>
												</video>
												<video width="400" controls>
												<source src="videos/VID-3.mp4" type="video/mp4"/>
												<source src="videos/VID-3.mp4" type="video/ogg"/>
												</video>
												<video width="400" controls>
												<source src="videos/VID-4.mp4" type="video/mp4"/>
												<source src="videos/VID-4.mp4" type="video/ogg"/>
												</video>
												 */}
												
										
										
										</div>
									</div>
						{/* <h4 className="news-heading">{this.props.t('jobSeekerPage.about.title', { param: 'react' })}</h4>
						<div className="row mt-2">
							<div className="col-md-3 col-sm-3 col-lg-3 col-md-3">
								<img alt={"Logo 1"} src="/images/logo-1.png" className="img-fluid mx-auto d-block" />
							</div>
							<div className="col-md-9 col-sm-9 col-lg-9 col-md-9">
								<p className="about-heading text-bold">{this.props.t('jobSeekerPage.about.desc', { param: 'react' })} {this.props.t('jobSeekerPage.about.mou', { param: 'react' })} <a href="docs/moa_of_pgrkam.pdf"
									target="_blank">{this.props.t('jobSeekerPage.about.clickHere', { param: 'react' })}</a>.</p>
								<ul className="pl-3 pt-2 pb-2">
									<li>{this.props.t('jobSeekerPage.about.inst1', { param: 'react' })}</li>
									<li>{this.props.t('jobSeekerPage.about.inst2', { param: 'react' })}</li>
									<li>{this.props.t('jobSeekerPage.about.inst3', { param: 'react' })}</li>
								</ul>
								<p className={""}>{this.props.t('jobSeekerPage.about.footer', { param: 'react' })}</p>
							</div>
						</div> */}
						{/* {this.props.actionReducer.allMelas && this.props.actionReducer.allMelas.length ?
							<>
								<h4 className="news-heading mt-3 mb-3">{this.props.t('footer.job_mela_calender', { param: 'react' })}</h4>
								<Calendar
									localizer={localizer}
									events={this.getJobMelaList()}
									onSelectEvent={this.redirectToMela()}
									startAccessor="start"
									className="jobmelaCal"
									endAccessor="end"
									style={{ height: 500 }}
								/>
							</> : null} */}



					</div>
				</section>



				<div className="clearfix"></div>
				<section className="client-logos text-center">
					<div className="container">
						<div className="row">
							<div className="col-md-2 col-sm-4 col-6 col-lg-2 d-flex">
								<a href="http://pmkvyofficial.org/" target="_blank" rel="noopener noreferrer">
									<img alt="" src="/images/banner01.png" className="img-fluid align-self-center mx-auto  mb-1" />
								</a>
							</div>

							<div className="col-md-2 col-sm-4 col-6 col-lg-2 d-flex">
								<a href="https://nsdcindia.org/" target="_blank" rel="noopener noreferrer">
									<img alt="" src="/images/banner02.png" className="img-fluid align-self-center mx-auto mb-1" />
								</a>
							</div>
							<div className="col-md-2 col-sm-4 col-6 col-lg-2 d-flex">
								<a href="https://msde.gov.in/" target="_blank" rel="noopener noreferrer">
									<img alt="" src="/images/banner03.png" className="img-fluid align-self-center mx-auto mb-1" />
								</a>
							</div>
							<div className="col-md-2 col-sm-4 col-6 col-lg-2 d-flex">
								<a href="https://india.gov.in/" target="_blank" rel="noopener noreferrer">
									<img alt="" src="/images/banner04.png" className="img-fluid align-self-center mx-auto mb-1" />
								</a>
							</div>
							<div className="col-md-2 col-sm-4 col-6 col-lg-2 d-flex">
								<a href="https://pbemployment.punjab.gov.in/" target="_blank" rel="noopener noreferrer">
									<img alt="" src="/images/banner05.png" className="img-fluid align-self-center mx-auto  mb-1" />
								</a>
							</div>
							<div className="col-md-2 col-sm-4 col-6 col-lg-2 d-flex">
								<a href="https://skillindia.nsdcindia.org/" target="_blank" rel="noopener noreferrer">
									<img alt="" src="/images/bann12.png" className="img-fluid align-self-center mx-auto mb-1" />
								</a>
							</div>
						
						</div>
					</div>
				</section>
				<div className="clearfix"></div>
				{/* <button type="button" id="serviceDescription1" style={{display:"none"}} className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}
				<div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog"
					aria-labelledby="myLargeModalLabel" id="descModal" aria-hidden="true">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							{this.state.description ? ReactHtmlParser(this.state.description) : ''}
						</div>
					</div>
				</div>

				 <div className="modal fade" id="homeModal" style={{ 'zIndex': '99999' }}>
					<div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
						<div className="modal-content d-block">
							


							<div className="modal-body p-0 text-center">
								<ul className="pull-left pull-left w-100 text-left p-0 m-0" style={{ paddingTop: 12 }}>
									<li className="list-inline-item pull-right"> <i className="fa fa-home mr-2" aria-hidden="true" ></i> <button type="button" style={{ 'color': 'rgb(178, 31, 45)', 'fontSize': '16px' }} className="close" data-dismiss="modal">Go to Homepage</button></li>
								</ul>


								<div className="d-flex align-items-center w-100 justify-content-center">
									<img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
								</div>
								<h5 className="mx-auto  text-uppercase mb-3">{this.props.t('homeContent.pgrkamTitle', { param: 'react' })}</h5>
								
								<div style={{ position: "relative" }}>
									<div className="abt_mela">
										<img alt="" src="images/logo-new2.png" className="img-fluid pb-3 home-top-logo top_log_job_mela" style={{ position: 'absolute', left: '40%' }} />
										<img alt="" src="/images/ghrghr.gif" className="img-fluid pb-2 jobmela_banner" />
										<div className="outerbtns">
                                            <div className="flexbtns ml-4">
                                                <a  className="mr-4" href="/signup">
                                                    <button
                                                        className={"btn btn-primart btn-primary2 print-btn mx-auto d-block mb-2 mt-2"}>
                                                        Register with portal
                                                                </button></a>
                                                <a  className="ml-4" href="/signin">
                                                    <button
                                                        className={"btn btn-primart btn-primary2 print-btn mx-auto d-block mb-2 mt-2"}>
                                                        Login into portal
                                                                </button></a>

                                            </div>
                                        </div>
										
										<ul className={"text-left fair-date pl-3"}>
											<li><b style={{ fontWeight: '700' }}>For assistance in participation, please contact the District Bureau office of your district, details of which are available</b> <a href="http://www.pgrkam.com/employment">here</a></li>
											
										</ul>
									</div>
								</div>
								<div className="row d-flex mt-3">
									

								</div>
							</div>



						</div>
					</div>
				</div> 
				<div className="modal fade" id="homeModal" style={{ 'zIndex': '99999' }}>
					<div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
						<div className="modal-content d-block">
							{/*  <!--   <button type="button" className="close " data-dismiss="modal">&times;</button> -->
							        <!-- Modal body --> */}


							<div className="modal-body pt-2  pb-1 text-center">
								<ul className="pull-left pull-left w-100 text-left p-0 m-0" style={{ paddingTop: 12 }}>
									<li className="list-inline-item pull-right"> <i className="fa fa-home mr-2" aria-hidden="true" ></i> <button type="button" style={{ 'color': 'rgb(178, 31, 45)', 'fontSize': '16px' }} className="close" data-dismiss="modal">Go to Homepage</button></li>
								</ul>


								<div className="d-flex align-items-center w-100 justify-content-center">
									<img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
								</div>
								<h5 className="mx-auto  text-uppercase mb-3">{this.props.t('homeContent.pgrkamTitle', { param: 'react' })}</h5>
								<div className="row d-flex">
									<div className="col-md-4 col-lg-4 col-sm-4 mb-3 d-flex">
										<div className="first-click">
											<img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
											<p> {this.props.t('homeContent.inst1', { param: 'react' })}</p>
											<a href="/signin" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickToLogin', { param: 'react' })}</a>
										</div>
									</div>
									<div className="col-md-4 col-lg-4 col-sm-4 mb-3 d-flex">
										<div className="first-click">
											<img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
											<p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst2', { param: 'react' })}</p>
											<a href="/signup" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickToRegister', { param: 'react' })}</a>
										</div>
									</div>

									{/* <div className="col-md-4 col-lg-4  col-sm-4 mb-3 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/mod4.png" className="img-fluid pb-2 w-25" />
											<p > {this.props.t('homeContent.inst3', { param: 'react' })} </p>
											<a className="btn btn-danger pt-1 pb-1" href="/job-placement-fair-employers">{this.props.t('homeContent.clickHere', { param: 'react' })}</a>

										</div>
									</div> */}


									<div className="col-md-4 col-lg-4  col-sm-4 mb-3 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/already-reg.png" className="img-fluid pb-2 w-25" />
											<p>{this.props.t('homeContent.dbeeAndHelpline', { param: 'react' })}</p>
											<a href="/resetpassword" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickHere', { param: 'react' })}</a>

										</div>
									</div>

									{/* <div className="col-md-4 col-lg-4  col-sm-4 mb-3 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/enquery.png" className="img-fluid pb-2 w-25" />
											<p> {this.props.t('homeContent.inst5', { param: 'react' })}</p>
											<a href="/resetpassword" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickHere', { param: 'react' })}</a>

										</div>
									</div> */}

									{/* <div className="col-md-4 col-lg-4  col-sm-4 mb-3 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/mod3.png" className="img-fluid pb-2 w-25" />
											<p className={"pl-4 pr-4"}>{this.props.t('homeContent.inst6', { param: 'react' })}</p>
											<Link className="btn btn-danger text-white pt-1 pb-1" data-dismiss="modal">{this.props.t('homeContent.clickHere', { param: 'react' })}</Link>
										</div>
									</div> */}
									{/* <div className="col-md-4 col-lg-4  col-sm-4 mb-3 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
											<p> {this.props.t('homeContent.inst1', { param: 'react' })}</p>
											<a href="/signin" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickToLogin', { param: 'react' })}</a>
										</div>
									</div>
									<div className="col-md-4 col-lg-4 col-sm-4 mb-3 d-flex">
										<div className="first-click">
											<img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
											<p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst2', { param: 'react' })}</p>
											<a href="/signup" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickToRegister', { param: 'react' })}</a>
										</div>
									</div>

									<div className="col-md-4 col-lg-4  col-sm-4 mb-3 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/mod4.png" className="img-fluid pb-2 w-25" />
											<p > {this.props.t('homeContent.inst3', { param: 'react' })} </p>
											<a className="btn btn-danger pt-1 pb-1"
												href="/job-placement-fair-employers">{this.props.t('homeContent.clickHere', { param: 'react' })}</a>

										</div>
									</div>


									<div className="col-md-4 col-lg-4  col-sm-4 mb-3 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/already-reg.png" className="img-fluid pb-2 w-25" />
											<p>{this.props.t('homeContent.inst4', { param: 'react' })}</p>
											<a href="/resetpassword" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickHere', { param: 'react' })}</a>

										</div>
									</div>

									<div className="col-md-4 col-lg-4  col-sm-4 mb-3 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/enquery.png" className="img-fluid pb-2 w-25" />
											<p> {this.props.t('homeContent.inst5', { param: 'react' })}</p>
											<a href="/resetpassword" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickHere', { param: 'react' })}</a>

										</div>
									</div>

									<div className="col-md-4 col-lg-4  col-sm-4 mb-3 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/mod3.png" className="img-fluid pb-2 w-25" />
											<p className={"pl-4 pr-4"}>{this.props.t('homeContent.inst6', { param: 'react' })}</p>
											<Link className="btn btn-danger text-white pt-1 pb-1" data-dismiss="modal">{this.props.t('homeContent.clickHere', { param: 'react' })}</Link>
										</div>
									</div> */}

								</div>
							</div>



						</div>
					</div>
				</div>
				<div className="modal fade intro-modal " id="jobMelaModal" style={{ 'zIndex': '99999' }}>
                    <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
                        <div className="modal-content d-block bg-dark2">
                            <div className="modal-body pt-2  pb-1 text-center">
                               
                                <div className="d-flex align-items-center w-100 justify-content-center">
                                    <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                </div>

                                <h5 className="mx-auto  text-uppercase mb-3">{this.props.t('jobMela.pgrkam', { param: 'react' })}</h5>
                                <div className="row d-flex">
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                        <div className="first-click">
                                            <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                                            <p> {this.props.t('homeContent.inst1', { param: 'react' })}</p>
                                            <Link to="/signin" className="btn btn-danger pt-1 pb-1" onClick={this.closeModal} >{this.props.t('homeContent.clickToLogin', { param: 'react' })}</Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                        <div className="first-click">
                                            <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                                            <p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst2', { param: 'react' })}</p>
                                            <Link to="/signup" className="btn btn-danger pt-1 pb-1" onClick={this.closeModal}>Click to Register</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

			 {/* <div className="modal fade" id="jobMelaModal" style={{ 'zIndex': '9999999999999' }}>
                    <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
                        <div className="modal-content d-block">
                            <div className="modal-body p-0 text-center" style={{ overflow: "hidden" }}>
                                <div className="position-relative">
                                    <img alt=""
                                        src={this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.webImageLink ? `${SERVER_PATH}${this.props.actionReducer.activeJobMela.webImageLink}` : ''}
                                        // src='/images/Option-1.gif'
                                        className="img-fluid pb-0 jobmela_banner w-100" />
                                    <div className="jobmelotr">
                                         <div className="jobmela_inner">
                                            <Link className="blinkingtxt-btn lft_btn" data-dismiss="modal"
                                                data-target="#registerModal1" data-toggle="modal"><img src="/images/apply.jpg" alt="Apply button" /></Link>


                                            <a className="blinkingtxt-btn lft_btn" href="/job-placement-fair-employers/636"><img src="/images/for_vacancies.jpg" alt="View jobs button" /></a>
											<a className="blinkingtxt-btn lft_btn" href="/employment" style={{fontSize:'14px'}}>Click Here to View DBEE</a>
										</div> 
                                        {/* <ul className="text-left fair-date mb-0 wrapcls btm_mess">
                                            <li>
                                                 <a href="/employment">{this.props.t('homeContent.clickHere', { param: 'react' })}</a>
                                            </li>
                                        </ul> */}
										
                                    {/* </div></div>
                            </div>
                        </div>
                    </div>
                </div>  */} 
				<div className="modal fade intro-modal" id="registerModal1" style={{ 'zIndex': '99999' }}>
          <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
            <div className="modal-content d-block">
              <div className="modal-body pt-2  pb-1 text-center">
                <div className="d-flex align-items-center w-100 justify-content-center">
                  <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                </div>
                <h5 className="mx-auto  text-uppercase mb-3">{this.props.t('homeContent.pgrkamTitle', { param: 'react' })}</h5>
                <div className="row d-flex">
                  <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                    <div className="first-click">
                      <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                      <p> {this.props.t('homeContent.inst1', { param: 'react' })}</p>
                      <Link className="btn btn-danger pt-1 pb-1"
                        onClick={this.redirectTo} data-dismiss="modal" style={{"color":"#ffff"}}>{this.props.t('homeContent.clickToLogin', { param: 'react' })}</Link>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                    <div className="first-click">
                      <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                      <p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst2', { param: 'react' })}</p>
                      <Link onClick={this.redirectToSignup} style={{"color":"#ffff"}} className="btn btn-danger pt-1 pb-1" data-dismiss="modal">{this.props.t('homeContent.clickToRegister', { param: 'react' })}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 

				<div className="modal fade" id="guestModal" style={{ 'zIndex': '99999' }}>
					<div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
						<div className="modal-content d-block">
							{/*  <!--   <button type="button" className="close " data-dismiss="modal">&times;</button> -->
										<!-- Modal body --> */}
							<div className="modal-body pt-3  pb-3 text-center" style={{ 'background': 'linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%)' }}>
								<img alt="" src="/images/logo-new2.png" className="img-fluid pb-3 home-top-logo" />
								<h2 className="mx-auto mb-4">PUNJAB GHAR GHAR ROZGAR</h2>
								<div className="row d-flex">
									<div className="col-md-3 col-lg-3 col-sm-6 mb-2 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/mod2.png" className="img-fluid pb-3 w-50" />
											<p> About Portal</p>
											<a type="button" href="signin" className="btn btn-danger mt-2" >Click Here</a>

										</div>
									</div>
									<div className="col-md-3 col-lg-3 col-sm-6 mb-2 d-flex ">
										<div className="first-click">
											<img alt="" src="/images/mod1.png" className="img-fluid pb-3 w-50" />
											<p> If You want to Register with this Portal</p>
											<a type="button" href="signup" className="btn btn-danger mt-2" >Click Here</a>
										</div>
									</div>
									<div className="col-md-3 col-lg-3 col-sm-6 mb-2 d-flex">
										<div className="first-click">
											<img alt="" src="/images/mod4.png" className="img-fluid pb-3 w-50" />
											<p> If You want to continue as a guest</p>

											<a className="btn btn-danger mt-2" href="/industrylist">Click Here</a>
										</div>
									</div>
									<div className="col-md-3 col-lg-3 col-sm-6 mb-2 d-flex">
										<div className="first-click">
											<img alt="" src="/images/mod3.png" className="img-fluid pb-3 w-50" />
											<p> If You want to go home page</p>
											<button type="button" className="btn btn-danger mt-2" data-dismiss="modal">Click Here</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>



			</div>

		);
	}
}

const mapStateToProps = store => {
	return store;
};
export default connect(mapStateToProps)(translate(Validation(Home)));
