import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { browserHistory } from 'react-router'

class Help extends Component {
    
    componentDidMount(){
        document.title = this.props.t('homeContent.employment', { param: 'react' })
    }

    render() {
        return (
            <div className="tab-content" style={{ "border": "none" }}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">Help
                                        <span className="pull-right" onClick={browserHistory.goBack} style={{color:"#fff", cursor:"pointer"}}>{this.props.t('jobSearch.goBack', { param: 'react' })}</span></h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <div className="row">
                                       
                                           
                                            <div className="col-md-6" style={{'marginLeft':'0%', 'marginTop':'2%','fontSize':'18px'}}>
                                            
                                            <ul className="m-0">
                                                <li className={"d-flex"}> <h4>Helpline numbers(PGRKAM-Mohali) </h4></li><hr></hr>
                                                    <li className={"d-flex "}><i className="fa fa-phone pr-2 pt-1" aria-hidden="true" ></i> <p>01725011184,</p></li>
                                                    <li className={"d-flex "}><i className="fa fa-phone pr-2 pt-1" aria-hidden="true" ></i> <p>01725011185,</p></li>
                                                    <li className={"d-flex "}><i className="fa fa-phone pr-2 pt-1" aria-hidden="true" ></i> <p>01725011186</p></li>
                                                    <li className={"d-flex "}><i className="fa fa-Envelope pr-2 pt-1" aria-hidden="true" ></i> <p>pgrkam.degt@gmail.com</p></li>
                                                <li className={"d-flex"}> <h4>Procedure regarding ER-I and ER-II form </h4></li><hr></hr>
                                                    <li className={"d-flex "}><p>How to fill ER-I & ER-II form: <a href="docs/how_to_fill_ER1_and_ER2.pdf" style={{'color':'#f7971a','fontSize':'13px','fontWeight':'600'}}>Download Instructions</a></p></li>
                                                    <li className={"d-flex "}><p>ER-I form: <a href="docs/ER-I.pdf" style={{'color':'#f7971a','fontSize':'13px','fontWeight':'600'}}>Download </a></p></li>
                                                    <li className={"d-flex "}><p>ER-II form: <a href="docs/ER-II.pdf" style={{'color':'#f7971a','fontSize':'13px','fontWeight':'600'}}>Download</a></p></li>
                                                <li className={"d-flex"}> <h4>Procedure regarding X-1 and X-10 form </h4></li><hr></hr>
                                                    <li className={"d-flex "}><p>How to fill X-1 & X-10 form: <a href="docs/how_to_fill_X1_and_X10.pdf" style={{'color':'#f7971a','fontSize':'13px','fontWeight':'600'}}>Download Instructions</a></p></li>
                                                    <li className={"d-flex "}><p>X-1 form: <a href="docs/X-1.pdf" style={{'color':'#f7971a','fontSize':'13px','fontWeight':'600'}}>Download </a></p></li>
                                                    <li className={"d-flex "}><p>X-10 form: <a href="docs/X-10.pdf" style={{'color':'#f7971a','fontSize':'13px','fontWeight':'600'}}>Download</a></p></li>
                                                <li className={"d-flex"}> <h4>Service Delivery Charter & Timelines </h4></li><hr></hr>
                                                    <li className={"d-flex "}><p><a href="docs/service_delivery_timelines.pdf" style={{'color':'#f7971a','fontSize':'13px','fontWeight':'600'}}>Download Instructions</a></p></li>

                                            
                                            </ul>
                                            </div>
                                            <div className="col-md-6">
                                            <img  width="100%" src="./images/helpline-nos.jpeg" 
                                            alt="Employment"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Help));
