import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-multi-lang'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

import {
    get_update_Education,
    get_jobseekerEducations,
    getMarital,
    get_update_Physical,
    get_jobseekerAddress,
    getReligion,
    getCategory,
    getRelation,
    getCapacity,
    get_update_profile,
    get_delete_Education,
    getDisability,
    get_jobseekerPhysical,
    get_update_address,
    getBloodGroups,
    getEyeSight,
    getExperience,
    get_delete_Experience,
    get_update_Experience,
    addAdditionalSkill, 
    getAdditionalSkills,
    setProgress,
    setProfileTabSubmitted,
    get_jobseeker_profile
} from '../../../actions/jobseekerAction';
import {
    get_User_Subistrict,
    get_User_Cities,
    get_User_Institution,
    get_User_Language,
    get_User_Qualification,
    // get_User_Specilization,
    get_User_Coursenature,
    get_skills,
    getEducationLevel,
    get_education,
    get_selected_User_District,
    setNotification,
    get_User_District,
    get_genders,
    getConstituency,
    showinterest
} from '../../../actions/accountAction';

import {
    get_job_shift, get_Salary_Type
} from "../../../actions/jobpostAction";
import store from '../../../store';
import Sidebar from './Sidebar';
import ServerSetting from '../../../config.json';
import SimpleReactValidator from 'simple-react-validator';
import {Link} from 'react-router'
import DatePicker from "react-datepicker";
import Autocomplete from 'react-autocomplete'
// import Multiselect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css';
// import { TableHeaderColumn } from 'react-bootstrap-table';
// import { setPostArchive } from '../../../actions/employerAction';

const VALID_AGE = 14
class Myprofile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect:false,
            Profile: [],
            Physical: [],
            Addresses: [],
            Education: [],
            sundirectoryload: false,
            disability: '0',
            formData: [],
            profileSubmitted:true,
            Experience: [],
            skill: [],
            error: '',
            errorGender: '',
            requiredPref: false,
            profileCompleted: false,
            edit: false,
            multiSelect:[],
            data:[],
            still_working: '2',
            errorDateFrom:'',
            errorDateTo:'',
            errorDoc:'',
            dobError:'',
            submitted: true,
            submitStatus: true,
            delOption:'',
            delIndex:'',
            submitted2:true,
            submitted1:true,
            physicalTabStatus: false,
            expTabStatus:false,
            addressTabStatus: false,
            eduTabStatus:true,
            submitPhysical: true,
            expUpdated: true,
            expUpdated1: false,
            profileFieldsSet1: false,
            profileFieldsSet2:true,
            profileFieldsSet3: false,
            profileFieldsSet4: true,
            addressFieldSet1:false,
            addressFieldSet2:true,
            deletedAddress: true,
            deletedProfile: true,
            deletedProfile1:true,
            imgSrc:'',
            loginid:'',
        }
        this.education = React.createRef();
        this.experience = React.createRef();
        this.skills = React.createRef();
        this.institue = React.createRef();
        store.dispatch(getConstituency())
        store.dispatch(get_jobseekerAddress()); 
        store.dispatch(getMarital());
        store.dispatch(get_genders());
        store.dispatch(getReligion());
        store.dispatch(getCategory());
        store.dispatch(getRelation());
        store.dispatch(getBloodGroups());
        store.dispatch(getEyeSight());
        store.dispatch(getAdditionalSkills());
        store.dispatch(getDisability());
        store.dispatch(getCapacity());
        store.dispatch(get_User_Language());
        store.dispatch(get_jobseekerEducations());
        store.dispatch(get_User_Institution());
        store.dispatch(get_User_Qualification());
        store.dispatch(get_skills());
        store.dispatch(get_selected_User_District())
        store.dispatch(get_User_Coursenature());
        store.dispatch(get_jobseeker_profile())
        store.dispatch(get_jobseekerPhysical());
        store.dispatch(get_job_shift());
        store.dispatch(get_Salary_Type());
        store.dispatch(getExperience());
        store.dispatch(get_User_District());
        store.dispatch(getEducationLevel());
        store.dispatch(get_education());
        store.dispatch(setProgress(20));
        store.dispatch(showinterest());

        this.certificateRef = React.createRef()
        this.allowedDocFormats = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ]
        this.imageFormats = [
            'image/png',
            'image/jpg',
            'image/jpeg'
        ]
        this.eduValidator = new SimpleReactValidator({
            element: message => <div style={{"color": "red"}}>{message}</div>
        });
        this.comValidator = new SimpleReactValidator({
            element: message => <div style={{"color": "red"}}>{message}</div>
        });
        this.expValidator = new SimpleReactValidator({
            element: message => <div style={{"color": "red"}}>{message}</div>
        });
        this.profileValidator = new SimpleReactValidator({
            element: message => <div style={{"color": "red"}}>{message}</div>
        });
         this.PhysicalValidator = new SimpleReactValidator({
            element: message => <div style={{"color": "red"}}>{message}</div>
        });

        this.skillValidator = new SimpleReactValidator({
            element: message => <div style={{"color": "red"}}>{message}</div>
        });
       
    }

     async UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({'Profile': nextProps.JobseekerReducer.Profile});
        this.setState({'Physical': nextProps.JobseekerReducer.Physical});
        this.setState({'Addresses': nextProps.JobseekerReducer.Addresses});
        if (nextProps.JobseekerReducer.Physical.disablity_type_id && nextProps.JobseekerReducer.Physical.disablity_type_id !== "0") {
            this.setState({disability: "1"});
        }
        if (!nextProps.JobseekerReducer.Physical.disablity_type_id || nextProps.JobseekerReducer.Physical.disablity_type_id === "0") {
            this.setState({disability: "0"});
        }
        if (nextProps.JobseekerReducer.Addresses.current.hasOwnProperty('district_id') && !this.state.sundirectoryload) {
            store.dispatch(get_User_Subistrict(nextProps.JobseekerReducer.Addresses.current.district_id));
            store.dispatch(get_User_Cities(nextProps.JobseekerReducer.Addresses.current.subdistrict_id));
            this.setState({sundirectoryload: true});
        }
        if (nextProps.JobseekerReducer.Addresses.permanent.hasOwnProperty('district_id') && !this.state.sundirectoryload) {
            store.dispatch(get_User_Subistrict(nextProps.JobseekerReducer.Addresses.permanent.district_id, 'permanent'));
            store.dispatch(get_User_Cities(nextProps.JobseekerReducer.Addresses.permanent.subdistrict_id, 'permanent'));
            this.setState({sundirectoryload: true});
        }
        if(nextProps.JobseekerReducer.experience && nextProps.JobseekerReducer.experience.data &&
            nextProps.JobseekerReducer.experience.data.length){
                this.setState({experienced:'1'})
            } else {
               this.setState({experienced:'0'})
            }
        const Profile = this.state.Profile
        if (Profile.prefered_district && Profile.prefered_district === '2') {
            this.setState({requiredPref: true})
        }
         this.changeTabStatus(nextProps)
        
    }

    moveToNextTab = (nextProps) => {
        // console.log('this.state.redirect', this.state.redirect)
        if(nextProps.JobseekerReducer.profileTabSubmitted) {
            if(nextProps.JobseekerReducer.profileTabSubmitted === 'profile'){
                setTimeout(() => {
                    document.getElementById("menuTab2").click()
                },1000)
            } else if (nextProps.JobseekerReducer.profileTabSubmitted === 'address'){
                setTimeout(() => {
                    document.getElementById("menuTab3").click()
                },1000)
                
            } else if (nextProps.JobseekerReducer.profileTabSubmitted === 'physical'){
                setTimeout(() => {
                    document.getElementById("menuTab4").click()
                },1000)
                
            } else if (nextProps.JobseekerReducer.profileTabSubmitted === 'education' && this.state.redirect){
                setTimeout(() => {
                    document.getElementById("menuTab5").click()
                    this.setState({redirect: false})
                }, 1000)
            } 
            
            store.dispatch(setProfileTabSubmitted(''))
        }
        
    }

    changeTabStatus = async (nextProps) => {
        //Address tab % calculations
        if(nextProps.JobseekerReducer.Addresses && nextProps.JobseekerReducer.Addresses.current &&
            nextProps.JobseekerReducer.Addresses.current.district_id && nextProps.JobseekerReducer.Addresses.current.state_id &&
            nextProps.JobseekerReducer.Addresses.current.subdistrict_id && nextProps.JobseekerReducer.Addresses.current.pincode &&
            (nextProps.JobseekerReducer.Addresses.current.city || nextProps.JobseekerReducer.Addresses.current.village)
            && !this.state.physicalTabStatus) {

                this.setState({physicalTabStatus: true})

                store.dispatch(setProgress(nextProps.JobseekerReducer.progress + 10))
                return
        } 

        if(nextProps.JobseekerReducer.Addresses && nextProps.JobseekerReducer.Addresses.current && 
            Object.keys(nextProps.JobseekerReducer.Addresses.current).length > 0){

            if(nextProps.JobseekerReducer.Addresses.current.address_Line1 && nextProps.JobseekerReducer.Addresses.current.address_Line3 &&
                nextProps.JobseekerReducer.Addresses.current.address_Line2 && nextProps.JobseekerReducer.Addresses.current.phone){
                if(!this.state.addressFieldSet1) {
                    this.setState({addressFieldSet1: true, deletedAddress:false})
                    store.dispatch(setProgress(nextProps.JobseekerReducer.progress + 10))
                    return
                } 
                    
        } else {
            
            if(!nextProps.JobseekerReducer.Addresses.current.address_Line1 || !nextProps.JobseekerReducer.Addresses.current.address_Line3 ||
                !nextProps.JobseekerReducer.Addresses.current.address_Line2 || !nextProps.JobseekerReducer.Addresses.current.phone){
                if(!this.state.addressFieldSet2 && !this.state.deletedAddress) {
                    this.setState({addressFieldSet2: true, deletedAddress: true})
                    store.dispatch(setProgress(nextProps.JobseekerReducer.progress - 10))
                    return
                }
            }
        }
        }

        if(nextProps.JobseekerReducer.Profile && nextProps.JobseekerReducer.Profile.firstname &&
            nextProps.JobseekerReducer.Profile.guardian_name && nextProps.JobseekerReducer.Profile.marital_status
            && nextProps.JobseekerReducer.Profile.employment_status && 
            ((!nextProps.JobseekerReducer.Profile.prefered_district || nextProps.JobseekerReducer.Profile.prefered_district ==='1') ||
            (nextProps.JobseekerReducer.Profile.prefered_district ==='2' && nextProps.JobseekerReducer.Profile.district1 && 
            nextProps.JobseekerReducer.Profile.district2 && nextProps.JobseekerReducer.Profile.district3))
            && !this.state.addressTabStatus){


            this.setState({addressTabStatus: true})
            store.dispatch(setProgress(nextProps.JobseekerReducer.progress + 10))
            return
        }
        // console.log('nextProps.JobseekerReducer.Profile', nextProps.JobseekerReducer.Profile)
        if(nextProps.JobseekerReducer.Profile && Object.keys(nextProps.JobseekerReducer.Profile).length > 0){
            //Field set 1
           
            if(nextProps.JobseekerReducer.Profile.email && nextProps.JobseekerReducer.Profile.middlename && 
                nextProps.JobseekerReducer.Profile.lastname && nextProps.JobseekerReducer.Profile.desired_salary){
                    if(!this.state.profileFieldsSet1) {
                        this.setState({profileFieldsSet1: true, deletedProfile: false})
                        store.dispatch(setProgress(nextProps.JobseekerReducer.progress + 5))
                        return
                    } 
                    
        } else {
            if(!nextProps.JobseekerReducer.Profile.email || !nextProps.JobseekerReducer.Profile.middlename || 
                !nextProps.JobseekerReducer.Profile.lastname || !nextProps.JobseekerReducer.Profile.desired_salary){
                    if(!this.state.profileFieldsSet2 && !this.state.deletedProfile) {
                        this.setState({profileFieldsSet2: true, deletedProfile: true})
                        store.dispatch(setProgress(nextProps.JobseekerReducer.progress - 5))
                        return
            }
    }
    }
        //Field set 2
        if(nextProps.JobseekerReducer.Profile.category && nextProps.JobseekerReducer.Profile.guardian_phone && 
                nextProps.JobseekerReducer.Profile.image && nextProps.JobseekerReducer.Profile.doc){
                    if(!this.state.profileFieldsSet3){
                        this.setState({profileFieldsSet3: true, deletedProfile1: false})
                        store.dispatch(setProgress(nextProps.JobseekerReducer.progress + 5))
                        return
                    } 
                    // else {
                        
                    // }
        } else {
            // this.setState({profileFieldsSet3: true})
            if(!nextProps.JobseekerReducer.Profile.category || !nextProps.JobseekerReducer.Profile.guardian_phone || 
                !nextProps.JobseekerReducer.Profile.image || !nextProps.JobseekerReducer.Profile.doc){
                    if(!this.state.profileFieldsSet4 &&  !this.state.deletedProfile1){
                        this.setState({profileFieldsSet4: true, deletedProfile1:true})
                        store.dispatch(setProgress(nextProps.JobseekerReducer.progress - 5))
                        return
                    }
        }
        }
        
        }

        

        if(nextProps.JobseekerReducer.Educations && nextProps.JobseekerReducer.Educations.length ){
            if(!this.state.expTabStatus) {
                this.setState({expTabStatus: true})
                store.dispatch(setProgress(nextProps.JobseekerReducer.progress + 20))
                return
            }
        } else {
            if(this.state.expTabStatus) {
                this.setState({expTabStatus: false})
                store.dispatch(setProgress(nextProps.JobseekerReducer.progress - 20))
            }
            
        }
       
        if(nextProps.JobseekerReducer.experience && nextProps.JobseekerReducer.experience.data 
            && nextProps.JobseekerReducer.experience.data && nextProps.JobseekerReducer.experience.data.length){
            if(!this.state.expUpdated1) {
                this.setState({expUpdated1: true})
                store.dispatch(setProgress(nextProps.JobseekerReducer.progress + 20))
            }
            
        } else {
            if(!this.state.expUpdated) {
                this.setState({expUpdated: true})
                store.dispatch(setProgress(nextProps.JobseekerReducer.progress - 20))
            }
        }
        this.moveToNextTab(nextProps)
    }

    componentWillUnmount(){
        // store.dispatch(expUpdated(false))
        // console.log('id:'+this.props.JobseekerReducer.Profile.user_id)
       
      
    }

    componentDidMount(){
        document.title = this.props.t('header.seekerProfile', { param: 'react' })
        // console.log('id:'+this.props.JobseekerReducer.Profile.user_id)
        // console.log(typeof localStorage.getItem('interest'))
        if(localStorage.getItem('interest') === "1"){
            console.log(2);
            window.$('#jobModal').modal('hide');
        } if(localStorage.getItem('interest') === "2"){
            console.log(3);
            window.$('#jobModal').modal('hide');
        }
        if(localStorage.getItem('interest') === 'null'){
            console.log(1);
            window.$('#jobModal').modal('show');
        }
        
    }

    checkForSubmittedForm = () => {
        const tabs = [
            {
                name: this.props.t('profileDetails.personalInfo', {param: 'react'}),
                value: this.state.profileSubmitted
            },
            {
                name: this.props.t('profileDetails.communication', {param: 'react'}),
                value: this.state.submitStatus
            },
            {
                name: this.props.t('profileDetails.educationTraning', {param: 'react'}),
                value: this.state.submitted
            },
            {
                name: this.props.t('education.additionalEducation', {param: 'react'}),
                value: this.state.submitted1
            },
            {
                name: this.props.t('profileDetails.experience', {param: 'react'}),
                value: this.state.submitted2
            },
            {
                name: this.props.t('profileDetails.physicalAttributes', {param: 'react'}),
                value: this.state.submitPhysical
            }
        ];
        tabs.forEach((t) => {
            if(!t.value){
                store.dispatch(setNotification({type: "warning", message: `You have unsaved changes in ${t.name} Tab`}));
            }
        })
    }

    
    handleyes=(e)=>{
        const value={show_interest: "1", id:this.props.JobseekerReducer.Profile.user_id};
        store.dispatch(showinterest(value));
        window.location.reload();
        // window.$('#jobModal').modal('hide');
       
    }

    handleno=(e)=>{
        const value={show_interest: "2", id:this.props.JobseekerReducer.Profile.user_id};
        store.dispatch(showinterest(value))
        window.location.reload();
        // window.$('#jobModal').modal('hide');


    }


    getSkillName = (skill) => {
        if (skill) {
            let valueArray = []
            let objects = JSON.parse("[" + skill + "]")
            objects = typeof objects[0] !== 'string' ? objects[0] : JSON.parse("[" + objects + "]")[0]
            if (objects && Array.isArray(objects)) {
                const value = objects
                if (typeof value !== 'number') {
                    value.forEach((elem) => {
                        if (typeof elem !== 'undefined') {
                            valueArray.push(elem.name)
                        }
                    })
                }
                return valueArray.join(", ")
            }
        }
    }

    handleExperienceRadio = (e) => {
        const value = e.target.value
        this.setState({experienced: value})
    }


    setDefaults = () => {
        if(this.props.JobseekerReducer.Profile.highest_education_level){
            const Education = this.state.Education
            Education['highest_education_level'] = this.props.JobseekerReducer.Profile.highest_education_level
            this.setState({Education})
        }
    }

    handleSkill = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;
        
        if(type==='text'){
            let regex = /^[A-Za-z0-9 ]+$/
            if(!regex.test(value) && value){
                return false
            }
        }
        let skill = this.state.skill
        if (e.target.type === 'number') {
            if (value.startsWith('0')) {
                value = ''
                e.target.value = ''
            }
            if (value.length > 2) {
                value = value.substr(2)
            }
        }
        skill[name] = value
        this.setState({skill, submitted1: false});
    }

    setEducationLevel = (data, val, key, valKey) => {
        // console.log([data, val, key, valKey])
        const Education = this.state.Education
        const MatchEdu = data && data.filter((edu) => edu.name === val)
        const value = MatchEdu && MatchEdu.length ? MatchEdu[0].id : ""
        Education[key] = value
        Education[valKey] =  val
        this.setState({Education, submitted: false})
    }

    
    setCourses = (data, val, key, valKey) => {
        const Education = this.state.Education
        const index = data.findIndex((d) => d.education_id === this.state.Education.highest_education_level)
        if(index !== -1){
            const MatchEdu =  data[index].qualification.filter((edu) => edu.name === val)
            const value = MatchEdu && MatchEdu.length ? MatchEdu[0].id : ""
            Education[key] = val === 'Others' ? '152' : value
            Education[valKey] = val
            this.setState({Education, submitted: false})
        }
    }

    clearInputOnBlur (key, keyVal) {
        const Education = this.state.Education
        // console.log(Education)
        if(Education && !Education.key){
            Education[keyVal] = ""
            this.setState({Education})
        }
    }

    async handleEditExperience(index) {
       
        const Experience = this.props.JobseekerReducer.experience.data[index] 
        
        if(Experience && Object.keys(Experience).length > 0 ){
            let dateTo = Experience.date_to.split('-')
            dateTo = `${dateTo[2]}-${dateTo[1]}-${dateTo[0]}`
            let dateFrom = Experience.date_from.split('-')
            dateFrom = `${dateFrom[2]}-${dateFrom[1]}-${dateFrom[0]}`
         
            Experience['date_from'] = dateFrom
            Experience['date_to'] = dateTo
            let objects = Experience.key_skills
            if(typeof Experience.key_skills === 'string'){
                objects = JSON.parse("[" + Experience.key_skills + "]")[0]
            } 
            Experience['key_skills'] = objects
            await this.setState({Experience, submitted:false, errorDateTo: '',
            errorDateFrom:'', btnText:this.props.t('profileDetails.update', {param: 'react'})});
        }
    }

    

    handleEditSkill = async (index) => {
        await this.setState({skill: this.props.JobseekerReducer.additionalSkills[index], edit: true, submitted: false});
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    handleChangePassingYear = (date) => {
        let formatedDate = moment(date).format('YYYY-MM-DD').split("-")
        if (formatedDate[0].length === 4) {
            const Education = this.state.Education
            formatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            Education['passing_year'] = formatedDate
            this.setState({Education});
        }
    }
    handleChangeDateFrom = (date, key) => {
        const Experience = this.state.Experience
        let formatedDate = moment(date).format('YYYY-MM-DD').split("-")
        
        let errorDateTo = ''
        let errorDateFrom = ''
        if(key === 'date_from' && Experience.date_to){
            let newFormatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            if(moment(newFormatedDate).isSameOrAfter(Experience.date_to)){
                errorDateFrom = 'Joining date must not be greater than or equal to the relieving date'
            } else {
                errorDateFrom = ''
                this.setDatePickerValues(formatedDate, key)
            }
            this.setState({errorDateFrom})
            
        } else if(key === 'date_to' && Experience.date_from) {
            let newFormatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            
            if(moment(newFormatedDate).isSameOrBefore(Experience.date_from)) {
                errorDateTo = 'Relieving date must not be less than or equal to the joining date'
            } else {
                this.setDatePickerValues(formatedDate, key)
                errorDateTo = ''
            }
            this.setState({errorDateTo})
            
        } else {
            this.setDatePickerValues(formatedDate, key)
        }
        
    }

    setDatePickerValues =(formatedDate, key) => {
        const Experience = this.state.Experience
        if (formatedDate[0].length === 4) {
            formatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            Experience[key] = formatedDate
            this.setState({Experience, submitted2:false});
        }
    }

    addExp = async () => {
        this.setState({Experience: [], submitted: false, btnText:this.props.t('profileDetails.add', {param: 'react'})})
        await this.expValidator.hideMessages()
    }

    handleChange = (date) => {
        let formatedDateMoment = moment(date).format('YYYY-MM-DD')
        let formatedDate = formatedDateMoment.split("-")
        const yearsDiff = moment().diff(formatedDateMoment, 'years', true)
        if(parseInt(yearsDiff) >= VALID_AGE){
            if (formatedDate[0].length === 4) {
                this.setState({dobError: ``})
                const Profile = this.state.Profile
                formatedDate = `${formatedDate[2]}-${formatedDate[1]}-${formatedDate[0].substring(0, 4)}`
                Profile['dob'] = formatedDate
                this.setState({Profile, profileSubmitted: false});
            }
        } else {
            this.setState({dobError: `Age must be ${VALID_AGE} or above`})
        }
    }

    saveExp = () => {
        store.dispatch(setNotification({type: "success", message: 'Updated successfully'}));
    }

    handleDeleteExperience(index) {
        let data = {};
        data.id = this.props.JobseekerReducer.experience.data[index].id;
        data.auth_key = localStorage.getItem('token');
        store.dispatch(get_delete_Experience(data));
        this.setState({'Experience': [], expUpdated: false});
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    handleDeleteSkill(id) {
        store.dispatch(getAdditionalSkills(id));
        store.dispatch(getAdditionalSkills())
        this.setState({'skill': []});
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    async handleSubmitExperience() {
        // if(!this.state.submitted){
            if(this.state.submitted2) {
                store.dispatch(setNotification({type:'warning', message:'No Are Changes Made'}))
                return
            }
            let Experience = this.state.Experience;
           
            
            if ( !this.state.submitted2 && !this.state.errorDateTo && !this.state.errorDateFrom &&
                 this.expValidator.allValid()) {
                Experience['auth_key'] = localStorage.getItem('token');
                this.setState({'Experience': Experience, submitted2: true});
                store.dispatch(get_update_Experience(this.state.Experience));
                await this.expValidator.hideMessages()
                this.setState({Experience: []})
                // document.getElementById("myModal413").click()
                // document.getElementById("menuTab6").click()
            } 
            else {
                this.expValidator.showMessages();
                this.forceUpdate();
            }
        // }
    }

    handleSubmitSkills = async (e) => {
        e.preventDefault()
        let skill = this.state.skill;
        if(!this.state.submitted1 && this.skillValidator.allValid()){
            skill['auth_key'] = localStorage.getItem('token');
            store.dispatch(addAdditionalSkill(skill))
            this.certificateRef.current.value = ''
            // this.skills.current.click()
            await this.skillValidator.hideMessages()
            this.setState({skill: [], edit: false, submitted1: true})    
            document.getElementById("menuTab5").click()
        } else {
            this.skillValidator.showMessages();
            this.forceUpdate();
        }
    }

    saveAndNext = (id) => {
        document.getElementById(id).click()
    }

    handleExperienceInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        let Experience = this.state.Experience;

        let type = e.target.type;
        
        if(type==='text'){
            let regex = /^[A-Za-z0-9 ]+$/
            if(!regex.test(value) && value){
                return false
            }
        }
        if (type === 'number') {
            if (parseInt(value) <= 0) {
                value = ''
                e.target.value = ''
            }
        }
        Experience[name] = value;
        // if (name === 'state_id') {
        //     this.setState({stateId: value})
        // }
        if (name === 'still_working') {
            this.setState({still_working: value})
        }
        this.setState({ Experience, submitted2: false});
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value, submitPhysical: false});
    }

    handleSubmitPhysical() {
        if(this.state.submitPhysical) {
            store.dispatch(setNotification({type:'warning', message:'No Are Changes Made'}))
            return
        }
        let Physical = this.state.Physical;
        Physical['auth_key'] = localStorage.getItem('token');
        Physical['isDisable'] = this.state.disability;
        this.setState({'Physical': Physical});
        if ((this.PhysicalValidator.allValid() || this.state.disability === '0') && !this.state.submitPhysical){
            store.dispatch(get_update_Physical(this.state.Physical));
            this.setState({submitPhysical: true})
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            // document.getElementById("menuTab4").click()
        }
        else{
            this.PhysicalValidator.showMessages();
            this.forceUpdate();
        }
    }

    clearEduModal = async () => {
        await this.setState({"Education": [], submitted: false, edit:false, btnText:this.props.t('profileDetails.add', {param: 'react'})}) 
        this.institue.current.value = ""
        await this.setDefaults()
    }

    submitProfile = (flashMsg = false, navigateToNextTab=true) => {
        // console.log('navigateToNextTab', navigateToNextTab)
        if(this.state.profileSubmitted) {
            store.dispatch(setNotification({type:'warning', message:'No Are Changes Made'}))
            return
        }
        let Profile = this.state.Profile;
        Profile['auth_key'] = localStorage.getItem('token');
        Profile['flashMsg'] = flashMsg
        this.setState({'Profile': Profile});
        if (this.profileValidator.allValid() && !this.state.dobError && !this.state.profileSubmitted) {
            // if(Profile.email && Profile.middlename && Profile.lastname && Profile.desired_salary){
            //     this.setState({ profileFieldsSet1: false})
            // }
            store.dispatch(get_update_profile(this.state.Profile));
            
            this.setState({profileSubmitted: true, imgSrc:''})
            this.setState({activeTab: 'address'})
            document.getElementById("doc-control").value = ""
            // if(navigateToNextTab){
            //     document.getElementById("menuTab2").click()
            // }
        } else {
            this.profileValidator.showMessages();
            this.forceUpdate();
        }
    }

    submitOnBlur = (e = '', value = '') => {
        if ((e && e.target.value) || value) {
            // this.submitProfile(true)
            this.submitProfile(false, false)
        }
    }

    async handleSubmitProfile() {
        await this.submitProfile()
    }

    years = () => {
        const yearList = []
        for (let i = 1980; i <= new Date().getFullYear(); i++) {
            yearList.push(<option key={i} value={i}>{i}</option>)
        }
        return yearList.reverse()
    }

    handleProfileImage(e, key) {
        e.persist();
        const files = e.target.files
        const formatIndex = this.imageFormats.findIndex(format => format === files[0].type)
        if (formatIndex !== -1) {
            const fileSize = Math.round((files[0].size / 1024));
            if (fileSize > 1024) {
                this.setState({[key]: 'Image size is too large'})
                setTimeout(() => this.setState({[key]: ''}), 3000)
                e.target.value = null;
            } else {
                if (key === 'error') {
                    var file = files[0];
                    let reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onloadend = function (e) {
                    this.setState({
                    imgSrc: [reader.result]
                    })
                    }.bind(this);
                    const Profile = this.state.Profile
                    Profile['image'] = files[0]
                    // store.dispatch(get_update_profile_image(files[0]));
                    this.setState({ Profile, profileSubmitted: false})
                } else if(key === 'errorSkill'){
                    const skill = this.state.skill
                    skill['img'] = files[0]
                    this.setState({skill, submitted1:false})
                } else {
                    const Education = this.state.Education
                    Education['img'] = files[0]
                    this.setState({Education, submitted:false})
                }
            }
        } else {
            this.setState({[key]: 'Only .jpg, .jpeg and .png formats are allowed'})
            setTimeout(() => this.setState({[key]: ''}), 3000)
            e.target.value = null;
        }
    }

    handleDocs(e, key) {
        e.persist();
        //Doc size 1mb allowed.
        const files = e.target.files
        const formatIndex = this.allowedDocFormats.findIndex(format => format === files[0].type)
        if (formatIndex !== -1) {
            const fileSize = Math.round((files[0].size / 1024));
            if (fileSize > 1024) {
                this.setState({[key]: 'File size is too large'})
                setTimeout(() => this.setState({[key]: ''}), 3000)
                e.target.value = null;
            } else {
                // store.dispatch(get_update_profile_image(files[0]));
                const Profile = this.state.Profile
                Profile['doc'] = files[0]
                this.setState({profileFieldsSet3: false, Profile, profileSubmitted: false})
            }
        } else {
            this.setState({[key]: 'Only doc, docx and pdf files are allowed'})
            setTimeout(() => this.setState({[key]: ''}), 3000)
            e.target.value = null;
        }
    }


    creaeduValidatorate = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map(function (data, index) {
                Distoption.push(<option key={index} value={data.state_id}>{data.state_name}</option>)
                return Distoption
            })
            return Distoption
        }
    }


    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
         if(Mainprops.actionReducer.selectedDistricts.length > 0 ){
              Mainprops.actionReducer.selectedDistricts.map(function(data,index){
                if(data.state_name === 'Punjab'){
                   data.dist.map(function(distdata,distindex){
                      Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
                      return Distoption
                    })
                }
                return Distoption
            })
          }
          return Distoption
        }

        createDistoption2 = () => {
            let Mainprops = this.props;
    
            let Distoption = []
            if (Mainprops.actionReducer.selectedDistricts.length > 0 && this.state.Experience.state_id) {
                Mainprops.actionReducer.selectedDistricts.map((data, index) => {
                    if (data.state_id === this.state.Experience.state_id) {
                        data.dist.map(function (distdata, distindex) {
                            Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
                            return Distoption
                        })
                    }
                    return Distoption
                })
            }
    
            return Distoption
        }

    handleExp = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        formData[name] = value;
        this.setState({formData});

    }

    async handleSubmitEducation() {
        if(this.state.submitted) {
            store.dispatch(setNotification({type:'warning', message:'No Are Changes Made'}))
            return
        }
        let Education = this.state.Education;
        
        if (!this.state.submitted && this.eduValidator.allValid()) {
            Education['auth_key'] = localStorage.getItem('token');
            this.setState({'Education': Education});
            store.dispatch(get_update_Education(this.state.Education));
            this.eduValidator.hideMessages()
            this.institue.current.value = ""
            this.setState({'Education': [], submitted: true})
            // this.education.current.click()
            // document.body.scrollTop = 0;
            // document.documentElement.scrollTop = 0;
            // if(this.state.redirect){
            //     document.getElementById("skillTab").click()
            //     this.setState({redirect:false})
            // }
            
        } 
        else {
            this.eduValidator.showMessages();
            this.forceUpdate();
        }
    }

    handleClearExperience() {
        this.setState({'Experience': []});
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    handleClearSkill() {
        this.setState({'skill': []});
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    handleSubmitAddress() {
        if(this.state.submitStatus) {
            store.dispatch(setNotification({type:'warning', message:'No Are Changes Made'}))
            return
        }
        let Addresses = this.state.Addresses;
        let current = this.state.Addresses.current;
        current['auth_key'] = localStorage.getItem('token');
        current['isDisable'] = 1;
        Addresses.current = current;
        this.setState({'Addresses': Addresses});

        if (this.comValidator.allValid() && !this.state.submitStatus) {
            store.dispatch(get_update_address(this.state.Addresses.current));
            this.comValidator.hideMessages()
            this.setState({submitStatus: true})
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;

            // document.getElementById("menuTab3").click()
            // document.getElementById("testBtn").click()
        } else {
            this.comValidator.showMessages();
            this.forceUpdate();
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    async handleEditEducation(index) {
        this.institue.current.value = ""
        let Education = this.props.JobseekerReducer.Educations[index]
        Education['doc'] = Education.img
        await this.setState({Education, submitted: false, edit: true, btnText:this.props.t('profileDetails.update', {param: 'react'})});
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    // getSpecialization = (id) => {
    //     if(this.props.actionReducer.Specilization && this.props.actionReducer.Specilization.length){
    //         const filter =this.props.actionReducer.Specilization.filter((spl) => spl.id === id)
    //         return filter && filter[0] ? filter[0].name: ''
    //     }
    // }

    handleDeleteEducation(index) {
        let data = {};
        data.id = this.props.JobseekerReducer.Educations[index].id;
        data.auth_key = localStorage.getItem('token');
        store.dispatch(get_delete_Education(data));
        this.setState({'Education': []});
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    handleClearEducation() {
        this.setState({'Education': []});
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    handleChangePassingYearSkill = (date) => {
        let formatedDate = moment(date).format('YYYY-MM-DD').split("-")
        if (formatedDate[0].length === 4) {
            const skill = this.state.skill
            formatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            skill['passing_year'] = formatedDate
            this.setState({skill});
        }
    }



    onChange = (val) => {
        const Experience = this.state.Experience
        Experience.key_skills = val
        this.setState({Experience})
    }

    handlePhysicalInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        let Physical = this.state.Physical;
        if (e.target.type === 'number') {
            if (value.startsWith('0')) {
                value = ''
                e.target.value = ''
            }
            if (name === 'height' && value > 250) {
                value = ''
                e.target.value = ''

            }
            if (name === 'weight' && value > 300) {
                value = ''
                e.target.value = ''
            }
            if (name === 'chest' && value > 50) {
                value = ''
                e.target.value = ''
            }
        }
        Physical[name] = value;
        this.setState({'Physical': Physical, submitPhysical: false});
    }

    handleEducationInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;
        
        if(type==='text'){
            let regex = /^[A-Za-z0-9 ]+$/
            if(!regex.test(value) && value){
                return false
            }
        }
        if (name === 'passing_year') {
            const val = value.split("-");
            if (val[0].length > 4) {
                value = val[0].substr(0, 4) + '-' + val[1] + '-' + val[2]
            }
        }
        if (e.target.type === 'number') {
            if (value.startsWith('0')) {
                value = ''
                e.target.value = ''
            }
            if (value.length > 2 && value !== '100') {
                const newVal = value.substr(0, 2)
                e.target.value = newVal
                value = newVal
            }
        }
        let Education = this.state.Education;
        Education[name] = value;
        // console.log('Education', Education)
        this.setState({ Education, submitted: false});
    }

    handleCrruntaddressInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;

        if(type==='text'){
            let regex = /^[A-Za-z0-9 ]+$/
            if(!regex.test(value) && value){
                return false
            }
        }
        if (name === 'isSame' && !e.target.checked) {
            value = 0;
        }
        if (name === 'district_id') {
            store.dispatch(get_User_Subistrict(value));
        }
        // if (name === 'address_locality' && value ==='1') {

        // }
        if (name === 'pincode' && value.length > 8) {
            e.target.value = value.substr(0, 8)
            value = value.substr(0, 8)
        }
        if (e.target.type === 'tel') {
            const re = /^[0-9\b]+$/;
            // if value is not blank, then eduValidator the regex
            if (!re.test(e.target.value)) {
                value = ''
                e.target.value = ''
            }
        }
        let Addresses = this.state.Addresses;
        let current = this.state.Addresses.current;
        const fieldSet1 = ['address_Line1', 'address_Line2', 'address_Line3', 'phone']
        
        if(fieldSet1.includes(name)){
            if(!value) {
                this.setState({addressFieldSet2:false})
            }
            if(!current[name] && value) {
                this.setState({addressFieldSet1: false })
            }
        }
        current[name] = value;
        Addresses.current = current;
        this.setState({ Addresses, submitStatus: false});
    }

    handlePermanentaddressInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'district_id') {
            store.dispatch(get_User_Subistrict(value, 'permanent'));
        }
        if (name === 'subdistrict_id') {
            store.dispatch(get_User_Cities(value, 'permanent'));
        }
        let Addresses = this.state.Addresses;
        let permanent = this.state.Addresses.permanent;
        permanent[name] = value;
        Addresses.permanent = permanent;
        this.setState({ Addresses});
    }

    async handleProfileInput(e) {
        e.persist()
        const name = e.target.name;
        var value = e.target.value;
        var type = e.target.type;
        let Profile = this.state.Profile;

        if(type==='text'){
            let regex = /^[A-Za-z0-9 ]+$/
            if(!regex.test(value) && value){
                return false
            }
        }
        if (type === 'number') {
            if (value.startsWith('0')) {
                value = ''
                e.target.value = ''
            }


        }
        if (name === 'prefered_district' && value === '2') {
            this.setState({requiredPref: true})
        } else if(name === 'prefered_district' && value === '1'){
            this.setState({requiredPref: false})
        }

        const fieldSet1 = ['email', 'middlename', 'lastname', 'desired_salary']
        if(fieldSet1.includes(name)){
            if(!value) this.setState({profileFieldsSet2:false}) 
            
            if(value && !Profile[name]) this.setState({profileFieldsSet1: false}) 
        } 

        const fieldSet2 = ['category', 'guardian_phone']
        if(fieldSet2.includes(name)){
            if(!value) this.setState({profileFieldsSet4:false}) 
            
            if(value && !Profile[name]) this.setState({profileFieldsSet3: false}) 
        } 

        Profile[name] = value;

        if ((name === 'prefered_district' && (!value || value === '1')) && (Profile.district1 || Profile.district2 || Profile.district3)) {
            Profile['district1'] = ""
            Profile['district2'] = ""
            Profile['district3'] = ""
            this.setState({requiredPref: false})

        }
        await this.setState({Profile, profileSubmitted: false});
    }

    getDocName = () => {
        let doc = this.props.JobseekerReducer.Profile.doc
        doc = doc.name ? doc.name : doc 
        if(doc){
            const index = doc.lastIndexOf("/")
            return doc.substr(index + 1)
        }
    }
    

    getSkills = (index) => {
        const expVal = this.props.JobseekerReducer.experience.data[index].key_skills
        let objects = JSON.parse("[" + expVal + "]")
        const names = []
        objects && objects[0].forEach((val) => names.push(val.name))
        return names.toString()
    }

    close = () => {
        this.setState({profileCompleted:false})
    }

    deleteRecord = () => {
        const { delIndex, delOption } = this.state
        if(delOption === 'edu') {
            this.handleDeleteEducation(delIndex)
        } else if(delOption === 'skill') {
            this.handleDeleteSkill(delIndex)
        } else if(delOption === 'exp') {
            this.handleDeleteExperience(delIndex)
        }
        document.getElementById("exampleModal").click()
    }


    render() {
        const {requiredPref, skill, errorSkill, edit, Education, errorCertificate, errorDateFrom,
         errorDateTo, dobError, profileCompleted, physicalTabStatus, expTabStatus, addressTabStatus,
         eduTabStatus} = this.state
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;
        let Mainprops = this.props;
        let Mainstate = this.state;
        const {comValidator, profileValidator, expValidator,
            PhysicalValidator, eduValidator, skillValidator} = this
        
        let Mainthis = this;
        

        //Fetch courses for autocomplete 
        let courses = []
        const educationLevel = this.props.actionReducer.educationLevel
        if (Education.highest_education_level) {
            const selectedEducation = educationLevel.filter((edu) => edu.education_id === Education.highest_education_level)
            if (selectedEducation && selectedEducation[0]) {
                selectedEducation && selectedEducation[0] && selectedEducation[0].qualification.forEach((qual) => {
                    courses.push({label: qual.name, id: qual.id})
                })
            }
        }

        courses.push({label: "Others", id: "152"})
        

        // Date format for datepicker
        const dob = this.state.Profile.dob && this.state.Profile.dob.split("-")
        // const dateFrom = this.state.Experience[0].date_from 
        // && this.state.Experience.date_from.split("-")
        // const dateTo = this.state.Experience.date_to && this.state.Experience.date_to.split("-")
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        let constituency = []
        if (this.state.Addresses && this.state.Addresses.current && this.state.Addresses.current.district_id) {
            constituency = this.props.actionReducer.constituency.filter((data) => data.district_id === this.state.Addresses.current.district_id)
        }

        return (

            
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <section>
                <div className="modal fade intro-modal" id="jobModal" style={{ 'zIndex': '99999' }}>
                            <div className="modal-dialog" style={{ 'maxWidth': '969px' }}>
                            <div className="modal-content d-block bg-dark2">
                            <div className="modal-body pt-2  pb-1 text-center">
                               
                                <div className="d-flex align-items-center w-100 justify-content-center">
                                    <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                </div>

                                <h5 className="mx-auto  text-uppercase mb-3">{this.props.t('jobMela.pgrkam', { param: 'react' })}</h5>
                                <p className="mx-auto  text-uppercase mb-3">Dear PGRKAM user, please confirm if you still require employment facilitation by choosing an option below:</p>
                                <p className="mx-auto  text-uppercase mb-3">ਪਿਆਰੇ PGRKAM ਉਪਭੋਗਤਾ, ਕਿਰਪਾ ਕਰਕੇ ਹੇਠਾਂ ਦਿੱਤੇ ਵਿਕਲਪ ਦੀ ਚੋਣ ਕਰਕੇ ਪੁਸ਼ਟੀ ਕਰੋ ਕਿ ਕੀ ਤੁਹਾਨੂੰ ਅਜੇ ਵੀ ਰੁਜ਼ਗਾਰ ਸਹੂਲਤ ਦੀ ਲੋੜ ਹੈ:</p>
                                <div className="row d-flex">
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                        <div className="first-click">
                                            <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                                            <p>Yes, I still require employment facilitation.</p>
                                            <p>ਹਾਂ, ਮੈਨੂੰ ਅਜੇ ਵੀ ਰੁਜ਼ਗਾਰ ਸਹੂਲਤ ਦੀ ਲੋੜ ਹੈ।</p>
                                            {/* <Link to="/signin" className="btn btn-danger pt-1 pb-1" onClick={this.closeModal} >{this.props.t('homeContent.clickToLogin', { param: 'react' })}</Link> */}
                                            <button className="btn btn-danger pt-1 pb-1"  onClick={() => this.handleyes()}>Yes/ਹਾਂ</button>
                                        
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                        <div className="first-click">
                                            <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                                            <p className={"pl-2 pr-2"}>No, I do not require employment facilitation.</p>
                                            <p className={"pl-2 pr-2"}>ਨਹੀਂ, ਮੈਨੂੰ ਰੁਜ਼ਗਾਰ ਦੀ ਸਹੂਲਤ ਦੀ ਲੋੜ ਨਹੀਂ ਹੈ।</p>
                                            {/* <Link to="/signup" className="btn btn-danger pt-1 pb-1" onClick={this.closeModal}>Click to Register</Link> */}
                                            <button className="btn btn-danger pt-1 pb-1"  onClick={() => this.handleno()}>No/ਨਹੀਂ</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                      </div>
                      
                </section>
                <div className="container">
                    <div className="row">
                       
                        
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route}/>
                        <main className="col second-side p-0 pb-3 mt-2 h-100">
                        <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel">Are sure you want to delete?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                {/* <div class="modal-body">
                                    Are sure you want to delete?
                                </div> */}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary btn-primary2" onClick={() => this.deleteRecord()}>Ok</button>
                                    <button type="button" className="btn btn-primary btn-primary2" data-dismiss="modal">Cancel</button>
                                </div>
                                </div>
                            </div>
                        </div>
                            {profileCompleted &&
                            <div className="tab-content">
                                   <div id="home" className=" tab-pane bg-education active p-0">
                                       <section className="login-form mt-2">
                                           <div className="container">
                                               <div className="row">
                                                   <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                       <div className="panel-heading">
                                                           <h6 className="mb-0">Profile</h6>
                                                       </div>
                                                       <div className="panel-body" style={{"minHeight":"142px"}}>
                                                       <h5 className="text-success  pt-2 complete-profile">You have successfully completed your profile.<br/> Click on the <Link className="date-clr" to="jobsearch">Link</Link> to search for jobs.</h5>
                                                       <div className="align-items-end d-flex">
                                                       <button onClick={() => this.close()}
                                                                className="mx-auto d-block btn btn-primary btn-primary2 mt-3">
                                                                {this.props.t('jobSearch.goBack', {param: 'react'})}
                                                            </button>
                                                        </div>
                                                        
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </section>
                                                        </div>
                                                        </div>}

                            {!profileCompleted ?
                            <div>
                            <div className="row m-0 bg-white">
                            <Link to="/jobfair" className="date-clr"></Link>
                                <ul className="nav nav-tabs" role="tablist">
                                    <li  data-placement="bottom"
                                        onClick={() => this.checkForSubmittedForm()}
                                        title={this.props.t('profileDetails.personalInfoDecs', {param: 'react'})}
                                        className="nav-item">
                                        <a className={'nav-link active mr-0'} data-toggle="tab" id="menuTab1"
                                           href="#home">{this.props.t('profileDetails.personalInfo', {param: 'react'})}</a>
                                    </li>
                                    <li 
                                        data-placement="bottom"
                                        onClick={() => this.checkForSubmittedForm()} 
                                        title={addressTabStatus ? this.props.t('profileDetails.communicationDecs', {param: 'react'}) : "Complete profile tab first to access this tab"}
                                        className="nav-item">
                                         <a style={{cursor: addressTabStatus ? "pointer": "not-allowed"}} className="nav-link mr-0" 
                                         data-toggle={ addressTabStatus ? "tab": ""} id="menuTab2"
                                           href={addressTabStatus ? "#menu1": "#"}>{this.props.t('profileDetails.communication', {param: 'react'})}</a>
                                    </li>
                                    <li  data-placement="bottom"
                                        onClick={() => this.checkForSubmittedForm()}
                                        title={this.props.t('profileDetails.physicalAttributesDecs', {param: 'react'})}
                                        className="nav-item" >
                                        <a style={{cursor: physicalTabStatus ? "pointer": "not-allowed"}} className="nav-link mr-0" data-toggle={ physicalTabStatus ? "tab" : ""} id="menuTab3"
                                           href={ physicalTabStatus ? "#menu2" : "#"}>{this.props.t('profileDetails.physicalAttributes', {param: 'react'})}</a>
                                    </li>
                                    <li  data-placement="bottom"
                                        onClick={() => this.checkForSubmittedForm()}
                                        title={this.props.t('profileDetails.educationTraningDecs', {param: 'react'})}
                                        className="nav-item">
                                        <a 
                                        style={{cursor: eduTabStatus ? "pointer": "not-allowed"}}
                                         className="nav-link mr-0" data-toggle={eduTabStatus ? "tab":""} id="menuTab4"
                                           href={eduTabStatus ? "#menu3":"#"}>{this.props.t('profileDetails.educationTraning', {param: 'react'})}</a>
                                    </li>
                                    <li data-placement="bottom"
                                    onClick={() => this.checkForSubmittedForm()}
                                        title={expTabStatus ? this.props.t('profileDetails.experienceDecs', {param: 'react'}):"Please add basis education to access this tab"}
                                        className="nav-item">
                                        <a style={{cursor: expTabStatus ? "pointer": "not-allowed"}} className="nav-link mr-0" data-toggle={expTabStatus ? "tab" :""} id="menuTab5"
                                           href={expTabStatus ? "#menu4": "#"}>{this.props.t('profileDetails.experience', {param: 'react'})}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="clearfix"></div>
                            <div className="tab-content profile-page">
                                <div id="home" className=" tab-pane bg-education active">
                                    <br/>
                                    <section>
                                        <form onSubmit={(event) => {
                                            event.preventDefault();
                                            this.handleSubmitProfile(event)
                                        }}>
                                            <div className="panel-body mb-0 pb-3">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                    <h6 className="mb-0 mt-2">{this.props.t('profileDetails.personalInformation', {param: 'react'})}</h6>
                                                    </div>
                                                </div>
                                                
                                                
                                                <hr/>
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-4">
                                                    {this.state.imgSrc ? <img alt=""
                                                             src={this.state.imgSrc}
                                                             className="img-fluid profile-img mb-2 mx-auto d-block"/>:
                                                        <img alt=""
                                                             src={this.props.JobseekerReducer.Profile.image ? `${SERVER_PATH}${this.props.JobseekerReducer.Profile.image}` : '/images/img-1.png'}
                                                             className="img-fluid profile-img mb-2 mx-auto d-block"/>
                                                            }


                                                        <p style={{"cursor": "pointer", "width": "70px"}}
                                                           className="edit-img text-center  mx-auto d-block"
                                                           onClick={() => this.refs.fileUploader.click()}>{this.props.t('profileDetails.editImage', {param: 'react'})}</p>

                                                        <span className={'edit-img text-center  mx-auto d-block'}>
                                                            {this.props.t('profileDetails.uploadInst', {param: 'react'})} </span>
                                                        <input id="file-input" accept={this.imageFormats.toString()}
                                                               ref="fileUploader" type="file" style={{display: "none"}}
                                                               onChange={(e) => Mainthis.handleProfileImage(e, 'error')}/>
                                                        {this.state.error && <p style={{
                                                            "color": "red",
                                                        }}>{this.state.error}</p>}
                                                    </div>
                                                    <div className=" col-sm-12 offset-lg-1  col-lg-6">
                                                        <div className="row form-group">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label>{this.props.t('profileDetails.pgid', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 ">
                                                                <p className="nyc-number mb-0" style={{
                                                                    'fontWeight': '700',
                                                                    'color': '#000'
                                                                }}>PB/{Mainprops.actionReducer.Loginuser.id}/
                                                                {this.state.Profile &&  this.state.Profile.created_at 
                                                                && this.state.Profile.created_at.split(' ')[0].split('-')[0]}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.mobileDesc', {param: 'react'})}>{this.props.t('profileDetails.mobile', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input readOnly disabled
                                                                       defaultValue={this.state.Profile.mobile ? this.state.Profile.mobile : ''}
                                                                       type="tel" className="form-control" id="mobile"
                                                                       name="mobile" placeholder="Number"/>

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.emailDesc', {param: 'react'})}>{this.props.t('profileDetails.email', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input  type="email"
                                                                       value={this.state.Profile.email ? this.state.Profile.email : ''}
                                                                       className="form-control" id="email"
                                                                       name="email"
                                                                       onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                    //    onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                       placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('profileDetails.email', {param: 'react'})}/>
                                                                       {profileValidator.message('email', this.state.Profile.email, 'email')}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-6">
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.fnameDesc', {param: 'react'})}>{this.props.t('profileDetails.fname', {param: 'react'})}
                                                                    <span className="ml-2"
                                                                          style={{"color": "red"}}>*</span></label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type="text"

                                                                       onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                       value={this.state.Profile.firstname ? this.state.Profile.firstname : ''}
                                                                       className="form-control" id="firstname"
                                                                    //    onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                       name="firstname" placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('profileDetails.fname', {param: 'react'})}/>

                                                               <label> {profileValidator.message('firstname', this.state.Profile.firstname, 'required')}</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.lnameDesc', {param: 'react'})}>
                                                                           {this.props.t('profileDetails.lname', {param: 'react'})}
                                                                           {/* <span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span> */}
                                                                    </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input
                                                                    type="text"
                                                                    onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                    value={this.state.Profile.lastname ? this.state.Profile.lastname : ''}
                                                                    className="form-control" id="lastname"
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                    name="lastname" placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('profileDetails.lname', {param: 'react'})}/>
                                                                {/* {profileValidator.message('lastname', this.state.Profile.lastname, 'required')} */}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {/* </div> */}
                                                    <div className="col-sm-12 col-lg-6 ">
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.mnameDesc', {param: 'react'})}>{this.props.t('profileDetails.mname', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type="text"
                                                                       onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                       value={this.state.Profile.middlename ? this.state.Profile.middlename : ''}
                                                                       className="form-control" id="middlename"
                                                                    //    onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                       name="middlename"
                                                                       placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('profileDetails.mname', {param: 'react'})}/>

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.genderDesc', {param: 'react'})}>
                                                                    {this.props.t('profileDetails.gender', {param: 'react'})}
                                                                    {/*<span*/}
                                                                    {/*className="ml-2"*/}
                                                                    {/*style={{"color": "red"}}>*</span>*/}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group pr-0">
                                                                {Mainprops.actionReducer.Genders.length > 0 &&
                                                                Mainprops.actionReducer.Genders.map((data, index) => {
                                                                    return (
                                                                        <div className="form-check-inline mr-0 pr-1"
                                                                             key={index}>
                                                                            <label className="form-check-label"
                                                                                   htmlFor="radio1">
                                                                                <input required type="radio"
                                                                                       onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                                       checked={this.state.Profile.gender === data.id}
                                                                                       className="form-check-input"
                                                                                       id={data.name} name="gender"
                                                                                    //    onBlur={(e) => this.submitOnBlur(e)}
                                                                                       value={data.id}/>{data.name}
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                                {/*{errorGender &&*/}
                                                                {/*<span style={{"color": "red"}}>{errorGender}</span>}*/}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-sm-12 col-lg-6 ">
<div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label data-placement="bottom"
                                                                       title={'Please enter current salary'}>
                                                                    {this.props.t('profileDetails.desiredSalary', {param: 'react'})}
                                                                    {/*<span*/}
                                                                    {/*className="ml-2"*/}
                                                                    {/*style={{"color": "red"}}>*</span>*/}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type={"number"} min={"1"}
                                                                       className={"form-control"}
                                                                       value={this.state.Profile.desired_salary ? this.state.Profile.desired_salary : ''}
                                                                       onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                    // onBlur={this.checkSalary}
                                                                    //    onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                       name={'desired_salary'}
                                                                       placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('profileDetails.desiredSalary', {param: 'react'})}
                                                                /> <span className="edit-img text-center"
                                                                         >(INR per month)</span>
                                                                         {profileValidator.message('desired salary', this.state.Profile.desired_salary, 'max:8')}

                                                            </div>
                                                        </div>         
                                                    </div>
                                                    {/* <div className="row"> */}
                                                    <div className="col-sm-12 col-lg-6 ">
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.dobDesc', {param: 'react'})}>{this.props.t('profileDetails.dob', {param: 'react'})}<span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span></label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">

                                                                <DatePicker
                                                                    dateFormat="dd/MM/yyyy"
                                                                    
                                                                    placeholderText={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('profileDetails.dob', {param: 'react'})}
                                                                    className="form-control"
                                                                    minDate={new Date("01-01-1950")}
                                                                    maxDate={new Date()}
                                                                    yearDropdownItemNumber={50}
                                                                    scrollableYearDropdown={true}
                                                                    scrollableMonthDropdown={true}
                                                                    selected={dob ? Date.parse(`${dob[1]}/${dob[0]}/${dob[2]}`) : ''}
                                                                    onChange={this.handleChange}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                />
                                                             <label>   {profileValidator.message('date of birth', this.state.Profile.dob, 'required')}</label>
                                                                {dobError && <label style={{"color":"red"}}>{dobError}</label>}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    {/* </div> */}


                                                </div>

                                                <div className="row">
                                                                                                 {/* <div className="row"> */}
                                                    <div className="col-sm-12 col-lg-6 ">
                                                                                                                <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.fatherDesc', {param: 'react'})}>
                                                                    {this.props.t('profileDetails.father', {param: 'react'})}
                                                                    <span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input
                                                                    onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                    value={this.state.Profile.guardian_name ? this.state.Profile.guardian_name : ''}
                                                                    type="name"
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                    className="form-control" id="guardian_name"
                                                                    name="guardian_name"
                                                                    placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('profileDetails.father', {param: 'react'})}/>
                                                                {profileValidator.message('guardian name', this.state.Profile.guardian_name, 'required')}

                                                            </div>
                                                        </div>
                                                         <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.relationDesc', {param: 'react'})}>{this.props.t('profileDetails.relation', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select
                                                                    onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                    value={this.state.Profile.guardian_relation ? this.state.Profile.guardian_relation : ''}
                                                                    className="form-control" id="guardian_relation"
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                    name="guardian_relation">
                                                                    <option value="">{this.props.t('placeholder.select', {param: 'react'})+" "+ this.props.t('profileDetails.relation', {param: 'react'})}</option>
                                                                    {Mainprops.JobseekerReducer.Relation.length > 0 &&
                                                                    Mainprops.JobseekerReducer.Relation.map(function (data, index) {
                                                                        return (
                                                                            <option
                                                                                key={index}
                                                                                value={data.id}>{data.name}</option>
                                                                        )
                                                                    })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={'Please enter District preference'}>{this.props.t('profileDetails.districtPreference', {param: 'react'})}<span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span></label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select className={"form-control"}
                                                                        value={this.state.Profile.prefered_district ? this.state.Profile.prefered_district : ''}
                                                                        onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                        // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                        name={"prefered_district"}>
                                                                    <option value="">{this.props.t('placeholder.select', {param: 'react'})+" "+ this.props.t('profileDetails.districtPreference', {param: 'react'})}</option>
                                                                    <option value={"1"}>Anywhere in punjab</option>
                                                                    <option value={"2"}>Select district</option>
                                                                </select>
                                                                <label>{profileValidator.message('preferred district ', this.state.Profile.prefered_district, 'required')}</label>
                                                            </div>


                                                            {/* </div> */}
                                                        </div>
                                                            <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={'Please enter District preference'}>
                                                                    {this.props.t('profileDetails.1Preference', {param: 'react'})}
                                                                    {requiredPref && <span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span>}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select
                                                                    disabled={!this.state.Profile.prefered_district || this.state.Profile.prefered_district === '1'}
                                                                    value={this.state.Profile.district1 ? this.state.Profile.district1 : ''}
                                                                    name="district1" className="form-control"
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                    onChange={(event) => Mainthis.handleProfileInput(event)}>
                                                                    <option
                                                                        value="">{this.props.t('banner.select_district', {param: 'react'})}</option>
                                                                    {this.createDistoption()}
                                                                </select>
                                                               <label> {requiredPref && profileValidator.message('Preference 1 ', this.state.Profile.district1, 'required')}</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={'Please enter District preference'}>
                                                                    {this.props.t('profileDetails.3Preference', {param: 'react'})}
                                                                    {requiredPref && <span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span>}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select
                                                                    value={this.state.Profile.district3 ? this.state.Profile.district3 : ''}
                                                                    disabled={!this.state.Profile.prefered_district || this.state.Profile.prefered_district === '1'}
                                                                    name="district3" className="form-control"
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                    onChange={(event) => Mainthis.handleProfileInput(event)}>
                                                                    <option
                                                                        value="">{this.props.t('banner.select_district', {param: 'react'})}</option>
                                                                    {this.createDistoption()}
                                                                </select>
                                                                <label>{requiredPref && profileValidator.message('Preference 3 ', this.state.Profile.district3, 'required')}</label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {/* <div className="row"> */}
                                                    <div className="col-sm-12 col-lg-6 ">
                                                    <div className="row">
                                                        <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.categoryDesc', {param: 'react'})}>
                                                                    {this.props.t('profileDetails.category', {param: 'react'})}
                                                                    {/*<span*/}
                                                                    {/*className="ml-2"*/}
                                                                    {/*style={{"color": "red"}}>*</span>*/}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select
                                                                    value={this.state.Profile.category ?this.state.Profile.category : ''}
                                                                    onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                    className="form-control" id="category"
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                    name="category">
                                                                    <option value="">{this.props.t('placeholder.select', {param: 'react'})+" "+ this.props.t('profileDetails.category', {param: 'react'})}</option>
                                                                    {Mainprops.JobseekerReducer.Category && Mainprops.JobseekerReducer.Category.length > 0 &&
                                                                    Mainprops.JobseekerReducer.Category.map(function (data, index) {
                                                                        return (
                                                                            <option
                                                                                key={index}
                                                                                value={data.id}>{data.name}</option>
                                                                        )
                                                                    })
                                                                    }
                                                                </select>
                                                                {/*{profileValidator.message('category', this.state.Profile.category, 'required')}*/}
                                                            </div>
                                                            </div>
                                                    <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.maritalDesc', {param: 'react'})}>
                                                                    {this.props.t('profileDetails.marital', {param: 'react'})}
                                                                    <span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select
                                                                    value={this.state.Profile.marital_status ? this.state.Profile.marital_status : ''}
                                                                    onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                    className="form-control" id="marital_status"
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                    name="marital_status">
                                                                    <option value="">{this.props.t('placeholder.select', {param: 'react'})+" "+ this.props.t('profileDetails.marital', {param: 'react'})}</option>
                                                                    {Mainprops.JobseekerReducer.Marital.length > 0 &&
                                                                    Mainprops.JobseekerReducer.Marital.map(function (data, index) {
                                                                        return (
                                                                            <option key={index}
                                                                                    value={data.id}>{data.name}</option>
                                                                        )
                                                                    })
                                                                    }
                                                                </select>
                                                                {profileValidator.message('marital status', this.state.Profile.marital_status, 'required')}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.empStatusDesc', {param: 'react'})}>
                                                                           {this.props.t('profileDetails.empStatus', {param: 'react'})}<span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span></label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select
                                                                    onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                    value={this.state.Profile.employment_status && this.state.Profile.employment_status !=='3' ?
                                                                    this.state.Profile.employment_status 
                                                                    : ''}
                                                                    className="form-control"
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                    name="employment_status">
                                                                    <option value="">{this.props.t('placeholder.select', {param: 'react'})+" "+ this.props.t('profileDetails.empStatus', {param: 'react'})}</option>
                                                                    <option value={"1"}>Employed</option>
                                                                    <option value={"2"}>Unemployed</option>
                                                                </select>
                                                               <label> {profileValidator.message('employment status', this.state.Profile.employment_status && this.state.Profile.employment_status !=='3' ?
                                                                    this.state.Profile.employment_status 
                                                                    : '', 'required')}</label>

                                                            </div>
                                                        </div>
                                                         <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={'Please Enter District Preference'}>
                                                                    {this.props.t('profileDetails.2Preference', {param: 'react'})}
                                                                    {requiredPref && <span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span>}

                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select
                                                                    value={this.state.Profile.district2 ? this.state.Profile.district2 : ''}
                                                                    disabled={!this.state.Profile.prefered_district || this.state.Profile.prefered_district === '1'}
                                                                    name="district2" className="form-control"
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                    onChange={(event) => Mainthis.handleProfileInput(event)}>
                                                                    <option
                                                                        value="">{this.props.t('banner.select_district', {param: 'react'})}</option>
                                                                    {this.createDistoption()}
                                                                </select>
                                                                <label>{requiredPref && profileValidator.message('Preference 2 ', this.state.Profile.district2, 'required')}</label>
                                                            </div>
                                                            </div>
                                                            <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.cvDesc', {param: 'react'})}>
                                                                           {this.props.t('profileDetails.cv', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type="file"
                                                                        id="doc-control"
                                                                       onChange={(event) => Mainthis.handleDocs(event, 'errorDoc')}
                                                                       name="img" className="upload-cv w-100 border-0"
                                                                    //    onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                       accept={this.allowedDocFormats.toString()}
                                                                />
                                                                {this.state.Profile.doc &&
                                                                <a target="_blank"
                                                                   rel="noopener noreferrer"className="doc-up"
                                                                   href={`${SERVER_PATH}${this.state.Profile.doc}`}
                                                                   style={{"cursor": "pointer"}}><i
                                                                    style={{"marginRight": "6px"}}
                                                                    className="fa fa-file-text"
                                                                    aria-hidden="true"></i>{this.getDocName()}</a>}
                                                                <span className="text-center"  style={{"fontSize": "14px"}}>{this.props.t('profileDetails.docsInst', {param: 'react'})}
                                                                </span>
                                                                {this.state.errorDoc && <p
                                                                    style={{"color": "red"}}>{this.state.errorDoc}</p>}
                                                            </div>

                                                    </div>

                                                    <div className="col-sm-12 col-lg-6">
                                                    </div>
                                                    {/* <div className="row"> */}
                                                    <div className="col-sm-12 col-lg-6">
                                                    
                                                     
                                                        
                                                            {/* </div> */}
                                                        </div>
                                                        {/* <hr></hr> */}
                                                        
                                                        
                                                    </div>
                                                    <div className="col-sm-12 col-lg-12">
                                                    
                                                            {/* <hr></hr> */}
                                                                <a className={" date-clr"}
                                                                    data-toggle="collapse" href="#my-panel41"
                                                                >{this.props.t('jobProviderProfile.additionalInfo', { param: 'react' })}</a>

                                                            
                                                            <div id="my-panel41" className="collapse mt-2 show">
                                                            <div className={"row"}>
                                                        <div className="col-sm-6  col-lg-6">
                                                        <div className={"row"}>
                                                        {/* <div className="col-sm-6  col-lg-6"> */}
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.guardianPhoneDesc', {param: 'react'})}>{this.props.t('profileDetails.guardianPhone', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input
                                                                    onChange={(event) => this.handleProfileInput(event)}
                                                                    type="text"
                                                                    value={this.state.Profile.guardian_phone ? this.state.Profile.guardian_phone : ''}
                                                                    className="form-control"
                                                                    // onBlur={(e) => Mainthis.submitOnBlur(e)}
                                                                    placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('profileDetails.guardianPhone', {param: 'react'})}
                                                                    name="guardian_phone"
                                                                />
                                                                {profileValidator.message('guardian phone', 
                                                                this.state.Profile.guardian_phone, 'phone')}
                                                                
                                                            </div>
                                                        </div></div>
                                                        <div className="col-sm-6  col-lg-6">
                                                        <div className={"row"}>
                                                        {/* <div className="col-sm-6  col-lg-6"> */}
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       >{this.props.t('profileDetails.highest_education_level', {param: 'react'})}
                                                                       <span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span></label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                            <select
                                                                                            value={this.state.Profile.highest_education_level ? this.state.Profile.highest_education_level : ''}
                                                                                            onChange={(event) => this.handleProfileInput(event)}
                                                                                            className="form-control"
                                                                                            name="highest_education_level">
                                                                                            <option value="">{this.props.t('forms.selectEdu', { param: 'react' })}
                                                                                            </option>
                                                                                            {Mainprops.actionReducer.educationLevel.length > 0 &&
                                                                                                Mainprops.actionReducer.educationLevel.map(function (data, index) {
                                                                                                    return (

                                                                                                        <option
                                                                                                            value={data.education_id}
                                                                                                            key={index}>{data.education_name}</option>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </select>
                                                                                        {profileValidator.message('highest education level', 
                                                                this.state.Profile.highest_education_level, 'required')}
                                                                
                                                            </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                    {/* </div> */}
                                                        
                                                    {/* </div> */}
                                                    
                                                    
                                                </div>
                                                <hr/>
                                                <button type="submit"
                                                        className=" mx-auto d-block btn btn-primary btn-primary2 mt-1">{this.props.t('profileDetails.saveNext', {param: 'react'})}</button>
                                            </div>
                                        </form>
                                    </section>
                                </div>
                               
                                <div id="menu1" className="tab-pane bg-education fade">
                                    <br/>
                                    <section>
                                        <form onSubmit={(event) => {
                                            event.preventDefault();
                                            this.handleSubmitAddress(event)
                                        }}>
                                            <div className="panel-body mb-0 pb-3">
                                            <div className="row">
                                                    <div className="col-md-8">
                                                    <h6 className="mb-0 mt-2">{this.props.t('profileDetails.currentAddress', {param: 'react'})}</h6>
                                                    </div>
                                                    
                                                </div>
                                                
                                                
                                                <hr/>
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-6 ">
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.address1Desc', {param: 'react'})}>{this.props.t('profileDetails.address1', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type="text"
                                                                       onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                       value={Mainprops.JobseekerReducer.Addresses.current.address_Line1 ? Mainprops.JobseekerReducer.Addresses.current.address_Line1 : ''}
                                                                       className="form-control" id="address_Line1"
                                                                       name="address_Line1"
                                                                       placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('jobProviderProfile.add1', {param: 'react'})}/>

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.address3Desc', {param: 'react'})}>{this.props.t('profileDetails.address3', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type="text"
                                                                       onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                       value={Mainprops.JobseekerReducer.Addresses.current.address_Line3 ? Mainprops.JobseekerReducer.Addresses.current.address_Line3 : ''}
                                                                       className="form-control" id="address_Line3"
                                                                       name="address_Line3"
                                                                       placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('jobProviderProfile.add2', {param: 'react'})}/>

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.districtDesc', {param: 'react'})}>
                                                                    {this.props.t('profileDetails.district', {param: 'react'})}<span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span></label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select className="form-control"
                                                                        value={this.state.Addresses.current && this.state.Addresses.current.district_id}
                                                                        onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                        id="district_id" name="district_id">
                                                                    <option value="">{this.props.t('forms.selectDis', {param: 'react'})}</option>
                                                                    {this.createDistoption()}
                                                                </select>
                                                               <label> {comValidator.message('district', this.state.Addresses.current && this.state.Addresses.current.district_id, 'required')}</label>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.localityDesc', {param: 'react'})}>
                                                                    {this.props.t('profileDetails.locality', {param: 'react'})}
                                                                    <span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select
                                                                    className="form-control"
                                                                    name={"address_locality"}
                                                                    onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                    value={Mainprops.JobseekerReducer.Addresses.current.address_locality ? Mainprops.JobseekerReducer.Addresses.current.address_locality : ''}
                                                                >
                                                                    <option value={""}>{this.props.t('placeholder.select', {param: 'react'})+" "+ this.props.t('profileDetails.locality', {param: 'react'})}</option>
                                                                    <option
                                                                        value={"1"}>{this.props.t('profileDetails.urban', {param: 'react'})}</option>
                                                                    <option
                                                                        value={"2"}>{this.props.t('profileDetails.rural', {param: 'react'})}</option>
                                                                </select>
                                                               <label> {comValidator.message('address locality', this.state.Addresses.current && this.state.Addresses.current.address_locality, 'required')}</label>
                                                            </div>
                                                        </div>
                                                        {Mainprops.JobseekerReducer.Addresses && Mainprops.JobseekerReducer.Addresses.current &&
                                                         Mainprops.JobseekerReducer.Addresses.current.address_locality ==='1' ?
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('jobProviderProfile.city', {param: 'react'})}>
                                                                    {this.props.t('jobProviderProfile.city', {param: 'react'})}
                                                                    {/* <span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span> */}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                            <input type="text"
                                                                       className="form-control"
                                                                       onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                       value={Mainprops.JobseekerReducer.Addresses.current.city ? Mainprops.JobseekerReducer.Addresses.current.city : ''}
                                                                       id="city" name="city"
                                                                       placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('jobProviderProfile.city', {param: 'react'})}
                                                                    />
                                                               
                                                               {/* <label> {comValidator.message('city', this.state.Addresses.current && this.state.Addresses.current.city, 'required')}</label> */}
                                                            </div>
                                                        </div>:null}
                                                        {Mainprops.JobseekerReducer.Addresses && Mainprops.JobseekerReducer.Addresses.current &&
                                                         Mainprops.JobseekerReducer.Addresses.current.address_locality ==='2' ?
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('jobProviderProfile.village', {param: 'react'})}>
                                                                    {this.props.t('jobProviderProfile.village', {param: 'react'})}
                                                                    {/* <span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span> */}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                            <input type="text"
                                                                       className="form-control"
                                                                       onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                       value={Mainprops.JobseekerReducer.Addresses.current.village ? Mainprops.JobseekerReducer.Addresses.current.village : ''}
                                                                       name="village"
                                                                       placeholder={this.props.t('placeholder.enter', {param: 'react'})+" "+ this.props.t('jobProviderProfile.village', {param: 'react'})}
                                                                       />
                                                               
                                                               {/* <label> {comValidator.message('village', this.state.Addresses.current && this.state.Addresses.current.village, 'required')}</label> */}
                                                            </div>
                                                        </div>:null}
                                                        <div className="row">
                                                                <div className="col-sm-4 text-right pr-0">
                                                                    <label data-placement="bottom"
                                                                        title={this.props.t('profileDetails.selectConstituency', { param: 'react' })}>
                                                                        {this.props.t('profileDetails.constituency', { param: 'react' })}
                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-8 form-group">
                                                                    <select
                                                                        value={this.state.Addresses.current && this.state.Addresses.current.constituency_id ? this.state.Addresses.current.constituency_id : ""}
                                                                        onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                        className="form-control"
                                                                        name="constituency_id">
                                                                        <option value="">{this.props.t('profileDetails.selectConstituency', { param: 'react' })}</option>
                                                                        {constituency.length > 0 &&
                                                                            constituency.map(function (data, index) {
                                                                                return (
                                                                                    <option key={index}
                                                                                        value={data.id}>{data.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                    
                                                    </div>
                                                    <div className="col-sm-12 col-lg-6 ">
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.address2Desc', {param: 'react'})}>{this.props.t('profileDetails.address2', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type="text"
                                                                       onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                       value={Mainprops.JobseekerReducer.Addresses.current.address_Line2 ? Mainprops.JobseekerReducer.Addresses.current.address_Line2 : ''}
                                                                       className="form-control" id="address_Line2"
                                                                       name="address_Line2"
                                                                       placeholder="Enter Second Address"/>

                                                            </div>
                                                        </div>
                                                        {/* <div className="form-control"> */}
                                                            <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label data-placement="bottom"
                                                                       title={this.props.t('profileDetails.stateDesc', {param: 'react'})}>
                                                                    {this.props.t('profileDetails.state', {param: 'react'})}<span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span></label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select
                                                                    value={this.state.Addresses.current && this.state.Addresses.current.state_id ? this.state.Addresses.current.state_id:""}
                                                                    onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                    className="form-control" id="state_id"
                                                                    name="state_id">
                                                                    <option value="">Select State</option>
                                                                    {Mainprops.actionReducer.District.length > 0 &&
                                                                    Mainprops.actionReducer.District.map(function (data, index) {
                                                                        if (data.state_name === 'Punjab') {
                                                                            return (
                                                                                <option key={index}
                                                                                        value={data.state_id}>{data.state_name}</option>
                                                                            )
                                                                        }
                                                                        return ('');
                                                                    })
                                                                    }
                                                                </select>
                                                                <label>{comValidator.message('state', this.state.Addresses.current && this.state.Addresses.current.state_id, 'required')}</label>
                                                            </div>
                                                            </div>
                                                        {/* </div> */}
                                                        <div className="row">
                                                            {/* <div class="form-control"> */}
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.subdistrict', {param: 'react'})}>{this.props.t('profileDetails.subdistrict', {param: 'react'})}
                                                                    <span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                            <select className="form-control"
                                                                        value={this.state.Addresses.current && this.state.Addresses.current.subdistrict_id ?  this.state.Addresses.current.subdistrict_id :""}
                                                                        onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                         name="subdistrict_id">
                                                                    <option value="">Select Sub District</option>
                                                                    {this.props.actionReducer.Subdistrict && this.props.actionReducer.Subdistrict.length ?
                                                                    this.props.actionReducer.Subdistrict.map((sub, key) => {
                                                                    return <option key={key} value={sub.id}>{sub.name}</option>
                                                                    })
                                                                    
                                                                :null}
                                                                </select>
                                                               <label> {comValidator.message('sub district', this.state.Addresses.current && this.state.Addresses.current.subdistrict_id, 'required')}</label>
                                                                {/* <input type="text"
                                                                       className="form-control"
                                                                       onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                       value={Mainprops.JobseekerReducer.Addresses.current.subdistrict_id ? Mainprops.JobseekerReducer.Addresses.current.subdistrict_id : ''}
                                                                       id="subdistrict_id" name="subdistrict_id"
                                                                       placeholder="Enter Subdistrict"/> */}
                                                               {/* <label> {comValidator.message('subdistrict_id', this.state.Addresses.current && this.state.Addresses.current.subdistrict_id, 'required')}</label> */}
                                                            {/* </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            {/* <div class="form-control"> */}
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.pincodeDesc', {param: 'react'})}>{this.props.t('profileDetails.pincode', {param: 'react'})}
                                                                    <span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type="number"
                                                                       className="form-control"
                                                                       onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                       value={Mainprops.JobseekerReducer.Addresses.current.pincode ? Mainprops.JobseekerReducer.Addresses.current.pincode : ''}
                                                                       id="pincode" name="pincode"
                                                                       placeholder="Enter Pin Code"/>
                                                               <label> {comValidator.message('pincode', this.state.Addresses.current && this.state.Addresses.current.pincode, 'required')}</label>
                                                            {/* </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('profileDetails.phoneDesc', {param: 'react'})}>
                                                                    {this.props.t('profileDetails.phone', {param: 'react'})}</label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <input type="tel"
                                                                       onChange={(event) => Mainthis.handleCrruntaddressInput(event)}
                                                                       value={Mainprops.JobseekerReducer.Addresses.current.phone ? Mainprops.JobseekerReducer.Addresses.current.phone : ''}
                                                                       maxLength="10"
                                                                       className="form-control" id="phone" name="phone"
                                                                       placeholder="Enter Phone"/>
                                                                       {comValidator.message('alternate phone', this.state.Addresses.current && this.state.Addresses.current.phone, 'phone')}
                                                                       
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                
                                                <hr/>
                                                <button type="submit"
                                                        className="mx-auto d-block btn btn-primary btn-primary2 mt-1">{this.props.t('profileDetails.saveNext', {param: 'react'})}</button>
                                            </div>

                                        </form>
                                    </section>
                                </div>
                                <div id="menu2" className="tab-pane bg-education fade">
                                    <br/>
                                    <section>
                                        <div className="panel-body mb-0 pb-3">
                                        <div className="row">
                                                    <div className="col-md-8">
                                                    <h6 className="mb-0 mt-2"> {this.props.t('profileDetails.physicalAttributes', {param: 'react'})}</h6>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="text-right">
                                                        
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            
                                            
                                            <hr/>
                                            
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12">
                                                    <div className="row">
                                                        <div className="col-sm-4 text-left">
                                                            <label data-placement="bottom"
                                                                   title={this.props.t('profileDetails.disabledDesc', {param: 'react'})}>{this.props.t('profileDetails.disabled', {param: 'react'})}
                                                                   <span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span></label>
                                                        </div>
                                                        <div className="col-sm-4 form-group pr-0">
                                                            <div className="form-check-inline mr-0 pr-2">
                                                                <label className="form-check-label" htmlFor="radio33">
                                                                    <input
                                                                        onChange={(event) => Mainthis.handleUserInput(event)}
                                                                        checked={Mainstate.disability === '1'}
                                                                        type="radio" className="form-check-input"
                                                                        id="radio33" name="disability"
                                                                        value="1"/>{this.props.t('profileDetails.yes', {param: 'react'})}
                                                                </label>
                                                            </div>
                                                            <div className="form-check-inline mr-0 pr-0">
                                                                <label className="form-check-label" htmlFor="radio44">
                                                                    <input
                                                                        onChange={(event) => Mainthis.handleUserInput(event)}
                                                                        checked={Mainstate.disability === '0'}
                                                                        type="radio" className="form-check-input"
                                                                        id="radio2" name="disability"
                                                                        value="0"/>{this.props.t('profileDetails.no', {param: 'react'})}
                                                                </label>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                {Mainstate.disability === '1' &&
                                                <div className="col-sm-12 col-lg-6">
                                                    <div className="row">
                                                        <div className="col-sm-4 text-right pr-0">
                                                            <label>{this.props.t('profileDetails.disabilityType', {param: 'react'})}<span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span></label>
                                                        </div>
                                                        <div className="col-sm-8 form-group">
                                                            <select
                                                                defaultValue={Mainprops.JobseekerReducer.Physical.disablity_type_id}
                                                                onChange={(event) => Mainthis.handlePhysicalInput(event)}
                                                                className="form-control" id="disablity_type_id"
                                                                name="disablity_type_id">
                                                                <option value="0">Select disablity type</option>
                                                                {Mainprops.JobseekerReducer.Disability.length > 0 &&
                                                                Mainprops.JobseekerReducer.Disability.map(function (data, index) {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={data.id}>{data.name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </select>
                                                            <label> {PhysicalValidator.message('Disability Type', this.state.Physical.disablity_type_id, 'required')}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4 col-6 text-right">
                                                            <label>{this.props.t('profileDetails.disabilityPercentage', {param: 'react'})}</label>
                                                        </div>
                                                        <div className="col-sm-8 col-6 form-group">
                                                            <select
                                                                defaultValue={Mainprops.JobseekerReducer.Physical.disability_percentage_id}
                                                                onChange={(event) => Mainthis.handlePhysicalInput(event)}
                                                                className="form-control" id="disability_percentage_id"
                                                                name="disability_percentage_id">
                                                                <option value="0">Select disability percentage</option>
                                                                <option
                                                                    value="5">5 %
                                                                </option>
                                                                <option
                                                                    value="10">10 %
                                                                </option>
                                                                <option
                                                                    value="15">15 %
                                                                </option>
                                                                <option
                                                                    value="20">20 %
                                                                </option>
                                                                <option
                                                                    value="25">25 %
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-4 col-6 text-right">
                                                            <label>{this.props.t('profileDetails.workingCapacity', {param: 'react'})}</label>
                                                        </div>
                                                        <div className="col-sm-8 col-6 form-group">
                                                            <select
                                                                defaultValue={Mainprops.JobseekerReducer.Physical.working_capacity_id}
                                                                onChange={(event) => Mainthis.handlePhysicalInput(event)}
                                                                className="form-control" id="working_capacity_id"
                                                                name="working_capacity_id">
                                                                <option value="0">Select working capacity</option>
                                                                {Mainprops.JobseekerReducer.Capacity.length > 0 &&
                                                                Mainprops.JobseekerReducer.Capacity.map(function (data, index) {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={data.id}>{data.name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {Mainstate.disability === '1' &&
                                                <div className="col-sm-12 col-lg-6">
                                                    <div className="row">
                                                        <div className="col-sm-4 col-6 text-right">
                                                            <label>{this.props.t('profileDetails.partial', {param: 'react'})}<span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span></label>
                                                        </div>
                                                        <div className="col-sm-8 col-6 form-group">
                                                            <select
                                                                defaultValue={Mainprops.JobseekerReducer.Physical.partial_full_disability}
                                                                className="form-control" id="partial_full_disability"
                                                                name="partial_full_disability">
                                                                <option value="0">Select partial full disability</option>
                                                                <option
                                                                    value={0}>Partial
                                                                </option>
                                                                <option
                                                                    value={1}>Full
                                                                </option>
                                                            </select>
                                                            <label> {PhysicalValidator.message('', this.state.Physical.disablity_type_id, 'required')}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4 col-6 text-right">
                                                            <label>{this.props.t('profileDetails.addionalDisability', {param: 'react'})}</label>
                                                        </div>
                                                        <div className="col-sm-8 col-6 form-group">
                                                            <input
                                                                onChange={(event) => Mainthis.handlePhysicalInput(event)}
                                                                value={Mainprops.JobseekerReducer.Physical.additional_disability_type ? Mainprops.JobseekerReducer.Physical.additional_disability_type : ''}
                                                                type="text" className="form-control"
                                                                id="additional_disability_type"
                                                                name="additional_disability_type"
                                                                placeholder="Enter Additional Disabillity"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                            <hr className="mt-1"/>
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-6 ">
                                                    <div className="row">
                                                        <div className="col-sm-4 text-right pr-0">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('profileDetails.heightDesc', {param: 'react'})}>
                                                                {this.props.t('profileDetails.height', {param: 'react'})}</label>
                                                        </div>
                                                        <div className="col-sm-8 form-group">
                                                            <input
                                                                onChange={(event) => Mainthis.handlePhysicalInput(event)}
                                                                value={Mainprops.JobseekerReducer.Physical.height ? Mainprops.JobseekerReducer.Physical.height : ''}
                                                                type="number" className="form-control" id="height"
                                                                name="height" placeholder="Enter Height in cms"
                                                                min={"1"}/>

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4 text-right pr-0">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('profileDetails.chestDesc', {param: 'react'})}>
                                                                {this.props.t('profileDetails.chest', {param: 'react'})}</label>
                                                        </div>
                                                        <div className="col-sm-8 form-group">
                                                            <input
                                                                onChange={(event) => Mainthis.handlePhysicalInput(event)}
                                                                value={Mainprops.JobseekerReducer.Physical.chest ? Mainprops.JobseekerReducer.Physical.chest : ''}
                                                                type="number" className="form-control" id="chest"
                                                                name="chest" placeholder="Enter Chest in cms"
                                                                min={"1"}/>

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4 text-right pr-0">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('profileDetails.eyeSightDesc', {param: 'react'})}>
                                                                {this.props.t('profileDetails.eyeSight', {param: 'react'})}</label>
                                                        </div>
                                                        <div className="col-sm-8 form-group">
                                                            <select
                                                                defaultValue={Mainprops.JobseekerReducer.Physical.eye_sight_id}
                                                                onChange={(event) => Mainthis.handlePhysicalInput(event)}
                                                                className="form-control" id="eye_sight_id"
                                                                name="eye_sight_id">
                                                                {Mainprops.JobseekerReducer.EyeSight.length > 0 &&
                                                                Mainprops.JobseekerReducer.EyeSight.map(function (data, index) {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            // selected={Mainprops.JobseekerReducer.Physical.eye_sight_id === data.id}
                                                                            value={data.id}>{data.name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-lg-6">
                                                    <div className="row">
                                                        <div className="col-sm-4 text-right pr-0">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('profileDetails.weightDesc', {param: 'react'})}>
                                                                {this.props.t('profileDetails.weight', {param: 'react'})}</label>
                                                        </div>
                                                        <div className="col-sm-8 form-group">
                                                            <input
                                                                onChange={(event) => Mainthis.handlePhysicalInput(event)}
                                                                value={Mainprops.JobseekerReducer.Physical.weight ? Mainprops.JobseekerReducer.Physical.weight : ''}
                                                                type="number" className="form-control" id="weight"
                                                                name="weight" placeholder="Enter Weight in kg"
                                                                min={"1"}/>

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4 text-right pr-0">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('profileDetails.bloodGroupDesc', {param: 'react'})}>{this.props.t('profileDetails.bloodGroup', {param: 'react'})}</label>
                                                        </div>
                                                        <div className="col-sm-8 form-group">
                                                            <select
                                                                defaultValue={Mainprops.JobseekerReducer.Physical.blood_group_id}
                                                                onChange={(event) => Mainthis.handlePhysicalInput(event)}
                                                                className="form-control" id="blood_group_id"
                                                                name="blood_group_id">
                                                                {Mainprops.JobseekerReducer.BloodGroups.length > 0 &&
                                                                Mainprops.JobseekerReducer.BloodGroups.map(function (data, index) {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            // selected={Mainprops.JobseekerReducer.Physical.blood_group_id === data.id}
                                                                            value={data.id}>{data.name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                                                      <hr/>
                                            <button onClick={(event) => {
                                                event.preventDefault();
                                                this.handleSubmitPhysical(event)
                                            }} 
                                                    className="mx-auto d-block btn btn-primary btn-primary2 mt-1">{this.props.t('profileDetails.saveNext', {param: 'react'})}</button>

                                        </div>
                                    </section>
                                </div>
                                <div id="menu3" className="tab-pane bg-education fade">
                                   
                                    <section>
                                        
                                        {/* <span style={{'fontSize': '15px', 'color': 'red'}}>*If you are not getting any relative selection, write the content name in selection input field provided. </span> */}
                                        <br/>
                                        {/* <div className="modal fade" id="myModal414" ref={this.skills}>
<div className="modal-dialog">
<div className="modal-content">

    <div className="modal-header">
    <h6 className="modal-title">{this.props.t('profileDetails.skills', {param: 'react'})}</h6>
    <button type="button" className="close" data-dismiss="modal">&times;</button>
    </div>
    
    <div className="modal-body">
      
    </div>
    
    
</div>
</div>
</div> */}

                                        
  <ul className="nav nav-tabs d-flex mb-2" role="tablist">
    <li className="nav-item">
      <a className="nav-link active" data-toggle="pill" href="#edu">{this.props.t('education.basicEducation', {param: 'react'})}</a>
    </li>
    <li className="nav-item">
      <a className="nav-link" data-toggle="pill" id="skillTab" href="#skill">{this.props.t('education.additionalEducation', {param: 'react'})}</a>
    </li>
    {/* <li className="nav-item" style={{"marginLeft":"auto"}}>
        <button onClick={() => this.saveAndNext("menuTab5")} className="btn btn-primary btn-primary2 mt-1 mr-2">{this.props.t('profileDetails.saveNext', {param: 'react'})}</button>
    </li> */}
    
  </ul>
  <div className="tab tab-content border-0">
    <div id="edu" className="container tab-pane active">
    {/* <div className="d-flex flex-row-reverse">
    
    <div className="ml-auto p-1">
        <Link className=" ml-auto d-block mb-3 edu-add mt-2" data-toggle="modal" onClick={() => this.clearEduModal()}
        data-target="#myModal412">
        {this.props.t('profileDetails.addEdu', {param: 'react'})}</Link>
    </div>
    
    </div> */}
    <div>
    <form onSubmit={(event) => {
                                            event.preventDefault();
                                            this.handleSubmitEducation(event)
                                        }}>
                                            <div className="panel-body mb-0 pb-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                    <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('education.qualificationDesc', {param: 'react'})}>
                                                                    {this.props.t('education.qualification', {param: 'react'})}
                                                                    <span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 mb-3">
                                                                <select
                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                    value={Mainstate.Education.highest_education_level ? Mainstate.Education.highest_education_level: ''}
                                                                    className="form-control"
                                                                    id="highest_education_level"
                                                                    name="highest_education_level">
                                                                    <option value="">Select educations</option>
                                                                    {Mainprops.actionReducer.Educations.length > 0 &&
                                                                    Mainprops.actionReducer.Educations.map(function (data, index) {
                                                                        return (
                                                                            <option key={index}
                                                                                    value={data.id}>{data.name}</option>
                                                                        )
                                                                    })
                                                                    }
                                                                </select>
                                                                {eduValidator.message('highest_education_level', 
                                                                this.state.Education.highest_education_level, 'required')}
                                                                {/* {eduValidator.message('guardian phone', 
                                                                this.state.Profile.guardian_phone, 'phone')} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                    <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                        title={this.props.t('education.mediumOfEducationDesc', {param: 'react'})}>
                                                                    {this.props.t('education.mediumOfEducation', {param: 'react'})}
                                                                    <span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 mb-3">
                                                                <select
                                                                    value={Mainstate.Education.course_language_id ? Mainstate.Education.course_language_id : ''}
                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                    className="form-control" id="course_language_id"
                                                                    name="course_language_id">
                                                                    <option value="">Select language</option>
                                                                    {Mainprops.actionReducer.Language.length > 0 &&
                                                                    Mainprops.actionReducer.Language.map(function (data, index) {
                                                                        return (
                                                                            <option
                                                                                key={index}
                                                                                value={data.id}>{data.name}</option>
                                                                        )
                                                                    })
                                                                    }
                                                                </select>
                                                                {eduValidator.message('language', 
                                                                this.state.Education.course_language_id, 'required')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-md-6 mb-4">
                                                <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('education.board/universityDesc', {param: 'react'})}>
                                                                    {this.props.t('education.board/university', {param: 'react'})}
                                                                    <span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 test" id="instituteAuto">
                                                                
                                                                <Autocomplete
                                                                className="w-100 form-control"
                                                                    items={Mainprops.actionReducer.Institution &&
                                                                    Mainprops.actionReducer.Institution}
                                                                    shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                                    renderItem={(item, isHighlighted) =>
                                                                        <div key={item.id}
                                                                            
                                                                             style={{background: isHighlighted ? 'lightgray' : 'white', "borderBottom":"1px solid #ccc"}}>
                                                                            {item.name}
                                                                        </div>
                                                                    }
                                                                    renderInput={(props) => {
                                                                        return <input className="form-control" {...props} />
                                                                    }}
                                                                    wrapperStyle={{"width":"100%"}}
                                                                    menuStyle={{
                                                                        "zIndex": "9",
                                                                        "border":"1px solid #ccc",
                                                                        "overflow": "hidden",
                                                                        "overflowY": "scroll",
                                                                        "boxShadow": "1px 2px 10px #ccc",
                                                                        "padding": "4px",
                                                                        "maxHeight": "123px",
                                                                        "fontSize":"14px"
                                                                    }}
                                                                    value={Mainstate.Education.Univ_board_value ? Mainstate.Education.Univ_board_value : ''}
                                                                    onChange={(e) => this.setEducationLevel(Mainprops.actionReducer.Institution, e.target.value, 'Univ_board', 'Univ_board_value')}
                                                                    getItemValue={(item) => item.name}
                                                                    onSelect={(val) => this.setEducationLevel(Mainprops.actionReducer.Institution, val, 'Univ_board', 'Univ_board_value')}
                                                                />
                                                                

                                                            </div>
                                                            {eduValidator.message('institution', 
                                                                this.state.Education.Univ_board_value, 'required')}
                                                        </div>
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                <div className="row">
                                                
                                                <div className="col-sm-4 text-right pr-0">
                                                    <label  data-placement="bottom"
                                                           title={this.props.t('profileDetails.educationDesc', {param: 'react'})}>
                                                       {this.props.t('education.course', {param: 'react'})}
                                                       <span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-8 test" id="courseAuto">
                                                <Autocomplete
                                                items={ courses}
                                                shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                menuStyle={{
                                                "zIndex": "9",
                                                "border":"1px solid #ccc",
                                                "overflow": "hidden",
                                                "overflowY": "scroll",
                                                "boxShadow": "1px 2px 10px #ccc",
                                                "padding": "4px",
                                                "maxHeight": "123px",
                                                 "fontSize":"14px",
                                               
                                            }}
                                            renderInput={(props) => {
                                                return <input className="form-control" {...props} />
                                            }}
                                            wrapperStyle={{"width":"100%"}}
                                                renderItem={(item, isHighlighted) =>
                                                    <div key={item.id}
                                                         style={{background: isHighlighted ? 'lightgray' : 'white', "borderBottom":"1px solid #ccc",}}>
                                                        {item.label}
                                                    </div>
                                                }
                                                value={Mainstate.Education.education_level_id_value ? Mainstate.Education.education_level_id_value : ''}
                                                onChange={(e) => this.setCourses(educationLevel, e.target.value, 'education_level_id', 'education_level_id_value')}
                                                getItemValue={(item) => item.label}
                                                onSelect={(val) => this.setCourses(educationLevel, val, 'education_level_id', 'education_level_id_value')}
                                            />

                                            {eduValidator.message('education', 
                                                                this.state.Education.education_level_id_value, 'required')}
                                            
                                                </div>

                                            </div>
                                                </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                    {Mainstate.Education.Univ_board && Mainstate.Education.Univ_board === '344' &&
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('education.otherBoardDesc', {param: 'react'})}>
                                                                    {this.props.t('education.otherBoard', {param: 'react'})}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8">

                                                            <input
                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                    value={Mainstate.Education.Univ_board_other ? Mainstate.Education.Univ_board_other : ''}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="Univ_board_other" placeholder="Enter Other University Board"/>
                                                            </div>
                                                        </div>}
                                                        </div>
                                                        <div className="col-md-6">
                                                        {Mainstate.Education.education_level_id && Mainstate.Education.education_level_id === '152' &&
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('experience.othersCourseDesc', {param: 'react'})}>
                                                                    {this.props.t('experience.othersCourse', {param: 'react'})}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8">

                                                            <input
                                                                onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                value={Mainstate.Education.education_level_others ? Mainstate.Education.education_level_others : ''}
                                                                type="text"
                                                                className="form-control"
                                                                name="education_level_others" placeholder="Enter Other Course"
                                                            />
                                                            </div>
                                                        </div> }
                                                        </div>
                                                    </div>
                                                
                                                
                                                <div className="row">
                                                <div className="col-md-6">
                                                <div className="row">
                                                            <div className="col-sm-4 text-right pr-0 mb-3">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('education.scoreTypeDesc', {param: 'react'})}>
                                                                    {this.props.t('education.scoreType', {param: 'react'})}
                                                                    <span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8">

                                                                <select
                                                                onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                className="form-control"
                                                                value={Mainstate.Education.score_type ? Mainstate.Education.score_type : ''}
                                                                name="score_type">
                                                                    <option value="">Select score type</option>
                                                                    <option value="1">{this.props.t('education.percentage/percentile', {param: 'react'})}</option>
                                                                    <option value="2">{this.props.t('education.percentile', {param: 'react'})}</option>
                                                                    <option value="3">{this.props.t('education.grade', {param: 'react'})}</option>
                                                                    <option value="4">{this.props.t('education.CGPA', {param: 'react'})}</option>
                                                                </select>
                                                                {eduValidator.message('score type', 
                                                                this.state.Education.score_type, 'required')}
                                                            </div>
                                                        </div>
                                                </div>
                                                <div className="col-md-6">
                                                {/* here */}
                                                {Mainstate.Education.score_type && Mainstate.Education.score_type ==='1' &&
                                                        <div className="row mt-2">
                                                            <div className="col-sm-4 text-right pr-0 mb-3">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('education.percentage/percentileDesc', {param: 'react'})}>
                                                                    {this.props.t('education.percentage/percentile', {param: 'react'})}

                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <input
                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                    value={Mainstate.Education.percentage ? Mainstate.Education.percentage : ''}
                                                                    type="number"
                                                                    min={"1"}

                                                                    className="form-control" id="percentage"
                                                                    name="percentage" placeholder="Enter Percentage"/>
                                                            </div>
                                                        </div>}
                                                     <div className="row">
                                                <div className="col-md-12">
                                                {Mainstate.Education.score_type && Mainstate.Education.score_type ==='2' &&
                                                        <div className="row mt-2">
                                                            <div className="col-sm-4 text-right pr-0 mb-3">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('education.percentileDesc', {param: 'react'})}>
                                                                    {this.props.t('education.percentile', {param: 'react'})}
                                                                   
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <input
                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                    value={Mainstate.Education.percentile ? Mainstate.Education.percentile : ''}
                                                                    type="number"
                                                                    min={"1"}
                                                                    className="form-control"
                                                                    name="percentile" placeholder="Enter Percentile"/>

                                                            </div>
                                                        </div>}
                                                </div>
                                                
                                                </div>
                                                <div className="row">
                                                <div className="col-md-12">
                                                {Mainstate.Education.score_type && Mainstate.Education.score_type ==='4' &&
                                                        <div className="row mt-2">
                                                            <div className="col-sm-4 text-right pr-0 mb-3">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('education.CGPA', {param: 'react'})}>
                                                                    {this.props.t('education.CGPA', {param: 'react'})}
                                                                   
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <input
                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                    value={Mainstate.Education.CGPA ? Mainstate.Education.CGPA : ''}
                                                                    type="number"
                                                                    min={"1"}
                                                                    className="form-control"
                                                                    name="CGPA" placeholder="Enter CGPA"/>

                                                            </div>
                                                        </div>}
                                                </div>
                                                
                                                </div>
                                                <div className="row">
                                                <div className="col-md-12">
                                                {Mainstate.Education.score_type && Mainstate.Education.score_type ==='3' &&
                                                        <div className="row mt-2">
                                                            <div className="col-sm-4 text-right pr-0 mb-3">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('education.gradeDesc', {param: 'react'})}>
                                                                    {this.props.t('education.grade', {param: 'react'})}
                                                                    
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <input
                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                    value={Mainstate.Education.grade ? Mainstate.Education.grade : ''}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="grade" placeholder="Enter Grade"/>

                                                            </div>
                                                        </div>}
                                                </div>
                                                
                                                </div>
                                                </div>
    
                                                </div>
                                                <div className="row">
                                                                                            
                                                <div className="col-md-6">
                                               
                                                {/* {Mainstate.Education.specilization_id && Mainstate.Education.specilization_id === '284' &&
                                                        <div className="row">
                                                            <div className="col-sm-4 text-right pr-0 mb-3">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('education.otherSpecializationDesc', {param: 'react'})}>
                                                                    {this.props.t('education.otherSpecialization', {param: 'react'})}
                                                                    
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <input
                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                    value={Mainstate.Education.specilization_other ? Mainstate.Education.specilization_other : ''}
                                                                    type="text" className="form-control"
                                                                    id="specilization_other" name="specilization_other"
                                                                    placeholder="Enter Other Specialization"/>
                                                            </div>
                                                        </div>} */}
                                                </div>
                                                </div>
                                                
                                                <div className="row">
                                                <div className="col-md-6">
                                                <div className="row">
<div className="col-sm-4 text-right pr-0 mb-3">
    <label  data-placement="bottom"
           title={this.props.t('education.yearOfPassingDesc', {param: 'react'})}>
        {this.props.t('education.yearOfPassing', {param: 'react'})}
        <span
                                                                                    style={{ color: 'red' }}
                                                                                    className="ml-2">*</span>
    </label>
</div>
<div className="col-sm-8">

    <select className="form-control"
                        onChange={(event) => Mainthis.handleEducationInput(event)}
                        value={this.state.Education.passing_year ? this.state.Education.passing_year : ''}
                        name="passing_year">
                    <option value={""}>Select Year</option>
                    {this.years()}
                </select>
                {eduValidator.message('passing year', this.state.Education.passing_year, 'required')}

</div>
</div>
                                                </div>
                                                <div className="col-md-6">
                                                <div className="row">
                                                            <div className="col-sm-4 text-right pr-0 mb-3">
                                                                <label  data-placement="bottom" 
                                                                       title={this.props.t('education.certificateDesc', {param: 'react'})}>
                                                                    {this.props.t('education.certificate', {param: 'react'})}
                                                                    
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8">

                                                            <input type="file"
                                                                onChange={(event) => Mainthis.handleProfileImage(event, 'errorCertificate')}
                                                                name="img"
                                                                ref={this.institue}
                                                                accept={this.imageFormats.toString()}
                                                                className="my-form-text"
                                                            />
                                                             {errorCertificate &&
                                                                <span style={{"color": "red"}}>{errorCertificate}</span>}
                                                                {edit && this.state.Education.doc && <a rel="noopener noreferrer" className="img-text" target={"_blank"} href={`${SERVER_PATH}${this.state.Education.doc}`}>{`${SERVER_PATH}${this.state.Education.doc}`}</a>}

                                                            </div>
                                                        </div>
                                                </div>
                                                </div>
                                               
                                                <hr/>
                                                <div className="row">
                                                <div className="col-md-12">
                                                    {/* <div className={"btn-group "}> */}
                                                        <div className="row ">
                                                        <div className="col-md-6">
                                                        <button type="submit"
                                                        onClick={() => this.setState({redirect:true})}
                                                                className="btn btn-primary btn-primary2 pull-left">
                                                                    
                                                                    {this.props.t('profileDetails.saveNext', {param: 'react'})}
                                                                    </button>
                                                        </div>
                                                        <div className="col-md-6">

                                                        <button type="submit"
                                                                onClick={() => this.setState({redirect:false})}
                                                                className="btn btn-primary btn-primary2 pull-right">
                                                                    
                                                                    {this.props.t('jobProviderProfile.saveAndMore', {param: 'react'})}
                                                                    </button>
                                                        </div>
                                                        </div>
                                                        

                                                                    
                                                        
                                                    {/* </div> */}
                                                    

                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className={"btn-group mx-auto d-block text-center"}>
                                                        
                                                        
                                                    </div>
                                                    

                                                </div> */}
                                                </div>

                                            </div>

                                        </form>



    </div>
    {Mainprops.JobseekerReducer.Educations.length > 0 &&

<div>
    <hr></hr>



    
    {/* <hr className="mt-2 mb-0"/> */}
    {Mainprops.JobseekerReducer.Educations.map((edata, index) => {
        let html = ''
        
            html = <div className=" bg-education p-0" key={index}>
                
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                        <div className="row">
                            {/* <div
                                className="col-sm-4 col-6 ">
                             <b>   <label>{this.props.t('education.qualification', {param: 'react'})}</label> </b>
                            </div> */}
                            <div className="col-sm-12">
                            
                                {/* {Mainprops.actionReducer.Educations.length > 0 &&
                                Mainprops.actionReducer.Educations.map(function (data, index) {
                                    if (edata.highest_education_level === data.id) {
                                        return (
                                            <label
                                                key={index}>{data.name}</label>
                                        )
                                    }
                                    return ('');
                                })
                                }  */}
                                {edata.education_level_id_value && edata.education_level_id_value !=='Others' && <h6 className="edu-heading-width d-inline-block">{edata.education_level_id_value}</h6>}
                                {edata.education_level_others && edata.education_level_id_value ==='Others' && <h6 className="edu-heading-width d-inline-block">{edata.education_level_others}</h6>}
                                <span><Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    Mainthis.handleEditEducation(index)
                                }}
                                   className="edit-btn  pull-right" ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                <Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    this.setState({delOption:'edu', delIndex: index})
                                    // Mainthis.handleDeleteEducation(index)
                                }}
                                data-toggle="modal" data-target="#exampleModal" className="edit-btn mr-3 pull-right"><i className="fa fa-trash" aria-hidden="true"></i></Link></span>
                            </div>
                        </div>
                        <div className="row">
                             <div
                                className="col-sm-4 col-6">
                             <label>{this.props.t('education.board/university', {param: 'react'})}:</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                {/* {Mainprops.actionReducer.Institution.length > 0 &&
                                Mainprops.actionReducer.Institution.map(function (data, index) {
                                    if (edata.Univ_board === data.id) {
                                        return (
                                            <label
                                                key={index}>{data.name}</label>
                                        )
                                    }
                                    return ('');
                                })
                                } */}
                               {edata.Univ_board_value && edata.Univ_board_value !== 'Others' && <label> {edata.Univ_board_value}</label>}
                               {edata.Univ_board_other && edata.Univ_board_value === 'Others' && <label> {edata.Univ_board_other}</label>}
                            </div>
                        </div>
                        {/* {edata.specilization_id && 
                        <div className="row">
                            <div
                                className="col-sm-4 col-6 ">
                                <label>{this.props.t('education.specialization/majorInstitute', {param: 'react'})}:</label>
                            </div>
                            <div className="col-sm-8 col-6 ">
                                <label>{this.getSpecialization(edata.specilization_id)}</label>
                            </div>
                        </div>}

                        {edata.specilization_other && 
                        <div className="row">
                            <div
                                className="col-sm-4 col-6 ">
                                <label>{this.props.t('education.otherSpecialization', {param: 'react'})}:</label>
                            </div>
                            <div className="col-sm-8 col-6 ">
                                <label>{edata.specilization_other}</label>
                            </div>
                        </div>} */}


                        
                        {edata.score_type === '1' && 
                        <div className="row">
                        <div className="col-sm-4  col-6">
                            <label>{this.props.t('education.percentage/percentile', {param: 'react'})}:</label>
                        </div>
                        <div className="col-sm-8 col-6">
                            <label>{edata.percentage}</label>
                        </div>
                    </div>
                    }
                    {edata.score_type === '2' && 
                        <div className="row">
                            <div className="col-sm-4  col-6">
                                <label>{this.props.t('education.percentile', {param: 'react'})}:</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                <label>{edata.percentile}</label>
                            </div>
                        </div>}
                        {edata.score_type === '3' && 
                        <div className="row">
                            <div className="col-sm-4  col-6">
                                <label>{this.props.t('education.grade', {param: 'react'})}:</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                <label>{edata.grade}</label>
                            </div>
                        </div>}
                        {edata.score_type === '4' && 
                        <div className="row">
                            <div className="col-sm-4  col-6">
                                <label>{this.props.t('education.CGPA', {param: 'react'})}:</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                <label>{edata.CGPA}</label>
                            </div>
                        </div>}
                        <hr/>
                        {/* <div className="row">
                            <div
                                className="col-sm-4 col-6">
                                <label>{this.props.t('education.education', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6 ">
                                {Mainprops.actionReducer.Educations && Mainprops.actionReducer.Educations.length > 0 &&
                                Mainprops.actionReducer.Educations.map(function (data, index) {
                                    if (edata.highest_education_level === data.id) {
                                        return (
                                            <label
                                                key={index}>{data.name}</label>
                                        )
                                    }
                                    return ('');
                                })
                                }
                            </div>
                        </div>
                        {edata.Univ_board_other &&
                        <div className="row">
                            <div
                                className="col-sm-4 col-6 ">
                                <label>{this.props.t('education.otherBoard', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                <label>{edata.Univ_board_other}</label>
                            </div>
                        </div>}

                        <div className="row"> 
                            <div className="col-sm-4 ">
                                <label>{this.props.t('education.percentage/percentile', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                <label>{edata.percentage}</label>
                            </div>
                        </div>*/}
                    </div>
                   
                    <div className="col-md-12 col-sm-12 col-lg-6">
                        {/* <div className="row">
                            <div
                                className="col-sm-4 col-6 text-right">
                                <label>{this.props.t('education.specialization/majorInstitute', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6 ">
                                {Mainprops.actionReducer.Specilization.length > 0 &&
                                Mainprops.actionReducer.Specilization.map(function (data, index) {
                                    if (edata.specilization_id === data.id) {
                                        return (
                                            <label
                                                key={index}>{data.name}</label>
                                        )
                                    }
                                    return ('');
                                })
                                }
                            </div>
                        </div> */}
                        {/* {edata.specilization_other &&
                        <div className="row">
                            <div
                                className="col-sm-4 col-6 text-right">
                                <label>{this.props.t('education.otherSpecialization', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                <label>{edata.specilization_other}</label>
                            </div>
                        </div>} */}
                       

                        {/* <div className="row">
                            <div
                                className="col-sm-4 col-6 ">
                                <label>{this.props.t('education.yearOfPassing', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6 ">
                                <label>{edata.passing_year}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="col-sm-4 col-6 ">
                                <label>{this.props.t('education.mediumOfEducation', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                {Mainprops.actionReducer.Language.length > 0 &&
                                Mainprops.actionReducer.Language.map(function (data, index) {
                                    if (edata.course_language_id === data.id) {
                                        return (
                                            <label
                                                key={index}>{data.name}</label>
                                        )
                                    }
                                    return ('');
                                })
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ">
                                <label>{this.props.t('education.percentile', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                <label>{edata.percentile}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <label>{this.props.t('education.grade', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                <label>{edata.grade}</label>
                            </div>
                        </div>
                        */}
                    </div>
                   
                </div>
            </div>
              
        
        return html
    })
    }
    
</div>


}
    </div>
    <div id="skill" className="container tab-pane fade p-0">
        <div>
        <form onSubmit={this.handleSubmitSkills}>
                                            <div className="panel-body mb-0 pb-3">
                                                
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-lg-6">
                                                        <div className="row">
                                                            <div className="col-sm-4  text-right">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('experience.skill_nameDesc', {param: 'react'})}>
                                                                    {this.props.t('experience.skill_name', {param: 'react'})}
                                                                    <span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group">
                                                                <select
                                                                    value={skill.skill_id ? skill.skill_id : ''}
                                                                    onChange={Mainthis.handleSkill}
                                                                    className="form-control"
                                                                    name={"skill_id"}
                                                                >
                                                                    <option value={""}>Select skill</option>
                                                                    {this.props.actionReducer.userSkills && this.props.actionReducer.userSkills.length
                                                                    && this.props.actionReducer.userSkills.map((skill, key) => {
                                                                        return(
                                                                            <option key={key} value={skill.id}>{skill.name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                {skillValidator.message('skill name', skill.skill_id, 'required')}

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4  text-right">
                                                                <label data-placement="bottom"
                                                                       title={this.props.t('experience.durationDesc', {param: 'react'})}>
                                                                    {this.props.t('skillProvider.course_duration', {param: 'react'})}
                                                                    <span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group ">
                                                                <input type="number"
                                                                       value={skill.skill_duration ? skill.skill_duration : ''}
                                                                       onChange={Mainthis.handleSkill}
                                                                       name="skill_duration"
                                                                       className="form-control"
                                                                       placeholder={"Enter Skill Duration"}
                                                                />
                                                                {skillValidator.message('skill_duration', skill.skill_duration, 'required')}
                                                                {/*{expValidator.message('organisation name', Mainstate.Experience.org_name, 'required')}*/}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4  text-right">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('education.yearOfPassingDesc', {param: 'react'})}>
                                                                    {this.props.t('education.yearOfPassing', {param: 'react'})}
                                                                    <span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group ">
                                                                <select className="form-control"
                                                                        onChange={ this.handleSkill}
                                                                        value={this.state.skill.passing_year ? this.state.skill.passing_year : ''}
                                                                        name="passing_year">
                                                                    <option value={""}>Select passing year</option>
                                                                    {this.years()}
                                                                </select>
                                                                {skillValidator.message('passing_year', skill.passing_year, 'required')}
                                                                {/*<DatePicker*/}
                                                                {/*    dateFormat="dd/MM/yyyy"*/}
                                                                {/*    showYearDropdown={true}*/}
                                                                {/*    showMonthDropdown={true}*/}
                                                                {/*    className="form-control"*/}
                                                                {/*    selected={Mainstate.skill.passing_year ? Date.parse(Mainstate.skill.passing_year) : ''}*/}
                                                                {/*    onChange={this.handleChangePassingYearSkill}*/}
                                                                {/*/>*/}
                                                                {/*{expValidator.message('organisation name', Mainstate.Experience.org_name, 'required')}*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-sm-12 col-lg-6">
                                                    {skill.skill_id === '213' && <div className="row">
                                                            <div className="col-sm-4  text-right">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('experience.otherSkillDesc', {param: 'react'})}>
                                                                    {this.props.t('experience.otherSkill', {param: 'react'})}
                                                                    {/*<span*/}
                                                                    {/*className="ml-2"*/}
                                                                    {/*style={{"color": "red"}}>*</span>*/}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group ">
                                                                <input type="text"
                                                                       onChange={Mainthis.handleSkill}
                                                                       name="others"
                                                                    //    ref={this.institue}
                                                                       id={'others'}
                                                                       value={skill.others ? skill.others : ''}
                                                                       className="form-control"
                                                                       placeholder={"Enter Any Other Skill"}
                                                                />
                                                                {/*{expValidator.message('salary type', Mainstate.Experience.salary_type, 'required')}*/}
                                                            </div>
                                                        </div>}
                                                        <div className="row">
                                                            <div className="col-sm-4  text-right">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('experience.instituteDesc', {param: 'react'})}>
                                                                    {this.props.t('experience.institute', {param: 'react'})}
                                                                    <span
                                                                    className="ml-2"
                                                                    style={{"color": "red"}}>*</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group ">
                                                                <input type="text"
                                                                       onChange={Mainthis.handleSkill}
                                                                       name="institution_name"
                                                                    //    ref={this.institue}
                                                                       id={'institue'}
                                                                       value={skill.institution_name ? skill.institution_name : ''}
                                                                       className="form-control"
                                                                       placeholder={"Enter Institution Name"}
                                                                />
                                                                {skillValidator.message('institution_name', skill.institution_name, 'required')}
                                                                {/*{expValidator.message('salary type', Mainstate.Experience.salary_type, 'required')}*/}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4  text-right">
                                                                <label  data-placement="bottom"
                                                                       title={this.props.t('experience.certificateDesc', {param: 'react'})}>
                                                                    {this.props.t('experience.certificate', {param: 'react'})}
                                                                    {/*<span*/}
                                                                    {/*className="ml-2"*/}
                                                                    {/*style={{"color": "red"}}>*</span>*/}
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-8 form-group ">
                                                                <input type="file"
                                                                       onChange={(e) => Mainthis.handleProfileImage(e, 'errorSkill')}
                                                                       name="img"
                                                                       ref={this.certificateRef}
                                                                       accept={this.imageFormats.toString()}
                                                                       className="my-form-text"
                                                                />
                                                                {errorSkill &&
                                                                <span style={{"color": "red"}}>{errorSkill}</span>}
                                                                {edit && skill.skill_doc && <a rel="noopener noreferrer" className="img-text" target={"_blank"} href={`${SERVER_PATH}${skill.skill_doc}`}>{`${SERVER_PATH}${skill.skill_doc}`}</a>}
                                                                {/*{edit && <img width={"75"} height={"75"} src={`${SERVER_PATH}${skill.skill_doc}`}*/}
                                                                {/*                     alt={"Skill doc"}/>}*/}
                                                                {/*{expValidator.message('salary type', Mainstate.Experience.salary_type, 'required')}*/}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12"> 
                                                        <div className={"btn-group mx-auto d-block text-center"}>
                                                            <button type="submit"
                                                                    className="btn btn-primary btn-primary2 mt-1 mr-2">
                                                                        {this.props.t('profileDetails.saveNext', {param: 'react'})}
                                                                    </button>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                        </form>
  
        </div>
        <hr/>
    {/* <div className="d-flex flex-row-reverse">
    
    <div className="p-2"><Link className="edu-add pull-right ml-auto mb-3 mt-2 d-block" onClick={() => {
            this.setState({skill: [], edit:false, btnText:this.props.t('profileDetails.add', {param: 'react'})})
            this.certificateRef.current.value = ''
        }} data-toggle="modal" data-target="#myModal414">
        {this.props.t('profileDetails.addSkill', {param: 'react'})}  
        </Link></div>
    </div> */}
    {Mainprops.JobseekerReducer.additionalSkills &&
                                                    Mainprops.JobseekerReducer.additionalSkills.length > 0 &&
                                                    <div className="col-md-12 p-0">
                                                        
                                                        
                                                        {/* <hr className="mt-2 mb-0"/> */}

                                                        {Mainprops.JobseekerReducer.additionalSkills.map((edata, index) => {
                                                            return (
                                                                <div className="col-md-12 bg-education" key={index}>
                                                                    {/* <div className=" row">
                                                                        <div className="col-md-12">
                                                                            
                                                                        </div>
                                                                    </div> */}
                                                                    <div className=" row">
                                                                        <div className="col-md-12 col-sm-12">
                                                                            <div className="row">
                                                                                {/* <div className="col-sm-4 col-6 ">
                                                                                    <label>{this.props.t('experience.skill_name', {param: 'react'})}</label>
                                                                                </div> */}
                                                                                <div className="col-sm-12 ">
                                                                                   {edata.skill_name && edata.skill_name !== 'others' && <h6 className={"d-inline-block edu-heading-width"}>{edata.skill_name}</h6>}
                                                                                   {edata.others && edata.skill_name === 'others' && <h6 className={"d-inline-block edu-heading-width"}>{edata.others}</h6>}
                                                                                   <span>
                                                                                   <Link  onClick={(event) => {
                                                                                event.preventDefault();
                                                                                Mainthis.handleEditSkill(index)
                                                                            }}
                                                                               className="edit-btn pull-right" 
                                                                            //    data-toggle="modal" data-target="#myModal414"
                                                                               ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                                                            <Link to="#" onClick={(event) => {
                                                                                event.preventDefault();
                                                                                this.setState({delOption:'skill', delIndex: edata.id})
                                                                                // Mainthis.handleDeleteSkill(edata.id)
                                                                            }}
                                                                            data-toggle="modal" data-target="#exampleModal" className="edit-btn mr-3 pull-right"><i className="fa fa-trash" aria-hidden="true"></i></Link>
                                                                                   </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 col-sm-6 ">
                                                                            <div className="row">
                                                                                <div className="col-sm-6 col-6">
                                                                                <label>{this.props.t('experience.institute', {param: 'react'})}:</label>
                                                                                </div>
                                                                                <div className="col-sm-6 col-6 ">
                                                                                <label>{edata.institution_name}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                      </div>
                                                                      <div className=" row">
                                                                        <div className="col-md-12 col-sm-6">
                                                                            <div className="row">
                                                                                <div className="col-sm-6 col-6">
                                                                                    <label>{this.props.t('skillProvider.course_duration', {param: 'react'})}:</label>
                                                                                </div>
                                                                                <div className="col-sm-6 col-6">
                                                                                    <label>{edata.skill_duration}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div> 
                                                                        {/* <div className="col-md-12 col-sm-6">
                                                                            <div className="row">
                                                                                <div className="col-sm-6 ">
                                                                                    <label>{this.props.t('experience.certificate', {param: 'react'})}</label>
                                                                                </div>
                                                                                <div className="col-sm-6  ">
                                                                                    <img width={"75"} height={"75"} src={`${SERVER_PATH}${edata.skill_doc}`}
                                                                                     alt={"Skill doc"}/>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                          <div className="col-md-12 col-sm-6">
                                                                              <div className="row">
                                                                                  <div className="col-sm-6 col-6">
                                                                                      <label>{this.props.t('education.yearOfPassing', {param: 'react'})}:</label>
                                                                                  </div>
                                                                                  <div className="col-sm-6 col-6">
                                                                                      <label>{edata.passing_year}</label>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                    <hr />
                                                                        
                                                                    {/* <div className="row">
                                                                    <div className="col-md-12 col-sm-12 ">

                                                                        <div className="row">
                                                                            <div className="col-sm-8 col-6 text-right">
                                                                                <label>{this.props.t('experience.certificate', {param: 'react'})}</label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-6">
                                                                                <img width={"75"} height={"75"} src={`${SERVER_PATH}${edata.skill_doc}`}
                                                                                     alt={"Skill doc"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div> 
                                                                    </div>*/}
                                                                {/* <hr className={"mb-0"}/> */}
                                                                </div>
                                                            )
                                                        })
                                                        }


                                                    </div>
                                                    }
    </div>
  </div>
                                        
                                    </section>
                                </div>
                                <div id="menu4" className="tab-pane bg-education fade">
                                    <br/>
                                    
                                    <section>
                                   
                                   
     <div className="row">
        <div className="col-md-8">
        <h6 className="mb-0 mt-3">{this.props.t('profileDetails.experience', {param: 'react'})}</h6>
        </div>
      
    </div>
    <hr></hr>
    <div className="row">
    <div className="col-sm-12 col-lg-12">
                                                    <div className="row">
                                                        <div className="col-sm-4 text-left">
                                                            <label >{this.props.t('profileDetails.experienced_inst', {param: 'react'})}
                                                                   <span
                                                                        className="ml-2"
                                                                        style={{"color": "red"}}>*</span></label>
                                                        </div>
                                                        <div className="col-sm-4 form-group pr-0">
                                                            <div className="form-check-inline mr-0 pr-2">
                                                                <label className="form-check-label" htmlFor="radio33">
                                                                    <input
                                                                        onChange={(event) => Mainthis.handleExperienceRadio(event)}
                                                                        checked={Mainstate.experienced === '1'}
                                                                        type="radio" className="form-check-input"
                                                                         name="experienced"
                                                                        value="1"/>{this.props.t('profileDetails.yes', {param: 'react'})}
                                                                </label>
                                                            </div>
                                                            <div className="form-check-inline mr-0 pr-0">
                                                                <label className="form-check-label" htmlFor="radio44">
                                                                    <input
                                                                        onChange={(event) => Mainthis.handleExperienceRadio(event)}
                                                                        checked={Mainstate.experienced === '0'}
                                                                        type="radio" className="form-check-input"
                                                                        name="experienced"
                                                                        value="0"/>{this.props.t('profileDetails.no', {param: 'react'})}
                                                                </label>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    {Mainstate.experienced ==='0' ? 
                                                    <>
                                                    <hr></hr>
                                                    <div className="row">
                                                        
                                                        <div className="col-sm-12 mb-0 pb-2">
                                                        <button 
                                                            onClick={this.saveExp}
                                                            className="mx-auto d-block btn btn-primary btn-primary2 mb-2">
                                                                {/* {this.props.t('experience.Submit', {param: 'react'})} */}
                                                                {/* {this.state.btnText} */}
                                                                
                                                                {this.props.t('profileDetails.submit', {param: 'react'})}
                                                                </button>
                                                                </div>
                                                    </div> </>: null }
                                                </div>
    </div>
    {Mainstate.experienced ==='1' ? 

<>
<hr></hr>

<div>
<form onSubmit={(event) => {
                                        event.preventDefault();
                                        this.handleSubmitExperience(event)
                                    }}>
                                        <div className="panel-body mb-0 pb-3">
                                        

                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-lg-6">
                                                <div className="row">
                                                        <div className="col-sm-6">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('experience.Orgnisation NameDesc', {param: 'react'})}>
                                                                {this.props.t('experience.Orgnisation Name', {param: 'react'})}
                                                                <span
                                                                className="ml-2"
                                                                style={{"color": "red"}}>*</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group ">
                                                            <input type="text" className="form-control"
                                                                   onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                   value={Mainstate.Experience.org_name ? Mainstate.Experience.org_name : ''}
                                                                   id="org_name" name="org_name"
                                                                   placeholder="Enter Organisation Name"
                                                            />
                                                            {expValidator.message('organisation name', Mainstate.Experience.org_name, 'required')}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('experience.Joining DateDesc', {param: 'react'})}>{this.props.t('experience.Joining Date', {param: 'react'})}
                                                                  <span
                                                                className="ml-2"
                                                                style={{"color": "red"}}>*</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group">
                                                            {/*<input type="date" className="form-control"*/}
                                                            {/*   onChange={(event) => Mainthis.handleExperienceInput(event)}*/}
                                                            {/*   value={Mainstate.Experience.date_from ? Mainstate.Experience.date_from : ''}*/}
                                                            {/*   id="date_from" name="date_from"*/}
                                                            {/*   placeholder="Enter Joining Date"*/}
                                                            {/*/>*/}
                                                            <DatePicker
                                                                dateFormat="dd/MM/yyyy"
                                                                className="form-control"
                                                                placeholderText="Enter date from"
                                                                minDate={new Date("01-01-1950")}
                                                                maxDate={new Date()}
                                                                showYearDropdown={true}
                                                                showMonthDropdown={true}
                                                                selected={Mainstate.Experience.date_from ? Date.parse(Mainstate.Experience.date_from) : ''}
                                                                onChange={(e) => this.handleChangeDateFrom(e, "date_from")}
                                                            />
                                                            {errorDateFrom && <span  className="error-msg">{errorDateFrom}</span>}
                                                            {expValidator.message('Joining date', Mainstate.Experience.date_from, 'required')}

                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <label data-placement="bottom"
                                                                   title={this.props.t('experience.StateDesc', {param: 'react'})}>
                                                                {this.props.t('experience.State', {param: 'react'})}
                                                                {/*<span*/}
                                                                {/*className="ml-2"*/}
                                                                {/*style={{"color": "red"}}>*</span>*/}
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group ">
                                                            <select
                                                                value={Mainstate.Experience.state_id ? Mainstate.Experience.state_id : ''}
                                                                onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                name="state_id" className="form-control">
                                                                <option value="">Select State</option>
                                                                {this.creaeduValidatorate()}
                                                            </select>
                                                            {/*{expValidator.message('state', Mainstate.Experience.state_id, 'required')}*/}
                                                        </div>
                                                    </div>
                                                    {Mainstate.Experience.state_id && Mainstate.Experience.state_id === '3' &&
                                                    <div className="row">
                                                        <div className="col-sm-6 ">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('experience.LocationDesc', {param: 'react'})}>
                                                                {this.props.t('experience.Location', {param: 'react'})}
                                                                {/*<span*/}
                                                                {/*className="ml-2"*/}
                                                                {/*style={{"color": "red"}}>*</span>*/}
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group ">
                                                            <select
                                                                value={Mainstate.Experience.location ? Mainstate.Experience.location : ''}
                                                                onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                name="location" className="form-control">
                                                                <option value="">Select Location</option>
                                                                {this.createDistoption2()}
                                                            </select>
                                                            {/*{expValidator.message('location', Mainstate.Experience.location, 'required')}*/}
                                                        </div>
                                                    </div>}
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('experience.SalaryDesc', {param: 'react'})}>
                                                                {this.props.t('experience.Salary', {param: 'react'})}
                                                                <span
                                                                className="ml-2"
                                                                style={{"color": "red"}}>*</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group ">
                                                            <input type="number" min="1" className="form-control"
                                                                   onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                   value={Mainstate.Experience.salary ? Mainstate.Experience.salary : ''}
                                                                   id="salary" name="salary"
                                                                   placeholder="Enter Salary"/>
                                                            <span className="edit-img text-center">(INR per month)</span>
                                                            {expValidator.message('salary', Mainstate.Experience.salary, 'required|max:8')}
                                                        </div>
                                                    </div>
                                                    {/* <div className="row">
                                                        <div className="col-sm-4 ">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('experience.Salary typeDesc', {param: 'react'})}>
                                                                {this.props.t('experience.Salary type', {param: 'react'})}
                                                                
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 form-group ">
                                                            <select value={Mainstate.Experience.salary_type ? Mainstate.Experience.salary_type : ''}
                                                                    onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                    className="form-control"
                                                                    id="salary_type" name="salary_type">
                                                                <option value="">Select salary type</option>
                                                                {Mainprops.JobpostReducer.salaryType.length > 0 &&
                                                                Mainprops.JobpostReducer.salaryType.map(function (data, index) {
                                                                    return (
                                                                        <option key={index}
                                                                                value={data.id}>{data.name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div> */}

<div className="row">
                                                        <div className="col-sm-6">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('experience.durationDesc', {param: 'react'})}>
                                                                Experience (in years)
                                                                
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group">
                                                            <input type="number" className="form-control"
                                                                   onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                   value={Mainstate.Experience.duration ? Mainstate.Experience.duration : ''}
                                                                   name="duration"
                                                                   placeholder="Enter Experience"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12 col-lg-6 ">
                                                <div className="row">
                                                        <div className="col-sm-4 offset-md-2">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('experience.PositionDesc', {param: 'react'})}>{this.props.t('experience.Position', {param: 'react'})}
                                                                {/*   <span*/}
                                                                {/*className="ml-2"*/}
                                                                {/*style={{"color": "red"}}>*</span>*/}
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group">
                                                            <input type="text" className="form-control"
                                                                   onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                   value={Mainstate.Experience.position ? Mainstate.Experience.position : ''}
                                                                   id="position" name="position"
                                                                   placeholder="Enter Position"/>
                                                            {/*{expValidator.message('position', Mainstate.Experience.position, 'required')}*/}
                                                            <span className="sub_headings">Enter your position/designation in the organization </span>
                                                            {expValidator.message('position', Mainstate.Experience.position, 'max:50')}
                                                        </div>
                                                        
                                                    </div>
                                                    {this.state.still_working === "2" &&
                                                    <div className="row">
                                                        <div className="col-sm-4 offset-md-2">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('experience.Relieving DateDesc', {param: 'react'})}>{this.props.t('experience.Relieving Date', {param: 'react'})}
                                                                {/*   <span*/}
                                                                {/*className="ml-2"*/}
                                                                {/*style={{"color": "red"}}>*</span>*/}
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group ">
                                                            {/*<input type="date" className="form-control"*/}
                                                            {/*       onChange={(event) => Mainthis.handleExperienceInput(event)}*/}
                                                            {/*       value={Mainstate.Experience.date_to ? Mainstate.Experience.date_to : ''}*/}
                                                            {/*       id="date_to" name="date_to"*/}
                                                            {/*       placeholder="Enter relieving date"/>*/}
                                                            <DatePicker
                                                                dateFormat="dd/MM/yyyy"
                                                                className="form-control w-100"
                                                                placeholderText="Enter date to"
                                                                showYearDropdown={true}
                                                                minDate={new Date("01-01-1950")}
                                                                maxDate={new Date()}
                                                                showMonthDropdown={true}
                                                                selected={Mainstate.Experience.date_to ? Date.parse(Mainstate.Experience.date_to) : ''}
                                                                onChange={(e) => this.handleChangeDateFrom(e, "date_to")}
                                                            />
                                                            {errorDateTo && <span className="error-msg">{errorDateTo}</span>}
                                                            {/*{expValidator.message('date to', Mainstate.Experience.date_to, 'required')}*/}
                                                            <span className="sub_headings">If you are currently working, select yes to the radio button below </span>
                                                        </div>
                                                    </div>}

                                                    <div className="row">
                                                        <div className="col-sm-4 offset-md-2">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('experience.stillWorkingDesc', {param: 'react'})}>{this.props.t('experience.stillWorking', {param: 'react'})}
                                                                {/*   <span*/}
                                                                {/*className="ml-2"*/}
                                                                {/*style={{"color": "red"}}>*</span>*/}
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group pr-0">
                                                            <div className="form-check-inline mr-0 pr-1">
                                                                <label className="form-check-label"
                                                                       htmlFor="radio1">
                                                                    <input required type="radio"
                                                                           onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                           checked={this.state.still_working === '1'}
                                                                           className="form-check-input"
                                                                           name="still_working"
                                                                           value={"1"}/>
                                                                    {this.props.t('experience.yes', {param: 'react'})}
                                                                </label>
                                                            </div>
                                                            <div className="form-check-inline mr-0 pr-1">
                                                                <label className="form-check-label"
                                                                       htmlFor="radio26">
                                                                    <input required type="radio"
                                                                           onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                           checked={this.state.still_working === '2'}
                                                                           className="form-check-input"
                                                                           name="still_working"
                                                                           value={"2"}/>
                                                                    {this.props.t('experience.no', {param: 'react'})}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {this.state.still_working ==='1' && 
                                                    <div className="row">
                                                        <div className="col-sm-4 offset-md-2">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('JobPost.experienceMonths', {param: 'react'})}>
                                                                       {this.props.t('JobPost.experienceMonths', {param: 'react'})}
                                                                
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group pr-0">
                                                        <input type="number" className="form-control"
                                                            onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                            value={Mainstate.Experience.durationMonths ? Mainstate.Experience.durationMonths : ''}
                                                            name="durationMonths"
                                                            placeholder="Enter Experience"
                                                        />
                                                        </div>
                                                    </div>} */}
                                                    {/* <div className="row">
                                                        <div className="col-sm-4 offset-md-2 ">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('experience.Shift typeDesc', {param: 'react'})}>
                                                                {this.props.t('experience.Shift type', {param: 'react'})}
                                                                
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group ">
                                                            <select value={Mainstate.Experience.shift_type ? Mainstate.Experience.shift_type : ''}
                                                                    onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                    className="form-control" id="shift_type"
                                                                    name="shift_type">
                                                                <option value="">Select shift type</option>
                                                                {Mainprops.JobpostReducer.jobShift.length > 0 &&
                                                                Mainprops.JobpostReducer.jobShift.map(function (data, index) {
                                                                    return (
                                                                        <option key={index}
                                                                                value={data.id}>{data.name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="row">
                                                        <div className="col-sm-4 offset-md-2 ">
                                                            <label  data-placement="bottom"
                                                                   title={this.props.t('JobPost.employerTypeDesc', {param: 'react'})}>
                                                                {this.props.t('JobPost.employerType', {param: 'react'})}
                                                                
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 form-group ">
                                                            <select
                                                                value={Mainstate.Experience.type_of_employer ? Mainstate.Experience.type_of_employer : ''}
                                                                onChange={(event) => Mainthis.handleExperienceInput(event)}
                                                                className="form-control"
                                                                id="type_of_employer" name="type_of_employer">
                                                                <option value="">Select employer type</option>
                                                                {this.props.actionReducer.Coursenature && this.props.actionReducer.Coursenature.length &&
                                                                this.props.actionReducer.Coursenature.map((cor, key) => {
                                                                    return(
                                                                        <option key={key} value={cor.id}>{cor.name}</option>
                                                                    )
                                                                })}

                                                            </select>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                          

                                            <div className="col-md-12">
                                                <div className={"btn-group mx-auto d-block text-center"}>
                                                    <button type="submit"
                                                            className="btn btn-primary btn-primary2 mt-1 mr-2">
                                                                {/* {this.props.t('experience.Submit', {param: 'react'})} */}
                                                                {/* {this.state.btnText} */}
                                                                
                                                                {this.props.t('profileDetails.submit', {param: 'react'})}
                                                                </button>
                                                    {/* <button type="button" onClick={(event) => {
                                                        event.preventDefault();
                                                        this.handleClearExperience(event)
                                                    }}
                                                            className="btn btn-secondary  mt-1">{this.props.t('experience.reset', {param: 'react'})}</button> */}
                                                </div>

                                            </div>
                                            
                                        </div>
                                    </form>

</div>
<div className="d-flex">
  <div className="p-2">
  {parseInt(Mainprops.JobseekerReducer.experience.total) > 0 &&
    <b><label>{this.props.t('experience.totalExp', {param: 'react'})}:</label> { " "+Mainprops.JobseekerReducer.experience.total}</b>}
  </div>
 
</div>
{Mainprops.JobseekerReducer.experience.data && Mainprops.JobseekerReducer.experience.data.length > 0 &&

<div className="col-md-12 p-0">
    
    {/* <h6 className="mb-0 mt-3">{this.props.t('experience.list', {param: 'react'})}</h6> */}
    <hr className="mt-2 "/>
   
    
    
 
    { 
    Mainprops.JobseekerReducer.experience.data.map((edata, index) => {
        return (
            <div className=" bg-education p-0" key={index}>
                
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12 profile-label">
                    <div className="row">
                            {/* <div className="col-sm-6 col-6 ">
                                <label>{this.props.t('experience.Orgnisation Name', {param: 'react'})}:</label>
                            </div> */}
                            <div className="col-sm-12 ">
                                <b><label>{edata.org_name}</label></b>
                                <span>
                                    
                            <Link onClick={(event) => {
                            event.preventDefault();
                            Mainthis.handleEditExperience(index)
                            }}
                            className="edit-btn pull-right"
                            // data-toggle="modal" data-target="#myModal413"
                            >
                            <i className="fa fa-pencil" aria-hidden="true"></i></Link>
                        <Link  to="#" onClick={(event) => {
                            event.preventDefault();
                            
                            this.setState({delOption:'exp', delIndex: index})
                            // Mainthis.handleDeleteExperience(index)
                        }}
                        data-toggle="modal" data-target="#exampleModal" className="edit-btn mr-3 pull-right"><i className="fa fa-trash" aria-hidden="true"></i></Link>
                                </span>
                            </div>
                        </div>
                        {edata.position && 
                        <div className="row">
                            <div className="col-sm-4 col-6">
                                <label>{this.props.t('experience.Position', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6 ">
                                <label>{edata.position}</label>
                            </div>
                        </div>}
                        {edata.duration && 
                        <div className="row">
                            <div className="col-sm-4 col-6">
                                <label>{this.props.t('experience.duration', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6 ">
                                <label>{edata.duration}</label>
                            </div>
                        </div>}
                        {edata.date_from && 
                        <div className="row">
                            <div className="col-sm-4 col-6">
                                <label>{this.props.t('experience.Joining Date', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                <label>{edata.date_from}</label>
                            </div>
                        </div>}
                        
                        {edata.date_to &&
                       <div className="row">
                       <div className="col-sm-4 col-6 ">
                           <label>{this.props.t('experience.Relieving Date', {param: 'react'})}</label>
                       </div>
                       <div className="col-sm-8 col-6">
                           <label>{edata.date_to}</label>
                       </div>
                   </div>
                    } 
                   
                        {edata.salary && 
                        <div className="row">
                            <div className="col-sm-4 col-6">
                                <label>{this.props.t('experience.Salary', {param: 'react'})}</label>
                            </div>
                            <div className="col-sm-8 col-6">
                                <label>{edata.salary}</label>
                            </div>
                        </div>}
                        
                    </div> 
                </div>
                <hr className={"mb-0 pb-3"}/>
            </div>
        )
    })
    }
</div>
}
 
</>

    : null}
    

                                    </section>
                                </div>
  
                                                              </div>
                            </div>
                        : ''}
                        </main>
                        <div className="modal fade" id="profileModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Profile</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        Your have successfully completed your profile updation, Click next to search jobs.
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary btn-primary2 mt-1 mr-2" onClick={() => this.props.router.push('jobsearch')} data-dismiss="modal">Next</button>
        <button type="button" className="btn btn-primary btn-primary2 mt-1 mr-2" data-dismiss="modal">Close</button>
        {/* <button type="button" className="btn btn-primary">Save changes</button> */}
      </div>
    </div>
  </div>
</div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Myprofile));
