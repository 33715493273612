import React, { Component } from 'react';
// import { Link } from 'react-router';
import {translate} from "react-multi-lang";

class skilldevelopmentEnplanement extends Component {

    componentDidMount() {
        document.title = this.props.t('menu.aboutUs', {param: 'react'})
    }
    
    render() {
        return (
            <div>
                <section className="top-inner main-content-height animatedParent">
                    <div className="container" >
                        <div className="first-click1 mb-4 mt-4">
                      <div className={"row"}>

                          <div className={"col-md-4 col-sm-6 col-lg-3"}>
                              <img alt="/" src="/images/skill_course.jpg" className="img-fluid mx-auto d-block w-100"/>
                          </div>
                          <div className={"col-md-8 col-sm-6 col-lg-9"}>
                              <h4 className={""}>About Us</h4>

                          <p className="about-heading text-bold" style={{textAlign:'justify'}}>
                          The Government has set up Punjab Skill Development Mission (PSDM) to bring necessary synergy, scale, oversight and effective coordination in implementation of various skill development schemes across the department. Mission will provide a single point within the Government to formulate and steer various skill development schemes in the state of Punjab.  The Mission has been set up as a Society and registered under the Societies Registration Act, 1860.
The Governing Council is headed by Chief Minister and comprises of other Minister and Administrative Secretaries of different departments. The State Steering Committee is headed by the Chief Secretary and comprises of Administrative Secretaries from various Departments. The State Executive Committee is headed by the Member Secretary, PSDM, and comprises of representatives from different Departments. The District Executive Committee is headed by the Deputy Commissioner of the District.

                          </p>
                              <hr />
                              | <a href="/docs/contact_list.pdf" target="_blank">Contact- District Offices</a> | <a href="/docs/list_of_batches.pdf" target="_blank">List of Ongoing Batches under Punjab Skill Development Mission</a> |
                              <hr/>
                          </div>
                          <div className={"clearfix"}></div>

                          <div className={"row m-0"}>
                              <div className={"col-md-12 p-4 about-jobseeker"}>
                              <h5>Introduction</h5>

                                <p style={{textAlign:'justify'}}>
                                Punjab Skill Development Mission provides free employment linked skill development trainings to the Youth in the age group of 18-35 years across the state under the various state and centrally sponsored schemes. These nationally certified trainings are provided through empanelled training partners at centres in urban and rural areas across the state in over 40+ different sectors like Healthcare, Media & Entertainment, Green Jobs, Domestic Workers, Retail, Beauty, Construction, Electronics and Hardware, Food Processing, Health Care, IT ITES, Leather, Logistics etc. 
PSDM closely networks with a host of other key institutions and stakeholders in the skill development space, thus creating a knowledge network for skill development. PSDM leverages this network to provide candidates of Punjab with placement support or career related information.

                                </p>  
                                <p>
                                <h5 style={{textAlign:'center'}}>Organisation Structure:</h5>
                                <img src="/images/org_structure.jpg" style={{width:"70%", marginLeft:'16%'}}></img>
                                </p>
                                <p style={{textAlign:'center'}}>
                               <b> PSDM will have three tiers at State, District and Block / City Level.</b>
                                </p>    

                               <h5> Objectives: </h5>
                            <p style={{textAlign:'justify'}}>
                            The primary objective of Punjab Skill Development Mission is to enable a large number of youth to take up industry-relevant skill training that will improve their theoretical as well as practical knowledge and help them in securing a better livelihood by bringing necessary synergy, oversight and effective coordination. Punjab Skill Development Mission is bridging the gap between the skilled people required in Industry and the unemployed youth by ensuring that the right candidate with passion for a particular job chooses the right course according to his/her academic background, aptitude and skill-set.
                            </p>
                            <hr/>
                            {/* <p>For More details <a href="/docs/contact_list.pdf" style={{color:'blue'}}><b>Click Here</b></a> for contact details</p> */}
                                                       
                                                 

                                                 


                                                  

                                                   
                                                   
                                                      

                              </div>
                          </div>
                          </div>
                      

                      </div>
                    </div>
                </section>
            </div>
        )
    }
}


export default translate(skilldevelopmentEnplanement);
