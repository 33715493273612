import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router'
import store from '../../../store';
import {
    getProfile
} from '../../../actions/counsellingproviderAction';
import ServerSetting from '../../../config.json';
import { getChatInvites } from '../../../actions/accountAction';

class Sidebar extends Component {

    constructor(props) {
        super(props);
        if (this.props.counsellingproviderReducer.Profile && !Object.keys(this.props.counsellingproviderReducer.Profile).length) {
            store.dispatch(getProfile());
        }
        store.dispatch(getChatInvites());
    }

    componentDidMount(){
        window.openCloseSidebar()
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => {
            this.validateField(name, value)
        });
    }

    capitalizeFirstLetter = (string) => {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }


    render() {
        let Mainprops = this.props;
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;
        
        return (
            <aside className="col-md-2 px-0 widget-sidebar " id="left">
                <div className="list-group w-100">
                    <ul className="nav flex-column flex-nowrap">
                        {/* <li 
                        data-placement="bottom"
                        title={this.props.t('counsellingProvider.counsellingAccount', {param: 'react'})}
                        className={"nav-item list-group-item " + (Mainprops.route.path === '/myaccount' ? 'active' : '')}>
                            <Link className="nav-link" to="/myaccount">
                                {this.props.t('dashboard.welcome', {param: 'react'})} { 
                                <b>, { this.props.counsellingproviderReducer.Profile && this.props.counsellingproviderReducer.Profile.firstname}</b> }
                            </Link>
                        </li> */}
                        {this.props.counsellingproviderReducer.Profile && this.props.counsellingproviderReducer.Profile.profileCompleted === '1' ?
                            <Link to="/myaccount" className="date-clr">
                                <div className="d-flex align-items-center justify-content-center w-100 pull-left pt-2 outr-profile text-center">
                                    <div className="outr-avtar">
                                        <img alt="avatar"
                                            src={this.props.counsellingproviderReducer.Profile.profile_image ?
                                                `${SERVER_PATH}${this.props.counsellingproviderReducer.Profile.profile_image}`
                                                : '/images/img-1.png'}
                                            className="avatar mr-2 text-center" />
                                    </div>
                                    <div className="mb-2 font-18">
                                        <span className="outr-profile-text">
                                            {this.props.t('login.hi', { param: 'react' }).trimEnd()}, {this.capitalizeFirstLetter(this.props.counsellingproviderReducer.Profile.firstname)}</span>
                                        <br></br>{Mainprops.route.path === "/myaccount" ? this.props.t('dashboard.WelcomeDashboard', { param: 'react' }) : this.props.t('dashboard.clickForDashboard', { param: 'react' })}
                                    </div>
                                </div>
                            </Link> : null}
                        <li
                            data-placement="bottom"
                            title={this.props.t('counsellingProvider.updateProfile', { param: 'react' })}
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/myprofile' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/myprofile"><b>{this.props.t('sidebar.update_profile', { param: 'react' })}</b></Link>
                        </li>

                        {this.props.counsellingproviderReducer.Profile && this.props.counsellingproviderReducer.Profile.profileCompleted === '1' ?
                            <>
                                <li
                                    data-placement="bottom"
                                    title={this.props.t('counsellingProvider.createCounselling', { param: 'react' })}
                                    className={"nav-item list-group-item " + (Mainprops.route.path === '/post-counselling' ? 'active' : '')}>
                                    <Link className="nav-link"
                                        to="/post-counselling"><b>{this.props.t('counsellingProvider.postCounselling', { param: 'react' })}</b></Link>
                                </li>
                                <li
                                    data-placement="bottom"
                                    title={this.props.t('counsellingProvider.calenderDesc', { param: 'react' })}
                                    className={"nav-item list-group-item " + (Mainprops.route.path === '/session-calendar' ? 'active' : '')} ><Link className="nav-link"
                                        to="/session-calendar"><b>{this.props.t('counsellingProvider.calender', { param: 'react' })}</b></Link>
                                </li>
                                <li
                                    data-placement="bottom"
                                    title={this.props.t('counsellingProvider.sessionFeedBackDesc', { param: 'react' })}
                                    className={"nav-item list-group-item " + (Mainprops.route.path === '/session-feedback' ? 'active' : '')} ><Link className="nav-link"
                                        to="/session-feedback"><b>{this.props.t('counsellingProvider.sessionFeedBack', { param: 'react' })}</b></Link>
                                </li>
                                <li data-placement="bottom"
                                    title={this.props.t('forum.titleDesc', {param: 'react'})}
                                    className={"nav-item list-group-item " + (Mainprops.route.path === '/forum' ? 'active' : '')}>
                                    <Link className="nav-link"
                                        to="/forum"><b>{this.props.t('forum.title', {param: 'react'})}</b> </Link>
                                </li>
                                {/* <li  data-placement="bottom"
                                title={this.props.t('sidebar.search_lspDesc', {param: 'react'})}
                                className={"nav-item list-group-item " + (Mainprops.route.path === '/servicesearch' ? 'active' : '')}>
                                <Link className="nav-link"
                                    to="/servicesearch"><b>{this.props.t('sidebar.search_service', {param: 'react'})}</b> </Link>
                            </li> */}
                            </> : null}
                        {/* <li 
                        data-placement="bottom"
                        title={this.props.t('sidebar.change_passwordDesc', {param: 'react'})}
                        className={"nav-item list-group-item " + (Mainprops.route.path === '/change-password' ? 'active' : '')} ><Link className="nav-link"
                                    to="/change-password"><b>{this.props.t('sidebar.change_password', {param: 'react'})}</b></Link>
                                    </li> */}

                    </ul>
                </div>

            </aside>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Sidebar));
