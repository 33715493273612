import React, { Component } from 'react';
import SidebarProvider from './jobprovider/Sidebar';
import SidebarSeeker from './jobseeker/Sidebar';
import store from "../../store";
import { connect } from 'react-redux';
import { changePassword, setMessage } from "../../actions/jobseekerAction";
import { translate } from 'react-multi-lang'
import Validation from "../hoc/Validation";
import { Link } from 'react-router'

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: [],
            error: ''
        }
    }

    handleInputs = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        formData[name] = value;
        await this.setState({ formData: formData });
    }

    componentDidMount() {
        document.title = this.props.t('changePassword.title', {param: 'react'})
    }


    submit = async (e) => {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            if (this.state.formData.password === this.state.formData.confirm_password) {
                if (this.state.formData.password !== this.state.formData.current_password) {
                    await store.dispatch(changePassword(localStorage.getItem('token'),
                        this.state.formData.password, this.state.formData.current_password))
                    await this.props.validator.hideMessages()
                    this.setState({ formData: [] })
                } else {
                    this.setState({ error: 'New password is same as previous. Please enter a new password' });
                    setTimeout(() => this.setState({ error: '' }), 2000)
                }

            } else {
                this.setState({ error: 'Password does not match' });
                setTimeout(() => this.setState({ error: '' }), 2000)
            }
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }

    }

    change = (id, e) => {
        let inputType = document.getElementById(id).type
        if (inputType === 'password') {
            document.getElementById(id).type = 'text'
            e.target.className = e.target.className.substring(0, e.target.className.lastIndexOf("-"));
        } else {
            document.getElementById(id).type = 'password'
            e.target.className += '-slash'
        }

    }


    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.JobseekerReducer.msg) {
            setTimeout(() => {
                store.dispatch(setMessage(''))
            }, 3000)
        }
    }

    render() {
        let Mainprops = this.props;
        const { validator } = this.props
        const { current_password, password, confirm_password } = this.state.formData
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        let Sidebar = ''
        if (localStorage.getItem('login_type') === 'Jobseeker') {
            Sidebar = SidebarSeeker
        } else if (localStorage.getItem('login_type') === 'Employer') {
            Sidebar = SidebarProvider
        }

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        {Sidebar ? <Sidebar route={Mainprops.route}/> : ''}
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12 bg-education mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('changePassword.title', {param: 'react'})}
                                                            ({this.props.t('changePassword.titleDesc', {param: 'react'})})</h6>
                                                    </div>
                                                    <div className="panel-body">

                                                        <form onSubmit={this.submit}>
                                                            {this.state.error &&
                                                            <div className="form-group" style={{'textAlign': 'center'}}>
                                                                <span style={{'color': 'red'}}>{this.state.error}</span>
                                                            </div>}
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-sm-5 col-5 align-self-center text-right">
                                                                        <label>{this.props.t('changePassword.current', {param: 'react'})}<span
                                                                            className="ml-2"
                                                                            style={{"color": "red"}}>*</span> </label>
                                                                    </div>

                                                                    <div className="col-sm-5 ">
                                                                        <input type="password"
                                                                               onChange={this.handleInputs}
                                                                               value={current_password ? current_password : ''}
                                                                               className="form-control form-eye"
                                                                               id="current_password"
                                                                               name="current_password"
                                                                               placeholder={this.props.t('placeholder.enter', {param: 'react'}) +' '+this.props.t('changePassword.current', {param: 'react'})}/>
                                                                        <span
                                                                            onClick={(e) => this.change('current_password', e)}
                                                                            className={`home-input fa fa-fw field-icon toggle-password fa-eye-slash`}
                                                                            ></span>
                                                                        {validator.message('current password', current_password, 'required')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-sm-5 col-5 align-self-center text-right">
                                                                        <label>{this.props.t('changePassword.new', {param: 'react'})}<span
                                                                            className="ml-2"
                                                                            style={{"color": "red"}}>*</span></label>
                                                                    </div>

                                                                    <div className="col-sm-5">
                                                                        <input type="password" className="form-control form-eye"
                                                                               value={password ? password : ''}
                                                                               onChange={this.handleInputs}
                                                                               id="password_new" name="password"
                                                                               placeholder={this.props.t('placeholder.enter', {param: 'react'}) +' '+this.props.t('changePassword.new', {param: 'react'})} />
                                                                        <span
                                                                            onClick={(e) => this.change('password_new', e)}
                                                                            className="home-input fa fa-fw field-icon toggle-password fa-eye-slash"
                                                                            ></span>
                                                                        {validator.message('password', password, 'required')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-sm-5 col-5 align-self-center text-right">
                                                                        <label>{this.props.t('changePassword.confirm', {param: 'react'})}<span
                                                                            className="ml-2"
                                                                            style={{"color": "red"}}>*</span></label>
                                                                    </div>

                                                                    <div className="col-sm-5">
                                                                        <input type="password"
                                                                               onChange={this.handleInputs}
                                                                               value={confirm_password ? confirm_password : ''}
                                                                               className="form-control form-eye"
                                                                               id="confirm_password"
                                                                               name="confirm_password"
                                                                               placeholder={this.props.t('changePassword.confirm', {param: 'react'})}/>
                                                                        <span
                                                                            onClick={(e) =>
                                                                                this.change('confirm_password', e)}
                                                                            toggle="#confirm_password"
                                                                            className="home-input fa fa-fw field-icon toggle-password fa-eye-slash"
                                                                            ></span>
                                                                        {validator.message('confirm password', confirm_password, 'required')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-5 col-5 align-self-center text-right"></div>
                                                                <div className="col-sm-5">
                                                                    <button type="submit"
                                                                            className="btn btn-primary btn-primary2 mb-2"
                                                                            style={{"marginRight": "10px"}}>{this.props.t('changePassword.submit', {param: 'react'})}</button>
                                                                    {this.props.JobseekerReducer.msg &&
                                                                    <span style={{"color":"green"}}>{this.props.JobseekerReducer.msg}</span>}
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>)
        }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(ChangePassword)));
