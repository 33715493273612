import React from 'react';
import { connect } from 'react-redux';
import { getActiveJobMela } from '../../actions/accountAction'
import store from '../../store'


export default function (ComposedComponent) {
    class ProtectSeekerTypeRoutes extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                basePath: window.location.protocol + '//' + window.location.host + '/',
            }
            if (this.props.actionReducer.activeJobMela && !Object.keys(this.props.actionReducer.activeJobMela).length) {
                store.dispatch(getActiveJobMela());
            }
        }

        getComponent = () => {
            const { basePath } = this.state
            if (this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.hasOwnProperty('expire')) {
                const isAuthenticated = this.props.actionReducer.activeJobMela.expire
                if (!isAuthenticated) {
                    return <ComposedComponent {...this.props} />
                } else {
                    window.location.href = basePath + "404"
                }
            }
            return null
        }

        render() {
            return (this.getComponent());
        }
    }

    function mapStateToProps(store) {
        return store
    }

    return connect(mapStateToProps)(ProtectSeekerTypeRoutes);
}



