import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Sidebar from './Sidebar';
import store from "../../../store";
import {
    getAllJobByEducation,
    applyJob,
    get_jobseeker_profile,
    jobApplied
} from "../../../actions/jobseekerAction";
import Loader from 'react-loader-spinner';

import Pagination from "react-js-pagination";

import { Link } from 'react-router'
import { chunk } from '../../../helpers'

const RESULT_PER_PAGE = 25

class AllJobsForUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePage: 1
        }
        store.dispatch(get_jobseeker_profile());

    }

    componentDidMount() {
        document.title = this.props.t('jobProviderProfile.jobsAccordingTooProfile', { param: 'react' })
        store.dispatch(getAllJobByEducation());
    }

    getLocationFromIds = (location) => {
        const names = []
        if (this.props.actionReducer.District && this.props.actionReducer.District.length > 0 && location && location.length) {
            this.props.actionReducer.District.forEach((data) => {
                if (data.state_id === "3") {
                    location.forEach(element => {
                        let filterVal = data.dist.filter((district) => district.id === element)
                        if (filterVal && filterVal[0]) {
                            names.push(filterVal[0].name)
                        }
                    });
                }
            })
        }
        return names.length ? names.toString() : ''
    }

    capitalizeFirstLetter = (string) => {
        if (string) {
            const newString = string.toLowerCase()
            return newString.charAt(0).toUpperCase() + newString.slice(1);
        }
    }


    getExperienceToShow = (jobData) => {
        if ((jobData.min_exp && jobData.max_exp && parseInt(jobData.min_exp) > 0 && parseInt(jobData.max_exp) > 0)) {
            return `${jobData.min_exp} - ${jobData.max_exp} ${this.props.t('experience.years', { param: 'react' })}`
        } else {
            return this.props.t('JobPost.fresher', { param: 'react' })
        }
    }


    clicked = (job) => {
        if (job && job.id) {
            this.props.router.push(`job-details/${job.id}`)
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }

    applyForJob = async (job) => {
        this.setState({ job })
        await store.dispatch(applyJob({ job_id: job.id }));
    }



    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.JobseekerReducer.jobApplied) {
            store.dispatch(jobApplied(false))
            store.dispatch(getAllJobByEducation())
        }
    }



    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }


    render() {
        const { activePage, showDetails, job } = this.state

        let Mainprops = this.props;
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const searchResults = chunk(Mainprops.JobseekerReducer.jobsByEdu, RESULT_PER_PAGE)
        // const Profile = this.props.JobseekerReducer.Profile
        // console.log('searchResults', searchResults)

        return (

            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="modal fade bd-example-modal-sm" tabIndex="-1" id="locationModal"
                    role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="mySmallModalLabel">{this.props.t('homeContent.locations', { param: 'react' })}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ul className="loc-lisiting">
                                    {this.state.locations && this.state.locations.length ? this.state.locations.map((loc, key) => {
                                        return (
                                            <li key={key}>
                                                <span >{loc}</span>
                                            </li>)
                                    })
                                        : ''}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div>
                                {!Object.keys(Mainprops.JobseekerReducer.jobsByEdu).length &&

                                    <Loader style={{ position: 'fixed', top: '49%', left: '48%' }}
                                        type="Rings"
                                        color="#F1911C"
                                        height={100}
                                        width={100}
                                    />}
                                {Mainprops.JobseekerReducer.jobsByEdu && Mainprops.JobseekerReducer.jobsByEdu.length > 0
                                    && !showDetails &&
                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane  active">
                                            <section className="job-details nogap mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{
                                                                    this.props.t('jobProviderProfile.jobsAccordingTooProfile', { param: 'react' })}
                                                                    <span className="pull-right" style={{ "color": "#fff" }}>
                                                                        {Mainprops.JobseekerReducer.jobsByEdu.length ?
                                                                            Mainprops.JobseekerReducer.jobsByEdu.length : 0}{this.props.t('JobPost.found', { param: 'react' })}</span></h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="">
                                                                    {searchResults && searchResults.length && searchResults[activePage - 1].map((job, key) => {
                                                                        let locationList = ''
                                                                        let locationsArray = ''
                                                                        let locations = ''
                                                                        if (!job.hasOwnProperty('apply_link')) {
                                                                            locationList = this.getLocationFromIds(job.location)
                                                                            locationsArray = locationList.split(",")
                                                                            locations = locationsArray.slice(0, 3)
                                                                            locations = locations.toString()
                                                                        }
                                                                        return (

                                                                            <div className="right-sidebar" key={key}>
                                                                                <div className="first-job mb-3 p-3">
                                                                                    <div className="row">
                                                                                        <div className="col-md-8">
                                                                                            <h4 className="company-name">
                                                                                                <Link>
                                                                                                    <i className="fa fa-globe pr-2"></i>{this.props.t('govtJobs.nameOfPost', { param: 'react' })}:
                                             {job.job_title}
                                                                                                </Link>
                                                                                            </h4>
                                                                                            <h6 className="pt-1 company-name2 pb-1 mb-0 ">
                                                                                                <Link>
                                                                                                    {this.props.t('govtJobs.nameOfEmployer', { param: 'react' })}: {job.org_name}</Link>
                                                                                            </h6>
                                                                                            <ul className="nav">

                                                                                                {job.location && !job.hasOwnProperty('apply_link') &&

                                                                                                    <li className="">
                                                                                                        <Link >
                                                                                                            <i className="fa fa-map-marker pr-2"
                                                                                                                aria-hidden="true"></i>
                                                                                                            <span>{this.props.t('govtJobs.placeOfPosting', { param: 'react' })}: {locations} {job.location.length > 3 &&
                                                                                                                <span style={{ "cursor": "pointer" }} className="date-clr" data-toggle="modal"
                                                                                                                    data-target="#locationModal" onClick={() => this.setState({ locations: locationsArray })}>{this.props.t('homeContent.viewLocations', { param: 'react' })}</span>}

                                                                                                            </span>
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                }

                                                                                                {job.location && job.hasOwnProperty('apply_link') &&
                                                                                                    <li className=""><Link ><i className="fa fa-map-marker pr-2"
                                                                                                        aria-hidden="true"></i>
                                                                                                        <span>{this.props.t('govtJobs.placeOfPosting', { param: 'react' })}: {job.location}
                                                                                                        </span></Link></li>
                                                                                                }


                                                                                            </ul>

                                                                                            <p>
                                                                                                <i className="fa fa-pencil pr-2" aria-hidden="true" ></i>{this.props.t('govtJobs.reqQual',
                                                                                                    { param: 'react' })}: {job.qualification ? job.qualification : ''}
                                                                                            </p>
                                                                                            {job.salary && job.hasOwnProperty('apply_link') && <p>
                                                                                                <i className="fa fa-inr pr-2" aria-hidden="true" ></i>
                                                                                                {this.props.t('dashboard.table.salary', { param: 'react' })}: {job.salary}

                                                                                            </p>}
                                                                                            {!job.hasOwnProperty('apply_link') &&
                                                                                                <p>
                                                                                                    <i className="fa fa-inr pr-2" aria-hidden="true" ></i>{this.props.t('dashboard.table.salary', { param: 'react' })}: {job.salary_min && job.salary_max ? job.salary_min + '-' + job.salary_max : job.salary_min}

                                                                                                </p>}



                                                                                            <span className="pr-3 date-clr">{this.props.t('govtJobs.vacancy', { param: 'react' })}: <span>{job.vacancies} </span></span>
                                                                                            <span className="pr-3 date-clr">{this.props.t('counsellingProvider.expire_on', { param: 'react' })}: <span>{job.expiring_on} </span></span>
                                                                                            <span className="pr-3 date-clr">{this.props.t('homeContent.minAge', { param: 'react' })}: <span>
                                                                                                {job.age_preference ? job.age_preference : ''}


                                                                                            </span></span><br />
                                                                                            <span className="pr-3 date-clr">{this.props.t('govtJobs.age', { param: 'react' })}: <span>
                                                                                                {job.max_age ? job.max_age : ''}


                                                                                            </span></span>

                                                                                            {!job.hasOwnProperty('apply_link') && <span className="pr-3 date-clr">{this.props.t('govtJobs.exp', { param: 'react' })}: <span>
                                                                                                {this.getExperienceToShow(job)} </span></span>}
                                                                                            {job.hasOwnProperty('apply_link') &&
                                                                                                <span className="pr-3 date-clr">{this.props.t('govtJobs.exp', { param: 'react' })}: <span>
                                                                                                    {job.experience}</span></span>
                                                                                            }
                                                                                            {job.gender_preference && <span className="pr-3 date-clr">
                                                                                                {this.props.t('govtJobs.gender', { param: 'react' })}:

                                                                <span>{" " + job.gender_preference} </span></span>}

                                                                                            {job.hasOwnProperty('apply_link') &&
                                                                                                <div>

                                                                                                    <span className="pr-3 date-clr">{this.props.t('govtJobs.applyHere', { param: 'react' })}: <span>
                                                                                                        <a rel="noopener noreferrer" className="mb-2" target="_blank" href={job.apply_link}>{this.props.t('govtJobs.clickHere', { param: 'react' })}</a>


                                                                                                    </span></span>

                                                                                                    <span className="pr-3 date-clr">{this.props.t('govtJobs.readNotification', { param: 'react' })}: <span>
                                                                                                        <a rel="noopener noreferrer" className="mb-2" target="_blank" href={job.pdf_link}>{this.props.t('govtJobs.clickHere', { param: 'react' })}</a>
                                                                                                    </span></span>

                                                                                                    <span className="pr-3 date-clr d-block">
                                                                                                        {this.props.t('homeContent.whereToapply', { param: 'react' })}: <span>{this.capitalizeFirstLetter(job.apply_mode)} </span></span>




                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            {!job.hasOwnProperty('apply_link') &&
                                                                                                <div className="text-right">

                                                                                                    {!job.status ?

                                                                                                        <button
                                                                                                            onClick={() => this.applyForJob(job)}
                                                                                                            className="btn btn-primary btn-primary2">
                                                                                                            {this.props.t('jobSearch.apply', { param: 'react' })}
                                                                                                        </button> :
                                                                                                        <h6 className="date-clr pull-right">{this.props.t('jobSearch.alreadyApplied', { param: 'react' })}
                                                                                                            <br></br>
                                                                                                            {this.props.t('jobSearch.appliedOn', { param: 'react' })} {job.applied_on}
                                                                                                        </h6>}

                                                                                                </div>}
                                                                                            {!job.hasOwnProperty('apply_link') &&
                                                                                                <div className="text-right">
                                                                                                    <Link className="date-clr pull-right" to={`/job-details/${job.id}`}>
                                                                                                        {this.props.t('jobSearch.viewDetails', { param: 'react' })}
                                                                                                    </Link>
                                                                                                </div>}
                                                                                            <div className="pull-right" style={{ "marginTop": !job.hasOwnProperty('apply_link') ? "125px" : "220px" }}>
                                                                                                {!job.hasOwnProperty('apply_link') && <span style={{ "display": "block" }}>{this.props.t('govtJobs.postedBy', { param: 'react' })}: {job.postedBy ? job.postedBy : "Employer"}</span>}
                                                                                                {job.hasOwnProperty('apply_link') && <span style={{ "display": "block" }}>{this.props.t('govtJobs.postedBy', { param: 'react' })}: {job.postedBy ? job.postedBy : "Admin"}</span>}
                                                                                                <span>{this.props.t('homeContent.postedOn', { param: 'react' })} {job.created_at}
                                                                                                </span>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {searchResults && searchResults.length > 1 ?

                                                                        <div className='react-pagination'>
                                                                            <Pagination
                                                                                pageRangeDisplayed={10}
                                                                                activePage={activePage}
                                                                                itemsCountPerPage={RESULT_PER_PAGE}
                                                                                totalItemsCount={Mainprops.JobseekerReducer.jobsByEdu.length}
                                                                                onChange={this.handlePageChange}
                                                                            />
                                                                        </div> : ''}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}
                                {showDetails &&

                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane  active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('jobSearch.appliedJob', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <p className="text-success">{this.props.t('jobSearch.successInst', { param: 'react' })}</p>
                                                                <Link>{job.job_title ? job.job_title : ''}</Link>
                                                                <ul className="nav">
                                                                    <li className="list-items-inline pt-2">
                                                                        <i
                                                                            className="fa fa-briefcase pt-2  pr-2"></i>{job.org_name ? job.org_name : ''}

                                                                    </li>
                                                                </ul>
                                                                <p className="pt-2 mb-0">
                                                                    <i
                                                                        className=" fa fa-rupee pr-2"></i>{job.salary_min && job.salary_max ? job.salary_min + '-' + job.salary_max : job.salary_min}

                                                                </p>
                                                                <button onClick={() => this.close()}
                                                                    className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                    {this.props.t('jobSearch.goBack', { param: 'react' })}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                }
                                {Mainprops.JobseekerReducer.jobsByEdu && Mainprops.JobseekerReducer.jobsByEdu.status === '401' &&

                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane  active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{
                                                                    this.props.t('jobProviderProfile.jobsAccordingTooProfile', { param: 'react' })}
                                                                    <span className="pull-right" style={{ "color": "#fff" }}>
                                                                        {Mainprops.JobseekerReducer.jobsByEdu && Mainprops.JobseekerReducer.jobsByEdu.length ?
                                                                            Mainprops.JobseekerReducer.jobsByEdu.length : 0}{this.props.t('JobPost.found', { param: 'react' })}</span></h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <h5>{Mainprops.JobseekerReducer.jobsByEdu.message}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}

                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(AllJobsForUser));
