import React from 'react';
import {connect} from 'react-redux';

export default function(ComposedComponent) {
  class AuthRoutes extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          basePath : window.location.protocol + '//' + window.location.host + '/'
        }
      }
    
    getHtml = () => {
      if(localStorage.getItem('Islogin') === 'true'){
        window.location.href = this.state.basePath + 'myaccount'
      } else {
        return <ComposedComponent {...this.props} />
      }
    }

    render() {
      return(this.getHtml())
    }
  }

  function mapStateToProps(store) {
    return store
  }

  return connect(mapStateToProps)(AuthRoutes);
}
  
  

