import React, {Component} from 'react'
import {connect} from 'react-redux';
import {translate} from 'react-multi-lang'
import { subscribeForWebinar } from '../../../actions/jobseekerAction'
import { sendFeedback } from '../../../actions/accountAction'
import store from '../../../store';
import Sidebar from './Sidebar'
import StarRatings from 'react-star-ratings'
import { Link } from 'react-router'

class Webinar extends Component {

    constructor(props){
        super(props)
        this.state = {
            feedback:'',
            rating:''
        }
    }

    componentDidMount() {
        document.title = this.props.t('homeContent.webinar', { param: 'react' })
        
    }

    applyWebinar = () => {
        store.dispatch(subscribeForWebinar({webinar_subscription: '1'}))
    }

    ratingChanged = (val) => {
        this.setState({'rating':val})
    }

    handleFeedback = (e) => {
        const value = e.target.value
        const name = e.target.name
        this.setState({[name]:value})
    }

    submitFeedback = () => {
        const { feedback, rating } = this.state
        if(feedback || rating){
            store.dispatch(sendFeedback({feedback, rating, webinar:"1"}))
            this.setState({feedback:''})
        }
    }

    render() {
        
        //   stars['value'] = this.state.defaultRating
        const Mainprops = this.props
        const { feedback, rating } = this.state
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>

                            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">Webinar rating</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body mx-auto">
                                    <StarRatings
                                    rating={rating}
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="80px"
                                    starRatedColor="rgb(255, 215, 0)"
                                    starHoverColor="rgb(255, 215, 0)"
                                    changeRating={this.ratingChanged}

                                />

                                    <textarea cols="5" className="w-100" name="feedback" onChange={this.handleFeedback}/>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" disabled={!feedback && !rating}  data-dismiss="modal" onClick={() => this.submitFeedback()} className="btn btn-primary btn-primary2 mx-auto">{this.props.t('jobProviderProfile.submit', { param: 'react' })}</button>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            {/* <!-- Tab panes --> */}
                            
                            <div>
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-3">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                        <div className="panel-heading">
        <h6 className="mb-0">{this.props.t('homeContent.webinar', { param: 'react' })} </h6>
                                                        </div>
                                        <div className="panel-body p-policy pt-0  p-3 ">
                                        The Govt. of Punjab has decided to organize a State Level Webinar on “Emerging Dynamics in Employment of Youth post Covid-19- Challenges & Opportunities”. The Webinar will be held on 24th July 2020, at 3:00 PM. The Session would be inaugurated by honourable Mr. Charanjit Singh Channi, Minister of Technical Education & Industrial Training, Govt. of Punjab and 6 eminent speakers from renowned companies like Microsoft, Ansys, Pepsico, Wallmart, Siemens etc would be addressing on new opportunities of employment and requirements of the industry in the changed scenario.
                                        
                                    {
                                     this.props.JobseekerReducer.Profile && !this.props.JobseekerReducer.Profile.webinar_subscription && 
                                     <>
                                     <h5 style= {{'fontSize':'14px', 'marginTop':'1%'}}>Note: Only registered seekers can participate in the webinar after login. If you are not registered on the portal <a href="/signup?type=1">click here</a> to register and then click on the following button.</h5>
                                    <button type="submit"
                                        onClick={() => this.applyWebinar()}
                                        className="mt-4 btn btn-primary mx-auto d-block btn-primary2 mt-1">Click here to apply for the webinar</button>
                                        </>}
                                    {this.props.JobseekerReducer.Profile && this.props.JobseekerReducer.Profile.webinar_subscription ==='1' &&
                                    <div>
                                        <p className="mt-4 font-weight-bold mb-0">You have successfully applied for webinar expected to be held on 24.7.20 at 3 pm. To know the link, exact date and time to participate, please log into your account on or after 18.7.20 (before 24.7.20).<br></br> <a rel="noopener noreferrer" href="https://www.youtube.com/watch?v=nqGefNp2Uog&feature=youtu.be" target="_blank"> https://www.youtube.com/watch?v=nqGefNp2Uog&feature=youtu.be</a></p><br></br>
                                        <Link data-toggle="modal" style={{"cursor":"pointer"}}  className="date-clr" data-target="#exampleModalCenter">Give your feedback regarding the webinar</Link>
                                    </div>}
                                </div>
                                </div> 
                                </div> 
                                </div> 
                                </section>
                                </div>
                                </div>
                                </div>
                                </main> 
                           </div>
                           </div>
                           </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Webinar));
