import React, { Component } from 'react'
import { connect } from 'react-redux';
import store from '../../../store';
import Sidebar from './Sidebar';
import { get_education } from '../../../actions/accountAction'
import { applyForCounselling, getCounsellingById, setApplyForCounselling } from '../../../actions/jobseekerAction';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router'
import {  getTimeWithAmPm } from '../../../helpers'


class CounsellingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentJob: []
        }
        store.dispatch(get_education())
    }

    componentDidMount() {
        if (this.props.routeParams.id) {
            store.dispatch(getCounsellingById(this.props.routeParams.id))
        }
        document.title = this.props.t('header.coun_details', { param: 'react' })
    }

    getDate = (created_at) => {
        if (created_at) {
            let date = created_at.split(" ")[0]
            date = date.split("-")
            return `${date[2]}-${date[1]}-${date[0]}`
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.JobseekerReducer.appliedForCounselling) {
            this.props.router.push('/myaccount')
        }
    }

    componentWillUnmount() {
        store.dispatch(setApplyForCounselling(false))
    }

    applyForJob = async () => {
        await store.dispatch(applyForCounselling(this.props.routeParams.id))
    }


    render() {
        const jobData = this.props.JobseekerReducer.counsellingById
        let education = []
        if (this.props.actionReducer.Educations && this.props.actionReducer.Educations.length && jobData.education_level) {
            jobData.education_level.forEach((ed) => {
                const filterData = this.props.actionReducer.Educations.filter((e) => e.id === ed)
                if (filterData && filterData.length) {
                    education.push(filterData[0].name)
                }
            })
        }
        education = education.length ? education.toString() : ''


        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={this.props.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0"> {this.props.t('counsellingProvider.applySession', { param: 'react' })}</h6>
                                                    </div>
                                                    {jobData.notFound &&
                                                        <div className="panel-body">
                                                            <h4>{this.props.t('JobPost.notFound', { param: 'react' })}</h4>
                                                        </div>}
                                                    <div className="panel-body">
                                                        {jobData && !jobData.notFound && Object.keys(jobData).length > 0 ?
                                                            
                                                            <div className="job-sec bg-white cover-shadow mb-3 p-3">
                                                                <h5>{jobData.title ? jobData.title : ''} {jobData.applied ? <span className="date-clr pull-right">{this.props.t('jobSearch.alreadyApplied', { param: 'react' })}</span>: null}</h5>

                                                                {jobData.name && <p className="pt-none"><i
                                                                    className="fa fa-map-marker  pr-2"></i>{jobData.name ? jobData.name : ''}
                                                                </p>}
                                                                <div className="row">
                                                                    <div className="col md-12 col-sm-12 ">
                                                                        <h6>{this.props.t('counsellingProvider.details', { param: 'react' })}</h6>
                                                                        <table className="w-100">
                                                                            <tbody>
                                                                                {jobData.valid_from && jobData.expire_on && <tr>
                                                                                    <td>{this.props.t('dashboard.table.date', { param: 'react' })}</td>
                                                                                    <td><span
                                                                                        className="text-span"> {": " + this.getDate(jobData.valid_from) + " - " + this.getDate(jobData.expire_on)}</span>
                                                                                    </td>
                                                                                </tr>}
                                                                                {jobData.fees && <tr>
                                                                                    <td>{this.props.t('dashboard.table.fees', { param: 'react' })}</td>
                                                                                    <td><span
                                                                                        className="text-span"> {" : " + jobData.fees}</span>
                                                                                    </td>
                                                                                </tr>}
                                                                                {jobData.timing_from && jobData.timing_to && <tr>
                                                                                    <td>{this.props.t('counsellingProvider.timings', { param: 'react' })}</td>
                                                                                    <td><span
                                                                                        className="text-span">: {getTimeWithAmPm(jobData.timing_from) + " - " +
                                                                                            getTimeWithAmPm(jobData.timing_to)}</span>
                                                                                    </td>
                                                                                </tr>}
                                                                                <tr>
                                                                                    <td>{this.props.t('counsellingProvider.type', { param: 'react' })}</td>
                                                                                    <td><span
                                                                                        className="text-span"> {": " + jobData.type}</span>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{this.props.t('counsellingProvider.mode', { param: 'react' })}</td>
                                                                                    <td><span
                                                                                        className="text-span"> {": " + jobData.mode}</span>
                                                                                    </td>
                                                                                </tr>
                                                                                {education ?
                                                                                    <tr>
                                                                                        <td>{this.props.t('counsellingProvider.eduLevel', { param: 'react' })}</td>
                                                                                        <td><span
                                                                                            className="text-span"> {": " + education}</span>
                                                                                        </td>
                                                                                    </tr> : null}

                                                                            </tbody>

                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div> : ''}
                                                        {jobData && !jobData.notFound && Object.keys(jobData).length > 0 ?
                                                            <div className="job-sec bg-white cover-shadow mb-3 p-3 mt-4">
                                                                {jobData.description ? <>
                                                                    <h6>{this.props.t('dashboard.table.description', { param: 'react' })}</h6>
                                                                    <hr />
                                                                    <span
                                                                        className="text-span d-block">{jobData.description}</span>
                                                                </> : null}
                                                                {!jobData.applied && <button onClick={() => this.applyForJob()}
                                                                    className="btn btn-primary btn-primary2 mt-2">{this.props.t('homeContent.apply', { param: 'react' })}
                                                                </button>}
                                                            </div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(CounsellingDetails));
