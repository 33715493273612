import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Sidebar from './Sidebar';
import ReactToPrint from "react-to-print";
import { Link } from 'react-router'
import Validation from "../../hoc/Validation";
import ReactHtmlParser from 'react-html-parser';
import {
    getScheduledJobs, getAppliedJobs, getUpcomingJobs, postForeignJobInterviewParticipation,
    postJobInterviewParticipation, sendFeedback, applyEvent,
    willGoForInterview, subscribeForWebinar,
    getScheduledCareerCounselling, getAppliedCounsellings,
    // postTrainingInterest,
    // getTrainingInterest,
    // getUserSelfEmpInterest,
    // addInterestInSelfEmp,
    // getScheduledSelfEmployment,
    // getMatchingCareers,
    // getRelevantSelfEmployment,
    getAppliedServices,
    getSchduledServices
    // getAppliedForgienJobs,
    // getScheduledForeignJobs
    // getScheduledForeignJobs, getScheduledStudy, getScheduledSelfEmployment,
    // getUpcomingSelfEmployment, getForeignJobsInterview, 
    // ,  getAppliedEvents
} from "../../../actions/jobseekerAction";
import { get_User_District } from "../../../actions/accountAction";
import { markAttendence } from "../../../actions/counsellingproviderAction";
import { getJobStatus } from "../../../actions/employerAction";
import store from '../../../store';
import Pagination from "react-js-pagination";
import { chunk, getTimeWithAmPm } from '../../../helpers'
import StarRatings from 'react-star-ratings'
import ServerSetting from '../../../config.json';

const RESULT_PER_PAGE = 25

class MyAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            basePath: window.Location.protocol + '//' + window.Location.host + '/',
            show: false,
            heading: '',
            showTime: false,
            sessionData: [],
            checked: "",
            jobs: [],
            jobInterview: [],
            type: '',
            showApplyJob: false,
            job: '',
            activePageUpcomingPvtJobs: 1,
            activePageUpcomingGovtJobs: 1,
            activePageJobInvites: 1,
            activePageScheduledJobs: 1,
            activePageCounselling: 1,
            activePageStudy: 1,
            activePageForgeignJobs: 1,
            activePagehandleEmployment: 1,
            activePageForgeignJobsInterview: 1,
            activePagehandleUpcomingEmployment: 1,
            activePageJobApplied: 1,
            activePage: 1,
            inviteType: '',
            appliedForWebinar: false,
            jobType: 1,
            formData: [],
            appliedEvent: false,
            activePageEvents: 1,
            locations: [],
            description: '',
            activeSelfEmp: 1,
            activePageCareer: 1,
            selfEmpData: [],
            content: [],
            activePageSchduledServices: 1,
            activePageServices: 1
        }

        this.printContent = React.createRef();
        this.feedbackRef = React.createRef();
        // store.dispatch(getMatchingCareers())
        store.dispatch(getScheduledJobs())
        store.dispatch(get_User_District());
        // store.dispatch(getUserSelfEmpInterest());
        // store.dispatch(getTrainingInterest())

        store.dispatch(getAppliedCounsellings())

        store.dispatch(getUpcomingJobs())
        store.dispatch(getScheduledCareerCounselling())
        // store.dispatch(getRelevantSelfEmployment())

        // store.dispatch(getScheduledSelfEmployment());
        // store.dispatch(getUpcomingSelfEmployment());
        // store.dispatch(getForeignJobsInterview());
        store.dispatch(getJobStatus())
        store.dispatch(getAppliedJobs())

        store.dispatch(getAppliedServices());
        store.dispatch(getSchduledServices());

        // store.dispatch(getScheduledForeignJobs());
        // store.dispatch(getAppliedForgienJobs());
    }

    getLocationFromIds = (location) => {
        const names = []
        if (this.props.actionReducer.District && this.props.actionReducer.District.length > 0 && location && location.length) {
            this.props.actionReducer.District.forEach((data) => {
                if (data.state_id === "3") {
                    location.forEach(element => {
                        let filterVal = data.dist.filter((district) => district.id === element)
                        if (filterVal && filterVal[0]) {
                            names.push(filterVal[0].name)
                        }
                    });
                }
            })
        }
        return names.length ? names.toString() : ''
    }

    markAttendence = (jobs) => {
        if (!jobs.attendence) {
            store.dispatch(markAttendence({
                attendence: "1",
                session_id: jobs.id,
                applied_user_id: "64"
            }))
        }
    }

    submit = async (e) => {
        e.preventDefault()
        const formData = this.state.formData
        if (this.props.validator.allValid()) {
            formData['session_id'] = this.state.session_id
            await this.setState({ formData })
            await store.dispatch(sendFeedback(this.state.formData))
            await this.props.validator.hideMessages()
            this.setState({ formData: [] })
            this.feedbackRef.current.click()
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    handlePageChangeSchduledServices = (activePage) => {
        this.setState({ activePageSchduledServices: activePage })
    }

    handlePageChangeServices = (activePage) => {
        this.setState({ activePageServices: activePage })
    }

    change = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;

        formData[name] = value;
        this.setState({ formData });
    }

    handlePageChangeForgeignJobsInterview = (activePage) => {
        this.setState({ activePageForgeignJobsInterview: activePage })
    }

    componentDidMount() {
        document.title = this.props.t('header.my_account', { param: 'react' })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.JobseekerReducer.Profile && Object.keys(nextProps.JobseekerReducer.Profile).length) {

            const query = this.props.location.query
            // console.log('query', typeof query.event)
            if (nextProps.JobseekerReducer.Profile.webinar_subscription !== '1' && query.sub
                && query.sub === '1' && !this.state.appliedForWebinar) {
                store.dispatch(subscribeForWebinar({ webinar_subscription: '1' }, true))
                this.setState({ appliedForWebinar: true })
            }
            if (nextProps.JobseekerReducer.Profile.appliedEvent && nextProps.JobseekerReducer.Profile.appliedEvent.length &&
                query.event && query.event !== '1' && !this.state.appliedEvent) {
                const eventList = nextProps.JobseekerReducer.Profile.appliedEvent.filter((e) => e === query.event)
                if (eventList && !eventList.length) {
                    store.dispatch(applyEvent({ event_id: query.event }))
                    this.setState({ appliedEvent: true })
                }
            }
        }
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => {
            this.validateField(name, value)
        });
    }

    getJobStatus = (status) => {
        const matchedJob = this.props.EmployerReducer.jobStatus.filter((job) => job.id === status)
        return matchedJob && matchedJob[0] ? matchedJob[0].name : ''
    }

    handleChange = async (e, job) => {

        this.setState({ checked: e.target.value })

        // if (e.target.value === '1') {
        if (this.state.inviteType === 'job') {
            await store.dispatch(postJobInterviewParticipation({
                accept: e.target.value,
                job_id: job
            }))
        } else {
            await store.dispatch(postForeignJobInterviewParticipation({
                accept: e.target.value,
                job_id: job
            }))
        }

        // }


        setTimeout(() => {
            const modal = document.getElementById("myModal01");
            this.setState({ checked: "" })
            modal.click();
        }, 200)

    }

    capitalizeFirstLetter = (string) => {
        if (string) {
            const newString = string.toLowerCase()
            return newString.charAt(0).toUpperCase() + newString.slice(1);
        }
    }

    getJobStatusText = (status) => {
        return status
        // const matchedJob = this.props.EmployerReducer.jobStatus.filter((job) => job.id === status)
        // return matchedJob && matchedJob[0] ? matchedJob[0].name : ''
    }

    goForInterview = (job, status) => {
        store.dispatch(willGoForInterview({
            accepted: status,
            id: job.id
        }))
    }


    getDate = (created_at) => {
        if (created_at) {
            let date = created_at.split(" ")[0]
            date = date.split("-")
            return `${date[2]}-${date[1]}-${date[0]}`
        }
    }

    redirectToJobs = (id) => {
        this.props.router.push(`/job-details/${id}`)
    }

    getFormatedDate = (expireOn) => {
        if (expireOn) {
            const date = expireOn.split("-")
            return `${date[2]}-${date[1]}-${date[0]}`
        }

    }

    getExperience = (jobData) => {
        if (jobData.min_exp && jobData.max_exp) {
            return `${jobData.min_exp} - ${jobData.max_exp} years`
        } else {
            return "Fresher"
        }
    }

    openPopupForJobs = () => {
        const { type, jobs } = this.state
        if (type === 'jobInvitesAccept') {
            return [
                {
                    title: jobs.job_title ? jobs.job_title : '',
                    lable: this.props.t('dashboard.table.job-title', { param: 'react' }) + ": "
                },
                {
                    title: jobs.org_name ? jobs.org_name : '',
                    lable: this.props.t('JobPost.org_name', { param: 'react' }) + ": "
                },

                {
                    title: jobs.interview_venue ? jobs.interview_venue : '',
                    lable: this.props.t('dashboard.table.location', { param: 'react' }) + ": "
                },
                {
                    title: jobs.salary_min && jobs.salary_max ? jobs.salary_min + '-' + jobs.salary_max : jobs.salary_min,
                    lable: this.props.t('dashboard.table.salary', { param: 'react' }) + ": "
                },
                {
                    title: jobs.expiring_on ? jobs.expiring_on : '',
                    lable: this.props.t('dashboard.table.date', { param: 'react' }) + ": "
                },
            ]
        }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    handlePageChangeEvents = (activePage) => {
        this.setState({ activePageEvents: activePage })
    }

    handlePageChange1 = (activePage) => {
        this.setState({ activePageUpcomingPvtJobs: activePage })
    }
    handlePageChange2 = (activePage) => {
        this.setState({ activePageUpcomingGovtJobs: activePage })
    }
    handlePageChangeJobInvites = (activePage) => {
        this.setState({ activePageJobInvites: activePage })
    }
    handlePageChangeScheduledJobs = (activePage) => {
        this.setState({ activePageScheduledJobs: activePage })
    }
    handlePageChangeCounselling = (activePage) => {
        this.setState({ activePageCounselling: activePage })
    }
    handlePageChangeStudy = (activePage) => {
        this.setState({ activePageStudy: activePage })
    }
    handlePageChangeForgeignJobs = (activePage) => {
        this.setState({ activePageForgeignJobs: activePage })
    }

    handlePageChangeEmployment = (activePage) => {
        this.setState({ activePagehandleEmployment: activePage })
    }

    handlePageChangeUpComingEmployment = (activePage) => {
        this.setState({ activePagehandleUpcomingEmployment: activePage })
    }

    handleChangeRelEmployment = (activePage) => {
        this.setState({ handleChangeRelEmployment: activePage })
    }

    handlePageChangeJobApplied = (activePage) => {
        this.setState({ activePageJobApplied: activePage })
    }

    // saveTrainingInterest = () => {
    //     if (!this.props.JobseekerReducer.training_interest) {
    //         store.dispatch(postTrainingInterest())
    //     }
    // }

    // changeInterest = () => {
    //     if (!this.props.JobseekerReducer.interest) {
    //         store.dispatch(addInterestInSelfEmp())
    //     }
    // }

    handlePageChangeCareer = (activePage) => {
        this.setState({ activePageCareer: activePage })
    }

    getTimeWithAmPm = (time) => {
        if (time) {
            let newtime = time.split(":");
            var AmOrPm = newtime[0] >= 12 ? 'PM' : 'AM';
            let hours = (newtime[0] % 12) || 12
            return hours + ":" + newtime[1] + " " + AmOrPm
        }
        return ""
    }



    getLocationFromIds = (location) => {
        const names = []
        if (this.props.actionReducer.District && this.props.actionReducer.District.length > 0 && location && location.length) {
            this.props.actionReducer.District.forEach((data) => {
                if (data.state_id === "3") {
                    location.forEach(element => {
                        let filterVal = data.dist.filter((district) => district.id === element)
                        if (filterVal && filterVal[0]) {
                            names.push(filterVal[0].name)
                        }
                    });
                }
            })
        }
        return names.length ? names.toString() : ''
        // return  ''
    }

    handlePageChangeForgeignJobs = (activePage) => {
        this.setState({ activePageForgeignJobs: activePage })
    }

    getDate = (created_at) => {
        if (created_at) {
            let date = created_at.split(" ")[0]
            date = date.split("-")
            return `${date[2]}-${date[1]}-${date[0]}`
        }
    }

    ratingChanged = (val) => {
        const formData = this.state.formData
        formData['rating'] = val
        this.setState({ formData })
    }

    scrollToAccordion = (selector) => {
        // document.getElementById(selector).scrollIntoView(false)
    }


    render() {

        const { jobs, type, activePageUpcomingGovtJobs, activePageUpcomingPvtJobs,
            activePageJobApplied, activePageScheduledJobs, formData, jobInterview,
            activePageCounselling, activePage, showTime, sessionData,
            // activePageForgeignJobs, activePageForgeignJobsInterview,
            // activeSelfEmp, activePagehandleEmployment, activePageCareer,
            activePageServices, activePageSchduledServices
            // activePageStudy, activePagehandleEmployment,
            // activePagehandleUpcomingEmployment, 
            // activePage,  activePageEvents 
        } = this.state
        let Mainprops = this.props;
        let popupData = ''
        popupData = this.openPopupForJobs()
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;
        const scheduledJobs = chunk(Mainprops.JobseekerReducer.scheduledJobs, RESULT_PER_PAGE)
        // const jobInvites = chunk(Mainprops.JobseekerReducer.jobInvites, RESULT_PER_PAGE)
        const appliedJobs = chunk(Mainprops.JobseekerReducer.appliedJobs, RESULT_PER_PAGE)
        const upcomingGovtJobs = chunk(Mainprops.JobseekerReducer.upComingJobs.govt, RESULT_PER_PAGE)
        const upcomingPvtJobs = chunk(Mainprops.JobseekerReducer.upComingJobs.pvt, RESULT_PER_PAGE)
        const scheduledCounselling = chunk(Mainprops.JobseekerReducer.scheduledCounselling, RESULT_PER_PAGE)


        // const scheduledStudy = chunk(Mainprops.JobseekerReducer.scheduledStudy, RESULT_PER_PAGE)
        // const scheduledSelfEmployment = chunk(Mainprops.JobseekerReducer.scheduledSelfEmployment, RESULT_PER_PAGE)

        // const upComingSelfEmployment = chunk(Mainprops.JobseekerReducer.upComingSelfEmployment, RESULT_PER_PAGE)
        const appliedCounselling = chunk(this.props.JobseekerReducer.appliedPages, RESULT_PER_PAGE)
        // const appliedEvents = chunk(this.props.JobseekerReducer.appliedEvents, RESULT_PER_PAGE)
        // console.log('appliedEvents', Mainprops.JobseekerReducer.appliedEvents)
        // const scheduledSelfEmployment = chunk(Mainprops.JobseekerReducer.scheduledSelfEmployment, RESULT_PER_PAGE)
        // const relevantSelfEmp = chunk(Mainprops.JobseekerReducer.relevantSelfEmp, RESULT_PER_PAGE)

        // const scheduledForeignJobs = chunk(Mainprops.JobseekerReducer.scheduledForeignJobs, RESULT_PER_PAGE)
        // const appliedForeignJobs = chunk(Mainprops.JobseekerReducer.appliedForeignJobs, RESULT_PER_PAGE)
        // const careerOpt = chunk(Mainprops.JobseekerReducer.careerOpt, RESULT_PER_PAGE)
        const appliedServices = chunk(this.props.JobseekerReducer.appliedServices, RESULT_PER_PAGE)
        const schduledServices = chunk(Mainprops.JobseekerReducer.schduledServices, RESULT_PER_PAGE)

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        const { validator } = this.props

        const { selfEmpData, content } = this.state

        // console.log('upcomingPvtJobs', Mainprops.JobseekerReducer.upComingJobs)

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="modal fade" id="descriptionModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <h6>{this.props.t('counsellingProvider.details', { param: 'react' })}</h6>
                                            {selfEmpData.projects_covered ? <p>{this.props.t('selfEmployment.Project', { param: 'react' })}: {selfEmpData.projects_covered}</p> : ''}
                                            {selfEmpData.education_level ? <p>{this.props.t('selfEmployment.Education Level', { param: 'react' })} : {selfEmpData.education_level}</p> : ''}
                                            {selfEmpData.qualification ? <p>{this.props.t('selfEmployment.Qualification', { param: 'react' })}  : {selfEmpData.qualification}</p> : ''}
                                            {selfEmpData.training_required ? <p>{this.props.t('selfEmployment.Training', { param: 'react' })}: {selfEmpData.training_required}</p> : ''}
                                            {selfEmpData.experience ? <p>{this.props.t('selfEmployment.Experience (In Years)', { param: 'react' })} : {selfEmpData.experience}</p> : ''}
                                            {selfEmpData.collateral_required ? <p>{this.props.t('selfEmployment.Can you offer collateral', { param: 'react' })}: {selfEmpData.collateral_required && selfEmpData.collateral_required === "1" ? "Yes" : "No"}</p> : ''}
                                            {selfEmpData.defaulter ? <p>{this.props.t('selfEmployment.Have you defaulted against a loan in past', { param: 'react' })}: {selfEmpData.defaulter && selfEmpData.defaulter === "1" ? "Yes" : "No"}</p> : ''}
                                            {selfEmpData.collateral_required ? <p>{this.props.t('selfEmployment.Can you offer collateral', { param: 'react' })}: {selfEmpData.collateral_required && selfEmpData.collateral_required === "1" ? "Yes" : "No"}</p> : ''}
                                            {selfEmpData.loan_type ? <p>{this.props.t('selfEmployment.Type of Loan', { param: 'react' })}: {selfEmpData.loan_type}</p> : ''}
                                            {selfEmpData.loan_purpose ? <p>{this.props.t('selfEmployment.Purpose of Loan', { param: 'react' })}: {selfEmpData.loan_purpose}</p> : ''}
                                            {selfEmpData.repaying_period ? <p>{this.props.t('selfEmployment.Repaying Period', { param: 'react' })}: {selfEmpData.repaying_period}</p> : ''}
                                            {selfEmpData.family_income ? <p>{this.props.t('selfEmployment.Annual Family Income (In INR)', { param: 'react' })}: {selfEmpData.family_income} </p> : ''}
                                            {selfEmpData.subdistrict_id ? <p>{this.props.t('selfEmployment.Block Name', { param: 'react' })}: {selfEmpData.subdistrict_id}</p> : ''}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">
                                                {this.props.t('counsellingProvider.close', { param: 'react' })}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="careerModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLongTitle">
                                                {this.props.t('counsellingProvider.details', { param: 'react' })}
                                            </h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.JOB DESCRIPTION', { param: 'react' })}</h6>
                                                </div>
                                                <div className="panel-body">
                                                    <p className="date-clr">{content.description ? content.description : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.WORK ENVIRONMENT', { param: 'react' })}</h6>
                                                </div>
                                                <div className="panel-body">
                                                    <p className="date-clr">{content.work_environment ? content.work_environment : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.KEY COMPETENCIES', { param: 'react' })}</h6>
                                                </div>
                                                <div className="panel-body">
                                                    <p className="date-clr">{content.key_competencies ? content.key_competencies : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.DESIRABLE COMPETENCIES', { param: 'react' })}</h6>
                                                </div>
                                                <div className="panel-body">
                                                    <p className="date-clr">{content.desirable_competencies ? content.desirable_competencies : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.AVAILABLE SKILL TRAINING AND LEARNING INSTITUTES', { param: 'react' })}</h6>
                                                </div>
                                                <div className="panel-body">
                                                    <p className="date-clr">{content.skill_training_institutes ? content.skill_training_institutes : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.AVAILABLE SKILL TRAINING SCHEMES/SCHOLARSHIPS', { param: 'react' })}</h6></div>
                                                <div className="panel-body">
                                                    <p className="date-clr">{content.skill_training_schemes ? content.skill_training_schemes : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.CAREER PROGRESSION PATH', { param: 'react' })}</h6>
                                                </div>
                                                <div className="panel-body">
                                                    <p className="date-clr">{content.career_progress_path ? content.career_progress_path : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.EXPECTED EARNINGS', { param: 'react' })}</h6>
                                                </div>
                                                <div className="panel-body"><p className="date-clr">{content.expected_earnings ? content.expected_earnings : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.REQUIRED WORK EXPERIENCE', { param: 'react' })}</h6></div>
                                                <div className="panel-body"><p className="date-clr">{content.work_experience ? content.work_experience : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.PROBABLE EMPLOYERS', { param: 'react' })}</h6></div>
                                                <div className="panel-body"><p className="date-clr">{content.employers ? content.employers : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.KEYWORDS', { param: 'react' })} </h6></div>
                                                <div className="panel-body"><p className="date-clr">{content.keywords ? content.keywords : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.OCCUPATIONAL CODES AND STANDARDS', { param: 'react' })} </h6>
                                                </div>
                                                <div className="panel-body"> <p className="date-clr">{content.desirable_competencies ? content.desirable_competencies : "None"}</p>
                                                </div>
                                            </>
                                            <>
                                                <div className="panel-heading">
                                                    <h6>{this.props.t('selfEmployment.REFERENCES', { param: 'react' })}</h6>
                                                </div>
                                                <div className="panel-body"><p className="date-clr">{content.reference ? content.reference : "None"}</p>
                                                </div>
                                            </>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">{this.props.t('counsellingProvider.close', { param: 'react' })}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="descriptionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('dashboard.table.eligiblity', { param: 'react' })}</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {this.state.description ? ReactHtmlParser(this.state.description) : ''}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">{this.props.t('counsellingProvider.close', { param: 'react' })}</button>
                                            {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade bd-example-modal-sm" tabIndex="-1" id="locationModal"
                                role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" id="mySmallModalLabel">{this.props.t('homeContent.locations', { param: 'react' })}</h4>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <ul className="loc-lisiting">
                                                {this.state.locations && this.state.locations.length ? this.state.locations.map((loc, key) => {
                                                    return (<li key={key}><span >{loc}</span></li>)
                                                })
                                                    : ''}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal top-header2 show" id="myModal001">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">Job Info</h4>
                                            <button type="button" className="close"
                                                data-dismiss="modal">&times;</button>
                                        </div>


                                        <div className="modal-body">
                                            <div className="job-sec bg-white mb-3 p-3"><Link>{jobs.job_title}</Link>
                                                <p className="pt-none"><i
                                                    className="fa fa-briefcase  pr-2"></i>{this.getExperience(jobs)}
                                                </p>
                                                <div className="row">
                                                    <div className="col md-6 col-sm-12">
                                                        <p>Job Details</p>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ "fontWeight": "bold" }}>Employer name:
                                                                    </td>
                                                                    <td><span
                                                                        className="text-span"> {jobs.org_name ? " " + jobs.org_name : ''}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ "fontWeight": "bold" }}>Job Title:</td>
                                                                    <td><span
                                                                        className="text-span"> {jobs.job_title ? " " + jobs.job_title : ''}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ "fontWeight": "bold" }}>Experience:</td>
                                                                    <td><span
                                                                        className="text-span"> {this.getExperience(jobs)}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ "fontWeight": "bold" }}>Salary:</td>
                                                                    <td><span
                                                                        className="text-span"> {jobs.salary_min ? " " + jobs.salary_min : ''}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ "fontWeight": "bold" }}>Education:</td>
                                                                    <td><span
                                                                        className="text-span"> {jobs.education ? " " + jobs.education : ''}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span className="text-span">  </span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ "fontWeight": "bold" }}>Place of
                                                                    posting:
                                                                    </td>
                                                                    <td><span
                                                                        className="text-span">  {" " + this.getLocationFromIds(jobs.location)}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="job-sec bg-white mb-3 p-4"><Link>Job Description</Link>
                                            <div
                                                className="desc-modal">{jobs.description ? jobs.description : ''}</div>
                                        </div>


                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primart btn-primary2"
                                                data-dismiss="modal">Close
                                                </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="modal" id="feedbackModal1" ref={this.feedbackRef}>
                                <div className="modal-dialog">
                                    <div className="modal-content">

                                        <div className="modal-header">
                                            <h4 className="modal-title">{this.props.t('feedback.feedbackCounselling', { param: 'react' })}</h4>
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        </div>

                                        <div className="modal-body">
                                            <form onSubmit={this.submit}>
                                                <div className="form-group">
                                                    <div className="row">

                                                        <div className="col-sm-12">
                                                            <StarRatings
                                                                rating={formData.rating}
                                                                numberOfStars={5}
                                                                name='rating'
                                                                starDimension="20px"
                                                                starRatedColor="rgb(255, 215, 0)"
                                                                starHoverColor="rgb(255, 215, 0)"
                                                                changeRating={this.ratingChanged}

                                                            />
                                                            {validator.message('rating', formData.rating, 'required')}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label>{this.props.t('feedback.feedbackCounselling', { param: 'react' })} : <span
                                                                className="ml-2"
                                                                style={{ "color": "red" }}>*</span></label>

                                                            <textarea className="w-100 p-1"
                                                                onChange={this.change}
                                                                value={formData.remarks ? formData.remarks : ''}
                                                                placeholder={this.props.t('forms.enterFeedback', { param: 'react' })}
                                                                style={{ "height": "200px !important" }}
                                                                name="remarks" rows="4"
                                                                cols="8" />
                                                            {validator.message('feedback', formData.remarks, 'required')}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <button type="submit"
                                                                className="btn btn-primary btn-primary2 mx-auto d-block"
                                                                style={{ "marginRight": "10px" }}>
                                                                {this.props.t('feedback.submit', { param: 'react' })}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade bd-example-modal-sm" id="detailsModal" tabIndex="-1"
                                role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-md ">
                                    <div className="modal-content bg-modal bg-orange">
                                        <div className="modal-header" style={{ "background": "#f7b560 " }}>
                                            <h5 className="modal-title" id="exampleModalLabel">{this.props.t('JobPost.int', { param: 'react' })}</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body pt-2 pb-1 text-center" style={{ "background": "#f7b560 " }} ref={this.printContent}>
                                            <div className="row">
                                                <div className="col-md-12">

                                                    <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                                    <p>Please find below interview details that you have applied for on pgrkam.com</p>
                                                    <span className="AppliedJob"><span>{this.props.t('jobProviderProfile.org_name', { param: 'react' })}:</span> <span>{jobInterview.org_name}</span></span>
                                                    <span className="AppliedJob"><span>{this.props.t('dashboard.table.job-title', { param: 'react' })}:</span> <span> {jobInterview.job_title}</span></span>

                                                    {jobInterview.interview_date &&
                                                        <span className="AppliedJob"><span>{this.props.t('JobPost.int_date', { param: 'react' })}: </span> <span>{jobInterview.interview_date}</span></span>}
                                                    {jobInterview.slot_time_start &&
                                                        <span className="AppliedJob"><span>{this.props.t('JobPost.int_time', { param: 'react' })}: </span> <span>{this.getTimeWithAmPm(jobInterview.slot_time_start)}</span></span>}

                                                    {/* {jobInterview.slot_date_start && jobInterview.slot_date_start !== "null" &&
                                                        <span className="AppliedJob"><span>{this.props.t('JobPost.int_date', { param: 'react' })}:</span> <span> {jobInterview.slot_date_start}</span></span>}
                                                    {jobInterview.time_slot !== "null" && jobInterview.time_slot !== "null" &&
                                                        <span className="AppliedJob"><span>{this.props.t('JobPost.timeSlot', { param: 'react' })}:</span> <span> {jobInterview.time_slot} minutes</span></span>} */}
                                                    {jobInterview.contact_person && jobInterview.contact_person !== "null" &&
                                                        <span className="AppliedJob">
                                                            <span>{this.props.t('dashboard.table.contact_person', { param: 'react' })}:</span> <span> {jobInterview.contact_person}</span></span>}
                                                    {jobInterview.person_designation && jobInterview.person_designation !== "null" &&
                                                        <span className="AppliedJob"><span>{this.props.t('dashboard.table.designation', { param: 'react' })}:</span> <span> {jobInterview.person_designation}</span></span>}
                                                    {jobInterview.interview_phone && jobInterview.interview_phone !== "null" &&
                                                        <span className="AppliedJob"> <span>{this.props.t('JobPost.contact_phone', { param: 'react' })} : </span> <span>{jobInterview.interview_phone}</span></span>}

                                                    {jobInterview.address_Line1 &&
                                                        <span className="AppliedJob"> <span>{this.props.t('jobProviderProfile.add1', { param: 'react' })} : </span> <span>{jobInterview.address_Line1}</span></span>}

                                                    {jobInterview.address_Line2 &&
                                                        <span className="AppliedJob"> <span>{this.props.t('jobProviderProfile.add2', { param: 'react' })} : </span> <span>{jobInterview.address_Line2}</span></span>}

                                                    {jobInterview.address_Line3 &&
                                                        <span className="AppliedJob"> <span>{this.props.t('jobProviderProfile.add3', { param: 'react' })} : </span> <span>{jobInterview.address_Line3}</span></span>}

                                                    {jobInterview.city &&
                                                        <span className="AppliedJob"> <span>{this.props.t('JobPost.city', { param: 'react' })} : </span> <span>{jobInterview.city}</span></span>}
                                                    <p>{this.props.t('JobPost.modalDisclaimer', { param: 'react' })}</p>
                                                </div>
                                                <hr></hr>

                                            </div>

                                        </div>
                                        <ReactToPrint
                                            trigger={() => <button
                                                className={"btn btn-danger btn-print mx-auto d-block mb-2 mt-2"}>
                                                {this.props.t('hallTicket.print', { param: 'react' })}
                                            </button>}
                                            content={() => this.printContent.current}
                                            copyStyles={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade bd-example-modal-md" id="sessionDetails" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-md">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title"><Link>{sessionData.title}</Link></h4>
                                            <button type="button" className="close"
                                                data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <table className="w-100">
                                                <tbody>
                                                    <tr>
                                                        <td className="date-clr">{this.props.t('counsellingProvider.sessionMode', { param: 'react' })}:
                                                                    </td>
                                                        <td><span
                                                            className="text-span"> {sessionData.mode ? " " + sessionData.mode : ''}</span>
                                                        </td>
                                                    </tr>
                                                    {sessionData.mode_id === '1' || (sessionData.mode_id === '2' && sessionData.modeType === '2') ?
                                                        <>
                                                            <tr>
                                                                <td className="date-clr">{this.props.t('counsellingProvider.counsellingDate', { param: 'react' })}:
                                                                    </td>
                                                                <td><span
                                                                    className="text-span"> {sessionData.valid_from + " - " + sessionData.expire_on}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="date-clr">{this.props.t('counsellingProvider.timings', { param: 'react' })}:
                                                                    </td>
                                                                <td><span
                                                                    className="text-span"> {getTimeWithAmPm(sessionData.timing_from) + "-" + getTimeWithAmPm(sessionData.timing_to)}</span>
                                                                </td>
                                                            </tr>
                                                        </> : null}
                                                    {sessionData.mode_id === '2' ?
                                                        <>
                                                            {sessionData.modeType === '1' ?
                                                                <>
                                                                    <tr>
                                                                        <td className="date-clr">{this.props.t('JobPost.venue', { param: 'react' })}:
                                                                    </td>
                                                                        <td><span
                                                                            className="text-span"> {sessionData.address ? " " + sessionData.address : ''}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="date-clr">{this.props.t('counsellingProvider.counsellingDate', { param: 'react' })}:
                                                                </td>
                                                                        <td><span
                                                                            className="text-span"> {sessionData.valid_from ? " " + sessionData.valid_from : ''}</span>
                                                                        </td>
                                                                    </tr>
                                                                    {showTime ?
                                                                        <tr>
                                                                            <td className="date-clr">{this.props.t('counsellingProvider.session_start_time', { param: 'react' })}:
                                                                </td>
                                                                            <td><span
                                                                                className="text-span"> {getTimeWithAmPm(sessionData.start_time)}</span>
                                                                            </td>
                                                                        </tr> : null}
                                                                </>
                                                                : null}
                                                        </> : null}
                                                    {sessionData.mode_id === '1' && sessionData.phone_no ?
                                                        <tr>
                                                            <td className="date-clr">{this.props.t('JobPost.cont_phone', { param: 'react' })}:
                                                                    </td>
                                                            <td><span
                                                                className="text-span"> {" " + sessionData.phone_no}</span>
                                                            </td>
                                                        </tr> : null}
                                                    {sessionData.mode_id === '3' ?
                                                        <>
                                                            <tr>
                                                                <td className="date-clr">{this.props.t('counsellingProvider.link', { param: 'react' })}:
                                                                    </td>
                                                                <td><a className="date-clr" target="_blank" rel="noopener noreferrer" onClick={() => this.markAttendence(sessionData)}
                                                                    href={sessionData.coun_link}>{this.props.t('counsellingProvider.clickHere', { param: 'react' })}</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="date-clr">{this.props.t('counsellingProvider.username', { param: 'react' })}:
                                                                    </td>
                                                                <td><span
                                                                    className="text-span"> {sessionData.username ? " " + sessionData.username : ''}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="date-clr">{this.props.t('counsellingProvider.password', { param: 'react' })}:
                                                                    </td>
                                                                <td><span
                                                                    className="text-span"> {sessionData.password ? " " + sessionData.password : ''}</span>
                                                                </td>
                                                            </tr>
                                                        </> : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal top-header2 show" id="myModal01" aria-modal="true">
                                <div className="modal-dialog">
                                    <div className="modal-content" style={{ "marginTop": "30%" }}>
                                        <div className="modal-header ">
                                            <div className="panel-heading w-100">
                                                <span className="mb-0">{this.props.t('dashboard.table.description', { param: 'react' })}</span>

                                                <button type="button" className="close" data-dismiss="modal">×</button>
                                            </div>
                                        </div>
                                        <div className="modal-body p-3">
                                            {popupData && popupData.map((job, key) => {
                                                return (<div className="row" key={key}>
                                                    <div className="col-sm-4 col-6 text-right">
                                                        <label>{job.lable}</label>
                                                    </div>
                                                    <div className="col-sm-8 col-6">
                                                        <label>{job.title}</label>
                                                    </div>
                                                </div>)
                                            })}
                                            {type === 'jobInvitesAccept' && <div className="row">
                                                <div className="col-sm-4 text-right">
                                                    <label data-toggle="tooltip" data-placement="bottom"
                                                        title={this.props.t('dashboard.table.will_go', { param: 'react' })}>{this.props.t('dashboard.table.will_go', { param: 'react' })}</label>
                                                </div>
                                                <div className="col-sm-8 form-group pr-0">

                                                    <div className="form-check-inline mr-0 pr-1">
                                                        <label className="form-check-label" htmlFor="radio1"
                                                            style={{ "marginRight": "10px" }}>
                                                            <input required type="radio"
                                                                onChange={(event) => this.handleChange(event, jobs.id)}
                                                                checked={this.state.checked === '1'}
                                                                className="form-check-input" name="will_go"
                                                                value={"1"} />Yes
                                                        </label>
                                                        <label className="form-check-label" htmlFor="radio1">
                                                            <input required type="radio"
                                                                onChange={(event) => this.handleChange(event, jobs.id)}
                                                                checked={this.state.checked === '0'}
                                                                className="form-check-input" name="will_go"
                                                                value={"0"} />No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <div className={"row"}>
                                                            <div className={"col-lg-6"}>
                                                                <h6 className="mb-0">{this.props.t('dashboard.title', { param: 'react' })}</h6>
                                                            </div>
                                                            {/* <div className={"col-md-6"}>
                                                                <h6 className="mb-0 text-underline">
                                                                    <a className={"pull-right text-dark"}
                                                                        data-toggle="collapse" href="#my-panel2"
                                                                    >{this.props.t('modal.dash_inst', { param: 'react' })}</a>
                                                                </h6>
                                                            </div>
                                                            <div id="my-panel2" className="collapse my-drop">
                                                                <div className="row d-flex">
                                                                    <div className="col-md-12 col-lg-12 col-sm-12 d-flex ">
                                                                        <div className="">

                                                                            <h5>{this.props.t('how_to_use.instruction', { param: 'react' })}</h5>
                                                                            <ul className="jobseeker-benefits"
                                                                            >
                                                                                <li>{this.props.t('how_to_use.changePassword', { param: 'react' })} {" "}

                                                                                </li>

                                                                                <li>{this.props.t('how_to_use.updateProfile', { param: 'react' })}</li>
                                                                                <li>{this.props.t('how_to_use.searchJob', { param: 'react' })}</li>
                                                                                <li>{this.props.t('how_to_use.appliedJob', { param: 'react' })}</li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="panel-body">
                                                        {/* <div className="mb-2 d-flex">
                                                            <div className="dashboard-items" data-toggle="collapse" data-parent="#accordionNo1" href="#collapse1221" aria-expanded="true" aria-controls="collapse1221">
                                                                <img src="/images/icon7.png" alt={this.props.t('dashboard.jobs', { param: 'react' })} />
                                                                <span><b>{this.props.t('dashboard.jobs', { param: 'react' })}</b></span>
                                                            </div>
                                                            {(scheduledCounselling && scheduledCounselling.length > 0) || (appliedCounselling && appliedCounselling.length) ?
                                                                <div className="dashboard-items" data-toggle="collapse" data-parent="#accordionNo1" href="#collapse1222" aria-expanded="false" aria-controls="collapse1222">
                                                                    <img src="/images/icon2.png" alt={this.props.t('homeContent.Counseling', { param: 'react' })} />
                                                                    <span><b>{this.props.t('homeContent.Counseling', { param: 'react' })}</b></span>
                                                                </div> : null}
                                                            <div className="dashboard-items" data-toggle="collapse" data-parent="#accordionNo1" onClick={() => this.scrollToAccordion('self-emp')} href="#collapse1225" aria-expanded="false" aria-controls="collapse1225">
                                                                <img src="/images/icon7.png" alt={this.props.t('homeContent.selfEmp', { param: 'react' })} />
                                                                <span><b>{this.props.t('homeContent.selfEmp', { param: 'react' })}</b></span>
                                                            </div>
                                                            {careerOpt && careerOpt.length > 0 ?
                                                                <div className="dashboard-items" data-toggle="collapse" data-parent="#accordionNo1" href="#collapse0011" onClick={() => this.scrollToAccordion('collapse0011')} aria-expanded="false" aria-controls="collapse0011">
                                                                    <img src="/images/icon4.png" alt={this.props.t('career.title', { param: 'react' })} />
                                                                    <span><b>{this.props.t('career.title', { param: 'react' })}</b></span>
                                                                </div> : null}
                                                            {(appliedServices && appliedServices.length > 0) || (schduledServices && schduledServices.length > 0) ?
                                                                <div className="dashboard-items" data-toggle="collapse" data-parent="#accordionNo1" href="#collapse0101" onClick={() => this.scrollToAccordion('collapse0101')} aria-expanded="false" aria-controls="collapse0101">
                                                                    <img src="/images/service-ico.png" alt={this.props.t('career.title', { param: 'react' })} />
                                                                    <span><b>{this.props.t('homeContent.localServices', { param: 'react' })}</b></span>
                                                                </div> : null}

                                                        </div> */}
                                                        < div className="accordion md-accordion accordion-2"
                                                            id="accordionNo1" role="tablist"
                                                            aria-multiselectable="true">
                                                            {/* {(scheduledJobs && scheduledJobs.length > 0) || (jobInvites && jobInvites.length > 0) || (upcomingJobs && upcomingJobs.length > 0) ? */}
                                                            <div className="card border-0">
                                                                <div
                                                                    className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                    role="tab" id="heading12">
                                                                    <a className="collapsed" data-toggle="collapse"
                                                                        data-parent="#accordionNo1" href="#collapse1221"
                                                                        aria-expanded="false" aria-controls="collapse1221">
                                                                        <h6 className="mb-0 white-text font-thin">
                                                                            {this.props.t('dashboard.jobs', { param: 'react' })}<i
                                                                                className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                        </h6>
                                                                    </a>
                                                                </div>
                                                                <div id="collapse1221" className="collapse sub-content"
                                                                    role="tabpanel" aria-labelledby="heading12"
                                                                    data-parent="#accordionNo1">
                                                                    <div className="accordion md-accordion accordion-2 "
                                                                        id="accordionEx9" role="tablist"
                                                                        aria-multiselectable="true">

                                                                        <div className="card border-0">

                                                                            {/* <!-- Card header --> */}
                                                                            <div
                                                                                className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                                role="tab" id="heading123">
                                                                                <a className="collapsed" data-toggle="collapse"
                                                                                    data-parent="#accordionEx9"
                                                                                    href="#collapse123"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapse123">
                                                                                    <h6 className="mb-0 white-text font-thin">
                                                                                        {this.props.t('dashboard.job', { param: 'react' })}
                                                                                        <i
                                                                                            className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                    </h6>
                                                                                </a>
                                                                            </div>

                                                                            {/* <!-- Card body --> */}
                                                                            <div id="collapse123" className="collapse"
                                                                                role="tabpanel" aria-labelledby="heading123"
                                                                                data-parent="#accordionEx9">
                                                                                <div
                                                                                    className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                    <span className="d-block" style={{ "cursor": "pointer" }}><span className={this.state.jobType === 1 ? "date-clr" : ""} onClickCapture={() => {
                                                                                        this.setState({ jobType: 1 })
                                                                                    }}>{this.props.t('menu.private', { param: 'react' })} ({Mainprops.JobseekerReducer.upComingJobs && Mainprops.JobseekerReducer.upComingJobs.pvt && Mainprops.JobseekerReducer.upComingJobs.pvt.length ? Mainprops.JobseekerReducer.upComingJobs.pvt.length : 0}) </span> / <span className={this.state.jobType === 2 ? "date-clr" : ""}
                                                                                        onClickCapture={() => this.setState({ jobType: 2 })}>{this.props.t('menu.govt', { param: 'react' })} ({Mainprops.JobseekerReducer.upComingJobs && Mainprops.JobseekerReducer.upComingJobs.govt && Mainprops.JobseekerReducer.upComingJobs.govt.length ? Mainprops.JobseekerReducer.upComingJobs.govt.length : 0})</span></span>
                                                                                    {this.state.jobType === 1 &&
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.job-title', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.no_of_vacancies', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.salary', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.place_of_work', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.eligiblity', { param: 'react' })}</th>
                                                                                                        {/* <th style={{"width":"10%"}}>{this.props.t('dashboard.table.expire_on', {param: 'react'})}</th> */}
                                                                                                        <th>{this.props.t('dashboard.table.action', { param: 'react' })}</th>

                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {upcomingPvtJobs && upcomingPvtJobs.length > 0 && upcomingPvtJobs[activePageUpcomingPvtJobs - 1].map((jobs, key) => {
                                                                                                        let locationList = ''
                                                                                                        let locationsArray = ''
                                                                                                        let locations = ''
                                                                                                        locationList = this.getLocationFromIds(jobs.location)
                                                                                                        locationsArray = locationList.split(",")
                                                                                                        locations = locationsArray.slice(0, 3)
                                                                                                        locations = locations.toString()
                                                                                                        return (<tr key={key}>
                                                                                                            <td>{((activePageUpcomingPvtJobs - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                            <td>
                                                                                                                {jobs.job_title}
                                                                                                            </td>
                                                                                                            <td>{jobs.vacancies && jobs.vacancies.split(" ")[0]}</td>
                                                                                                            {!jobs.hasOwnProperty("salary") && <td>{jobs.salary_min && jobs.salary_min.split(" ")[0]}-{jobs.salary_max && jobs.salary_max.split(" ")[0]}</td>}
                                                                                                            {jobs.hasOwnProperty("salary") && <td>{jobs.salary ? jobs.salary : ''}</td>}
                                                                                                            {!jobs.hasOwnProperty("apply_link") && <td ><span>
                                                                                                                {locations} {jobs.location.length > 3 && <span style={{ "cursor": "pointer" }} className="date-clr" data-toggle="modal"
                                                                                                                    data-target="#locationModal" onClick={() => this.setState({ locations: locationsArray })}>{"," + this.props.t('homeContent.viewLocations', { param: 'react' })}</span>}
                                                                                                            </span></td>}
                                                                                                            {/* {jobs.hasOwnProperty("apply_link") && <td >{jobs.location}</td>} */}
                                                                                                            {!jobs.hasOwnProperty('display_qualification') && <td>{jobs.education_level ? jobs.education_level : ''}</td>}

                                                                                                            {/* <td style={{"width":"12%"}}>{jobs.expiring_on ? jobs.expiring_on : ''}</td> */}
                                                                                                            {!jobs.hasOwnProperty("apply_link") &&
                                                                                                                <td onClick={() => this.redirectToJobs(jobs.id)}><span
                                                                                                                    style={{ "cursor": "pointer" }}
                                                                                                                    className="date-clr">
                                                                                                                    {this.props.t('homeContent.clickHere', { param: 'react' })}
                                                                                                                </span></td>}
                                                                                                            {jobs.hasOwnProperty("apply_link") &&
                                                                                                                <td ><a
                                                                                                                    target="_blank" href={jobs.apply_link}
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    className="date-clr">
                                                                                                                    {this.props.t('homeContent.clickHere', { param: 'react' })}
                                                                                                                </a></td>}

                                                                                                        </tr>)
                                                                                                    })}
                                                                                                    {Mainprops.JobseekerReducer.upComingJobs && Mainprops.JobseekerReducer.upComingJobs.pvt && !Mainprops.JobseekerReducer.upComingJobs.pvt.length &&
                                                                                                        <tr>
                                                                                                            <td colSpan="7"
                                                                                                                style={{ "textAlign": "center" }}>
                                                                                                                <h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6>
                                                                                                            </td>
                                                                                                        </tr>}
                                                                                                </tbody>
                                                                                            </table>
                                                                                            {upcomingPvtJobs && upcomingPvtJobs.length > 1 ?
                                                                                                <div className='react-pagination'>
                                                                                                    <Pagination
                                                                                                        pageRangeDisplayed={10}
                                                                                                        activePage={activePageUpcomingPvtJobs}
                                                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                        totalItemsCount={Mainprops.JobseekerReducer.upComingJobs && Mainprops.JobseekerReducer.upComingJobs.pvt && Mainprops.JobseekerReducer.upComingJobs.pvt.length}
                                                                                                        onChange={this.handlePageChange1}
                                                                                                    />
                                                                                                </div> : ''}
                                                                                        </div>
                                                                                    }
                                                                                    {this.state.jobType === 2 && <div className="table-responsive">
                                                                                        <table className="table table-striped">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('dashboard.table.job-title', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('dashboard.table.no_of_vacancies', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('homeContent.whereToapply', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('dashboard.table.place_of_work', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('dashboard.table.eligiblity', { param: 'react' })}</th>
                                                                                                    <th style={{ "width": "10%" }}>{this.props.t('dashboard.table.expire_on', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('homeContent.apply', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('govtJobs.readNotification', { param: 'react' })}</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {upcomingGovtJobs && upcomingGovtJobs.length > 0 && upcomingGovtJobs[activePageUpcomingGovtJobs - 1].map((jobs, key) => {
                                                                                                    return (<tr key={key}>
                                                                                                        <td>{((activePageUpcomingGovtJobs - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                        <td>
                                                                                                            {jobs.job_title}
                                                                                                        </td>
                                                                                                        <td>{jobs.vacancies && jobs.vacancies.split(" ")[0]}</td>
                                                                                                        <td>{jobs.apply_mode ? this.capitalizeFirstLetter(jobs.apply_mode) : ""}</td>
                                                                                                        {jobs.hasOwnProperty("apply_link") && <td >{jobs.location}</td>}
                                                                                                        {jobs.hasOwnProperty('display_qualification') && jobs.display_qualification && <td style={{ "width": "12%" }}><Link className="date-clr"
                                                                                                            data-target="#descriptionModal" data-toggle="modal"
                                                                                                            style={{ "cursor": "pointer" }}
                                                                                                            onClick={() => this.setState({ description: jobs.display_qualification })}>{this.props.t('homeContent.clickHere', { param: 'react' })}</Link></td>}
                                                                                                        {/* {!jobs.hasOwnProperty("salary") && <td>{jobs.salary_min && jobs.salary_min.split(" ")[0]}-{jobs.salary_max && jobs.salary_max.split(" ")[0]}</td>} */}
                                                                                                        {/* {jobs.hasOwnProperty("salary") && <td>{jobs.salary ? jobs.salary : ''}</td>} */}
                                                                                                        {/* {!jobs.hasOwnProperty("apply_link") && <td className="fix-col">{jobs.location ? this.getLocationFromIds(jobs.location) : ''}</td>} */}

                                                                                                        {/* {!jobs.hasOwnProperty('display_qualification') && <td>{jobs.education_level ? jobs.education_level : ''}</td>} */}

                                                                                                        <td style={{ "width": "12%" }}>{jobs.expiring_on ? jobs.expiring_on : ''}</td>


                                                                                                        <td style={{ "width": "12%" }}>{this.capitalizeFirstLetter(jobs.apply_mode) !== "Offline" ? <a
                                                                                                            target="_blank" href={jobs.apply_link}
                                                                                                            rel="noopener noreferrer"
                                                                                                            className="date-clr">
                                                                                                            {this.props.t('homeContent.clickHere', { param: 'react' })}
                                                                                                        </a> : null}</td>
                                                                                                        <td ><a
                                                                                                            target="_blank" href={jobs.pdf_link}
                                                                                                            rel="noopener noreferrer"
                                                                                                            className="date-clr">
                                                                                                            {this.props.t('homeContent.clickHere', { param: 'react' })}
                                                                                                        </a></td>
                                                                                                    </tr>)
                                                                                                })}
                                                                                                {Mainprops.JobseekerReducer.upComingJobs && Mainprops.JobseekerReducer.upComingJobs.govt && !Mainprops.JobseekerReducer.upComingJobs.govt.length &&
                                                                                                    <tr>
                                                                                                        <td colSpan="9"
                                                                                                            style={{ "textAlign": "center" }}>
                                                                                                            <h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6>
                                                                                                        </td>
                                                                                                    </tr>}
                                                                                            </tbody>
                                                                                        </table>
                                                                                        {upcomingGovtJobs && upcomingGovtJobs.length > 1 ?
                                                                                            <div className='react-pagination'>
                                                                                                <Pagination
                                                                                                    pageRangeDisplayed={10}
                                                                                                    activePage={activePageUpcomingGovtJobs}
                                                                                                    itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                    totalItemsCount={Mainprops.JobseekerReducer.upComingJobs && Mainprops.JobseekerReducer.upComingJobs.govt && Mainprops.JobseekerReducer.upComingJobs.govt.length}
                                                                                                    onChange={this.handlePageChange2}
                                                                                                />
                                                                                            </div> : ''}
                                                                                    </div>}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card border-0">

                                                                            {/* <!-- Card header --> */}
                                                                            <div
                                                                                className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                                role="tab" id="heading12">
                                                                                <a className="collapsed" data-toggle="collapse"
                                                                                    data-parent="#accordionEx9" href="#collapse1234"
                                                                                    aria-expanded="false" aria-controls="collapse1234">
                                                                                    <h6 className="mb-0 white-text font-thin">
                                                                                        {this.props.t('sidebar.jobsDesc', { param: 'react' })}<i
                                                                                            className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                    </h6>
                                                                                </a>
                                                                            </div>

                                                                            {/* <!-- Card body --> */}
                                                                            <div id="collapse1234" className="collapse"
                                                                                role="tabpanel" aria-labelledby="heading12"
                                                                                data-parent="#accordionEx9">
                                                                                <div
                                                                                    className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-striped">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('dashboard.table.job-title', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('dashboard.table.employer', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('dashboard.table.salary', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('dashboard.table.applied_on', { param: 'react' })}</th>
                                                                                                    <th>{this.props.t('dashboard.table.status', { param: 'react' })}</th>

                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {appliedJobs && appliedJobs.length > 0 && appliedJobs[activePageJobApplied - 1].map((jobs, key) => {
                                                                                                    return (<tr key={key}>
                                                                                                        <td>{((activePageJobApplied - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                        <td
                                                                                                        // onClick={() => this.setState({ jobs })}
                                                                                                        >
                                                                                                            {/* <span
                                    data-toggle="modal"
                                    style={{ "cursor": "pointer" }}
                                    data-target="#myModal001"
                                    className="date-clr"> */}
                                                                                                            {jobs.job_title ? jobs.job_title : ''}
                                                                                                            {/* </span> */}
                                                                                                        </td>
                                                                                                        <td>{jobs.org_name ? jobs.org_name : ''}</td>
                                                                                                        <td>{jobs.salary_min && jobs.salary_max ? jobs.salary_min + '-' + jobs.salary_max : jobs.salary_min} </td>
                                                                                                        <td>{jobs.applied_on ? jobs.applied_on.split(" ")[0] : ''}</td>
                                                                                                        <td>{this.getJobStatusText(jobs.job_status)}</td>
                                                                                                    </tr>)
                                                                                                })}

                                                                                                {!Mainprops.JobseekerReducer.appliedJobs.length &&
                                                                                                    <tr>
                                                                                                        <td colSpan="8"
                                                                                                            style={{ "textAlign": "center" }}>
                                                                                                            <h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                    </tr>}

                                                                                            </tbody>
                                                                                        </table>
                                                                                        {appliedJobs && appliedJobs.length > 1 ?
                                                                                            <div className='react-pagination'>
                                                                                                <Pagination
                                                                                                    pageRangeDisplayed={10}
                                                                                                    activePage={activePageJobApplied}
                                                                                                    itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                    totalItemsCount={Mainprops.JobseekerReducer.appliedJobs.length}
                                                                                                    onChange={this.handlePageChangeJobApplied}
                                                                                                />
                                                                                            </div> : ''}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="card border-0">

                                                                                {/* <!-- Card header --> */}
                                                                                <div
                                                                                    className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                                    role="tab" id="heading12">
                                                                                    <a className="collapsed" data-toggle="collapse"
                                                                                        data-parent="#accordionEx9" href="#collapse12"
                                                                                        aria-expanded="false" aria-controls="collapse12">
                                                                                        <h6 className="mb-0 white-text font-thin">
                                                                                            {this.props.t('dashboard.job_interviews', { param: 'react' })}<i
                                                                                                className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                        </h6>
                                                                                    </a>
                                                                                </div>

                                                                                {/* <!-- Card body --> */}
                                                                                <div id="collapse12" className="collapse"
                                                                                    role="tabpanel" aria-labelledby="heading12"
                                                                                    data-parent="#accordionEx9">
                                                                                    <div
                                                                                        className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.job-title', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.employer', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.date', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('govtJobs.agePref', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('jobSearch.qual', { param: 'react' })}</th>
                                                                                                        {/* <th>{this.props.t('dashboard.table.mobile', { param: 'react' })}</th> */}
                                                                                                        <th>{this.props.t('dashboard.table.will_go', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('JobPost.int', { param: 'react' })}</th>
                                                                                                        {/* <th>{this.props.t('dashboard.table.salary', {param: 'react'})}</th> */}

                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {scheduledJobs && scheduledJobs.length > 0 && scheduledJobs[activePageScheduledJobs - 1].map((jobs, key) => {
                                                                                                        return (<tr key={key}>
                                                                                                            <td>{((activePageScheduledJobs - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                            <td>{jobs.job_title ? jobs.job_title : ''}</td>
                                                                                                            <td>{jobs.org_name ? jobs.org_name : ''}</td>
                                                                                                            <td>{jobs.created_at ? jobs.created_at : ""}</td>
                                                                                                            <td>{jobs.age_preference ? jobs.age_preference : ""}</td>
                                                                                                            <td>{jobs.qualification ? jobs.qualification : jobs.education}</td>
                                                                                                            {/* <td>{jobs.employer_no ? jobs.employer_no : ""}</td> */}
                                                                                                            <td>{!parseInt(jobs.invitation_accepted) && <div style={{ "cursor": "pointer" }}><Link className="date-clr" onClick={() => this.goForInterview(jobs, '1')}>{this.props.t('dashboard.table.accept', { param: 'react' })}</Link>/
<Link className="date-clr" onClick={() => this.goForInterview(jobs, '0')}>{this.props.t('dashboard.table.reject', { param: 'react' })}</Link></div>}
                                                                                                                {/* {jobs.invitation_accepted === '0' && <span>{this.props.t('dashboard.table.reject', { param: 'react' })}</span>}
{jobs.invitation_accepted === '1' && <span>{this.props.t('dashboard.table.accept', { param: 'react' })}</span>} */}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {jobs.invitation_accepted && jobs.invitation_accepted === '1' &&
                                                                                                                    <span onClick={() => this.setState({ jobInterview: jobs })}
                                                                                                                        data-toggle="modal" data-target="#detailsModal" className="date-clr" style={{ "cursor": "pointer" }}>
                                                                                                                        {this.props.t('modal.clickHere', { param: 'react' })}
                                                                                                                    </span>
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr>)
                                                                                                    })}
                                                                                                    {!Mainprops.JobseekerReducer.scheduledJobs.length &&
                                                                                                        <tr>
                                                                                                            <td colSpan="8"
                                                                                                                style={{ "textAlign": "center" }}>
                                                                                                                <h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                        </tr>}
                                                                                                </tbody>
                                                                                            </table>
                                                                                            {scheduledJobs && scheduledJobs.length > 1 ?
                                                                                                <div className='react-pagination'>
                                                                                                    <Pagination
                                                                                                        pageRangeDisplayed={10}
                                                                                                        activePage={activePageScheduledJobs}
                                                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                        totalItemsCount={Mainprops.JobseekerReducer.scheduledJobs.length}
                                                                                                        onChange={this.handlePageChangeScheduledJobs}
                                                                                                    />
                                                                                                </div> : ''}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                            {/* <div className="card border-0">

                                                                <div
                                                                    className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                    role="tab" id="heading12">
                                                                    <a className="collapsed" data-toggle="collapse"
                                                                       data-parent="#accordionEx9" href="#collapse1234"
                                                                       aria-expanded="false" aria-controls="collapse1234">
                                                                        <h6 className="mb-0 white-text font-thin">
                                                                            {this.props.t('dashboard.invitations_for_interviews', {param: 'react'})}<i
                                                                            className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                        </h6>
                                                                    </a>
                                                                </div>

                                                                <div id="collapse1234" className="collapse"
                                                                     role="tabpanel" aria-labelledby="heading12"
                                                                     data-parent="#accordionEx9">
                                                                    <div
                                                                        className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                        <div className="table-responsive">
                                                                            <table className="table table-striped">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>{this.props.t('dashboard.table.sr_no', {param: 'react'})}</th>
                                                                                    <th>{this.props.t('dashboard.table.job-title', {param: 'react'})}</th>
                                                                                    <th>{this.props.t('dashboard.table.no_of_vacancies', {param: 'react'})}</th>
                                                                                    <th>{this.props.t('dashboard.table.salary', {param: 'react'})}</th>
                                                                                    <th>{this.props.t('dashboard.table.eligiblity', {param: 'react'})}</th>
                                                                                    <th>{this.props.t('dashboard.table.expire_on', {param: 'react'})}</th>
                                                                                    <th>{this.props.t('dashboard.table.will_go', {param: 'react'})}</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {jobInvites.length > 0 && jobInvites[activePageJobInvites -1 ].map((jobs, key) => {
                                                                                    return (<tr key={key}>
                                                                                        <td>{((activePageJobInvites - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                        <td>
                                                                                            {jobs.job_title}</td>
                                                                                        <td>{jobs.vacancies}</td>
                                                                                        <td>{jobs.salary_min && jobs.salary_max ? jobs.salary_min + '-' + jobs.salary_max : jobs.salary_min}</td>
                                                                                        <td>{jobs.qualification}</td>
                                                                                        <td>{this.getFormatedDate(jobs.expiring_on)}</td>
                                                                                        <td onClick={() => this.setState({
                                                                                            jobs,
                                                                                            inviteType:'job',
                                                                                            type: 'jobInvitesAccept'
                                                                                        })}>
                                                                        <span data-toggle="modal"
                                                                              data-target="#myModal01"
                                                                              style={{"cursor": "pointer"}}
                                                                              className="date-clr">
                                                                           {this.props.t('dashboard.table.action', {param: 'react'})}
                                                                        </span>
                                                                                        </td>
                                                                                    </tr>)
                                                                                })}

                                                                                {!Mainprops.JobseekerReducer.jobInvites.length &&
                                                                                <tr>
                                                                                    <td colSpan="8"
                                                                                        style={{"textAlign": "center"}}>
                                                                                        <h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                </tr>}

                                                                                </tbody>
                                                                            </table>
                                                                            {jobInvites && jobInvites.length > 1 ?
                                                                                <div className='react-pagination'>
                                                                                    <Pagination
                                                                                        pageRangeDisplayed={10}
                                                                                        activePage={activePageJobInvites}
                                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                                        totalItemsCount={Mainprops.JobseekerReducer.jobInvites.length}
                                                                                        onChange={this.handlePageChangeJobInvites}
                                                                                    />
                                                                                </div> : ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(scheduledCounselling && scheduledCounselling.length > 0) || (appliedCounselling && appliedCounselling.length) ?
                                                            <div className="card border-0">
                                                                <div
                                                                    className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                    role="tab" id="heading12">
                                                                    <a className="collapsed" data-toggle="collapse"
                                                                        data-parent="#accordionNo1" href="#collapse1222"
                                                                        aria-expanded="false" aria-controls="collapse1222">
                                                                        <h6 className="mb-0 white-text font-thin">
                                                                            {this.props.t('homeContent.Counseling', { param: 'react' })}
                                                                            <i
                                                                                className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                        </h6>
                                                                    </a>
                                                                </div><div id="collapse1222" className="collapse sub-content"
                                                                    role="tabpanel" aria-labelledby="heading12"
                                                                    data-parent="#accordionNo1">
                                                                    <div className="accordion md-accordion accordion-2 "
                                                                        id="accordionEx9" role="tablist"
                                                                        aria-multiselectable="true">
                                                                        {scheduledCounselling && scheduledCounselling.length > 0 ?



                                                                            <div className="card border-0">
                                                                                <div className="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading-3">
                                                                                    <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx9" href="#collapse-4"
                                                                                        aria-expanded="false" aria-controls="collapse-4">
                                                                                        <h6 className="mb-0 white-text font-thin">
                                                                                            {this.props.t('dashboard.scheduled_counselling', { param: 'react' })}<i
                                                                                                className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                        </h6>
                                                                                    </a>
                                                                                </div>
                                                                                <div id="collapse-4" className="collapse" role="tabpanel" aria-labelledby="heading-3"
                                                                                    data-parent="#accordionEx9">
                                                                                    <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('counsellingProvider.session_name', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('counsellingProvider.sessionMode', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.action', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('lsp_user.details', { param: 'react' })}</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {scheduledCounselling.length > 0 && scheduledCounselling[activePageCounselling - 1].map((jobs, key) => {
                                                                                                        return (<tr key={key}>
                                                                                                            <td>{((activePageCounselling - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                            <td>{jobs.title ? jobs.title : ''} </td>
                                                                                                            <td>{jobs.mode ? jobs.mode : ''} </td>
                                                                                                            <td><span className="date-clr" data-toggle="modal" onClick={() => {
                                                                                                                this.setState({ session_id: jobs.id, formData: [], error: '' })
                                                                                                                this.props.validator.hideMessages()
                                                                                                            }
                                                                                                            } data-target="#feedbackModal1" style={{ "cursor": "pointer" }}>{this.props.t('feedback.feed', { param: 'react' })}</span></td>
                                                                                                            <td><span data-toggle="modal"
                                                                                                                data-target="#sessionDetails"
                                                                                                                style={{ "cursor": "pointer" }}
                                                                                                                onClick={() => this.setState({ showTime: true, sessionData: jobs })}
                                                                                                                className="date-clr">
                                                                                                                {this.props.t('footer.clickHere', { param: 'react' })}
                                                                                                            </span></td>
                                                                                                        </tr>)
                                                                                                    })}

                                                                                                    {!Mainprops.JobseekerReducer.scheduledCounselling.length && <tr>
                                                                                                        <td colSpan="5" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                    </tr>}

                                                                                                </tbody>
                                                                                            </table>
                                                                                            {scheduledCounselling && scheduledCounselling.length > 1 ?
                                                                                                <div className='react-pagination'>
                                                                                                    <Pagination
                                                                                                        pageRangeDisplayed={10}
                                                                                                        activePage={activePageCounselling}
                                                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                        totalItemsCount={Mainprops.JobseekerReducer.scheduledCounselling.length}
                                                                                                        onChange={this.handlePageChangeCounselling}
                                                                                                    />
                                                                                                </div> : ''}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            : null}
                                                                        {appliedCounselling && appliedCounselling.length > 0 ?


                                                                            <div className="card border-0">
                                                                                <div className="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading-3">
                                                                                    <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx9" href="#collapse-5"
                                                                                        aria-expanded="false" aria-controls="collapse-5">
                                                                                        <h6 className="mb-0 white-text font-thin">
                                                                                            {this.props.t('sidebar.counselling_sessions', { param: 'react' })}<i
                                                                                                className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                        </h6>
                                                                                    </a>
                                                                                </div>
                                                                                <div id="collapse-5" className="collapse" role="tabpanel" aria-labelledby="heading-3"
                                                                                    data-parent="#accordionEx9">
                                                                                    <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('counsellingProvider.session_name', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('counsellingProvider.coun_type', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('counsellingProvider.sessionMode', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.applied_on', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('lsp_user.details', { param: 'react' })}</th>

                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {appliedCounselling.length > 0 && appliedCounselling[activePage - 1].map((jobs, key) => {
                                                                                                        return (<tr key={key}>
                                                                                                            <td>{((activePage - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                            <td>
                                                                                                                {<span>{jobs.title ? jobs.title : ''}</span>}</td>
                                                                                                            <td>{jobs.counselling_type ? jobs.counselling_type : ''}</td>
                                                                                                            <td>{jobs.mode ? jobs.mode : ''}</td>
                                                                                                            <td>{this.getDate(jobs.created_at)}</td>
                                                                                                            <td><span data-toggle="modal"
                                                                                                                data-target="#sessionDetails"
                                                                                                                style={{ "cursor": "pointer" }}
                                                                                                                onClick={() => this.setState({ showTime: false, sessionData: jobs })}
                                                                                                                className="date-clr">
                                                                                                                {this.props.t('footer.clickHere', { param: 'react' })}
                                                                                                            </span></td>

                                                                                                        </tr>)
                                                                                                    })}

                                                                                                    {!Mainprops.JobseekerReducer.appliedPages.length && <tr>
                                                                                                        <td colSpan="6" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                    </tr>}

                                                                                                </tbody>
                                                                                            </table>
                                                                                            {appliedCounselling && appliedCounselling.length > 1 ?
                                                                                                <div className='react-pagination'>
                                                                                                    <Pagination
                                                                                                        pageRangeDisplayed={10}
                                                                                                        activePage={activePage}
                                                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                        totalItemsCount={Mainprops.JobseekerReducer.appliedPages.length}
                                                                                                        onChange={this.handlePageChange}
                                                                                                    />
                                                                                                </div> : ''}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>


                                                                            : null}
                                                                    </div>
                                                                </div>

                                                            </div> : null}
                                                        {/* {(appliedForeignJobs && appliedForeignJobs.length > 0) || (scheduledForeignJobs && scheduledForeignJobs.length > 0) ?
                                                            <div className="card border-0">
                                                                <div
                                                                    className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                    role="tab" id="heading12">
                                                                    <a className="collapsed" data-toggle="collapse"
                                                                        data-parent="#accordionNo1" href="#collapse1223"
                                                                        aria-expanded="false" aria-controls="collapse1223">
                                                                        <h6 className="mb-0 white-text font-thin">
                                                                            {this.props.t('sidebar.forJobs', { param: 'react' })}
                                                                            <i
                                                                                className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                        </h6>
                                                                    </a>
                                                                </div>
                                                                <div id="collapse1223" className="collapse sub-content"
                                                                    role="tabpanel" aria-labelledby="heading12"
                                                                    data-parent="#accordionNo1">
                                                                    <div className="accordion md-accordion accordion-2 "
                                                                        id="accordionEx9" role="tablist"
                                                                        aria-multiselectable="true">
                                                                        <div className="accordion md-accordion accordion-2 "
                                                                            id="accordionEx101" role="tablist"
                                                                            aria-multiselectable="true">
                                                                            {scheduledForeignJobs && scheduledForeignJobs.length > 0 ?

                                                                                <div className="card border-0">
                                                                                    <div className="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading-5">
                                                                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx101" href="#collapse-51"
                                                                                            aria-expanded="false" aria-controls="collapse-51">
                                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                                {this.props.t('dashboard.scheduled_foreign_jobs', { param: 'react' })}<i
                                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                            </h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div id="collapse-51" className="collapse" role="tabpanel" aria-labelledby="heading-3"
                                                                                        data-parent="#accordionEx101">
                                                                                        <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                            <div className="table-responsive">
                                                                                                <table className="table table-striped">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('dashboard.table.job-title', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('govtJobs.age', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('dashboard.table.salary1', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('dashboard.table.vacancies', { param: 'react' })}</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {scheduledForeignJobs && scheduledForeignJobs.length > 0 && scheduledForeignJobs[activePageForgeignJobs - 1].map((jobs, key) => {
                                                                                                            return (<tr key={key}>
                                                                                                                <td>{((activePageForgeignJobs - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                                <td >
                                                                                                                    {jobs.job_title ? jobs.job_title : ''}
                                                                                                                </td>
                                                                                                                <td>{jobs.age_preference ? jobs.age_preference : ''}</td>
                                                                                                                <td>{jobs.salary_min ? jobs.salary_min + "-" + jobs.salary_max : ''} ({`In ${jobs.currency_code}`})</td>
                                                                                                                <td>{jobs.vacancies ? jobs.vacancies : ''}</td>
                                                                                                            </tr>)
                                                                                                        })}

                                                                                                        {!Mainprops.JobseekerReducer.scheduledForeignJobs.length && <tr>
                                                                                                            <td colSpan="5" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                        </tr>}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                                {scheduledForeignJobs && scheduledForeignJobs.length > 1 ?
                                                                                                    <div className='react-pagination'>
                                                                                                        <Pagination
                                                                                                            pageRangeDisplayed={10}
                                                                                                            activePage={activePageForgeignJobs}
                                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                            totalItemsCount={Mainprops.JobseekerReducer.scheduledForeignJobs.length}
                                                                                                            onChange={this.handlePageChangeForgeignJobs}
                                                                                                        />
                                                                                                    </div> : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                            {appliedForeignJobs && appliedForeignJobs.length > 0 ?

                                                                                <div className="card border-0">
                                                                                    <div className="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading-5">
                                                                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx101" href="#collapse-515"
                                                                                            aria-expanded="false" aria-controls="collapse-515">
                                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                                {this.props.t('sidebar.jobsDesc', { param: 'react' })}<i
                                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                            </h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div id="collapse-515" className="collapse" role="tabpanel" aria-labelledby="heading-3"
                                                                                        data-parent="#accordionEx101">
                                                                                        <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                            <div className="table-responsive">
                                                                                                <table className="table table-striped">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('dashboard.table.job-title', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('dashboard.table.salary1', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('dashboard.table.status', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('dashboard.table.applied_on', { param: 'react' })}</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {appliedForeignJobs && appliedForeignJobs.length > 0 && appliedForeignJobs[activePageForgeignJobsInterview - 1].map((jobs, key) => {
                                                                                                            return (<tr key={key}>
                                                                                                                <td>{((activePageForgeignJobsInterview - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                                <td>{jobs.job_title ? jobs.job_title : ''}</td>
                                                                                                                <td>{jobs.salary_min && jobs.salary_max ? jobs.salary_min + '-' + jobs.salary_max : jobs.salary_min} ({`In ${jobs.currency_code}`})</td>
                                                                                                                <td>{this.getJobStatus(jobs.job_status)}</td>
                                                                                                                <td>{this.getDate(jobs.applied_on)}</td>
                                                                                                            </tr>)
                                                                                                        })}

                                                                                                        {!Mainprops.JobseekerReducer.appliedForeignJobs.length && <tr>
                                                                                                            <td colSpan="5" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                        </tr>}

                                                                                                    </tbody>
                                                                                                </table>
                                                                                                {appliedForeignJobs && appliedForeignJobs.length > 1 ?
                                                                                                    <div className='react-pagination'>
                                                                                                        <Pagination
                                                                                                            pageRangeDisplayed={10}
                                                                                                            activePage={activePageForgeignJobsInterview}
                                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                            totalItemsCount={Mainprops.JobseekerReducer.appliedForeignJobs.length}
                                                                                                            onChange={this.handlePageChangeForgeignJobsInterview}
                                                                                                        />
                                                                                                    </div> : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                    </div>                                          </div>
                                                            </div> : null} */}

                                                        {/* <div className="card border-0" id="self-emp">
                                                            <div
                                                                className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                role="tab" id="heading12">
                                                                <a className="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionNo1" href="#collapse1225"
                                                                    aria-expanded="false" aria-controls="collapse1225">
                                                                    <h6 className="mb-0 white-text font-thin">
                                                                        {this.props.t('homeContent.selfEmp', { param: 'react' })}<i
                                                                            className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                    </h6>
                                                                </a>
                                                            </div>

                                                            <div id="collapse1225" className="collapse sub-content"
                                                                role="tabpanel" aria-labelledby="heading12"
                                                                data-parent="#accordionNo1">
                                                                <div className="accordion md-accordion accordion-2 "
                                                                    id="accordionEx9" role="tablist"
                                                                    aria-multiselectable="true">
                                                                    <div className="accordion md-accordion accordion-2 "
                                                                        id="accordionEx10" role="tablist"
                                                                        aria-multiselectable="true">
                                                                        {scheduledSelfEmployment && scheduledSelfEmployment.length > 0 ?

                                                                            <div className="card border-0">
                                                                                <div className="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading-7">
                                                                                    <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx10" href="#collapse-71"
                                                                                        aria-expanded="false" aria-controls="collapse-7">
                                                                                        <h6 className="mb-0 white-text font-thin">
                                                                                            {this.props.t('sidebar.appliedEmp', { param: 'react' })}<i
                                                                                                className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                        </h6>
                                                                                    </a>
                                                                                </div>
                                                                                <div id="collapse-71" className="collapse" role="tabpanel" aria-labelledby="heading-3"
                                                                                    data-parent="#accordionEx10">
                                                                                    <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('selfEmployment.name', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.contact_person', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('JobPost.contact_phone', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('JobPost.contactPersonEmail', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('profileDetails.communication', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('dashboard.table.status', { param: 'react' })}</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {scheduledSelfEmployment.length > 0 && scheduledSelfEmployment[activePagehandleEmployment - 1].map((jobs, key) => {
                                                                                                        return (<tr key={key}>
                                                                                                            <td>{((activePagehandleEmployment - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                            <td>{jobs.scheme_name}</td>
                                                                                                            <td>{jobs.contact_person}</td>
                                                                                                            <td>{jobs.mobile}</td>
                                                                                                            <td>{jobs.email}</td>
                                                                                                            <td>{jobs.nodal_officer}</td>
                                                                                                            <td>{jobs.dbees_status}</td>
                                                                                                        </tr>)
                                                                                                    })}

                                                                                                    {!Mainprops.JobseekerReducer.scheduledSelfEmployment.length && <tr>
                                                                                                        <td colSpan="6" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                    </tr>}

                                                                                                </tbody>
                                                                                            </table>
                                                                                            {scheduledSelfEmployment && scheduledSelfEmployment.length > 1 ?
                                                                                                <div className='react-pagination'>
                                                                                                    <Pagination
                                                                                                        pageRangeDisplayed={10}
                                                                                                        activePage={activePagehandleEmployment}
                                                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                        totalItemsCount={Mainprops.JobseekerReducer.scheduledSelfEmployment.length}
                                                                                                        onChange={this.handlePageChangeEmployment}
                                                                                                    />
                                                                                                </div> : ''}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                        <div className="card border-0 m-2">
                                                                            <label><input type="checkbox"
                                                                                checked={Mainprops.JobseekerReducer.interest === 1}
                                                                                onChange={this.changeInterest}
                                                                                disabled={Mainprops.JobseekerReducer.interest === 1}
                                                                            /> <b>{this.props.t('selfEmployment.interested', { param: 'react' })}</b></label>
                                                                        </div>

                                                                        {relevantSelfEmp && relevantSelfEmp.length > 0 && (Mainprops.JobseekerReducer.interest || Mainprops.JobseekerReducer.scheduledSelfEmployment.length) ?
                                                                            <div className="card border-0">
                                                                                <div className="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading-7">
                                                                                    <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx10" href="#collapse-7109"
                                                                                        aria-expanded="false" aria-controls="collapse-7">
                                                                                        <h6 className="mb-0 white-text font-thin">
                                                                                            {this.props.t('sidebar.rel_self', { param: 'react' })}<i
                                                                                                className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                        </h6>
                                                                                    </a>
                                                                                </div>
                                                                                <div id="collapse-7109" className="collapse" role="tabpanel" aria-labelledby="heading-3"
                                                                                    data-parent="#accordionEx10">
                                                                                    <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('selfEmployment.name', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('selfEmployment.Loan Amount (In INR)', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('selfEmployment.Margin Money (In %)', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('selfEmployment.Subsidy Component (In %)', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('selfEmployment.Whether collateral free loan', { param: 'react' })}</th>
                                                                                                        <th>{this.props.t('jobSearch.view_desc', { param: 'react' })}</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {relevantSelfEmp.length > 0 && relevantSelfEmp[activeSelfEmp - 1].map((jobs, key) => {
                                                                                                        return (<tr key={key}>
                                                                                                            <td>{((activePagehandleEmployment - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                            <td>{jobs.scheme_name}</td>
                                                                                                            <td>{jobs.loan_amount_min + "-" + jobs.loan_amount_max}</td>
                                                                                                            <td>{jobs.margin_min + " - " + jobs.margin_max}</td>
                                                                                                            <td>{jobs.subsidy}</td>
                                                                                                            <td>{jobs.collateral_free === '1' ? "Yes" : "NO"}</td>
                                                                                                            <td>
                                                                                                                <Link data-target="#descriptionModal1"
                                                                                                                    onClick={() => this.setState({ selfEmpData: jobs })}
                                                                                                                    data-toggle="modal" style={{ "cursor": "pointer" }} className="date-clr">
                                                                                                                    {this.props.t('homeContent.clickHere', { param: 'react' })}
                                                                                                                </Link>
                                                                                                            </td>
                                                                                                        </tr>)
                                                                                                    })}

                                                                                                    {!Mainprops.JobseekerReducer.relevantSelfEmp.length && <tr>
                                                                                                        <td colSpan="7" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                    </tr>}

                                                                                                </tbody>
                                                                                            </table>
                                                                                            {relevantSelfEmp && relevantSelfEmp.length > 1 ?
                                                                                                <div className='react-pagination'>
                                                                                                    <Pagination
                                                                                                        pageRangeDisplayed={10}
                                                                                                        activePage={activeSelfEmp}
                                                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                        totalItemsCount={Mainprops.JobseekerReducer.relevantSelfEmp.length}
                                                                                                        onChange={this.handleChangeRelEmployment}
                                                                                                    />
                                                                                                </div> : ''}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : null}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {
                                                            (appliedServices && appliedServices.length > 0) || (schduledServices && schduledServices.length > 0) ?
                                                                <div className="card border-0">
                                                                    <div
                                                                        className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                        role="tab" id="heading12">
                                                                        <a className="collapsed" data-toggle="collapse"
                                                                            data-parent="#accordionNo1" href="#collapse0101"
                                                                            aria-expanded="false" aria-controls="collapse0101">
                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                {this.props.t('homeContent.localServices', { param: 'react' })}<i
                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                            </h6>
                                                                        </a>
                                                                    </div>
                                                                    <div id="collapse0101" className="collapse sub-content"
                                                                        role="tabpanel" aria-labelledby="heading12"
                                                                        data-parent="#accordionNo1">
                                                                        <div className="accordion md-accordion accordion-2 "
                                                                            id="accordionEx91001" role="tablist"
                                                                            aria-multiselectable="true">
                                                                            {appliedServices && appliedServices.length > 0 ?

                                                                                <div className="card border-0">
                                                                                    <div className="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading-61">
                                                                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx91001" href="#collapse-611"
                                                                                            aria-expanded="false" aria-controls="collapse-6">
                                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                                {this.props.t('lsp_user.applied_services', { param: 'react' })}<i
                                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                            </h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div id="collapse-611" className="collapse" role="tabpanel" aria-labelledby="heading-3"
                                                                                        data-parent="#accordionEx91001">
                                                                                        <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                            <div className="table-responsive">
                                                                                                <table className="table table-striped">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('lsp_user.table.servicename', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('experience.Orgnisation Name', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('lsp_user.price_tag', { param: 'react' })}</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {appliedServices.length > 0 && appliedServices[activePageServices - 1].map((services, key) => {
                                                                                                            return (<tr key={key}>
                                                                                                                <td>{((activePageServices - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                                <td>{services.service ? services.service : ''}</td>
                                                                                                                <td>{services.org_name ? services.org_name : ''}</td>
                                                                                                                <td>{services.price_tag ? <a href={SERVER_PATH + services.price_tag}
                                                                                                                    target="_blank"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    className="date-clr">{this.props.t('homeContent.clickHere', { param: 'react' })}</a> : ''}</td>
                                                                                                            </tr>)
                                                                                                        })}

                                                                                                        {!Mainprops.JobseekerReducer.appliedServices.length && <tr>
                                                                                                            <td colSpan="5" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                        </tr>}

                                                                                                    </tbody>
                                                                                                </table>
                                                                                                {appliedServices && appliedServices.length > 1 ?
                                                                                                    <div className='react-pagination'>
                                                                                                        <Pagination
                                                                                                            pageRangeDisplayed={10}
                                                                                                            activePage={activePageServices}
                                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                            totalItemsCount={Mainprops.JobseekerReducer.appliedServices.length}
                                                                                                            onChange={this.handlePageChangeServices}
                                                                                                        />
                                                                                                    </div>
                                                                                                    : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                            {schduledServices && schduledServices.length > 0 ?

                                                                                <div className="card border-0">
                                                                                    <div className="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading-61">
                                                                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx91001" href="#collapse-61101"
                                                                                            aria-expanded="false" aria-controls="collapse-6">
                                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                                {this.props.t('lsp_user.schduled_services', { param: 'react' })}<i
                                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                                            </h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div id="collapse-61101" className="collapse" role="tabpanel" aria-labelledby="heading-3"
                                                                                        data-parent="#accordionEx91001">
                                                                                        <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                            <div className="table-responsive">
                                                                                                <table className="table table-striped">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('lsp_user.table.servicename', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('experience.Orgnisation Name', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('lsp_user.visit_date', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('lsp_user.visit_time', { param: 'react' })}</th>
                                                                                                            <th>{this.props.t('lsp_user.price_tag', { param: 'react' })}</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {schduledServices && schduledServices.length > 0 && schduledServices[activePageSchduledServices - 1].map((services, key) => {
                                                                                                            return (<tr key={key}>
                                                                                                                <td>{((activePageSchduledServices - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                                <td>{services.service ? services.service : ''}</td>
                                                                                                                <td>{services.org_name ? services.org_name : ''}</td>
                                                                                                                <td>{services.service_date ? services.service_date : ''}</td>
                                                                                                                <td>{services.service_time ? getTimeWithAmPm(services.service_time) : ''}</td>
                                                                                                                <td>{services.price_tag ? <a href={SERVER_PATH + services.price_tag}
                                                                                                                    target="_blank"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    className="date-clr">
                                                                                                                    {this.props.t('homeContent.clickHere', { param: 'react' })}</a> : ''}</td>
                                                                                                            </tr>)
                                                                                                        })}

                                                                                                        {!Mainprops.JobseekerReducer.schduledServices.length && <tr>
                                                                                                            <td colSpan="6" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                        </tr>}

                                                                                                    </tbody>
                                                                                                </table>
                                                                                                {schduledServices && schduledServices.length > 1 ?
                                                                                                    <div className='react-pagination'>
                                                                                                        <Pagination
                                                                                                            pageRangeDisplayed={10}
                                                                                                            activePage={activePageSchduledServices}
                                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                            totalItemsCount={Mainprops.JobseekerReducer.schduledServices.length}
                                                                                                            onChange={this.handlePageChangeSchduledServices}
                                                                                                        />
                                                                                                    </div>
                                                                                                    : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }


                                                        {/* {
                                                            careerOpt && careerOpt.length > 0 ?
                                                                <div className="card border-0">
                                                                    <div
                                                                        className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                        role="tab" id="heading12">
                                                                        <a className="collapsed" data-toggle="collapse"
                                                                            data-parent="#accordionNo1" href="#collapse0011"
                                                                            aria-expanded="false" aria-controls="collapse0011">
                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                {this.props.t('sidebar.careerOpportunities', { param: 'react' })}<i
                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                            </h6>
                                                                        </a>
                                                                    </div>
                                                                    <div id="collapse0011" className="collapse" role="tabpanel" aria-labelledby="heading-3"
                                                                        data-parent="#accordionEx320">
                                                                        <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('selfEmployment.sector', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('selfEmployment.sector_profile', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.education', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('selfEmployment.Qualification', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('counsellingProvider.details', { param: 'react' })}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {careerOpt.length > 0 && careerOpt[activePageCareer - 1].map((jobs, key) => {
                                                                                            return (<tr key={key}>
                                                                                                <td>{((activePageCareer - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                <td>{jobs.sector ? jobs.sector : ''}</td>
                                                                                                <td>{jobs.sector_profile ? jobs.sector_profile : ''}</td>
                                                                                                <td>{jobs.education ? jobs.education : ''}</td>
                                                                                                <td>{jobs.display_qualification ? jobs.display_qualification : ''}</td>
                                                                                                <td><Link
                                                                                                    onClick={() => this.setState({ content: jobs })}
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#careerModal"
                                                                                                    style={{ "cursor": "pointer" }} className="date-clr pull-right" >
                                                                                                    {this.props.t('homeContent.clickHere', { param: 'react' })}
                                                                                                </Link></td>
                                                                                            </tr>)
                                                                                        })}

                                                                                        {!Mainprops.JobseekerReducer.careerOpt.length && <tr>
                                                                                            <td colSpan="7" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                        </tr>}

                                                                                    </tbody>
                                                                                </table>
                                                                                {careerOpt && careerOpt.length > 1 ?
                                                                                    <div className='react-pagination'>
                                                                                        <Pagination
                                                                                            pageRangeDisplayed={10}
                                                                                            activePage={activePageCareer}
                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                            totalItemsCount={Mainprops.JobseekerReducer.careerOpt.length}
                                                                                            onChange={this.handlePageChangeCareer}
                                                                                        />
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        } */}
                                                        {/* <div className="card border-0">
                                                            <div
                                                                className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                role="tab" id="heading12">
                                                                <a className="collapsed" data-toggle="collapse"
                                                                    data-parent="#accordionNo1" href="#collapse12255"
                                                                    aria-expanded="false" aria-controls="collapse12255">
                                                                    <h6 className="mb-0 white-text font-thin">
                                                                        {this.props.t('homeContent.skill_development_title', { param: 'react' })}<i
                                                                            className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                    </h6>
                                                                </a>
                                                            </div>
                                                            <div id="collapse12255" className="collapse sub-content"
                                                                role="tabpanel" aria-labelledby="heading12"
                                                                data-parent="#accordionNo1">
                                                                <div className="accordion md-accordion accordion-2 "
                                                                    id="accordionEx320" role="tablist"
                                                                    aria-multiselectable="true"></div>
                                                                <div className="card border-0 m-2">
                                                                    <label><input type="checkbox"
                                                                        checked={Mainprops.JobseekerReducer.training_interest === 1}
                                                                        onChange={this.saveTrainingInterest}
                                                                        disabled={Mainprops.JobseekerReducer.training_interest === 1}
                                                                    /> <b>{this.props.t('homeContent.skill_development', { param: 'react' })}</b></label>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    console.log('store', store)
    return store;
};

export default connect(mapStateToProps)(translate(Validation(MyAccount)));

