import React, { Component } from 'react';
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import {
    getScheduleVisits
} from '../../../actions/lspAction'
import moment from 'moment'
import { getTimeWithAmPm } from '../../../helpers'
import { Link } from 'react-router'

class VisitCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formDataPass: [],
            activePage: 1,
            Profile: [],
            job: [],
            submit: false,
            selectedCheckboxes: new Set(),
            candidatesArray: [],
            eventData: [],
            show: false
        }
        store.dispatch(getScheduleVisits())
    }

    componentDidMount() {
        document.title = this.props.t('lsp_user.calender', { param: 'react' })
    }

    getAllSession = () => {
        const sessions = []
        this.props.LspReducer.scheduleVisits &&
            this.props.LspReducer.scheduleVisits.length > 0 &&
            this.props.LspReducer.scheduleVisits.forEach((e) => {
                let date = e.service_date.split("-")
                date = new Date(`${date[2]},${date[1]}, ${date[0]}`)
                sessions.push({
                    id: e.service_id,
                    title: e.service,
                    mobile: e.mobile,
                    customer_name: e.customer_name,
                    start: date,
                    end: date,
                    date: e.service_date,
                    time: e.service_time,
                    address: e.customer_address
                })

            })
        return sessions
    }

    showModal = (event) => {
        this.setState({ show: true, eventData: event })
        document.body.classList.add('modal-open');
    }

    render() {
        const localizer = momentLocalizer(moment)
        const sessions = this.getAllSession()
        const Mainprops = this.props
        const { show, eventData } = this.state
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}
                        <main className="col-md-9 second-side p-0 pb-3 h-100">
                            {show &&
                                <div id="modal" className="">
                                    <div className="overly">
                                        <div className="dialog-desc" style={{ width: "20%" }}>
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('counsellingProvider.details', { param: 'react' })}</h5>
                                                    <button type="button" className="close" onClick={() => {
                                                        document.body.classList.remove('modal-open')
                                                        this.setState({ show: false })
                                                    }}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>      </div>
                                                <div className="modal-body">
                                                    <p>{this.props.t('lsp_user.customer_name', { param: 'react' })}: {eventData.customer_name}</p>
                                                    <p>{this.props.t('lsp_user.table.servicename', { param: 'react' })}: {eventData.title}</p>
                                                    <p>{this.props.t('lsp_user.visit_date', { param: 'react' })}: {eventData.date}</p>
                                                    <p>{this.props.t('lsp_user.visit_time', { param: 'react' })}: {getTimeWithAmPm(eventData.time)}</p>
                                                    <p>{this.props.t('profileDetails.communication', { param: 'react' })}: {eventData.address}</p>
                                                    <p>{this.props.t('dashboard.table.mobile', { param: 'react' })}: {eventData.mobile}</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-primary btn-primary2" onClick={() => this.setState({ show: false })}>{this.props.t('counsellingProvider.close', { param: 'react' })}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            <div className="clearfix"></div>
                            <div id="home" className=" tab-pane active">
                                <section className="job-details mt-4">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12  mx-auto  tab-content  p-0">
                                                <div className="panel-heading">
                                                    <h6 className="mb-0">{this.props.t('lsp_user.calender', { param: 'react' })}</h6>
                                                </div>
                                                <div className="panel-body">
                                                    <div className="col-md-12 col-sm-12 col-lg-12 right-sidebar-scroll">
                                                        <Calendar
                                                            localizer={localizer}
                                                            events={sessions}
                                                            onSelectEvent={(event) => this.showModal(event)}
                                                            startAccessor="start"
                                                            className="counsellor-calendar"
                                                            endAccessor="end"
                                                            style={{ "height": "500", "width": "100%" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(VisitCalendar));
