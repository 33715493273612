import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Sidebar from './Sidebar';
import store from '../../../store';
import {
    getPostArchive
    , getJobsAppliedBySeeker,
    getScheduledJobs,get_delete_Jobs
} from '../../../actions/employerAction'
import { get_User_District, getEducationLevel } from '../../../actions/accountAction';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router'
import Pagination from "react-js-pagination";
import { chunk } from '../../../helpers'

const RESULT_PER_PAGE = 25

class ApplyJob extends Component {

    constructor(props) {
        super(props);
        this.state = {
            jobs: '',
            type: '',
            activePagePostArchive: 1,
            activePagePostArchive1: 1,
            recentJobsActivePage: 1,
            activePageAppliedInterviews: 1
        }
        store.dispatch(getScheduledJobs());
        store.dispatch(getPostArchive());
        store.dispatch(getJobsAppliedBySeeker());
        store.dispatch(get_User_District());
        store.dispatch(getEducationLevel());
    }

    componentDidMount() {
        document.title = this.props.t('header.my_account', { param: 'react' });
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => {
            this.validateField(name, value)
        });
    }

    getQualification = (job) => {
        const qualification = []
        if (job.qualification1) {
            qualification.push(job.qualification1)
        }
        if (job.qualification2) {
            qualification.push(job.qualification2)
        }
        if (job.qualification3) {
            qualification.push(job.qualification3)
        }
        return qualification.length ? qualification.toString() : ''

    }

    getExperience = (jobData) => {
        if (jobData.min_exp && jobData.max_exp) {
            return `${jobData.min_exp} - ${jobData.max_exp} years`
        } else {
            return "Fresher"
        }
    }

    handlePageChangePostArchive = (activePage) => {
        this.setState({ activePagePostArchive: activePage })
    }
    handlePageChangePostArchive1 = (activePage) => {
        this.setState({ activePagePostArchive1: activePage })
    }
    handlePageChangeRecentJobs = (activePage) => {
        this.setState({ recentJobsActivePage: activePage })
    }
    handlePageChangeAppliedInterviews = (activePage) => {
        this.setState({ activePageAppliedInterviews: activePage })
    }

    getInterviewDate = (date) => {
        const dates = []
        if (date) {
            const newDatesArray = JSON.parse(date)
            newDatesArray.forEach((d) => {
                const parts = d.split('-')
                dates.push(`${parts[2]}-${parts[1]}-${parts[0]}`)
            })
            return dates && dates.length ? dates.toString() : ''
            //     // return Location && Location.length > 1 ? JSON.parse(location).toString() : Location[0]
        }
        // return ''
    }


    selectApplied = async (e, jobs) => {
        // const {id, user_id} = jobs
        // if (id && user_id && e.target.value) {
        //     await store.dispatch(selectAppliedApplicant({job_id: id, user_id, action: e.target.value}));
        //     await store.dispatch(getAppliedInterviews());
        // }
    }

    repost = (jobs) => {
        // console.log('jobs', jobs)
        // store.dispatch(postJob(jobs))
    }

    getLocation = (location) => {
        if (location) {
            const Location = JSON.parse(location)
            return Location && Location.length > 1 ? JSON.parse(location).toString() : Location[0]
        }
        return ''
    }

    getLocationFromIds = (job) => {

        const names = []
        if (typeof job.location !== 'undefined') {
            if (job.location && job.location.length && this.props.actionReducer.District && this.props.actionReducer.District.length > 0) {
                this.props.actionReducer.District.forEach((data) => {
                    if (data.state_id === "3") {
                        JSON.parse(job.location).forEach(element => {
                            let filterVal = data.dist.filter((district) => district.id === element)
                            if (filterVal && filterVal[0]) {
                                names.push(filterVal[0].name)
                            }
                        });
                    }
                })
            }
            return names.length ? names.toString() : ''
        }


    }

    getPlaceOfPosting = (job) => {
        let result = ''
        if (job.location !== "") {
            result = this.getLocationFromIds(job)
        }
        if (job.state_id !== "" && !job.location) {
            result = this.getStates(job)
        }
        return result
    }

    getStates = (job) => {
        const names = []
        if (typeof job.state_id !== "undefined") {
            const stateArray = JSON.parse(job.state_id)
            if (stateArray && stateArray.length && this.props.actionReducer.District && this.props.actionReducer.District.length > 0) {
                stateArray.forEach((state) => {
                    let filterVal = this.props.actionReducer.District.filter((district) => district.state_id === state)
                    if (filterVal && filterVal[0]) {
                        names.push(filterVal[0].state_name)
                    }
                })

            }

        }
        return names.length ? names.toString() : ''
    }
    deleteRecord = () => {
        const { delIndex, delOption } = this.state
        if(delOption === 'edu') {
            this.handleDeleteEducation(delIndex)
        }
        document.getElementById("exampleModal").click()
    }
    handleDeleteEducation(index) {
        let data = {};
        data.id = this.props.EmployerReducer.postArchive[index].job_id;
        data.auth_key = localStorage.getItem('token');
        console.log(data);
        store.dispatch(get_delete_Jobs(data));
        this.setState({'Education': []});
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        // const {jobs, activePagePostArchive, activePagePostArchive1, recentJobsActivePage, activePageAppliedInterviews} = this.state
        
        const { jobs, activePagePostArchive, activePagePostArchive1, activePageAppliedInterviews } = this.state
        const postArchive = chunk(this.props.EmployerReducer.postArchive, RESULT_PER_PAGE)
        const appliedInterviews = chunk(this.props.EmployerReducer.appliedInterviews, RESULT_PER_PAGE)
        const schduledJobs = chunk(this.props.EmployerReducer.schduledJobs, RESULT_PER_PAGE)
     //   console.log(postArchive);
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        let Mainprops = this.props;

        let qualification = ''
        if (jobs) {
            qualification = this.getQualification(jobs)
        }




        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                        <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel">Are sure you want to Un-Publish job?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                {/* <div class="modal-body">
                                    Are sure you want to delete?
                                </div> */}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary btn-primary2" onClick={() => this.deleteRecord()}>Ok</button>
                                    <button type="button" className="btn btn-primary btn-primary2" data-dismiss="modal">Cancel</button>
                                </div>
                                </div>
                            </div>
                        </div>
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div className="modal top-header2 show" id="myModal001">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title"><Link>{jobs.job_title}</Link></h4>
                                                <button type="button" className="close"
                                                    data-dismiss="modal">&times;</button>
                                            </div>


                                            <div className="modal-body">
                                                <div className="bg-white mb-3 p-3">

                                                    <div className="row">
                                                        <div className="col md-6 col-sm-12">
                                                            <h5 className="date-clr mb-2 font-18">{this.props.t('header.job_details', { param: 'react' })}</h5> <br />
                                                            <table className="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="date-clr">{this.props.t('dashboard.table.employer', { param: 'react' })}:
                                                                    </td>
                                                                        <td><span
                                                                            className="text-span"> {jobs.org_name ? " " + jobs.org_name : ''}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="date-clr">{this.props.t('dashboard.table.job-title', { param: 'react' })}:</td>
                                                                        <td><span
                                                                            className="text-span"> {jobs.job_title ? " " + jobs.job_title : ''}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="date-clr">{this.props.t('profileDetails.experience', { param: 'react' })}:</td>
                                                                        <td><span
                                                                            className="text-span"> {this.getExperience(jobs)}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="date-clr">{this.props.t('dashboard.table.salary1', { param: 'react' })}:</td>
                                                                        <td><span
                                                                            className="text-span"> {jobs.salary_min ? " " + jobs.salary_min : ''}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="date-clr">{this.props.t('JobPost.edu', { param: 'react' })}:</td>
                                                                        <td><span
                                                                            className="text-span"> {jobs.education ? " " + jobs.education : ''}</span>
                                                                        </td>
                                                                    </tr>
                                                                    {qualification ?
                                                                        <tr>
                                                                            <td className="date-clr">{this.props.t('jobSearch.qual', { param: 'react' })}:</td>
                                                                            <td><span
                                                                                className="text-span"> {qualification}</span>
                                                                            </td>
                                                                        </tr> : null}
                                                                    <tr>
                                                                        <td><span className="text-span">  </span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="date-clr">{this.props.t('JobPost.posting', { param: 'react' })}:
                                                                    </td>
                                                                        <td><span
                                                                            className="text-span">  {" " + this.getPlaceOfPosting(jobs)}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className="date-clr mb-2 p-4 font-20"><Link>{this.props.t('dashboard.table.description', { param: 'react' })}</Link></h5> <hr />
                                            <div
                                                className="desc-modal p-4 mb-2 font-15">{jobs.description ? ReactHtmlParser(jobs.description) : ''}</div>



                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primart btn-primary2"
                                                    data-dismiss="modal">Close
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <div className={"row"}>
                                                            <div className={"col-lg-6"}>
                                                                <h6 className="mb-0">{this.props.t('dashboard.title', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className={"col-md-6"}>
                                                                <h6 className="mb-0 text-underline">
                                                                    {/* <a className={"pull-right text-dark"}
                                                                        data-toggle="collapse" href="#my-panel2"
                                                                    >{this.props.t('modal.dash_inst', { param: 'react' })}</a> */}
                                                                </h6>
                                                            </div>
                                                            {/* <div id="my-panel2" className="collapse my-drop">
                                                                <div className="row d-flex">
                                                                    <div className="col-md-12 col-lg-12 col-sm-12 d-flex ">
                                                                        <div className="">

                                                                            <h5>{this.props.t('how_to_use.instruction', { param: 'react' })}</h5>
                                                                            <ul className="jobseeker-benefits"
                                                                            >
                                                                                <li>{this.props.t('how_to_use.changePassword', { param: 'react' })} {" "}

                                                                                </li>
                                                                                <li>{this.props.t('how_to_use.providerProfile', { param: 'react' })}</li>
                                                                                <li>{this.props.t('how_to_use.providerVacancy', { param: 'react' })}</li>
                                                                                <li>{this.props.t('how_to_use.providerSearch', { param: 'react' })}</li>
                                                                                <li>{this.props.t('how_to_use.providerApplied', { param: 'react' })}</li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>

                                                    </div>
                                                    <div className="panel-body">
                                                        <form>
                                                            <div className="accordion md-accordion accordion-2 "
                                                                id="accordionEx7" role="tablist"
                                                                aria-multiselectable="true">

                                                                <div className="card border-0">

                                                                    <div
                                                                        className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                        role="tab" id="heading1">
                                                                        <a data-toggle="collapse"
                                                                            data-parent="#accordionEx7"
                                                                            href="#collapse411" aria-expanded="true"
                                                                            aria-controls="collapse1">
                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                {this.props.t('jobProviderProfile.interested', { param: 'react' })}<i
                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                            </h6>
                                                                        </a>
                                                                    </div>

                                                                    <div id="collapse411" className="collapse "
                                                                        role="tabpanel" aria-labelledby="heading1"
                                                                        data-parent="#accordionEx7">
                                                                        <div
                                                                            className="card-body mb-1 p-2 rgba-grey-light white-text">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('JobPost.job_title', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.date_of_interview', { param: 'react' })}</th>
                                                                                            {/* <th>{this.props.t('dashboard.table.salary', {param: 'react'})}</th> */}
                                                                                            <th>{this.props.t('dashboard.table.venue', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('jobSearch.vacancies', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('homeContent.postedOn', { param: 'react' })}</th>

                                                                                            <th>{this.props.t('counsellingProvider.shortlisted', { param: 'react' })}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {appliedInterviews && appliedInterviews.length > 0 && appliedInterviews[activePageAppliedInterviews - 1].map((jobs, key) => {
                                                                                            return (<tr key={key}>
                                                                                                <td>{((activePageAppliedInterviews - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                <td onClick={() => this.setState({ jobs })}>
                                                                                                    <span
                                                                                                        data-toggle="modal"
                                                                                                        style={{ "cursor": "pointer" }}
                                                                                                        data-target="#myModal001"
                                                                                                        className="date-clr">{jobs.job_title ? jobs.job_title : ''}</span>
                                                                                                </td>
                                                                                                <td>{jobs.interview_date ? this.getInterviewDate(jobs.interview_date) : ''}</td>
                                                                                                {/* <td>{jobs.salary_min+"-"+jobs.salary_max}</td> */}


                                                                                                <td>{jobs.interview_venue ? jobs.interview_venue : ''}</td>
                                                                                                <td>{jobs.vacancies ? jobs.vacancies : ''}</td>
                                                                                                <td>{jobs.created_at ? jobs.created_at : ''}</td>
                                                                                                <td >
                                                                                                    {/* <a
                                                                                                        href={`applied-candidates/${jobs.id}`} className="date-clr">{this.props.t('homeContent.clickHere', { param: 'react' })}</a> */}
                                                                                                    <Link
                                                                                                        to={`applied-candidates/${jobs.id}`}
                                                                                                        className="date-clr"
                                                                                                    >{this.props.t('homeContent.clickHere', { param: 'react' })}</Link>
                                                                                                </td>
                                                                                            </tr>)
                                                                                        })}
                                                                                        {!this.props.EmployerReducer.appliedInterviews.length &&
                                                                                            <tr>
                                                                                                <td colSpan="6"
                                                                                                    style={{ "textAlign": "center" }}>
                                                                                                    <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>
                                                                                                </td>
                                                                                            </tr>}
                                                                                    </tbody>
                                                                                </table>
                                                                                {appliedInterviews && appliedInterviews.length > 1 ?
                                                                                    <div className='react-pagination'>
                                                                                        <Pagination
                                                                                            pageRangeDisplayed={10}
                                                                                            activePage={activePageAppliedInterviews}
                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                            totalItemsCount={this.props.EmployerReducer.appliedInterviews.length}
                                                                                            onChange={this.handlePageChangeAppliedInterviews}
                                                                                        />
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="card border-0">

                                                                    <div
                                                                        className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                        role="tab" id="heading1">
                                                                        <a data-toggle="collapse"
                                                                            data-parent="#accordionEx7"
                                                                            href="#collapse41" aria-expanded="true"
                                                                            aria-controls="collapse1">
                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                {this.props.t('dashboard.interviews', { param: 'react' })}<i
                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>

                                                                            </h6>
                                                                        </a>
                                                                    </div>

                                                                    <div id="collapse41" className="collapse "
                                                                        role="tabpanel" aria-labelledby="heading1"
                                                                        data-parent="#accordionEx7">
                                                                        <div
                                                                            className="card-body mb-1 p-2 rgba-grey-light white-text">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('JobPost.job_title', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.date_of_interview', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.venue', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.vacancies', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('JobPost.shortlisted', { param: 'react' })}</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {schduledJobs && schduledJobs.length > 0 && schduledJobs[activePagePostArchive - 1].map((jobs, key) => {
                                                                                            return (<tr key={key}>
                                                                                                <td>{((activePagePostArchive - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                <td onClick={() => this.setState({ jobs })}>
                                                                                                    <span
                                                                                                        data-toggle="modal"
                                                                                                        style={{ "cursor": "pointer" }}
                                                                                                        data-target="#myModal001"
                                                                                                        className="date-clr">{jobs.job_title ? jobs.job_title : ''}</span>
                                                                                                </td>
                                                                                                <td>{jobs.interview_date ? this.getInterviewDate(jobs.interview_date) : ''}</td>


                                                                                                <td>{jobs.interview_venue ? jobs.interview_venue : ''}</td>
                                                                                                <td>{jobs.vacancies ? jobs.vacancies : ''}</td>
                                                                                                <td >
                                                                                                    <Link
                                                                                                        to={`shortlisted-candidates/${jobs.id}`}
                                                                                                        className="date-clr"
                                                                                                    >{this.props.t('homeContent.clickHere', { param: 'react' })}</Link>
                                                                                                </td>

                                                                                                {/* <td onClick={() => this.props.router.push(`/job-applicants/invited/${jobs.job_id}`)}>
                                                                                                <span
                                                                                                    className="date-clr"
                                                                                                    style={{"cursor": "pointer"}}>Invited</span>
                                                                                            </td> */}
                                                                                                {/* <td onClick={() => this.props.router.push(`/job-applicants/selected/${jobs.job_id}`)}>
                                                                                                <span
                                                                                                    className="date-clr"
                                                                                                    style={{"cursor": "pointer"}}>Selected</span>
                                                                                            </td> */}

                                                                                            </tr>)
                                                                                        })}
                                                                                        {!this.props.EmployerReducer.schduledJobs.length &&
                                                                                            <tr>
                                                                                                <td colSpan="8"
                                                                                                    style={{ "textAlign": "center" }}>
                                                                                                    <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>
                                                                                                </td>
                                                                                            </tr>}
                                                                                    </tbody>
                                                                                </table>
                                                                                {schduledJobs && schduledJobs.length > 1 ?
                                                                                    <div className='react-pagination'>
                                                                                        <Pagination
                                                                                            pageRangeDisplayed={10}
                                                                                            activePage={activePagePostArchive}
                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                            totalItemsCount={Mainprops.EmployerReducer.schduledJobs.length}
                                                                                            onChange={this.handlePageChangePostArchive}
                                                                                        />
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="card border-0">

                                                                    <div
                                                                        className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                        role="tab" id="heading4">
                                                                        <a data-toggle="collapse"
                                                                            data-parent="#accordionEx7" href="#collapse5"
                                                                            aria-expanded="true"
                                                                            aria-controls="collapse5">
                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                {this.props.t('dashboard.posting_archive', { param: 'react' })}
                                                                                <i className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                            </h6>
                                                                        </a>
                                                                    </div>

                                                                    <div id="collapse5" className="collapse"
                                                                        role="tabpanel" aria-labelledby="heading4"
                                                                        data-parent="#accordionEx7">
                                                                        <div
                                                                            className="card-body mb-1 p-2 rgba-grey-light white-text">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.job-title', { param: 'react' })}</th>

                                                                                            <th>{this.props.t('dashboard.table.date_of_interview', { param: 'react' })}</th>
                                                                                            {/* <th>{this.props.t('dashboard.table.salary', {param: 'react'})}</th> */}
                                                                                            <th>{this.props.t('dashboard.table.venue', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.noSelected', { param: 'react' })}</th>
                                                                                            <th>Action</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {postArchive && postArchive.length > 0 && postArchive[activePagePostArchive1 - 1].map((jobs, key) => {
                                                                                            return (<tr key={key}>
                                                                                                <td>{((activePagePostArchive1 - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                <td onClick={() => this.setState({ jobs })}>
                                                                                                    <span
                                                                                                        style={{ "cursor": "pointer" }}
                                                                                                        className="date-clr"
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#myModal001">{jobs.job_title ? jobs.job_title : ''}</span>
                                                                                                </td>

                                                                                                <td>{jobs.interview_date ? this.getInterviewDate(jobs.interview_date) : ''}</td>
                                                                                                <td>{jobs.interview_venue ? jobs.interview_venue : ''}</td>
                                                                                                {/* <td>{jobs.salary_min ? jobs.salary_min : ''}</td> */}
                                                                                                <td >
                                                                                                    <Link
                                                                                                        to={`selected-candidates/${jobs.job_id}`}
                                                                                                        className="date-clr"
                                                                                                    >{this.props.t('homeContent.clickHere', { param: 'react' })}</Link>
                                                                                                </td>
                                                                                                <td>   <Link to="#" onClick={(event) => {
                                                                                                    event.preventDefault();
                                                                                                    this.setState({ delOption: 'edu', delIndex: key })
                                                                                                    // Mainthis.handleDeleteEducation(index)
                                                                                                }}
                                                                                                    data-toggle="modal" data-target="#exampleModal" className="edit-btn mr-3 pull-right"><i className="fa fa-trash" aria-hidden="true"></i></Link></td>
                                                                                            </tr>)
                                                                                        })}
                                                                                        {!this.props.EmployerReducer.postArchive.length &&
                                                                                            <tr>
                                                                                                <td colSpan="8"
                                                                                                    style={{ "textAlign": "center" }}>
                                                                                                    <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>
                                                                                                </td>
                                                                                            </tr>}
                                                                                    </tbody>
                                                                                </table>
                                                                                {postArchive && postArchive.length > 1 ?
                                                                                    <div className='react-pagination'>
                                                                                        <Pagination
                                                                                            pageRangeDisplayed={10}
                                                                                            activePage={activePagePostArchive1}
                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                            totalItemsCount={Mainprops.EmployerReducer.postArchive.length}
                                                                                            onChange={this.handlePageChangePostArchive1}
                                                                                        />
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(ApplyJob));
