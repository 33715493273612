import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router'

class ForeignStudyCamp extends Component {

    state = {
        fraud: []
    }

    componentDidMount() {
        document.title = this.props.t('homeContent.noticeForFraud', { param: 'react' })
    }

    setModalData = fraud => this.setState({ fraud })


    render() {
        const fraudCases = [
            {
                title: "Reg. fake employer Mohit Kumar at employment fair in Fazilka",
                description: "At an employment fair organized at Fazilka, an employer in the name of Mohit Kumar S/o sh. Rajesh Kumar, R/o Sri Muktsar Sahib who introduced himself as H.R. Amazon Co., has been found to be fake.  The jobseekers are advised to bring it to the notice of their respective DBEEs, if they find themselves dealing with any such fake/fraudulent Employers. Contact details are available at",
                link: <a href={'http://pgrkam.com/employment'} rel="noopener noreferrer" target="_blank">http://pgrkam.com/employment</a>
            }

        ]
        const { fraud } = this.state
        return (
            <div className="tab-content" style={{ "border": "none" }}>
                <div className="modal fade" id="descriptionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('counsellingProvider.details', { param: 'react' })}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {fraud.description} {fraud.link}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">{this.props.t('counsellingProvider.close', { param: 'react' })}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">{this.props.t('homeContent.UK', { param: 'react' })}</h6>
                                    </div>
                                    <div className="panel-body p-3">
                                        {/* {fraudCases.map((fraud, key) => {
                                            return ( */}
                                                <div className="mb-4" >
                                                    {fraud.title} 
                                                    <h6>For more details and application form, please visit the website: <a href="https://www.chevening.org" target="_blank">Click Here</a></h6>
                                                    <h6>CHEVENING SCHOLARSHIPS 2022-23</h6>
                                                      <p> Chevening is the UK government’s international awards programme aimed at developing global leaders. </p>
                                                      <p>Funded by the Foreign, Commonwealth and Development Office (FCDO) and partner organisations, Chevening offers two types of award – Chevening Scholarships and Chevening Fellowships – the recipients of which are personally selected by British embassies and high commissions throughout the world.</p>
                                                      {/* <p> As a pilot project, the first registration-cum-counseling camp is going to be held at village Dholan Majra, Morinda from 20-07-2021 to 20-08-2021. The interested eligible candidates from Chamkaur Sahib Constituency can get themselves registered online at the below mentioned link or may visit the said venue for physical registration from 20-07-2021 to 20-08-2021 (on all government working days, Time: 10 AM-04 PM)</p> */}
                                                       <p>Chevening enables outstanding emerging leaders from all over the world to pursue one-year master’s degrees in the UK.</p>
                                                       <h6>Chevening in India</h6>
                                                      {/* <p> Chevening is the UK government’s international awards programme aimed at developing global leaders. </p> */}
                                                      <p>It doesn’t matter where you’re from, it’s where you’re going that counts.</p>
                                                      <p>Applications for 2022/2023 Chevening Scholarships are now open.</p>
                                                      <p>Open for applications until 2 November 2021, at 12:00 (GMT)</p>
                                                      <p>Age-No upper age limit</p>
                                                      <p>Funded by the Foreign, Commonwealth and Development Office and partner organisations, we offer awards to study in the UK for one year on a fully funded master’s degree course.</p>

                                                      <h6>Required documents</h6>
                                                      <p>All Chevening applicants must submit their education documents, references, and one unconditional UK university offer. The deadlines for these required documents are in the Chevening application timeline. Use the ‘update my application’ button above to upload them.</p>
                                                      <p>If you are conditionally selected for a Chevening Scholarship, it is essential that you submit these documents in order to remain in the process.</p>
                                                      
                                                      <h6>Selection updates and timelines</h6>
                                                      <p>You will receive email updates about your application status at each step of the selection process. You can also check your status on the online application system. </p>
                                                       
                                                       <h6>What is included?</h6>
                                                       <p>As Chevening Scholarships are fully-funded, your award includes the following: </p>
                                                            <ul style={{'marginLeft':'5%'}}>
                                                                <li>university tuition fees</li>
                                                                <li>a monthly stipend</li>
                                                                <li>travel costs to and from the UK</li>
                                                                <li>an arrival allowance</li>
                                                                <li>a homeward departure allowance</li>
                                                                <li>the cost of one visa application</li>
                                                                <li>a travel grant to attend Chevening events in the UK</li>

                                                            </ul>

                                                           <h6>Courses</h6> 
                                                    <p>You will need to select three different master's courses. These can be three different courses at the same institution or three similar courses at up to three different universities.</p>
                                                    <p>Choose your three courses carefully. You will not be able to change your course choices after submitting your application unless you are granted permission to do so at the interview stage. You will need to apply separately to your selected courses directly via the university. Please apply as soon as possible to ensure you get a place. To be awarded a scholarship, you must get an unconditional offer for one of your course choices by the deadline in our application timeline for your application year.</p>
                                                    <p>We recommend selecting three similar courses at three different universities to increase the likelihood that you will gain entry into one of them.</p>
                                                    <p>Please note that the course titles do not have to match exactly, but all three courses should be similar in content. You should select courses that reflect your current or future career goals.</p>

                                                    <h6>Eligible courses</h6>
                                                    <p>In order to be eligible, the courses you choose must be full-time, and:</p>
                                                        <ul style={{'marginLeft':'5%'}}>
                                                        <li>Start in the autumn term (usually September/October)</li>
                                                        <li>Taught master’s (i.e. not research-focused programmes)</li>
                                                        <li>Lead to a master’s degree qualification</li>
                                                        <li>Based in the UK</li>
                                                        </ul>
                                                    <p>Courses cannot be:</p>
                                                    <ul style={{'marginLeft':'5%'}}>
                                                    <li>Part-time</li>
                                                    <li>Distance learning</li>
                                                    <li>Less than nine months in duration</li>
                                                    <li>More than 12 months in duration</li>
                                                    <li>PhD or DPhil</li>
                                                    <li>Taught with more than one month of study outside the UK</li>
                                                    </ul>

                                                    <p><b>MBA fee cap:</b> There is a fee cap applied to MBA programmes that requires you to cover any additional cost over £22,000.</p>


                                                        
                                                </div>
                                                {/* ) */}
                                        {/* })} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(ForeignStudyCamp));
