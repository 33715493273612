import axios from 'axios';
import qs from 'qs';
import { push } from 'react-router-redux';
import ServerSetting from '../config.json';
import {SET_LOADER,USER_COURSENATURE,SET_JOBPROVIDER_PROFILE,UNSET_LOADER,USER_LANGUAGE,USER_QUALIFICATION,USER_SPECILIZATION,
  USER_INSTITUTION,USER_JOBSEEKER_CITIES,USER_JOBSEEKER_SUBDISTRICT,USER_CITIES,USER_SUBDISTRICT,OPEN_OPT,USER_RESET,
  USER_SUB_TYPE,USER_DISTRICT,USER_VERIFIED,GET_LOGIN,CREATE_USER,SET_NOTIFICATION,ORG_TYPE,ORG_CATEGORY,ORG_STATE,
  FUNCTIONAL_AREA,SECTOR, SET_SCHEDULED_INTERVIEWS, SET_POST_ARCHIVE, SET_RECENT_JOBS, SET_JOB_MELA_PARTICIPATION,
  SET_EXPIRE_JOB, SET_JOBPROVIDER_ADDRESSES, SET_PROVIDER_SEARCH_RESULTS, SET_APPLIED_INTERVIEWS, SET_SELECTED_APPLICANTS,
  SET_JOB_STATUS, SET_APPLIED_JOBS, SET_MATCHING, SET_SHORTLISTED, SET_APPLICANTS_ACCEPTED_INVITE, SET_RESUME_DATA,
  SET_TIME_SLOT, SET_CONFIRMED_CANDIDATES, SET_SCHDULED_JOBS_PROVIDER, SET_SERVICE_ARCHIEVE} from './actionTypes';
let environment = (typeof process.env.NODE_ENV !== 'undefined' && process.env.NODE_ENV === 'production') ? 'production' : 'development';
let API_SERVER = ServerSetting[environment].API_SERVER;
export const setLoginuser = loginuser => {
  return {
    type: GET_LOGIN,
    loginuser
  };
};
export const setJobProviderProfile = Profile => {
  return {
    type: SET_JOBPROVIDER_PROFILE,
    Profile
  };
}
export const set_usersubtype = UserSubType=> {
  return {
    type: USER_SUB_TYPE,
    UserSubType
  };
};

export const set_Loader = Education => {
  return {
    type: SET_LOADER,
  };
};
export const unset_Loader = Education => {
  return {
    type: UNSET_LOADER,
  };
};

export const set_JUser_Cities = Cities => {
  return {
    type: USER_JOBSEEKER_CITIES,
    Cities
  };
}
export const set_JUser_SubDistrict = Subdistrict => {
  return {
    type: USER_JOBSEEKER_SUBDISTRICT,
    Subdistrict
  };
};
export const set_User_Cities = Cities => {
  return {
    type: USER_CITIES,
    Cities
  };
}
export const set_User_SubDistrict = Subdistrict => {
  return {
    type: USER_SUBDISTRICT,
    Subdistrict
  };
};
export const set_User_District = District => {
  return {
    type: USER_DISTRICT,
    District
  };
};
export const set_userverified = user=> {
  return {
    type: USER_VERIFIED,
    user
  };
};
export const set_userreset = user=> {
  return {
    type: USER_RESET,
    user
  };
};
export const set_OTP = user=> {
  return {
    type: OPEN_OPT,
    user
  };
};
export const set_RegisterUser = user=> {
  return {
    type: CREATE_USER,
    user
  };
};
export const set_User_Qualification = Qualification=> {
  return {
    type: USER_QUALIFICATION,
    Qualification
  };
};
export const set_User_Institution = Institution=> {
  return {
    type: USER_INSTITUTION,
    Institution
  };
};
export const set_User_Specilization = Specilization => {
  return {
    type: USER_SPECILIZATION,
    Specilization
  };
};
export const set_User_Coursenature = Coursenature => {
  return {
    type: USER_COURSENATURE,
    Coursenature
  };
};
export const set_User_Language = Language => {
  return {
    type: USER_LANGUAGE,
    Language
  };
};
export const set_org_type = Orgtype => {
  return {
    type: ORG_TYPE,
    Orgtype
  };
};
export const set_org_category = Orgcategory => {
  return {
    type: ORG_CATEGORY,
    Orgcategory
  };
};
export const set_org_state = Orgstate => {
  return {
    type: ORG_STATE,
    Orgstate
  };
};
export const set_functional_area = Functionalarea => {
  return {
    type: FUNCTIONAL_AREA,
    Functionalarea
  };
};

export const set_Sector = Sector => {
  return {
    type: SECTOR,
    Sector
  };
};


export const setScheduledInterviews = interviews => {
  return {
    type: SET_SCHEDULED_INTERVIEWS,
    interviews
  };
}
export const setMatching = results => {
  return {
    type: SET_MATCHING,
    results
  };
}

export const setShortlistedCandidates = results => {
  return {
    type: SET_SHORTLISTED,
    results
  };
}
export const setResumeData = results => {
  return {
    type: SET_RESUME_DATA,
    results
  };
}




export const postProviderAddress = (data) => {
  return dispatch => {
    dispatch(set_Loader()); 
    axios.post(API_SERVER+'ggr-address/create',qs.stringify(data))
    .then(resp => {
      // console.log(resp)
      // // debugger
      dispatch(unset_Loader()); 
      if(resp.data.status === '401'){
          dispatch(setNotification({type:"error",message:resp.data.message}));
      }
      else{
          dispatch(setNotification({type:"success",message:resp.data.message}));
          dispatch(getJobProviderAddress());        
      }
    })
    .catch(error => {
      // console.log(error);
      // dispatch(Logout());
    });
  };
};

export const confirmJob = (data) => {
  // data['auth_key'] = localStorage.getItem('token')
  return dispatch => {
      axios.post(API_SERVER+'confirmed-candidate/view',qs.stringify(data))
          .then(resp => {
            // console.log(resp)
            // debugger
              if(resp.data.status === '401'){
                  dispatch(setNotification({type:"error",message:resp.data.message}));
              }
              else{
                dispatch(setNotification({type:"success",message:resp.data.message}));
                dispatch(getShortlistedCandidates(data.job_id))
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};

export const getAppliedServiceCandidates = (service_id) => {
  
  return dispatch => {
      axios.post(API_SERVER+'apply-services/view',qs.stringify({auth_key:localStorage.getItem('token'), service_id}))
          .then(resp => {
              // console.log('resp', resp)
              // debugger
              if(resp.data.status === '401'){
                dispatch(setNotification({type:"error",message:resp.data.message}));
            }
            else{
              dispatch(setConfirmedCandidates(resp.data.data));
            }
          })
          .catch(error => {
              // console.log(error);
          });
  };
};



export const selectCandidatesForJob = (data) => {
  return dispatch => {
      dispatch(set_Loader(''))
      axios.post(API_SERVER + 'shorlist-jobseeker/index', qs.stringify(data))
          .then(resp => {
              dispatch(unset_Loader())
              // console.log('resp', resp)
              if (resp.data.status === '401') {
                  dispatch(setNotification({type: "error", message: resp.data.message}));
              } else {
                  dispatch(setNotification({type: "success", message: resp.data.message}));
                  dispatch(getAppliedCandidates(data.job_id))
              }
          })
          .catch(error => {
              // console.log(error);
              // // dispatch(Logout());
          });
  };
};
export const updateTimeSlot = (data) => {
  return dispatch => {
      dispatch(set_Loader(''))
      // http://151.106.28.89/ggr/api/v1/index.php/
      axios.post(API_SERVER + 'timeslot/update', qs.stringify(data))
          .then(resp => {
              dispatch(unset_Loader())
              // console.log('resp', resp)
              if (resp.data.status === '401') {
                  dispatch(setNotification({type: "error", message: resp.data.message}));
              } else {
                  dispatch(setNotification({type: "success", message: resp.data.message}));
                  dispatch(getAppliedCandidates(data.job_id))
              }
          })
          .catch(error => {
              // console.log(error);
              // // dispatch(Logout());
          });
  };
};



export const getJobProviderAddress = () => {
  return dispatch => {
    dispatch(set_Loader());
    axios.post(API_SERVER+'ggr-address/view',qs.stringify({auth_key:localStorage.getItem('token')}))
    .then(resp => {
      dispatch(unset_Loader()); 
      if(resp.data.status === '401'){
          dispatch(setNotification({type:"error",message:resp.data.message}));
      }
      else{
          dispatch(setProviderAddress(resp.data));        
      }
    })
    .catch(error => {
      // console.log(error);
      // dispatch(Logout());
    });
  };
};
export const getShortlistedCandidates = (job_id) => {
  return dispatch => {
    dispatch(set_Loader());
    axios.post(API_SERVER+'shortlisted-candidates/index',qs.stringify({auth_key:localStorage.getItem('token'), job_id}))
    .then(resp => {
      dispatch(unset_Loader()); 
      // console.log('resp', resp)
      if(resp.data.length){
        dispatch(setShortlistedCandidates(resp.data));   
       
          // dispatch(setNotification({type:"error",message:resp.data.message}));
      }
      else{
        dispatch(setShortlistedCandidates({notFound:"No Records Found"}));   
      }
    })
    .catch(error => {
      // console.log(error);
      // dispatch(Logout());
    });
  };
};


export const setServiceArchieve = data => {
  return {
    type: SET_SERVICE_ARCHIEVE,
    data
  };
}


export const getServiceArchieve = () => {
  return dispatch => {
    axios.post(API_SERVER + 'local-service-provider/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
        if(resp.data.status ==='200'){
          dispatch(setServiceArchieve(resp.data.data));
        } else {
          dispatch(setServiceArchieve([]
            ));
        }
          
      })
      .catch(error => {
        // console.log(error);
      });
  };
};

export const getConfimedCandidates = (job_id) => {
  return dispatch => {
    dispatch(set_Loader());
    axios.post(API_SERVER+'confirmed-candidate/index',qs.stringify({auth_key:localStorage.getItem('token'), job_id}))
    .then(resp => {
      dispatch(unset_Loader()); 
      if(resp.data.length){
        dispatch(setConfirmedCandidates(resp.data));   
      }
      else{
        dispatch(setConfirmedCandidates({notFound:"No Records Found"}));   
      }
    })
    .catch(error => {
      // console.log(error);
      // dispatch(Logout());
    });
  };
};


export const getMatching = () => {
  return dispatch => {
    axios.post(API_SERVER+'interview-candidates/view')
    .then(resp => {
      if(resp.data.status === '401'){
          dispatch(setNotification({type:"error",message:resp.data.message}));
      }
      else{
          dispatch(setMatching(resp.data));        
      }
    })
    .catch(error => {
      // console.log(error);
      // dispatch(Logout());
    });
  };
};

export const getResumeData = (id) => {
  return dispatch => {
    dispatch(setResumeData([]));
    dispatch(set_Loader())
    axios.post(API_SERVER+'jobseeker-detail/index',qs.stringify({id}))
    .then(resp => {
      // console.log('respp', resp)
      dispatch(setResumeData(resp.data));
      if(resp.data.status === '401'){
        dispatch(setResumeData({msg: "No Records Found"}));  
        // dispatch(setNotification({type:"error",message:resp.data.message}));
      }
      
      dispatch(unset_Loader())
    })
    .catch(error => {
      // console.log(error);
      // dispatch(Logout());
    });
  };
};


export const inviteJobSeeker = (data) => {
  data['auth_key'] = localStorage.getItem('token')
  return dispatch => {
    axios.post(API_SERVER+'jobproviderinvitation/index',qs.stringify(data))
    .then(resp => {
      const notificationType = resp.data.status === '401' ? 'error': 'success'
      if(resp.data.status !== '401'){
        dispatch(push('/myaccount')) 
      }
      dispatch(setNotification({type:notificationType,message:resp.data.message}));       
    })
    .catch(error => {
      // console.log(error);
      // dispatch(Logout());
    });
  };
};

export const setProviderAddress = Address => {
  return {
    type: SET_JOBPROVIDER_ADDRESSES,
    Address
  };
}

export const setAppliedInterviews = interviews => {
  return {
    type: SET_APPLIED_INTERVIEWS,
    interviews
  };
}
export const setSelectedApplicants = applicants => {
  return {
    type: SET_SELECTED_APPLICANTS,
    applicants
  };
}

export const setConfirmedCandidates = data => {
  return {
    type: SET_CONFIRMED_CANDIDATES,
    data
  };
}



export const getExpireJob = () => {
  return dispatch => {
    axios.post(API_SERVER+'expirejob/index',qs.stringify({auth_key:localStorage.getItem('token')}))
    .then(resp => {
      dispatch(setExpireJob(resp.data));
    })
    .catch(error => {
      // dispatch(Logout());
    });
  };
};
export const getTimeSlots = () => {
  return dispatch => {
    axios.get(API_SERVER+'timeslot/view')
    .then(resp => {
      dispatch(setTimeSlots(resp.data));
    })
    .catch(error => {
      // dispatch(Logout());
    });
  };
};

export const setExpireJob = (job) => {
  return {
    type: SET_EXPIRE_JOB,
    job
  };
}
export const setTimeSlots = (data) => {
  return {
    type: SET_TIME_SLOT,
    data
  };
}
export const setJobStatus = (status) => {
  return {
    type: SET_JOB_STATUS,
    status
  };
}
export const setAppliedJobs = (jobs) => {
  return {
    type: SET_APPLIED_JOBS,
    jobs
  };
}
export const setListOfCandidatesAcceptedInvite = (data) => {
  return {
    type: SET_APPLICANTS_ACCEPTED_INVITE,
    data
  };
}
export const getListOfCandidatesAcceptedInvite = (job_id) => {
  return dispatch => {
      dispatch(set_Loader())
      axios.post(API_SERVER+'scheduled-interview/view',qs.stringify({job_id}))
          .then(resp => {
              dispatch(unset_Loader())
              if(resp.data.status === '401'){
                dispatch(setListOfCandidatesAcceptedInvite([]));
                  // dispatch(setNotification({type:"error",message:resp.data.message}));
              }
              else{
                  dispatch(setListOfCandidatesAcceptedInvite(resp.data));
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};


export const getAppliedCandidates = (job_id) => {
  return dispatch => {
      dispatch(set_Loader())
      axios.post(API_SERVER+'scheduled-interview/index',qs.stringify({auth_key:localStorage.getItem('token'), job_id}))
          .then(resp => {
              dispatch(unset_Loader())
              // console.log('resp hererererererer', resp.data)
              // console.log('resp hererererererer', resp.data.length)
              if(resp.data.status === '401'){
                  // dispatch(setNotification({type:"error",message:resp.data.message}));
                  // dispatch(setScheduledInterviews([]));
                  // dispatch(setShortlistedCandidates({"notFound":"No records Found"}));
                  dispatch(setShortlistedCandidates({"notFound":"No records Found"}));
              }
              else{
                // let data = resp.data ? resp.data : 
                dispatch(setShortlistedCandidates(resp.data));
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};


export const getScheduledInterviews = (job_id) => {
    return dispatch => {
        dispatch(set_Loader())
        axios.post(API_SERVER+'scheduled-interview/index',qs.stringify({auth_key:localStorage.getItem('token'), job_id}))
            .then(resp => {
                dispatch(unset_Loader())
                if(resp.data.status === '401'){
                    // dispatch(setNotification({type:"error",message:resp.data.message}));
                }
                else{
                    dispatch(setScheduledInterviews(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getAllInterviews = () => {
    return dispatch => {
        dispatch(set_Loader())
        axios.post(API_SERVER+'scheduled-interview/create',qs.stringify({auth_key:localStorage.getItem('token')}))
            .then(resp => {
                dispatch(unset_Loader())
                if(resp.data.status === '401'){
                    // dispatch(setNotification({type:"error",message:resp.data.message}));
                }
                else{
                  const data = []
                  resp.data.posted.forEach((r) => {
                    let invitation = resp.data.invitation.filter((sel) => sel.job_id === r.id)
                    invitation = invitation ? invitation[0]: []
                    let selected = resp.data.selected.filter((sel) => sel.job_id === r.id)
                    selected = selected ? selected[0]: []
                    let applied = resp.data.applied.filter((sel) => sel.job_id === r.id)
                    applied = applied ? applied[0]: []
                    data.push({...r, ...invitation, ...selected, ...applied})
                  })
                    dispatch(setScheduledInterviews(data));
                }
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
};



export const updateJobStatus = (data) => {
  data['auth_key'] = localStorage.getItem('token')
  return dispatch => {
      axios.post(API_SERVER+'accepted-record/create',qs.stringify(data))
          .then(resp => {
            // console.log(resp)
            // debugger
              if(resp.data.status === '401'){
                  dispatch(setNotification({type:"error",message:resp.data.message}));
              }
              else{
                dispatch(setNotification({type:"success",message:resp.data.message}));
                dispatch(getAppliedCandidates(data.job_id))
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};
export const getJobStatus = () => {
  return dispatch => {
      axios.get(API_SERVER+'jobstatus/view',qs.stringify({auth_key:localStorage.getItem('token')}))
          .then(resp => {
              // // console.log(resp)
              // // debugger
              if(resp.data.status === '401'){
                  // dispatch(setNotification({type:"error",message:resp.data.message}));
              }
              else{
                  dispatch(setJobStatus(resp.data));
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};
export const getAppliedJobs = () => {
  return dispatch => {
      axios.post(API_SERVER+'jobstatus/index',qs.stringify({auth_key:localStorage.getItem('token')}))
          .then(resp => {
              // // console.log(resp)
              // // debugger
              if(resp.data.status === '401'){
                  // dispatch(setNotification({type:"error",message:resp.data.message}));
              }
              else{
                  dispatch(setAppliedJobs(resp.data));
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};


export const getJobsAppliedBySeeker = () => {
  return dispatch => {
    dispatch(set_Loader());
      axios.post(API_SERVER+'scheduled-interview/update',qs.stringify({auth_key:localStorage.getItem('token')}))
          .then(resp => {
            // console.log(resp)
           
            dispatch(unset_Loader());
              if(resp.data.status === '401'){
              }
              else{
                  dispatch(setAppliedInterviews(resp.data.data));
              }
          })
          .catch(error => {
              
          });
  };
};

export const getAppliedInterviews = () => {
  return dispatch => {
    dispatch(set_Loader());
      axios.post(API_SERVER+'scheduled-job/view',qs.stringify({auth_key:localStorage.getItem('token')}))
          .then(resp => {
           
            dispatch(unset_Loader());
              if(resp.data.status === '401'){
              }
              else{
                  dispatch(setAppliedInterviews(resp.data));
              }
          })
          .catch(error => {
              
          });
  };
};

export const getSelectedApplicants = (job_id) => {
  return dispatch => {
      dispatch(set_Loader())
      axios.post(API_SERVER+'scheduled-job/index',qs.stringify({auth_key:localStorage.getItem('token'), job_id}))
          .then(resp => {
            // // console.log('resp', resp)
            // // debugger
              dispatch(unset_Loader())
              if(resp.data.status === '401'){
                  // dispatch(setNotification({type:"error",message:resp.data.message}));
              }
              else{
                  dispatch(setSelectedApplicants(resp.data));
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};

export const selectAppliedApplicant = (data) => {
  data['auth_key'] = localStorage.getItem('token')
  return dispatch => {
      axios.post(API_SERVER+'applied-job/create',qs.stringify(data))
          .then(resp => {
            // // console.log(resp)
            // // debugger
              if(resp.data.status === '401'){
                  // dispatch(setNotification({type:"error",message:resp.data.message}));
              }
              else{
                  // dispatch(setAppliedInterviews(resp.data));
                  dispatch(setNotification({type:"success",message:resp.data.message}));
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};

export const setPostArchive = archive => {
  return {
    type: SET_POST_ARCHIVE,
    archive
  };
}

export const setScheduledJobs = data => {
  return {
    type: SET_SCHDULED_JOBS_PROVIDER,
    data
  };
}


export const getScheduledJobs = () => {
  return dispatch => {
    dispatch(set_Loader());
      axios.post(API_SERVER+'scheduled-interview/view',qs.stringify({auth_key:localStorage.getItem('token')}))
          .then(resp => {
            dispatch(unset_Loader());
              if(resp.data.status === '401'){
              } else {
                dispatch(setScheduledJobs(resp.data.data));
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};

export const get_delete_Jobs = (data) => {
  return dispatch => {
      dispatch(set_Loader());
      axios.post(API_SERVER + 'job_archieve/delete', qs.stringify(data))
          .then(resp => {
              dispatch(unset_Loader());
              if (resp.data.status === '401') {
                  dispatch(setNotification({ type: "error", message: resp.data.message }));
              } else {
                  dispatch(setNotification({ type: "success", message: resp.data.message }));
                  dispatch(getPostArchive());
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};

export const getPostArchive = () => {
  return dispatch => {
    dispatch(set_Loader());
      axios.post(API_SERVER+'job_archieve/index',qs.stringify({auth_key:localStorage.getItem('token')}))
          .then(resp => {
            dispatch(unset_Loader());
              if(resp.data.status === '401'){
                  // dispatch(setNotification({type:"error",message:resp.data.message}));
              }
              else {
                const data = []
                resp.data.data.forEach((r) => {
                  let filterSelected = resp.data.selected.filter((sel) => sel.job_id === r.job_id)
                  filterSelected = filterSelected ? filterSelected[0]: []
                  data.push({...r, ...filterSelected})
                })
                dispatch(setPostArchive(data));
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};

export const getPostArchiveView = (data) => {
  data['auth_key'] = localStorage.getItem('token')
  return (dispatch) => {
      dispatch(set_Loader())
      axios.post(API_SERVER+'job_archieve/view',qs.stringify(data))
          .then(resp => {
              dispatch(unset_Loader())
              let value = resp.data.data
              if(resp.data.data && resp.data.applied){
                  value = []
                  
                  let val = resp.data.data
                  const filterValue = resp.data.applied.filter((ap) => val.id === ap.job_id)
                  value = {...val, ...filterValue[0]}
              } 
              
              if(resp.data.status === '401'){
                value = {notFound:true}
            }
            dispatch(setPostArchive(value));  
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};

export const setRecentJobs = jobs => {
  return {
    type: SET_RECENT_JOBS,
    jobs
  };
}

export const setSearchResults = results => {
  return {
      type: SET_PROVIDER_SEARCH_RESULTS,
      results
  };
}

export const getSearchResults = (data) => {
  data['auth_key'] = localStorage.getItem('token')
  return dispatch => {
      dispatch(set_Loader());
      axios.post(API_SERVER+'search-jobseekers/index',qs.stringify(data))
          .then(resp => {
            
              dispatch(unset_Loader());
              dispatch(setSearchResults(resp.data));
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};


export const getRecentJobs = () => {
  return dispatch => {
    dispatch(set_Loader());
    // http://151.106.28.89/ggr/api/v1/index.php/
      axios.post(API_SERVER+'scheduled-interview/view',qs.stringify({auth_key:localStorage.getItem('token')}))
          .then(resp => {
            dispatch(unset_Loader());
            // console.log('resp ->>>>>>', resp);
              if(resp.data.status === '401'){
                  // dispatch(setNotification({type:"error",message:resp.data.message}));
              }
              else{
                  // const data = []
                  // resp.data.data.forEach((r) => {
                  //   let filterSelected = resp.data.pending.filter((sel) => sel.job_id === r.job_id)
                  //   filterSelected = filterSelected.length ? true: false
                  //   data.push({...r, action: filterSelected})
                  // })
                  dispatch(setRecentJobs(resp.data.data));
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};

export const setJobMelaParticipation = participation => {
  return {
    type: SET_JOB_MELA_PARTICIPATION,
    participation
  };
}

export const getJobMelaParticipation = () => {
  return dispatch => {
      axios.post(API_SERVER+'job-mela-participation/index',qs.stringify({auth_key:localStorage.getItem('token')}))
          .then(resp => {
            
              if(resp.data.status === '401'){
                  // dispatch(setNotification({type:"error",message:resp.data.message}));
              }
              else{
                  dispatch(setJobMelaParticipation(resp.data));
              }
          })
          .catch(error => {
              // console.log(error);
              // dispatch(Logout());
          });
  };
};

export const get_jobprovider_profile = () => {
  return dispatch => {
    dispatch(set_Loader()); 
    axios.post(API_SERVER+'jobprovider-details/view',qs.stringify({auth_key:localStorage.getItem('token')}))
    .then(resp => {
      dispatch(unset_Loader()); 
      if(resp.data.status === '401'){
          dispatch(setNotification({type:"error",message:resp.data.message}));
      }
      else{
          dispatch(setJobProviderProfile(resp.data));        
      }
    })
    .catch(error => {
      // console.log(error);
      // dispatch(Logout());
    });
  };
};
export const get_update_profile = (data) => {
  return dispatch => {
    dispatch(set_Loader()); 
    axios.post(API_SERVER+'jobprovider-details/create',qs.stringify(data))
    .then(resp => {
      dispatch(unset_Loader()); 
      if(resp.data.status === '401'){
          dispatch(setNotification({type:"error",message:resp.data.message}));
      }
      else{
          dispatch(setNotification({type:"success",message:resp.data.message}));
          dispatch(get_jobprovider_profile());        
          dispatch(getJobProviderAddress());
      }
    })
    .catch(error => {
      // console.log(error);
      // // dispatch(Logout());
    });
  };
};
export const get_functional_area = () => {
  return dispatch => {
    axios.get(API_SERVER+'functional-area/view')
    .then(resp => {
        dispatch(set_functional_area(resp.data));
    })
    .catch(error => {
      // console.log(error);
      //   // dispatch(Logout());
    });
  };
};
export const get_Sector = () => {
  return dispatch => {
    axios.get(API_SERVER+'sector/view')
    .then(resp => {
        dispatch(set_Sector(resp.data));
    })
    .catch(error => {
      // console.log(error);
      // // dispatch(Logout());
    });
  };
};
export const get_User_Specilization = () => {
  return dispatch => {
    axios.get(API_SERVER+'specialization/view')
    .then(resp => {
        dispatch(set_User_Specilization(resp.data));
    })
    .catch(error => {
      // console.log(error);
      // // dispatch(Logout());
    });
  };
};
export const get_User_Qualification = () => {
  return dispatch => {
    axios.get(API_SERVER+'ggr-user-qualification/view')
    .then(resp => {
        dispatch(set_User_Qualification(resp.data));
    })
    .catch(error => {
      // console.log(error);
    });
  };
};



export const get_User_Cities = (dist,type = 'currunt') => {
  return dispatch => {
    dispatch(set_Loader(''));
    axios.post(API_SERVER+'ggr-city/view',qs.stringify({subdistrict_id:dist}))
    .then(resp => {
      dispatch(unset_Loader(''));
      if(type === 'currunt'){
        dispatch(set_User_Cities(resp.data));
      }
      else{
        dispatch(set_JUser_Cities(resp.data));
      }

    })
    .catch(error => {
      dispatch(unset_Loader(''));
      // console.log(error);
    });
  };
};
export const get_User_Subistrict = (dist,type = 'currunt') => {
  return dispatch => {
    dispatch(set_Loader(''));
    axios.post(API_SERVER+'ggr-user-subdistrict/view',qs.stringify({district_id:dist}))
    .then(resp => {
      dispatch(unset_Loader(''));
      if(type === 'currunt'){
        dispatch(set_User_SubDistrict(resp.data));
      }
      else{
        dispatch(set_JUser_SubDistrict(resp.data));
      }
    })
    .catch(error => {
      dispatch(unset_Loader(''));
      // console.log(error);
    });
  };
};

export const get_User_District = () => {
  return dispatch => {
    axios.get(API_SERVER+'state/index')
    .then(resp => {
      dispatch(set_User_District(resp.data))
    })
    .catch(error => {
      // console.log(error);
      //// dispatch(Logout());
    });
  };
};
export const get_org_type= () => {
  return dispatch => {
    axios.get(API_SERVER+'ggr-organization-type/view')
    .then(resp => {
      dispatch(set_org_type(resp.data))
    })
    .catch(error => {
      // console.log(error);
      //// dispatch(Logout());
    });
  };
};

export const get_org_category= () => {
  return dispatch => {
    axios.get(API_SERVER+'ggr-organization-category/view')
    .then(resp => {
      dispatch(set_org_category(resp.data))
    })
    .catch(error => {
      // console.log(error);
      //// dispatch(Logout());
    });
  };
};
export const get_org_state = () => {
  return dispatch => {
    axios.get(API_SERVER+'state/index')
    .then(resp => {
      dispatch(set_org_state(resp.data))
    })
    .catch(error => {
      // console.log(error);
      //// dispatch(Logout());
    });
  };
};
export const get_User_Type = () => {
  return dispatch => {
    axios.get(API_SERVER+'user_subtype/index')
    .then(resp => {
      dispatch(set_usersubtype(resp.data))
      
    })
    .catch(error => {
      // console.log(error);
      //// dispatch(Logout());
    });
  };
};

export const setNotification = Notification => {
  return {
    type: SET_NOTIFICATION,
    Notification
  };
}
export const Resendotp = (data) => {
  // console.log(data);
  return dispatch => {
     dispatch(set_Loader(''));
    axios.post(API_SERVER+'site/resend-verification-email',qs.stringify(data))
    .then(resp => {
      dispatch(unset_Loader(''));
      // console.log(resp);
      if(resp.data.status === '200'){
          dispatch(set_OTP(''));
          dispatch(setNotification({type:"success",message:resp.data.message}));
      }
      else{
          dispatch(setNotification({type:"error",message:resp.data.message}));        
      }
    })
    .catch(error => {
      dispatch(unset_Loader(''));
      if (error.response) {
        dispatch(setNotification({type:"error",message:error.response.data}));
        
      } else if (error.request) {
          // console.log(error.request);
      } else {
          // console.log('Error', error.message);
          dispatch(setNotification({type:"error",message:error.message}));
      }
    });
  };
}
export const user_forgetpass = (data) => {
  // console.log(data);
  return dispatch => {
     dispatch(set_Loader(''));
    axios.post(API_SERVER+'site/request-password-reset',qs.stringify(data))
    .then(resp => {
      dispatch(unset_Loader(''));
      // console.log(resp);
      if(resp.data.status === '200'){
          dispatch(set_userreset(resp.data.message));
          dispatch(setNotification({type:"success",message:resp.data.message}));
      }
      else{
          dispatch(setNotification({type:"error",message:resp.data.message}));        
      }
    })
    .catch(error => {
      dispatch(unset_Loader(''));
      if (error.response) {
        dispatch(setNotification({type:"error",message:error.response.data}));
        
      } else if (error.request) {
          // console.log(error.request);
      } else {
          // console.log('Error', error.message);
          dispatch(setNotification({type:"error",message:error.message}));
      }
    });
  };
}
export const user_verify = (data) => {
  // console.log(data);
  return dispatch => {
     dispatch(set_Loader(''));
    axios.post(API_SERVER+'site/verify-email',qs.stringify(data))
    .then(resp => {
      dispatch(unset_Loader(''));
      // console.log(resp);
      if(resp.data.status === '200'){
          dispatch(set_userverified(resp.data.message));
          dispatch(setNotification({type:"success",message:resp.data.message}));
      }
      else{
          dispatch(setNotification({type:"error",message:resp.data.message}));        
      }
    })
    .catch(error => {
      dispatch(unset_Loader(''));
      if (error.response) {
        dispatch(setNotification({type:"error",message:error.response.data}));
        
      } else if (error.request) {
          // console.log(error.request);
      } else {
          // console.log('Error', error.message);
          dispatch(setNotification({type:"error",message:error.message}));
      }
    });
  };
}
export const RegisterJobSeeker = (data) => {
  // console.log(data);
  return dispatch => {
    dispatch(set_Loader(''));
    axios.post(API_SERVER+'site/signup',qs.stringify(data))
    .then(resp => {
      dispatch(unset_Loader(''));
      if(resp.data.status === '401'){
          dispatch(setNotification({type:"error",message:resp.data.message}));
        }
        else{
          dispatch(set_RegisterUser(resp.data));
        }
    })
    .catch(error => {
      dispatch(unset_Loader(''));
      if (error.response) {
        dispatch(setNotification({type:"error",message:error.response.data}));
        
      } else if (error.request) {
          // console.log(error.request);
          // dispatch(setNotification({type:"error",error.message}));
      } else {
          // console.log('Error', error.message);
          dispatch(setNotification({type:"error",message:error.message}));
      }
    });
  };
};

export const Islogin = () => {
  return dispatch => {
    axios.post(API_SERVER+'site/index',qs.stringify({auth_key:localStorage.getItem('token')}))
    .then(resp => {
      // console.log(resp);
       if(resp.data.status === '401'){
          // dispatch(Logout());
        }
        else{
          localStorage.setItem('token', resp.data.auth_key);
          localStorage.setItem('login_type',resp.data.name);
          localStorage.setItem('Islogin',true);
          dispatch(setLoginuser(resp.data));
        }
    })
    .catch(error => {
      if (error.response) {
        dispatch(setNotification({type:"error",message:error.response.data}));
         // dispatch(Logout());
        
      } else if (error.request) {
      } else {
          // console.log('Error', error.message);
          dispatch(setNotification({type:"error",message:error.message}));
          // dispatch(Logout());
      }
    });
  };
};
export const Logout = () => {
  return dispatch => {
   /* axios.get('/user/logout',{headers: {'x-access-token': localStorage.getItem('token')}})
    .then(resp => {
      // console.log(resp);
	  localStorage.setItem('token','null');
	  localStorage.setItem('Islogin',false);
	  dispatch(setLoginuser([]));
    })
    .catch(error => {
	  localStorage.setItem('token','null');
	  localStorage.setItem('Islogin',false);
		dispatch(setLoginuser([]));
    });*/
    localStorage.setItem('token','null');
    localStorage.setItem('login_type','null');
    localStorage.setItem('Islogin',false);
    dispatch(setLoginuser([]));
     dispatch(setNotification({type:"success",message:"Logout Successfully.."}));
  };
};
export const Login = (data) => {
  return dispatch => {
    dispatch(set_Loader(''));
    axios.post(API_SERVER+'site/login',qs.stringify(data))
    .then(resp => {
      dispatch(unset_Loader(''));
      // console.log(resp);
      if(resp.data.status === '401' || resp.data.status === '204'){
          if( resp.data.message === 'Account not verified'){
            dispatch(set_RegisterUser(resp.data));
            dispatch(setLoginuser('Account not verified'));
          }
          dispatch(setNotification({type:"error",message:resp.data.message}));
      }
      else{
        localStorage.setItem('token', resp.data.auth_key);
        localStorage.setItem('login_type',resp.data.name);
       localStorage.setItem('Islogin',true);
      dispatch(setLoginuser(resp.data));
      dispatch(setNotification({type:"success",message:"Login Successfully.."}));
      }

    })
    .catch(error => {
      dispatch(unset_Loader(''));
      if (error.response) {
        dispatch(setNotification({type:"error",message:error.response.data}));
        
      } else if (error.request) {
      } else {
          // console.log('Error', error.message);
          dispatch(setNotification({type:"error",message:error.message}));
      }
    });
  };
};
export const CreateUser = (newuser) => {
  return dispatch => {
    axios.post('/user/',newuser)
    .then(resp => {
      dispatch(setNotification({type:"success",message:"User created Successfully.."}));
    })
    .catch(error => {
      // console.log(error);
		// console.log(error.response.data.error);
		if(error.response.data.error === 11000){
			dispatch(setNotification({type:"error",message:"Email already exist..!"}));
		}
		if(error.response.data.error === 11001){
			dispatch(setNotification({type:"error",message:"Urlkey already exist..!"}));
		}
    });
  };
};
export const SignupUser = (newuser) => {
  return dispatch => {
    axios.post('/user/signup',newuser)
    .then(resp => {
      dispatch(setNotification({type:"success",message:"Login Successfully.."}));
	    localStorage.setItem('token', resp.data.token);
		localStorage.setItem('Islogin',true);
	   let user = resp.data.user;
		user.role = resp.data.role;
		user.business_id = resp.data.business_id;
	  dispatch(setLoginuser(user));
    })
    .catch(error => {
      // console.log(error);
	  if(error.response.data.error === 11000){
			dispatch(setNotification({type:"error",message:"Email already exist..!"}));
		}
		if(error.response.data.error === 11001){
			dispatch(setNotification({type:"error",message:"Urlkey already exist..!"}));
		}
    });
  };
};
export const UpdateUser = (newuser) => {
  return dispatch => {
    axios.post('/user/update',newuser)
    .then(resp => {
	  // console.log(resp);
	  if(resp.data.success === true || resp.data.success === 'true' ){
		   dispatch(setNotification({type:"success",message:"User updated Successfully.."}));
	  } 
    })
    .catch(error => {
      // console.log(error);
	   if(error.response.data.error === 11000){
			dispatch(setNotification({type:"error",message:"Email already exist..!"}));
		}
		if(error.response.data.error === 11001){
			dispatch(setNotification({type:"error",message:"Urlkey already exist..!"}));
		}
    });
  };
};
export const UpdateProfile = (newuser) => {
  return dispatch => {
    axios.post('/user/profileupdate',newuser)
    .then(resp => {
	 // console.log(resp);
	  let user = resp.data.user;
	  user.role = resp.data.role;
	  user.business_id = resp.data.business_id;
      dispatch(setLoginuser(user));
    })
    .catch(error => {
      // console.log(error);
	   if(error.response.data.error === 11000){
			dispatch(setNotification({type:"error",message:"Email already exist..!"}));
		}
		if(error.response.data.error === 11001){
			dispatch(setNotification({type:"error",message:"Urlkey already exist..!"}));
		}
    });
  };
};
export const UpdateProfileImage = (newuser) => {
  return dispatch => {
    axios.post('/user/imageupdate',newuser)
    .then(resp => {
	 // console.log(resp);
	  let user = resp.data.user;
	  user.role = resp.data.role;
	  user.business_id = resp.data.business_id;
      dispatch(setLoginuser(user));
    })
    .catch(error => {
      // console.log(error);
	   if(error.response.data.error === 11000){
			dispatch(setNotification({type:"error",message:"Email already exist..!"}));
		}
		if(error.response.data.error === 11001){
			dispatch(setNotification({type:"error",message:"Urlkey already exist..!"}));
		}
    });
  };
};
export const UpdatePassword = (newuser) => {
  return dispatch => {
    axios.post('/user/profilepassword',newuser)
    .then(resp => {
	 // console.log(resp);
	  if(resp.data.success === true || resp.data.success === 'true' ){
		   dispatch(setNotification({type:"success",message:"User updated Successfully.."}));
	  } 
    })
    .catch(error => {
      // console.log(error);
        // dispatch(Logout());
    });
  };
};
