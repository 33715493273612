import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'

class Privacy extends Component {

    componentDidMount() {
        document.title = this.props.t('footer.privacy_policy', {param: 'react'})
    }
    
    render() {
        return (
            <div className="tab-content">
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">{this.props.t('privacy.title', { param: 'react' })}</h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <p>{this.props.t('privacy.content', { param: 'react' })}</p>
                                        <h4>{this.props.t('privacy.head1', { param: 'react' })}</h4>
                                        <p>{this.props.t('privacy.content1', { param: 'react' })}</p>
                                        <h4>{this.props.t('privacy.head2', { param: 'react' })}</h4>
                                        <p>{this.props.t('privacy.content2', { param: 'react' })}</p>
                                        <h4>{this.props.t('privacy.head3', { param: 'react' })}</h4>
                                        <p>{this.props.t('privacy.content3', { param: 'react' })}</p>
                                        <h4>{this.props.t('privacy.head4', { param: 'react' })}</h4>
                                        <p>{this.props.t('privacy.content4', { param: 'react' })}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Privacy));
