import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Pagination from "react-js-pagination";
import store from "../../store"
import {
    Islogin, setRedirectionLink, get_education,
    getExperience, get_org_type, get_User_District
} from "../../actions/accountAction";
import { chunk, capitalizeFirstLetter, getQualification } from '../../helpers'
import { Link } from 'react-router'
import Loader from 'react-loader-spinner';
import { getSearchResults, setSearchResults } from "../../actions/jobseekerAction";
import { getJobTitles } from '../../actions/forgienproviderAction'
import { Multiselect } from 'multiselect-react-dropdown';
import Autocomplete from 'react-autocomplete'

const RESULT_PER_PAGE = 25

class SearchResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            locations: '',
            formData1: [],
            detailText:''
        }
        this.multiselectRef = React.createRef();
        this.allJobs = React.createRef();
        if (localStorage.getItem('Islogin') === 'true' && localStorage.getItem('Islogin')) {
            store.dispatch(Islogin());
        }
        store.dispatch(getSearchResults(this.props.location.query));
        store.dispatch(get_education());
        store.dispatch(getExperience());
        store.dispatch(get_org_type());
        store.dispatch(get_User_District());
        store.dispatch(getJobTitles());
    }

    componentDidMount() {
        document.title = this.props.t('sidebar.search_jobs', { param: 'react' })
        const queryData = this.props.location.query
        const formData1 = this.state.formData1
        formData1['education_level'] = queryData['education_level'] ? queryData['education_level'] : ''
        formData1['experience'] = queryData['experience'] ? queryData['experience'] : ''
        formData1['job_type'] = queryData['job_type'] ? queryData['job_type'] : ''
        formData1['location'] = queryData['location'] ? JSON.parse(queryData['location']) : ''
        formData1['govtType'] = queryData['govtType'] ? queryData['govtType'] : ''
        formData1['techType'] = queryData['techType'] ? queryData['techType'] : ''
        formData1['gender'] = queryData['gender'] ? queryData['gender'] : ''
        formData1['job_title'] = queryData['job_title'] ? queryData['job_title'] : ''
        formData1['pwd'] = queryData['pwd'] ? queryData['pwd'] : ''
        this.setState({ formData1 })
        
    }

    
    setEducationJobTitle = (value) => {
        const formData1 = this.state.formData1
        let newValue = value
        if (this.props.forgienproviderReducer.jobTitles && this.props.forgienproviderReducer.jobTitles.length) {
            const filterValue = this.props.forgienproviderReducer.jobTitles.filter((job) => job.job_title === value)
            if (filterValue && filterValue.length) {
                newValue = filterValue[0].job_title
            }
        }
        formData1['job_title'] = newValue
        this.setState({ formData1 })
    }

    resetValues() {
        // By calling the belowe method will reset the selected values programatically
        this.multiselectRef.current.resetSelectedValues();
    }

    getExperienceToShow = (jobData) => {
        if ((jobData.min_exp && jobData.max_exp && parseInt(jobData.min_exp) > 0 && parseInt(jobData.max_exp) > 0)) {
            return `${jobData.min_exp} - ${jobData.max_exp} ${this.props.t('experience.years', { param: 'react' })}`
        } else {
            return this.props.t('JobPost.fresher', { param: 'react' })
        }
    }

    parseLocation = (location) => {
        return location ? JSON.parse(location) : []
    }

    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map(function (data, index) {
                if (data.state_name === 'Punjab') {
                    data.dist.map(function (distdata, distindex) {
                        Distoption.push({ name: distdata.name, id: distdata.id })
                        return Distoption
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }

    getStates = (job) => {
        const names = []
        if (typeof job.state_id !== "undefined") {
            const stateArray = JSON.parse(job.state_id)
            if (stateArray && stateArray.length && this.props.actionReducer.District && this.props.actionReducer.District.length > 0) {
                stateArray.forEach((state) => {
                    let filterVal = this.props.actionReducer.District.filter((district) => district.state_id === state)
                    if (filterVal && filterVal[0]) {
                        names.push(filterVal[0].state_name)
                    }
                })
            }
        }
        return names.length ? names.toString() : ''
    }

    submitSearch = async (e) => {
        e.preventDefault();
        this.setState({ activePage: 1 })
        let queryString = ''

        for (let property in this.state.formData1) {
            let value = this.state.formData1[property] !== '' ? this.state.formData1[property] : ''
            if (value) {
                let prefix = queryString !== '' ? '&' : ''
                if (property === "location") {
                    value = JSON.stringify(value)
                }
                queryString += `${prefix}${property}=${value}`
            }
        }

        await this.props.router.push(`/search-results/?${queryString}`)
        store.dispatch(getSearchResults(this.props.location.query));
    }

    onSelectChange = async (selectedList) => {
        const { formData1 } = this.state
        formData1['location'] = []
        this.setState({ formData1 })
        await this.resetValues()
        let filterDist1 = selectedList.filter((dist) => dist.id === '667')
        if (filterDist1 && filterDist1.length) {
            formData1['location'] = this.getDistrictId(filterDist1)
            this.setState({ formData1 })
            return
        }

        let filterDist2 = selectedList.filter((dist) => dist.id === '666')
        if (filterDist2 && filterDist2.length) {
            formData1['location'] = this.getDistrictId(filterDist2)
            this.setState({ formData1 })
            return
        }
        formData1['location'] = this.getDistrictId(selectedList)
        this.setState({ formData1 })
    }

    getDistrictId = (selectedList) => {
        let district = []
        selectedList.length && selectedList.forEach((dist) => {
            district.push(dist.id)
        })
        return district
    }

    handleProfileInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData1;
        formData[name] = value;
        if (name === "job_type" && value === '2') {
            delete formData['location']
            delete formData['experience']
            delete formData['govtType']
            delete formData['techType']
        } 
        this.setState({ formData1: formData });
    }

    componentWillUnmount() {
        store.dispatch(setSearchResults([]))
    }

    clickLink = () => {
        window.location.href = '/search-results/?gender=1'

    }

    clickLinkForBoth = () => {
        window.location.href = '/private-jobs'
    }

    clickJobLink = (id) => {
        window.location.href = '/search-results/?job_type=' + id + '&gender=1'
    }

    getLocationFromIds = (location) => {
      
        let newLocation = this.parseLocation(location)
 
        const names = []
        if (this.props.actionReducer.District && this.props.actionReducer.District.length > 0 && newLocation && newLocation.length) {
            this.props.actionReducer.District.forEach((data) => {
                if (data.state_id === "3") {
                    newLocation.forEach(element => {
                        let filterVal = data.dist.filter((district) => district.id === element)
                        if (filterVal && filterVal[0]) {
                            names.push(filterVal[0].name)
                        }
                    });
                }
            })
        }
        return names.length ? names.toString() : ''
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    clicked = (job) => {
        this.props.router.push(`/job-details/${job.id}`)
    }

    clickRegister = () => {
        if (this.state.job && this.state.job.id) {
            store.dispatch(setRedirectionLink(`job-details/${this.state.job.id}`))
            this.props.router.push('/signup?type=1')
        }
    }

    clickLogin = () => {
        if (this.state.job && this.state.job.id) {
            store.dispatch(setRedirectionLink(`job-details/${this.state.job.id}`))
            this.props.router.push('/signin')
        }
    }

    render() {
        const Mainprops = this.props

        const { activePage, formData1 } = this.state
        let searchResults = ''
        if (Mainprops.JobseekerReducer.searchResults && Mainprops.JobseekerReducer.searchResults.length) {
            searchResults = chunk(Mainprops.JobseekerReducer.searchResults, RESULT_PER_PAGE)
        }

        const distData = this.createDistoption()

        const selectedDist = []
        if (this.state.formData1.location && this.state.formData1.location.length) {
            this.state.formData1.location.forEach((dist) => {
                let fileter = distData.filter((fil) => fil.id === dist)
                if (fileter && fileter.length) {
                    selectedDist.push(fileter[0])
                }
            })
        }

        return (
            <div>
                <div className="modal fade" id="descriptionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('counsellingProvider.details', { param: 'react' })}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.detailText ? this.state.detailText : ''}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">{this.props.t('counsellingProvider.close', { param: 'react' })}</button>
                                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade bd-example-modal-sm" tabIndex="-1" id="locationModal"
                    role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="mySmallModalLabel">{this.props.t('homeContent.locations', { param: 'react' })}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ul className="loc-lisiting">
                                    {this.state.locations && this.state.locations.length ? this.state.locations.map((loc, key) => {
                                        return (<li key={key}><span >{loc}</span></li>)
                                    })
                                        : ''}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="serviceModal" style={{ 'zIndex': '99999' }}>
                    <div className="modal-dialog modal-dialog-centered modal-lg" >
                        <div className="modal-content d-block">
                            <div className="modal-body pt-2  pb-1 text-center">
                                <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                <h5 className="mx-auto  text-uppercase mb-3">
                                    {this.props.t('homeContent.pgrkamTitle', { param: 'react' })}
                                </h5>
                                <div className="row d-flex">
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex ">
                                        <div className="first-click">
                                            <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                                            <p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst1', { param: 'react' })}.</p>
                                            <Link onClick={() => this.clickLogin()} data-dismiss="modal" style={{ "color": "#fff" }} className="btn btn-danger pt-1 pb-1" >
                                                {this.props.t('homeContent.clickToLogin', { param: 'react' })}</Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                        <div className="first-click">
                                            <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                                            <p className={"pl-2 pr-2"}>{this.props.t('homeContent.inst2', { param: 'react' })}.</p>
                                            <Link style={{ "color": "#fff" }} onClick={() => this.clickRegister()} data-dismiss="modal"
                                                className="btn btn-danger pt-1 pb-1">{this.props.t('homeContent.clickToRegister', { param: 'react' })}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {Mainprops.JobseekerReducer.searchResults && !Mainprops.JobseekerReducer.searchResults.length && !Mainprops.JobseekerReducer.searchResults.status &&
                    <Loader style={{ position: 'fixed', top: '49%', left: '48%' }}
                        type="Rings"
                        color="#F1911C"
                        height={100}
                        width={100}
                    />}

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12 p-0 mt-2">
                            {this.state.formData1.gender !== "" ? <>
                                <marquee scrolldelay="100" behavior="scroll" direction="left" ref={this.allJobs}>{this.props.t('homeContent.viewJobs', { param: 'react' })}
                                    <span onMouseLeave={(e) => this.allJobs.current.start()}
                                        onMouseEnter={(e) => this.allJobs.current.stop()} className="mr-4"
                                    > {this.props.t('homeContent.(Men And Women)', { param: 'react' })} <span onClick={this.clickLinkForBoth} className="date-clr" style={{ cursor: "pointer" }}>{this.props.t('homeContent.clickHere', { param: 'react' })}</span></span>
                                </marquee>

                            </> : null}
                            {!this.state.formData1.gender ? <form onSubmit={this.submitSearch}>
                                <div className="col-lg-12 col-xl-12 mb-1 p-0">

                                {formData1['pwd'] !== '1' &&
                                    <div className={"mx-auto d-block w-100"}>
                                        <h4 style={{ 'color': '#fff' }}>{this.props.t('banner.fill_the_form_below_to_search_job', { param: 'react' })}</h4>
                                        <div className="row m-0">

                                            <div className="col-lg p-0">
                                                <select onChange={this.handleProfileInput}
                                                    value={formData1.job_type ? formData1.job_type : ''}
                                                    name={"job_type"} className=" search-slt">
                                                    <option value={""}>{this.props.t('forms.jobType', { param: 'react' })}</option>
                                                    {this.props.actionReducer.Orgtype && this.props.actionReducer.Orgtype.length ?
                                                        this.props.actionReducer.Orgtype.map((org, key) => {
                                                            return (
                                                                <option value={org.id} key={key}>{org.name}</option>
                                                            )
                                                        }) : ''}
                                                </select>

                                            </div>
                                            {this.state.formData1.job_type === '2' && formData1['pwd'] !== '1' && 
                                                <div className="col-lg p-0">
                                                    <select
                                                        name="govtType"
                                                        className="search-slt"
                                                        value={this.state.formData1.govtType ? this.state.formData1.govtType : ''}
                                                        onChange={this.handleProfileInput}
                                                    >
                                                        <option value={""}>{this.props.t('homeContent.govtType', { param: 'react' })}</option>
                                                        <option value={"1"}>{this.props.t('homeContent.gop', { param: 'react' })}</option>
                                                        <option value={"2"}>{this.props.t('homeContent.gopOther', { param: 'react' })}</option>
                                                    </select>
                                                </div>}

                                            <div className="col-lg p-0">
                                                <select
                                                    onChange={this.handleProfileInput}
                                                    className="search-slt"
                                                    value={formData1.education_level ? formData1.education_level : ''}
                                                    name="education_level">
                                                    <option value={""}>{this.props.t('banner.select_qualification', { param: 'react' })}</option>
                                                    {this.props.actionReducer.Educations.length > 0 &&
                                                        this.props.actionReducer.Educations.map(function (data, index) {
                                                            return (
                                                                <option key={index} value={data.id}>{data.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>

                                            </div>
                                            {formData1.job_type !== '2' && formData1['pwd'] !== '1' &&
                                                <div className="col-lg p-0">
                                                    <select
                                                        name="experience"
                                                        className="search-slt"
                                                        onChange={this.handleProfileInput}
                                                        value={formData1.experience ? formData1.experience : ''}
                                                    >
                                                        <option value={""}>{this.props.t('forms.enterExp', { param: 'react' })}</option>
                                                        {this.props.actionReducer.exps && this.props.actionReducer.exps.length &&
                                                            this.props.actionReducer.exps.map((exp, key) => {
                                                                return (
                                                                    <option key={key} value={exp.id}>{exp.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>}

                                            {formData1.job_type !== '2' && formData1['pwd'] !== '1' &&
                                                <div className="col-lg p-0">
                                                    <Multiselect
                                                        ref={this.multiselectRef}
                                                        placeholder={this.props.t('JobPost.posting', { param: 'react' })}
                                                        showCheckbox={true}
                                                        selectionLimit={3}
                                                        caseSensitiveSearch={true}
                                                        options={distData} // Options to display in the dropdown
                                                        selectedValues={selectedDist && selectedDist.length ? selectedDist : []} // Preselected value to persist in dropdown
                                                        onSelect={this.onSelectChange} // Function will trigger on select event
                                                        onRemove={this.onSelectChange} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </div>}

                                        </div>
                                    </div> }
                                    
                                    {formData1['pwd'] !== '1' && <p className="m-0 text-center">-----{this.props.t('banner.or', { param: 'react' })}-----</p>}
                                    
                                    {formData1['pwd'] !== '1' &&
                                    <div className={"mx-auto d-block w-100"}>
                                        <div className="row m-0">
                                            <div className="col-lg p-0 test">
                                                <Autocomplete
                                                    className="w-100 form-control"
                                                    items={
                                                        this.props.forgienproviderReducer.jobTitles}
                                                    shouldItemRender={(item, value) =>
                                                        item.job_title.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                    renderItem={(item, isHighlighted) =>
                                                        <div key={item.id}
                                                            style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                                            {item.job_title}
                                                        </div>
                                                    }
                                                    renderInput={(props) => {
                                                        return <input className="search-slt" placeholder={this.props.t('JobPost.search_placeholder', { param: 'react' })} {...props} />
                                                    }}
                                                    wrapperStyle={{ "width": "100%" }}
                                                    menuStyle={{
                                                        "zIndex": "9",
                                                        "border": "1px solid #ccc",
                                                        "boxShadow": "1px 2px 10px #ccc",
                                                        "padding": "4px",
                                                        "fontSize": "14px"
                                                    }}
                                                    value={this.state.formData1.job_title ? this.state.formData1.job_title : ''}
                                                    onChange={(e) => this.setEducationJobTitle(e.target.value)}
                                                    getItemValue={(item) => item.job_title}
                                                    onSelect={(val) => this.setEducationJobTitle(val)}
                                                />

                                            </div>

                                        </div>

                                        </div> }
                                </div>
                                {formData1['pwd'] !== '1' && <div className="row">
                                    <div className="col-md-2 mx-auto">
                                        <button type="submit" className="btn text-white wrn-btn">
                                            {this.props.t('banner.search_job', { param: 'react' })}
                                        </button>
                                    </div>
                                </div> }
                            </form>
                                : null}
                        </div>
                    </div>
                </div>

                {Mainprops.JobseekerReducer.searchResults && Mainprops.JobseekerReducer.searchResults.length > 0
                    && <div className="tab-content">
                        <div id="home" className=" tab-pane active">
                            <section className="job-details mt-4">
                                <div className="container">
                                    <div className="row">

                                        <div className="col-md-12  mx-auto  tab-content  p-0">
                                            <div className="panel-heading">
                                                <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}<span className="pull-right" style={{ "color": "#fff" }}>{Mainprops.JobseekerReducer.searchResults.length ?
                                                    Mainprops.JobseekerReducer.searchResults.length : 0}{this.props.t('JobPost.found', { param: 'react' })}</span></h6>
                                            </div>
                                            <div className="panel-body">
                                                <div className="col-md-12 col-sm-12 col-lg-12 right-sidebar-scroll">
                                                    {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((job, key) => {

                                                        let locations = []
                                                        let locationsArray = []
                                                        let states = []
                                                        
                                                        if (!job.hasOwnProperty('apply_link') && this.parseLocation(job.location).length) {
                                                            const locationList = this.getLocationFromIds(job.location)
                                                            locationsArray = locationList.split(",")
                                                            locations = locationsArray.slice(0, 3)
                                                            locations = locations.toString()
                                                        }
                                                        if (!job.hasOwnProperty('apply_link') && job.state_id !== '' && !this.parseLocation(job.location).length) {
                                                            const locationList1 = this.getStates(job)
                                                            locationsArray = locationList1.split(",")
                                                            locations = locationsArray.slice(0, 3)
                                                            locations = locations.toString()
                                                            states = JSON.parse(job.state_id)
                                                        }

                                                        return (
                                                            <div className="right-sidebar" key={key}>

                                                                <div className="first-job mb-3 px-3 pt-3">
                                                                    <div className="row">
                                                                        <div className="col-md-9">

                                                                            <h4 className="company-name">
                                                                                <Link>{this.props.t('govtJobs.nameOfPost', { param: 'react' })}: {capitalizeFirstLetter(job.job_title)}</Link>
                                                                            </h4>
                                                                            <h6 className="pt-1 company-name2 pb-1 mb-0 "><Link>
                                                                                {this.props.t('govtJobs.nameOfEmployer', { param: 'react' })}: {capitalizeFirstLetter(job.org_name)}</Link></h6>
                                                                            <ul className="nav">

                                                                                {!job.hasOwnProperty('apply_link') && this.parseLocation(job.location).length ?
                                                                                    <li className="w-100 pb-2 pt-0 float-left"><Link >
                                                                                        <span><span className="float-left pt-2 pb-0  font-weight-bold">{this.props.t('govtJobs.placeOfPosting', { param: 'react' })}: </span>
                                                                                            <span className="float-left pt-2 pb-0 ml-1"> {locations} {this.parseLocation(job.location).length > 3 &&
                                                                                                <span style={{ "cursor": "pointer" }} className="date-clr" data-toggle="modal"
                                                                                                    data-target="#locationModal" onClick={() => this.setState({ locations: locationsArray })}>{this.props.t('homeContent.viewLocations', { param: 'react' })}</span>}
                                                                                            </span></span></Link></li>
                                                                                    : null}
                                                                                {!job.hasOwnProperty('apply_link') && job.state_id && !this.parseLocation(job.location).length ?
                                                                                    <li className="w-100 pb-2 pt-0 float-left"><Link >
                                                                                        <span><span className="float-left pt-2 pb-0  font-weight-bold">{this.props.t('govtJobs.placeOfPosting', { param: 'react' })}: </span>
                                                                                            <span className="float-left pt-2 pb-0 ml-1"> {locations} {states.length > 3 &&
                                                                                                <span style={{ "cursor": "pointer" }} className="date-clr" data-toggle="modal"
                                                                                                    data-target="#locationModal" onClick={() => this.setState({ locations: locationsArray })}>{this.props.t('homeContent.viewLocations', { param: 'react' })}</span>}
                                                                                            </span></span></Link></li>
                                                                                    : null}

                                                                                {job.hasOwnProperty('apply_link') && job.location &&
                                                                                    <li className="w-100 p-0 float-left pt-1"><Link>
                                                                                        <span><span className="float-left p-0 font-weight-bold">{this.props.t('govtJobs.placeOfPosting', { param: 'react' })}: &nbsp;</span> {capitalizeFirstLetter(job.location)}</span></Link></li>
                                                                                }

                                                                            </ul>

                                                                            {/* {job.hasOwnProperty('apply_link') && <p className="w-100 pull-left"><span className=" float-left p-0  font-weight-bold">{this.props.t('govtJobs.reqQual', { param: 'react' })}:&nbsp;</span>{job.qualification ? capitalizeFirstLetter(job.qualification) : ''}</p>} */}
                                                                            {job.hasOwnProperty('apply_link') && <p className="w-100 pull-left"><span className=" float-left p-0  font-weight-bold">{this.props.t('govtJobs.reqQual', { param: 'react' })}:&nbsp;</span>{job.qualification ? job.qualification.substring(0, 40) : ''}
                                                                            {job.qualification.length > 40 ? <Link data-target="#descriptionModal" data-toggle="modal" style={{ "cursor": "pointer" }} onClick={() => this.setState({ detailText: job.qualification })} className="date-clr">{this.props.t('homeContent.viewLocations', { param: 'react' })}</Link> : null}</p>}
                                                                            {!job.hasOwnProperty('apply_link') && <p className="w-100 pull-left"><span className="float-left p-0  font-weight-bold">{this.props.t('govtJobs.reqQual', { param: 'react' })}:&nbsp;</span>{getQualification(job)}</p>}
                                                                            {!job.hasOwnProperty('apply_link') && <p className="w-100 pull-left"><span className="float-left p-0  font-weight-bold">
                                                                                {this.props.t('dashboard.table.salary', { param: 'react' })}:&nbsp;</span> {job.salary_min && job.salary_max ? job.salary_min + '-' + job.salary_max : job.salary_min} </p>}
                                                                            {job.salary && job.hasOwnProperty('apply_link') && <span className="pr-5"><i className="fa fa-inr pr-2"
                                                                                aria-hidden="true"></i> <span>{job.salary}</span></span>}

                                                                            <div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 pl-0">
                                                                            {!job.hasOwnProperty('apply_link') && <div className="text-left">
                                                                                <div className=""></div>
                                                                                <div className="text-right">
                                                                                    {
                                                                                        this.props.actionReducer.Loginuser
                                                                                            && this.props.actionReducer.Loginuser.user_type &&
                                                                                            this.props.actionReducer.Loginuser.user_type === '1'
                                                                                            ?
                                                                                            (<button
                                                                                                className="btn btn-primary btn-primary2 mt-2 ml-auto btn-center"
                                                                                                onClick={() => this.clicked(job)}> {this.props.t('jobSearch.apply', { param: 'react' })}
                                                                                            </button>

                                                                                            ) : null
                                                                                    }
                                                                                    {
                                                                                        !Object.keys(this.props.actionReducer.Loginuser).length ?
                                                                                            (<button
                                                                                                data-target="#serviceModal"
                                                                                                data-toggle="modal"
                                                                                                onClick={() => this.setState({ job })}
                                                                                                className="btn btn-primary btn-primary2 mt-2 ml-auto btn-center"
                                                                                            > {this.props.t('jobSearch.apply', { param: 'react' })}	</button>)
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    <Link className="date-clr" to={`/job-details-home/${job.id}`}>
                                                                                        {this.props.t('jobSearch.viewDetails', { param: 'react' })}
                                                                                    </Link>
                                                                                </div></div>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                        <div className="col-md-3 mb-1"><span className=" date-clr">{this.props.t('govtJobs.vacancy', { param: 'react' })}: <span>{job.vacancies} </span></span></div>
                                                                        {job.hasOwnProperty('apply_link') &&
                                                                            <div className="col-md-3 mb-1">
                                                                                <span className=" date-clr">{this.props.t('counsellingProvider.expire_on', { param: 'react' })}:
                                                                             <span>{job.expiring_on} </span></span></div>}
                                                                        {!job.hasOwnProperty('apply_link') && <div className="col-md-3 mb-1">
                                                                            <span className=" date-clr">{this.props.t('homeContent.minAge', { param: 'react' })}: <span>
                                                                                {job.age_preference ? job.age_preference : ''}
                                                                            </span></span></div>
                                                                        }
                                                                        {job.hasOwnProperty('apply_link') && <div className="col-md-3 mb-1"><span className=" date-clr">{this.props.t('govtJobs.age', { param: 'react' })}: <span>
                                                                            {job.max_age ? job.max_age : ''}
                                                                        </span></span></div>}
                                                                        {!job.hasOwnProperty('apply_link') &&
                                                                            <div className="col-md-3 mb-1"><span className=" date-clr">{this.props.t('jobSearch.exp', { param: 'react' })}: <span>
                                                                                {this.getExperienceToShow(job)} </span></span></div>
                                                                        }
                                                                        {job.hasOwnProperty('apply_link') &&
                                                                            <div className="col-md-3 mb-1"><span className=" date-clr">{this.props.t('jobSearch.exp', { param: 'react' })}: <span>
                                                                                {job.experience ? job.experience.substring(0, 12) : null} {job.experience.length > 12 ? <Link data-target="#descriptionModal" data-toggle="modal" style={{ "cursor": "pointer" }} onClick={() => this.setState({ detailText: job.experience })} className="date-clr">{this.props.t('homeContent.viewLocations', { param: 'react' })}</Link> : null}
                                                                            </span></span></div>
                                                                        }

                                                                        <div className="col-md-3 mb-1">{job.gender_preference && <span className=" date-clr">
                                                                            {this.props.t('govtJobs.gender', { param: 'react' })}:

                                                                            <span>{" " + job.gender_preference} </span></span>}
                                                                        </div>

                                                                        {job.hasOwnProperty('apply_link') && <>
                                                                            <div className="col-md-3 mb-1"><span><span className="date-clr">{this.props.t('govtJobs.applyHere', { param: 'react' })}:   </span><a rel="noopener noreferrer" className="mr-4 mb-2" target="_blank" href={job.apply_link}>{this.props.t('govtJobs.clickHere', { param: 'react' })}</a></span></div>
                                                                            <div className="col-md-3 mb-1"><span><span className="date-clr">{this.props.t('govtJobs.readNotification', { param: 'react' })}:  </span><a rel="noopener noreferrer" className="ml-2 mb-2" target="_blank" href={job.pdf_link}>{this.props.t('govtJobs.clickHere', { param: 'react' })}</a></span></div>
                                                                            <div className="col-md-3 mb-1"><span className="date-clr">{this.props.t('homeContent.whereToapply', { param: 'react' })}: <span>{capitalizeFirstLetter(job.apply_mode)} </span></span></div>
                                                                        </>}
                                                                    </div>
                                                                    <div className="row bg-color">
                                                                        <div className="col-md-12 d-flex align-items-center justify-content-between flex-wrap">
                                                                            <span style={{ color: "#fff" }}>
                                                                                {this.props.t(!job.hasOwnProperty('apply_link') ? 'homeContent.privateOrganization' : 'homeContent.publicSectorVacancy', { param: 'react' })}
                                                                            </span>
                                                                            <span className="text-right pull-right">
                                                                                {!job.hasOwnProperty('apply_link') && <span style={{ color: "#fff" }} className="mr-3">{this.props.t('govtJobs.postedBy', { param: 'react' })}: {job.postedBy ? job.postedBy : "Employer"}</span>}
                                                                                {job.hasOwnProperty('apply_link') && <span style={{ color: "#fff" }} className="mr-3">{this.props.t('govtJobs.postedBy', { param: 'react' })}: {job.postedBy ? job.postedBy : "Admin"}</span>}
                                                                                <span style={{ color: "#fff" }}>{this.props.t('homeContent.postedOn', { param: 'react' })} {job.created_at}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    {searchResults && searchResults.length > 1 ?
                                                        <div className='react-pagination'>
                                                            <Pagination
                                                                pageRangeDisplayed={10}
                                                                activePage={activePage}
                                                                itemsCountPerPage={RESULT_PER_PAGE}
                                                                totalItemsCount={Mainprops.JobseekerReducer.searchResults.length}
                                                                onChange={this.handlePageChange}
                                                            />
                                                        </div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>}
                {Mainprops.JobseekerReducer.searchResults && Mainprops.JobseekerReducer.searchResults.status === '401' &&
                    <div className="tab-content">
                        <div id="home" className=" tab-pane active">
                            <section className="login-form mt-2">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12  mx-auto   p-0">
                                            <div className="panel-heading">
                                                <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}<span className="pull-right" style={{ "color": "#fff" }}>{Mainprops.JobseekerReducer.searchResults.length ?
                                                    Mainprops.JobseekerReducer.searchResults.length : 0}{this.props.t('JobPost.found', { param: 'react' })}</span></h6>
                                            </div>
                                            <div className="panel-body">
                                                <h3>{Mainprops.JobseekerReducer.searchResults.message ? Mainprops.JobseekerReducer.searchResults.message : 'No results found'}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(SearchResults));
