import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import {
    getProfile,getIndividualRequest,updateProfile, removeProfileData, updateEducation, getUserEducation, deleteEducation, getAreaOfInterest
} from '../../../actions/counsellingproviderAction';
import { get_User_District, getEducationLevel, get_User_Institution, get_genders, get_User_Language } from '../../../actions/accountAction';
import { getJobProviderAddress } from '../../../actions/employerAction'
import store from '../../../store';
import Sidebar from './Sidebar';
import Validation from "../../hoc/Validation";
import "react-datepicker/dist/react-datepicker.css";
import SimpleReactValidator from 'simple-react-validator';
import Autocomplete from 'react-autocomplete'
import ServerSetting from '../../../config.json';
import MultiSelect from "@kenshooui/react-multi-select";
import { Link } from 'react-router'

class SkillProviderProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Profile: [],
            Education: [],
            profileError: '',
            educationError: '',
            submitted1: true,
            submitted2: true,
            submitted3: true,
            imgSrc: '',
            submitted: false,
            delIndex: '',
            errorCertificate: '',
            edit: false,
            selectedItems: [],
            months: [],
            initialLoad: false
        }
        store.dispatch(get_User_District());
        store.dispatch(getProfile());
        store.dispatch(getIndividualRequest());
        store.dispatch(getJobProviderAddress());
        store.dispatch(getEducationLevel());
        store.dispatch(get_User_Institution());
        store.dispatch(get_genders());
        store.dispatch(get_User_Language());
        store.dispatch(getUserEducation());
        store.dispatch(getAreaOfInterest())

        this.imageControl1 = React.createRef()
        this.imageControl2 = React.createRef()
        this.institue = React.createRef()

        this.allowedDocFormats = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ]

        this.addressValidator = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });

        this.validator3 = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });
        this.eduValidator = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });

        this.imageFormats = [
            'image/png',
            'image/jpg',
            'image/jpeg'
        ]
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const newState = { ...nextProps.EmployerReducer.address[0], ...nextProps.counsellingproviderReducer.Profile }
        const Education = this.state.Education
        if (nextProps.counsellingproviderReducer.Profile.education_level && !this.state.initialLoad) {
            console.log('nextProps.counsellingproviderReducer.Profile.education_level', nextProps.counsellingproviderReducer.Profile.education_level)
        
            Education.highest_education_level = nextProps.counsellingproviderReducer.Profile.education_level
            const selectedItems = this.getMonthsListFromIds(nextProps.counsellingproviderReducer.Profile.area_of_interest)
            this.setState({ Education, selectedItems, initialLoad: true });
        }
        this.setState({ Profile: newState });
    }

    async componentDidMount() {
        document.title = this.props.t('counsellingProvider.profile', { param: 'react' });
       
    }

    handleProfileInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;
        if (type === 'text') {
            let regex = /^[A-Za-z0-9 ]+$/
            if (!regex.test(value) && value) {
                return false
            }
        }
        let Profile = this.state.Profile;
        Profile[name] = value;
        this.setState({ Profile, submitted1: false });
    }

    handleProfileAddress(e) {
        const name = e.target.name;
        let value = e.target.value;
        let Profile = this.state.Profile;
        Profile[name] = value;
        this.setState({ Profile, submitted2: false });
    }

    years = () => {
        const yearList = []
        for (let i = 1980; i <= new Date().getFullYear(); i++) {
            yearList.push(<option key={i} value={i}>{i}</option>)
        }
        return yearList.reverse()
    }

    handleProfileExperince(e) {
        const name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;
        if (type === 'text' || type === 'textarea') {
            let regex = /^[A-Za-z0-9 ]+$/
            if (!regex.test(value) && value) {
                return false
            }
        }
        let Profile = this.state.Profile;
        if (name === 'education_level') {
            Profile['qualification'] = ''
        }
        if (name === "university_name") {
            this.validator3.purgeFields()
        }
        Profile[name] = value;
        this.setState({ Profile, submitted3: false });
    }

    handleSubmitProfile(event) {
        event.preventDefault()
        // const area_of_interest = this.state.months
        if (this.props.validator.allValid() && !this.state.submitted1) {
            store.dispatch(updateProfile({ ...this.state.Profile, area_of_interest: this.state.months }));

            this.imageControl1.current.value = ""
            this.imageControl2.current.value = ""
            this.props.validator.hideMessages();
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.setState({ submitted1: true, imgSrc: '' })
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    removeProfileImage = () => {
        store.dispatch(removeProfileData('profile_image'));
    }

    removeEducationCertificate = () => {
        store.dispatch(removeProfileData('education_certificate'));
    }

    removeLicense = (key) => {
        store.dispatch(removeProfileData(key));
    }

    getDocName = (doc) => {
        if (doc) {
            const index = doc.lastIndexOf("/")
            return doc.substr(index + 1)
        }
    }

    handleSubmitExperience(event) {
        event.preventDefault()
        if (this.validator3.allValid() && !this.state.submitted3) {
            store.dispatch(updateProfile(this.state.Profile));
            this.setState({ submitted3: true })
            this.props.validator.hideMessages();
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.validator3.showMessages();
            this.forceUpdate();
        }
    }


    async handleEditEducation(index) {
        this.institue.current.value = ""
        let Education = this.props.counsellingproviderReducer.educations[index]
        Education['doc'] = Education.img
        await this.setState({ Education, submitted: false, edit: true });
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }



    handleSubmitAddress(event) {
        event.preventDefault()
        const Profile = this.state.Profile
        Profile['address_subtype_id'] = '1'
        this.setState({ Profile })

        if (this.addressValidator.allValid() && !this.state.submitted2) {
            store.dispatch(updateProfile(this.state.Profile));
            this.setState({ submitted2: true })
            this.addressValidator.hideMessages();
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.addressValidator.showMessages();
            this.forceUpdate();
        }
    }


    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map(function (data) {
                if (data.state_id === '3') {
                    data.dist.map(function (distdata) {
                        Distoption.push(distdata)
                        return Distoption
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }

    handleEducationInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;
        if (name === 'score_type') {
            this.eduValidator.purgeFields()
        }
        if (type === 'text') {
            let regex = /^[A-Za-z0-9 ]+$/
            if (!regex.test(value) && value) {
                return false
            }
        }

        if (e.target.type === 'number') {
            if (value.startsWith('0')) {
                value = ''
                e.target.value = ''
            }
            if (value.length > 2 && value !== '100') {
                const newVal = value.substr(0, 2)
                e.target.value = newVal
                value = newVal
            }
        }
        let Education = this.state.Education;
        Education[name] = value;
        this.setState({ Education, submitted: false });
    }

    setEducationLevel = (data, val, key, valKey) => {
        this.eduValidator.purgeFields()
        const Education = this.state.Education
        const MatchEdu = data && data.filter((edu) => edu.name === val)
        const value = MatchEdu && MatchEdu.length ? MatchEdu[0].id : ""
        Education[key] = value
        Education[valKey] = val
        this.setState({ Education, submitted: false })
    }

    getMonthsListFromIds = (months) => {
        const list = []
        months && months.forEach((m) => {
            const filterMonth = this.props.counsellingproviderReducer.area_of_interest.filter((mon) => parseInt(mon.id) === m)
            if (filterMonth && filterMonth.length) {
                list.push({ id: filterMonth[0].id, label: filterMonth[0].name })
            }
        })
        return list
    }

    handleChangeMonths = (value) => {
        let months = []
        value.forEach((item => {
            months.push(item.id)
        }))
        months = months.length ? months : []
        this.setState({ selectedItems: value, months, submitted1: false })
    }

    handleUserInput = e => {
        const Profile = this.state.Profile
        const name = e.target.name
        const checked = e.target.checked ? 1 : 0
        Profile[name] = checked
        this.setState({ Profile, submitted1: false })
    }

    setCourses = (data, val, key, valKey) => {
        this.eduValidator.purgeFields()
        const Education = this.state.Education
        const index = data.findIndex((d) => d.education_id === this.state.Education.highest_education_level)
        if (index !== -1) {
            const MatchEdu = data[index].qualification.filter((edu) => edu.name === val)
            const value = MatchEdu && MatchEdu.length ? MatchEdu[0].id : ""
            Education[key] = val === 'Others' ? '152' : value
            Education[valKey] = val
            this.setState({ Education, submitted: false })
        }
    }

    async handleSubmitEducation() {
        if (!this.state.submitted && this.eduValidator.allValid()) {
            store.dispatch(updateEducation({ ...this.state.Education }));
            this.eduValidator.hideMessages()
            this.institue.current.value = ""
            this.setState({ Education: [], submitted: true, edit: false })
        }
        else {
            this.eduValidator.showMessages();
            this.forceUpdate();
        }
    }

    getValue = field => {
        // if(this.state.Profile[field.fieldName])
        // console.log('this.state.Profile[field]', this.state.Profile[field])
        return this.state.Profile[field] ? true : false
    }

    deleteRecord = () => {
        const { delIndex } = this.state
        if (delIndex !== "") {
            this.handleDeleteEducation(delIndex)
            document.getElementById("exampleModal").click()
        }
    }

    handleDeleteEducation(index) {
        store.dispatch(deleteEducation({ id: this.props.counsellingproviderReducer.educations[index].id }));
        this.setState({ delIndex: '' })
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    handleProfileImage(e, key, name) {
        e.persist();
        const files = e.target.files

        const formatIndex = this.imageFormats.findIndex(format => format === files[0].type)
        const Profile = this.state.Profile
        if (formatIndex !== -1) {
            const fileSize = Math.round((files[0].size / 1024));
            if (fileSize > 1024) {
                this.setState({ [key]: 'Image size is too large' })
                setTimeout(() => this.setState({ [key]: '' }), 3000)
                e.target.value = null;
            } else {
                let file = files[0];
                let reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onloadend = function (e) {
                    this.setState({
                        imgSrc: [reader.result]
                    })
                }.bind(this);
                Profile[name] = files[0]
                this.setState({ Profile, submitted1: false })
            }
        } else {
            this.setState({ [key]: 'Only .jpg, .jpeg and .png formats are allowed' })
            setTimeout(() => this.setState({ [key]: '' }), 3000)
            e.target.value = null;
        }
    }

    handleDocs(e, key, name, submit) {
        e.persist();
        //Doc size 1mb allowed.
        const files = e.target.files
        const formatIndex = this.allowedDocFormats.findIndex(format => format === files[0].type)
        const Profile = this.state.Profile
        if (formatIndex !== -1) {
            const fileSize = Math.round((files[0].size / 1024));
            if (fileSize > 1024) {
                this.setState({ [key]: 'File size is too large' })
                setTimeout(() => this.setState({ [key]: '' }), 3000)
                e.target.value = null;
            } else {
                if (key === "errorCertificate") {
                    const Education = this.state.Education
                    Education[name] = files[0]
                    this.setState({ Education, [submit]: false })
                } else {
                    Profile[name] = files[0]
                    this.setState({ Profile, [submit]: false })
                }
            }
        } else {
            this.setState({ [key]: 'Only doc, docx and pdf files are allowed' })
            setTimeout(() => this.setState({ [key]: '' }), 3000)
            e.target.value = null;
        }
    }

    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const { validator } = this.props
        const eduValidator = this.eduValidator
        let Mainprops = this.props;
        const data = []
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        let Mainthis = this;
        let Mainstate = this.state
        let validator3 = this.validator3
        let Profile = this.state.Profile
        const educationLevel = this.props.actionReducer.educationLevel
        const filterEdu = []
        const arrayDis = ["7", "8", "9", "12"]
        educationLevel.forEach((edu) => {
            if (arrayDis.includes(edu.education_id)) {
                filterEdu.push(edu)
            }

        });
        if (Profile.education_level) {
            const selectedEducation = filterEdu.filter((edu) => edu.education_id === Profile.education_level)
            if (selectedEducation && selectedEducation[0]) {
                selectedEducation && selectedEducation[0] && selectedEducation[0].qualification.forEach((qual) => {
                    data.push({ label: qual.name, id: qual.id })
                })
            }
        }

        let courses = []
        if (Mainstate.Education.highest_education_level) {
            const selectedEducation = educationLevel.filter((edu) => edu.education_id === Mainstate.Education.highest_education_level)
            if (selectedEducation && selectedEducation[0]) {
                selectedEducation && selectedEducation[0] && selectedEducation[0].qualification.forEach((qual) => {
                    courses.push({ label: qual.name, id: qual.id })
                })
            }
        }

        courses.push({ label: "Others", id: "152" })

        const distData = this.createDistoption()
        const items = []
        if (this.props.counsellingproviderReducer.area_of_interest && this.props.counsellingproviderReducer.area_of_interest.length) {
            this.props.counsellingproviderReducer.area_of_interest.forEach(item => {
                items.push({ label: item.name, id: item.id })
            })
        }

        const fieldsToDisplay = [{ fieldName: 'show_qual', label: 'Qualification' },
        { fieldName: 'show_mobile', label: 'Mobile' },
        { fieldName: 'show_email', label: 'Email' },
        { fieldName: 'show_address', label: 'Address' }]

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                            </Link>
                        <Sidebar route={Mainprops.route} />
                        {Mainprops.counsellingproviderReducer.Profile.hasOwnProperty('mobile') &&
                            <main className="col second-side p-0 pb-3 h-100">

                                <div className="clearfix"></div>
                                <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h6 className="modal-title" id="exampleModalLabel">Are sure you want to delete?</h6>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary btn-primary2" onClick={() => this.deleteRecord()}>Ok</button>
                                                <button type="button" className="btn btn-primary btn-primary2" data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row m-0 bg-white">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li data-placement="bottom"

                                            className="nav-item">
                                            <a className={'nav-link mr-0 active show'} data-toggle="tab" id="menuTab1"
                                                href="#home">{this.props.t('sidebar.profile_details', { param: 'react' })}</a>
                                        </li>

                                        <li
                                            data-placement="bottom"

                                            className="nav-item">
                                            <a className="nav-link mr-0" data-toggle="tab" id="menuTab2"
                                                href="#menu2">{this.props.t('counsellingProvider.address', { param: 'react' })}</a>
                                        </li>
                                        <li
                                            data-placement="bottom"

                                            className="nav-item">
                                            <a className="nav-link mr-0" data-toggle="tab" id="menuTab3"
                                                href="#menu3">{this.props.t('jobSearch.exp', { param: 'react' })}</a>
                                        </li>
                                        <li
                                            data-placement="bottom"

                                            className="nav-item">
                                            <a className="nav-link mr-0" data-toggle="tab" id="menuTab4"
                                                href="#edu">{this.props.t('education.education', { param: 'react' })}</a>
                                        </li>


                                    </ul>
                                </div>

                                <div className="clearfix"></div>
                                <div className="tab-content profile-page">
                                    <div id="home" className="tab-pane bg-education active pt-4">
                                        <form onSubmit={(event) => this.handleSubmitProfile(event)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h6 className="mb-0 mt-2">{this.props.t('sidebar.profile_details', { param: 'react' })}</h6>
                                                    {this.props.counsellingproviderReducer.Profile &&
                                                        !this.props.counsellingproviderReducer.Profile.profile_image &&
                                                        !this.props.counsellingproviderReducer.Profile.education_certificate &&
                                                        !this.props.counsellingproviderReducer.Profile.license ?
                                                        <div className="mt-2" style={{ "background": "#f7900d" }}>
                                                            <span className="pl-2" style={{ color: "#fff" }}>
                                                                {this.props.t('profileCompletion.inst1', { param: 'react' })}
                                                            </span> </div>

                                                        : null}

                                                    {this.props.counsellingproviderReducer.Profile &&
                                                        this.props.counsellingproviderReducer.Profile.profile_image &&
                                                        this.props.counsellingproviderReducer.Profile.education_certificate &&
                                                        this.props.counsellingproviderReducer.Profile.license &&
                                                        this.props.counsellingproviderReducer.Profile.profileCompleted === '0' ?
                                                        <div className="mt-2" style={{ "background": "#f7900d" }}>
                                                            <span className="pl-2" style={{ color: "#fff" }}>
                                                                {this.props.t('profileCompletion.inst2', { param: 'react' })}
                                                            </span>
                                                        </div>
                                                        : null}
                                                </div>


                                            </div> <hr></hr>
                                            <div className="row form-group">
                                                <div className="col-sm-6 col-lg-5 text-right">
                                                    <label>{this.props.t('profileDetails.fname', { param: 'react' })} :</label><span
                                                        style={{ color: 'red' }} className="ml-2">*</span>
                                                </div>
                                                <div className="col-sm-6 col-lg-5">
                                                    <input type="text" className="form-control"
                                                        onChange={(event) => Mainthis.handleProfileInput(event)}
                                                        value={this.state.Profile.firstname ? this.state.Profile.firstname : ''}
                                                        name="firstname"
                                                        placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('profileDetails.fname', { param: 'react' })}
                                                    />
                                                    {validator.message('firstname', this.state.Profile.firstname, 'required')}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('profileDetails.lname', { param: 'react' })} :</label>
                                                        <span
                                                            style={{ color: 'red' }}
                                                            className="ml-2">*</span>
                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        <input type="text" className="form-control"
                                                            onChange={(event) => Mainthis.handleProfileInput(event)}
                                                            value={this.state.Profile.lastname ? this.state.Profile.lastname : ''}
                                                            name="lastname"
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('profileDetails.lname', { param: 'react' })}
                                                        />
                                                        {validator.message('lastname', this.state.Profile.lastname, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0 pb-4">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('counsellingProvider.img_upload', { param: 'react' })} : <span
                                                            style={{ color: 'red' }} className="ml-2">*</span></label>

                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        {this.state.imgSrc ? <img alt=""
                                                            src={this.state.imgSrc}
                                                            className="img-fluid profile-img mb-2" /> :
                                                            <img alt=""
                                                                src={this.props.counsellingproviderReducer.Profile.profile_image ? `${SERVER_PATH}${this.props.counsellingproviderReducer.Profile.profile_image}` : 'images/img-1.png'}
                                                                className="img-fluid profile-img mb-2" />}
                                                        <input name="profile_image" accept={this.imageFormats.toString()}
                                                            type="file"
                                                            ref={this.imageControl1}
                                                            onChange={(e) => Mainthis.handleProfileImage(e, 'profileError', 'profile_image')} />

                                                        {validator.message('profile image', this.state.Profile.profile_image, 'required')}
                                                        {this.state.profileError && <p style={{
                                                            "color": "red",
                                                        }}>{this.state.profileError}</p>}
                                                        <span className={'edit-img d-block'}>
                                                            {this.props.t('profileDetails.uploadInst', { param: 'react' })} </span>
                                                        {this.props.counsellingproviderReducer.Profile.profile_image ? <Link style={{ cursor: "pointer" }}
                                                            onClick={this.removeProfileImage} className={'date-clr'}>
                                                            {this.props.t('sidebar.remove', { param: 'react' })} </Link> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('profileDetails.mobile', { param: 'react' })} :</label>
                                                    </div>

                                                    <div className=" col-sm-6 col-lg-5">
                                                        <input
                                                            disabled={true}
                                                            type="text"
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('profileDetails.mobile', { param: 'react' })}
                                                            value={this.state.Profile.mobile ? this.state.Profile.mobile : ''}
                                                            className="form-control"
                                                            name="mobile" />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('profileDetails.gender', { param: 'react' })} :</label>

                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        {Mainprops.actionReducer.Genders.length > 0 &&
                                                            Mainprops.actionReducer.Genders.map((data, index) => {
                                                                return (
                                                                    <div className="form-check-inline" key={index}>
                                                                        <label className="form-check-label" htmlFor="radio1">
                                                                            <input type="radio" onChange={(event) => this.handleProfileInput(event)}
                                                                                className="form-check-input" id={data.name} name="gender" value={data.id}
                                                                                checked={parseInt(this.state.Profile.gender) === parseInt(data.id)} />{data.name}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {validator.message('gender', this.state.Profile.gender, 'required')}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('profileDetails.email', { param: 'react' })} :</label>

                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        <input type="email" disabled={true} placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('profileDetails.email', { param: 'react' })}
                                                            value={this.state.Profile.email ? this.state.Profile.email : ''}
                                                            className="form-control" name="email" />
                                                    </div>
                                                </div>
                                            </div>

                                           
    {/* <div className="row"> */}
        <div className="form-group">
            <div className="row">
            <div className="col-sm-12 col-lg-10 text-right">
                <label>Availablity of individual counselling session(<spam style={{ "color": "#f7900d" }}>Default time -  9 am to 5 pm</spam>) </label>
            </div>
                <div
                    className="col-sm-1 col-lg-3 text-center" style={{marginLeft:"30%"}}>
                    <label>From </label>
                </div>
                <div
                    className="col-sm-1 col-lg-3 text-right">
                    <label>To </label>
                </div>
                <div
                    className="col-sm-1 col-lg-3 form-group" style={{marginLeft:"32%"}}>
                    <input onChange={event => this.handleProfileInput(event)}
                        type="time"
                        placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.timing_from', { param: 'react' })}
                        value={Profile.time_slot ? Profile.time_slot : ''}
                        className="form-control"
                        name="time_slot" />
                    <span className="date-clr" style={{ "fontSize": "12px" }}>
                        {this.props.t('JobPost.timeInst', { param: 'react' })}
                    </span>
                    {/* {validator.message('timing from', Profile.timing_from, 'required')} */}
                </div>
                <div
                    className="col-sm-1 col-lg-3 form-group">
                    <input onChange={event => this.handleProfileInput(event)}
                        type="time"
                        placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.timing_to', { param: 'react' })}
                        value={Profile.time_slot2 ? Profile.time_slot2 : ''}
                        className="form-control"
                        name="time_slot2" />
                    <span className="date-clr" style={{ "fontSize": "12px" }}>
                        {this.props.t('JobPost.timeInst', { param: 'react' })}
                    </span>
                    {/* {validator.message('timing to', Profile.timing_to, 'required')} */}

                </div>
            </div>
        </div>
        

    {/* </div></> */}
                                           

                                            <div className="form-group mb-0 pb-4">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('JobPost.doc_verification', { param: 'react' })} 1: <span
                                                            style={{ color: 'red' }} className="ml-2">*</span></label>
                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">

                                                        <input name="license" accept={this.allowedDocFormats.toString()}
                                                            type="file"
                                                            className="image-control"
                                                            ref={this.imageControl2}
                                                            onChange={(e) => Mainthis.handleDocs(e, 'licenseError', 'license', "submitted1")} />

                                                        {validator.message('proof 1', this.state.Profile.license, 'required')}
                                                        {this.state.licenseError && <p style={{
                                                            "color": "red",
                                                        }}>{this.state.licenseError}</p>}
                                                        {this.props.counsellingproviderReducer.Profile.license &&
                                                            <a target="_blank"
                                                                rel="noopener noreferrer" className="doc-up"
                                                                href={`${SERVER_PATH}${this.props.counsellingproviderReducer.Profile.license}`}
                                                                style={{ "cursor": "pointer" }}><i
                                                                    style={{ "marginRight": "6px" }}
                                                                    className="fa fa-file-text"
                                                                    aria-hidden="true"></i>{this.getDocName(this.props.counsellingproviderReducer.Profile.license)}</a>}
                                                        <span className={'edit-img d-block'}>
                                                            {this.props.t('profileDetails.docsInst', { param: 'react' })} </span>
                                                        {this.props.counsellingproviderReducer.Profile.license ? <Link style={{ cursor: "pointer" }}
                                                            onClick={() => this.removeLicense('license')} className={'date-clr'}>
                                                            {this.props.t('sidebar.remove', { param: 'react' })} </Link> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0 pb-4">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('JobPost.doc_verification', { param: 'react' })} 2: </label>
                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        <input name="license1" accept={this.allowedDocFormats.toString()}
                                                            type="file"
                                                            className="image-control"
                                                            ref={this.imageControl2}
                                                            onChange={(e) => Mainthis.handleDocs(e, 'licenseError1', 'license1', "submitted1")} />


                                                        {this.state.licenseError1 && <p style={{
                                                            "color": "red",
                                                        }}>{this.state.licenseError1}</p>}
                                                        {this.props.counsellingproviderReducer.Profile.license1 &&
                                                            <a target="_blank"
                                                                rel="noopener noreferrer" className="doc-up"
                                                                href={`${SERVER_PATH}${this.props.counsellingproviderReducer.Profile.license1}`}
                                                                style={{ "cursor": "pointer" }}><i
                                                                    style={{ "marginRight": "6px" }}
                                                                    className="fa fa-file-text"
                                                                    aria-hidden="true"></i>{this.getDocName(this.props.counsellingproviderReducer.Profile.license1)}</a>}
                                                        <span className={'edit-img d-block'}>
                                                            {this.props.t('profileDetails.docsInst', { param: 'react' })} </span>
                                                        {this.props.counsellingproviderReducer.Profile.license1 ? <Link style={{ cursor: "pointer" }}
                                                            onClick={() => this.removeLicense('license1')} className={'date-clr'}>
                                                            {this.props.t('sidebar.remove', { param: 'react' })} </Link> : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mb-0 pb-4">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('JobPost.doc_verification', { param: 'react' })} 3:</label>
                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        <input name="license2" accept={this.allowedDocFormats.toString()}
                                                            type="file"
                                                            className="image-control"
                                                            ref={this.imageControl2}
                                                            onChange={(e) => Mainthis.handleDocs(e, 'licenseError2', 'license2', "submitted1")} />


                                                        {this.state.licenseError2 && <p style={{
                                                            "color": "red",
                                                        }}>{this.state.licenseError2}</p>}
                                                        {this.props.counsellingproviderReducer.Profile.license2 &&
                                                            <a target="_blank"
                                                                rel="noopener noreferrer" className="doc-up"
                                                                href={`${SERVER_PATH}${this.props.counsellingproviderReducer.Profile.license2}`}
                                                                style={{ "cursor": "pointer" }}><i
                                                                    style={{ "marginRight": "6px" }}
                                                                    className="fa fa-file-text"
                                                                    aria-hidden="true"></i>{this.getDocName(this.props.counsellingproviderReducer.Profile.license2)}</a>}
                                                        <span className={'edit-img d-block'}>
                                                            {this.props.t('profileDetails.docsInst', { param: 'react' })} </span>
                                                        {this.props.counsellingproviderReducer.Profile.license2 ? <Link style={{ cursor: "pointer" }}
                                                            onClick={() => this.removeLicense('license2')} className={'date-clr'}>
                                                            {this.props.t('sidebar.remove', { param: 'react' })} </Link> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0 pb-4">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('counsellingProvider.areaOfInterest', { param: 'react' })} :</label>
                                                    </div>

                                                    <div className="col-sm-6 col-lg-7">
                                                        <MultiSelect
                                                            items={items}
                                                            responsiveHeight={200}
                                                            selectedItems={this.state.selectedItems}
                                                            onChange={this.handleChangeMonths}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('counsellingProvider.fieldsToDisplay', { param: 'react' })} :</label>
                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        {
                                                            fieldsToDisplay.map((field) => {
                                                                return (
                                                                    <div className="form-check" key={field.fieldName}>
                                                                        <label className="form-check-label" htmlFor="check1">
                                                                            <input onChange={(event) => this.handleUserInput(event)}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                name={field.fieldName}
                                                                                checked={this.getValue(field.fieldName)} />{field.label}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="form-group mb-0 pb-4">
                                                <div className="row">
                                                    <div
                                                        className="col-sm-6 col-lg-5 text-right"></div>

                                                    <div className=" col-sm-6 col-lg-5">
                                                        <button type="submit"

                                                            className="btn btn-primary btn-primary2 mr-2">{this.props.t('jobProviderProfile.submit', { param: 'react' })}
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="menu2" className="tab-pane bg-education fade pt-4">
                                        <form onSubmit={(event) => this.handleSubmitAddress(event)}>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h6 className="mb-0 mt-2">{this.props.t('counsellingProvider.addressInfo', { param: 'react' })}</h6>
                                                </div>


                                            </div> <hr></hr>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label data-toggle="tooltip" data-placement="bottom"
                                                            title={this.props.t('profileDetails.address1Desc', { param: 'react' })}>
                                                            {this.props.t('profileDetails.address1', { param: 'react' })} :<span
                                                                className="ml-2"
                                                                style={{ "color": "red" }}>*</span></label>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-5">
                                                        <input type="text"
                                                            onChange={(event) => Mainthis.handleProfileAddress(event)}
                                                            value={this.state.Profile.address_Line1 ?
                                                                this.state.Profile.address_Line1 : ''}
                                                            className="form-control" id="address_Line1"
                                                            name="address_Line1"
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('profileDetails.address1', { param: 'react' })} />
                                                        {this.addressValidator.message('address Line1', this.state.Profile.address_Line1, 'required')}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label data-toggle="tooltip" data-placement="bottom"
                                                            title={this.props.t('profileDetails.address2Desc', { param: 'react' })}>
                                                            {this.props.t('profileDetails.address2', { param: 'react' })} :
                                                            <span
                                                                className="ml-2"
                                                                style={{ "color": "red" }}>*</span></label>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-5">
                                                        <input type="text"
                                                            onChange={(event) => Mainthis.handleProfileAddress(event)}
                                                            value={this.state.Profile.address_Line2 ?
                                                                this.state.Profile.address_Line2 : ''}
                                                            className="form-control" id="address_Line2"
                                                            name="address_Line2"
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('profileDetails.address2', { param: 'react' })} />
                                                        {this.addressValidator.message('address Line2', this.state.Profile.address_Line2, 'required')}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('jobProviderProfile.district', { param: 'react' })} :</label><span
                                                            style={{ color: 'red' }}
                                                            className="ml-2">*</span>
                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        <select
                                                            value={this.state.Profile.district_id ? this.state.Profile.district_id : ""}
                                                            className="form-control" id="district_id"
                                                            name="district_id"
                                                            onChange={(e) => this.handleProfileAddress(e)}>
                                                            <option value="">{this.props.t('placeholder.select', { param: 'react' }) + ' ' + this.props.t('jobProviderProfile.district', { param: 'react' })}</option>
                                                            {distData && distData.length ? distData.map((d, key) => {
                                                                if (d.id !== "666") {
                                                                    return (<option key={key} value={d.id}>{d.name}</option>)
                                                                }
                                                                return ''
                                                            }) : null}
                                                        </select>
                                                        {this.addressValidator.message('district', this.state.Profile.district_id, 'required')}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label data-toggle="tooltip" data-placement="bottom"
                                                            title={this.props.t('profileDetails.pincodeDesc', { param: 'react' })}>
                                                            {this.props.t('profileDetails.pincode', { param: 'react' })} :
                                                                    <span
                                                                className="ml-2"
                                                                style={{ "color": "red" }}>*</span>
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-5">
                                                        <input type="number"
                                                            className="form-control"
                                                            onChange={(event) => Mainthis.handleProfileAddress(event)}
                                                            value={this.state.Profile.pincode ? this.state.Profile.pincode : ''}
                                                            name="pincode"
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('profileDetails.pincode', { param: 'react' })} />
                                                        {this.addressValidator.message('pincode', this.state.Profile.pincode, 'required|min:6|max:8')}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('profileDetails.phone', { param: 'react' })} :
                                                        <span
                                                                className="ml-2"
                                                                style={{ "color": "red" }}>*</span></label>
                                                    </div>

                                                    <div className=" col-sm-6 col-lg-5">
                                                        <input onChange={(event) => Mainthis.handleProfileAddress(event)}
                                                            type="number"
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('profileDetails.phone', { param: 'react' })}
                                                            value={this.state.Profile.phone ? this.state.Profile.phone : ''}
                                                            className="form-control"
                                                            name="phone" />
                                                        {this.addressValidator.message('phone no', this.state.Profile.phone, 'required|phone')}

                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="form-group mb-0 pb-4">
                                                <div className="row">
                                                    <div
                                                        className="col-sm-6 col-lg-5 text-right"></div>

                                                    <div className=" col-sm-6 col-lg-5">
                                                        <button type="submit"

                                                            className="btn btn-primary btn-primary2 mr-2">{this.props.t('jobProviderProfile.submit', { param: 'react' })}
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="menu3" className="tab-pane bg-education fade pt-4">
                                        <form onSubmit={(event) => this.handleSubmitExperience(event)}>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('counsellingProvider.experience', { param: 'react' })} :</label>
                                                        <span
                                                            style={{ color: 'red' }}
                                                            className="ml-2">*</span>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-5">
                                                        <input className="form-control"
                                                            type="number"
                                                            onChange={(event) => Mainthis.handleProfileExperince(event)}
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.experience', { param: 'react' })}
                                                            value={this.state.Profile.experience ? this.state.Profile.experience : ''}
                                                            name="experience" />
                                                        {validator3.message('experience', this.state.Profile.experience, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('counsellingProvider.expDetails', { param: 'react' })} :</label>
                                                        <span
                                                            style={{ color: 'red' }}
                                                            className="ml-2">*</span>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-5">
                                                        <textarea name="experience_details"
                                                            onChange={(event) => Mainthis.handleProfileExperince(event)}
                                                            value={this.state.Profile.experience_details ? this.state.Profile.experience_details : ''}
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + ' ' + this.props.t('counsellingProvider.expDetails', { param: 'react' })}
                                                            className="w-100" />
                                                        {validator3.message('experience_details', this.state.Profile.experience_details, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="form-group mb-0 pb-4">
                                                <div className="row">
                                                    <div
                                                        className="col-sm-6 col-lg-5 text-right"></div>

                                                    <div className=" col-sm-6 col-lg-5">
                                                        <button type="submit"
                                                            className="btn btn-primary btn-primary2 mr-2">{this.props.t('jobProviderProfile.submit', { param: 'react' })}
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="edu" className="tab-pane bg-education fade pt-4">
                                        <div>
                                            <form onSubmit={(event) => {
                                                event.preventDefault();
                                                this.handleSubmitEducation(event)
                                            }}>
                                                <div className="panel-body mb-0 pb-3">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right pr-0">
                                                                    <label data-placement="bottom"
                                                                        title={this.props.t('education.qualificationDesc', { param: 'react' })}>
                                                                        {this.props.t('education.qualification', { param: 'react' })}
                                                                        <span
                                                                            style={{ color: 'red' }}
                                                                            className="ml-2">*</span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-8 mb-3">
                                                                    <select
                                                                        onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                        value={Mainstate.Education.highest_education_level ? Mainstate.Education.highest_education_level : ''}
                                                                        className="form-control"
                                                                        id="highest_education_level"
                                                                        name="highest_education_level">
                                                                        <option value="">Select educations</option>
                                                                        {filterEdu && filterEdu.length > 0 ?
                                                                            filterEdu.map(function (data, index) {
                                                                                return (
                                                                                    <option
                                                                                        value={data.education_id}
                                                                                        key={index}>{data.education_name}</option>
                                                                                )
                                                                            }) : null
                                                                        }
                                                                    </select>
                                                                    {eduValidator.message('highest_education_level',
                                                                        this.state.Education.highest_education_level, 'required')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right pr-0">
                                                                    <label data-placement="bottom"
                                                                        title={this.props.t('education.mediumOfEducationDesc', { param: 'react' })}>
                                                                        {this.props.t('education.mediumOfEducation', { param: 'react' })}
                                                                        <span
                                                                            style={{ color: 'red' }}
                                                                            className="ml-2">*</span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-8 mb-3">
                                                                    <select
                                                                        value={Mainstate.Education.course_language_id ? Mainstate.Education.course_language_id : ''}
                                                                        onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                        className="form-control" id="course_language_id"
                                                                        name="course_language_id">
                                                                        <option value="">Select language</option>
                                                                        {Mainprops.actionReducer.Language.length > 0 &&
                                                                            Mainprops.actionReducer.Language.map(function (data, index) {
                                                                                return (
                                                                                    <option
                                                                                        key={index}
                                                                                        value={data.id}>{data.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {eduValidator.message('language',
                                                                        this.state.Education.course_language_id, 'required')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-4">
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right pr-0">
                                                                    <label data-placement="bottom"
                                                                        title={this.props.t('education.board/universityDesc', { param: 'react' })}>
                                                                        {this.props.t('education.board/university', { param: 'react' })}
                                                                        <span
                                                                            style={{ color: 'red' }}
                                                                            className="ml-2">*</span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-8" id="instituteAuto">

                                                                    <Autocomplete
                                                                        className="w-100 form-control"
                                                                        items={Mainprops.actionReducer.Institution &&
                                                                            Mainprops.actionReducer.Institution}
                                                                        shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                                        renderItem={(item, isHighlighted) =>
                                                                            <div key={item.id}

                                                                                style={{ background: isHighlighted ? 'lightgray' : 'white', "borderBottom": "1px solid #ccc" }}>
                                                                                {item.name}
                                                                            </div>
                                                                        }
                                                                        renderInput={(props) => {
                                                                            return <input className="form-control" {...props} />
                                                                        }}
                                                                        wrapperStyle={{ "width": "100%" }}
                                                                        menuStyle={{
                                                                            "zIndex": "9",
                                                                            "border": "1px solid #ccc",
                                                                            "overflow": "hidden",
                                                                            "overflowY": "scroll",
                                                                            "boxShadow": "1px 2px 10px #ccc",
                                                                            "padding": "4px",
                                                                            "maxHeight": "123px",
                                                                            "fontSize": "14px"
                                                                        }}
                                                                        value={Mainstate.Education.Univ_board_value ? Mainstate.Education.Univ_board_value : ''}
                                                                        onChange={(e) => this.setEducationLevel(Mainprops.actionReducer.Institution, e.target.value, 'Univ_board', 'Univ_board_value')}
                                                                        getItemValue={(item) => item.name}
                                                                        onSelect={(val) => this.setEducationLevel(Mainprops.actionReducer.Institution, val, 'Univ_board', 'Univ_board_value')}
                                                                    />
                                                                    {eduValidator.message('institution',
                                                                        this.state.Education.Univ_board_value, 'required')}


                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-4">
                                                            <div className="row">

                                                                <div className="col-sm-4 text-right pr-0">
                                                                    <label data-placement="bottom"
                                                                        title={this.props.t('profileDetails.educationDesc', { param: 'react' })}>
                                                                        {this.props.t('education.course', { param: 'react' })}
                                                                        <span
                                                                            style={{ color: 'red' }}
                                                                            className="ml-2">*</span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-8" id="courseAuto">
                                                                    <Autocomplete
                                                                        items={courses}
                                                                        shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                                        menuStyle={{
                                                                            "zIndex": "9",
                                                                            "border": "1px solid #ccc",
                                                                            "overflow": "hidden",
                                                                            "overflowY": "scroll",
                                                                            "boxShadow": "1px 2px 10px #ccc",
                                                                            "padding": "4px",
                                                                            "maxHeight": "123px",
                                                                            "fontSize": "14px"
                                                                        }}
                                                                        renderInput={(props) => {
                                                                            return <input className="form-control" {...props} />
                                                                        }}
                                                                        wrapperStyle={{ "width": "100%" }}
                                                                        renderItem={(item, isHighlighted) =>
                                                                            <div key={item.id}
                                                                                style={{ background: isHighlighted ? 'lightgray' : 'white', "borderBottom": "1px solid #ccc", }}>
                                                                                {item.label}
                                                                            </div>
                                                                        }
                                                                        value={Mainstate.Education.education_level_id_value ? Mainstate.Education.education_level_id_value : ''}
                                                                        onChange={(e) => this.setCourses(educationLevel, e.target.value, 'education_level_id', 'education_level_id_value')}
                                                                        getItemValue={(item) => item.label}
                                                                        onSelect={(val) => this.setCourses(educationLevel, val, 'education_level_id', 'education_level_id_value')}
                                                                    />

                                                                    {eduValidator.message('education',
                                                                        this.state.Education.education_level_id_value, 'required')}

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {Mainstate.Education.Univ_board && Mainstate.Education.Univ_board === '344' &&
                                                                <div className="row">
                                                                    <div className="col-sm-4 text-right pr-0">
                                                                        <label data-placement="bottom"
                                                                            title={this.props.t('education.otherBoardDesc', { param: 'react' })}>
                                                                            {this.props.t('education.otherBoard', { param: 'react' })}
                                                                            <span
                                                                                style={{ color: 'red' }}
                                                                                className="ml-2">*</span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-sm-8">

                                                                        <input
                                                                            onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                            value={Mainstate.Education.Univ_board_other ? Mainstate.Education.Univ_board_other : ''}
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="Univ_board_other" placeholder="Enter Other University Board" />
                                                                        {eduValidator.message('Univ_board_other',
                                                                            this.state.Education.Univ_board_other, 'required')}
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                        <div className="col-md-6">
                                                            {Mainstate.Education.education_level_id && Mainstate.Education.education_level_id === '152' &&
                                                                <div className="row">
                                                                    <div className="col-sm-4 text-right pr-0">
                                                                        <label data-placement="bottom"
                                                                            title={this.props.t('experience.othersCourseDesc', { param: 'react' })}>
                                                                            {this.props.t('experience.othersCourse', { param: 'react' })}
                                                                            <span
                                                                                style={{ color: 'red' }}
                                                                                className="ml-2">*</span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-sm-8">

                                                                        <input
                                                                            onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                            value={Mainstate.Education.education_level_others ? Mainstate.Education.education_level_others : ''}
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="education_level_others" placeholder="Enter Other Course"
                                                                        />
                                                                        {eduValidator.message('education_level_others',
                                                                            this.state.Education.education_level_others, 'required')}
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right pr-0 mb-3">
                                                                    <label data-placement="bottom"
                                                                        title={this.props.t('education.scoreTypeDesc', { param: 'react' })}>
                                                                        {this.props.t('education.scoreType', { param: 'react' })}
                                                                        <span
                                                                            style={{ color: 'red' }}
                                                                            className="ml-2">*</span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-8">

                                                                    <select
                                                                        onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                        className="form-control"
                                                                        value={Mainstate.Education.score_type ? Mainstate.Education.score_type : ''}
                                                                        name="score_type">
                                                                        <option value="">Select score type</option>
                                                                        <option value="1">{this.props.t('education.percentage/percentile', { param: 'react' })}</option>
                                                                        <option value="2">{this.props.t('education.percentile', { param: 'react' })}</option>
                                                                        <option value="3">{this.props.t('education.grade', { param: 'react' })}</option>
                                                                        <option value="4">{this.props.t('education.CGPA', { param: 'react' })}</option>
                                                                    </select>
                                                                    {eduValidator.message('score type',
                                                                        this.state.Education.score_type, 'required')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {/* here */}
                                                            {Mainstate.Education.score_type && Mainstate.Education.score_type === '1' &&
                                                                <div className="row mt-2">
                                                                    <div className="col-sm-4 text-right pr-0 mb-3">
                                                                        <label data-placement="bottom"
                                                                            title={this.props.t('education.percentage/percentileDesc', { param: 'react' })}>
                                                                            {this.props.t('education.percentage/percentile', { param: 'react' })}<span
                                                                                style={{ color: 'red' }}
                                                                                className="ml-2">*</span>

                                                                        </label>
                                                                    </div>
                                                                    <div className="col-sm-8">
                                                                        <input
                                                                            onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                            value={Mainstate.Education.percentage ? Mainstate.Education.percentage : ''}
                                                                            type="number"
                                                                            min={"1"}

                                                                            className="form-control" id="percentage"
                                                                            name="percentage" placeholder="Enter Percentage" />
                                                                        {eduValidator.message('percentage',
                                                                            this.state.Education.percentage, 'required')}
                                                                    </div>
                                                                </div>}
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    {Mainstate.Education.score_type && Mainstate.Education.score_type === '2' &&
                                                                        <div className="row mt-2">
                                                                            <div className="col-sm-4 text-right pr-0 mb-3">
                                                                                <label data-placement="bottom"
                                                                                    title={this.props.t('education.percentileDesc', { param: 'react' })}>
                                                                                    {this.props.t('education.percentile', { param: 'react' })}
                                                                                    <span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span>

                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-8">
                                                                                <input
                                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                                    value={Mainstate.Education.percentile ? Mainstate.Education.percentile : ''}
                                                                                    type="number"
                                                                                    min={"1"}
                                                                                    className="form-control"
                                                                                    name="percentile" placeholder="Enter Percentile" />
                                                                                {eduValidator.message('Percentile',
                                                                                    this.state.Education.percentile, 'required')}

                                                                            </div>
                                                                        </div>}
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    {Mainstate.Education.score_type && Mainstate.Education.score_type === '4' &&
                                                                        <div className="row mt-2">
                                                                            <div className="col-sm-4 text-right pr-0 mb-3">
                                                                                <label data-placement="bottom"
                                                                                    title={this.props.t('education.CGPA', { param: 'react' })}>
                                                                                    {this.props.t('education.CGPA', { param: 'react' })}
                                                                                    <span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-8">
                                                                                <input
                                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                                    value={Mainstate.Education.CGPA ? Mainstate.Education.CGPA : ''}
                                                                                    type="number"
                                                                                    min={"1"}
                                                                                    className="form-control"
                                                                                    name="CGPA" placeholder="Enter CGPA" />
                                                                                {eduValidator.message('CGPA',
                                                                                    Mainstate.Education.CGPA, 'required')}

                                                                            </div>
                                                                        </div>}
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    {Mainstate.Education.score_type && Mainstate.Education.score_type === '3' &&
                                                                        <div className="row mt-2">
                                                                            <div className="col-sm-4 text-right pr-0 mb-3">
                                                                                <label data-placement="bottom"
                                                                                    title={this.props.t('education.gradeDesc', { param: 'react' })}>
                                                                                    {this.props.t('education.grade', { param: 'react' })}
                                                                                    <span
                                                                                        style={{ color: 'red' }}
                                                                                        className="ml-2">*</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-8">
                                                                                <input
                                                                                    onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                                    value={Mainstate.Education.grade ? Mainstate.Education.grade : ''}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="grade" placeholder="Enter Grade" />
                                                                                {eduValidator.message('grade',
                                                                                    Mainstate.Education.grade, 'required')}

                                                                            </div>
                                                                        </div>}
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right pr-0 mb-3">
                                                                    <label data-placement="bottom"
                                                                        title={this.props.t('education.yearOfPassingDesc', { param: 'react' })}>
                                                                        {this.props.t('education.yearOfPassing', { param: 'react' })}
                                                                        <span
                                                                            style={{ color: 'red' }}
                                                                            className="ml-2">*</span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-8">

                                                                    <select className="form-control"
                                                                        onChange={(event) => Mainthis.handleEducationInput(event)}
                                                                        value={this.state.Education.passing_year ? this.state.Education.passing_year : ''}
                                                                        name="passing_year">
                                                                        <option value={""}>Select Year</option>
                                                                        {this.years()}
                                                                    </select>
                                                                    {eduValidator.message('passing year', this.state.Education.passing_year, 'required')}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right pr-0 mb-3">
                                                                    <label data-placement="bottom"
                                                                        title={this.props.t('education.certificateDesc', { param: 'react' })}>
                                                                        {this.props.t('education.certificate', { param: 'react' })}

                                                                    </label>
                                                                </div>
                                                                <div className="col-sm-8">

                                                                    <input type="file"
                                                                        onChange={(event) => Mainthis.handleDocs(event, 'errorCertificate', 'img', 'submitted')}
                                                                        name="img"
                                                                        ref={this.institue}
                                                                        accept={this.allowedDocFormats.toString()}
                                                                        className="my-form-text"
                                                                    />
                                                                    {Mainstate.errorCertificate &&
                                                                        <span style={{ "color": "red" }}>{Mainstate.errorCertificate}</span>}
                                                                    {Mainstate.edit && this.state.Education.doc ? <a rel="noopener noreferrer" className="img-text" target={"_blank"} href={`${SERVER_PATH}${this.state.Education.doc}`}>{`${SERVER_PATH}${this.state.Education.doc}`}</a> : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="row ">
                                                                <div className="col-md-12 col-md-12 text-center">
                                                                    <button type="submit"
                                                                        className="btn btn-primary btn-primary2">
                                                                        {this.props.t(Mainstate.edit ? 'profileDetails.update' : 'jobProviderProfile.saveAndMore', { param: 'react' })}
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        {Mainprops.counsellingproviderReducer.educations.length > 0 &&
                                            <div>
                                                <hr></hr>
                                                {Mainprops.counsellingproviderReducer.educations.map((edata, index) => {
                                                    let html = ''
                                                    html = <div className=" bg-education p-0" key={index}>
                                                        <div className="row">
                                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        {edata.education_level_id_value && edata.education_level_id_value !== 'Others' && <h6 className="edu-heading-width d-inline-block">{edata.education_level_id_value}</h6>}
                                                                        {edata.education_level_others && edata.education_level_id_value === 'Others' && <h6 className="edu-heading-width d-inline-block">{edata.education_level_others}</h6>}
                                                                        <span><Link to="#" onClick={(event) => {
                                                                            event.preventDefault();
                                                                            Mainthis.handleEditEducation(index)
                                                                        }}
                                                                            className="edit-btn  pull-right" ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                                                            <Link to="#" onClick={(event) => {
                                                                                event.preventDefault();
                                                                                this.setState({ delIndex: index })
                                                                            }}
                                                                                data-toggle="modal" data-target="#exampleModal" className="edit-btn mr-3 pull-right"><i className="fa fa-trash" aria-hidden="true"></i></Link></span>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div
                                                                        className="col-sm-4 col-6">
                                                                        <label>{this.props.t('education.board/university', { param: 'react' })}:</label>
                                                                    </div>
                                                                    <div className="col-sm-8 col-6">

                                                                        {edata.Univ_board_value && edata.Univ_board_value !== 'Others' && <label> {edata.Univ_board_value}</label>}
                                                                        {edata.Univ_board_other && edata.Univ_board_value === 'Others' && <label> {edata.Univ_board_other}</label>}
                                                                    </div>
                                                                </div>
                                                                {edata.score_type === '1' &&
                                                                    <div className="row">
                                                                        <div className="col-sm-4  col-6">
                                                                            <label>{this.props.t('education.percentage/percentile', { param: 'react' })}:</label>
                                                                        </div>
                                                                        <div className="col-sm-8 col-6">
                                                                            <label>{edata.percentage}</label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {edata.score_type === '2' &&
                                                                    <div className="row">
                                                                        <div className="col-sm-4  col-6">
                                                                            <label>{this.props.t('education.percentile', { param: 'react' })}:</label>
                                                                        </div>
                                                                        <div className="col-sm-8 col-6">
                                                                            <label>{edata.percentile}</label>
                                                                        </div>
                                                                    </div>}
                                                                {edata.score_type === '3' &&
                                                                    <div className="row">
                                                                        <div className="col-sm-4  col-6">
                                                                            <label>{this.props.t('education.grade', { param: 'react' })}:</label>
                                                                        </div>
                                                                        <div className="col-sm-8 col-6">
                                                                            <label>{edata.grade}</label>
                                                                        </div>
                                                                    </div>}
                                                                {edata.score_type === '4' &&
                                                                    <div className="row">
                                                                        <div className="col-sm-4  col-6">
                                                                            <label>{this.props.t('education.CGPA', { param: 'react' })}:</label>
                                                                        </div>
                                                                        <div className="col-sm-8 col-6">
                                                                            <label>{edata.CGPA}</label>
                                                                        </div>
                                                                    </div>}
                                                                <hr />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    return html
                                                })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </main>
                        }
                    </div>
                </div>

            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(SkillProviderProfile)));
