import React, { Component } from "react";
import Sidebar from './Sidebar'
import ReactToPrint from "react-to-print";
import { translate } from 'react-multi-lang'
import { connect } from "react-redux";
import { getHallTicketData } from '../../../actions/jobseekerAction'
import store from '../../../store'
import { Link } from 'react-router'

class HallTicket extends Component {

    constructor(props) {
        super(props);
        this.printContent = React.createRef();
        store.dispatch(getHallTicketData());
        this.state = {
            isLoading: true
        }
    }


    getInterviewDate = (ticket) => {
        if (ticket.virtual_interview === '1' && ticket.physical_interview === '1') {
            return `Virtual(${ticket.virtual_interview_date}) & Physical(${ticket.physical_interview_date})`
        }
        else if (ticket.virtual_interview === '1') {
            return `Virtual(${ticket.virtual_interview_date})`
        } else if (ticket.physical_interview === '1') {
            return `Physical(${ticket.physical_interview_date})`
        } else {
            return ''
        }
    }

    getVenue = (ticket) => {
        if (ticket.virtual_interview === '1' && ticket.physical_interview === '1') {
            return `Virtual(${ticket.virtual_venue}) & Physical(${ticket.physical_venue})`
        }
        else if (ticket.virtual_interview === '1') {
            return `Virtual(${ticket.virtual_venue})`
        } else if (ticket.physical_interview === '1') {
            return `Physical(${ticket.physical_venue})`
        } else {
            return ''
        }
    }

    componentDidMount() {
        document.title = this.props.t('hallTicket.sidebar', { param: 'react' })
    }

    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        let ticketData = this.props.JobseekerReducer.hallTicketForPrint
        let userData = this.props.JobseekerReducer.Profile
        let showTicket = this.props.actionReducer.activeJobMela.showTicket
        showTicket = 1
        console.log(userData)
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={this.props.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="panel-heading">
                                            <h6 className="mb-0">{this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.jobMela ? this.props.actionReducer.activeJobMela.jobMela : ''}</h6>

                                        </div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12 bg-education  mx-auto   p-0">
                                                    <div className="panel-body" ref={this.printContent}>
                                                        <div className="d-flex align-items-center w-100 justify-content-center">
                                                            <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                                        </div>
                                                        <h5 className="text-center text-uppercase mb-3">{this.props.t('jobMela.pgrkam', { param: 'react' })}</h5>
                                                        <h6 className="text-center text-uppercase">Hall Ticket for Job Fair in Rozgar Mela - 07.06.2023</h6>
                                                        <p style={{ fontSize: "1rem", textAlign:'justify' }}>You have applied for the following Jobs on www.pgrkam.com in the {this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.jobMela ? this.props.actionReducer.activeJobMela.jobMela : ''}. You are required to show your Hall ticket at the Interview Venue .Your credentials will be shared with the Employer(s). You may contact the District Bureau of Employment and Enterprises (DBEE) in case some clarification is required regarding the interviews in the {this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.jobMela ? this.props.actionReducer.activeJobMela.jobMela : ''}
                                                        .</p>

                                                        {showTicket ? <>
                                                            <hr className="m-0"></hr>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <h6 className="text-center p-2 mb-0">CANDIDATE INFORMATION</h6>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" style={{ width: "50%" }}>
                                                                    <h6>Name: {userData.firstname ? userData.firstname + ' ' + userData.lastname : ''}</h6>
                                                                    <h6>District: {userData.district}</h6>
                                                                    <h6>Gender: {userData.gen}</h6>
                                                                </div>
                                                                <div className="col-md-6" style={{ width: "50%" }}>
                                                                    <h6>Date of Birth: {userData.dob ? userData.dob : "Information not Provided"}</h6>
                                                                    <h6>Mobile: {userData.mobile}</h6>
                                                                    <h6>Qualification: {userData.edu}</h6>
                                                                </div>
                                                            </div>
                                                            <p></p>
                                                            <hr></hr>
                                                            {ticketData && ticketData.length ? <>
                                                                <h5 className="text-center p-2 mb-0"> Details of vacancies applied for in {this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.jobMela ? this.props.actionReducer.activeJobMela.jobMela : ''}</h5>
                                                                <h6 className="text-center p-2 mb-0">Total Jobs Applied: {ticketData.length}</h6></> : null}
                                                            <div>
                                                                {ticketData && ticketData.length ? ticketData.map((ticket, key) => {
                                                                    return (
                                                                        <div key={key} className="shadow mb-3">
                                                                            <div className="bank-name text-center">
                                                                                <h6 className="mb-0 p-1"> Employer Name :  {ticket.org_name}</h6>
                                                                            </div>
                                                                            <div className="row m-0">
                                                                                <div
                                                                                    className="col-md-12 col-lg-12  text-left job-data mt-1">
                                                                                    <div className="row m-0">
                                                                                        <span className="mr-3 font-weight-bold w-20">Job Role</span>
                                                                                        <span className="w-25">{ticket.job_title}</span>

                                                                                        <span className="mr-3 font-weight-bold w-13">Eligiblity</span>
                                                                                        <span className="w-20">{ticket.eligiblity}</span>
                                                                                    </div>
                                                                                    <div className="row m-0">
                                                                                        <span className="mr-3 font-weight-bold w-20 ">District/Dbee</span>
                                                                                        <span className="w-25">{ticket.district}</span>
                                                                                        <span className="mr-3 w-13 font-weight-bold">Place of Posting</span>
                                                                                        <span className="w-20" >{ticket.posting}</span>
                                                                                    </div>
                                                                                    <div className="row m-0">
                                                                                        <span className="mr-3 font-weight-bold w-20">Salary</span>
                                                                                        <span className="w-25">{ticket.salary}</span>
                                                                                        <span className="mr-3 font-weight-bold w-13">Interview Date</span>
                                                                                        <span className="w-20">{this.getInterviewDate(ticket)}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="col-md-12 col-lg-12  text-left job-data mt-1">
                                                                                    {ticket.contact ? <div className="row m-0">
                                                                                        <span className="mr-3 font-weight-bold w-20">Dbee Contact</span>
                                                                                        <span className="mr-5">{ticket.contact}</span>
                                                                                    </div>
                                                                                        : null}
                                                                                    <div className="row m-0">
                                                                                        <span className="mr-3 font-weight-bold w-20">Interview Venue</span>
                                                                                        <span className="mr-5">{this.getVenue(ticket)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }) : null}
                                                            </div>
                                                        </> :
                                                            <h6>{this.props.t('hallTicket.inst1', { param: 'react' })} {this.props.actionReducer.activeJobMela.dateFrom} {this.props.t('hallTicket.to', { param: 'react' })} {this.props.actionReducer.activeJobMela.dateTo}</h6>}
                                                        {ticketData && ticketData.notFound ?
                                                            <div>
                                                                <hr />
                                                                <p>You have not applied for any job in {this.props.actionReducer.activeJobMela && this.props.actionReducer.activeJobMela.jobMela ? this.props.actionReducer.activeJobMela.jobMela : ''} through <a href="http://pgrkam.com">http://pgrkam.com</a></p>
                                                            </div> : null}
                                                        {showTicket ? <p className="mt-4">Disclaimer: Merely applying for the jobs through this portal does not entitle you to be selected for the job(s) that you have applied. This portal is for the facilitation of the Jobseekers and the Employers.</p> : null}
                                                    </div>
                                                    {showTicket && ticketData && ticketData.length ?
                                                        <ReactToPrint
                                                            trigger={() => <button
                                                                className={"btn btn-primart btn-primary2 print-btn mx-auto d-block mb-2 mt-2"}>
                                                                {/* {this.props.t('hallTicket.print', { param: 'react' })}
                                                                 */}
                                                                 Print Hall Ticket
                                                            </button>}
                                                            content={() => this.printContent.current}
                                                            copyStyles={true}
                                                        /> : null}
                                                    <h6 className="text-center">For contact details of the DBEE, <Link to="/employment">Click Here</Link></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        )
    }
}


const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(HallTicket));
