import React, { Component } from 'react'
import { connect } from 'react-redux';
import store from '../../../store';
import SidebarSeeker from '../jobseeker/Sidebar';
import SidebarProvider from '../jobprovider/Sidebar';
import { getChatMessages, getChats, sentChatMessage, setChatMessages, blockUnBlockUser } from '../../../actions/accountAction'
import { getTimeWithAmPm } from '../../../helpers'
import { translate } from 'react-multi-lang'
import ServerSetting from '../../../config.json';
import CounsellingProviderSidebar from '../counsellingprovider/Sidebar';
import moment from 'moment'
import { Link } from 'react-router'

class Forum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            receiver_id: '',
            chatImage: '',
            activeChat: ''
        }
        store.dispatch(getChats())

    }

    getMessages = (chat, key) => {
        if (chat.request_to !== this.state.receiver_id) {
            store.dispatch(getChatMessages({ receiver_id: chat.request_to }))
            this.setState({ receiver_id: chat.request_to, chatImage: chat.img, activeChat: key })
        }
    }

    componentWillUnmount() {
        store.dispatch(setChatMessages([]))
    }

    blockUser = (chat) => {
        store.dispatch(blockUnBlockUser(1, chat.request_to))
    }

    unBlockUser = (chat) => {
        store.dispatch(blockUnBlockUser(0, chat.request_to))
    }

    componentDidMount() {
        document.title = "Discussion Forum"
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.actionReducer.chats && nextProps.actionReducer.chats.length && this.props.location.query &&
            this.props.location.query.chat && this.props.location.query.chat !== "") {
            const activeChat = nextProps.actionReducer.chats.findIndex((chat) => chat.request_to === this.props.location.query.chat)
            if (activeChat !== -1) {
                this.setState({ activeChat })
                this.getMessages(nextProps.actionReducer.chats[activeChat], activeChat)
            }
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    postChatMessage = (e) => {
        e.preventDefault()
        const { message } = this.state
        if (message) {
            store.dispatch(sentChatMessage({ message, receiver_id: this.state.receiver_id }))
            this.setState({ message: '' })
        }
    }

    getTimeOfPost = (date) => {
        const splitedDate = date.split('-')
        return moment([parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2])]).fromNow()
    }

    render() {
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;
        const loggedInUserId = this.props.actionReducer.Loginuser.id

        let Sidebar = ''
        if (localStorage.getItem('login_type') === 'Jobseeker') {
            Sidebar = SidebarSeeker
        } else if (localStorage.getItem('login_type') === 'Indian Employer') {
            Sidebar = SidebarProvider
        } else if (localStorage.getItem('login_type') === 'Counselling Provider') {
            Sidebar = CounsellingProviderSidebar
        }

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={this.props.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="messaging_container">
                                            <div className="messaging">
                                                <div className="inbox_msg">
                                                    <div className="inbox_people">
                                                        <div className="headind_srch">
                                                            <div className="recent_heading p-2">
                                                                <h4>Discussion Forum</h4>
                                                            </div>
                                                        </div>
                                                        <div className="inbox_chat">
                                                            {this.props.actionReducer.chats && this.props.actionReducer.chats.length ?
                                                                this.props.actionReducer.chats.map((chat, key) => {
                                                                    return (
                                                                        <div className={this.state.activeChat === key ? 'chat_list active_chat' : 'chat_list'} key={key} onClick={() => this.getMessages(chat, key)}>
                                                                            <div className="chat_people">
                                                                                <div className="chat_img"> <img className="img-fluid" src={chat.img ? `${SERVER_PATH}${chat.img}` : '/images/img-1.png'} alt={chat.name} /></div>
                                                                                <div className="chat_ib">
                                                                                    <h5 className="m-0">{chat.employer_name}
                                                                                        {this.props.actionReducer.Loginuser.user_type !== '1' ? <> {!parseInt(chat.block) ?
                                                                                            <span title="Block User" onClick={() => this.blockUser(chat)} className="chat_date pt-1"><i style={{ fontSize: "18px" }} className="fa fa-ban" aria-hidden="true"></i></span> :
                                                                                            <span title="Unblock User" onClick={() => this.unBlockUser(chat)} className="chat_date pt-1"><i style={{ fontSize: "18px" }} className="fa fa-undo" aria-hidden="true"></i></span>
                                                                                        }
                                                                                        </> : null}
                                                                                    </h5>

                                                                                    {/* <span className="chat_date">Dec 25</span> */}
                                                                                    {/* <p>Test, which is a new approach to have all solutions astrology under one roof.</p> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div className="mesgs">
                                                        {this.state.activeChat === "" && this.props.actionReducer.chats.length ?
                                                            <div>
                                                                <h5>Click on the recent messages to view the conversations <i className="fa fa-commenting-o" aria-hidden="true"></i></h5>
                                                            </div>
                                                            : null}
                                                            {this.props.actionReducer.Loginuser.user_type ==='1' && !this.props.actionReducer.chats.length && this.state.activeChat === "" ?
                                                            <div>
                                                            <h5>No invitation sent/accepted. <Link to="/jobsearch">Click Here</Link> to go to jobs search to invite employer for Chat <i className="fa fa-commenting-o" aria-hidden="true"></i></h5>
                                                        </div>: null}
                                                        {this.props.actionReducer.Loginuser.user_type !=='1' && !this.props.actionReducer.chats.length && this.state.activeChat === "" ?
                                                            <div>
                                                            <h5>No invitations accepted for chat <i className="fa fa-commenting-o" aria-hidden="true"></i></h5>
                                                        </div>: null}
                                                           {this.state.activeChat !=="" && this.props.actionReducer.Loginuser.user_type ==='1' && parseInt(this.props.actionReducer.chats[this.state.activeChat].block) ? 
                                                           <div>
                                                           <h5>{this.props.actionReducer.chats[this.state.activeChat].employer_name} has blocked you. You cannot send messages anymore.</h5>
                                                       </div>
                                                           : null} 
                                                           {this.state.activeChat !=="" && this.props.actionReducer.Loginuser.user_type !=='1' && parseInt(this.props.actionReducer.chats[this.state.activeChat].block) ? 
                                                           <div>
                                                           <h5>You have blocked {this.props.actionReducer.chats[this.state.activeChat].employer_name}. You cannot send messages anymore. Unblock the user to send messages again.</h5>
                                                       </div>
                                                           : null} 
                                                        <div className="msg_history">
                                                            {
                                                                this.props.actionReducer.chatMessages.map((chat, key) => {
                                                                    let html = ''
                                                                    if (chat.sender_id === loggedInUserId) {
                                                                        html = <div className="outgoing_msg">
                                                                            <div className="sent_msg">
                                                                                <p>{chat.message}</p>
                                                                                <span className="time_date"> {getTimeWithAmPm(chat.time)}    |    {chat.date}</span> </div>
                                                                            {/* <span className="time_date"> {this.getTimeOfPost(chat.date)}</span> </div> */}
                                                                        </div>
                                                                    } else {
                                                                        html = <div className="incoming_msg">
                                                                            <div className="incoming_msg_img"> <img className="img-fluid" src={this.state.chatImage ? `${SERVER_PATH}${this.state.chatImage}` : '/images/img-1.png'} alt="Avatar" /> </div>
                                                                            <div className="received_msg">
                                                                                <div className="received_withd_msg">
                                                                                    <p>{chat.message}</p>
                                                                                    <span className="time_date"> {getTimeWithAmPm(chat.time)}    |    {chat.date}</span></div>
                                                                                {/* <span className="time_date"> {this.getTimeOfPost(chat.date)}</span></div> */}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    return <div key={key}>{html}</div>
                                                                })}
                                                        </div>
                                                        {this.state.activeChat !== "" &&  !parseInt(this.props.actionReducer.chats[this.state.activeChat].block) ?
                                                            <div className="type_msg">
                                                                <div className="input_msg_write">
                                                                    <form onSubmit={this.postChatMessage}>
                                                                        <input type="text" name="message" value={this.state.message ? this.state.message : ''} className="message p-2" placeholder="Type a message" onChange={this.handleChange} />
                                                                        <button className="msg_send_btn mr-2" disabled={!this.state.message} type="submit"><i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
                                                                    </form>
                                                                </div>
                                                            </div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Forum));
