import React, { Component } from 'react';
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { chunk } from '../../../helpers'
import Pagination from "react-js-pagination";
import {
    getConfimedCandidates, setConfirmedCandidates
} from '../../../actions/employerAction'
import { Link } from 'react-router'

const RESULT_PER_PAGE = 25

class ConfirmedCandidates extends Component {

    constructor(props) {
        super(props);
        this.state = {

            activePage: 1,

        }
        if (this.props.params && this.props.params.job_id) {
            store.dispatch(getConfimedCandidates(this.props.params.job_id));
        }
    }



    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }




    componentWillUnmount() {
        store.dispatch(setConfirmedCandidates([]))
    }




    componentDidMount() {
        document.title = this.props.t('dashboard.table.noSelected', { param: 'react' })
    }



    render() {
        let Mainprops = this.props;

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }


        let searchResults = chunk(Mainprops.EmployerReducer.confirmedCandidates, RESULT_PER_PAGE)
        const { activePage } = this.state


        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}


                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div id="home" className=" tab-pane active">
                                <section className="job-details mt-4">
                                    <div className="container">
                                        <div className="row">

                                            <div className="col-md-12  mx-auto  tab-content  p-0">

                                                <div className="panel-heading">
                                                    <h6 className="mb-0">{this.props.t('dashboard.table.noSelected', { param: 'react' })}</h6>

                                                </div>
                                                <div className="panel-body">
                                                    <div className="row pl-3 pr-3">
                                                        {Mainprops.EmployerReducer.confirmedCandidates && Mainprops.EmployerReducer.confirmedCandidates.length ?
                                                            <>
                                                                <h6 className="mr-3">{this.props.t('dashboard.table.job-title', { param: 'react' })}: <b>{Mainprops.EmployerReducer.confirmedCandidates[0].job_title}</b></h6>
                                                                <h6 className="mr-3">{this.props.t('dashboard.table.vacancies', { param: 'react' })}: <b>{Mainprops.EmployerReducer.confirmedCandidates[0].vacancies}</b></h6>
                                                                <h6 className="mr-3">{this.props.t('jobSearch.qual', { param: 'react' })}: <b>{Mainprops.EmployerReducer.confirmedCandidates[0].qualification}</b></h6>
                                                            </> : null
                                                        }
                                                    </div>
                                                    <hr></hr>
                                                    <div className="row pl-3 pr-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                        <th>{this.props.t('dashboard.table.name', { param: 'react' })}</th>
                                                                        <th>{this.props.t('dashboard.table.gender', { param: 'react' })}</th>
                                                                        <th>{this.props.t('dashboard.table.education', { param: 'react' })}</th>
                                                                        <th>{this.props.t('JobPost.district', { param: 'react' })}</th>
                                                                        <th>{this.props.t('JobPost.email', { param: 'react' })}</th>
                                                                        <th>{this.props.t('JobPost.exp1', { param: 'react' })}</th>
                                                                        <th>{this.props.t('dashboard.table.mobile', { param: 'react' })}</th>
                                                                    </tr>

                                                                </thead>
                                                                <tbody>
                                                                    {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((jobs, key) => {

                                                                        return (<tr key={key}>
                                                                            <td>{((activePage - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                            <td>
                                                                                <span


                                                                                >{jobs.firstname ? jobs.firstname : ''}</span>


                                                                            </td>
                                                                            <td>{jobs.gender ? jobs.gender : ''}</td>
                                                                            <td>{jobs.education ? jobs.education : ''}</td>
                                                                            <td>{jobs.district ? jobs.district : ''}</td>
                                                                            <td>{jobs.email ? jobs.email : ''}</td>
                                                                            <td>{jobs.experience ? jobs.experience : ''}</td>
                                                                            <td>{jobs.mobile ? jobs.mobile : ''}</td>
                                                                        </tr>)
                                                                    })}
                                                                    {Mainprops.EmployerReducer.confirmedCandidates.notFound &&
                                                                        <tr>
                                                                            <td colSpan="8" style={{ "textAlign": "center" }}>
                                                                                <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5></td>
                                                                        </tr>}


                                                                </tbody>

                                                            </table>
                                                            {searchResults && searchResults.length > 1 ?
                                                                <div className='react-pagination'>
                                                                    <Pagination
                                                                        pageRangeDisplayed={10}
                                                                        activePage={activePage}
                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                        totalItemsCount={Mainprops.EmployerReducer.confirmedCandidates.length}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(ConfirmedCandidates));
