import {SET_LOADER,UNSET_LOADER} from '../actions/actionTypes';

const initialState = {
  loader: false
};
const setloader = (state, action) => {
  return { ...state, loader : true };
};
const unsetloader = (state, action) => {
  return { ...state, loader : false };
};
const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADER: return setloader(state, action);
    case UNSET_LOADER: return unsetloader(state, action);
    default: return state;
  }
};
export default loaderReducer;
