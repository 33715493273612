import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import store from '../../store';
import { VerifyUserWithOtp, setVerifyWithOtp } from '../../actions/accountAction';
import Validation from "../hoc/Validation";

class VerifyUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            otp: '',
            submitted: false
        }
    }

    componentWillUnmount(){
        store.dispatch(setVerifyWithOtp(false))
    }

    componentDidMount() {
        document.title = this.props.t('login.verify_no', { param: 'react' })
    }
    
    handleSubmit = async (e) => {
        e.preventDefault();
        const { otp } = this.state
        
        if (!this.state.submitted && this.props.validator.allValid() && this.props.location.query.cid) {
            store.dispatch(VerifyUserWithOtp({ mobile:this.props.location.query.cid, otp}));
            this.setState({submitted: true})
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }

    }

    change = (id, e) => {
        let inputType = document.getElementById(id).type
        if(inputType === 'password') {
            document.getElementById(id).type = 'text'
            e.target.className = e.target.className.substring(0, e.target.className.lastIndexOf("-"));
        } else {
            document.getElementById(id).type = 'password'
            e.target.className += '-slash'
        }

    }

    handleUserInput (e) {
        const name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value, submitted: false});
    }


    render() {
        const { validator } = this.props
        return (
            <section className="login-form main-content-height item-center">
                <div className="container">
                    <div className="col-md-6 mx-auto cover cover-shadow border-0  p-0">
                        <div className="first-click-inst p-3">
                            <h5>{this.props.t('login.verfiyUser', { param: 'react' })}</h5>
                        </div>
                        {!this.props.actionReducer.verifyWithOtp &&
                            <div className="panel-body">
                                <form className="col-md-12 mx-auto" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12 ">
                                                <label>{this.props.t('login.code', { param: 'react' })}</label>
                                                <input onChange={(event) => this.handleUserInput(event)}
                                                    placeholder={this.props.t('forms.enterOtp', { param: 'react' })}
                                                    type="password" className="form-control form-eye" id="password-otp" name="otp"
                                                    defaultValue={this.state.otp} />
                                                {validator.message('otp', this.state.otp, 'required|numeric')}
                                                <span onClick={(e) => this.change('password-otp', e)}
                                                    className="input fa fa-fw field-icon toggle-password fa-eye-slash"
                                                ></span>

                                                <button type="submit" disabled={!this.props.location.query.cid} className="btn btn-primary btn-primary2 mr-2 mt-3">{this.props.t('changePassword.submit', { param: 'react' })}</button>
                                            </div>

                                        </div>
                                    </div>

                                    
                                </form>

                            </div>}
                            {this.props.actionReducer.verifyWithOtp && <div className="panel-body">
                                <div className="row">
                                    <form className="col-md-10">
                                        <div className="text-success">
                                            {this.props.t('login.otp_verified', { param: 'react' })} <br /><br />
                                            {this.props.t('login.job_rec1', { param: 'react' })}
                                            <a className="ml-1 date-clr mr-1" href="/signin">{this.props.t('login.login', { param: 'react' })} </a>
                                            {this.props.t('login.job_rec2', { param: 'react' })}<br /><br />
                                            {this.props.t('login.job_rec3', { param: 'react' })}<br /><br />
                                            {this.props.t('login.job_rec4', { param: 'react' })}
                                        </div>
                                    </form>
                                </div>
                            </div>}

                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Validation(VerifyUser)));
