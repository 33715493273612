import React, { Component } from 'react';
import SidebarSeeker from './jobseeker/Sidebar';
import SidebarProvider from './jobprovider/Sidebar';
import store from '../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import {
    getChatInvites, inviteAction
} from '../../actions/accountAction'
import Pagination from "react-js-pagination";
import { Link } from 'react-router'
import { chunk } from '../../helpers'
import ServerSetting from '../../config.json';

const RESULT_PER_PAGE = 25

class ForgienProviderTimeline extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
        }
        store.dispatch(getChatInvites());
    }

    inviteAction = (val, job) => {
        store.dispatch(inviteAction({ status: val, request_from: job.user_id }))
    }


    componentDidMount() {
        document.title = "Invites"
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }


    render() {
        const Mainprops = this.props
        const { activePage } = this.state
        const searchResults = chunk(this.props.actionReducer.chatInvites, RESULT_PER_PAGE)
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        let Sidebar = ''
        if (localStorage.getItem('login_type') === 'Jobseeker') {
            Sidebar = SidebarSeeker
        } else if (localStorage.getItem('login_type') === 'Indian Employer') {
            Sidebar = SidebarProvider
        } 

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <a href="/#" className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </a>
                        {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}


                        <main className="col-md-9 second-side p-0 pb-3 h-100">

                            <div className="clearfix"></div>

                            <div id="home" className=" tab-pane active">
                                <section className="job-details mt-4">
                                    <div className="container">
                                        <div className="row">

                                            <div className="col-md-12  mx-auto  tab-content  p-0">

                                                <div className="panel-heading">
                                                    <h6 className="mb-0">Invites</h6>

                                                </div>
                                                <div className="panel-body">

                                                    <div className="col-md-12 col-sm-12 col-lg-12 right-sidebar-scroll">
                                                        {searchResults && searchResults.length ? searchResults[activePage - 1].map((job, key) => {
                                                            return (

                                                                <div className="right-sidebar" key={key}>
                                                                    <div className="first-job mb-3 p-3">
                                                                        <div className="row">
                                                                            <div className="col-md-8">
                                                                                <div className="outr-avtar"><img alt="avatar" src={job.image ? `${SERVER_PATH}${job.image}`: '/images/img-1.png'} className="avatar mr-2 text-center" />
                                                                                <Link className="date-clr"> {job.name}</Link>
                                                                                </div>
                                                                                
                                                                                
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                            <button className="btn btn-primary btn-primary2 pull-right ml-2" onClick={() => this.inviteAction(1, job)}>Accept</button><button onClick={() => this.inviteAction(0, job)} className="btn btn-primary btn-primary2 pull-right">Decline</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }) : null}
                                                        {searchResults && searchResults.length > 1 ?
                                                            <div className='react-pagination'>
                                                                <Pagination
                                                                    pageRangeDisplayed={10}
                                                                    activePage={activePage}
                                                                    itemsCountPerPage={RESULT_PER_PAGE}
                                                                    totalItemsCount={Mainprops.actionReducer.chatInvites.length}
                                                                    onChange={this.handlePageChange}
                                                                />
                                                            </div> : ''}
                                                        {Mainprops.actionReducer.chatInvites.notFound ? <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>






                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(ForgienProviderTimeline));
