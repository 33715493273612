import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router'
import { translate } from 'react-multi-lang'
import { get_User_District } from '../../../actions/accountAction'
import store from '../../../store';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { getQualification } from '../../../helpers'

const NO_DISABILITY = 28

class JobDetails extends Component {
    constructor(props) {
        super(props);
        if (this.props.actionReducer.District && !this.props.actionReducer.District.length) {
            store.dispatch(get_User_District())
        }

        this.state = {
            locations: ''
        }
    }
    
    onSubmit = (e) => {
        e.preventDefault();
        this.props.submit(this.props.jobData.pwd)
    }

    close = (e) => {
        e.preventDefault();
        if (this.props.close) {
            this.props.close()
        }
    }

    applyForJob = () => {
        if (this.props.apply) {
            this.props.apply()
        }
    }

    getLocationFromIds = (location) => {
        const names = []
        let parsedLocation = this.parseLocation(location)
        if (this.props.actionReducer.District && this.props.actionReducer.District.length > 0 && parsedLocation && parsedLocation.length) {
            this.props.actionReducer.District.forEach((data) => {
                if (data.state_id === "3") {
                    parsedLocation.forEach(element => {
                        let filterVal = data.dist.filter((district) => district.id === element)
                        if (filterVal && filterVal[0]) {
                            names.push(filterVal[0].name)
                        }
                    });
                }
            })
        }
        return names.length ? names.toString() : ''
    }

    parseLocation = (location) => {
        return location ? JSON.parse(location) : []
    }


    checkPwd = (job) => {
        const disablityType = parseInt(this.props.JobseekerReducer.Profile.disablity_type_id)
        if(job.pwd ==='1' && disablityType && disablityType !== NO_DISABILITY){
            return true
        } else if(job.pwd ==='0' && (!disablityType || disablityType === NO_DISABILITY)){
            return true
        } else {
            return false
        }
    }

    getExperience = (jobData) => {
        if ((jobData.min_exp && jobData.max_exp && parseInt(jobData.min_exp) > 0 && parseInt(jobData.max_exp) > 0)) {
            return `${jobData.min_exp} - ${jobData.max_exp} ${this.props.t('experience.years', { param: 'react' })}`
        } else {
            return this.props.t('JobPost.fresher', { param: 'react' })
        }
    }

    getStates = (job) => {
        const names = []
        if (typeof job.state_id !== "undefined") {
            const stateArray = JSON.parse(job.state_id)
            if (stateArray && stateArray.length && this.props.actionReducer.District && this.props.actionReducer.District.length > 0) {
                stateArray.forEach((state) => {
                    let filterVal = this.props.actionReducer.District.filter((district) => district.state_id === state)
                    if (filterVal && filterVal[0]) {
                        names.push(filterVal[0].state_name)
                    }
                })

            }

        }
        return names.length ? names.toString() : ''
    }


    render() {

        const { jobData } = this.props

        let locations = []
        let locationsArray = []
        let states = ''
        if (jobData.state_id) {
            const locationList = this.getLocationFromIds(jobData.location)
            locationsArray = locationList.split(",")
            locations = locationsArray.slice(0, 3)
            locations = locations.toString()
            states = this.getStates(jobData)
        }
        
        return (
            <div className="tab-content">
                <div id="home" className=" tab-pane active">
                    <section className="job-details nogap mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12  mx-auto p-0">
                                    <div className="panel-heading">
                                        <h6 className="mb-0"> {this.props.t('homeContent.apply', { param: 'react' })}</h6>
                                    </div>

                                    <div className="panel-body">
                                        {jobData && !Object.keys(jobData).length &&
                                            <Loader style={{ position: 'fixed', top: '49%', left: '48%' }}
                                                type="Rings"
                                                color="#F1911C"
                                                height={100}
                                                width={100}
                                            />}
                                        <div className="modal fade bd-example-modal-sm" tabIndex="-1" id="locationModal"
                                            role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-sm">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title" id="mySmallModalLabel">{this.props.t('homeContent.locations', { param: 'react' })}</h4>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <ul className="loc-lisiting">
                                                            {this.state.locations && this.state.locations.length ? this.state.locations.map((loc, key) => {
                                                                return (<li key={key}><span >{loc}</span></li>)
                                                            })
                                                                : ''}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(jobData).length > 0 && !jobData.notFound ?
                                            <form onSubmit={this.onSubmit}>

                                                <div className="right-sidebar" >

                                                    <div className="first-job mb-3 p-3">
                                                        <div className="row">
                                                            <div className="col-md-8 widcustom">

                                                                <h4 className="company-name">
                                                                    <Link>{this.props.t('govtJobs.nameOfPost', { param: 'react' })}: {jobData.job_title}</Link>
                                                                </h4>

                                                                <h6 className="pt-1 company-name2 pb-1 mb-0 "><Link>{this.props.t('govtJobs.nameOfEmployer', { param: 'react' })}: {jobData.org_name}</Link></h6>
                                                                <ul className="nav">

                                                                    {jobData.state_id &&
                                                                        <li className="w-100 pb-2 pt-0 float-left"><Link >
                                                                            <span><span className="wid-30 float-left pt-2 pb-0  font-weight-bold">{this.props.t('govtJobs.placeOfPosting', { param: 'react' })} :</span>
                                                                                <span className="float-left pt-2 pb-0 "> {states} {this.parseLocation(jobData.location).length > 0 ? <span>({locations} {this.parseLocation(jobData.location).length > 3 &&
                                                                                    <span style={{ "cursor": "pointer" }} className="date-clr" data-toggle="modal"
                                                                                        data-target="#locationModal" onClick={() => this.setState({ locations: locationsArray })}>{this.props.t('homeContent.viewLocations', { param: 'react' })}</span>})
                                                                                    </span> : null}
                                                                                </span></span></Link></li>
                                                                    }
                                                                </ul>
                                                                <p className="w-100 pb-2 pt-0 float-left"><span className="wid-30 float-left p-0  font-weight-bold">{this.props.t('govtJobs.reqQual', { param: 'react' })}:</span>
                                                                    <span className="float-left p-0"> {getQualification(jobData)}</span></p>
                                                                <p className="w-100 pb-2 pt-0 float-left"><span className="wid-30 float-left p-0  font-weight-bold">{this.props.t('dashboard.table.salary', { param: 'react' })}:</span>
                                                                    <span className="float-left p-0"> {jobData.salary_min && jobData.salary_max ? jobData.salary_min + '-' + jobData.salary_max : jobData.salary_min} ({jobData.salary_type})</span></p>
                                                                {jobData.salary && <span className="pr-5"><span>{jobData.salary}</span></span>}
                                                                <div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="text-right">
                                                                    <div className="pull-right">
                                                                        {jobData.applied_on &&
                                                                            <h6 className="date-clr pull-right">{this.props.t('jobSearch.alreadyApplied', { param: 'react' })} <br></br>
                                                                                {this.props.t('jobSearch.appliedOn', { param: 'react' })} {jobData.applied_on}</h6>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="w-100 pl-3">
                                                                <span className="pr-3 date-clr">{this.props.t('govtJobs.vacancy', { param: 'react' })}: <span>{jobData.vacancies} </span></span>
                                                                <span className="pr-3 date-clr">{this.props.t('govtJobs.age', { param: 'react' })}: <span>
                                                                    {jobData.age_preference ? jobData.age_preference : ''}


                                                                </span></span>
                                                                <span className="pr-3 date-clr">{this.props.t('JobPost.exp', { param: 'react' })}: <span>
                                                                    {this.getExperience(jobData)} </span></span>

                                                                {jobData.gender_preference && <span className="pr-3 date-clr">
                                                                    {this.props.t('govtJobs.gender', { param: 'react' })}:
                                                                    <span>{" " + jobData.gender_preference} </span></span>}
                                                            </div>
                                                            <div className="row w-100 pt-4">
                                                                <div className="pull-right text-right w-100">
                                                                    {parseInt(jobData.pwd) > 0 && <span className="disabled-color mr-3" >{this.props.t('JobPost.pwdInst', { param: 'react' })}</span>}
                                                                    {!jobData.hasOwnProperty('apply_link') && <span className="disabled-color mr-3" >{this.props.t('govtJobs.postedBy', { param: 'react' })}: {jobData.postedBy ? jobData.postedBy : "Employer"}</span>}
                                                                    {jobData.hasOwnProperty('apply_link') && <span className="mr-3 disabled-color">{this.props.t('govtJobs.postedBy', { param: 'react' })}: {jobData.postedBy ? jobData.postedBy : "Admin"}</span>}
                                                                    <span className="mr-3 disabled-color">{this.props.t('homeContent.postedOn', { param: 'react' })} {jobData.created_at}
                                                                    </span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {jobData.description ? <div className="job-sec bg-white cover-shadow mb-3 p-3">
                                                    <div>
                                                        <Link>{this.props.t('JobPost.desc', { param: 'react' })}</Link>
                                                        <hr />
                                                        <span
                                                            className="text-span">{ReactHtmlParser(jobData.description)}</span>
                                                    </div>
                                                </div> : null}

                                                <div>
                                                    {!this.props.isNotSubmit && this.checkPwd(jobData) &&
                                                        <div>
                                                            {!jobData.applied_on && <button type="submit"
                                                                className="btn btn-primary btn-primary2 mt-2 mx-auto d-block">{this.props.t('homeContent.apply', { param: 'react' })}
                                                            </button>}
                                                        </div>
                                                    }
                                                    
                                                    {this.props.isNotSubmit && ((this.props.actionReducer.Loginuser &&
                                                        this.props.actionReducer.Loginuser.user_type &&
                                                        this.props.actionReducer.Loginuser.user_type === '1') ) ?
                                                        <div>
                                                            {!jobData.applied_on && <button onClick={() => this.applyForJob()}
                                                                className="btn btn-primary btn-primary2 mt-2 mx-auto d-block">{this.props.t('JobPost.goToJob', { param: 'react' })}
                                                            </button>}
                                                        </div> : null
                                                    }
                                                    {!Object.keys(this.props.actionReducer.Loginuser).length ? 
                                                    <div>
                                                    {!jobData.applied_on && <button onClick={() => this.applyForJob()}
                                                        className="btn btn-primary btn-primary2 mt-2 mx-auto d-block">{this.props.t('homeContent.apply', { param: 'react' })}
                                                    </button>}
                                                </div>
                                                    : null}
                                                </div>
                                            </form>
                                            : ''}
                                        {Object.keys(jobData).length > 0 && jobData.notFound ?
                                            <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(JobDetails));