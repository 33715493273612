import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { getMobileAppData } from "../../actions/accountAction";
import store from '../../store';

class Downloads extends Component {

    componentDidMount() {
        store.dispatch(getMobileAppData())
        document.title = this.props.t('homeContent.downloads', { param: 'react' })
    }
    render() {
        console.log('this.props.actionReducer.mobileApp.file_name', this.props.actionReducer.mobileApp.file_name)
        return (
            <div className="tab-content" style={{ "border": "none" }}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">{this.props.t('homeContent.downloads', { param: 'react' })}</h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {this.props.t('homeContent.app', { param: 'react' })} <a href={this.props.actionReducer.mobileApp.path} className="date-clr" >{this.props.t('homeContent.clickHere', { param: 'react' })}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Downloads));
