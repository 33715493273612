import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Sidebar from './Sidebar';
import store from '../../../store';
import {
    getAppliedServices,
    postVisit,
    deleteService
} from '../../../actions/lspAction'
import { getServiceArchieve } from '../../../actions/employerAction'
import Pagination from "react-js-pagination";
import { chunk, getTimeWithAmPm } from '../../../helpers'
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment'
import { Link } from 'react-router'

const RESULT_PER_PAGE = 25

class LspServices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            jobs: '',
            type: '',
            recentJobsActivePage: 1,
            description: '',
            formData: [],
            submitted: false,
            serviceArchieveActivePage: 1,
            service: []
        }
        store.dispatch(getAppliedServices());
        store.dispatch(getServiceArchieve());
        this.validator = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });
    }

    componentDidMount() {
        document.title = this.props.t('header.my_account', { param: 'react' })
    }

    getDate = (date) => {
        const newDate = date.split('-')
        return newDate[2]+"-"+newDate[1]+"-"+newDate[0]
    }

    confirmVisit = () => {
        store.dispatch(postVisit({ ...this.state.visit }));
        document.getElementById("visitModal").click()
    }

    handlePageChangeRecentJobs = (activePage) => {
        this.setState({ recentJobsActivePage: activePage })
    }

    handlePageChangeserviceArchieve = (activePage) => {
        this.setState({ serviceArchieveActivePage: activePage })
    }

    handleChangeDate = (date, key) => {
        const formData = this.state.formData
        let formatedDate = moment(date).format('YYYY-MM-DD').split("-")
        formatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
        formData[key] = formatedDate
        this.setState({ formData })
    }

    deleteService = () => {
        const { service } = this.state
        if (service) {
            store.dispatch(deleteService({ id: service.id }))
            document.getElementById("confirmBox").click()
        }
    }



    render() {
        const {
            recentJobsActivePage, serviceArchieveActivePage, visit
        } = this.state


        const recentServices = chunk(this.props.EmployerReducer.recentServices, RESULT_PER_PAGE)
        const servicesArchieve = chunk(this.props.EmployerReducer.servicesArchieve, RESULT_PER_PAGE)

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }



        let Mainprops = this.props;
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <div className="modal fade" id="confirmBox" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title" id="exampleModalLabel">{this.props.t('profileDetails.deleteInst', { param: 'react' })}</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary btn-primary2" onClick={() => this.deleteService()}>{this.props.t('profileDetails.yes', { param: 'react' })}</button>
                                        <button type="button" className="btn btn-primary btn-primary2" data-dismiss="modal">{this.props.t('profileDetails.no', { param: 'react' })}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="visitModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title" id="exampleModalLabel">{this.props.t('lsp_user.visit', { param: 'react' })}
                                          </h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                    {visit && visit.service_date ? <h6>{this.props.t('dashboard.table.date', { param: 'react' })}: {this.getDate(visit.service_date)}</h6>: ''}
                                    {visit && visit.service_time ? <h6>{this.props.t('dashboard.table.time', { param: 'react' })}: {getTimeWithAmPm(visit.service_time)}</h6>: ''}
                                    <p className="m-0">{this.props.t('lsp_user.visitInst', { param: 'react' })}</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary btn-primary2" onClick={() => this.confirmVisit()}>{this.props.t('profileDetails.yes', { param: 'react' })}</button>
                                        <button type="button" className="btn btn-primary btn-primary2" data-dismiss="modal">{this.props.t('profileDetails.no', { param: 'react' })}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <div className={"row"}>
                                                            <div className={"col-lg-6"}>
                                                                <h6 className="mb-0">{this.props.t('dashboard.title', { param: 'react' })}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body">
                                                        <form>
                                                            <div className="accordion md-accordion accordion-2 "
                                                                id="accordionEx7" role="tablist"
                                                                aria-multiselectable="true">
                                                                <div className="card border-0">
                                                                    <div
                                                                        className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                        role="tab" id="heading2">
                                                                        <a data-toggle="collapse"
                                                                            data-parent="#accordionEx7" href="#collapse2"
                                                                            aria-expanded="true"
                                                                            aria-controls="collapse2">
                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                {this.props.t('lsp_user.table.interestedCustomers', { param: 'react' })}<i
                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                            </h6>
                                                                        </a>
                                                                    </div>

                                                                    <div id="collapse2" className="collapse"
                                                                        role="tabpanel" aria-labelledby="heading2"
                                                                        data-parent="#accordionEx7">
                                                                        <div
                                                                            className="card-body mb-1 p-2 rgba-grey-light white-text">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{this.props.t('lsp_user.table.sr_no', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('lsp_user.service_seeker', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('lsp_user.table.servicename', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('profileDetails.communication', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.mobile', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('lsp_user.visit', { param: 'react' })}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {recentServices && recentServices.length > 0 && recentServices[recentJobsActivePage - 1].map((services, key) => {
                                                                                            return (<tr key={key}>
                                                                                                <td>{((recentJobsActivePage - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                <td>{services.customer_name ? services.customer_name : ''}</td>
                                                                                                <td>{services.service ? services.service : ''}</td>
                                                                                                <td>{services.customer_address ? services.customer_address : ''}</td>
                                                                                                <td>{services.mobile ? services.mobile : ''}</td>
                                                                                                <td><span className="date-clr" data-toggle="modal" data-target="#visitModal"
                                                                                                    onClick={() => {
                                                                                                        this.setState({ visit: services })
                                                                                                        this.validator.hideMessages()
                                                                                                    }}
                                                                                                    style={{ "cursor": "pointer" }}>{this.props.t('homeContent.clickHere', { param: 'react' })}</span></td>
                                                                                            </tr>)
                                                                                        })}
                                                                                        {!this.props.EmployerReducer.recentServices.length &&
                                                                                            <tr>
                                                                                                <td colSpan="6"
                                                                                                    style={{ "textAlign": "center" }}>
                                                                                                    <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>
                                                                                                </td>
                                                                                            </tr>}
                                                                                    </tbody>
                                                                                </table>
                                                                                {recentServices && recentServices.length > 1 ?
                                                                                    <div className='react-pagination'>
                                                                                        <Pagination
                                                                                            pageRangeDisplayed={10}
                                                                                            activePage={recentJobsActivePage}
                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                            totalItemsCount={this.props.EmployerReducer.recentServices.length}
                                                                                            onChange={this.handlePageChangeRecentJobs}
                                                                                        />
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card border-0">
                                                                    <div
                                                                        className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                        role="tab" id="heading2">
                                                                        <a data-toggle="collapse"
                                                                            data-parent="#accordionEx7" href="#collapse3"
                                                                            aria-expanded="true"
                                                                            aria-controls="collapse3">
                                                                            <h6 className="mb-0 white-text font-thin">
                                                                                {this.props.t('lsp_user.table.archieve', { param: 'react' })}<i
                                                                                    className="fa fa-angle-down rotate-icon pull-right"></i>
                                                                            </h6>
                                                                        </a>
                                                                    </div>

                                                                    <div id="collapse3" className="collapse"
                                                                        role="tabpanel" aria-labelledby="heading2"
                                                                        data-parent="#accordionEx7">
                                                                        <div
                                                                            className="card-body mb-1 p-2 rgba-grey-light white-text">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('lsp_user.service_name', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('lsp_user.service_cat', { param: 'react' })}</th>
                                                                                            <th>{this.props.t('dashboard.table.action', { param: 'react' })}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {servicesArchieve && servicesArchieve.length > 0 && servicesArchieve[serviceArchieveActivePage - 1].map((services, key) => {
                                                                                            return (<tr key={key}>
                                                                                                <td>{((serviceArchieveActivePage - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                <td>{services.category ? services.category : ''}</td>
                                                                                                <td>{services.service_name ? services.service_name : ''}</td>
                                                                                                <td><span
                                                                                                    className="date-clr"
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#confirmBox"
                                                                                                    onClick={() => this.setState({ service: services })}
                                                                                                    style={{ "cursor": "pointer" }}
                                                                                                >{this.props.t('sidebar.remove', { param: 'react' })}</span></td>

                                                                                            </tr>)
                                                                                        })}
                                                                                        {!this.props.EmployerReducer.servicesArchieve.length &&
                                                                                            <tr>
                                                                                                <td colSpan="6"
                                                                                                    style={{ "textAlign": "center" }}>
                                                                                                    <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>
                                                                                                </td>
                                                                                            </tr>}
                                                                                    </tbody>
                                                                                </table>
                                                                                {servicesArchieve && servicesArchieve.length > 1 ?
                                                                                    <div className='react-pagination'>
                                                                                        <Pagination
                                                                                            pageRangeDisplayed={10}
                                                                                            activePage={serviceArchieveActivePage}
                                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                                            totalItemsCount={this.props.EmployerReducer.servicesArchieve.length}
                                                                                            onChange={this.handlePageChangeserviceArchieve}
                                                                                        />
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(LspServices));
