import React, { Component } from 'react';
// import { Link } from 'react-router';
import {translate} from "react-multi-lang";

class JobSeekersPage extends Component {

    componentDidMount() {
        document.title = this.props.t('menu.aboutUs', {param: 'react'})
    }
    
    render() {
        return (
            <div>
                <section className="top-inner main-content-height animatedParent">
                    <div className="container" >
                        <div className="first-click1 mb-4 mt-4">
                      <div className={"row"}>

                          <div className={"col-md-4 col-sm-6 col-lg-3"}>
                              <img alt="/" src="/images/logo-1.png" className="img-fluid mx-auto d-block w-75"/>
                          </div>
                          <div className={"col-md-8 col-sm-6 col-lg-9"}>
                              <h4 className={""}>About Us</h4>

                          <p className="about-heading text-bold">{this.props.t('jobSeekerPage.about.desc', {param: 'react'})} To view the MoA of PGRKAM, <a href="docs/moa_of_pgrkam.pdf" target="_blank"><span style={{'color':'#007bff'}}>click here</span></a></p>
                              <hr />
                          </div>
                          <div className={"clearfix"}></div>

                          <div className={"row m-0"}>
                              <div className={"col-md-12 p-4 about-jobseeker"}>
                              <h5>{this.props.t('jobSeekerPage.about.aim', {param: 'react'})}</h5>

                                                    <ol type="A" className="pl-3 pt-2 pb-2 p-2">
                                                        <li>{this.props.t('jobSeekerPage.about.aim_a', {param: 'react'})}</li>
														<ol type="1" className="pl-3 pt-2 pb-2">
                                                            <li>{this.props.t('jobSeekerPage.about.inst1', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.inst2', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.inst3', {param: 'react'})}</li>
                                                        </ol>
                                                        <li>{this.props.t('jobSeekerPage.about.objective', {param: 'react'})}</li>
														<ol type="1" className="pl-3 pt-2 pb-2">
                                                            <li>{this.props.t('jobSeekerPage.about.obj1', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj2', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj3', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj4', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj5', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj6', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj7', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj8', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj9', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj10', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj11', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj12', {param: 'react'})}</li>
                                                            <li>{this.props.t('jobSeekerPage.about.obj13', {param: 'react'})}</li>
                                                        </ol>
													</ol>
                                                   <h5><u>{this.props.t('jobSeekerPage.about.projects', {param: 'react'})}</u></h5>
                                                   <p>{this.props.t('jobSeekerPage.about.pgr_head', {param: 'react'})}</p>
                                                   <ol type="i" className="pl-3 pt-2 pb-2">
                                                       <li>{this.props.t('jobSeekerPage.about.pgr1', {param: 'react'})}</li>
                                                       <li>{this.props.t('jobSeekerPage.about.pgr2', {param: 'react'})}</li>
                                                       <li>{this.props.t('jobSeekerPage.about.pgr3', {param: 'react'})}. </li>
                                                       <li>{this.props.t('jobSeekerPage.about.pgr4', {param: 'react'})}</li>
                                                       <li>{this.props.t('jobSeekerPage.about.pgr5', {param: 'react'})}</li>

                                                   </ol>

                                                   {/* <h5><u>{this.props.t('jobSeekerPage.about.progress', {param: 'react'})}</u></h5>
                                                   <img alt={"Progress"} src="/images/progress.png" className={"w-100 mb-3 mt-2"}/>*/}

                                                  <h5><u>{this.props.t('jobSeekerPage.about.office', {param: 'react'})}</u></h5>

                                                  <ul type="bullet" className="pl-3 pt-2 pb-2">
                                                      <li>{this.props.t('jobSeekerPage.about.off_loc1', {param: 'react'})}</li>
                                                      <li>{this.props.t('jobSeekerPage.about.off_loc2', {param: 'react'})}</li>
                                                      <li>{this.props.t('jobSeekerPage.about.off_loc3', {param: 'react'})}</li>
                                                  </ul>



                                                   <h5><u>{this.props.t('jobSeekerPage.about.officers', {param: 'react'})}</u></h5>
                                                   
                                                   <ul type="circle" className="pl-3 pt-2 pb-2">
                                                       <li>{this.props.t('jobSeekerPage.about.off1', {param: 'react'})}</li>
                                                       <li>{this.props.t('jobSeekerPage.about.off2', {param: 'react'})}</li>
                                                       <li>{this.props.t('jobSeekerPage.about.off3', {param: 'react'})}</li>
                                                       <li>{this.props.t('jobSeekerPage.about.off4', {param: 'react'})}</li>
                                                       <li>{this.props.t('jobSeekerPage.about.off5', {param: 'react'})}</li>
                                                       <li>{this.props.t('jobSeekerPage.about.off6', {param: 'react'})}</li>
                                                       <li>{this.props.t('jobSeekerPage.about.off7', {param: 'react'})}</li>
                                                   </ul>

                                                   <h5><u>{this.props.t('jobSeekerPage.about.abt_contact', {param: 'react'})}</u></h5>
                                                   <ul type="circle" className="pl-3 pt-2 pb-2">
                                                       <li>{this.props.t('jobSeekerPage.about.abt_E-mail', {param: 'react'})} <span style={{marginLeft:"3%"}}> - pgrkam.degt@gmail.com</span></li>
                                                       <li>{this.props.t('jobSeekerPage.about.abt_phone', {param: 'react'})}  - 01725011186<br/>
                                                                                                                          <span style={{marginLeft:"8%"}}>01725011185</span><br/>
                                                                                                                          <span style={{marginLeft:"8%"}}>01725011184</span>
                                                        
                                                        </li>
                                                       
                                                   </ul>
                                                   
                                                      

                              </div>
                          </div>
                          </div>
                      

                      </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default translate(JobSeekersPage);
