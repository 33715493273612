import React, { Component } from 'react';
// import { Link } from 'react-router';
import {translate} from "react-multi-lang";

class skilldevelopmentContact extends Component {

    componentDidMount() {
        document.title = this.props.t('menu.aboutUs', {param: 'react'})
    }
    
    render() {
        return (
            <div className="tab-content" style={{'border':'none'}}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        {/* <h6 className="mb-0"><a href="/aboutus" style={{'color':'#fff'}}>HOME:-Skill Development</a></h6> */}
                                        <h6 className="mb-0"><span>Contact: Skill Development</span>
                                        <span class="float-right p-0 mt-0" ><a href="/aboutus"><u style={{'color':'#000', fontSize:'12px'}}>Home-Skill Development</u></a></span></h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive testable">
                                                    {/* <h2>RTI</h2> */}
                                                    <ol>
                                                        <li style={{fontSize:'20px'}}>
                                                            <a href="\docs\contact_list.pdf" target="_blank">1. HQ & District Offices </a>
                                                        </li>
                                                    </ol>
                    
                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}


export default translate(skilldevelopmentContact);
