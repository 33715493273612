import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Pagination from "react-js-pagination";
import { chunk, getTimeWithAmPm } from '../../helpers'
import { Link } from 'react-router'
import store from "../../store"
import Autocomplete from 'react-autocomplete'
import {
    searchLocalServices, checkForAppliedServiceByMobile, applyLocalServices, user_verify,
    set_OTP, Resendotp, setApplyServiceResp, set_service_applied, resetUserVerification,
    get_User_District, get_User_Cities, get_User_Subistrict, getServiceSuggestions,
    getDistance
} from "../../actions/accountAction";

import { sendServiceFeedback } from '../../actions/jobseekerAction'
import SimpleReactValidator from 'simple-react-validator';
import StarRatings from 'react-star-ratings'
import ServerSetting from '../../config.json';

const RESULT_PER_PAGE = 25

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            formData: [],
            formData1: [],
            submitted: false,
            description: '',
            serviceData: [],
            submitted1: false,
            service: [],
            verified: false,
            submitted2: false
        }
        this.modalRef = React.createRef();

        store.dispatch(getServiceSuggestions())

        this.validator1 = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });

        this.validator2 = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });

        this.validator3 = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });

        this.validator4 = new SimpleReactValidator({
            element: message => <div style={{ "color": "red", fontSize: "13px" }}>{message}</div>
        });

        this.feedbackRef1 = React.createRef();
        store.dispatch(searchLocalServices(this.state.formData))
        store.dispatch(get_User_District())
        store.dispatch(getDistance())
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    componentDidMount() {
        document.title = this.props.t('lsp_user.searchProvider', { param: 'react' })
        navigator.geolocation.getCurrentPosition(
            (position) => {
              this.setState({cords: position.coords})
            },
            function(error) {
              console.error("Error Code = " + error.code + " - " + error.message);
            }
          );
    }

    submitSearch = (e) => {
        e.preventDefault()
        if (this.validator4.allValid() && !this.state.submitted) {
            this.setState({ activePage: 1 })
            const data = this.state.formData
            const { cords } = this.state
            if (cords) {
                data.latitude = cords.latitude
                data.longitude = cords.longitude
            }
            store.dispatch(searchLocalServices(data))
            this.setState({ submitted: true })
        } else {
            this.validator4.showMessages();
            this.forceUpdate();
        }
    }

    submitVisit = async (e) => {
        e.preventDefault();
        const serviceData = this.state.serviceData
        const { provider_id, service_id } = this.state.service
        serviceData['provider_id'] = provider_id
        serviceData['service_id'] = service_id
        if (this.validator1.allValid() && !this.state.submitted1) {
            store.dispatch(applyLocalServices(serviceData));
            await this.validator1.hideMessages();
            this.setState({ submitted1: true })
        } else {
            this.validator1.showMessages();
            this.forceUpdate();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { mobile } = this.state.serviceData
        if (nextProps.actionReducer.serviceStatus && nextProps.actionReducer.serviceStatus === 2
            && !this.state.verified) {
            store.dispatch(Resendotp({ mobile, registered: false }))
            this.setState({ verified: true })
        }
    }


    handleInputApply = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let serviceData = this.state.serviceData;
        if (name === 'district') {
            store.dispatch(get_User_Subistrict(value, "service_subdistrict"));
            delete serviceData["subdistrict_id"]
            delete serviceData['city_id']
        }
        if (name === 'subdistrict_id') {
            store.dispatch(get_User_Cities(value, "service_city"));
            delete serviceData['city_id']
        }
        serviceData[name] = value
        this.setState({ serviceData, submitted1: false });
    }

    openFeedback = (job) => {
        let formData1 = []
        if (this.props.actionReducer.Loginuser && Object.keys(this.props.actionReducer.Loginuser).length > 0) {
            let profile = this.props.actionReducer.Loginuser
            formData1['mobile'] = profile.username
        }
        delete formData1['rating']
        this.setState({ service: job, formData1 })
    }

    handleInputApply1 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let serviceData = this.state.serviceData;
        serviceData[name] = value
        this.setState({ serviceData, submitted2: false });
    }

    setServiceName = (value) => {
        const formData = this.state.formData
        formData['service_name'] = value
        this.setState({ formData })
    }

    resetStore = async (service) => {
        let serviceData = []
        if (this.props.actionReducer.Loginuser && Object.keys(this.props.actionReducer.Loginuser).length > 0) {
            let profile = this.props.actionReducer.Loginuser
            if (profile.district) {
                store.dispatch(get_User_Subistrict(profile.district, "service_subdistrict"));
            }
            if (profile.subdistrict_id) {
                store.dispatch(get_User_Cities(profile.subdistrict_id, "service_city"));
            }
            serviceData['mobile'] = profile.username
            serviceData['district'] = profile.district
            serviceData['subdistrict_id'] = profile.subdistrict_id
            serviceData['city_id'] = profile.city_id
            serviceData['customer_address'] = profile.customer_address
            serviceData['customer_name'] = profile.displayName
            this.setState({ serviceData })
        }
        if (this.props.notificationReducer.openotp) {
            store.dispatch(set_OTP(false))
        }
        if (this.props.actionReducer.serviceStatus) {
            store.dispatch(setApplyServiceResp(0))
        }
        if (this.props.notificationReducer.user_verify) {
            store.dispatch(resetUserVerification())
        }
        store.dispatch(set_service_applied(false))
        await this.validator1.hideMessages();
        await this.validator3.hideMessages();
        await this.validator1.purgeFields();
        await this.validator3.purgeFields();
        this.setState({ serviceData, verified: false, submitted2: false, submitted1: false, service })
    }

    handleInputs = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        if (name === 'district_id') {
            store.dispatch(get_User_Subistrict(value));
            delete formData["subdistrict_id"]
            delete formData['city_id']
        }
        if (name === 'subdistrict_id') {
            store.dispatch(get_User_Cities(value));
            delete formData['city_id']
        }
        if (!value) {
            delete formData[name]
        } else {
            formData[name] = value
        }
        this.setState({ formData, submitted: false });
    }

    verifyUserMobile = async (e) => {
        e.preventDefault()
        const { mobile, otp } = this.state.serviceData
        const { service_id } = this.state.service

        if (this.validator3.allValid() && !this.state.submitted2) {
            if (!otp) {
                store.dispatch(checkForAppliedServiceByMobile({ mobile, service_id }))
            } else {
                store.dispatch(user_verify({ username: mobile, otp, verifyForGuest: true }))
            }
            await this.validator3.hideMessages();
            this.setState({ submitted2: true })
        } else {
            this.validator3.showMessages();
            this.forceUpdate();
        }
    }

    ratingChanged = (val) => {
        const formData1 = this.state.formData1
        formData1['rating'] = val
        this.setState({ formData1 })
    }

    submitFeedback = async (e) => {
        e.preventDefault()
        const formData1 = this.state.formData1
        const { service_id, provider_id } = this.state.service
        if (this.validator2.allValid()) {
            formData1['service_id'] = service_id
            formData1['provider_id'] = provider_id
            await this.setState({ formData1 })
            await store.dispatch(sendServiceFeedback(this.state.formData1))
            await this.validator2.hideMessages()
            this.setState({ formData1: [] })
            this.feedbackRef1.current.click()
        } else {
            this.validator2.showMessages();
            this.forceUpdate();
        }
    }

    change = (e) => {
        if (this.props.actionReducer.Loginuser && !Object.keys(this.props.actionReducer.Loginuser).length) {
            const name = e.target.name;
            const value = e.target.value;
            let formData1 = this.state.formData1;
            formData1[name] = value;
            this.setState({ formData1 });
        }
    }

    changeTextArea = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData1 = this.state.formData1;
        formData1[name] = value;
        this.setState({ formData1 });
    }

    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map(function (data) {
                if (data.state_id === '3') {
                    data.dist.map(function (distdata, distindex) {
                        if (distindex > 0) {
                            Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
                            return Distoption
                        }
                        return ''
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }

    getServiceTimings = (job) => {
        return getTimeWithAmPm(job.service_start_time) + ' - ' + getTimeWithAmPm(job.service_end_time)
    }

    render() {
        const searchResult = chunk(this.props.actionReducer.localServices, RESULT_PER_PAGE)
        const { activePage, formData, serviceData, formData1, service, cords } = this.state
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH

        const html = <div className="right-sidebar">
            <div className="first-job mb-3 p-3">
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="company-name">
                            <Link>{service.org_name}</Link>
                            <div className="outr-avtar">
                                <img alt="avatar"
                                    src={service.profile_image ? `${SERVER_PATH}${service.profile_image}` : '/images/img-1.png'}
                                    className="avatar mr-2 text-center" />
                            </div>
                        </h4>
                        <ul className="service-li" style={{ marginLeft: "12%" }}>
                            <li className="pr-5">
                                <b>{this.props.t('profileDetails.communication', { param: 'react' })}</b>: {service.Address ? service.Address+', ' : ''} {service.district ? service.district+", " : ''} {service.subdistrict ? service.subdistrict+', ' : ''} {service.city ? service.city : ''}
                            </li>

                            {service.rating ?
                                <li>
                                    <StarRatings
                                        rating={service.rating}
                                        numberOfStars={5}
                                        name='rating'
                                        starDimension="20px"
                                        starRatedColor="rgb(255, 215, 0)"
                                        starHoverColor="rgb(255, 215, 0)"
                                    /> </li>
                                : null}

                            <li className="pr-5">
                                <b>{this.props.t('lsp_user.service_name', { param: 'react' })}</b>: {service.services_name}
                            </li>
                            {service.service_start_time && service.service_end_time ?
                            <li className="pr-5">
                                <b>{this.props.t('lsp_user.service_time', { param: 'react' })}</b>: {this.getServiceTimings(service)}
                            </li>: null}
                            <li className="pr-5">
                                <b>{this.props.t('lsp_user.contactProvider', { param: 'react' })}</b>: {service.mobile}
                            </li>
                        </ul>
                        <hr></hr>
                        <span style={{ color: "#000" }}>Disclaimer :  Please be careful while dealing with Service Provider. Portal is just for facilitation.</span>
                    </div>
                </div>
            </div>
        </div>

        const formHtml = <form onSubmit={this.submitVisit}>

            <div>
                <div className="row">
                    <div className="col-sm-4 text-right pr-0">
                        <label data-placement="bottom"
                        >{this.props.t('lsp_user.mobile', { param: 'react' })}<span className="ml-2"
                            style={{ "color": "red" }}>*</span></label>
                    </div>
                    <div className="col-sm-8 form-group">
                        <input type="text"
                            readOnly={true}
                            value={serviceData.mobile ? serviceData.mobile : ''}
                            className="form-control"
                            name="mobile"
                            placeholder={this.props.t('forms.enterMobile', { param: 'react' })} />
                        {this.validator1.message('mobile', serviceData.mobile, 'required|phone')}

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 text-right pr-0">
                        <label data-placement="bottom"
                        >{this.props.t('lsp_user.customer_name', { param: 'react' })}<span className="ml-2"
                            style={{ "color": "red" }}>*</span></label>
                    </div>
                    <div className="col-sm-8 form-group">
                        <input type="text"
                            onChange={this.handleInputApply}
                            value={serviceData.customer_name ? serviceData.customer_name : ''}
                            className="form-control"
                            name="customer_name"
                            placeholder={this.props.t('lsp_user.enterCustomerName', { param: 'react' })} />
                        {this.validator1.message('customer_name', serviceData.customer_name, 'required')}

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 text-right pr-0">
                        <label data-placement="bottom"
                        >{this.props.t('lsp_user.service_date', { param: 'react' })}<span className="ml-2"
                            style={{ "color": "red" }}>*</span></label>
                    </div>
                    <div className="col-sm-8 form-group">
                        <input type="date"
                            onChange={this.handleInputApply}
                            value={serviceData.service_date ? serviceData.service_date : ''}
                            className="form-control"
                            name="service_date"
                        />
                        {this.validator1.message('service_date', serviceData.service_date, 'required')}

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 text-right pr-0">
                        <label data-placement="bottom"
                        >{this.props.t('lsp_user.service_time_opted', { param: 'react' })}<span className="ml-2"
                            style={{ "color": "red" }}>*</span></label>
                    </div>
                    <div className="col-sm-8 form-group">
                        <input type="time"
                            onChange={this.handleInputApply}
                            value={serviceData.service_time ? serviceData.service_time : ''}
                            className="form-control"
                            name="service_time"
                        />
                        {this.validator1.message('service_time', serviceData.service_time, 'required')}

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 text-right pr-0">
                        <label data-placement="bottom"
                        >{this.props.t('profileDetails.communication', { param: 'react' })}<span className="ml-2"
                            style={{ "color": "red" }}>*</span></label>
                    </div>
                    <div className="col-sm-8 form-group">
                        <input type="text"
                            onChange={this.handleInputApply}
                            value={serviceData.customer_address ? serviceData.customer_address : ''}
                            className="form-control"
                            name="customer_address"
                            placeholder={this.props.t('lsp_user.enterCustomerAddress', { param: 'react' })} />
                        {this.validator1.message('customer_address', serviceData.customer_address, 'required')}

                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-4 text-right pr-0">
                        <label data-placement="bottom"
                        >{this.props.t('profileDetails.district', { param: 'react' })}<span className="ml-2"
                            style={{ "color": "red" }}>*</span></label>
                    </div>
                    <div className="col-sm-8 form-group">
                        <select onChange={this.handleInputApply} value={serviceData.district ? serviceData.district : ''}
                            className="form-control" name="district">
                            <option value="">{this.props.t('forms.selectDis', { param: 'react' })}</option>
                            {this.createDistoption()}
                        </select>
                        {this.validator1.message('district', serviceData.district, 'required')}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 text-right pr-0">
                        <label data-placement="bottom"
                        >{this.props.t('profileDetails.subdistrict', { param: 'react' })}<span className="ml-2"
                            style={{ "color": "red" }}>*</span></label>
                    </div>
                    <div className="col-sm-8 form-group">
                        <select
                            value={serviceData.subdistrict_id ? serviceData.subdistrict_id : ""}
                            className="form-control"
                            name="subdistrict_id"
                            onChange={(e) => this.handleInputApply(e)}>
                            <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('profileDetails.subdistrict', { param: 'react' })}</option>
                            {this.props.actionReducer.JSubdistrict && this.props.actionReducer.JSubdistrict.length ?
                                this.props.actionReducer.JSubdistrict.map((sub, key) => {
                                    return <option key={key} value={sub.id}>{sub.name}</option>
                                })

                                : null}
                        </select>
                        {this.validator1.message('sub-district', serviceData.subdistrict_id, 'required')}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 text-right pr-0">
                        <label data-placement="bottom"
                        >{this.props.t('jobProviderProfile.city', { param: 'react' })}<span className="ml-2"
                            style={{ "color": "red" }}>*</span></label>
                    </div>
                    <div className="col-sm-8 form-group">
                        <select
                            value={serviceData.city_id ? serviceData.city_id : ""}
                            onChange={(event) => this.handleInputApply(event)}
                            className="form-control"
                            name="city_id">
                            <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('jobProviderProfile.city/village', { param: 'react' })}</option>
                            {this.props.actionReducer.JCities.length > 0 &&
                                this.props.actionReducer.JCities.map(function (data, index) {
                                    return (
                                        <option key={index}
                                            value={data.id}>{data.name}</option>
                                    )
                                })
                            }
                        </select>
                        {this.validator1.message('city', serviceData.city_id, 'required')}
                    </div>
                </div>
                <hr />
                <button type="submit"
                    className="mx-auto d-block btn btn-primary btn-primary2 mt-1">{this.props.t('profileDetails.submit', { param: 'react' })}</button>
            </div>
        </form>
        let containerClass = ''
        let resultClass = ''
        if (!this.props.actionReducer.localServices.length) {
            containerClass = 'spread-show'
            resultClass = 'height0'
        }

        return (
            <div>
                <div className="modal" id="feedbackModal22" ref={this.feedbackRef1}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.props.t('lsp_user.feedback', { param: 'react' })}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <form onSubmit={this.submitFeedback}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{this.props.t('lsp_user.mobile', { param: 'react' })} : <span
                                                    className="ml-2"
                                                    style={{ "color": "red" }}>*</span></label>

                                                <input className="form-control"
                                                    onChange={this.change}
                                                    readOnly={this.props.actionReducer.Loginuser && Object.keys(this.props.actionReducer.Loginuser).length > 0}
                                                    value={formData1.mobile ? formData1.mobile : ''}
                                                    placeholder={this.props.t('forms.enterMobile', { param: 'react' })}
                                                    name="mobile"
                                                />
                                                {this.validator2.message('mobile', formData1.mobile, 'required|phone')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{this.props.t('lsp_user.feedback', { param: 'react' })} : <span
                                                    className="ml-2"
                                                    style={{ "color": "red" }}>*</span></label>
                                                <textarea className="w-100 p-1"
                                                    onChange={this.changeTextArea}
                                                    value={formData1.remarks ? formData1.remarks : ''}
                                                    placeholder={this.props.t('forms.enterFeedback', { param: 'react' })}
                                                    style={{ "height": "200px !important" }}
                                                    id="date" name="remarks" rows="4"
                                                    cols="8" />
                                                {this.validator2.message('feedback', formData1.remarks, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{this.props.t('feedback.rating', { param: 'react' })} : <span
                                                    className="ml-2"
                                                    style={{ "color": "red" }}>*</span></label>
                                                <div>
                                                    <StarRatings
                                                        rating={formData1.rating}
                                                        numberOfStars={5}
                                                        name='rating'
                                                        starDimension="20px"
                                                        starRatedColor="rgb(255, 215, 0)"
                                                        starHoverColor="rgb(255, 215, 0)"
                                                        changeRating={this.ratingChanged}
                                                    />
                                                </div>
                                                {this.validator2.message('rating', formData1.rating, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <button type="submit"
                                                    className="btn btn-primary btn-primary2 mx-auto d-block"
                                                    style={{ "marginRight": "10px" }}>
                                                    {this.props.t('feedback.submit', { param: 'react' })}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" data-backdrop="static" id="visitModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('lsp_user.contactService', { param: 'react' })}</h5>
                                <button type="button" className="close" id="closeBtn" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {!this.props.actionReducer.serviceApplied ? <>{formHtml}</> : null}
                                {this.props.actionReducer.serviceApplied ? <div>
                                    <h6>{this.props.actionReducer.serviceApplied === 1 ? "Service Applied" : "Already Applied"}</h6>
                                    <>{html}</>
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" data-backdrop="static" id="visitModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('lsp_user.contactService', { param: 'react' })}</h5>
                                <button type="button" className="close" id="closeBtn" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.props.actionReducer.serviceStatus && this.props.actionReducer.serviceStatus === 1 ?
                                    <div>
                                        <h6>{this.props.t('lsp_user.alreadyApplied', { param: 'react' })}</h6>
                                        {html}
                                    </div> : null}
                                {this.props.actionReducer.serviceStatus === 1 || this.props.actionReducer.serviceStatus === 3 || this.props.notificationReducer.user_verify ?
                                    null : <form onSubmit={this.verifyUserMobile}>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-4 text-right pr-0">
                                                    <label data-placement="bottom"
                                                    >{this.props.t('lsp_user.mobile', { param: 'react' })}<span className="ml-2"
                                                        style={{ "color": "red" }}>*</span></label>
                                                </div>
                                                <div className="col-sm-8 form-group">
                                                    <input type="text"
                                                        onChange={this.handleInputApply1}
                                                        value={serviceData.mobile ? serviceData.mobile : ''}
                                                        className="form-control"
                                                        name="mobile"
                                                        placeholder={this.props.t('forms.enterMobile', { param: 'react' })} />
                                                    {this.validator3.message('mobile', serviceData.mobile, 'required|phone')}

                                                </div>
                                            </div>
                                            {this.props.notificationReducer.openotp ?
                                                <div className="row">
                                                    <div className="col-sm-4 text-right pr-0">
                                                        <label data-placement="bottom"
                                                        >{this.props.t('lsp_user.otp', { param: 'react' })}<span className="ml-2"
                                                            style={{ "color": "red" }}>*</span></label>
                                                    </div>
                                                    <div className="col-sm-8 form-group">
                                                        <input type="text"
                                                            onChange={this.handleInputApply1}
                                                            value={serviceData.otp ? serviceData.otp : ''}
                                                            className="form-control"
                                                            name="otp" placeholder={this.props.t('forms.enterOtp', { param: 'react' })} />
                                                        {this.validator3.message('otp', serviceData.otp, 'required')}

                                                    </div>
                                                </div> : null}
                                            <hr />

                                            <button type="submit"
                                                className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                {this.props.t('lsp_user.verifyMobile', { param: 'react' })}
                                            </button>
                                        </div>
                                    </form>}

                                {this.props.actionReducer.serviceApplied ? <div>
                                    <h6>{this.props.t('lsp_user.serviceApplied', { param: 'react' })}</h6>
                                    {html}
                                </div> : null}
                                {(this.props.actionReducer.serviceStatus === 3 || this.props.notificationReducer.user_verify) && !this.props.actionReducer.serviceApplied ?
                                    <>{formHtml}</> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <section className="total-job">
                    <div className="container">
                        <div className={`row ${containerClass}`}>
                            <div className="modal fade" id="serviceModal" style={{ 'zIndex': '99999' }}>
                                <div className="modal-dialog modal-dialog-centered modal-lg" >
                                    <div className="modal-content d-block">
                                        <div className="modal-body pt-2  pb-1 text-center">
                                            <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                            <h5 className="mx-auto  text-uppercase mb-3">{this.props.t('homeContent.pgrkamTitle', { param: 'react' })}</h5>
                                            <div className="row d-flex">
                                                <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex ">
                                                    <div className="first-click">
                                                        <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                                                        <p> {this.props.t('homeContent.inst1', { param: 'react' })}</p>
                                                        <a href="/signin" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickToLogin', { param: 'react' })}</a>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                                    <div className="first-click">
                                                        <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                                                        <p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst2', { param: 'react' })}</p>
                                                        <a href="/signup?type=8" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickToRegister', { param: 'react' })}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12">
                                <h4 style={{ 'color': '#fff' }}>{this.props.t('homeContent.localServices', { param: 'react' })}</h4>
                                <form className="pb-0" onSubmit={this.submitSearch}>
                                    <div className="col-lg-12 col-xl-12 mx-auto mt-5 mb-3 ">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-12 p-0 test">
                                                <Autocomplete
                                                    className="w-100 form-control"
                                                    items={
                                                        this.props.actionReducer.serviceSuggestions}
                                                    shouldItemRender={(item, value) =>
                                                        item.services_name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                    renderItem={(item, isHighlighted) =>
                                                        <div key={item.services_name}
                                                            style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                                            {item.services_name}
                                                        </div>
                                                    }
                                                    renderInput={(props) => {
                                                        return <input className="search-slt" placeholder="Plumber, Online Fitness" {...props} />
                                                    }}
                                                    wrapperStyle={{ "width": "100%" }}
                                                    menuStyle={{
                                                        "zIndex": "9",
                                                        "border": "1px solid #ccc",
                                                        "boxShadow": "1px 2px 10px #ccc",
                                                        "padding": "4px",
                                                        "fontSize": "14px"
                                                    }}
                                                    value={formData.service_name ? formData.service_name : ''}
                                                    onChange={(e) => this.setServiceName(e.target.value)}
                                                    getItemValue={(item) => item.services_name}
                                                    onSelect={(val) => this.setServiceName(val)}
                                                />
                                                {this.validator4.message('service_name', formData.service_name, 'required')}
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-12 p-0">
                                                <select
                                                    value={formData.district_id ? formData.district_id : ""}
                                                    className="search-slt"
                                                    name="district_id"
                                                    onChange={(e) => this.handleInputs(e)}>
                                                    <option value="">{this.props.t('forms.selectDis', { param: 'react' })}</option>
                                                    {this.createDistoption()}
                                                </select>
                                                {/* {this.validator4.message('district', formData.district_id, 'required')} */}
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-12 p-0">
                                                <select
                                                    value={formData.subdistrict_id ? formData.subdistrict_id : ""}
                                                    className="search-slt"
                                                    name="subdistrict_id"
                                                    onChange={(e) => this.handleInputs(e)}>
                                                    <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('profileDetails.subdistrict', { param: 'react' })}</option>
                                                    {this.props.actionReducer.Subdistrict && this.props.actionReducer.Subdistrict.length ?
                                                        this.props.actionReducer.Subdistrict.map((sub, key) => {
                                                            return <option key={key} value={sub.id}>{sub.name}</option>
                                                        })

                                                        : null}
                                                </select>
                                                {/* {this.validator4.message('sub-district', formData.subdistrict_id, 'required')} */}
                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-12 p-0">
                                                <select
                                                    value={formData.city_id ? formData.city_id : ""}
                                                    onChange={(event) => this.handleInputs(event)}
                                                    className="search-slt"
                                                    name="city_id">
                                                    <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('jobProviderProfile.city/village', { param: 'react' })}</option>
                                                    {this.props.actionReducer.Cities.length > 0 &&
                                                        this.props.actionReducer.Cities.map(function (data, index) {
                                                            return (
                                                                <option key={index}
                                                                    value={data.id}>{data.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                {/* {this.validator4.message('city', formData.city_id, 'required')} */}
                                            </div>
                                            {cords &&
                                                <div className="col-lg-2 col-md-2 col-sm-12 p-0">
                                                    <select
                                                        value={formData.nearBy ? formData.nearBy : ""}
                                                        className="search-slt"
                                                        name="nearBy"
                                                        onChange={(e) => this.handleInputs(e)}>
                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('counsellingProvider.nearBy', { param: 'react' })}</option>
                                                        {this.props.actionReducer.distance && this.props.actionReducer.distance.length ?
                                                            this.props.actionReducer.distance.map((dist, key) => {
                                                                return <option key={key} value={dist.id}>{dist.name}</option>
                                                            })

                                                            : null}
                                                    </select>

                                                </div>}

                                            <div className="col-lg-2 col-md-2 col-sm-12 p-0">
                                                <button type="submit" className="btn text-white wrn-btn">{this.props.t('jobSearch.search', { param: 'react' })}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="job-details">
                    <div className="container">
                        <div className="row">
                            <div className={`col-md-12 col-sm-12 col-lg-12 right-sidebar-scroll ${resultClass}`}>
                                {this.props.actionReducer.localServices && this.props.actionReducer.localServices.status === '401' &&
                                    <div className="right-sidebar"><h5 className="first-job mb-3 p-3">
                                        {this.props.t('JobPost.notFound', { param: 'react' })}</h5></div>}

                                {searchResult && searchResult.length ?
                                    searchResult[activePage - 1].map((job, key) => {
                                        return (
                                            <div className="right-sidebar" key={key}>
                                                <div className="first-job mb-3 p-3">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <h4 className="company-name">
                                                                <Link>{job.org_name}</Link>
                                                                <div className="outr-avtar">
                                                                    <img alt="avatar"
                                                                        src={job.profile_image ? `${SERVER_PATH}${job.profile_image}` : '/images/img-1.png'}
                                                                        className="avatar mr-2 text-center" />
                                                                </div>
                                                            </h4>
                                                            <ul className="service-li">
                                                                <li className="pr-5">
                                                                    <b>{this.props.t('profileDetails.communication', { param: 'react' })}</b>: {job.Address ? job.Address+', ' : ''} 
                                                                    {job.district ? job.district+", " : ''} {job.subdistrict ? job.subdistrict+', ' : ''} {job.city ? job.city : ''}
                                                                </li>

                                                                {job.rating ?
                                                                    <li>
                                                                        <StarRatings
                                                                            rating={job.rating}
                                                                            numberOfStars={5}
                                                                            name='rating'
                                                                            starDimension="20px"
                                                                            starRatedColor="rgb(255, 215, 0)"
                                                                            starHoverColor="rgb(255, 215, 0)"
                                                                        /> </li>
                                                                    : null}

                                                                <li className="pr-5">
                                                                    <b>{this.props.t('lsp_user.service_name', { param: 'react' })}</b>: {job.services_name}
                                                                </li>
                                                                {job.service_start_time && job.service_end_time ?
                                                                    <li className="pr-5">
                                                                        <b>{this.props.t('lsp_user.service_time', { param: 'react' })}</b>: {this.getServiceTimings(job)}
                                                                    </li>
                                                                    : null}

                                                                {job.img ?
                                                                    <li className="pr-5">
                                                                        <a target="_blank"
                                                                            className="date-clr"
                                                                            rel="noopener noreferrer"
                                                                            href={`${SERVER_PATH}${job.img}`}
                                                                        >{this.props.t('lsp_user.priceTagLink', { param: 'react' })}</a></li>
                                                                    : null}
                                                                <li className="pr-5">
                                                                    <span className="date-clr" style={{ "cursor": "pointer" }}
                                                                        data-toggle="modal"
                                                                        onClick={() => this.openFeedback(job)}
                                                                        data-target="#feedbackModal22" >{this.props.t('feedback.feed', { param: 'react' })}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-4">
                                                            {this.props.actionReducer.Loginuser && !Object.keys(this.props.actionReducer.Loginuser).length ?
                                                                <button
                                                                    className="btn btn-primary btn-primary2 pull-right"
                                                                    data-toggle="modal" data-target="#visitModal"
                                                                    onClick={() => this.resetStore(job)}
                                                                > {this.props.t('counsellingProvider.I am interested', { param: 'react' })}	</button>
                                                                : <>{this.props.actionReducer.Loginuser.user_type !== '8' && <button
                                                                    className="btn btn-primary btn-primary2 pull-right"
                                                                    data-toggle="modal" data-target="#visitModal1"
                                                                    onClick={() => this.resetStore(job)}
                                                                > {this.props.t('lsp_user.apply', { param: 'react' })}	</button>}</>}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        )
                                    }) : ''}

                            </div>
                            {searchResult && searchResult.length > 1 ?
                                <div className='react-pagination pagination-cls'>
                                    <Pagination
                                        pageRangeDisplayed={10}
                                        activePage={activePage}
                                        itemsCountPerPage={RESULT_PER_PAGE}
                                        totalItemsCount={this.props.actionReducer.localServices.length}
                                        onChange={this.handlePageChange}
                                    />
                                </div> : ''}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Services));
// export default ;
