import React, { Component } from 'react';
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { chunk } from '../../../helpers'
import Pagination from "react-js-pagination";
// import { Link } from 'react-router'
import {
    getAppliedCandidates, setShortlistedCandidates, selectCandidatesForJob,
    // getTimeSlots, updateTimeSlot
} from '../../../actions/employerAction'
import Loader from 'react-loader-spinner';
import { Link } from 'react-router'

const RESULT_PER_PAGE = 25

class CandidatesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formDataPass: [],
            activePage: 1,
            Profile: [],
            job: [],
            submit: false,
            selectedCheckboxes: new Set(),
            candidatesArray: [],
            loaded: false,
            editSlot: false
        }
        if (this.props.params && this.props.params.job_id) {
            store.dispatch(getAppliedCandidates(this.props.params.job_id));
        }
        // store.dispatch(getTimeSlots());
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    applyForSession = () => {
        let seats = this.props.EmployerReducer.shortListed.rows
        store.dispatch(selectCandidatesForJob({
            job_id: this.props.params.job_id,
            users: this.state.candidatesArray,
            start_date: seats[0].slot_date_start,
            slot: seats[0].time_slot,
            auth_key: localStorage.getItem('token')
        }
        ))
        this.setState({ candidatesArray: [], selectedCheckboxes: new Set() })
    }

    // handleSlotChange = (e) => {
    //     e.persist()
    //     if(e.target.value){
    //         store.dispatch(updateTimeSlot({
    //             job_id: this.props.params.job_id,
    //             time_slot: e.target.value,
    //             auth_key: localStorage.getItem('token')
    //         }))
    //     }
    // }

    handleCheckbox = async (e, job) => {
        e.persist()
        let seats = this.props.EmployerReducer.shortListed.rows
        let count = this.props.EmployerReducer.shortListed.count
        seats = seats.filter((coun) => coun.job_status === "3")
        if (seats && seats.length) {
            seats = count - seats.length
        } else {
            seats = count
        }

        if (this.state.candidatesArray.length === seats && e.target.checked) {
            e.target.checked = false
        }
        else {
            let value = e.target.value;
            let selectedCheckboxes = this.state.selectedCheckboxes

            if (selectedCheckboxes.has(value)) {
                selectedCheckboxes.delete(value);
            } else {
                selectedCheckboxes.add(value);
            }

            await this.setState({ selectedCheckboxes }, () => {
                value = Array.from(this.state.selectedCheckboxes)

            })

            this.setState({ candidatesArray: value })

        }
    }


    componentWillUnmount() {
        store.dispatch(setShortlistedCandidates([]))
    }


    componentDidMount() {
        document.title = this.props.t('dashboard.table.appliedCandidates', { param: 'react' })

    }

    render() {
        let Mainprops = this.props;

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        const searchResults = chunk(Mainprops.EmployerReducer.shortListed.rows, RESULT_PER_PAGE)
        const { activePage } = this.state

        let seats = this.props.EmployerReducer.shortListed.rows


        let noOfSeat = ''
        // let showEditSlot = true

        if (seats && seats.length) {
            let count = this.props.EmployerReducer.shortListed.count
            if (parseInt(seats[0].job_status) >= 3) {
                // showEditSlot = false
            }
            seats = seats.filter((coun) => coun.job_status === "3")
            if (seats && seats.length) {
                // showEditSlot = false
                noOfSeat = count - seats.length
            } else {
                noOfSeat = count
            }
            if (Math.sign(noOfSeat) === -1) {
                noOfSeat = 0
            }
        }

        return (
            <>
                <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                    <div className="details-block">

                        <p className="mx-auto mb-1"><strong>{this.props.t('modal.cand_sel', { param: 'react' })}</strong></p>


                        {Mainprops.EmployerReducer.shortListed && Mainprops.EmployerReducer.shortListed.rows &&
                            !Mainprops.EmployerReducer.shortListed.rows.length && <Loader
                                type="Rings"
                                color="#F1911C"
                                height={100}
                                width={100}
                            />}
                        {Mainprops.EmployerReducer.shortListed && Mainprops.EmployerReducer.shortListed.rows && Mainprops.EmployerReducer.shortListed.rows.length ?
                            <>
                                <span>  {noOfSeat - this.state.candidatesArray.length ? <p className="m-0">{this.props.t('modal.tot_sel', { param: 'react' })} <b>: {noOfSeat}</b></p> : null}</span>
                            </> : null
                        }


                        {noOfSeat === this.state.candidatesArray.length &&
                            <p className="m-0">
                                {this.props.t('JobPost.selectInst', { param: 'react' })}
                            </p>
                        }
                        <>

                            {this.state.candidatesArray && this.state.candidatesArray.length ? <p className="m-0">{this.props.t('modal.rem', { param: 'react' })} <b>: {noOfSeat - this.state.candidatesArray.length}</b></p> : null}

                            {this.state.candidatesArray && this.state.candidatesArray.length ? <p className="m-0"> {this.props.t('modal.curr_sel', { param: 'react' })} <b>: {this.state.candidatesArray.length}</b></p> : null}
                            <div className=" w-100 justify-content-start mt-2">

                                {this.state.candidatesArray && this.state.candidatesArray.length > 0 &&
                                    <button type="btn"
                                        data-dismiss="modal"
                                        onClick={() => this.applyForSession()}
                                        className="btn btn-primary btn-primary2  btn-sm d-block ml-2 justify-content-center align-self-center collapsed"

                                    > {this.props.t('JobPost.shortlist', { param: 'react' })}
                                    </button>}
                                {/* {showEditSlot && 
                                    <>
                                    <Link className="date-clr" style={{ "cursor": "pointer" }} onClick={() => this.setState((prev) => { return {editSlot: !prev.editSlot} })}
                                >{this.props.t('JobPost.editSlot', { param: 'react' })}</Link>
                                <br></br>
                                
                                {this.state.editSlot  &&
                                <div>
                                    <select
                                        value={Mainprops.EmployerReducer.shortListed  && Mainprops.EmployerReducer.shortListed.rows[0] && 
                                            Mainprops.EmployerReducer.shortListed.rows[0].time_slot ? Mainprops.EmployerReducer.shortListed.rows[0].time_slot : ''}
                                        onChange={this.handleSlotChange}
                                        className="form-control"
                                        name="time_slot">
                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('JobPost.timeSlot', { param: 'react' })}
                                        </option>
                                        {Mainprops.EmployerReducer.timeSlot && Mainprops.EmployerReducer.timeSlot.length > 0 &&
                                            Mainprops.EmployerReducer.timeSlot.map(function (data, index) {
                                                return (
                                                    <option
                                                        value={data.id}
                                                        key={index}>{data.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>}
                                </>} */}
                            </div>
                        </>


                    </div>
                    <div className="container">
                        <div className="row">
                            <Link className="navbar-toggler widget-sidebar-toggler text-white">
                                Sidebar
                            </Link>
                            {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}


                            <main className="col second-side p-0 pb-3 h-100">


                                <div className="clearfix"></div>



                                <div>
                                    <div id="home" className=" tab-pane active">
                                        <section className="job-details mt-4">
                                            <div className="container">
                                                <div className="row">

                                                    <div className="col-md-12  mx-auto  tab-content  p-0">

                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('dashboard.table.appliedCandidates', { param: 'react' })}</h6>

                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="row pl-3 pr-3">
                                                                {Mainprops.EmployerReducer.shortListed && Mainprops.EmployerReducer.shortListed.rows && Mainprops.EmployerReducer.shortListed.rows.length ?
                                                                    <>
                                                                        <h6 className="mr-3">{this.props.t('dashboard.table.job-title', { param: 'react' })}: <b>{Mainprops.EmployerReducer.shortListed.rows[0].job_title}</b></h6>
                                                                        <h6 className="mr-3">{this.props.t('dashboard.table.vacancies', { param: 'react' })}: <b>{Mainprops.EmployerReducer.shortListed.rows[0].vacancies}</b></h6>
                                                                        <h6 className="mr-3">{this.props.t('jobSearch.qual', { param: 'react' })}: <b>{Mainprops.EmployerReducer.shortListed.rows[0].qualification}</b></h6>
                                                                    </> : null
                                                                }
                                                            </div>
                                                            <hr></hr>
                                                            <div className="row pl-3 pr-3">
                                                                <h6>{this.props.t('JobPost.select_candidates_inst', { param: 'react' })}</h6>
                                                                <div className="table-responsive">
                                                                    <table className="table table-hover table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.name', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.gender', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.education', { param: 'react' })}</th>
                                                                                <th>{this.props.t('JobPost.district', { param: 'react' })}</th>
                                                                                <th>{this.props.t('JobPost.email', { param: 'react' })}</th>
                                                                                <th>{this.props.t('JobPost.exp1', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.mobile', { param: 'react' })}</th>
                                                                                <th>{this.props.t('jobProviderProfile.shortlistCandidates', { param: 'react' }).slice(0, -1)}</th>
                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((jobs, key) => {

                                                                                return (<tr key={key}>
                                                                                    <td>{((activePage - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                    <td>
                                                                                        <span


                                                                                        >{jobs.firstname ? jobs.firstname : ''}</span>


                                                                                    </td>
                                                                                    <td>{jobs.gender ? jobs.gender : ''}</td>
                                                                                    <td>{jobs.education ? jobs.education : ''}</td>
                                                                                    <td>{jobs.district ? jobs.district : ''}</td>
                                                                                    <td>{jobs.email ? jobs.email : ''}</td>
                                                                                    <td>{jobs.experience ? jobs.experience : ''}</td>
                                                                                    <td>{jobs.mobile ? jobs.mobile : ''}</td>
                                                                                    <td>
                                                                                        {jobs.job_status === '1' &&
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input ml-2"
                                                                                                name="alternatePhone"

                                                                                                value={jobs.applied_user_id}
                                                                                                onChange={(event) => this.handleCheckbox(event, jobs)}
                                                                                            />}
                                                                                        {parseInt(jobs.job_status) >= 3 &&
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input ml-2"
                                                                                                name="selected"
                                                                                                readOnly={true}
                                                                                                disabled={parseInt(jobs.job_status) >= 3}
                                                                                                checked={parseInt(jobs.job_status) >= 3}

                                                                                            />}
                                                                                    </td>
                                                                                </tr>)
                                                                            })}
                                                                            {Mainprops.EmployerReducer.shortListed.notFound &&
                                                                                <tr>
                                                                                    <td colSpan="9" style={{ "textAlign": "center" }}>
                                                                                        <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5></td>
                                                                                </tr>}


                                                                        </tbody>

                                                                    </table>
                                                                    {searchResults && searchResults.length > 1 ?
                                                                        <div className='react-pagination'>
                                                                            <Pagination
                                                                                pageRangeDisplayed={10}
                                                                                activePage={activePage}
                                                                                itemsCountPerPage={RESULT_PER_PAGE}
                                                                                totalItemsCount={Mainprops.EmployerReducer.shortListed.rows.length}
                                                                                onChange={this.handlePageChange}
                                                                            />
                                                                        </div> : ''}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>





                            </main>
                        </div>
                    </div>
                </section>


            </>)
    }


}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(CandidatesList));
