import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Pagination from "react-js-pagination";
import { chunk, getTimeWithAmPm } from '../../helpers'
import { Link } from 'react-router'
import ReactHtmlParser from 'react-html-parser';
import store from "../../store"
import {
    getCounsellingType, postCounsellingSearch,
    setCounsellingResult
} from '../../actions/jobseekerAction';
import { setRedirectionLink, get_education } from '../../actions/accountAction'
import { getCounsellingModes } from '../../actions/counsellingproviderAction'


const RESULT_PER_PAGE = 25

class CounsellingSessions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            formData: [],
            submitted: false,
            description: ''
        }
        this.modalRef = React.createRef();

        store.dispatch(getCounsellingModes())
        store.dispatch(getCounsellingType());
        store.dispatch(get_education())
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    clicked = (job) => {

        if (this.props.actionReducer.Loginuser && this.props.actionReducer.Loginuser.id) {
            if (this.props.actionReducer.Loginuser.user_type === '1') {
                this.props.router.push(`counselling-details/${job.id}`)
            }
        } else {
            store.dispatch(setRedirectionLink(`counselling-details/${job.id}`))
            this.props.router.push("/signin")
        }
    }

    componentDidMount() {
        document.title = this.props.t('sidebar.search_counselling', { param: 'react' })
    }

    submitSearch = (e) => {
        e.preventDefault()
        if (!this.state.submitted) {
            this.setState({ activePage: 1 })
            store.dispatch(postCounsellingSearch(this.state.formData))
            this.setState({ submitted: true })
        }
    }

    componentWillUnmount() {
        store.dispatch(setCounsellingResult([]))
    }
   
    handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        if (!value) {
            delete formData[name]
        } else {
            formData[name] = value
        }
        this.setState({ formData, submitted: false });
    }

    render() {
        const searchResult = chunk(this.props.JobseekerReducer.counsellingResults, RESULT_PER_PAGE)
        const { activePage, formData } = this.state
        const Mainprops = this.props
        let containerClass = ''
        let resultClass = ''
        if (!this.props.JobseekerReducer.counsellingResults.length) {
            containerClass = 'spread-show'
            resultClass = 'height0'
        }

        return (
            <div>
                <section className="total-job">
                    <div className="container">
                        <div className={`row ${containerClass}`}>
                            <div className="modal fade" id="serviceDescription" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('counsellingProvider.details', { param: 'react' })}</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {this.state.description ? ReactHtmlParser(this.state.description) : ''}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">{this.props.t('counsellingProvider.close', { param: 'react' })}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="serviceModal" style={{ 'zIndex': '99999' }}>
                                <div className="modal-dialog modal-dialog-centered modal-lg" >
                                    <div className="modal-content d-block">
                                        <div className="modal-body pt-2  pb-1 text-center">
                                            <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                            <h5 className="mx-auto  text-uppercase mb-3">{this.props.t('homeContent.pgrkamTitle', { param: 'react' })}</h5>
                                            <div className="row d-flex">
                                                <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex ">
                                                    <div className="first-click">
                                                        <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                                                        <p> {this.props.t('homeContent.inst1', { param: 'react' })}</p>
                                                        <a href="/signin" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickToLogin', { param: 'react' })}</a>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                                    <div className="first-click">
                                                        <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                                                        <p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst2', { param: 'react' })}</p>
                                                        <a href="/signup?type=1" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickToRegister', { param: 'react' })}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8 col-sm-12 col-lg-8">
                                <h4 style={{ 'color': '#fff' }}>{this.props.t('counsellingProvider.sessions', { param: 'react' })}</h4>
                                <form className="pb-0" onSubmit={this.submitSearch}>
                                    <div className="col-lg-12 col-xl-12 mx-auto mt-5 mb-3 ">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                                                <select onChange={this.handleInput}
                                                    value={formData.type ? formData.type : ''}
                                                    className="search-slt" name="type">
                                                    <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('counsellingProvider.coun_type', { param: 'react' })}</option>
                                                    {Mainprops.JobseekerReducer.counsellingType.length > 0 &&
                                                        Mainprops.JobseekerReducer.counsellingType.map(function (data, key) {
                                                            return (
                                                                <option value={data.id} key={key}>{data.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                                                <select onChange={this.handleInput}
                                                    value={formData.mode ? formData.mode : ''}
                                                    className="search-slt" name="mode">
                                                    <option value="">{this.props.t('counsellingProvider.mode', { param: 'react' })}</option>
                                                    {this.props.counsellingproviderReducer && this.props.counsellingproviderReducer.modes &&
                                                        this.props.counsellingproviderReducer.modes.length ?
                                                        this.props.counsellingproviderReducer.modes.map((result, key) => {
                                                            return (
                                                                <option key={key} value={result.id}>{result.name}
                                                                </option>
                                                            )
                                                        }) : ''}
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                                                <select onChange={this.handleInput}
                                                    value={formData.education_level ? formData.education_level : ''}
                                                    className="search-slt" name="education_level">
                                                    <option value="">{this.props.t('forms.selectEdu', { param: 'react' })}</option>
                                                    {Mainprops.actionReducer.Educations.length > 0 &&
                                                        Mainprops.actionReducer.Educations.map(function (data, key) {
                                                            return (
                                                                <option value={data.id} key={key}>{data.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                                                <button type="submit" className="btn text-white wrn-btn">
                                                    {this.props.t('jobSearch.search', { param: 'react' })}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="job-details">
                    <div className="container">
                        <div className="row">
                            <div className={`col-md-12 col-sm-12 col-lg-12 right-sidebar-scroll ${resultClass}`}>
                                {this.props.JobseekerReducer.counsellingResults && this.props.JobseekerReducer.counsellingResults.status === '401' &&
                                    <div className="right-sidebar"><h5 className="first-job mb-3 p-3">
                                        {this.props.t('JobPost.notFound', { param: 'react' })}</h5></div>}

                                {searchResult && searchResult.length ?
                                    searchResult[activePage - 1].map((job, key) => {
                                        return (
                                            <div className="right-sidebar" key={key}>
                                                <div className="first-job mb-3 p-3">
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <Link>{job.title ? job.title : ''}</Link>
                                                            <ul className="nav">
                                                                {job.type === '2' ?
                                                                    <li className="list-items-inline pt-2 pl-3" title={'Session location'}><i
                                                                        className="fa fa-map-marker  pr-2"></i> {job.address ? job.address : ''}
                                                                    </li> : null}
                                                                {job.timing_from && job.timing_to ? <li className="list-items-inline pt-2 pl-3" title={'Session timings'}><i className="fa fa-clock-o" aria-hidden="true"></i>{" " + getTimeWithAmPm(job.timing_from) + ' - ' + getTimeWithAmPm(job.timing_to)}
                                                                </li> : null}
                                                                <li className="list-items-inline pt-2 pl-3" title={'Session dates'}><i className="fa fa-calendar-o" aria-hidden="true"></i>{" " + job.valid_from + ' - ' + job.expire_on}</li>
                                                            </ul>
                                                            <span className="pr-5" style={{ "color": "#f7900d", "cursor": "pointer" }} onClick={() => this.setState({ description: job.description })} data-toggle="modal" data-target="#serviceDescription" >View details</span>
                                                        </div>
                                                        <div className="col-md-5">
                                                            {!Object.keys(this.props.actionReducer.Loginuser).length ||
                                                                (this.props.actionReducer.Loginuser &&
                                                                    this.props.actionReducer.Loginuser.user_type &&
                                                                    this.props.actionReducer.Loginuser.user_type === '1') ?
                                                                (<button
                                                                    className="pull-right btn btn-primary btn-primary2 mt-2 ml-auto btn-center"
                                                                    onClick={() => this.clicked(job)}
                                                                > {this.props.t('counsellingProvider.I am interested', { param: 'react' })}	</button>) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        )
                                    }) : ''}

                            </div>
                            {searchResult && searchResult.length > 1 ?
                                <div className='react-pagination pagination-cls'>
                                    <Pagination
                                        pageRangeDisplayed={10}
                                        activePage={activePage}
                                        itemsCountPerPage={RESULT_PER_PAGE}
                                        totalItemsCount={this.props.JobseekerReducer.counsellingResults.length}
                                        onChange={this.handlePageChange}
                                    />
                                </div> : ''}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(CounsellingSessions));
