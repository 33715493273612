import React, { Component } from 'react';
import { translate } from "react-multi-lang";

class NotificationPage extends Component {

    componentDidMount() {
        document.title = this.props.t('banner.notifications', { param: 'react' })
    }

    render() {
        return (
            <div className="tab-content" style={{ border: "none" }}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">{this.props.t('banner.notifications', { param: 'react' })}</h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0 p-3">
                                        <div className={"row"}>
                                            <div className={"col-md-12"}>
                                                <ul>
                                                    <li className="mb-2">
                                                        <a style={{ color: "#007bff" }} rel="noopener noreferrer" target="_blank" href="/docs/SCSP Recruitment 1 of 2021.pdf">
                                                            Directorate of SCSP-Advertisement for the post of Programmer, Young Professional, Investigator and Data Entry Operator (Last date 30-04-2021 before 3:00 P.M.).
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a style={{ color: "#007bff" }} rel="noopener noreferrer" target="_blank" href="/docs/application form SCSP Recruitment 1 of 2021.docx">
                                                            Directorate of SCSP-Application form in word format.
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default translate(NotificationPage);
