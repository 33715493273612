import React, { Component } from 'react';
import { Link } from 'react-router';
import {translate} from "react-multi-lang";

class skilldevelopmentAchievement extends Component {

    componentDidMount() {
        document.title = 'Achievement'
    }
    
    render() {
        return (
            <div className="tab-content" style={{'border':'none'}}>
                <div id="home" className=" tab-pane active">
                 <section className="login-form mt-2">
                    <div className="container" >
                        <div className="panel-heading">
                                        {/* <h6 className="mb-0"><a href="/aboutus" style={{'color':'#fff'}}>HOME:-Skill Development</a></h6> */}
                            <h6 className="mb-0"><span>ACHIEVEMENTS - From Inception till 31st Jan, 2023</span>
                            <span class="float-right p-0 mt-0" ><a href="/aboutus"><u style={{'color':'#000', fontSize:'12px'}}>Home</u></a></span></h6>
                        </div>
                        <div className="first-click1 mb-4" style={{padding:'35px'}}>
                        
                            <div class="container-fluid" style={{background:'rgba(0,0,0,0.8)',color:'#fff',borderBottom: "#fff solid 2px"}}>
                                <div class="outerDiv">
                                {/* <h1 class="text-center">ACHIEVEMENTS</h1> */}
                                    <div class="row mt2">
                                    <div class="col-sm-3 text-center p0">
                                    <div class="icons"><img style={{width:'41%'}} src="images/icons.png" alt=""/></div>
                                    <div class="font22 p4 bg-white mt-3" ><span style={{color:"#000"}}><b>149450+</b></span></div>
                                    <div class="font22 mt-2">CANDIDATES TRAINED</div>
                                    </div>
                                    <div class="col-sm-3 text-center p0">
                                    <div class="icons"><img style={{width:'41%'}} src="images/users.png" alt=""/></div>
                                    <div class="font22 p4 bg-white mt-3"><span style={{color:"#000"}}><b>83840+</b></span></div>
                                    <div class="font22 mt-2">APPOINTED/PLACED</div>
                                    </div>
                                    <div class="col-sm-3 text-center p0">
                                    <div class="icons"><img style={{width:'41%'}} src="images/partners.png" alt=""/></div>
                                    <div class="font22 p4 bg-white mt-3"><span style={{color:"#000"}}><b>250+</b></span></div>
                                    <div class="font22 mt-2">EMPANELLED Training Partners</div>
                                </div>
                                    <div class="col-sm-3 text-center p0">
                                    <div class="icons"><img style={{width:'41%'}} src="images/training.png" alt=""/></div>
                                    <div class="font22 p4 bg-white mt-3"><span style={{color:"#000"}}><b>124</b></span></div>
                                    <div class="font22 mt-2">No. Of Training Centers(Active)</div>
                                    </div>
                             
                            </div>
                            <div className="register-bar">
                                {/* <Link className="nav-link registered" to="signup?type=1"><i className="fa fa-registered" aria-hidden="true"></i></Link> */}
                                <Link className="nav-link registered" to="signup?type=1"><span style={{background:"rgb(237 144 23)", padding:"9px 7px 8px 6px" ,fontWeight:"900" ,borderRadius:"10px",fontSize:"10px"}}>REGISTER FOR SKILL TRAINING</span></Link>
                            </div> 

                        </div>
                        </div>

                      </div>
                    </div>
                </section>
                </div>
            </div>
        )
    }
}


export default translate(skilldevelopmentAchievement);
