import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'

class Faq extends Component {

    componentDidMount() {
        document.title = this.props.t('faq', { param: 'react' })
    }
    
    render() {
        return (
            <div className="tab-content" style={{"border":"none"}}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">{this.props.t('faq', { param: 'react' })}</h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3">
                                        {/* <div className="mb-4">
                                            <h4>{this.props.t('faqContent.faqFor', { param: 'react' }) + " " +this.props.t('dashboard.job_mela', { param: 'react' })}</h4>
                                            <a className="date-clr" href="/docs/FAQ-Placement Drive.pdf" target="_blank">{this.props.t('homeContent.clickHere', { param: 'react' })}</a>
                                        </div> */}
                                        <div className="mb-4">
                                            <h4>{this.props.t('faqContent.faqFor', { param: 'react' }) + " " +this.props.t('faqContent.jobseeker', { param: 'react' })}</h4>
                                            <a className="date-clr" href="/docs/FAQ-jobseeker.pdf" target="_blank">{this.props.t('homeContent.clickHere', { param: 'react' })}</a>
                                        </div>
                                        <div className="mb-4">
                                            <h4>{this.props.t('faqContent.faqFor', { param: 'react' }) + " " +this.props.t('faqContent.employer', { param: 'react' })}</h4>
                                            <a className="date-clr" href="/docs/FAQ-employers.pdf" target="_blank">
                                                {this.props.t('homeContent.clickHere', { param: 'react' })}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Faq));
