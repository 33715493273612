import React from 'react';
import SimpleReactValidator from 'simple-react-validator';

const Validation = (WrappedComponent) => {
    return class HOC extends React.Component {
        constructor(props) {
            super(props)
            this.validator = new SimpleReactValidator({
                element: message => <div style={{"color":"red"}}>{message}</div>
            });
        }
    render() {
        return (<WrappedComponent
        validator={this.validator}
        {...this.props}
        />)
    }
  }


};

export default Validation;