import {SET_CATEGORY, JOB_SHIFT,USER_INSTITUTION,USER_COURSENATURE,SET_JOBPROVIDER_PROFILE,
  USER_QUALIFICATION,USER_CITIES,USER_JOBSEEKER_CITIES,USER_JOBSEEKER_SUBDISTRICT,
  USER_GENDER,ORG_TYPE,ORG_CATEGORY,FUNCTIONAL_AREA,SECTOR, SALARY_TYPE, JOB_POSTED, SERVICE_POSTED  } from '../actions/actionTypes';

const initialState = {
  Loginuser: [],
  Profile: [],
  Registeruser: [],
  Genders: [],
  District: [],
  Specilization: [],
  Language: [],
  Coursenature: [],
  Subdistrict: [],
  JSubdistrict: [],
  Educations: [],
  UserSubType: [],
  Qualification: [],
  Institution: [],
  Cities: [],
  JCities: [],
  Orgtype:[],
  Orgcategory: [],
  Functionalarea: [],
  Sector: [],
  salaryType: [],
  jobShift: [],
  selectedPerson: null,
  category:[],
  jobPosted: false,
  servicesPosted: false
};

const setProfile = (state, action) => {
  return { ...state, Profile: action.Profile };
};

const setjobShift = (state, action) => {
    return { ...state, jobShift: action.jobShift };
  };
const setGender = (state, action) => {
  return { ...state, Genders: action.Genders };
}

const setCities = (state, action) => {
  return { ...state, Cities: action.Cities };
}
const setjsubdistrict = (state, action) => {
  return { ...state, JSubdistrict: action.Subdistrict };
}
const setjCities = (state, action) => {
  return { ...state, JCities: action.Cities };
}
const setInstitution = (state, action) => {
  return { ...state, Institution: action.Institution };
}
const setQualification = (state, action) => {
  return { ...state, Qualification: action.Qualification };
}
const setCoursenature = (state, action) => {
  return { ...state, Coursenature: action.Coursenature };
}
const setsalaryType = (state, action) => {
    return { ...state, salaryType: action.salaryType };
  }
const setOrgtype = (state, action) => {
  return { ...state, Orgtype: action.Orgtype };
}
const setOrgcategory = (state, action) => {
  return { ...state, Orgcategory: action.Orgcategory };
}
const setFunctionalarea = (state, action) => {
  return { ...state, Functionalarea: action.Functionalarea };
}
const setSector = (state, action) => {
  return { ...state, Sector: action.Sector };
}
const setCategory = (state, action) => {
  return { ...state, category: action.category };
}
const jobPosted = (state, action) => {
  return { ...state, jobPosted: action.status };
}
const servicesPosted = (state, action) => {
  return { ...state, servicesPosted: action.status };
}


const JobpostReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_JOBPROVIDER_PROFILE: return setProfile(state, action);
    case FUNCTIONAL_AREA: return setFunctionalarea(state, action);
    case SECTOR: return setSector(state, action);
    case USER_COURSENATURE: return setCoursenature(state, action);
    case ORG_CATEGORY: return setOrgcategory(state, action);
    case SALARY_TYPE: return setsalaryType(state, action);
    case USER_CITIES: return setCities(state, action);
    case USER_INSTITUTION: return setInstitution(state, action);
    case USER_QUALIFICATION: return setQualification(state, action);
    case USER_JOBSEEKER_SUBDISTRICT: return setjsubdistrict(state, action);
    case USER_JOBSEEKER_CITIES: return setjCities(state, action);
    case JOB_SHIFT: return setjobShift(state,action);
    case ORG_TYPE: return setOrgtype(state, action);
    case USER_GENDER: return setGender(state, action);
    case SET_CATEGORY: return setCategory(state, action)
    case JOB_POSTED: return jobPosted(state, action)
    case SERVICE_POSTED: return servicesPosted(state, action)
    default: return state;
  }
};

export default JobpostReducer;
