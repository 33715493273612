import axios from 'axios';
import qs from 'qs';
import ServerSetting from '../config.json';
import { getServiceArchieve } from './employerAction'
import {
  SET_LOADER, USER_COURSENATURE, SET_JOBPROVIDER_PROFILE, UNSET_LOADER, USER_LANGUAGE,
  OPEN_OPT, USER_RESET,
  USER_SUB_TYPE,
  SET_CATEGORY_SERVICE,
  SET_SCHEDULE_VISITS,
  SET_SERVICE_FEEDBACK,
  USER_VERIFIED, GET_LOGIN, CREATE_USER, SET_NOTIFICATION,
  SET_POST_ARCHIVE, SET_RECENT_SERVICES,
  SET_EXPIRE_JOB, SET_JOBPROVIDER_ADDRESSES, SET_PROVIDER_SEARCH_RESULTS, SET_APPLIED_INTERVIEWS, SET_SELECTED_APPLICANTS,
  SET_JOB_STATUS, SET_APPLIED_JOBS, SET_APPLIED_CANDIDATES,
  SERVICE_POSTED
} from './actionTypes';
import { push } from 'react-router-redux';
let environment = (typeof process.env.NODE_ENV !== 'undefined' && process.env.NODE_ENV === 'production') ? 'production' : 'development';
let API_SERVER = ServerSetting[environment].API_SERVER;



export const setLoginuser = loginuser => {
  return {
    type: GET_LOGIN,
    loginuser
  };
};
export const setJobProviderProfile = Profile => {
  return {
    type: SET_JOBPROVIDER_PROFILE,
    Profile
  };
}

export const set_usersubtype = UserSubType => {
  return {
    type: USER_SUB_TYPE,
    UserSubType
  };
};

export const set_Loader = Education => {
  return {
    type: SET_LOADER,
  };
};
export const unset_Loader = Education => {
  return {
    type: UNSET_LOADER,
  };
};

export const set_userverified = user => {
  return {
    type: USER_VERIFIED,
    user
  };
};
export const set_userreset = user => {
  return {
    type: USER_RESET,
    user
  };
};
export const set_OTP = user => {
  return {
    type: OPEN_OPT,
    user
  };
};
export const set_RegisterUser = user => {
  return {
    type: CREATE_USER,
    user
  };
};

export const set_User_Coursenature = Coursenature => {
  return {
    type: USER_COURSENATURE,
    Coursenature
  };
};
export const set_User_Language = Language => {
  return {
    type: USER_LANGUAGE,
    Language
  };
};

export const setServiceCategory = data => {
  return {
    type: SET_CATEGORY_SERVICE,
    data
  };
};

export const setScheduleVisits = data => {
  return {
    type: SET_SCHEDULE_VISITS,
    data
  };
};


export const setFeedback = data => {
  return {
    type: SET_SERVICE_FEEDBACK,
    data
  };
};


export const getServiceCategory = () => {
  return dispatch => {
    axios.get(API_SERVER + 'category-service/view')
      .then(resp => {
       
        dispatch(setServiceCategory(resp.data));
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};

export const postProviderAddress = (data) => {
  return dispatch => {
    dispatch(set_Loader());
    axios.post(API_SERVER + 'ggr-address/create', qs.stringify(data))
      .then(resp => {
        dispatch(unset_Loader());
        if (resp.data.status === '401') {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
        else {
          dispatch(setNotification({ type: "success", message: resp.data.message }));
          dispatch(getJobProviderAddress());
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};

export const getFeedback = () => {
  return dispatch => {
    dispatch(set_Loader(''))

    axios.post(API_SERVER + 'feedback-form/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
        dispatch(unset_Loader())
        if (resp.data.status === '200') {
          dispatch(setFeedback(resp.data.data));
        } else {
          dispatch(setFeedback(resp.data));
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };
};


export const getJobProviderAddress = () => {
  return dispatch => {
    axios.post(API_SERVER + 'ggr-address/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
        if (resp.data.status === '401') {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
        else {
          dispatch(setProviderAddress(resp.data));
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};
export const postVisit = (data) => {
  data["auth_key"] = localStorage.getItem('token')
  return dispatch => {
    axios.post(API_SERVER + 'schedule-service/create', qs.stringify(data))
      .then(resp => {
        if (resp.data.status === '200') {
          dispatch(setNotification({ type: "success", message: resp.data.message }));
          dispatch(getAppliedServices())
        }
        else {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };
};



export const deleteService = (data) => {
  data["auth_key"] = localStorage.getItem('token')
  return dispatch => {
    axios.post(API_SERVER + 'service/delete', qs.stringify(data))
      .then(resp => {
        if (resp.data.status === '200') {
          dispatch(setNotification({ type: "success", message: resp.data.message }));
          dispatch(getServiceArchieve())
        }
        else {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };
};



export const inviteJobSeeker = (data) => {
  data['auth_key'] = localStorage.getItem('token')
  return dispatch => {
    axios.post(API_SERVER + 'jobproviderinvitation/index', qs.stringify(data))
      .then(resp => {
        const notificationType = resp.data.status === '401' ? 'error' : 'success'
        dispatch(setNotification({ type: notificationType, message: resp.data.message }));
      })
      .catch(error => {
        // console.log(error);
      });
  };
};

export const getScheduleVisits = () => {
  return dispatch => {
    axios.post(API_SERVER + 'schedule-service/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
        if (resp.data.status === '200') {
          dispatch(setScheduleVisits(resp.data.data))
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };
};

export const setProviderAddress = Address => {
  return {
    type: SET_JOBPROVIDER_ADDRESSES,
    Address
  };
}

export const setAppliedInterviews = interviews => {
  return {
    type: SET_APPLIED_INTERVIEWS,
    interviews
  };
}
export const setSelectedApplicants = applicants => {
  return {
    type: SET_SELECTED_APPLICANTS,
    applicants
  };
}



export const getExpireJob = () => {
  return dispatch => {
    axios.post(API_SERVER + 'expirejob/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
        dispatch(setExpireJob(resp.data));
      })
      .catch(error => {
      });
  };
};

export const setExpireJob = (job) => {
  return {
    type: SET_EXPIRE_JOB,
    job
  };
}
export const setJobStatus = (status) => {
  return {
    type: SET_JOB_STATUS,
    status
  };
}
export const setAppliedJobs = (jobs) => {
  return {
    type: SET_APPLIED_JOBS,
    jobs
  };
}
export const setAppliedCandidates = (data) => {
  return {
    type: SET_APPLIED_CANDIDATES,
    data
  };
}


export const updateJobStatus = (data) => {
  data['auth_key'] = localStorage.getItem('token')
  return dispatch => {
    axios.post(API_SERVER + 'accepted-record/create', qs.stringify(data))
      .then(resp => {
        if (resp.data.status === '401') {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
        else {
          dispatch(setNotification({ type: "success", message: resp.data.message }));
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };
};
export const getJobStatus = () => {
  return dispatch => {
    axios.get(API_SERVER + 'jobstatus/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
     
        if (resp.data.status === '401') {
        }
        else {
          dispatch(setJobStatus(resp.data));
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};


export const getAppliedJobs = () => {
  return dispatch => {
    axios.post(API_SERVER + 'jobstatus/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
    
        if (resp.data.status === '401') {
        }
        else {
          dispatch(setAppliedJobs(resp.data));
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};


export const getAppliedInterviews = () => {
  return dispatch => {
    axios.post(API_SERVER + 'scheduled-job/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
      
        if (resp.data.status === '401') {
        }
        else {
          dispatch(setAppliedInterviews(resp.data));
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};

export const getSelectedApplicants = (job_id) => {
  return dispatch => {
    dispatch(set_Loader())
    axios.post(API_SERVER + 'scheduled-job/index', qs.stringify({ auth_key: localStorage.getItem('token'), job_id }))
      .then(resp => {
     
        dispatch(unset_Loader())
        if (resp.data.status === '401') {
        }
        else {
          dispatch(setSelectedApplicants(resp.data));
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};

export const selectAppliedApplicant = (data) => {
  data['auth_key'] = localStorage.getItem('token')
  return dispatch => {
    axios.post(API_SERVER + 'applied-job/create', qs.stringify(data))
      .then(resp => {
       
        if (resp.data.status === '401') {
        }
        else {
          dispatch(setNotification({ type: "success", message: resp.data.message }));
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};

export const setPostArchive = archive => {
  return {
    type: SET_POST_ARCHIVE,
    archive
  };
}

export const getPostArchive = () => {
  return dispatch => {
    axios.post(API_SERVER + 'job_archieve/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
        if (resp.data.status === '401') {
        }
        else {
          const data = []
          resp.data.data.forEach((r) => {
            let filterSelected = resp.data.selected.filter((sel) => sel.job_id === r.job_id)
            filterSelected = filterSelected ? filterSelected[0] : []
            data.push({ ...r, ...filterSelected })
          })
          dispatch(setPostArchive(data));
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};

export const getPostArchiveView = (data) => {
  data['auth_key'] = localStorage.getItem('token')
  return dispatch => {
    dispatch(set_Loader())
    axios.post(API_SERVER + 'job_archieve/view', qs.stringify(data))
      .then(resp => {
        dispatch(unset_Loader())

        // debugger
        if (resp.data.status === '401') {
        }
        else {
          dispatch(setPostArchive(resp.data));
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};

export const setRecentServices = Services => {
  return {
    type: SET_RECENT_SERVICES,
    Services
  };
}

export const setSearchResults = results => {
  return {
    type: SET_PROVIDER_SEARCH_RESULTS,
    results
  };
}

export const getSearchResults = (data) => {
  data['auth_key'] = localStorage.getItem('token')
  return dispatch => {
    dispatch(set_Loader());
    axios.post(API_SERVER + 'search-jobseekers/index', qs.stringify(data))
      .then(resp => {

        dispatch(unset_Loader());
        dispatch(setSearchResults(resp.data));
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};


export const getAppliedServices = () => {
  return dispatch => {
    axios.post(API_SERVER + 'apply-services/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
        if (resp.data.status === '401') {
          dispatch(setRecentServices([]));
        } else {
          dispatch(setRecentServices(resp.data.data));
        }
      })
      .catch(error => {
        console.log(error);
        //
      });
  };
};


export const get_lsp_profile = () => {
  return dispatch => {
    axios.post(API_SERVER + 'service-provider/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
        if (resp.data.status === '401') {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
        else {
          dispatch(setJobProviderProfile(resp.data));
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};

export const lsp_update_profile = (data) => {
  let formData = new FormData();
  if (data.profile_image && typeof data.profile_image === 'string') {
    delete data['profile_image']
  }
  if (data.police_verification && typeof data.police_verification === 'string') {
    delete data['police_verification']
  }

  for (var key in data) {
    formData.append(key, !data[key] ? '' : data[key]);
  }
  return dispatch => {
    dispatch(set_Loader());
    axios.post(API_SERVER + 'service-provider/update', formData, { headers: { 'content-type': 'multipart/form-data' } })
      .then(resp => {
        dispatch(unset_Loader());
        if (resp.data.status === '401') {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
        else {
          dispatch(setNotification({ type: "success", message: resp.data.message }));
          dispatch(get_lsp_profile());
          dispatch(getJobProviderAddress());
        }
      })
      .catch(error => {
        // console.log(error);
        // 
      });
  };
};



export const get_User_Type = () => {
  return dispatch => {
    axios.get(API_SERVER + 'user_subtype/index')
      .then(resp => {
        dispatch(set_usersubtype(resp.data))

      })
      .catch(error => {
        // console.log(error);
        //
      });
  };
};

export const setNotification = Notification => {
  return {
    type: SET_NOTIFICATION,
    Notification
  };
}
export const Resendotp = (data) => {
  return dispatch => {
    dispatch(set_Loader(''));
    axios.post(API_SERVER + 'site/resend-verification-email', qs.stringify(data))
      .then(resp => {
        dispatch(unset_Loader(''));
        if (resp.data.status === '200') {
          dispatch(set_OTP(''));
          dispatch(setNotification({ type: "success", message: resp.data.message }));
        }
        else {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
      })
      .catch(error => {
        dispatch(unset_Loader(''));
        if (error.response) {
          dispatch(setNotification({ type: "error", message: error.response.data }));

        } else if (error.request) {
        } else {
          dispatch(setNotification({ type: "error", message: error.message }));
        }
      });
  };
}
export const user_forgetpass = (data) => {
  return dispatch => {
    dispatch(set_Loader(''));
    axios.post(API_SERVER + 'site/request-password-reset', qs.stringify(data))
      .then(resp => {
        dispatch(unset_Loader(''));
        if (resp.data.status === '200') {
          dispatch(set_userreset(resp.data.message));
          dispatch(setNotification({ type: "success", message: resp.data.message }));
        }
        else {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
      })
      .catch(error => {
        dispatch(unset_Loader(''));
        if (error.response) {
          dispatch(setNotification({ type: "error", message: error.response.data }));

        } else if (error.request) {
        } else {
          dispatch(setNotification({ type: "error", message: error.message }));
        }
      });
  };
}

export const user_verify = (data) => {
  return dispatch => {
    dispatch(set_Loader(''));
    axios.post(API_SERVER + 'site/verify-email', qs.stringify(data))
      .then(resp => {
        dispatch(unset_Loader(''));
        if (resp.data.status === '200') {
          dispatch(set_userverified(resp.data.message));
          dispatch(setNotification({ type: "success", message: resp.data.message }));
        }
        else {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
      })
      .catch(error => {
        dispatch(unset_Loader(''));
        if (error.response) {
          dispatch(setNotification({ type: "error", message: error.response.data }));

        } else if (error.request) {
        } else {
          dispatch(setNotification({ type: "error", message: error.message }));
        }
      });
  };
}

export const RegisterJobSeeker = (data) => {
  // console.log(data);
  return dispatch => {
    dispatch(set_Loader(''));
    axios.post(API_SERVER + 'site/signup', qs.stringify(data))
      .then(resp => {
        dispatch(unset_Loader(''));
        if (resp.data.status === '401') {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
        else {
          dispatch(set_RegisterUser(resp.data));
        }
      })
      .catch(error => {
        dispatch(unset_Loader(''));
        if (error.response) {
          dispatch(setNotification({ type: "error", message: error.response.data }));

        } else if (error.request) {
        } else {
          dispatch(setNotification({ type: "error", message: error.message }));
        }
      });
  };
};

export const Islogin = () => {
  return dispatch => {
    axios.post(API_SERVER + 'site/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
      .then(resp => {
        if (resp.data.status === '401') {
          
        }
        else {
          localStorage.setItem('token', resp.data.auth_key);
          localStorage.setItem('login_type', resp.data.name);
          localStorage.setItem('Islogin', true);
          dispatch(setLoginuser(resp.data));
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(setNotification({ type: "error", message: error.response.data }));
          

        } else if (error.request) {
        } else {
          dispatch(setNotification({ type: "error", message: error.message }));
          
        }
      });
  };
};
export const Logout = () => {
  return dispatch => {
    localStorage.setItem('token', 'null');
    localStorage.setItem('login_type', 'null');
    localStorage.setItem('Islogin', false);
    dispatch(setLoginuser([]));
    dispatch(setNotification({ type: "success", message: "Logout Successfully.." }));
  };
};

export const Login = (data) => {
  return dispatch => {
    dispatch(set_Loader(''));
    axios.post(API_SERVER + 'site/login', qs.stringify(data))
      .then(resp => {
        dispatch(unset_Loader(''));
        if (resp.data.status === '401' || resp.data.status === '204') {
          if (resp.data.message === 'Account not verified') {
            dispatch(set_RegisterUser(resp.data));
            dispatch(setLoginuser('Account not verified'));
          }
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        }
        else {
          localStorage.setItem('token', resp.data.auth_key);
          localStorage.setItem('login_type', resp.data.name);
          localStorage.setItem('Islogin', true);
          dispatch(setLoginuser(resp.data));
          dispatch(setNotification({ type: "success", message: "Login Successfully.." }));
        }

      })
      .catch(error => {
        dispatch(unset_Loader(''));
        if (error.response) {
          dispatch(setNotification({ type: "error", message: error.response.data }));

        } else if (error.request) {
        } else {
          dispatch(setNotification({ type: "error", message: error.message }));
        }
      });
  };
};
export const CreateUser = (newuser) => {
  return dispatch => {
    axios.post('/user/', newuser)
      .then(resp => {
        dispatch(setNotification({ type: "success", message: "User created Successfully.." }));
      })
      .catch(error => {
    
        if (error.response.data.error === 11000) {
          dispatch(setNotification({ type: "error", message: "Email already exist..!" }));
        }
        if (error.response.data.error === 11001) {
          dispatch(setNotification({ type: "error", message: "Urlkey already exist..!" }));
        }
      });
  };
};
export const SignupUser = (newuser) => {
  return dispatch => {
    axios.post('/user/signup', newuser)
      .then(resp => {
        dispatch(setNotification({ type: "success", message: "Login Successfully.." }));
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('Islogin', true);
        let user = resp.data.user;
        user.role = resp.data.role;
        user.business_id = resp.data.business_id;
        dispatch(setLoginuser(user));
      })
      .catch(error => {
        // console.log(error);
        if (error.response.data.error === 11000) {
          dispatch(setNotification({ type: "error", message: "Email already exist..!" }));
        }
        if (error.response.data.error === 11001) {
          dispatch(setNotification({ type: "error", message: "Urlkey already exist..!" }));
        }
      });
  };
};
export const UpdateUser = (newuser) => {
  return dispatch => {
    axios.post('/user/update', newuser)
      .then(resp => {
        if (resp.data.success === true || resp.data.success === 'true') {
          dispatch(setNotification({ type: "success", message: "User updated Successfully.." }));
        }
      })
      .catch(error => {
        // console.log(error);
        if (error.response.data.error === 11000) {
          dispatch(setNotification({ type: "error", message: "Email already exist..!" }));
        }
        if (error.response.data.error === 11001) {
          dispatch(setNotification({ type: "error", message: "Urlkey already exist..!" }));
        }
      });
  };
};
export const UpdateProfile = (newuser) => {
  return dispatch => {
    axios.post('/user/profileupdate', newuser)
      .then(resp => {
        let user = resp.data.user;
        user.role = resp.data.role;
        user.business_id = resp.data.business_id;
        dispatch(setLoginuser(user));
      })
      .catch(error => {
        // console.log(error);
        if (error.response.data.error === 11000) {
          dispatch(setNotification({ type: "error", message: "Email already exist..!" }));
        }
        if (error.response.data.error === 11001) {
          dispatch(setNotification({ type: "error", message: "Urlkey already exist..!" }));
        }
      });
  };
};
export const UpdateProfileImage = (newuser) => {
  return dispatch => {
    axios.post('/user/imageupdate', newuser)
      .then(resp => {
        let user = resp.data.user;
        user.role = resp.data.role;
        user.business_id = resp.data.business_id;
        dispatch(setLoginuser(user));
      })
      .catch(error => {
        // console.log(error);
        if (error.response.data.error === 11000) {
          dispatch(setNotification({ type: "error", message: "Email already exist..!" }));
        }
        if (error.response.data.error === 11001) {
          dispatch(setNotification({ type: "error", message: "Urlkey already exist..!" }));
        }
      });
  };
};
export const UpdatePassword = (newuser) => {
  return dispatch => {
    axios.post('/user/profilepassword', newuser)
      .then(resp => {
        if (resp.data.success === true || resp.data.success === 'true') {
          dispatch(setNotification({ type: "success", message: "User updated Successfully.." }));
        }
      })
      .catch(error => {
        // console.log(error);
        
      });
  };
};
export const postServices = (data) => {
  const formData = new FormData()

  formData.set("img", data.img ? data.img : '');
  formData.set("service_id", data.service_id ? data.service_id : '');
  formData.set("category_id", data.category_id ? data.category_id : '');
  formData.set("auth_key", data.auth_key);

  return dispatch => {
    dispatch(set_Loader());
    axios.post(API_SERVER + 'local-service-provider/create', formData, { headers: { 'content-type': 'multipart/form-data' } })
      .then(resp => {
        dispatch(unset_Loader());
        if (resp.data.status === '401') {
          dispatch(setNotification({ type: "error", message: resp.data.message }));
        } else {
          dispatch(setNotification({ type: "success", message: resp.data.message }));
          dispatch(servicePosted(true));
          dispatch(push('/myaccount'))

        }
      })
      .catch(error => {
        // console.log(error);
        // 
      });
  };
};
export const servicePosted = status => {
  return {
    type: SERVICE_POSTED,
    status
  };
};
