import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Sidebar from './Sidebar';
import ReactToPrint from "react-to-print";
import { Link } from 'react-router'
import Validation from "../../hoc/Validation";
import ReactHtmlParser from 'react-html-parser';
import {
    getScheduledJobs, getAppliedJobs, getUpcomingJobs, postForeignJobInterviewParticipation,
    postJobInterviewParticipation, sendFeedback, applyEvent,
    willGoForInterview, subscribeForWebinar,
    getScheduledCareerCounselling, getAppliedCounsellings,getappliedSkillCourses,getOngoingSkillCourses,
    // postTrainingInterest,
    // getTrainingInterest,
    // getUserSelfEmpInterest,
    // addInterestInSelfEmp,
    // getScheduledSelfEmployment,
    // getMatchingCareers,
    // getRelevantSelfEmployment,
    getAppliedServices,
    getSchduledServices
    // getAppliedForgienJobs,
    // getScheduledForeignJobs
    // getScheduledForeignJobs, getScheduledStudy, getScheduledSelfEmployment,
    // getUpcomingSelfEmployment, getForeignJobsInterview, 
    // ,  getAppliedEvents
} from "../../../actions/jobseekerAction";
import { get_User_District } from "../../../actions/accountAction";
import { markAttendence } from "../../../actions/counsellingproviderAction";
import { getJobStatus } from "../../../actions/employerAction";
import store from '../../../store';
import Pagination from "react-js-pagination";
import { chunk, getTimeWithAmPm } from '../../../helpers'
import StarRatings from 'react-star-ratings'
import ServerSetting from '../../../config.json';

const RESULT_PER_PAGE = 25

class AppliedCoursesLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            basePath: window.Location.protocol + '//' + window.Location.host + '/',
            show: false,
            heading: '',
            showTime: false,
            sessionData: [],
            checked: "",
            jobs: [],
            skills: [],
            type: '',
            showApplyJob: false,
            job: '',
            activePageUpcomingPvtJobs: 1,
            activePageUpcomingGovtJobs: 1,
            activePageJobInvites: 1,
            activePageScheduledJobs: 1,
            activePageCounselling: 1,
            activePageStudy: 1,
            activePageForgeignJobs: 1,
            activePagehandleEmployment: 1,
            activePageForgeignJobsInterview: 1,
            activePagehandleUpcomingEmployment: 1,
            activePageJobApplied: 1,
            activePage: 1,
            inviteType: '',
            appliedForWebinar: false,
            jobType: 1,
            formData: [],
            appliedEvent: false,
            activePageEvents: 1,
            locations: [],
            description: '',
            activeSelfEmp: 1,
            activePageCareer: 1,
            selfEmpData: [],
            content: [],
            activePageSchduledServices: 1,
            activePageServices: 1,
            skillpayload:[],
        }

        this.printContent = React.createRef();
        this.feedbackRef = React.createRef();
        // store.dispatch(getMatchingCareers())
        store.dispatch(getScheduledJobs())
        store.dispatch(get_User_District());
        // store.dispatch(getUserSelfEmpInterest());
        // store.dispatch(getTrainingInterest())

        store.dispatch(getAppliedCounsellings())

        store.dispatch(getUpcomingJobs())
        store.dispatch(getScheduledCareerCounselling())
        // store.dispatch(getRelevantSelfEmployment())

        // store.dispatch(getScheduledSelfEmployment());
        // store.dispatch(getUpcomingSelfEmployment());
        // store.dispatch(getForeignJobsInterview());
        store.dispatch(getJobStatus())
        store.dispatch(getAppliedJobs())

        store.dispatch(getAppliedServices());
        store.dispatch(getSchduledServices());
        store.dispatch(getappliedSkillCourses());
        store.dispatch(getOngoingSkillCourses());
        // store.dispatch(getScheduledForeignJobs());
        // store.dispatch(getAppliedForgienJobs());

        const user_id=localStorage.getItem('user_id')
        const applied_courses=1
        const ongoing_courses=1
        const value={user_id:user_id,applied_courses:applied_courses};
        const data={user_id:user_id,ongoing_courses:ongoing_courses};
        store.dispatch(getOngoingSkillCourses(data))
        store.dispatch(getappliedSkillCourses(value))

    }

    getLocationFromIds = (location) => {
        const names = []
        if (this.props.actionReducer.District && this.props.actionReducer.District.length > 0 && location && location.length) {
            this.props.actionReducer.District.forEach((data) => {
                if (data.state_id === "3") {
                    location.forEach(element => {
                        let filterVal = data.dist.filter((district) => district.id === element)
                        if (filterVal && filterVal[0]) {
                            names.push(filterVal[0].name)
                        }
                    });
                }
            })
        }
        return names.length ? names.toString() : ''
    }

    markAttendence = (jobs) => {
        if (!jobs.attendence) {
            store.dispatch(markAttendence({
                attendence: "1",
                session_id: jobs.id,
                applied_user_id: "64"
            }))
        }
    }

    submit = async (e) => {
        e.preventDefault()
        const formData = this.state.formData
        if (this.props.validator.allValid()) {
            formData['session_id'] = this.state.session_id
            await this.setState({ formData })
            await store.dispatch(sendFeedback(this.state.formData))
            await this.props.validator.hideMessages()
            this.setState({ formData: [] })
            this.feedbackRef.current.click()
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    handlePageChangeSchduledServices = (activePage) => {
        this.setState({ activePageSchduledServices: activePage })
    }

    handlePageChangeServices = (activePage) => {
        this.setState({ activePageServices: activePage })
    }

    change = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;

        formData[name] = value;
        this.setState({ formData });
    }

    handlePageChangeForgeignJobsInterview = (activePage) => {
        this.setState({ activePageForgeignJobsInterview: activePage })
    }

    componentDidMount() {
        document.title = this.props.t('header.my_account', { param: 'react' })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.JobseekerReducer.Profile && Object.keys(nextProps.JobseekerReducer.Profile).length) {

            const query = this.props.location.query
            // console.log('query', typeof query.event)
            if (nextProps.JobseekerReducer.Profile.webinar_subscription !== '1' && query.sub
                && query.sub === '1' && !this.state.appliedForWebinar) {
                store.dispatch(subscribeForWebinar({ webinar_subscription: '1' }, true))
                this.setState({ appliedForWebinar: true })
            }
            if (nextProps.JobseekerReducer.Profile.appliedEvent && nextProps.JobseekerReducer.Profile.appliedEvent.length &&
                query.event && query.event !== '1' && !this.state.appliedEvent) {
                const eventList = nextProps.JobseekerReducer.Profile.appliedEvent.filter((e) => e === query.event)
                if (eventList && !eventList.length) {
                    store.dispatch(applyEvent({ event_id: query.event }))
                    this.setState({ appliedEvent: true })
                }
            }
        }
    }
        
    appliedstatus(status){

       if(status==1){
        return ("Enrolled")
       } 
       else if(status==2){
        return ("Not Interested")
       }else if(status==3){
        return("Not Eligible")
       }else{
        return ("Seats Full")
       }
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => {
            this.validateField(name, value)
        });
    }

    getJobStatus = (status) => {
        const matchedJob = this.props.EmployerReducer.jobStatus.filter((job) => job.id === status)
        return matchedJob && matchedJob[0] ? matchedJob[0].name : ''
    }

    handleChange = async (e, job) => {

        this.setState({ checked: e.target.value })

        // if (e.target.value === '1') {
        if (this.state.inviteType === 'job') {
            await store.dispatch(postJobInterviewParticipation({
                accept: e.target.value,
                job_id: job
            }))
        } else {
            await store.dispatch(postForeignJobInterviewParticipation({
                accept: e.target.value,
                job_id: job
            }))
        }

        // }


        setTimeout(() => {
            const modal = document.getElementById("myModal01");
            this.setState({ checked: "" })
            modal.click();
        }, 200)

    }

    capitalizeFirstLetter = (string) => {
        if (string) {
            const newString = string.toLowerCase()
            return newString.charAt(0).toUpperCase() + newString.slice(1);
        }
    }

    getJobStatusText = (status) => {
        return status
        // const matchedJob = this.props.EmployerReducer.jobStatus.filter((job) => job.id === status)
        // return matchedJob && matchedJob[0] ? matchedJob[0].name : ''
    }

    goForInterview = (job, status) => {
        store.dispatch(willGoForInterview({
            accepted: status,
            id: job.id
        }))
    }


    getDate = (created_at) => {
        if (created_at) {
            let date = created_at.split(" ")[0]
            date = date.split("-")
            return `${date[2]}-${date[1]}-${date[0]}`
        }
    }

    redirectToJobs = (id) => {
        this.props.router.push(`/job-details/${id}`)
    }

    getFormatedDate = (expireOn) => {
        if (expireOn) {
            const date = expireOn.split("-")
            return `${date[2]}-${date[1]}-${date[0]}`
        }

    }

    getExperience = (jobData) => {
        if (jobData.min_exp && jobData.max_exp) {
            return `${jobData.min_exp} - ${jobData.max_exp} years`
        } else {
            return "Fresher"
        }
    }

    openPopupForJobs = () => {
        const { type, jobs } = this.state
        if (type === 'jobInvitesAccept') {
            return [
                {
                    title: jobs.job_title ? jobs.job_title : '',
                    lable: this.props.t('dashboard.table.job-title', { param: 'react' }) + ": "
                },
                {
                    title: jobs.org_name ? jobs.org_name : '',
                    lable: this.props.t('JobPost.org_name', { param: 'react' }) + ": "
                },

                {
                    title: jobs.interview_venue ? jobs.interview_venue : '',
                    lable: this.props.t('dashboard.table.location', { param: 'react' }) + ": "
                },
                {
                    title: jobs.salary_min && jobs.salary_max ? jobs.salary_min + '-' + jobs.salary_max : jobs.salary_min,
                    lable: this.props.t('dashboard.table.salary', { param: 'react' }) + ": "
                },
                {
                    title: jobs.expiring_on ? jobs.expiring_on : '',
                    lable: this.props.t('dashboard.table.date', { param: 'react' }) + ": "
                },
            ]
        }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    handlePageChangeEvents = (activePage) => {
        this.setState({ activePageEvents: activePage })
    }

    handlePageChange1 = (activePage) => {
        this.setState({ activePageUpcomingPvtJobs: activePage })
    }
    handlePageChange2 = (activePage) => {
        this.setState({ activePageUpcomingGovtJobs: activePage })
    }
    handlePageChangeJobInvites = (activePage) => {
        this.setState({ activePageJobInvites: activePage })
    }
    handlePageChangeScheduledJobs = (activePage) => {
        this.setState({ activePageScheduledJobs: activePage })
    }
    handlePageChangeCounselling = (activePage) => {
        this.setState({ activePageCounselling: activePage })
    }
    handlePageChangeStudy = (activePage) => {
        this.setState({ activePageStudy: activePage })
    }
    handlePageChangeForgeignJobs = (activePage) => {
        this.setState({ activePageForgeignJobs: activePage })
    }

    handlePageChangeEmployment = (activePage) => {
        this.setState({ activePagehandleEmployment: activePage })
    }

    handlePageChangeUpComingEmployment = (activePage) => {
        this.setState({ activePagehandleUpcomingEmployment: activePage })
    }

    handleChangeRelEmployment = (activePage) => {
        this.setState({ handleChangeRelEmployment: activePage })
    }

    handlePageChangeJobApplied = (activePage) => {
        this.setState({ activePageJobApplied: activePage })
    }

    // saveTrainingInterest = () => {
    //     if (!this.props.JobseekerReducer.training_interest) {
    //         store.dispatch(postTrainingInterest())
    //     }
    // }

    // changeInterest = () => {
    //     if (!this.props.JobseekerReducer.interest) {
    //         store.dispatch(addInterestInSelfEmp())
    //     }
    // }

    handlePageChangeCareer = (activePage) => {
        this.setState({ activePageCareer: activePage })
    }

    getTimeWithAmPm = (time) => {
        if (time) {
            let newtime = time.split(":");
            var AmOrPm = newtime[0] >= 12 ? 'PM' : 'AM';
            let hours = (newtime[0] % 12) || 12
            return hours + ":" + newtime[1] + " " + AmOrPm
        }
        return ""
    }



    getLocationFromIds = (location) => {
        const names = []
        if (this.props.actionReducer.District && this.props.actionReducer.District.length > 0 && location && location.length) {
            this.props.actionReducer.District.forEach((data) => {
                if (data.state_id === "3") {
                    location.forEach(element => {
                        let filterVal = data.dist.filter((district) => district.id === element)
                        if (filterVal && filterVal[0]) {
                            names.push(filterVal[0].name)
                        }
                    });
                }
            })
        }
        return names.length ? names.toString() : ''
        // return  ''
    }

    handlePageChangeForgeignJobs = (activePage) => {
        this.setState({ activePageForgeignJobs: activePage })
    }

    getDate = (created_at) => {
        if (created_at) {
            let date = created_at.split(" ")[0]
            date = date.split("-")
            return `${date[2]}-${date[1]}-${date[0]}`
        }
    }

    ratingChanged = (val) => {
        const formData = this.state.formData
        formData['rating'] = val
        this.setState({ formData })
    }

    scrollToAccordion = (selector) => {
        // document.getElementById(selector).scrollIntoView(false)
    }


    render() {

        const { jobs, type, activePageUpcomingGovtJobs, activePageUpcomingPvtJobs,
            activePageJobApplied, activePageScheduledJobs, formData,
            activePageCounselling, activePage, showTime, sessionData,
            // activePageForgeignJobs, activePageForgeignJobsInterview,
            // activeSelfEmp, activePagehandleEmployment, activePageCareer,
            activePageServices, activePageSchduledServices
            // activePageStudy, activePagehandleEmployment,
            // activePagehandleUpcomingEmployment, 
            // activePage,  activePageEvents 
        } = this.state
        let Mainprops = this.props;
        let popupData = ''
        popupData = this.openPopupForJobs()
  
        // const jobInvites = chunk(Mainprops.JobseekerReducer.jobInvites, RESULT_PER_PAGE)
      
        const appliedSkillCourse = chunk(Mainprops.JobseekerReducer.appliedSkillCoursesResult, RESULT_PER_PAGE)
        const ongoingSkillCourse = chunk(Mainprops.JobseekerReducer.ongoingSkillCoursesResult, RESULT_PER_PAGE)


        // const scheduledStudy = chunk(Mainprops.JobseekerReducer.scheduledStudy, RESULT_PER_PAGE)
        // const scheduledSelfEmployment = chunk(Mainprops.JobseekerReducer.scheduledSelfEmployment, RESULT_PER_PAGE)

        // const upComingSelfEmployment = chunk(Mainprops.JobseekerReducer.upComingSelfEmployment, RESULT_PER_PAGE)
        const appliedCounselling = chunk(this.props.JobseekerReducer.appliedPages, RESULT_PER_PAGE)
        // const appliedEvents = chunk(this.props.JobseekerReducer.appliedEvents, RESULT_PER_PAGE)
        // console.log('appliedEvents', Mainprops.JobseekerReducer.appliedEvents)
        // const scheduledSelfEmployment = chunk(Mainprops.JobseekerReducer.scheduledSelfEmployment, RESULT_PER_PAGE)
        // const relevantSelfEmp = chunk(Mainprops.JobseekerReducer.relevantSelfEmp, RESULT_PER_PAGE)

        // const scheduledForeignJobs = chunk(Mainprops.JobseekerReducer.scheduledForeignJobs, RESULT_PER_PAGE)
        // const appliedForeignJobs = chunk(Mainprops.JobseekerReducer.appliedForeignJobs, RESULT_PER_PAGE)
        // const careerOpt = chunk(Mainprops.JobseekerReducer.careerOpt, RESULT_PER_PAGE)
    

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        const { validator } = this.props

        const { selfEmpData, content } = this.state

        // console.log('upcomingPvtJobs', Mainprops.JobseekerReducer.upComingJobs)

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                      
                       
                        <main className="col second-side p-0 pb-3 h-100">
                            
                      
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <div className={"row"}>
                                                            <div className={"col-md-12"}>
                                                                <h6 className="mb-0"><span>Applied Skill Development Courses</span> <span className='float-right p-0 '><a href='/skill-courses' style={{'color':'White'}}>Back</a></span></h6>
                                                             
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="panel-body">
                                                       
                                                        < div className="accordion md-accordion accordion-2"
                                                            id="accordionNo1" role="tablist"
                                                            aria-multiselectable="true">
                                                            <div className="card border-0">
                                                          
                                                                <div id="collapse1221" className="collapse sub-content"
                                                                    role="tabpanel" aria-labelledby="heading12"
                                                                    data-parent="#accordionNo1">
                                                                    <div className="accordion md-accordion accordion-2 "
                                                                        id="accordionEx9" role="tablist"
                                                                        aria-multiselectable="true">

                                                                        <div className="card border-0">

                                                                       
                                                                   
                                                                        </div>
                                                                        <div className="card border-0">

   
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                     

                                                         {(appliedSkillCourse && appliedSkillCourse.length > 0) || (ongoingSkillCourse && ongoingSkillCourse.length) ? 
                                                            <div className="card border-0">
                                                                <div
                                                                    className="card-header rgba-stylish-strong z-depth-1 mb-1"
                                                                    role="tab" id="heading12">
                                                                   
                                                                    {appliedSkillCourse && appliedSkillCourse.length > 0 ? 



                                                            <div className="card border-0">
                                                            
                                                            
                                                                    
                                                                    <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                        <div className="table-responsive">
                                                                            <table className="table table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                        <th>Skill Name</th>
                                                                                        <th>Course Name</th>
                                                                                        <th>Sector</th>
                                                                                        <th>Start Date</th>
                                                                                        <th>End Date</th>
                                                                                        <th>Status</th>
                                                                                        <th>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {appliedSkillCourse.length > 0 && appliedSkillCourse[activePageCounselling - 1].map((skill, key) => {
                                                                                        return (<tr key={key}>
                                                                                            <td>{((activePageCounselling - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                            <td>{skill.skill_name ? skill.skill_name : ''} </td>
                                                                                            <td>{skill.course ? skill.course : ''} </td>
                                                                                            <td>{skill.sector ? skill.sector : ''} </td>
                                                                                            <td>{skill.start_date ? skill.start_date : ''} </td>
                                                                                            <td>{skill.end_date ? skill.end_date : ''} </td>
                                                                                            <td>{skill.applied ? skill.applied :" "}</td>
                                                                                            <td>
                                                                                                    <span onClick={() => this.setState({ skills: skill })}
                                                                                                        data-toggle="modal" data-target="#detailsModal1" className="date-clr" style={{ "cursor": "pointer" }}>
                                                                                                        {this.props.t('modal.clickHere', { param: 'react' })}
                                                                                                    </span>
                                                                                                
                                                                                            </td>
                                                                                        
                                                                                        </tr>)
                                                                                    })}

                                                                                    {!Mainprops.JobseekerReducer.appliedSkillCoursesResult.length && <tr>
                                                                                        <td colSpan="5" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                    </tr>}

                                                                                </tbody>
                                                                            </table>
                                                                            {/* {appliedSkillCourse && appliedSkillCourse.length > 1 ?
                                                                                <div className='react-pagination'>
                                                                                    <Pagination
                                                                                        pageRangeDisplayed={10}
                                                                                        activePage={activePageCounselling}
                                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                                        totalItemsCount={Mainprops.JobseekerReducer.appliedSkillCourse.length}
                                                                                        onChange={this.handlePageChangeCounselling}
                                                                                    />
                                                                                </div> : ''} */}
                                                                        </div>
                                                                    </div>
                                                                
                                                            </div>


                                                            : null} 
                                                                </div><div id="collapse1222" className="collapse sub-content"
                                                                    role="tabpanel" aria-labelledby="heading12"
                                                                    data-parent="#accordionNo1">
                                                                    <div className="accordion md-accordion accordion-2 "
                                                                        id="accordionEx9" role="tablist"
                                                                        aria-multiselectable="true">
                                                                         {appliedSkillCourse && appliedSkillCourse.length > 0 ? 



                                                                            <div className="card border-0">
                                                                             
                                                                               
                                                                                    
                                                                                    <div className="card-body p-2 mb-1 rgba-grey-light white-text">
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                        <th>Skill Name</th>
                                                                                                        <th>Course Name</th>
                                                                                                        <th>Sector</th>
                                                                                                        <th>Start Date</th>
                                                                                                        <th>End Date</th>
                                                                                                        <th>Status</th>
                                                                                                        <th>Action</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {appliedSkillCourse.length > 0 && appliedSkillCourse[activePageCounselling - 1].map((skill, key) => {
                                                                                                        return (<tr key={key}>
                                                                                                            <td>{((activePageCounselling - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                                            <td>{skill.skill_name ? skill.skill_name : ''} </td>
                                                                                                            <td>{skill.course ? skill.course : ''} </td>
                                                                                                            <td>{skill.sector ? skill.sector : ''} </td>
                                                                                                            <td>{skill.start_date ? skill.start_date : ''} </td>
                                                                                                            <td>{skill.end_date ? skill.end_date : ''} </td>
                                                                                                            <td>{skill.applied ? this.appliedstatus(skill.applied) :" "}</td>
                                                                                                            <td>
                                                                                                                    <span onClick={() => this.setState({ skills: skill })}
                                                                                                                        data-toggle="modal" data-target="#detailsModal1" className="date-clr" style={{ "cursor": "pointer" }}>
                                                                                                                        {this.props.t('modal.clickHere', { param: 'react' })}
                                                                                                                    </span>
                                                                                                                
                                                                                                            </td>
                                                                                                           
                                                                                                        </tr>)
                                                                                                    })}

                                                                                                    {!Mainprops.JobseekerReducer.appliedSkillCoursesResult.length && <tr>
                                                                                                        <td colSpan="5" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                    </tr>}

                                                                                                </tbody>
                                                                                            </table>
                                                                                            {/* {appliedSkillCourse && appliedSkillCourse.length > 1 ?
                                                                                                <div className='react-pagination'>
                                                                                                    <Pagination
                                                                                                        pageRangeDisplayed={10}
                                                                                                        activePage={activePageCounselling}
                                                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                                                        totalItemsCount={Mainprops.JobseekerReducer.appliedSkillCourse.length}
                                                                                                        onChange={this.handlePageChangeCounselling}
                                                                                                    />
                                                                                                </div> : ''} */}
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                            </div>


                                                                             : null} 
                                                                       
                                                                          
                                                                    </div>
                                                                </div>

                                                            </div>
                                                              : null} 
                                                 

                                                      
                        
                                                        
                            <div className="modal fade bd-example-modal-sm" id="detailsModal1" tabIndex="-1"
                                role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-md ">
                                    <div className="modal-content bg-modal bg-orange">
                                        <div className="modal-header" style={{ "background": "#f7b560 " }}>
                                            <h5 className="modal-title" id="exampleModalLabel">Applied Course Details</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body pt-2 pb-1 text-center" style={{ "background": "#f7b560 " }} ref={this.printContent}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <span className="AppliedJob"><span>Sector:</span> <span>{this.state.skills.sector}</span></span>
                                                    <span className="AppliedJob"><span>Job Role/Trade:</span> <span> {this.state.skills.course}</span></span>
                                                
                                                    {this.state.skills.skill_name &&
                                                        <span className="AppliedJob"><span>Batch Name: </span> <span>{this.state.skills.skill_name}</span></span>}
                                                        
                                                        

                                                    {this.state.skills.skill_name &&
                                                        <span className="AppliedJob"><span>Training Partner: </span> <span>{this.state.skills.tp_name}</span></span>}       

                                                        {this.state.skills.skill_name &&
                                                        <span className="AppliedJob"><span>Training Center : </span> <span>{this.state.skills.tc_name}</span></span>}
                                                         {this.state.skills.skill_name &&
                                                        <span className="AppliedJob"><span>Starting from : </span> <span>{this.state.skills.start_date} To {this.state.skills.end_date}</span></span>}

                                                         {this.state.skills.skill_name &&
                                                        <span className="AppliedJob"><span>Duration (hr): </span> <span>{this.state.skills.duration}</span></span>}

                                                         {this.state.skills.skill_name &&
                                                        <span className="AppliedJob"><span>Batch Strenght: </span> <span>{this.state.skills.strength}</span></span>}   
                                                        <hr></hr>
                                                         {this.state.skills.applied == "Enrolled" ? <>
                                                         <span className="AppliedJob"><h5>Contacts -:</h5></span>
                                                         {this.state.skills.sdcontact.map((skillls, key) => {
                                                        return (
                                                            <>
                                                         <span className="AppliedJob"><span>SD Name: </span> <span>{skillls.sd_name}</span></span>
                                                         <span className="AppliedJob"><span>Designation: </span> <span>{skillls.designation}</span></span>
                                                         <span className="AppliedJob"><span>Contact: </span> {skillls.contact}</span>
                                                        <span className="AppliedJob"><span>Email: </span> <span>{skillls.email}</span></span>
                                                        <hr></hr>
                                                       </> )})} </>:""}
                                                   
                                                   
                                                </div>
                                                <hr></hr>

                                            </div>

                                        </div>
                                        {/* <ReactToPrint
                                            trigger={() => <button
                                                className={"btn btn-danger btn-print mx-auto d-block mb-2 mt-2"}>
                                                {this.props.t('hallTicket.print', { param: 'react' })}
                                            </button>}
                                            content={() => this.printContent.current}
                                            copyStyles={true}
                                        /> */}
                                    </div>
                                </div>
                            </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    // console.log('store', store)
    return store;
};

export default connect(mapStateToProps)(translate(Validation(AppliedCoursesLink)));

