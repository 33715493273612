import React, { Component } from "react";
import { translate } from 'react-multi-lang'

class AccessDenied extends Component {

    redirectTo = () => {
        this.props.router.push('/myprofile')
    }

    componentDidMount() {
        document.title = this.props.t('header.access_denied', { param: 'react' })
    }

    render() {
        return (
            <section className="top-inner animatedParent main-content-height mt-5 mb-5">
                <div className="container">
                    <div className="jumbotron access-denied ">
                        <h3>{this.props.t('accessDenied.title', { param: 'react' })}</h3>
                        <hr className="my-4" />
                        <p>{this.props.t('accessDenied.getAccess', { param: 'react' })}.</p>
                        <button
                            onClick={() => this.redirectTo()}
                            className="btn btn-primary btn-primary2 mr-2 mx-auto d-block">{this.props.t('homeContent.clickHere', { param: 'react' })}
                        </button>
                    </div>
                </div>
            </section>
        )
    }
}

export default translate(AccessDenied);