import { SET_FORGIEN_POST_STATUS, SET_FORGIEN_PROVIDER_PROFILE,
  SET_FORGIEN_RECENT_JOBS, SET_FORGIEN_SCHEDULED_JOBS, 
  SET_FORGIEN_POST_ARCHIVE, SET_APPLIED_FORGIEN_JOB,
  FORGIEN_JOB_UPDATE, SET_SELECTED_FORGIEN_APPLICANTS,
  SET_SEEKER_FOR_FORGIEN_JOB, SET_JOB_TITLES, SET_FOREIGN_JOB_MODES, SET_PROVIDER_TYPES } from '../actions/actionTypes';
  
  const initialState = {
      Profile: [],
      jobPosted: false,
      recentJobs: [],
      scheduledInterviews:[],
      postArchive:[],
      appliedJobs: [],
      applicants:[],
      jobUpdated:false,
      searchResults:[],
      jobTitles:[],
      modesOfSelection:[],
      providerTypes:[]
  };

  const setJobProviderTypes = (state, action) => {
    return { ...state, providerTypes: action.data };
  };

  const setProfile = (state, action) => {
    return { ...state, Profile: action.data };
  }
  const setPostJobStatus = (state, action) => {
    return { ...state, jobPosted: action.status };
  };
  
  const setSearchResults = (state, action) => {
    return { ...state, searchResults: action.data };
  }
  const setRecentJobs = (state, action) => {
    return { ...state, recentJobs: action.data };
  }
  
  const setScheduledInterviews = (state, action) => {
    return { ...state, scheduledInterviews: action.data };
  }
  
  const setPostArchive = (state, action) => {
    return { ...state, postArchive: action.data };
  }
  
  const setAppliedJobs = (state, action) => {
    return { ...state, appliedJobs: action.data };
  }
  
  const setApplicants = (state, action) => {
    return { ...state, applicants: action.data };
  }
  
  const updateJob = (state, action) => {
    return { ...state, jobUpdated: action.status };
  }
  
  const setJobTitles = (state, action) => {
    return { ...state, jobTitles: action.data };
  }

  const setModesOfSelection = (state, action) => {
    return { ...state, modesOfSelection: action.data };
  }
  
  const forgienproviderReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_FORGIEN_PROVIDER_PROFILE: return setProfile(state, action);
      case SET_FORGIEN_POST_STATUS: return setPostJobStatus(state, action);
      case SET_FORGIEN_RECENT_JOBS: return setRecentJobs(state, action);
      case SET_FORGIEN_SCHEDULED_JOBS: return setScheduledInterviews(state, action);
      case SET_FORGIEN_POST_ARCHIVE: return setPostArchive(state, action);
      case SET_APPLIED_FORGIEN_JOB: return setAppliedJobs(state, action);
      case SET_SELECTED_FORGIEN_APPLICANTS: return setApplicants(state, action);
      case FORGIEN_JOB_UPDATE: return updateJob(state, action)
      case SET_SEEKER_FOR_FORGIEN_JOB: return setSearchResults(state, action)
      case SET_JOB_TITLES: return setJobTitles(state, action)
      case SET_FOREIGN_JOB_MODES: return setModesOfSelection(state, action)
      case SET_PROVIDER_TYPES: return setJobProviderTypes(state, action)
      default: return state;
    }
  };
  export default forgienproviderReducer;
  
  
  
  
  