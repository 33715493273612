import React, { Component } from 'react';
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import moment from "moment";
import { chunk } from '../../../helpers'
import Pagination from "react-js-pagination";
// import ServerSetting from '../../../config.json';
import {
    getShortlistedCandidates, setShortlistedCandidates, updateJobStatus,
    confirmJob
} from '../../../actions/employerAction'
import { Link } from 'react-router'


const RESULT_PER_PAGE = 25

class SelectedCandidatesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formDataPass: [],
            activePage: 1,
            Profile: [],
            job: [],
            submit: false,
            selectedCheckboxes: new Set(),
            candidatesArray: [],
            type: '',
            jobs: []
        }
        if (this.props.params && this.props.params.job_id) {
            store.dispatch(getShortlistedCandidates(this.props.params.job_id));
        }
    }

    confirmForJob = () => {
        const { type, jobs } = this.state
        if (type && jobs) {
            store.dispatch(confirmJob({ job_id: jobs.id, status: type, user_id: jobs.applied_user_id }))
            document.getElementById("confirmBox").click()
        }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    handleCheckbox = async (e) => {

        let value = e.target.value;
        let selectedCheckboxes = this.state.selectedCheckboxes

        if (selectedCheckboxes.has(value)) {
            selectedCheckboxes.delete(value);
        } else {
            selectedCheckboxes.add(value);
        }

        await this.setState({ selectedCheckboxes }, () => {
            value = Array.from(this.state.selectedCheckboxes)

        })

        this.setState({ candidatesArray: value })


    }


    componentWillUnmount() {
        store.dispatch(setShortlistedCandidates([]))
    }




    handleJobInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const Profile = this.state.Profile
        Profile[name] = value
        this.setState({ Profile, submit: false })
    }

    componentDidMount() {
        document.title = this.props.t('counsellingProvider.shortlisted', { param: 'react' })
    }

    handleChangeDate1 = (date, key) => {
        let formatedDate = moment(date).format('YYYY-MM-DD').split("-")
        if (formatedDate[0].length === 4) {
            const Profile = this.state.Profile
            formatedDate = `${formatedDate[0].substring(0, 4)}-${formatedDate[1]}-${formatedDate[2]}`
            Profile[key] = formatedDate
            this.setState({ Profile, submit: false });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { interview_date, slot_time_end, slot_time_start } = this.state.Profile
        if (this.props.validator.allValid() && !this.state.submit) {
            store.dispatch(updateJobStatus({
                job_status: '3',
                job_id: this.state.formDataPass.job_id,
                applied_user_id: this.state.job.applied_user_id,
                interview_date, slot_time_end, slot_time_start
            }))
            this.setState({ submit: true })
            document.getElementById("myModal414").click()
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }

    }


    render() {
        let Mainprops = this.props;

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        // const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        let searchResults = chunk(Mainprops.EmployerReducer.shortListed, RESULT_PER_PAGE)
        const { activePage } = this.state

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="modal fade" id="confirmBox" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="exampleModalLabel">{this.props.t('JobPost.finalize_inst', { param: 'react' })}</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary btn-primary2" onClick={() => this.confirmForJob()}>{this.props.t('profileDetails.yes', { param: 'react' })}</button>
                                <button type="button" className="btn btn-primary btn-primary2" data-dismiss="modal">{this.props.t('profileDetails.no', { param: 'react' })}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}


                        <main className="col second-side p-0 pb-3 h-100">

                            <div className="clearfix"></div>



                            {/* {Mainprops.EmployerReducer.shortListed.length > 0 */}
                            {/* && */}
                            <div id="home" className=" tab-pane active">
                                <section className="job-details mt-4">
                                    <div className="container">
                                        <div className="row">

                                            <div className="col-md-12  mx-auto  tab-content  p-0">

                                                <div className="panel-heading">
                                                    <h6 className="mb-0">{this.props.t('counsellingProvider.shortlisted', { param: 'react' })}</h6>

                                                </div>
                                                <div className="panel-body">
                                                    <div className="row pl-3 pr-3">
                                                        {Mainprops.EmployerReducer.shortListed && Mainprops.EmployerReducer.shortListed.length ?
                                                            <>
                                                                <h6 className="mr-3">{this.props.t('dashboard.table.job-title', { param: 'react' })}: <b>{Mainprops.EmployerReducer.shortListed[0].job_title}</b></h6>
                                                                <h6 className="mr-3">{this.props.t('dashboard.table.vacancies', { param: 'react' })}: <b>{Mainprops.EmployerReducer.shortListed[0].vacancies}</b></h6>
                                                                <h6 className="mr-3">{this.props.t('jobSearch.qual', { param: 'react' })}: <b>{Mainprops.EmployerReducer.shortListed[0].qualification}</b></h6>
                                                            </> : null
                                                        }
                                                    </div>
                                                    <hr></hr>
                                                    <div className="row pl-3 pr-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                        <th>{this.props.t('dashboard.table.name', { param: 'react' })}</th>
                                                                        <th>{this.props.t('dashboard.table.gender', { param: 'react' })}</th>
                                                                        <th>{this.props.t('dashboard.table.education', { param: 'react' })}</th>
                                                                        <th>{this.props.t('JobPost.district', { param: 'react' })}</th>
                                                                        <th>{this.props.t('JobPost.email', { param: 'react' })}</th>
                                                                        <th>{this.props.t('JobPost.exp1', { param: 'react' })}</th>
                                                                        <th>{this.props.t('dashboard.table.mobile', { param: 'react' })}</th>
                                                                        <th>{this.props.t('dashboard.table.action', { param: 'react' })}</th>
                                                                    </tr>

                                                                </thead>
                                                                <tbody>
                                                                    {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((jobs, key) => {

                                                                        return (<tr key={key}>
                                                                            <td>{((activePage - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                            <td>
                                                                                <span


                                                                                >{jobs.firstname ? jobs.firstname : ''}</span>


                                                                            </td>
                                                                            <td>{jobs.gender ? jobs.gender : ''}</td>
                                                                            <td>{jobs.education ? jobs.education : ''}</td>
                                                                            <td>{jobs.district ? jobs.district : ''}</td>
                                                                            <td>{jobs.email ? jobs.email : ''}</td>
                                                                            <td>{jobs.experience ? jobs.experience : ''}</td>
                                                                            <td>{jobs.mobile ? jobs.mobile : ''}</td>
                                                                            <td>
                                                                                {jobs.job_status !== '4' ?
                                                                                    <div className="dropdown">
                                                                                        <button
                                                                                            className="btn btn-primary2 p-1 btn-group dropdown-toggle"
                                                                                            type="button"
                                                                                            id="dropdownMenuButton2"
                                                                                            data-toggle="dropdown"
                                                                                            aria-haspopup="true"
                                                                                            aria-expanded="false">
                                                                                            Select
                                                                                    </button>
                                                                                        <div className="dropdown-menu"
                                                                                            aria-labelledby="dropdownMenuButton2">



                                                                                            {jobs.job_status === '3' &&
                                                                                                <>
                                                                                                    <Link className="dropdown-item"
                                                                                                        onClick={() => this.setState({ type: 'confirmed', jobs })
                                                                                                        }><span
                                                                                                            style={{ "cursor": "pointer" }}
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#confirmBox">{this.props.t('placeholder.confirm', { param: 'react' })}</span></Link>
                                                                                                    <Link className="dropdown-item"
                                                                                                        onClick={() => this.setState({ type: 'not-confirm', jobs })
                                                                                                        }><span
                                                                                                            style={{ "cursor": "pointer" }}
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#confirmBox">{this.props.t('placeholder.notConfirm', { param: 'react' })}</span></Link>
                                                                                                </>}

                                                                                            {jobs.job_status === '5' && <Link className="dropdown-item"
                                                                                                onClick={() => this.setState({ type: 'confirmed', jobs })
                                                                                                }><span
                                                                                                    style={{ "cursor": "pointer" }}
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#confirmBox">{this.props.t('placeholder.confirm', { param: 'react' })}</span></Link>}
                                                                                        </div>
                                                                                    </div> : null}

                                                                            </td>
                                                                        </tr>)
                                                                    })}
                                                                    {Mainprops.EmployerReducer.shortListed.notFound &&
                                                                        <tr>
                                                                            <td colSpan="9" style={{ "textAlign": "center" }}>
                                                                                <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5></td>
                                                                        </tr>}


                                                                </tbody>

                                                            </table>
                                                            {searchResults && searchResults.length > 1 ?
                                                                <div className='react-pagination'>
                                                                    <Pagination
                                                                        pageRangeDisplayed={10}
                                                                        activePage={activePage}
                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                        totalItemsCount={Mainprops.EmployerReducer.shortListed.length}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(SelectedCandidatesList));
