import React, { Component } from 'react';
import { translate } from 'react-multi-lang'
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroll-component'
import { applyJobMela, getJobMelaEmployers, removeJobApplied, get_jobseeker_profile } from '../../../actions/jobseekerAction';
import { get_User_District } from '../../../actions/accountAction'
import { Link } from 'react-router'
import _ from 'lodash';

class AppliedJobMela extends Component {

    constructor(props) {
        super(props);
        this.testModal1 = React.createRef();
        // this.scrollParentRef = React.createRef()
        this.state = {
            jobsArray: [],
            activePage: 1,
            noOfJobAllowed: '',
            id: '',
            offset: 1,
            limit: 25,
            isFetching: false,
            searchParams: [],
            loadProfile: false,
            isLoadingJobs: false
        }

        this.allowedDocFormats = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ]

        this.imageFormats = [
            'image/png',
            'image/jpg',
            'image/jpeg'
        ]
        store.dispatch(get_jobseeker_profile())
        store.dispatch(get_User_District())
    }

    applyForJobMela = () => {
        if (this.state.jobsArray && this.state.jobsArray.length) {
            this.testModal1.current.click()
            store.dispatch(applyJobMela(this.state.jobsArray))
        }
    }

    getTableItems = () => {
        return Object.keys(this.props.JobseekerReducer.hallTicket).length && this.props.JobseekerReducer.hallTicket.data &&
            this.props.JobseekerReducer.hallTicket.data.length ?
            this.props.JobseekerReducer.hallTicket.data.map((job, key) => {
                return (<div className="mela_row" key={key}>
                    {/* <div style={{ width: "10%" }}>{key + 1}</div> */}
                    <div style={{ width: "10%" }}>{job.district}</div>
                    <div style={{ width: "10%" }}>{job.org_name}</div>
                    <div style={{ width: "10%" }}>{job.job_title}</div>
                    <div style={{ width: "10%" }}>{job.vacancies}</div>
                    <div style={{ width: "10%", textAlign:'left'}}><span>{job.eligiblity}</span> <i style={{ fontSize: "15px", cursor: "pointer", color: "#ed9017" }}
            className="fa fa-plus-circle " aria-hidden="true" onClick={() => this.setCourseDetails(job)}
          ></i><span data-toggle="modal" data-target="#myModal1" id="spanModal1"></span></div>
                    <div style={{ width: "10%" }}>{job.maxage ? job.maxage : 'NA'}</div>
                    <div style={{ width: "10%" }}>{this.getGender(job.gender)}</div>
                    <div style={{ width: "10%" }}> {job.experience ? job.experience : 'NA'}</div>
                    <div style={{ width: "10%" }}>{job.salary}</div>
                    <div style={{ width: "10%" }}>{job.posting}</div>
                    <div style={{ width: "10%" }}>{this.getAction(job)}</div>
                    <div  style={{ width: "10%" }}><i style={{ fontSize: "20px", cursor: "pointer", color: "#ed9017" }}
                        className="fa fa-info-circle " aria-hidden="true" onClick={() => this.setInterviewDetails(job)}
                    ></i><span data-toggle="modal" data-target="#myModal" id="spanModal"></span></div>
                </div>)
            })
            : null
    }


    removeSelectedJob = () => {
        if (this.state.id) {
            store.dispatch(removeJobApplied(this.state.id, { limit: this.state.limit, offset: this.state.offset, searchParams: this.state.searchParams }))
            this.setState({ jobsArray: [] })
            document.getElementById("confirmBox").click()
        }
    }

    initialFetch = props => {
        if (props.JobseekerReducer.Profile && Object.keys(props.JobseekerReducer.Profile).length && !this.state.loadProfile) {
            const searchParams = []
            searchParams.gender = props.JobseekerReducer.Profile.gender
            store.dispatch(getJobMelaEmployers({ limit: this.state.limit, offset: this.state.offset, searchParams }));
            this.setState({ loadProfile: true, searchParams })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.initialFetch(nextProps)
        this.setState({ noOfJobAllowed: nextProps.JobseekerReducer.hallTicket.appliedJobsCount})
        if (nextProps.JobseekerReducer.hallTicket && nextProps.JobseekerReducer.hallTicket.data
            && nextProps.JobseekerReducer.hallTicket.data.length) {
            const isFetching = nextProps.JobseekerReducer.hallTicket.hasMoreRecords ? false : true
            this.setState({ isFetching })
        }
    }

    componentDidMount() {
        document.title = 'Rozgar Mela - 07.06.2023';
    }

    addJobs = (jobid) => {
        const jobsArray = this.state.jobsArray
        if (this.state.noOfJobAllowed < this.props.JobseekerReducer.hallTicket.selectJobsLimit) {
            if (jobsArray.indexOf(jobid) === -1) {
                jobsArray.push(jobid)
                this.setState((prevState) => {
                    return { jobsArray, noOfJobAllowed: prevState.noOfJobAllowed + 1 }
                })
            }
        }
    }

    removeJobs = (id) => {
        const jobsArray = this.state.jobsArray
        const index = jobsArray.indexOf(id);
        if (index > -1) {
            jobsArray.splice(index, 1);
            this.setState((prevState) => {
                return { jobsArray, noOfJobAllowed: prevState.noOfJobAllowed - 1 }
            })
        }
    }

    search = (e) => {
        let name = e.target.name
        let value = e.target.value
        const searchParams = this.state.searchParams
        if (value) {
            searchParams[name] = value
        } else {
            delete searchParams[name]
        }
        this.setState({ searchParams }, () => {
            this.debouncedSearch()
        })
    }

    debouncedSearch = _.debounce(() => {
        this.setState({ offset: 1 })
        const { searchParams, limit, offset } = this.state
        store.dispatch(getJobMelaEmployers({ offset, limit, searchParams }));
    }, 500)

    loadItems() {
        if (!this.state.isFetching) {
            this.setState((prevState) => {
                return { offset: prevState.offset + prevState.limit }
            })
            let searchParams = this.state.searchParams
            searchParams = Object.keys(searchParams).length ? searchParams : undefined
            store.dispatch(getJobMelaEmployers({ offset: this.state.offset, limit: this.state.limit, searchParams }, true));
            this.setState({ isFetching: true })
        }
    }

    checkForJob = (id) => {
        return this.state.jobsArray.indexOf(id) !== -1
    }

    getAction = (jobs) => {
        let html = ''
        if (jobs.status) {
            html = this.props.JobseekerReducer.hallTicket.editable ? <span
                onClick={() => this.setState({ id: jobs.id })}
                data-toggle="modal"
                data-target="#confirmBox"
                style={{ "cursor": "pointer" }}
                className="date-clr">{this.props.t('jobMela.unSelect', { param: 'react' })}</span>
                : <span>{this.props.t('sidebar.applied', { param: 'react' })}</span>
        } else {
            html = <span>
                {!this.checkForJob(jobs.id) &&
                    <span
                        data-toggle="modal"
                        data-target="#testmodal1"
                        onClick={() => this.addJobs(jobs.id)}
                        style={{ "cursor": "pointer" }}
                        className="date-clr">{this.props.t('jobMela.select', { param: 'react' })}</span>
                }

                {this.checkForJob(jobs.id) &&
                    <span
                        onClick={() => this.removeJobs(jobs.id)}
                        style={{ "cursor": "pointer" }}
                        className="date-clr">{this.props.t('jobMela.remove', { param: 'react' })}</span>
                }
            </span>
        }
        return html
    }

    getDistrict = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map(function (data, index) {
                if (data.state_id === '3') {
                    data.dist.map(function (distdata) {
                        // console.log(this.props.JobseekerReducer.hallTicket.data);
                        if ((distdata.id !== '666') && (distdata.id !== '668')) {//for all Punjab District in dropdown
                        // if (distdata.id === '36') { // for selected district
                            Distoption.push(<option key={distdata.name} 
                       
                        
                                value={distdata.name}>{distdata.name}</option>)
                        }

                        return Distoption
                    })
                }
                return Distoption
            })
        }
        return Distoption

    }

    getGender = (gender) => {
        if (gender.indexOf(',') >= 0) {
            return "Any"
        } else {
            return gender === '1' ? 'Female' : 'Male'
        }
    }

    

    setInterviewDetails = (details) => {
        document.getElementById("spanModal").click()
        this.setState({ interviewDetails: details })
    }

    setCourseDetails = (details) => {
        // document.getElementById("spanModal").click()
        document.getElementById("spanModal1").click()
        this.setState({ interviewDetails: details })
      }

    getInput = (field) => <input type="text" className="form-control" name={field} onChange={this.search} />

    getSelect = (field) => {
        let options = field === 'gender' ?
            <>
                <option value="">{this.props.t('profileDetails.selectGender', { param: 'react' })}</option>
                <option value="1">Female</option>
                <option value="2">Male</option>
                <option value="3">Any</option>
            </> :
            <>
                {field === 'district' ? <>
                    <option value="">{this.props.t('profileDetails.selectDistrict', { param: 'react' })}</option>
                    {this.getDistrict()}
                </>
                    :
                    <>
                        <option value="">{this.props.t('placeholder.select', { param: 'react' })}</option>
                        <option value="1">{this.props.t('dashboard.table.jobs', { param: 'react' })}</option>
                    </>}
            </>

        return <select name={field} className="form-control" value={this.state.searchParams[field] ? this.state.searchParams[field] : ''} onChange={this.search}>{options}</select>
    }


    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        let Mainprops = this.props;
        const { jobsArray, noOfJobAllowed } = this.state
        const JOBSALLOWED = this.props.JobseekerReducer.hallTicket.selectJobsLimit
        const { interviewDetails } = this.state

        const columns = [
            // {
            //     label: this.props.t('dashboard.table.sr_no', { param: 'react' }),
            //     field: 'srno',
            //     sort: 'asc',
            //     width: '4%',
            //     html: ''
            // },
            {
                label: this.props.t('dashboard.table.district', { param: 'react' }),
                field: 'district',
                sort: 'asc',
                width: '10%',
                html: this.getSelect('district')
            },
            {
                label: 'Employer',
                field: 'employer',
                sort: 'asc',
                width: '10%',
                html: this.getInput('employer')
            },
            {
                label: this.props.t('dashboard.table.job-title', { param: 'react' }),
                field: 'jobTitle',
                sort: 'asc',
                width: '10%',
                html: this.getInput('jobTitle')
            },
            {
                label: 'Vacancies',
                field: 'vacancies',
                sort: 'asc',
                width: '10%',
                html: this.getInput('vacancies')
            },
            {
                label: 'Eligiblity',
                field: 'eligibility',
                sort: 'asc',
                width: '10%',
                html: this.getInput('eligibility')
            },
            {
                label: 'Age' + "** ",
                field: 'maxage',
                sort: 'asc',
                width: '10%',
                html: this.getInput('age')
            },
            {
                label: this.props.t('dashboard.table.gender', { param: 'react' }),
                field: 'gender',
                sort: 'asc',
                width: '10%',
                html: this.getSelect('gender')
            },
            {
                label: 'Exp(yrs)',
                field: 'experience',
                sort: 'asc',
                width: '10%',
                html: this.getInput('experience')
            },
            {
                label: 'Salary',
                field: 'salary',
                sort: 'asc',
                width: '10%',
                html: this.getInput('salary')
            },
            {
                label: 'Posting Place',
                field: 'posting',
                sort: 'asc',
                width: '10%',
                html: this.getInput('posting')
            },
            {
                label: 'Apply',
                field: 'action',
                sort: 'asc',
                width: '10%',
                html: this.getSelect('action')
            },
            {
                label: 'Venue',
                field: '',
                sort: 'asc',
                width: '10%',
                
            }
        ];



        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container-fluid jobfair-container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />

                        <div className="modal" id="myModal1" tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Education Details </h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                        <h6><u>Course</u></h6>
                                        <span>{interviewDetails && interviewDetails.course}</span>
                                        <br/>
                                        <h6><u>Course Specialization</u></h6>
                                        <span> {interviewDetails && interviewDetails.course_specialization}</span>
                                </div>
                                <span style={{'fontSize':'16px', 'color':'orange', 'marginLeft':'2%'}}>For further details contact <b>{interviewDetails && interviewDetails.district} DBEE</b>. Mob: <b>{interviewDetails && interviewDetails.contact}</b></span>
                                <div className="modal-footer">
                                {/* <span>For More information contact to {interviewDetails && interviewDetails.district} DBEE. Mob: {interviewDetails && interviewDetails.contact}</span><br/> */}
                                    <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">{this.props.t('how_to_use.close', { param: 'react' })}</button>
                                </div>
                                </div>
                            </div>
                            </div>
                        <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.props.t('JobPost.int', { param: 'react' })}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {interviewDetails && interviewDetails.virtual_interview ==='1' ?
                                        <>
                                        <h6>{this.props.t('hallTicket.virtual', { param: 'react' })}</h6>
                                        <p>{this.props.t('hallTicket.interviewPlatform', { param: 'react' })}: {interviewDetails && interviewDetails.virtual_venue}</p>
                                        <p>{this.props.t('JobPost.int_date', { param: 'react' })}: {interviewDetails && interviewDetails.virtual_interview_date}</p>
                                        
                                        </> : null}
                                        {interviewDetails && interviewDetails.physical_interview ==='1' ?
                                        <>
                                        <h6>{this.props.t('hallTicket.physical', { param: 'react' })}</h6>
                                        <p>{this.props.t('JobPost.int_venue', { param: 'react' })}: {interviewDetails && interviewDetails.physical_venue}</p>
                                        <p>{this.props.t('JobPost.int_date', { param: 'react' })}: {interviewDetails && interviewDetails.physical_interview_date}</p>
                                        </> : null}
                                    </div>
                                    <span style={{'fontSize':'16px', 'color':'orange', 'marginLeft':'2%'}}>For further details contact <b>{interviewDetails && interviewDetails.district} DBEE</b>. Mob: <b>{interviewDetails && interviewDetails.contact}</b></span>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">{this.props.t('how_to_use.close', { param: 'react' })}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="confirmBox" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title" id="exampleModalLabel">{this.props.t('jobMela.deleteInst', { param: 'react' })}</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary btn-primary2" onClick={() => this.removeSelectedJob()}>{this.props.t('profileDetails.yes', { param: 'react' })}</button>
                                        <button type="button" className="btn btn-primary btn-primary2" data-dismiss="modal">{this.props.t('profileDetails.no', { param: 'react' })}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <main className="col second-side p-0 pb-3 h-100 ml-3 mr-0" style={{ "width": "100px" }}>
                            {/* <div className="clearfix"></div>
                            <marquee scrolldelay="10" behavior="scroll" direction="left"><b>{this.props.t('hallTicket.inst1', { param: 'react' })} {this.props.actionReducer.activeJobMela.dateFrom} {this.props.t('hallTicket.to', { param: 'react' })} {this.props.actionReducer.activeJobMela.dateTo}</b></marquee> */}
                            <div className="tab-content">

                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container-fluid">
                                            <div className="row ">
                                                <div className="col-sm-12 col-lg-12 p-0 mx-auto">
                                                    <div className="panel-heading">
                                                        <div className={"row"}>
                                                            <div className={"col-md-12 col-sm-12 "}>
                                                                <h6 className="mb-0 text-uppercase" style={{textAlign:'center'}}>Rozgar Mela - 07.06.2023</h6>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="panel-body p-0 m-2">
                                                        <section>
                                                            <div
                                                                className="job-sec bg-white mb-3 pb-3 ">
                                                                <div className="row">
                                                                    <div className="col-md-12 col-sm-12 cpl-lg-12  ">
                                                                        <div className={"row"}>
                                                                            <div className={"col-md-8 col-lg-8 col-sm-7"}>
                                                                                <h6 className={"mb-0 font-weight-bold text-uppercase"}>{this.props.t('jobMela.melaDesc', { param: 'react' })}</h6>
                                                                                {/* <p style={{ fontWeight: '500' }}>{this.props.t('hallTicket.maximum', { param: 'react' })} {JOBSALLOWED} {this.props.t('hallTicket.jobsToApply', { param: 'react' })}.</p> */}
                                                                                
                                                                                {/* <span style={{ fontWeight: '500' }}>{this.props.t('jobMela.noOfSelectedJobs', { param: 'react' })}: {noOfJobAllowed ? noOfJobAllowed : 0}/5 job(s) */}
                                                                                    {/* {jobsArray && jobsArray.length > 0 ?
                                                                                        <button type="btn"
                                                                                            onClick={() => this.applyForJobMela()}
                                                                                            className="btn btn-primary btn-primary2  d-block mt-2 justify-content-center align-self-center collapsed"

                                                                                        > {this.props.t('jobMela.apply', { param: 'react' })}
                                                                                        </button> : null} */}
                                                                                {/* </span>                                                                              */}
                                                                                </div>
                                                                            <div className={"col-md-3 col-lg-4 col-sm-5"}>
                                                                                <Link type="btn " to="myprofile" className="date-clr font-weight-bold pull-right">{this.props.t('jobMela.inst2', { param: 'react' })}</Link>
                                                                                <span className="pull-right">** {this.props.t('hallTicket.maxAge', { param: 'react' })}</span>

                                                                            </div>
                                                                        </div>
                                                                        <hr className={"mt-0 mb-2"} />
                                                                    </div>
                                                                </div>


                                                                <div className="row m-0">
                                                                    <div id="jobfair" className="overflowouter table-responsive">
                                                                        {this.props.JobseekerReducer.hallTicket && this.props.JobseekerReducer.hallTicket.hasOwnProperty('appliedJobsCount') ? <>
                                                                            {this.props.JobseekerReducer.hallTicket.appliedJobsCount === JOBSALLOWED ?
                                                                                <div className="text-center w-100">
                                                                                    <span>{this.props.t('jobMela.selectedJobs', { param: 'react' })} {JOBSALLOWED} {this.props.t('jobMela.linkBelow', { param: 'react' })}
                                                                                    </span>
                                                                                    <Link className="edu-add d-block" to="hall-ticket">{this.props.t('jobMela.here', { param: 'react' })}</Link>

                                                                                </div> :
                                                                                // <table className="table table-striped tablesmall headerfixed">
                                                                                <div className="testClass" id="scrollableDiv">
                                                                                    <div>
                                                                                        <div className="mela_row headertopfixed">
                                                                                            {columns.map((col, key) => {
                                                                                                return (<div style={{ width: col.width }} key={key}>{col.label}</div>)
                                                                                            })}
                                                                                        </div>
                                                                                        <div className="mela_row headermidfixed">
                                                                                            {columns.map((col, key) => {
                                                                                                return (<div key={key}>{col.html}</div>)
                                                                                            })
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* <InfiniteScroll
                                                                                        initialLoad={false}
                                                                                        // getScrollParent={() => this.scrollParentRef}
                                                                                        useWindow={false}
                                                                                        element="tbody"
                                                                                        loadMore={this.loadItems.bind(this)}
                                                                                        hasMore={this.props.JobseekerReducer.hallTicket.hasMoreRecords}
                                                                                        loader={<div key={0} >
                                                                                            <div colSpan="12" style={{ textAlign: "center" }}>
                                                                                                <Loader
                                                                                                    type="Rings"
                                                                                                    color="#F1911C"
                                                                                                    height={100}
                                                                                                    width={100}
                                                                                                />
                                                                                            </div></div>}
                                                                                    > */}
                                                                                    {this.props.JobseekerReducer.hallTicket && this.props.JobseekerReducer.hallTicket.hasOwnProperty('data') ?
                                                                                        <InfiniteScroll
                                                                                            height={400}
                                                                                            dataLength={this.props.JobseekerReducer.hallTicket.data.length}
                                                                                            next={this.loadItems.bind(this)}
                                                                                            hasMore={this.props.JobseekerReducer.hallTicket.hasMoreRecords}
                                                                                            loader={<div key={0} >
                                                                                                <div style={{ textAlign: "center" }}>
                                                                                                    <Loader
                                                                                                        type="Rings"
                                                                                                        color="#F1911C"
                                                                                                        height={100}
                                                                                                        width={100}
                                                                                                    />
                                                                                                </div></div>}
                                                                                            scrollableTarget="scrollableDiv"
                                                                                        >
                                                                                            <>
                                                                                                {this.getTableItems()}
                                                                                                {this.props.JobseekerReducer.hallTicket && this.props.JobseekerReducer.hallTicket.data &&
                                                                                                    !this.props.JobseekerReducer.hallTicket.data.length ?

                                                                                                    <div>
                                                                                                        <div
                                                                                                            className="job-not-found">
                                                                                                            <h3>{this.props.t('JobPost.notFound', { param: 'react' })}</h3>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : null}
                                                                                            </>
                                                                                        </InfiniteScroll> : null}

                                                                                </div>}
                                                                        </> : null}
                                                                    </div>

                                                                    <div id="testmodal1" className="modal right fade" ref={this.testModal1}>
                                                                        <div className="modal-dialog" style={{ 'maxWidth': '420px' }}>
                                                                            <div className="modal-content d-block" style={{ 'background': '#f6d9ab' }}>
                                                                                <button type="button" className="close " data-dismiss="modal">&times;</button>
                                                                                <div className="modal-body pt-3  pb-3 text-center">
                                                                                    <h3 className="mx-auto mb-3 placement">Rozgar Mela - 07.06.2023</h3>
                                                                                    <div className="abt_mela">
                                                                                        <ul className="text-left fair-date ">
                                                                                            {/* <li className="pl-18">
                                                                                            {this.props.t('jobMela.hallTicketInst', { param: 'react' })}</li> */}
                                                                                            {/* {JOBSALLOWED - noOfJobAllowed ? */}
                                                                                             {/* <li className="pl-18">{this.props.t('hallTicket.select', { param: 'react' })} {JOBSALLOWED - noOfJobAllowed} {this.props.t('jobMela.moreJobs', { param: 'react' })} {this.props.actionReducer.activeJobMela.dateTo}</li> : ''} */}
                                                                                            {JOBSALLOWED === noOfJobAllowed && <li className="pl-18">{this.props.t('jobMela.selected', { param: 'react' })} {JOBSALLOWED} {this.props.t('jobMela.clickBtn', { param: 'react' })}</li>}
                                                                                            
                                                                                            <li className="pl-18">{this.props.t('hallTicket.inst2', { param: 'react' })}</li>
                                                                                            <li className="pl-18">To apply and Generate Hall Ticket                                                                                      
                                                                                            {jobsArray && jobsArray.length > 0 &&
                                                                                            <Link className="blinkingtxtnew" style={{color:"#ed9017",fontWeight:"700",cursor:"pointer",marginLeft:"4px"}} onClick={() => this.applyForJobMela()}>Click Here</Link>
                                                                                            } 
                                                                                            {/* // <button type="btn"
                                                                                            //     onClick={() => this.applyForJobMela()}
                                                                                            //     className="btn btn-primary btn-primary2 "
                                                                                            // > Click Here
                                                                                            // </button> */}
                                                                                        
                                                                                        </li>
                                                                                        </ul>
                                                                                    </div>

                                                                                    <div className="row w-100 mx-auto d-flex">

                                                                                        {/* {noOfJobAllowed !== JOBSALLOWED ? */}
                                                                                            <button type="button" className=" btn btn-primary mb-2 btn-primary2 d-block justify-content-center align-self-center collapsed " data-dismiss="modal">{this.props.t('jobMela.continue', { param: 'react' })}</button>
                                                                                            {/* : null} */}
                                                                                       
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(AppliedJobMela));
