import React, { Component } from 'react';


class LocalServicePage extends Component {
    render() {
        return (
            <div>
            <div class="guestModal" style={{'z-index':'99999'}}>
              <div class="modal-dialog" style={{'max-width':'969px'}}>
                <div class="modal-content d-block">
                <div class="modal-body pt-3  pb-3 text-center" style={{'background':'linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%)'}}>
                  <img alt={''} src="/images/logo-new2.png" class="img-fluid pb-3 home-top-logo"/>
                    <h2 class="mx-auto mb-4">PUNJAB GHAR GHAR ROZGAR</h2>
                      <div class="row d-flex">
                        <div class="col-md-3 col-lg-3 col-sm-6 mb-2 d-flex ">
                          <div class="first-click">
                                <img alt={''} src="/images/mod2.png" class="img-fluid pb-3 w-50"/>
                                <p> About Portal</p>
                                <a type="button" href="signin"  class="btn btn-danger mt-2" >Click Here</a>
                          </div>
                      </div>
                      <div class="col-md-3 col-lg-3 col-sm-6 mb-2 d-flex ">
                        <div class="first-click">
                          <img alt={''} src="/images/mod1.png" class="img-fluid pb-3 w-50"/>
                            <p> If You want to Register with this Portal</p>
                              <a type="button" href="signup" class="btn btn-danger mt-2" >Click Here</a>
                          </div>
                      </div>
                      <div class="col-md-3 col-lg-3 col-sm-6 mb-2 d-flex">
                        <div class="first-click">
                          <img alt={''} src="/images/mod4.png" class="img-fluid pb-3 w-50"/>
                            <p> If You want to continue as a guest</p>
                            <button type="button" class="btn btn-danger mt-2" data-dismiss="modal" >Click Here</button>
                      </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-6 mb-2 d-flex">
                      <div class="first-click">
                        <img alt={''} src="/images/mod3.png" class="img-fluid pb-3 w-50"/>
                          <p> If You want to go home page</p>
                            <button type="button" class="btn btn-primary btn-primary2 mr-2 mt-2" data-dismiss="modal">Click Here</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
            </div>
        )
    }
}

export default LocalServicePage;
