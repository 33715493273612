import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-multi-lang'
import {Link} from 'react-router'
import {get_jobprovider_profile} from '../../../actions/employerAction'
import store from '../../../store'
import { getChatInvites } from '../../../actions/accountAction';

class Sidebar extends Component {

    componentDidMount() {
        if(this.props.EmployerReducer.Profile && !Object.keys(this.props.EmployerReducer.Profile).length){
            store.dispatch(get_jobprovider_profile());  
        }
        window.openCloseSidebar()
        store.dispatch(getChatInvites())
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, () => {
            this.validateField(name, value)
        });
    }

    capitalizeFirstLetter = (string) => {
        if(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }


    render() {
        let Mainprops = this.props;
        return (
            <aside className="col-md-2 px-0 widget-sidebar " id="left">
                <div className="list-group w-100">
                    <ul className="nav flex-column flex-nowrap">
                        {/* <li className={"nav-item list-group-item " + (Mainprops.route.path === '/myaccount' ? 'active' : '')}>
                            <Link className="nav-link" to="/myaccount">
                                {this.props.t('dashboard.welcome', {param: 'react'})} {this.props.EmployerReducer.Profile.org_name ?
                                <b>{", " + this.props.EmployerReducer.Profile.org_name}</b> : ''}
                            </Link>
                        </li> */}
                        <Link to="/myaccount" className="date-clr">
                            <div className="d-flex align-items-center justify-content-center w-100 pull-left pt-2 outr-profile text-center">
                                <div className="outr-avtar">
                                    {/* <img alt="avatar"
                                        src={'/images/img-1.png'}
                                        className="avatar mr-2 text-center" /> */}
                                    <i style={{"fontSize":"60px"}} className="fa fa-briefcase" aria-hidden="true"></i>
                                </div>
                                <div className="mb-2 font-18">
                                    <span className="outr-profile-text">
                                        {this.props.t('login.hi', { param: 'react' }).trimEnd()}, {this.capitalizeFirstLetter(this.props.EmployerReducer.Profile.org_name)}</span>
                                    <br></br>{this.props.t('dashboard.WelcomeDashboard', { param: 'react' })}
                                        </div>
                            </div>
                        </Link>
                        <li title={this.props.t('sidebar.update_profileDesc', {param: 'react'})} className={"nav-item list-group-item " + (Mainprops.route.path === '/myprofile' ? 'active' : '')}>
                            <Link className="nav-link"
                                  to="/myprofile"><b>{this.props.t('JobPost.org_profile', {param: 'react'})}</b></Link>
                        </li>
                        <li title={this.props.t('sidebar.post_job_vacanciesDesc', {param: 'react'})} className={"nav-item list-group-item " + (Mainprops.route.path === '/PostJob' ? 'active' : '')}>
                            <Link className="nav-link"
                                  to="/post-job"><b>{this.props.t('sidebar.post_job_vacancies', {param: 'react'})}</b></Link>
                        </li>
                        <li data-placement="bottom"
                            title="Click here for Forum"
                            className={"nav-item list-group-item " + (Mainprops.route.path === '/forum' ? 'active' : '')}>
                            <Link className="nav-link"
                                to="/forum"><b>
                                Discussion Forum</b> </Link>
                        </li>
                        {/* <li title={this.props.t('sidebar.search_jobseekerDesc', {param: 'react'})} className={"nav-item list-group-item " + (Mainprops.route.path === '/search' ? 'active' : '')}>
                            <Link className="nav-link"
                                  to="/search"><b>{this.props.t('sidebar.search_jobseeker', {param: 'react'})}</b>
                            </Link></li> */}
                        {/* <li title={this.props.t('sidebar.appliedDesc', {param: 'react'})} className={"nav-item list-group-item " + (Mainprops.route.path === '/schduled-interviews' ? 'active' : '')}>
                            <Link className="nav-link"
                                  to="/applied-jobs"><b>{this.props.t('sidebar.appliedProvider', {param: 'react'})}</b>
                            </Link></li> */}
                            {/* <li  data-placement="bottom"
                                title={this.props.t('sidebar.search_lspDesc', {param: 'react'})}
                                className={"nav-item list-group-item " + (Mainprops.route.path === '/servicesearch' ? 'active' : '')}>
                                <Link className="nav-link"
                                    to="/servicesearch"><b>{this.props.t('sidebar.search_service', {param: 'react'})}</b> </Link>
                            </li> */}
                        {/* <li className={"nav-item list-group-item " + (Mainprops.route.path === '/jobfair' ? 'active' : '')}>
                            <Link className="nav-link"
                                  to="/jobfair"><b>{this.props.t('jobMela.name', {param: 'react'})}</b>
                                <img src={"/images/BLINK.gif"} alt={"New"} />
                            </Link></li> */}
                        {/* <li className={"nav-item list-group-item " + (Mainprops.route.path === '/portal-feedback' ? 'active' : '')}>
                            <Link className="nav-link"
                                  to="/portal-feedback"><b>{this.props.t('sidebar.portal_feedback', {param: 'react'})}</b></Link>
                        </li> */}
                        {/* <li className="nav-item list-group-item" title={this.props.t('sidebar.change_passwordDesc', {param: 'react'})}><Link className="nav-link"
                                    to="/change-password"><b>{this.props.t('sidebar.change_password', {param: 'react'})}</b></Link>
                                    </li> */}
                        {/* <li className={"nav-item list-group-item " + (Mainprops.route.path === '/change-password' ? 'active' : '')}>
                            <a className="nav-link collapsed" href="#submenu1" data-toggle="collapse"
                               data-target="#submenu1"><b>{this.props.t('sidebar.settings', {param: 'react'})} </b></a>
                            <div className="collapse" id="submenu1" aria-expanded="false">
                                <ul className="flex-column pl-2 nav">
                                    <li className="nav-item list-group-item mb-2 p-2"><Link className="nav-link py-0"
                                    to="/change-password"><b>{this.props.t('sidebar.change_password', {param: 'react'})}</b></Link>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                    </ul>
                </div>

            </aside>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Sidebar));
