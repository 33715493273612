import React, { Component } from 'react';
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { chunk } from '../../../helpers'
import Pagination from "react-js-pagination";
import {
    getSessionApplicants, setSessionApplicants, applySesssion,
    scheduleOnlineOrTelephonicSession
} from '../../../actions/counsellingproviderAction'
import ReactExport from "react-export-excel";
import { Link } from 'react-router'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const RESULT_PER_PAGE = 25

class AppliedCandidates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formDataPass: [],
            activePage: 1,
            Profile: [],
            job: [],
            submit: false,
            selectedCheckboxes: new Set(),
            candidatesArray: [],
            called: false
        }
        if (this.props.params && this.props.params.id) {
            store.dispatch(getSessionApplicants(this.props.params.id));
        }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    UNSAFE_componentWillReceiveProps(nexpProps) {
        if (nexpProps.counsellingproviderReducer.applicants && nexpProps.counsellingproviderReducer.applicants.rows &&
            nexpProps.counsellingproviderReducer.applicants.rows.length && !this.state.called) {
            if (nexpProps.counsellingproviderReducer.applicants.rows[0].mode_id === '1' ||
                nexpProps.counsellingproviderReducer.applicants.rows[0].mode_id === '3') {
                store.dispatch(scheduleOnlineOrTelephonicSession({ session_id: this.props.params.id }))
                this.setState({ called: true })
            }
        }
    }

    shortListCandidates = () => {
        let seats = this.props.counsellingproviderReducer.applicants.rows
        store.dispatch(applySesssion({
            session_id: this.props.params.id,
            users: this.state.candidatesArray,
            auth_key: localStorage.getItem('token'),
            start_date: seats[0].session_date,
            slot: seats[0].time_slot
        }))
        this.setState({ candidatesArray: [], selectedCheckboxes: new Set() })
    }

    handleCheckbox = async (e) => {
        e.persist()

        let seats = this.props.counsellingproviderReducer.applicants.rows
        let count = this.props.counsellingproviderReducer.applicants.count
        seats = seats.filter((coun) => coun.counselling_status === "3")
        if (seats && seats.length) {
            seats = count - seats.length
        } else {
            seats = count
        }

        if (this.state.candidatesArray.length === seats && e.target.checked) {
            e.target.checked = false
        }
        else {
            let value = e.target.value;
            let selectedCheckboxes = this.state.selectedCheckboxes

            if (selectedCheckboxes.has(value)) {
                selectedCheckboxes.delete(value);
            } else {
                selectedCheckboxes.add(value);
            }

            await this.setState({ selectedCheckboxes }, () => {
                value = Array.from(this.state.selectedCheckboxes)
            })

            this.setState({ candidatesArray: value })
        }
    }

    componentWillUnmount() {
        store.dispatch(setSessionApplicants([]))
    }

    componentDidMount() {
        document.title = this.props.t('dashboard.table.appliedCandidates', { param: 'react' })
    }

    render() {
        let Mainprops = this.props;

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        const searchResults = chunk(Mainprops.counsellingproviderReducer.applicants.rows, RESULT_PER_PAGE)
        const { activePage } = this.state

        let seats = this.props.counsellingproviderReducer.applicants.rows
        let noOfSeat = ''
        if (seats && seats.length) {
            let count = this.props.counsellingproviderReducer.applicants.count
            seats = seats.filter((coun) => coun.counselling_status === "3")
            if (seats && seats.length) {
                noOfSeat = count - seats.length
            } else {
                noOfSeat = count
            }
        }

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}
                        <main className="col second-side p-0 pb-3 h-100">
                            {Mainprops.counsellingproviderReducer.applicants && Mainprops.counsellingproviderReducer.applicants.rows &&
                                Mainprops.counsellingproviderReducer.applicants.rows[0] && Mainprops.counsellingproviderReducer.applicants.rows[0].mode_id &&
                                Mainprops.counsellingproviderReducer.applicants.rows[0].mode_id === '2' ?
                                <div className="details-block">
                                    <p className="mx-auto mb-1"><strong>{this.props.t('modal.cand_sel', { param: 'react' })}</strong></p>

                                    {noOfSeat === this.state.candidatesArray.length &&
                                        <p className="m-0">
                                            {this.props.t('counsellingProvider.selectInst', { param: 'react' })}
                                        </p>
                                    }
                                    <>
                                        {noOfSeat - this.state.candidatesArray.length ? <p className="m-0">{this.props.t('JobPost.selCan', { param: 'react' })} <b>{noOfSeat - this.state.candidatesArray.length}</b> slots more</p> : null}
                                        {this.state.candidatesArray && this.state.candidatesArray.length ? <p className="m-0"> {this.props.t('counsellingProvider.candidatesForSelection', { param: 'react' })} {this.state.candidatesArray.length}</p> : null}
                                    </>
                                    <>

                                        <div className="row w-100 mx-auto d-flex pt-2">
                                            {this.state.candidatesArray && this.state.candidatesArray.length > 0 &&
                                                <button type="btn"
                                                    data-dismiss="modal"
                                                    onClick={() => this.shortListCandidates()}
                                                    className="btn btn-primary btn-primary2  d-block justify-content-center align-self-center collapsed"

                                                > {this.props.t('JobPost.shortlist_candidate', { param: 'react' })}
                                                </button>}
                                        </div>
                                    </>
                                </div> : null}
                            <div className="clearfix"></div>
                            <div>
                                <div id="home" className=" tab-pane active">
                                    <section className="job-details mt-4">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12  mx-auto  tab-content  p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('dashboard.table.appliedCandidates', { param: 'react' })}
                                                        </h6>
                                                    </div>
                                                    <div className="panel-body">
                                                        {this.props.counsellingproviderReducer.applicants && this.props.counsellingproviderReducer.applicants.notFound ?
                                                            <h4>{this.props.counsellingproviderReducer.applicants.notFound}</h4>
                                                            : null}
                                                        <div className="row pl-3 pr-3">
                                                            {Mainprops.counsellingproviderReducer.applicants && Mainprops.counsellingproviderReducer.applicants.rows &&
                                                                Mainprops.counsellingproviderReducer.applicants.rows.length ?
                                                                <>
                                                                    <h6 className="mr-3">{this.props.t('counsellingProvider.session_name', { param: 'react' })}: <b>{Mainprops.counsellingproviderReducer.applicants.rows[0].session_name}</b></h6>
                                                                    <h6 className="mr-3">{this.props.t('counsellingProvider.seat', { param: 'react' })}: <b>{Mainprops.counsellingproviderReducer.applicants.rows[0].seat}</b></h6>
                                                                    <h6 className="mr-3">{this.props.t('counsellingProvider.sessionMode', { param: 'react' })}: <b>{Mainprops.counsellingproviderReducer.applicants.rows[0].mode}</b></h6>
                                                                    <h6 className="mr-3">
                                                                        {seats && seats.length ? <ExcelFile filename="Selected candidate" element={<button className="btn btn-primary btn-primary2 pull-right" style={{ "cursor": "pointer" }} >
                                                                            {this.props.t('counsellingProvider.downloadExcelInst1', { param: 'react' })}</button>}>
                                                                            <ExcelSheet data={this.props.counsellingproviderReducer.applicants.rows} name="Candidates">
                                                                                <ExcelColumn label="Name" value={(jobs) => jobs.firstname ? jobs.firstname : ''} />
                                                                                <ExcelColumn label="Gender" value={(jobs) => jobs.gender ? jobs.gender : ''} />
                                                                                <ExcelColumn label="Education" value={(jobs) => jobs.education ? jobs.education : ''} />
                                                                                <ExcelColumn label="Mobile"
                                                                                    value={(jobs) => jobs.mobile ? jobs.mobile : ''} />
                                                                                <ExcelColumn label="Email" value={(jobs) => jobs.email ? jobs.email : ''} />
                                                                                <ExcelColumn label="Status"
                                                                                    value={(jobs) => {
                                                                                        if (jobs.counselling_status && jobs.counselling_status === '1') {
                                                                                            return 'Applied'
                                                                                        } else if (jobs.counselling_status && jobs.counselling_status === '3') {
                                                                                            return 'Shortlisted'
                                                                                        } else {
                                                                                            return ''
                                                                                        }
                                                                                    }} />
                                                                            </ExcelSheet>
                                                                        </ExcelFile>
                                                                            : ''}
                                                                    </h6>
                                                                </> : null
                                                            }
                                                        </div>

                                                        {Mainprops.counsellingproviderReducer.applicants && Mainprops.counsellingproviderReducer.applicants.rows &&
                                                            Mainprops.counsellingproviderReducer.applicants.rows[0] && Mainprops.counsellingproviderReducer.applicants.rows[0].mode_id &&
                                                            Mainprops.counsellingproviderReducer.applicants.rows[0].mode_id === '2' ?
                                                            <div className="row pl-3 pr-3">
                                                                <div className="table-responsive">
                                                                    <table className="table table-hover table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.name', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.gender', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.education', { param: 'react' })}</th>
                                                                                <th>{this.props.t('JobPost.district', { param: 'react' })}</th>
                                                                                <th>{this.props.t('JobPost.email', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.mobile', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.action', { param: 'react' })}</th>
                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((jobs, key) => {
                                                                                return (<tr key={key}>
                                                                                    <td>{((activePage - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                    <td>
                                                                                        <span
                                                                                        >{jobs.firstname ? jobs.firstname : ''}</span>
                                                                                    </td>
                                                                                    <td>{jobs.gender ? jobs.gender : ''}</td>
                                                                                    <td>{jobs.education ? jobs.education : ''}</td>
                                                                                    <td>{jobs.district ? jobs.district : ''}</td>
                                                                                    <td>{jobs.email ? jobs.email : ''}</td>
                                                                                    <td>{jobs.mobile ? jobs.mobile : ''}</td>
                                                                                    <td>
                                                                                        {jobs.counselling_status !== '3' &&
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input ml-3"
                                                                                                name="alternatePhone"
                                                                                                value={jobs.applied_user_id}
                                                                                                onChange={(event) => this.handleCheckbox(event, jobs)}
                                                                                            />}
                                                                                        {jobs.counselling_status === '3' &&
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input ml-3"
                                                                                                name="selected"
                                                                                                disabled={jobs.counselling_status === '3'}
                                                                                                checked={jobs.counselling_status === '3'}

                                                                                            />}
                                                                                    </td>
                                                                                </tr>)
                                                                            })}
                                                                            {Mainprops.counsellingproviderReducer.applicants.notFound &&
                                                                                <tr>
                                                                                    <td colSpan="8" style={{ "textAlign": "center" }}>
                                                                                        <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5></td>
                                                                                </tr>}
                                                                        </tbody>

                                                                    </table>
                                                                    {searchResults && searchResults.length > 1 ?
                                                                        <div className='react-pagination'>
                                                                            <Pagination
                                                                                pageRangeDisplayed={10}
                                                                                activePage={activePage}
                                                                                itemsCountPerPage={RESULT_PER_PAGE}
                                                                                totalItemsCount={Mainprops.counsellingproviderReducer.applicants && Mainprops.counsellingproviderReducer.applicants.rows
                                                                                    && Mainprops.counsellingproviderReducer.applicants.rows.length}
                                                                                onChange={this.handlePageChange}
                                                                            />
                                                                        </div> : ''}
                                                                </div> </div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(AppliedCandidates));
