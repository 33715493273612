import React, { Component } from 'react'
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { getEducationLevel } from '../../../actions/accountAction';
import { searchCareer } from '../../../actions/jobseekerAction';
import Pagination from "react-js-pagination";
import { chunk } from '../../../helpers'
import { Link } from 'react-router'
import { translate } from 'react-multi-lang'

const RESULT_PER_PAGE = 25

class SearchCareer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: [],
            jobDetails: false,
            job: [],
            activePage: 1,
            submitted: false
        }

        store.dispatch(getEducationLevel());
    }

    componentDidMount() {
        document.title = this.props.t('sidebar.search_career', { param: 'react' })
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    handleInput = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        formData[name] = value;
        if (!value) {
            delete formData[name]
        }
        await this.setState({ formData, submitted: false });
    }

    clicked = (job) => {
        this.setState({ job, jobDetails: true })
    }

    submit = (e) => {
        e.preventDefault()
        if (!this.state.submitted) {
            this.setState({ activePage: 1 })
            store.dispatch(searchCareer(this.state.formData));
            this.setState({ submitted: true })
        }
    }

    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const Mainprops = this.props
        const { jobDetails, job, activePage, formData } = this.state
        const searchResults = chunk(Mainprops.JobseekerReducer.careerResults, RESULT_PER_PAGE)

        const data = []
        let content = job
        if (formData.education) {
            const educationLevel = this.props.actionReducer.educationLevel
            const selectedEducation = educationLevel.filter((edu) => edu.education_id === formData.education)
            if (selectedEducation && selectedEducation[0]) {
                selectedEducation && selectedEducation[0] && selectedEducation[0].qualification.forEach((qual) => {
                    data.push({ label: qual.name, id: qual.id })
                })
            }
        }

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            {jobDetails &&
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('counsellingProvider.details', { param: 'react' })}
                                                            <Link onClick={() => this.setState({ jobDetails: false })} style={{ color: "#fff", cursor:"pointer" }}
                                                                className="pull-right">{this.props.t('jobSearch.goBack', { param: 'react' })}</Link></h6>
                                                            
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="row ">
                                                                <div className="col-md-12">
                                                                    <div className="cover-shadow p-3 m-2">
                                                                        <>
                                                                            <h5>{content.sector_profile}</h5>
                                                                            <hr></hr>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.JOB DESCRIPTION', { param: 'react' })}</h6>
                                                                            </div>
                                                                            <div className="panel-body">
                                                                                <p className="date-clr">{content.description ? content.description : "None"}</p>
                                                                            </div>

                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.WORK ENVIRONMENT', { param: 'react' })}</h6>
                                                                            </div>
                                                                            <div className="panel-body">
                                                                                <p className="date-clr">{content.work_environment ? content.work_environment : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.KEY COMPETENCIES', { param: 'react' })}</h6>
                                                                            </div>
                                                                            <div className="panel-body">
                                                                                <p className="date-clr">{content.key_competencies ? content.key_competencies : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.DESIRABLE COMPETENCIES', { param: 'react' })}</h6>
                                                                            </div>
                                                                            <div className="panel-body">
                                                                                <p className="date-clr">{content.desirable_competencies ? content.desirable_competencies : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.AVAILABLE SKILL TRAINING AND LEARNING INSTITUTES', { param: 'react' })}</h6>
                                                                            </div>
                                                                            <div className="panel-body">
                                                                                <p className="date-clr">{content.skill_training_institutes ? content.skill_training_institutes : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.AVAILABLE SKILL TRAINING SCHEMES/SCHOLARSHIPS', { param: 'react' })}</h6></div>
                                                                            <div className="panel-body">
                                                                                <p className="date-clr">{content.skill_training_schemes ? content.skill_training_schemes : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.CAREER PROGRESSION PATH', { param: 'react' })}</h6>
                                                                            </div>
                                                                            <div className="panel-body">
                                                                                <p className="date-clr">{content.career_progress_path ? content.career_progress_path : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.EXPECTED EARNINGS', { param: 'react' })}</h6>
                                                                            </div>
                                                                            <div className="panel-body"><p className="date-clr">{content.expected_earnings ? content.expected_earnings : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.REQUIRED WORK EXPERIENCE', { param: 'react' })}</h6></div>
                                                                            <div className="panel-body"><p className="date-clr">{content.work_experience ? content.work_experience : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.PROBABLE EMPLOYERS', { param: 'react' })}</h6></div>
                                                                            <div className="panel-body"><p className="date-clr">{content.employers ? content.employers : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.KEYWORDS', { param: 'react' })} </h6></div>
                                                                            <div className="panel-body"><p className="date-clr">{content.keywords ? content.keywords : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.OCCUPATIONAL CODES AND STANDARDS', { param: 'react' })} </h6>
                                                                            </div>
                                                                            <div className="panel-body"> <p className="date-clr">{content.desirable_competencies ? content.desirable_competencies : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        <>
                                                                            <div className="panel-heading">
                                                                                <h6>{this.props.t('selfEmployment.REFERENCES', { param: 'react' })}</h6>
                                                                            </div>
                                                                            <div className="panel-body"><p className="date-clr">{content.reference ? content.reference : "None"}</p>
                                                                            </div>
                                                                        </>
                                                                        {/* <div className="row ">
                                                                            <div className="col-md-12">
                                                                                <hr />
                                                                                <button onClick={() => this.setState({ jobDetails: false })} style={{ "marginLeft": "10px" }}
                                                                                    className="btn btn-primary btn-primary2 mt-1">{this.props.t('jobSearch.goBack', { param: 'react' })}</button>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>}
                            {!jobDetails && <div>
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-3">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('sidebar.search_career', { param: 'react' })}</h6>
                                                        </div>
                                                        <div className="panel-body mt-2">
                                                            <form onSubmit={this.submit}>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-4 col-md-3  col-5 ">
                                                                            <label>{this.props.t('counsellingProvider.education', { param: 'react' })}</label>
                                                                        </div>
                                                                        <div className="col-sm-8 col-md-7  col-7">
                                                                            <select onChange={this.handleInput}
                                                                                value={formData.education ? formData.education : ''}
                                                                                className="form-control" name="education">
                                                                                <option value="">{this.props.t('forms.selectEdu', { param: 'react' })}</option>
                                                                                {Mainprops.actionReducer.educationLevel.length > 0 &&
                                                                                    Mainprops.actionReducer.educationLevel.map(function (data, key) {
                                                                                        return (
                                                                                            <option value={data.education_id} key={key}>{data.education_name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-4 col-md-3  col-5 ">
                                                                            <label>{this.props.t('selfEmployment.Qualification', { param: 'react' })}</label>
                                                                        </div>
                                                                        <div className="col-sm-8 col-md-7  col-7">
                                                                            <select
                                                                                onChange={this.handleInput}
                                                                                value={this.state.formData.course ? this.state.formData.course : ''}
                                                                                className="form-control"
                                                                                name="course">
                                                                                <option value="">{this.props.t('banner.select_qualification', { param: 'react' })}</option>
                                                                                {data && data.length &&
                                                                                    data.map(function (d, index) {
                                                                                        return (
                                                                                            <option key={index}
                                                                                                value={d.id}>{d.label}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-4 col-md-3  col-5 ">
                                                                            <label>{this.props.t('JobPost.keyword', { param: 'react' })}</label>
                                                                        </div>
                                                                        <div className="col-sm-8 col-md-7  col-7">
                                                                            <input onChange={this.handleInput}
                                                                                placeholder={this.props.t('placeholder.enter', { param: 'react' }) +" "+ this.props.t('JobPost.keyword', { param: 'react' })}
                                                                                value={formData.keyword ? formData.keyword : ''}
                                                                                className="form-control" name="keyword" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 ">
                                                                            <button type="submit"
                                                                                className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                                {this.props.t('jobSearch.search', { param: 'react' })}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                {Mainprops.JobseekerReducer.careerResults.length > 0 &&
                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('jobSearch.results',
                                                                    { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                {searchResults && searchResults.length && searchResults[activePage - 1].map((job, key) => {
                                                                    return (
                                                                        <div className="job-sec bg-white cover-shadow mb-2 p-3" key={key}>
                                                                            <div className="row">
                                                                                <div className="col-md-8">
                                                                                    <Link>{job.sector_profile ? job.sector_profile : ''}</Link>
                                                                                    <ul className="nav">
                                                                                        <li className="list-items-inline pt-2" >{this.props.t('selfEmployment.sector', { param: 'react' })}: {job.sector ? job.sector : ''}
                                                                                        </li>
                                                                                        <li className="list-items-inline pt-2 pl-3" >{this.props.t('counsellingProvider.education', { param: 'react' })}: {job.education ? job.education : ''}
                                                                                        </li>
                                                                                        <li className="list-items-inline pt-2 pl-3" >{this.props.t('selfEmployment.Qualification', { param: 'react' })}: {job.display_qualification ? job.display_qualification : ''}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <span
                                                                                        onClick={() => this.clicked(job)}
                                                                                        style={{ "cursor": "pointer" }} className="date-clr pull-right" >
                                                                                        {this.props.t('jobSearch.viewDetails', { param: 'react' })}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                })}
                                                                {searchResults && searchResults.length > 1 ? <div className='react-pagination'>
                                                                    <Pagination
                                                                        pageRangeDisplayed={10}
                                                                        activePage={activePage}
                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                        totalItemsCount={Mainprops.JobseekerReducer.careerResults.length}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}
                                {Mainprops.JobseekerReducer.careerResults.status === '401' && <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('jobSearch.results',
                                                                { param: 'react' })}</h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            <h3>{Mainprops.JobseekerReducer.careerResults.message}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>}
                            </div>
                            }
                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(SearchCareer));