import { combineReducers } from 'redux';
import actionReducer from './actionReducer';
import notificationReducer from './notificationReducer';
import JobpostReducer from './jobpostReducer';
import JobseekerReducer from './JobseekerReducer';
import EmployerReducer from './EmployerReducer';
import counsellingproviderReducer from './counsellingproviderReducer';
import forgienproviderReducer from './forgienproviderReducer'
import forgienstudyproviderReducer from './forgienstudyproviderReducer'
import loaderReducer from './loaderReducer';
import LspReducer from './LspReducer'

export default combineReducers({
	JobseekerReducer: JobseekerReducer,
	JobpostReducer: JobpostReducer,
	EmployerReducer: EmployerReducer,
	notificationReducer: notificationReducer,
	actionReducer: actionReducer,
	loaderReducer:loaderReducer,
	counsellingproviderReducer:counsellingproviderReducer,
	forgienproviderReducer:forgienproviderReducer,
	forgienstudyproviderReducer: forgienstudyproviderReducer,
	LspReducer:LspReducer
});
