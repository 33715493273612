import React, { Component } from 'react';
import Sidebar from './Sidebar';
import store from "../../../store";
import { connect } from 'react-redux';
import { createTicket, getTickets, getIssueTypes } from "../../../actions/jobseekerAction";
import { translate } from 'react-multi-lang'
import Validation from "../../hoc/Validation";
import { chunk, getTimeWithAmPm } from '../../../helpers'
import Pagination from "react-js-pagination";
import { Link } from 'react-router'

const RESULT_PER_PAGE = 25

class Crm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: [],
            submit: false,
            activePage: 1,
            ticket: []
        }
        this.confirmBox = React.createRef()
        store.dispatch(getTickets())
        store.dispatch(getIssueTypes())
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    handleInputs = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        formData[name] = value;
        await this.setState({ formData, submit: false });
    }

    componentDidMount() {
        document.title = this.props.t('crm.pageTitle', { param: 'react' })
    }

    submit = async (e) => {
        e.preventDefault();
        if (!this.props.validator.allValid() && !this.state.submit) {
            store.dispatch(createTicket(this.state.formData))
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    confirmSubmit = () => {
        this.confirmBox.current.click()
        store.dispatch(createTicket(this.state.formData))
        this.props.validator.hideMessages()
        this.setState({ formData: [], submit: true })
    }

    getTimeInfo = (time) => {
        if (time) {
            let splitTime = time.split(' ')
            splitTime = getTimeWithAmPm(splitTime[1])
            return splitTime
        }
    }

    render() {
        let Mainprops = this.props;
        const { validator } = this.props
        const { formData, activePage, ticket } = this.state
        const Mainthis = this

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        let searchResults = chunk(Mainprops.JobseekerReducer.tickets, RESULT_PER_PAGE)
        const issueType = this.props.JobseekerReducer.issueTypes

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}
                        <div className="modal fade" ref={this.confirmBox} id="confirmBox" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title" id="exampleModalLabel">{this.props.t('JobPost.ticketConfirm', { param: 'react' })}</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary btn-primary2" onClick={this.confirmSubmit}>{this.props.t('profileDetails.yes', { param: 'react' })}</button>
                                        <button type="button" className="btn btn-primary btn-primary2" data-dismiss="modal">{this.props.t('profileDetails.no', { param: 'react' })}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12 bg-education mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('crm.pageTitle', { param: 'react' })} {this.props.t('crm.inst1', { param: 'react' })}, <Link to="/employment">{this.props.t('modal.clickHere', { param: 'react' })}</Link>)</h6>
                                                    </div>
                                                    <div className="panel-body">
                                                        <form onSubmit={this.submit} className="p-3">
                                                            <div className="form-group mb-0 pb-4">
                                                                <div className="row">
                                                                    <div className="col-sm-6 col-lg-2 text-right">
                                                                        <label>{this.props.t('crm.Type of Issue', { param: 'react' })} :</label>
                                                                        <span style={{ color: 'red' }}
                                                                            className="ml-2">*</span>
                                                                    </div>
                                                                    <div className="col-sm-6 col-lg-10">
                                                                        <select className="form-control"
                                                                            onChange={(event) =>
                                                                                Mainthis.handleInputs(event)}
                                                                            value={formData.issue_type ? formData.issue_type : ''}
                                                                            name="issue_type">
                                                                            <option value="">{this.props.t('crm.Select Type of Issue', { param: 'react' })}
                                                                            </option>
                                                                            {issueType && issueType.length > 0 ?
                                                                                issueType.map(function (issue, index) {
                                                                                    return (
                                                                                        <option key={index}
                                                                                            value={issue.id}>{issue.name}</option>
                                                                                    )
                                                                                }) : null
                                                                            }
                                                                        </select>
                                                                        {validator.message('issue_type', formData.issue_type, 'required')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-0 pb-4">
                                                                <div className="row">
                                                                    <div className="col-sm-6 col-lg-2 text-right">
                                                                        <label>{this.props.t('crm.Describe Issue', { param: 'react' })} :</label>
                                                                        <span style={{ color: 'red' }}
                                                                            className="ml-2">*</span>
                                                                    </div>
                                                                    <div className="col-sm-6 col-lg-10">
                                                                        <textarea
                                                                            rows="4"
                                                                            cols={"8"}
                                                                            onChange={(event) => Mainthis.handleInputs(event)}
                                                                            value={formData.issue_desc ? formData.issue_desc : ''}
                                                                            className="w-100 "
                                                                            name="issue_desc"
                                                                            placeholder={this.props.t('crm.Enter Issue Description', { param: 'react' })} />
                                                                        {validator.message('issue description', formData.issue_desc, 'required')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="form-group mb-0">
                                                                <div className="row">
                                                                    <div
                                                                        className="col-sm-6 col-lg-5 text-right"></div>
                                                                    <div className=" col-sm-6 col-lg-5">
                                                                        <button type="submit"
                                                                            data-toggle={this.props.validator.allValid() && !this.state.submit ? "modal" : ""}
                                                                            data-target={this.props.validator.allValid() && !this.state.submit ? "#confirmBox" : ""}
                                                                            className="btn btn-primary btn-primary2 mr-2">{this.props.t('jobProviderProfile.submit', { param: 'react' })}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12 bg-education mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('crm.recent', { param: 'react' })}</h6>
                                                    </div>
                                                    <div className="panel-body">
                                                        {ticket && Object.keys(ticket).length ? <div>
                                                            <div className="row">
                                                                <div className="col-md-9">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mb-1">
                                                                            <span className="pr-2 date-clr"><b>{this.props.t('crm.Issue Type', { param: 'react' })}</b> <br></br><span style={{ color: "#000" }}>{ticket.Issue_Type} </span></span>
                                                                        </div>
                                                                        <div className="col-md-4 mb-1">
                                                                            <span className="pr-2 date-clr"><b>{this.props.t('crm.Raised On', { param: 'react' })}</b> <br></br><span style={{ color: "#000" }}>{ticket.Submission_date} </span></span>
                                                                        </div>
                                                                        <div className="col-md-4 mb-1">
                                                                            <span className="pr-2 date-clr"><b>{this.props.t('crm.Time', { param: 'react' })} </b> <br></br><span style={{ color: "#000" }}>{this.getTimeInfo(ticket.created_on)} </span></span>
                                                                        </div>
                                                                        <div className="col-md-4 mb-1">
                                                                            <span className="pr-2 date-clr"><b>{this.props.t('crm.Ticket No', { param: 'react' })}</b> <br></br><span style={{ color: "#000" }}>{`PGRKAM-TKT-#${ticket.Ticket_id}`} </span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span className="pr-2 date-clr"><b>{this.props.t('crm.Status', { param: 'react' })}</b> <br></br><span style={{ color: "#000" }}>{ticket.status}</span></span>
                                                                </div>
                                                            </div>
                                                            <h6>{this.props.t('crm.Issue Description', { param: 'react' })}</h6>
                                                            <p className="p-2" style={{ border: "1px solid #d1cbcb" }}>{ticket.issue_desc}</p>
                                                            {ticket.response_desc ? <>
                                                                <hr></hr>
                                                                <h6>{this.props.t('crm.Ticket Response', { param: 'react' })}</h6>
                                                                <p className="p-2" style={{ border: "1px solid #d1cbcb" }}>{ticket.response_desc}</p>
                                                                <hr></hr>
                                                            </> : null}
                                                            <Link className="date-clr" onClick={() => this.setState({ ticket: [] })}
                                                                style={{ cursor: "pointer" }}><h6 className="ml-1">{this.props.t('jobSearch.goBack', { param: 'react' })}</h6></Link>
                                                        </div> :
                                                            <div className="table-responsive">
                                                                <table className="table table-hover table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                            <th>{this.props.t('crm.Issue Type', { param: 'react' })}</th>
                                                                            <th>{this.props.t('crm.Raised On', { param: 'react' })}</th>
                                                                            <th>{this.props.t('crm.Time', { param: 'react' })}</th>
                                                                            <th>{this.props.t('crm.Status', { param: 'react' })}</th>
                                                                            <th>{this.props.t('crm.Ticket No', { param: 'react' })}.</th>
                                                                        </tr>

                                                                    </thead>
                                                                    <tbody>
                                                                        {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((jobs, key) => {

                                                                            return (<tr key={key}>
                                                                                <td>{((activePage - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                <td>{jobs.Issue_Type ? jobs.Issue_Type : ''}</td>
                                                                                <td>{jobs.Submission_date ? jobs.Submission_date : ''}</td>
                                                                                <td>{jobs.created_on ? this.getTimeInfo(jobs.created_on) : ''}</td>
                                                                                <td>{jobs.status ? jobs.status : ''}</td>
                                                                                <td><span className="date-clr" onClick={() => this.setState({ ticket: jobs })} style={{ cursor: "pointer" }}>{`PGRKAM-TKT-#${jobs.Ticket_id}`}</span></td>
                                                                            </tr>)
                                                                        })}
                                                                        {Mainprops.JobseekerReducer.tickets && !Mainprops.JobseekerReducer.tickets.length &&
                                                                            <tr>
                                                                                <td colSpan="6" style={{ "textAlign": "center" }}>
                                                                                    <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5></td>
                                                                            </tr>}
                                                                    </tbody>
                                                                </table>
                                                                {searchResults && searchResults.length > 1 ?
                                                                    <div className='react-pagination'>
                                                                        <Pagination
                                                                            pageRangeDisplayed={10}
                                                                            activePage={activePage}
                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                            totalItemsCount={Mainprops.JobseekerReducer.tickets.length}
                                                                            onChange={this.handlePageChange}
                                                                        />
                                                                    </div> : ''}
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(Crm)));
