import moment from 'moment'


export const chunk = (array, size) => {
    if (array) {
        const chunked_arr = [];
        let index = 0;
        while (index < array.length) {
            let newArray = array.slice(index, size + index)
            chunked_arr.push(newArray);
            index += size;
        }
        return chunked_arr;
    }
}

export const getTimeWithAmPm = (time) => {
    if (time) {
        let newtime = time.split(":");
        let AmOrPm = newtime[0] >= 12 ? 'PM' : 'AM';
        let hours = (newtime[0] % 12) || 12
        return hours + ":" + newtime[1] + " " + AmOrPm
    }
    return ""
}

export const getTimeAgo = (date) => {
    if(date){
        let newDate = date.split("-")
        return "Posted "+moment([newDate[0], newDate[1], newDate[2]]).fromNow()
    }
}

export const capitalizeFirstLetter = (string) => {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}

export const getQualification = (job) => {
    const qualification = []
    if (job.qualification) {
        qualification.push(job.qualification)
    }
    if (job.qualification2) {
        qualification.push(job.qualification2)
    }
    if (job.qualification3) {
        qualification.push(job.qualification3)
    }
    return qualification.length ? qualification.toString() : job.education_level
}