import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import {
  RegisterJobSeeker, get_education, get_genders,
  getSeekerType, getLspServices, getDistance, getConstituency, get_skill_education
} from '../../actions/accountAction';
import store from '../../store';
import Validation from "../hoc/Validation";
import { getEducationLevel } from '../../actions/accountAction';
import Autocomplete from 'react-autocomplete';

const COUNSELLOR = '10'
const USERTYPE = '1'
const LSP = '8'

class Jobseekersignup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitStatus: false,
      gender: 2,
      user_subtype: window.location.search.split('=')[1],
      skill: window.location.search.split('=')[2],
      error: '',
      selectedCheckboxes: new Set(),
      rememberCheckboxes: new Set(),
      shareMobile: new Set(),
      capchaError: '',
      share_mobile: [],
      Education: [],
      educid: '',
      educkey: '',
    }
    // console.log('User Subtype===='+this.state.skill)
    this.capchaRef = React.createRef()
    if (this.state.skill) {

    }

    if (this.state.skill) {
      store.dispatch(get_skill_education());
      console.log('skills------' + this.state.skill)
    } else {
      store.dispatch(get_education());
      store.dispatch(getEducationLevel());
      console.log('getskills------' + this.state.skill)
    }
    store.dispatch(get_genders());
    if (this.state.user_subtype && this.state.user_subtype === USERTYPE) {
      store.dispatch(getSeekerType());
      store.dispatch(getConstituency())
    }

    if (this.state.user_subtype && this.state.user_subtype === LSP) {
      store.dispatch(getLspServices());
      store.dispatch(getDistance())
    }


    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    let text = ''
    if (this.state.user_subtype === '1') {
      text = 'forms.seekerSignup'
    } else if (this.state.user_subtype === '8') {
      text = 'forms.lspSignup'
    } else if (this.state.user_subtype === '10') {
      text = 'forms.counSignup'
    }
    document.title = this.props.t(text, { param: 'react' })
    if (this.props.locationData.for_coun_register === '1') {
      this.setState({ education_level: '7' })
    }
  }

  async handleUserInput(e) {
    const name = e.target.name;
    let value = e.target.value;
    let type = e.target.type;
    let key = e.target.key;

    if (type === 'text') {
      let regex = /^[A-Za-z0-9 ]+$/
      if (!regex.test(value) && value) {
        return false
      }
    }

    console.log(e.target.name);


    if (this.state.error && e.target.name === 'gender') {
      this.setState({ error: '' })
    }
    if (name === 'education_level') {
      this.setState({ educid: value });
      // this.setState({educkey:key});
      console.log(this.state.educkey);
    }

    if (name === 'remember') {
      let rememberCheckboxes = this.state.rememberCheckboxes
      if (rememberCheckboxes.has(value)) {
        rememberCheckboxes.delete(value);
      } else {
        rememberCheckboxes.add(value);
      }
      await this.setState({ rememberCheckboxes }, () => {
        value = Array.from(this.state.rememberCheckboxes)
      })
    }

    if (name === 'subtype_id') {
      let selectedCheckboxes = this.state.selectedCheckboxes
      if (selectedCheckboxes.has(value)) {
        selectedCheckboxes.delete(value);
      } else {
        selectedCheckboxes.add(value);
      }
      await this.setState({ selectedCheckboxes }, () => {
        value = Array.from(this.state.selectedCheckboxes)
      })
    }
    if (name === 'share_mobile') {
      let shareMobile = this.state.shareMobile
      if (shareMobile.has(value)) {
        shareMobile.delete(value);
      } else {
        shareMobile.add(value);
      }
      await this.setState({ shareMobile }, () => {
        value = Array.from(this.state.shareMobile)
      })
    }
    this.setState({ [name]: value, submitStatus: false });
  }

  handleSubmit(event) {
    event.preventDefault();
    const user = {
      email: this.state.email,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      mobile: this.state.mobile,
      aadhar: this.state.aadhar,
      district_id: this.state.district_id,
      password: this.state.password,
      other: this.state.other,
      education_level: this.state.education_level,
      qualification: this.state.Education.education_level_id,
      state_id: 3,
      gender: this.state.gender,
      user_subtype: this.state.user_subtype,
      communication: this.state.communication,
      subtype_id: this.state.subtype_id ? this.state.subtype_id : undefined,
      share_mobile: this.state.share_mobile.length ? this.state.share_mobile.toString() : undefined,
      constituency_id: this.state.constituency_id ? this.state.constituency_id : undefined
      // subtype_id: ["2", "4", "5", "11"]
    };

    if (!this.state.submitStatus && this.props.validator.allValid() && this.state.gender) {
      this.setState({ submitStatus: true });
      store.dispatch(RegisterJobSeeker(user));
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      this.props.validator.showMessages();
      this.forceUpdate();
      if (!this.state.gender) {
        this.setState({ error: 'Gender is required' })
      }
    }
  }




  setCourses = (data, val, key, valKey) => {
    console.log(val, key);
    const Education = this.state.Education
    const index = data.findIndex((d) => d.education_id === this.state.educid)
    console.log(index);
    if (index !== -1) {
      const MatchEdu = data[index].qualification.filter((edu) => edu.name === val)
      const value = MatchEdu && MatchEdu.length ? MatchEdu[0].id : ""
      console.log(value);
      Education[key] = val === 'Others' ? '152' : value
      Education[valKey] = val
      console.log(Education['education_level_id']);
      this.setState({ Education, submitted: false })
    }
  }



  createDistoption = () => {
    let Mainprops = this.props;
    let Distoption = []
    if (Mainprops.actionReducer.selectedDistricts.length > 0) {
      Mainprops.actionReducer.selectedDistricts.map(function (data) {
        if (data.state_name === 'Punjab') {
          data.dist.map(function (distdata, distindex) {
            Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
            return Distoption
          })
        }
        return Distoption
      })
    }
    return Distoption
  }

  render() {
    let courses = [];
    const { Education } = this.state
    let Mainprops = this.props;
    const { validator } = this.props
    const { error, user_subtype } = this.state
    const serviceList = this.props.actionReducer.Services
    let Mainthis = this;
    let Mainstate = this.state;
    let data = Mainprops.actionReducer.educationLevel
    // console.log(data);
    // console.log(Education.highest_education_level);
    if (this.state.educid) {
      const selectedEducation = data.filter((edu) => edu.education_id === this.state.educid)
      if (selectedEducation && selectedEducation[0]) {
        selectedEducation && selectedEducation[0] && selectedEducation[0].qualification.forEach((qual) => {
          courses.push({ label: qual.name, id: qual.id })
          console.log(courses);
        })
      }
    }
    courses.push({ label: "Others", id: "152" })

    if (user_subtype === COUNSELLOR) {
      const filterEdu = []
      data.forEach((edu, ind) => {
        if (ind >= 8) {
          filterEdu.push(edu)
        }
      });
      data = filterEdu
    } else if (this.state.skill === 1) {
      const filterEdu = []
      console.log(this.state.skill);
      data.forEach((edu, ind) => {
        if (ind >= 1) {
          filterEdu.push(edu)
        }
      });
      data = filterEdu
    }
    let constituency = []
    if (this.state.district_id) {
      constituency = this.props.actionReducer.constituency.filter((data) => data.district_id === this.state.district_id)
    }

    return (
      <div className="m-1">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-12 text-left">
                <label>{this.props.t('register_user.user_fname', { param: 'react' })}   <span style={{ color: 'red' }} className="ml-2">*</span></label>
                <input type="text" onChange={(event) => this.handleUserInput(event)}
                  placeholder={this.props.t('forms.enterFname', { param: 'react' })}
                  value={this.state.firstname ? this.state.firstname : ''}
                  className="form-control" id="firstname" name="firstname" />
                {validator.message('firstname', this.state.firstname, 'required')}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-sm-12 text-left">
                <label>{this.props.t('register_user.user_lname', { param: 'react' })}
                </label>
                <input type="text" placeholder={this.props.t('forms.enterLname', { param: 'react' })} onChange={(event) => this.handleUserInput(event)}
                  value={this.state.lastname ? this.state.lastname : ''}
                  className="form-control" id="lastname" name="lastname" />
              </div>

            </div>
          </div>

          <div className="form-group">
            <div className="col-sm-12 text-left">
              <div className="row">
                <label className={"col-md-12 p-0"}>{this.props.t('register_user.gender', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span> </label>
                {Mainprops.actionReducer.Genders.length > 0 &&
                  Mainprops.actionReducer.Genders.map(function (data, index) {
                    return (
                      <div className="form-check-inline" key={index}>
                        <label className="form-check-label" htmlFor="radio1">
                          <input type="radio" onChange={(event) => Mainthis.handleUserInput(event)}
                            className="form-check-input" id={data.name} name="gender" value={data.id}
                            checked={parseInt(Mainthis.state.gender) === parseInt(data.id)} />{data.name}
                        </label>
                      </div>
                    )
                  })
                }
                {error && <span style={{ "color": "red" }}>{error}</span>}
              </div>
            </div>
          </div>


          <div className="form-group">
            <div className="row">
              <div className="col-sm-12 text-left">
                <label>{this.props.t('register_user.education', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span></label>
                <select className="form-control" id="education_level"
                  disabled={this.props.locationData.for_coun_register === '1'}
                  onChange={(event) => this.handleUserInput(event)} name="education_level">
                  <option value="">{this.props.t('forms.selectEdu', { param: 'react' })}</option>
                  {data && data.length > 0 &&
                    data.map(function (data, index) {
                      // console.log(index);
                      return (
                        <option value={data.education_id} key={index}>{data.education_name}</option>
                      )
                    })
                  }
                </select>
                {validator.message('education level', this.state.education_level, 'required')}
              </div>
            </div>
          </div>
          
          <div className="form-group">
            <div className="row">
            <div className="col-sm-12 text-left">
            <label data-placement="bottom"
              title={this.props.t('profileDetails.educationDesc', { param: 'react' })}>
              {this.props.t('education.course_registration', { param: 'react' })}
              <span
                style={{ color: 'red' }}
                className="ml-2">*</span>
            </label>
          </div>
              <div className="col-sm-12 test" id="courseAuto">
                <Autocomplete
                  items={courses}
                  shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                  menuStyle={{
                    "zIndex": "9",
                    "border": "1px solid #ccc",
                    "overflow": "hidden",
                    "overflowY": "scroll",
                    "boxShadow": "1px 2px 10px #ccc",
                    "padding": "4px",
                    "maxHeight": "123px",
                    "fontSize": "14px",

                  }}
                  renderInput={(props) => {
                    return <input className="form-control" {...props} />
                  }}
                  wrapperStyle={{ "width": "100%" }}
                  renderItem={(item, isHighlighted) =>
                    <div key={item.id}
                      style={{ background: isHighlighted ? 'lightgray' : 'white', "borderBottom": "1px solid #ccc", }}>
                      {item.label}
                    </div>
                  }
                  value={Mainstate.Education.education_level_id_value ? Mainstate.Education.education_level_id_value : ''}
                  onChange={(e) => this.setCourses(data, e.target.value, 'education_level_id', 'education_level_id_value')}
                  getItemValue={(item) => item.label}
                  onSelect={(val) => this.setCourses(data, val, 'education_level_id', 'education_level_id_value')}
                />
                {/* 
                                            {eduValidator.message('education', 
                                                                this.state.Education.education_level_id_value, 'required')} */}

              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-sm-12 text-left">
                <label>{this.props.t('register_user.other', { param: 'react' })}</label>
                <input type="text" onChange={(event) => this.handleUserInput(event)} className="form-control"
                   name="other"
                />
                {/* {validator.message('email', this.state.email, 'email|required')} */}
              </div>
            </div>
          </div>


          <div className="form-group">
            <div className="row">
              <div className="col-sm-12 text-left">
                <label>{this.props.t('register_user.district', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span></label>
                <select className="form-control" id="district_id" onChange={(event) => this.handleUserInput(event)}
                  name="district_id">
                  <option value={""}>{this.props.t('profileDetails.selectDistrict', { param: 'react' })}</option>
                  {this.createDistoption()}
                </select>
                {validator.message('district', this.state.district_id, 'required')}
              </div>
            </div>
          </div>
          {user_subtype && user_subtype === USERTYPE ?
            <div className="form-group">
              <div className="row">
                <div className="col-sm-12 text-left">
                  <label>{this.props.t('register_user.constituency', { param: 'react' })}</label>
                  <select className="form-control" onChange={(event) => this.handleUserInput(event)}
                    name="constituency_id">
                    <option value={""}>{this.props.t('profileDetails.selectConstituency', { param: 'react' })}</option>
                    {constituency.length > 0 &&
                      constituency.map(function (data, index) {
                        return (
                          <option key={index}
                            value={data.id}>{data.name}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
            </div> : null}

          <div className="form-group">
            <div className="row">
              <div className="col-sm-12 text-left">
                <label>{this.props.t('register_user.email', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span></label>
                <input type="email" onChange={(event) => this.handleUserInput(event)} className="form-control"
                  placeholder={this.props.t('forms.egEmail', { param: 'react' })} name="email"
                />
                {validator.message('email', this.state.email, 'email|required')}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-sm-12 text-left">
                <label>{this.props.t('register_user.mobile', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span>
                </label>
                <input onChange={(event) => this.handleUserInput(event)} type="number"
                  className="form-control" placeholder={this.props.t('forms.10mobile', { param: 'react' })} name="mobile" id="mobile"  />
                {validator.message('mobile', this.state.mobile, 'required|phone')}
                {/* {this.state.mobile > 10 ? 'Enter a number less than 10' : ''} */}
              </div></div>
          </div>
          {/* <div className="form-group">
            <div className="row">
              <div className="col-sm-12 text-left">
                <label>{this.props.t('register_user.aadhar', { param: 'react' })}
                </label>
                <input onChange={(event) => this.handleUserInput(event)  } type="text"
                  className="form-control" placeholder={this.props.t('forms.12aadhar', { param: 'react' })} name="aadhar" id="aadhar" pattern="\d*" maxLength="12"/>
               
              </div></div>
          </div> */}

          {user_subtype === LSP ?
            <>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-12 text-left">
                    <label>{this.props.t('register_user.selectService', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span></label>
                    <select className="form-control"
                      onChange={(event) => this.handleUserInput(event)} name="service_id">
                      <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('lsp_user.service_name', { param: 'react' })}</option>
                      {serviceList && serviceList.length > 0 &&
                        serviceList.map(function (data, index) {
                          return (
                            <option key={index} value={data.category_id}>{data.category_name}</option>
                          )
                        })
                      }
                    </select>
                    {validator.message('service name', this.state.service_id, 'required')}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-12 text-left">
                    <label>{this.props.t('register_user.area_of_operation', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span></label>
                    <select
                      className="form-control"
                      name="nearBy"
                      onChange={(e) => this.handleUserInput(e)}>
                      <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('register_user.area_of_operation', { param: 'react' })}</option>
                      {this.props.actionReducer.distance && this.props.actionReducer.distance.length ?
                        this.props.actionReducer.distance.map((dist, key) => {
                          return <option key={key} value={dist.id}>{dist.name}</option>
                        })

                        : null}
                    </select>
                    {validator.message('area of operation', this.state.nearBy, 'required')}
                  </div>
                </div>
              </div>
            </>
            : null}

          {user_subtype !== LSP ?
            <div className="form-group">
              <div className="col-sm-12 text-left">
                <div className="row">

                  <label className={"col-md-12 p-0"}>{this.props.t('register_user.communication', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span> </label>
                  <div className="form-check-inline">
                    <label className="form-check-label" htmlFor="radio1">
                      <input type="radio" onChange={(event) => Mainthis.handleUserInput(event)}
                        className="form-check-input" name="communication" value={"1"}
                        checked={Mainthis.state.communication === "1"} />{this.props.t('profileDetails.yes', { param: 'react' })}
                    </label>
                  </div>
                  <div className="form-check-inline">
                    <label className="form-check-label" htmlFor="radio1">
                      <input type="radio" onChange={(event) => Mainthis.handleUserInput(event)}
                        className="form-check-input" name="communication" value={"0"}
                        checked={Mainthis.state.communication === "0"} />{this.props.t('profileDetails.no', { param: 'react' })}
                    </label>
                  </div>

                </div>

              </div>
              {validator.message('communication', this.state.communication, 'required')}
            </div> : null}
          {user_subtype === LSP ? <div className="form-group">
            <div className="row m-0">
              <div className="col-sm-12 ml-2">
                <input className="form-check-input" type="checkbox" name="share_mobile" value="1" onClick={(e) => this.handleUserInput(e)} />
                {this.props.t('register_user.share_mobile', { param: 'react' })}
              </div>
            </div>
          </div> : null}
          {user_subtype && user_subtype === USERTYPE && this.props.actionReducer.seekerType.length &&
            <div className="form-group">
              <div className="row">
                <div className="col-sm-12 text-left">
                  <label className={"col-md-12 p-0"}>{this.props.t('register_user.selectType', { param: 'react' })}
                  </label>
                  <div className={"d-inline"}>
                    {this.props.actionReducer.seekerType && this.props.actionReducer.seekerType.length ?
                      this.props.actionReducer.seekerType.map((types, key) => {
                        return (
                          <div className="form-check" key={key}>
                            <label className="form-check-label" htmlFor="check1">
                              <input onClick={(event) => this.handleUserInput(event)}
                                type="checkbox"
                                className="form-check-input"
                                name="subtype_id"
                                value={types.id} />{types.name}
                            </label>
                          </div>
                        )
                      }) : ''
                    }

                    {/* {validator.message('type', this.state.subtype_id, 'required')} */}
                  </div>
                </div>

              </div>
            </div>}
          <div className="form-group">
            <div className="row m-0">
              <div className="col-sm-12 ml-2">
                <input className="form-check-input" type="checkbox" name="remember" onClick={(e) => this.handleUserInput(e)} />

                {this.props.t('register_user.terms', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span>

                <div className="valid-feedback">Valid.</div>
                <label className="invalid-feedback">Check this checkbox to continue.</label>
              </div>
              <div className="row m-0">
                {validator.message('terms and conditions', this.state.remember, 'required')}
              </div>

              <div className="row m-0 w-100">
                <button type="submit" className="btn btn-primary btn-primary2  mt-3" >{this.props.t('profileDetails.submit', { param: 'react' })}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return store;
};

export default connect(mapStateToProps)(translate(Validation(Jobseekersignup)));
