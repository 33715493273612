import { SET_FORGIEN_POST_STATUS, SET_FORGIEN_STUDY_PROVIDER_PROFILE,
  SET_FORGIEN_RECENT_STUDY, SET_FORGIEN_SCHEDULED_STUDY, 
  SET_FORGIEN_STUDY_ARCHIVE, SET_APPLIED_FORGIEN_STUDY, SET_STATUS_FOR_FORGIEN_STUDY,
  FORGIEN_JOB_UPDATE, SET_SELECTED_FORGIEN_STUDY_APPLICANTS, SET_SEEKER_FOR_FORGIEN_STUDY,
  SET_SHORLISTED_FORGIEN_STUDY_APPLICANTS,SET_GENERATE_EXCEL, SET_STUDY_AREAS, SET_MONTHS } from '../actions/actionTypes';

const initialState = {
    Profile: [],
    jobPosted: false,
    recentStudy: [],
    scheduledStudy:[],
    studyArchive:[],
    appliedStudy: [],
    applicants:[],
    jobUpdated:false,
    searchResults:[],
    jobApplied:false,
    shorListedApplicants:[],
    generateExcel:false,
    areasOfStudy:[], 
    months:[]
};
const setMonths = (state, action) => {
  return { ...state, months: action.data };
}
const setGenerateExel = (state, action) => {
  return { ...state, generateExcel: action.status };
}
const setProfile = (state, action) => {
  return { ...state, Profile: action.data };
}
const setPostJobStatus = (state, action) => {
  return { ...state, jobPosted: action.status };
};

const setRecentStudy = (state, action) => {
  return { ...state, recentStudy: action.data };
}

const setScheduledStudy = (state, action) => {
  return { ...state, scheduledStudy: action.data };
}

const setPostArchive = (state, action) => {
  return { ...state, studyArchive: action.data };
}

const setAppliedStudy = (state, action) => {
  return { ...state, appliedStudy: action.data };
}

const setApplicants = (state, action) => {
  return { ...state, applicants: action.data };
}

const updateJob = (state, action) => {
  return { ...state, jobUpdated: action.status };
}

const setSearchResults = (state, action) => {
  return { ...state, searchResults: action.data };
}

const setStatus = (state, action) => {
  return { ...state, jobApplied: action.status };
}
const setShorListedApplicants = (state, action) => {
  return { ...state, shorListedApplicants: action.data };
}

const setAreasOfStudy = (state, action) => {
  return { ...state, areasOfStudy: action.data };
}



const forgienstudyproviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MONTHS: return setMonths(state, action);
    case SET_STUDY_AREAS: return setAreasOfStudy(state, action);
    case SET_FORGIEN_STUDY_PROVIDER_PROFILE: return setProfile(state, action);
    case SET_FORGIEN_POST_STATUS: return setPostJobStatus(state, action);
    case SET_FORGIEN_RECENT_STUDY: return setRecentStudy(state, action);
    case SET_FORGIEN_SCHEDULED_STUDY: return setScheduledStudy(state, action);
    case SET_FORGIEN_STUDY_ARCHIVE: return setPostArchive(state, action);
    case SET_APPLIED_FORGIEN_STUDY: return setAppliedStudy(state, action);
    case SET_SELECTED_FORGIEN_STUDY_APPLICANTS: return setApplicants(state, action);
    case FORGIEN_JOB_UPDATE: return updateJob(state, action)
    case SET_SEEKER_FOR_FORGIEN_STUDY: return setSearchResults(state, action)
    case SET_STATUS_FOR_FORGIEN_STUDY: return setStatus(state, action)
    case SET_SHORLISTED_FORGIEN_STUDY_APPLICANTS: return setShorListedApplicants(state, action)
    case SET_GENERATE_EXCEL: return setGenerateExel(state, action)
    
    
    default: return state;
  }
};
export default forgienstudyproviderReducer;




