import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { browserHistory } from 'react-router'

class Sitemap extends Component {
    
    componentDidMount(){
        document.title = this.props.t('homeContent.employment', { param: 'react' })
    }

    render() {
        return (
            <div className="tab-content" style={{ "border": "none" }}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">Sitemap
                                        <span className="pull-right" onClick={browserHistory.goBack} style={{color:"#fff", cursor:"pointer"}}>{this.props.t('jobSearch.goBack', { param: 'react' })}</span></h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <div className="row">
                                       
                                            {/* <div className="col-md-6">
                                            <img  width="100%" src="./images/helpline-nos.jpeg" 
                                            alt="Employment"/>
                                            </div> */}
                                            <div className="col-md-6" style={{'marginLeft':'4%','fontSize':'18px'}}>
                                            {/* <h4>SITEMAP </h4><hr></hr> */}
                                            <ul className="m-0">
                                                
                                                    <li style={{'color':'blue'}}><h4><a href="http://www.pgrkam.com/about" style={{'color':'blue'}}><u>About</u></a></h4></li>
                                                    <li style={{'color':'blue'}}><h4><a href="http://www.pgrkam.com/services" style={{'color':'blue'}}><u>Services</u></a></h4></li>
                                                    <li style={{'color':'blue'}}><h4><a href="http://www.pgrkam.com/contact-us" style={{'color':'blue'}}><u>Contact Us</u></a></h4></li>
                                                    {/* </ul> */}
                                                    <li style={{'color':'blue'}}><h4><a href="#" style={{'color':'blue'}}><u>Home</u></a></h4>
                                                        <ul >
                                                        <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/signup" style={{'color':'blue'}}><u>Registration</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/signin" style={{'color':'blue'}}><u>Login</u></a></p></li>
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/user-manual" style={{'color':'blue'}}><u>User Manual</u></a></p></li> */}
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/signin-with-otp" style={{'color':'blue'}}><u>Login Using OTP</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/resetpassword" style={{'color':'blue'}}><u>Forgot Password</u></a></p></li>
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/private-jobs" style={{'color':'blue'}}><u>Search Private Jobs</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/govt-jobs" style={{'color':'blue'}}><u>Search Government Jobs </u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/search-results/?job_type=1&gender=1" style={{'color':'blue'}}><u>Search Private Jobs for women</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/search-results/?job_type=2&gender=1" style={{'color':'blue'}}><u>Search Government Jobs for women</u></a></p></li> */}
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://afpipunjab.org/" style={{'color':'blue'}}><u>Induction into Armed Forces(Boys)</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://mbafpigirls.in/" style={{'color':'blue'}}><u>Induction into Armed Forces(Girls)</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://psdm.gov.in/" style={{'color':'blue'}}><u>Skill Training</u></a></p></li>
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/career-opportunities" style={{'color':'blue'}}><u>Career Opportunities</u></a></p></li> */}
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/counselling-sessions" style={{'color':'blue'}}><u>Search Counseling sessions</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/self-employment" style={{'color':'blue'}}><u>Self Employment</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/local-services" style={{'color':'blue'}}><u>Local Services</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/gallery" style={{'color':'blue'}}><u>Photo Gallery</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/videos" style={{'color':'blue'}}><u>Video Gallery</u></a></p></li>
                                                        </ul>

                                                        </li>

                                                    <li  style={{'color':'blue'}}><h4><a href="#" style={{'color':'blue'}}><u>Jobseekers</u></a></h4>
                                                        <ul >
                                                        <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/signup?type=1" style={{'color':'blue'}}><u>Registration</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/docs/Jobseeker_manual.pdf" style={{'color':'blue'}}><u>User Manual</u></a></p></li>
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/signin-with-otp" style={{'color':'blue'}}><u>Login Using OTP</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/resetpassword" style={{'color':'blue'}}><u>Forgot Password</u></a></p></li> */}
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/private-jobs" style={{'color':'blue'}}><u>Search Private Jobs</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/govt-jobs" style={{'color':'blue'}}><u>Search Government Jobs </u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/search-results/?job_type=1&gender=1" style={{'color':'blue'}}><u>Search Private Jobs for women</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/search-results/?job_type=2&gender=1" style={{'color':'blue'}}><u>Search Government Jobs for women</u></a></p></li>
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://afpipunjab.org/" style={{'color':'blue'}}><u>Induction into Armed Forces(Boys)</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://mbafpigirls.in/" style={{'color':'blue'}}><u>Induction into Armed Forces(Girls)</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://psdm.gov.in/" style={{'color':'blue'}}><u>Skill Training</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/career-opportunities" style={{'color':'blue'}}><u>Career Opportunities</u></a></p></li> */}
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/counselling-sessions" style={{'color':'blue'}}><u>Search Counseling sessions</u></a></p></li>
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/self-employment" style={{'color':'blue'}}><u>Self Employment</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/local-services" style={{'color':'blue'}}><u>Local Services</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/gallery" style={{'color':'blue'}}><u>Photo Gallery</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/videos" style={{'color':'blue'}}><u>Video Gallery</u></a></p></li> */}
                                                        </ul>
                                                    </li>
                                                    <li  style={{'color':'blue'}}><h4><a href="#" style={{'color':'blue'}}><u>Employers</u></a></h4>
                                                        <ul >
                                                        <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/signup?type=6" style={{'color':'blue'}}><u>Registration</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/docs/employer_manual.pdf" style={{'color':'blue'}}><u>User Manual</u></a></p></li>
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/signin-with-otp" style={{'color':'blue'}}><u>Login Using OTP</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/resetpassword" style={{'color':'blue'}}><u>Forgot Password</u></a></p></li> */}
                                                           
                                                        </ul>
                                                    </li>
                                                    <li  style={{'color':'blue'}}><h4><a href="#" style={{'color':'blue'}}><u>Counselor</u></a></h4>
                                                        <ul >
                                                        <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/signup?type=10" style={{'color':'blue'}}><u>Registration</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/docs/counsellor.pdf" style={{'color':'blue'}}><u>User Manual</u></a></p></li>
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/signin-with-otp" style={{'color':'blue'}}><u>Login Using OTP</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/resetpassword" style={{'color':'blue'}}><u>Forgot Password</u></a></p></li> */}
                                                           
                                                        </ul>
                                                    </li>
                                                    <li  style={{'color':'blue'}}><h4><a href="#" style={{'color':'blue'}}><u>Local Service Provider</u></a></h4>
                                                        <ul >
                                                        <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/signup?type=8" style={{'color':'blue'}}><u>Registration</u></a></p></li>
                                                            <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://pgrkam.com/docs/Local-service-provider.pdf" style={{'color':'blue'}}><u>User Manual</u></a></p></li>
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/signin-with-otp" style={{'color':'blue'}}><u>Login Using OTP</u></a></p></li> */}
                                                            {/* <li style={{'color':'blue','marginLeft':'6%'}}><p><a href="http://www.pgrkam.com/resetpassword" style={{'color':'blue'}}><u>Forgot Password</u></a></p></li> */}
                                                           
                                                        </ul>
                                                    </li>
                                                    <li style={{'color':'blue'}}><h4><a href="http://www.pgrkam.com/faq" style={{'color':'blue'}}><u>FAQs</u></a></h4></li>
                                                    <li style={{'color':'blue'}}><h4><a href="http://pgrkam.com/privacy-policy" style={{'color':'blue'}}><u>Privacy Policy</u></a></h4></li>
                                                    <li style={{'color':'blue'}}><h4><a href="http://pgrkam.com/copyright-policy" style={{'color':'blue'}}><u>Copyright Policy</u></a></h4></li>
                                                    <li style={{'color':'blue'}}><h4><a href="http://pgrkam.com/help" style={{'color':'blue'}}><u>Help</u></a></h4></li>
                                                    </ul>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Sitemap));
