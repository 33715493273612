import React, { Component } from 'react'
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { getAreaOfInterest } from '../../../actions/counsellingproviderAction';
import {  postSkilldevelopmentSearch, getSkilldevelopmentCourses,getsectorlist,getdistrictlist,getskillcourselist } from '../../../actions/jobseekerAction';
import ServerSetting from '../../../config.json';
import {applySkillcourse} from '../../../actions/accountAction';
import Pagination from "react-js-pagination";
import { chunk } from '../../../helpers'
import { getTimeWithAmPm } from '../../../helpers'
import { Link } from 'react-router'
import { translate } from 'react-multi-lang'


const RESULT_PER_PAGE = 25

class SearchSkilldevCourses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: [],

            activePage: 1,
            submitted: false,
            disabledButton:[],
            hide:"",
            color:"",

        }


        store.dispatch(getAreaOfInterest())
        store.dispatch(applySkillcourse())
        store.dispatch(getSkilldevelopmentCourses())
        store.dispatch(getsectorlist())
        store.dispatch(getskillcourselist())
        store.dispatch(getdistrictlist())

        if (localStorage.getItem('Islogin') === 'true' && localStorage.getItem('course_id')) {
          
            const course=  localStorage.getItem('course_id');
            const startdate= localStorage.getItem('course_startdate');
            const enddate= localStorage.getItem('course_enddate');
            const user_id=localStorage.getItem('user_id');
            const value={user_id:user_id,course_id:course,status:1,start_date:startdate,end_date:enddate};
          
            store.dispatch(applySkillcourse(value))
            if(course) {
            this.setState({
                isDisabled: true 
            })
        }
        localStorage.removeItem('course_id');
        localStorage.removeItem('course_startdate');
        localStorage.removeItem('course_enddate');
           
        }
  

    }

    submit = (e) => {
        e.preventDefault()
        if (!this.state.submitted) {
            this.setState({ activePage: 1 })
            
            store.dispatch( postSkilldevelopmentSearch(this.state.formData, true));
                this.setState({hide:"none"})
        
            this.setState({ submitted: true })
        }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    componentDidMount() {
        document.title = this.props.t('counsellingProvider.searchCounsellor', { param: 'react' })
     

       
    }



    handleInput = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const user_id ="user_id";
     
        let formData = this.state.formData;
        formData[name] = value;
        store.dispatch( getskillcourselist(this.state.formData));
        formData[user_id]=localStorage.getItem('user_id')
      
        if (!value) {
            delete formData[name]
        }
        await this.setState({ formData, submitted: false });
    }

    
    applyskillcourse(e,index,start,end){
    
        var arr = this.state.disabledButton.slice();
        arr.push(index);

        this.setState({
            
              disabledButton: arr
            })
            console.log(this.state.disabledButton[index])
        const course=e.target.value;
        const startdate=start
        const enddate=end
        const user_id=this.props.JobseekerReducer.Profile.user_id;
        const value={user_id:user_id,course_id:course,status:1,start_date:startdate,end_date:enddate};
        store.dispatch(applySkillcourse(value))
        if(course) {
        this.setState({
            isDisabled: true 
        })
    }
      
    }



    componentWillUnmount() {
    
    
    }

    getAreas = list => {
        const areaList = []
        list.split(',').forEach((ar) => {
            const filterList = this.props.counsellingproviderReducer.area_of_interest.filter((res) => res.id === ar)
            if (filterList && filterList[0]) {
                areaList.push(filterList[0].name)
            }
        })
        return areaList.join(',')
    }



    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        
        const Mainprops = this.props
        const { activePage, formData } = this.state
        const searchResults = chunk(Mainprops.JobseekerReducer.skilldevelopmentResult, RESULT_PER_PAGE)
        const searchallResults = chunk(Mainprops.JobseekerReducer.skilldevelopmentallResult, RESULT_PER_PAGE)
   
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>


                            <div>
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-3">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('skilldevelopment.searchskillcourses', { param: 'react' })}<span className='float-right p-0 mt-0'> <a  href="/applied-courses-link" style={{'color': "#fff"}}>Applied Courses</a></span></h6>
                                                        </div>
                                                        <div className="panel-body mt-2">
                                                            <form onSubmit={this.submit}>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                       

                                                                        <div className="col-sm-8 col-md-4  col-12" style={{"padding": "1px 33px 3px 28px"}}>

                                                                 <label >Sector</label> <br/>
                                                                 <select onChange={this.handleInput}
                                                                                value={formData.sector_id ? formData.sector_id : ''}
                                                                                className="form-control" name="sector_id">
                                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' })}</option>
                                                                                {this.props.JobseekerReducer.sectorresultlist.length > 0 &&
                                                                                    this.props.JobseekerReducer.sectorresultlist.map(function (data, key) {
                                                                                        return (
                                                                                            <option value={data.sector_id} key={key}>{data.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>

                                                                        <div className="col-sm-8 col-md-4  col-12" style={{"padding": "1px 33px 3px 28px"}}>
{/*                                                                     
                                                                            <input type="text" className="form-control"
                                                                 onChange={this.handleInput}
                                                                
                                                                   id="org_name" name="keyword"
                                                                   placeholder="Search"
                                                            /> */}
                                                                <label>Job Role/Trade</label><br/>
                                                                 <select onChange={this.handleInput}
                                                                                value={formData.course_id ? formData.course_id : ''}
                                                                                className="form-control" name="course_id">
                                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' })}</option>
                                                                                {this.props.JobseekerReducer.courseresultlist.length > 0 &&
                                                                                    this.props.JobseekerReducer.courseresultlist.map(function (data, key) {
                                                                                        return (
                                                                                            <option value={data.course_id} key={key}>{data.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                      
                                                                        <div className="col-sm-8 col-md-4  col-12" style={{"padding": "1px 33px 3px 28px"}}>

                                                                <label>District</label><br/>
                                                                 <select onChange={this.handleInput}
                                                                                value={formData.district_id ? formData.district_id : ''}
                                                                                className="form-control" name="district_id">
                                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' })}</option>
                                                                                {this.props.JobseekerReducer.displaydistrict.length > 0 &&
                                                                                    this.props.JobseekerReducer.displaydistrict.map(function (data, key) {
                                                                                        return (
                                                                                            <option value={data.id} key={key}>{data.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 ">
                                                                            <button type="submit"
                                                                                className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                                {this.props.t('jobSearch.search', { param: 'react' })}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                {Mainprops.JobseekerReducer.skilldevelopmentResult.length > 0 &&
                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}
                                                                    <span className="pull-right" style={{ "color": "#fff" }}>
                                                                        {Mainprops.JobseekerReducer.skilldevelopmentResult && Mainprops.JobseekerReducer.skilldevelopmentResult.length ?
                                                                            Mainprops.JobseekerReducer.skilldevelopmentResult.length : 0}{this.props.t('JobPost.foundItems', { param: 'react' })}</span></h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                {searchResults && searchResults.length && searchResults[activePage - 1].map((skill,index,key) => {
                                                                    return (
                                                                      
                                                                        <div className="right-sidebar" key={key}>
                                                                            <div className="first-job mb-3 p-3">
                                                                          {skill.applied == -1 ? <button  className='float-right mx-auto d-block btn btn-primary btn-primary2 mt-1' value={skill.id} onClick={e => this.applyskillcourse(e,index,skill.start_date,skill.end_date)}   disabled={this.state.disabledButton[index] === index ? true :false}>{this.state.disabledButton[index]== index ?"Applied":"Apply"}</button>:<div><h4 className='float-right mx-auto d-block mt-1'>{skill.applied}</h4></div>}
                                                                              
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h4 className="company-name">
                                                                                            
                                                                                            <div className="outr-avtar">
                                                                                                <img alt="avatar"
                                                                                                    src={skill.tp_logo ? `${SERVER_PATH}${skill.tp_logo}` : '/images/img-1.png'}
                                                                                                    className="avatar mr-2 text-center" />
                                                                                            </div> 
                                                                                        </h4>
                                                                                        <ul className="service-li" style={{ marginLeft: "18%" }}>

                                                                                                                                                                                    
                                                                                            {skill.sector ?  
                                                                                                <li className="pr-5" style={{'fontSize':'18px'}}>  <b>SECTOR</b>: {skill.sector }</li>: null }

                                                                                                {skill.course ?  
                                                                                                <li className="pr-5">  <b>JOB ROLE/TRADE</b>: {skill.course }</li>: null }
                                                                                            {/* {skill.skill_name ?  
                                                                                            <li className="pr-5">  <b>Batch Name</b>: {skill.skill_name }</li>: null } */}
                                                                                                <hr/>
                                                                                                <li className='pr-5' style={{'fontSize':'12px'}}>
                                                                                                    <b >Training Partner</b>: {skill.tp_name} 
                                                                                                {/* <b >Training Center</b>: {skill.tc_name}  */}
                                                                                                <b style={{'marginLeft':'12%'}}>District</b>: {skill.district}</li>
                                                                                                
                                                                                            {skill.education ?
                                                                                                <li className="pr-5" style={{'fontSize':'12px'}}>
                                                                                                    {/* <b style={{color:"#ed9017"}}>Qualification Required</b> <br/> */}
                                                                                                    <b >Qualification</b>: {skill.education }
                                                                                                    {/* <b>Batch Name</b>: {skill.skill_name} */}
                                                                                                    <b style={{'marginLeft':'6%'}}> Duration (hr):  </b> {skill.duration} 
                                                                                                    <b style={{'margin-left':'6%'}}>Batch Strength:  </b> {skill.strength} Candidates
                                                                                                </li> : null} 
                                                                                            {/* {skill.skill_name ?
                                                                                                    <li className="pr-5">
                                                                                                        <b>Batch Name</b>: {skill.skill_name}
                                                                                                    </li> : null}
                                                                                            <li className="pr-5">
                                                                                                <b > Duration (hr):  </b> {skill.duration} 
                                                                                                <b style={{'margin-left':'40%'}}>Batch Strength:  </b> {skill.strength} Candidates
                                                                                            </li>  */}

                                                                                            
                                                                                                

                                                                                            {/* {skill.tp_name ?
                                                                                                <li className="pr-5">
                                                                                                    <b >Training Partner</b>: {skill.tp_name}
                                                                                                    <b >Training Center</b>: {skill.tc_name}
                                                                                                </li> : null} */}
                                                                                            {/* {skill.tc_name ?
                                                                                                <li className="pr-5">
                                                                                                    <b >Training Center</b>: {skill.tc_name}
                                                                                                </li> : null} */}
                                                                                            
                                                                                            
                                                                                            </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {searchResults && searchResults.length > 1 ? <div className='react-pagination mt-2'>
                                                                    <Pagination
                                                                        pageRangeDisplayed={10}
                                                                        activePage={activePage}
                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                        totalItemsCount={Mainprops.JobseekerReducer.skilldevelopmentResult.length}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}

                                    {searchResults.length == 0?
                                    <div className="tab-content" style={{'display':this.state.hide}}>
                                        <div id="home" className=" tab-pane active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}
                                                                    <span className="pull-right" style={{ "color": "#fff" }}>
                                                                        {Mainprops.JobseekerReducer.skilldevelopmentallResult && Mainprops.JobseekerReducer.skilldevelopmentallResult.length ?
                                                                            Mainprops.JobseekerReducer.skilldevelopmentallResult.length : 0}{this.props.t('JobPost.foundItems', { param: 'react' })}</span></h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                {searchallResults && searchallResults.length && searchallResults[activePage - 1].map((skill,index,key) => {
                                                                    return (
                                                                      
                                                                        <div className="right-sidebar" key={key}>
                                                                            <div className="first-job mb-3 p-3">
                                                                            {skill.applied === -1 ? <button  className='float-right mx-auto d-block btn btn-primary btn-primary2 mt-1' value={skill.id} onClick={e => this.applyskillcourse(e,index,skill.start_date,skill.end_date)}   disabled={this.state.disabledButton[index] === index ? true :false}  >{this.state.disabledButton[index]== index ?"Applied":"Apply"}</button>:<div><h4 className='float-right mx-auto d-block mt-1'>{skill.applied}</h4></div>}

                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h4 className="company-name">
                                                                                         
                                                                                            <div className="outr-avtar">
                                                                                                <img alt="avatar"
                                                                                                    src={skill.tp_logo ? `${SERVER_PATH}${skill.tp_logo}` : '/images/img-1.png'}
                                                                                                    className="avatar mr-2 text-center" />
                                                                                            </div> 
                                                                                        </h4>
                                                                                        <ul className="service-li" style={{ marginLeft: "18%" }}>

                                                                                                                                                                                
                                                                                        {skill.sector ?  
                                                                                            <li className="pr-5" style={{'fontSize':'18px'}}>  <b>SECTOR</b>: {skill.sector }</li>: null }

                                                                                            {skill.course ?  
                                                                                            <li className="pr-5">  <b>JOB ROLE/TRADE</b>: {skill.course }</li>: null }
                                                                                            {/* {skill.skill_name ?  
                                                                                        <li className="pr-5">  <b>Batch Name</b>: {skill.skill_name }</li>: null } */}
                                                                                            <hr/>
                                                                                            <li className='pr-5' style={{'fontSize':'12px'}}>
                                                                                                <b >Training Partner</b>: {skill.tp_name} 
                                                                                            {/* <b >Training Center</b>: {skill.tc_name}  */}
                                                                                            <b style={{'marginLeft':'12%'}}>District</b>: {skill.district}</li>
                                                                                            
                                                                                        {skill.education ?
                                                                                            <li className="pr-5" style={{'fontSize':'12px'}}>
                                                                                                {/* <b style={{color:"#ed9017"}}>Qualification Required</b> <br/> */}
                                                                                                <b >Qualification</b>: {skill.education }
                                                                                                {/* <b>Batch Name</b>: {skill.skill_name} */}
                                                                                                <b style={{'marginLeft':'6%'}}> Duration (hr):  </b> {skill.duration} 
                                                                                                <b style={{'margin-left':'6%'}}>Batch Strength:  </b> {skill.strength} Candidates
                                                                                            </li> : null} 
                                                                                        {/* {skill.skill_name ?
                                                                                                <li className="pr-5">
                                                                                                    <b>Batch Name</b>: {skill.skill_name}
                                                                                                </li> : null}
                                                                                        <li className="pr-5">
                                                                                            <b > Duration (hr):  </b> {skill.duration} 
                                                                                            <b style={{'margin-left':'40%'}}>Batch Strength:  </b> {skill.strength} Candidates
                                                                                        </li>  */}

                                                                                        
                                                                                            

                                                                                        {/* {skill.tp_name ?
                                                                                            <li className="pr-5">
                                                                                                <b >Training Partner</b>: {skill.tp_name}
                                                                                                <b >Training Center</b>: {skill.tc_name}
                                                                                            </li> : null} */}
                                                                                        {/* {skill.tc_name ?
                                                                                            <li className="pr-5">
                                                                                                <b >Training Center</b>: {skill.tc_name}
                                                                                            </li> : null} */}
                                                                                        
                                                                                        
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {searchallResults && searchallResults.length > 1 ? <div className='react-pagination mt-2'>
                                                                    <Pagination
                                                                        pageRangeDisplayed={10}
                                                                        activePage={activePage}
                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                        totalItemsCount={Mainprops.JobseekerReducer.skilldevelopmentallResult.length}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>:""}
                                 
                                {Mainprops.JobseekerReducer.skilldevelopmentResult.status === '401' && <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}
                                                                <span className="pull-right" style={{ "color": "#fff" }}>
                                                                    {Mainprops.JobseekerReducer.skilldevelopmentResult && Mainprops.JobseekerReducer.skilldevelopmentResult.length ?
                                                                        Mainprops.JobseekerReducer.skilldevelopmentResult.length : 0}{this.props.t('JobPost.foundItems', { param: 'react' })}</span></h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            <h3>{Mainprops.JobseekerReducer.skilldevelopmentResult.message}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>}

                            </div>

                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(SearchSkilldevCourses));