import { SET_PROVIDER_COUNSELLING_TYPES, SET_PROVIDER_COUNSELLING_MODES,
 SET_COUNSELLING_PROVIDER_PROFILE, SET_POST_COUNSELLING_STATUS, 
 SET_SCHEDULED_SESSION, SET_UPCOMING_SESSION, SET_ARCHIEVE_SESSION,
 SET_APPLIED_SESSION, SET_SESSION_APPLICANTS, SESSION_UPDATED, 
 SET_APPLIED_SESSION_BY_SEEKER, SET_SESSION_DATA, SET_RECOMMENDED_COUNSELLING,
 SET_FEEDBACK_LIST, SET_COUNSELOR_EDUCATION, SET_AREA_OF_INTEREST,INDIVIDUAL_REQUEST,INDIVIDUALS_REQUEST} from '../actions/actionTypes';

const initialState = {
    Profile: [],
    counsellingType: [],
    modes: [],
    postCounsellingStatus: false,
    scheduledSession: [],
    upcomingSession: [],
    archieveSession:[],
    appliedSessions:[],
    applicants:[],
    sessionUpdated: false,
    sessionsAppliedBySeeker:[],
    sessionData:[],
    recommendedSession:[],
    feedbackList:[],
    educations:[],
    area_of_interest:[],
    individual_request:"",
    individuals_request:"",
};

const setAreaOfInterest = (state, action) => {
  return { ...state, area_of_interest: action.data };
};

const setUserEducation = (state, action) => {
  return { ...state, educations: action.data };
};
const setSessionData = (state, action) => {
  return { ...state, sessionData: action.data };
};
const setProfile = (state, action) => {
  return { ...state, Profile: action.data };
};
const setCounsellingModes = (state, action) => {
  return { ...state, modes: action.data };
};
const setCounsellingType = (state, action) => {
  return { ...state, counsellingType: action.data };
};
const setIndividualRequest=(state,action) =>{
  return {...state,individual_request: action.data};
};
const setIndividualsRequest=(state,action) =>{
  return {...state,individuals_request: action.data};
};
const setPostCounsellingStatus = (state, action) => {
  return { ...state, postCounsellingStatus: action.status };
};
const setScheduledSession = (state, action) => {
  return { ...state, scheduledSession: action.data };
};
const setUpcomingSession = (state, action) => {
  return { ...state, upcomingSession: action.data };
};
const setArchieveSession = (state, action) => {
  return { ...state, archieveSession: action.data };
};
const setAppliedSessions = (state, action) => {
  return { ...state, appliedSessions: action.data}
}
const setSessionApplicants = (state, action) => {
  return { ...state, applicants: action.data}
}

const updateSession = (state, action) => {
  return { ...state, sessionUpdated: action.status}
}
const setSessionsAppliedBySeeker = (state, action) => {
  return { ...state, sessionsAppliedBySeeker: action.data}
}

const setRecommendedSession = (state, action) => {
  return { ...state, recommendedSession: action.data}
}

const setFeedback = (state, action) => {
  return { ...state, feedbackList: action.data}
}





const counsellingproviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNSELLING_PROVIDER_PROFILE: return setProfile(state, action)
    case SET_PROVIDER_COUNSELLING_TYPES: return setCounsellingType(state, action)
    case SET_PROVIDER_COUNSELLING_MODES: return setCounsellingModes(state, action)
    case SET_POST_COUNSELLING_STATUS: return setPostCounsellingStatus(state, action)
    case SET_SCHEDULED_SESSION: return setScheduledSession(state, action)
    case SET_UPCOMING_SESSION: return setUpcomingSession(state, action)
    case SET_ARCHIEVE_SESSION: return setArchieveSession(state, action)
    case SET_APPLIED_SESSION : return setAppliedSessions(state, action)
    case SET_SESSION_APPLICANTS: return setSessionApplicants(state, action)
    case SESSION_UPDATED: return updateSession(state, action)
    case SET_APPLIED_SESSION_BY_SEEKER: return setSessionsAppliedBySeeker(state, action)
    case SET_SESSION_DATA: return setSessionData(state, action)
    case SET_RECOMMENDED_COUNSELLING: return setRecommendedSession(state, action)
    case SET_FEEDBACK_LIST: return setFeedback(state, action)
    case SET_COUNSELOR_EDUCATION: return setUserEducation(state, action)
    case INDIVIDUAL_REQUEST: return setIndividualRequest(state, action)
    case INDIVIDUALS_REQUEST: return setIndividualsRequest(state, action)
    case SET_AREA_OF_INTEREST: return setAreaOfInterest(state, action)
    
    default: return state;
  }
};
export default counsellingproviderReducer;

