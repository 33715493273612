import React, { Component } from 'react'
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { get_education } from '../../../actions/accountAction';
import { getCounsellingModes } from '../../../actions/counsellingproviderAction';

import {
    getCounsellingType, postCounsellingSearch,
    applyForCounselling, setApplyForCounselling,
    setCounsellingResult, sendChatInvite,
    enableChat
} from '../../../actions/jobseekerAction';
import Pagination from "react-js-pagination";
import { chunk, getTimeWithAmPm } from '../../../helpers'
import { Link } from 'react-router'
import { translate } from 'react-multi-lang'
import ReactHtmlParser from 'react-html-parser';

const RESULT_PER_PAGE = 25

class SearchSession extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: [],
            jobDetails: false,
            job: [],
            activePage: 1,
            submitted: false,
            defaultSet: false
        }

        store.dispatch(get_education())
        store.dispatch(getCounsellingType());
        store.dispatch(getCounsellingModes());

    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    componentDidMount() {
        document.title = this.props.t('sidebar.search_counselling', { param: 'react' })
    }

    goToChat = (job) => {
        store.dispatch(enableChat({chat_id:job.employer_id, url:`/forum?chat=${job.employer_id}`}))
    }


    handleInput = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        formData[name] = value;
        if (!value) {
            delete formData[name]
        }
        await this.setState({ formData, submitted: false });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.JobseekerReducer.appliedForCounselling) {
            this.props.router.push('myaccount')
        }
        const formData = this.state.formData
        if (this.props.JobseekerReducer.Profile.highest_education_level && !this.state.defaultSet) {
            formData['education_level'] = this.props.JobseekerReducer.Profile.highest_education_level
            this.setState({ formData, defaultSet: true })
        }
    }

    inviteForChat = (job) => {
        store.dispatch(sendChatInvite({ request_to: job.employer_id, employer_name: job.employer_name, user_type: job.user_type }, this.state.formData));
    }

    componentWillUnmount() {
        store.dispatch(setApplyForCounselling(false))
        store.dispatch(setCounsellingResult([]))
    }

    submit = (e) => {
        e.preventDefault()
        if (!this.state.submitted) {
            this.setState({ activePage: 1 })
            store.dispatch(postCounsellingSearch(this.state.formData, true));
            this.setState({ submitted: true })
        }
    }

    clicked = (job) => {
        this.setState({ job, jobDetails: true })
    }

    applySession = async () => {
        document.getElementById("exampleModal").click()
        if (this.state.job) {
            await store.dispatch(applyForCounselling(this.state.job.id))
        }
    }

    getEducation = (jobData) => {
        let education = []
        if (this.props.actionReducer.Educations && this.props.actionReducer.Educations.length && jobData.education_level) {
            jobData.education_level.forEach((ed) => {
                const filterData = this.props.actionReducer.Educations.filter((e) => e.id === ed)
                if (filterData && filterData.length) {
                    education.push(filterData[0].name)
                }
            })
        }
        return education.length ? education.toString() : ''
    }

    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const Mainprops = this.props
        const { jobDetails, job, activePage, formData } = this.state
        const searchResults = chunk(Mainprops.JobseekerReducer.counsellingResults, RESULT_PER_PAGE)


        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h6 className="modal-title" id="exampleModalLabel">{this.props.t('counsellingProvider.applyInst', { param: 'react' })}</h6>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary btn-primary2" onClick={() => this.applySession()}>{this.props.t('profileDetails.yes', { param: 'react' })}</button>
                                            <button type="button" className="btn btn-primary btn-primary2" data-dismiss="modal">{this.props.t('profileDetails.no', { param: 'react' })}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {jobDetails &&
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">
                                                                {this.props.t('jobSearch.applyForJob',
                                                                    { param: 'react' })}
                                                            </h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="row ">
                                                                <div className="col-md-12">
                                                                    <div className="cover-shadow p-3 m-2">

                                                                        <div className="row">
                                                                            <div className="col-lg-3 col-sm-6 col-6 ">
                                                                                <p>{this.props.t('dashboard.table.title',
                                                                                    { param: 'react' })}: </p>
                                                                            </div>
                                                                            <div className="col-lg-9 col-sm-6 col-6">
                                                                                <label>{job.title ? job.title : ''}</label>
                                                                                <div className="pull-right">
                                                                                    {job.applied_on ?
                                                                                        <h6 className="date-clr ">{this.props.t('jobSearch.alreadyApplied', { param: 'react' })} <br></br>
                                                                                            {this.props.t('jobSearch.appliedOn', { param: 'react' })} {job.applied_on}</h6>
                                                                                        : null}
                                                                                    {!job.invited ? <button
                                                                                        onClick={() => this.inviteForChat(job)}
                                                                                        className="btn btn-primary btn-primary2">
                                                                                        {this.props.t('jobSearch.inviteForChat', { param: 'react' })} <i className="fa fa-commenting-o" style={{ color: '#fff' }}></i>
                                                                                    </button> : null}
                                                                                    {job.invited === 1 ?
                                                                                        <h6 className="date-clr pull-right">{this.props.t('jobSearch.invitedForChat', { param: 'react' })}</h6> : null}
                                                                                    {job.invited === 2 ?
                                                                                        <button
                                                                                            onClick={() => this.goToChat(job)}
                                                                                            className="btn btn-primary btn-primary2">
                                                                                            {this.props.t('jobSearch.chat', { param: 'react' })} <i className="fa fa-commenting-o" style={{ color: '#fff' }}></i>
                                                                                        </button>
                                                                                        : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {job.type === '2' ?
                                                                            <div className="row">
                                                                                <div className="col-lg-3 col-sm-6 col-6 ">
                                                                                    <p>{this.props.t('dashboard.table.location',
                                                                                        { param: 'react' })} :</p>
                                                                                </div>
                                                                                <div className="col-lg-9 col-sm-6 col-6">
                                                                                    <label>{job.address ? job.address : ''}</label>
                                                                                </div>
                                                                            </div> : null}
                                                                        <div className="row">
                                                                            <div className="col-lg-3 col-sm-6 col-6 ">
                                                                                <p>{this.props.t('dashboard.table.date',
                                                                                    { param: 'react' })} :</p>
                                                                            </div>
                                                                            <div className="col-lg-9 col-sm-6 col-6">
                                                                                <label>{job.valid_from + " - " + job.expire_on}</label>
                                                                            </div>
                                                                        </div>
                                                                        {job.timing_from && job.timing_to &&
                                                                            <div className="row">
                                                                                <div className="col-lg-3 col-sm-6 col-6 ">
                                                                                    <p>{this.props.t('counsellingProvider.timings',
                                                                                        { param: 'react' })} :</p>
                                                                                </div>
                                                                                <div className="col-lg-9 col-sm-6 col-6">
                                                                                    <label>{getTimeWithAmPm(job.timing_from) + " - " + getTimeWithAmPm(job.timing_to)}</label>
                                                                                </div>
                                                                            </div>}
                                                                        <div className="row">
                                                                            <div className="col-lg-3 col-sm-6 col-6 ">
                                                                                <p>{this.props.t('counsellingProvider.coun_type',
                                                                                    { param: 'react' })} :</p>
                                                                            </div>
                                                                            <div className="col-lg-9 col-sm-6 col-6">
                                                                                <label>{job.type ? job.type : ''}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-lg-3 col-sm-6 col-6 ">
                                                                                <p>{this.props.t('counsellingProvider.mode',
                                                                                    { param: 'react' })} :</p>
                                                                            </div>
                                                                            <div className="col-lg-9 col-sm-6 col-6">
                                                                                <label>{job.mode ? job.mode : ''}</label>
                                                                            </div>
                                                                        </div>
                                                                        {job.education_level && job.education_level.length ?
                                                                            <div className="row">
                                                                                <div className="col-lg-3 col-sm-6 col-6 ">
                                                                                    <p>{this.props.t('counsellingProvider.eduLevel',
                                                                                        { param: 'react' })} :</p>
                                                                                </div>
                                                                                <div className="col-lg-9 col-sm-6 col-6">
                                                                                    <label>{this.getEducation(job)}</label>
                                                                                </div>
                                                                            </div> : null}
                                                                        <div className="row">
                                                                            <div className="col-lg-3 col-sm-6  ">
                                                                                <p>{this.props.t('dashboard.table.description',
                                                                                    { param: 'react' })} :</p>
                                                                            </div>
                                                                            <div className="col-lg-9 col-sm-6 ">
                                                                                <label>{job.description ? ReactHtmlParser(job.description) : ''}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row ">
                                                                            <div className="col-md-12">
                                                                                <hr />
                                                                                {!job.applied_on ?
                                                                                    <button 
                                                                                    // onClick={() => this.applySession()} 
                                                                                    data-toggle="modal" data-target="#exampleModal"
                                                                                    className="btn btn-primary btn-primary2 mt-1">
                                                                                        {this.props.t('counsellingProvider.I am interested', { param: 'react' })}</button> : null}
                                                                                <button onClick={() => this.setState({ jobDetails: false })} style={{ "marginLeft": "10px" }}
                                                                                    className="btn btn-primary btn-primary2 mt-1">{this.props.t('experience.Cancel',
                                                                                        { param: 'react' })}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>}
                            {!jobDetails && <div>
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-3">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('counsellingProvider.searchCoun', { param: 'react' })}</h6>
                                                        </div>
                                                        <div className="panel-body mt-2">
                                                            <form onSubmit={this.submit}>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-4 col-md-3  col-5">
                                                                                    <label>{this.props.t('JobPost.keywordSearch', { param: 'react' })}</label>
                                                                                </div>
                                                                                <div
                                                                                    className="col-sm-8 col-md-7 col-7">
                                                                                    <input type="text"
                                                                                        value={this.state.formData.keyword ? this.state.formData.keyword : ''}
                                                                                        className="form-control"
                                                                                        onChange={this.handleInput}
                                                                                        name="keyword" placeholder={this.props.t('forms.counselorSearch', { param: 'react' })}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-4 col-md-3  col-5">
                                                                            <label>{this.props.t('counsellingProvider.coun_type', { param: 'react' })}</label>
                                                                        </div>
                                                                        <div className="col-sm-8 col-md-7 col-7">
                                                                            <select onChange={this.handleInput}
                                                                                value={formData.type ? formData.type : ''}
                                                                                className="form-control" name="type">
                                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('counsellingProvider.coun_type', { param: 'react' })}</option>
                                                                                {Mainprops.JobseekerReducer.counsellingType.length > 0 &&
                                                                                    Mainprops.JobseekerReducer.counsellingType.map(function (data, key) {
                                                                                        return (
                                                                                            <option value={data.id} key={key}>{data.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-4 col-md-3  col-5">
                                                                            <label>{this.props.t('counsellingProvider.mode', { param: 'react' })}</label>
                                                                        </div>
                                                                        <div className="col-sm-8 col-md-7  col-7">
                                                                            <select onChange={this.handleInput}
                                                                                value={formData.mode ? formData.mode : ''}
                                                                                className="form-control" name="mode">
                                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' }) +' '+this.props.t('counsellingProvider.mode', { param: 'react' })}</option>
                                                                                {this.props.counsellingproviderReducer && this.props.counsellingproviderReducer.modes &&
                                                                                    this.props.counsellingproviderReducer.modes.length ?
                                                                                    this.props.counsellingproviderReducer.modes.map((result, key) => {
                                                                                        return (
                                                                                            <option key={key} value={result.id}>{result.name}
                                                                                            </option>
                                                                                        )
                                                                                    }) : ''}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-4 col-md-3  col-5 ">
                                                                            <label>{this.props.t('JobPost.edu', { param: 'react' })}</label>
                                                                        </div>

                                                                        <div className="col-sm-8 col-md-7  col-7">
                                                                            <select onChange={this.handleInput}
                                                                                value={formData.education_level ? formData.education_level : ''}
                                                                                className="form-control" name="education_level">
                                                                                <option value="">{this.props.t('forms.selectEdu', { param: 'react' })}</option>
                                                                                {Mainprops.actionReducer.Educations.length > 0 &&
                                                                                    Mainprops.actionReducer.Educations.map(function (data, key) {
                                                                                        return (
                                                                                            <option value={data.id} key={key}>{data.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 ">
                                                                            <button type="submit"
                                                                                className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                                {this.props.t('jobSearch.search', { param: 'react' })}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                {Mainprops.JobseekerReducer.counsellingResults.length > 0 &&
                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}
                                                                    <span className="pull-right" style={{ "color": "#fff" }}>
                                                                        {Mainprops.JobseekerReducer.counsellingResults && Mainprops.JobseekerReducer.counsellingResults.length ?
                                                                            Mainprops.JobseekerReducer.counsellingResults.length : 0}{this.props.t('JobPost.foundItems', { param: 'react' })}</span></h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                {searchResults && searchResults.length && searchResults[activePage - 1].map((job, key) => {
                                                                    return (
                                                                        <div className="job-sec bg-white cover-shadow mb-2 p-3" key={key} onClick={() => this.clicked(job)} style={{ "cursor": "pointer" }}>
                                                                            <div className="row">
                                                                                <div className="col-md-8">
                                                                                    <Link>{job.title ? job.title : ''}</Link>
                                                                                    <ul className="nav">
                                                                                        {job.type === '2' ?
                                                                                            <li className="list-items-inline pt-2 pl-3" ><i
                                                                                                className="fa fa-map-marker  pr-2"></i> {job.address ? job.address : ''}
                                                                                            </li> : null}
                                                                                        {job.timing_from && job.timing_to ? <li className="list-items-inline pt-2 pl-3" ><i className="fa fa-clock-o" aria-hidden="true"></i>{" " + getTimeWithAmPm(job.timing_from) + ' - ' + getTimeWithAmPm(job.timing_to)}
                                                                                        </li> : null}
                                                                                        <li className="list-items-inline pt-2 pl-3"><i className="fa fa-calendar-o" aria-hidden="true"></i>{" " + job.valid_from + ' - ' + job.expire_on}</li>
                                                                                        {job.name ? <li className="list-items-inline pt-2 pl-3" title={'Counsellor name'}><i className="fa fa-user mr-2" aria-hidden="true"></i>{job.name}</li> : null}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {searchResults && searchResults.length > 1 ? <div className='react-pagination mt-2'>
                                                                    <Pagination
                                                                        pageRangeDisplayed={10}
                                                                        activePage={activePage}
                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                        totalItemsCount={Mainprops.JobseekerReducer.counsellingResults.length}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}
                                {Mainprops.JobseekerReducer.counsellingResults.status === '401' && <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}
                                                                <span className="pull-right" style={{ "color": "#fff" }}>
                                                                    {Mainprops.JobseekerReducer.counsellingResults && Mainprops.JobseekerReducer.counsellingResults.length ?
                                                                        Mainprops.JobseekerReducer.counsellingResults.length : 0}{this.props.t('JobPost.foundItems', { param: 'react' })}</span></h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            <h3>{Mainprops.JobseekerReducer.counsellingResults.message}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>}

                            </div>
                            }
                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(SearchSession));