import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { RegisterJobSeeker, get_org_type } from '../../actions/employerAction';
import Validation from "../hoc/Validation";

import store from '../../store';

class Employersignup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitStatus: false,
            user_subtype: window.location.search.split('=')[1],
            stateId: '',
            selectedCheckboxes: new Set()
        }
        store.dispatch(get_org_type());
        // store.dispatch(get_org_category());
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = this.props.t('forms.empSignup', { param: 'react' })
    }

    async handleUserInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'state_id') {
            this.setState({
                stateId: value
            })
        }
        if (name === 'remember') {
            let selectedCheckboxes = this.state.selectedCheckboxes
            if (selectedCheckboxes.has(value)) {
                selectedCheckboxes.delete(value);
            } else {
                selectedCheckboxes.add(value);
            }
            await this.setState({ selectedCheckboxes }, () => {
                value = Array.from(this.state.selectedCheckboxes)
            })
        }
        this.setState({ [name]: value });
        this.setState({ submitStatus: false });
    }

    handleSubmit(event) {
        event.preventDefault();
        const user = {
            email: this.state.email,
            org_category: this.state.org_category,
            org_type: this.state.org_type,
            district_id: this.state.district_id,
            org_name: this.state.org_name,
            state_id: this.state.state_id,
            mobile: this.state.mobile,
            password: this.state.password,
            website: this.state.website,
            org_pan: this.state.org_pan,
            user_subtype: this.state.user_subtype,
            communication: this.state.communication
        };

        if (!this.state.submitStatus && this.props.validator.allValid()) {
            this.setState({ submitStatus: true });
            store.dispatch(RegisterJobSeeker(user));
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.selectedDistricts.length > 0 && this.state.stateId) {
            Mainprops.actionReducer.selectedDistricts.map((data, index) => {
                if (data.state_id === this.state.stateId) {

                    data.dist.map(function (distdata, distindex) {
                        Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
                        return Distoption
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }

    createState = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.selectedDistricts.length > 0) {
            Mainprops.actionReducer.selectedDistricts.map(function (data, index) {
                if (index > 0) {
                    Distoption.push(<option key={index} value={data.state_id}>{data.state_name}</option>)

                }
                return Distoption
            })
            return Distoption
        }
    }

    render() {
        // let Mainprops = this.props;
        const { validator } = this.props
        return (
            <div className={"m-2"}>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <div className="form-group">
                            <div className="row">

                                <div className="col-sm-12 text-left">
                                    <label> {this.props.t('register_user.organization_name', { param: 'react' })}<span
                                        style={{ color: 'red' }} className="ml-2">*</span></label>
                                    <input type="text" placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('experience.Orgnisation Name', { param: 'react' })}
                                        onBlur={(event) => this.handleUserInput(event)}
                                        className="form-control" id="org_name" name="org_name" />
                                    {validator.message('Organization name', this.state.org_name, 'required')}
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 text-left">
                                    <label> {this.props.t('register_user.organization_category', { param: 'react' })}<span
                                        style={{ color: 'red' }} className="ml-2">*</span></label>
                                    <select className="form-control" id="org_category"
                                        onChange={(event) => this.handleUserInput(event)} name="org_category">
                                        <option value="">{this.props.t('forms.selectOrgCat', { param: 'react' })}</option>
                                        {this.props.actionReducer.Orgtype && this.props.actionReducer.Orgtype.length ?
                                            this.props.actionReducer.Orgtype.map((org, key) => {
                                                return (
                                                    <option value={org.id} key={key}>{org.name}</option>
                                                )
                                            }) : ''}
                                    </select>
                                    {validator.message('Organization category', this.state.org_category, 'required')}
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 text-left">
                                    <label> {this.props.t('register_user.organization_state', { param: 'react' })}<span
                                        style={{ color: 'red' }} className="ml-2">*</span></label>
                                    <select onChange={(event) => this.handleUserInput(event)}
                                        name="state_id" className="form-control">
                                        <option value={""}>{this.props.t('profileDetails.selectState', { param: 'react' })}</option>
                                        {this.createState()}
                                    </select>
                                    {validator.message('state', this.state.state_id, 'required')}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 text-left">
                                    <label> {this.props.t('register_user.district', { param: 'react' })}<span
                                        style={{ color: 'red' }} className="ml-2">*</span></label>
                                    <select onChange={(event) => this.handleUserInput(event)}
                                        name="district_id" className="form-control">
                                        <option value={""}>{this.props.t('profileDetails.selectDistrict', { param: 'react' })}</option>
                                        {this.createDistoption()}
                                    </select>
                                    {validator.message('district', this.state.district_id, 'required')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('register_user.organization_website', { param: 'react' })}</label>
                                <input type="text" placeholder={this.props.t('forms.website', { param: 'react' })}
                                    onBlur={(event) => this.handleUserInput(event)}
                                    className="form-control" id="website" name="website" />
                                {validator.message('website', this.state.website, 'url')}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('register_user.organization_pan', { param: 'react' })}<span
                                    style={{ color: 'red' }} className="ml-2">*</span></label>
                                <input type="text" placeholder={this.props.t('forms.orgPan', { param: 'react' })}
                                    onChange={(event) => this.handleUserInput(event)}
                                    className="form-control" id="org_pan" name="org_pan"
                                />
                                {validator.message('pan', this.state.org_pan, 'required|regex:^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$')}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('register_user.email', { param: 'react' })}<span
                                    style={{ color: 'red' }} className="ml-2">*</span></label>
                                <input  onBlur={(event) => this.handleUserInput(event)}
                                    className="form-control" placeholder={this.props.t('forms.egEmail', { param: 'react' })} name="email"
                                    id="exampleInputDOB1" />
                                {validator.message('email', this.state.email, 'required|email')}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('register_user.mobile', { param: 'react' })}<span
                                    style={{ color: 'red' }} className="ml-2">*</span></label>
                                <input onChange={(event) => this.handleUserInput(event)}
                                    type="text" className="form-control" placeholder={this.props.t('forms.10mobile', { param: 'react' })}
                                    name="mobile" id="mobile"
                                />
                                {validator.message('mobile', this.state.mobile, 'required|phone')}
                            </div>
                        </div>
                    </div>
                   
                    <div className="form-group">
                        <div className="col-sm-12 text-left">
                            <div className="row">

                                <label className={"col-md-12 p-0"}>{this.props.t('register_user.communication', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span> </label>
                                <div className="form-check-inline">
                                    <label className="form-check-label" htmlFor="radio1">
                                        <input type="radio" onChange={(event) => this.handleUserInput(event)}
                                            className="form-check-input" name="communication" value={"1"}
                                            checked={this.state.communication === "1"} />{this.props.t('profileDetails.yes', { param: 'react' })}
                                    </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label" htmlFor="radio1">
                                        <input type="radio" onChange={(event) => this.handleUserInput(event)}
                                            className="form-check-input" name="communication" value={"0"}
                                            checked={this.state.communication === "0"} />{this.props.t('profileDetails.no', { param: 'react' })}
                                    </label>
                                </div>

                            </div>

                        </div>
                        {validator.message('communication', this.state.communication, 'required')}
                    </div>
                    <div className="form-group">
                        <div className="row m-0">
                            <div className="col-sm-12 ml-2">
                                <input className="form-check-input" type="checkbox" name="remember" onClick={(e) => this.handleUserInput(e)} />

                                {this.props.t('register_user.terms', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span>

                                <div className="valid-feedback">Valid.</div>
                                <label className="invalid-feedback">Check this checkbox to continue.</label>
                            </div>
                            <div className="row m-0">
                                {validator.message('terms and conditions', this.state.remember, 'required')}
                            </div>
                            <div className="row m-0 w-100">
                                <button type="submit" className="btn btn-primary btn-primary2  mt-3" >{this.props.t('profileDetails.submit', { param: 'react' })}</button>
                            </div>
                        </div>
                    </div>


                </form>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Validation(Employersignup)));
