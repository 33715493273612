import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { setRedirectionLink } from '../../actions/accountAction'
import { subscribeForWebinar, get_jobseeker_profile, setJobseekerProfile } from '../../actions/jobseekerAction'
import store from '../../store';
import { sendFeedback } from '../../actions/accountAction'
// import { Link } from 'react-router'
import StarRatings from 'react-star-ratings'

class Webinar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            noProfile: true,
            feedback: '',
            rating: ''
        }
        store.dispatch(setJobseekerProfile([]))

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.actionReducer.Loginuser && Object.keys(nextProps.actionReducer.Loginuser).length &&
            nextProps.actionReducer.Loginuser.user_type === '1' && this.state.noProfile) {
            store.dispatch(get_jobseeker_profile())
            this.setState({ noProfile: false })
        }
    }

    componentDidMount() {
        document.title = this.props.t('homeContent.webinar', { param: 'react' })

    }

    webinarRedirect = () => {
        if (this.props.actionReducer.Loginuser && Object.keys(this.props.actionReducer.Loginuser).length) {
            store.dispatch(subscribeForWebinar({ webinar_subscription: '1' }))
        } else {
            store.dispatch(setRedirectionLink('myaccount?sub=1'))
            this.props.router.push('/signin')
        }
    }

    feedbackClicked = () => {
        if (this.props.actionReducer.Loginuser && Object.keys(this.props.actionReducer.Loginuser).length) {
            this.props.router.push('/apply-webinar')
        } else {
            store.dispatch(setRedirectionLink('apply-webinar'))
            this.props.router.push('/signin')
        }
    }

    ratingChanged = (val) => {
        this.setState({ 'rating': val })
    }

    handleFeedback = (e) => {
        const value = e.target.value
        const name = e.target.name
        this.setState({ [name]: value })
    }

    submitFeedback = () => {
        const { feedback, rating } = this.state
        if (feedback || rating) {
            store.dispatch(sendFeedback({ feedback, rating, webinar: "1" }))
            this.setState({ feedback: '' })
        }
    }

    render() {
        const { feedback, rating } = this.state
        
        return (
            <div className="tab-content">
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Webinar rating</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body mx-auto">
                                <StarRatings
                                    rating={rating}
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="80px"
                                    starRatedColor="rgb(255, 215, 0)"
                                    starHoverColor="rgb(255, 215, 0)"
                                    changeRating={this.ratingChanged}

                                />

                                <textarea cols="5" className="w-100" name="feedback" value={feedback} onChange={this.handleFeedback} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={!feedback && !rating} data-dismiss="modal" onClick={() => this.submitFeedback()} className="btn btn-primary btn-primary2 mx-auto">{this.props.t('jobProviderProfile.submit', { param: 'react' })}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">YuWaah – Pride of Punjab</h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <b>Launch of YuWaah Programme by the hon'ble CM, Punjab</b><br />
                                    To empower the youth of Punjab, Hon'ble Chief Minister Punjab Capt. Amarinder Singh Ji will launch YuWaah "Pride of Punjab" Programme in collaboration with UNICEF on 5th August 2020 at 6:30 PM.
                                    Inauguration programme will be live telecast on Facebook. To join the event, please click on the following link and become part of a special evening:
                                    <br /><a href="https://www.facebook.com/YuWaahIndia" target="blank">https://www.facebook.com/YuWaahIndia</a>

                                        {/* {
                                     (!Object.keys(this.props.actionReducer.Loginuser).length || this.props.actionReducer.Loginuser.user_type==='1' && !this.props.JobseekerReducer.Profile.webinar_subscription) && 
                                     <>
                                        <h5 style= {{'fontSize':'14px', 'marginTop':'1%'}}>Note: Only registered seekers can participate in the webinar after login. If you are not registered on the portal <a href="/signup?type=1">click here</a> to register and then click on the following button.</h5>
                                        <button type="submit"
                                        onClick={() => this.webinarRedirect()}
                                        className="mb-2 btn btn-primary mx-auto d-block btn-primary2 mt-1">Click here to apply for the webinar</button>
                                    </>}
                                    {this.props.JobseekerReducer.Profile && this.props.JobseekerReducer.Profile.webinar_subscription ==='1' &&
                                    <>
                                    <p className="mt-4 font-weight-bold mb-0">You have successfully applied for webinar to be held on 24.7.20 at 3 pm. Click the link below to subscribe and participate in the webinar.
                                    <br></br> <a rel="noopener noreferrer" href="https://www.youtube.com/channel/UCb9yZbaHeSqfJGAq7EidUrA/featured?view_as=subscriber" target="_blank"> https://www.youtube.com/channel/UCb9yZbaHeSqfJGAq7EidUrA/featured?view_as=subscriber</a></p><br></br> */}
                                        {/* { <Link data-toggle="modal" style={{"cursor":"pointer"}}  className="date-clr" data-target="#exampleModalCenter">Give your feedback regarding the webinar</Link> } */}
                                        {/* <br /> <Link style={{"cursor":"pointer"}} className="date-clr" onClick={() => this.feedbackClicked()}>Give your feedback regarding webinar.</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Webinar));
