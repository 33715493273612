import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router'

class NoticeForFraud extends Component {

    state = {
        fraud: []
    }

    componentDidMount() {
        document.title = this.props.t('homeContent.noticeForFraud', { param: 'react' })
    }

    setModalData = fraud => this.setState({ fraud })


    render() {
        const fraudCases = [
            {
                title: "Reg. fake employer Mohit Kumar at employment fair in Fazilka",
                description: "At an employment fair organized at Fazilka, an employer in the name of Mohit Kumar S/o sh. Rajesh Kumar, R/o Sri Muktsar Sahib who introduced himself as H.R. Amazon Co., has been found to be fake.  The jobseekers are advised to bring it to the notice of their respective DBEEs, if they find themselves dealing with any such fake/fraudulent Employers. Contact details are available at",
                link: <a href={'http://pgrkam.com/employment'} rel="noopener noreferrer" target="_blank">http://pgrkam.com/employment</a>
            }

        ]
        const { fraud } = this.state
        return (
            <div className="tab-content" style={{ "border": "none" }}>
                <div className="modal fade" id="descriptionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('counsellingProvider.details', { param: 'react' })}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {fraud.description} {fraud.link}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">{this.props.t('counsellingProvider.close', { param: 'react' })}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">{this.props.t('homeContent.noticeForFraud', { param: 'react' })}</h6>
                                    </div>
                                    <div className="panel-body p-3">
                                        {fraudCases.map((fraud, key) => {
                                            return (
                                                <div className="mb-4" key={key}>
                                                    <h6>{fraud.title} (<Link style={{ cursor: "pointer" }} className="date-clr" data-target="#descriptionModal" data-toggle="modal"
                                                        onClick={() => this.setModalData(fraud)} >
                                                        {this.props.t('jobSearch.viewDetails', { param: 'react' })}</Link>)
                                                        <span className="pull-right disabled-color">Last Updated On: 17-2-2021</span>
                                                    </h6>
                                                </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(NoticeForFraud));
