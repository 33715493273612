import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import store from '../../store';
import { Resendotp, Login, set_OTP } from '../../actions/accountAction';
import Validation from "../hoc/Validation";

class MobileSignIn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      basePath: window.location.protocol + '//' + window.location.host + '/',
      mobile: '',
      otp: '',
      submitted: false
    }
  }

  componentWillUnmount() {
    store.dispatch(set_OTP(false))
  }

  componentDidMount() {
    document.title = this.props.t('login.login_otp', { param: 'react' })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { mobile, otp } = this.state

    if (!this.state.submitted && mobile && otp && this.props.validator.fieldValid('number') &&
      this.props.validator.fieldValid('otp')) {
      store.dispatch(Login({
        mobile,
        otp,
      }));
      this.setState({ submitted: true })
    }
    else if (mobile && !this.state.submitted && this.props.validator.fieldValid('number') &&
      !this.props.validator.fieldValid('otp') && !otp) {
      await store.dispatch(Resendotp({ mobile }));
      this.setState({ submitted: true })
    } else {
      this.props.validator.showMessages();
      this.forceUpdate();
    }

  }

  resendOtp() {
    const user = {
      mobile: this.state.mobile
    };
    store.dispatch(Resendotp(user));
  }

  change = (id, e) => {
    let inputType = document.getElementById(id).type
    if (inputType === 'password') {
      document.getElementById(id).type = 'text'
      e.target.className = e.target.className.substring(0, e.target.className.lastIndexOf("-"));
    } else {
      document.getElementById(id).type = 'password'
      e.target.className += '-slash'
    }

  }

  handleUserInput(e) {
    const name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value, submitted: false });
  }

  redirectConditionally = () => {
    if (this.props.actionReducer.link && this.props.actionReducer.Loginuser.user_type === '1') {
      window.location.href = this.state.basePath + this.props.actionReducer.link
      return
    }

    if ( this.props.actionReducer.Loginuser && this.props.actionReducer.Loginuser.user_type &&
      (this.props.actionReducer.Loginuser.user_type === '7' || this.props.actionReducer.Loginuser.user_type === '10' ||
       this.props.actionReducer.Loginuser.user_type === '12')) {
          window.location.href = this.state.basePath + 'myprofile';
          return
  }

    if (localStorage.getItem('Islogin') && localStorage.getItem('Islogin') === 'true') {
      window.location.href = this.state.basePath + 'myprofile'
    }
  }

  render() {
    this.redirectConditionally()
    const { validator } = this.props

    return (
      <section className="login-form main-content-height item-center">
        <div className="container">
          <div className="col-md-6 mx-auto cover cover-shadow border-0  p-0">
            <div className="first-click-inst p-3">
              <h5>Login with OTP</h5>
            </div>

            <div className="panel-body">

              <form className="col-md-12 mx-auto" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-12 mt-3 ">
                      <label>{this.props.t('login.forgotpass_inst1', { param: 'react' })}</label>
                      <input type="text"
                        onChange={(event) => this.handleUserInput(event)}
                        defaultValue={this.state.mobile}
                        placeholder={this.props.t('forms.enterMobile', { param: 'react' })}
                        disabled={this.props.notificationReducer.openotp}
                        onBlur={() => this.props.validator.showMessageFor('number')}
                        className="form-control" id="username" name="mobile" />
                      {validator.message('number', this.state.mobile, 'required|phone')}
                    </div>

                  </div>
                </div>
                {this.props.notificationReducer.openotp && <div className="form-group">
                  <div className="row">
                    <div className="col-sm-12 ">
                      <label>{this.props.t('login.code', { param: 'react' })}</label>
                      <input onChange={(event) => this.handleUserInput(event)}
                        placeholder={this.props.t('forms.enterOtp', { param: 'react' })}
                        type="password" className="form-control form-eye" id="password-otp" name="otp"
                        defaultValue={this.state.otp} />
                      {validator.message('otp', this.state.otp, 'required')}
                      <span onClick={(e) => this.change('password-otp', e)}
                        className="input fa fa-fw field-icon toggle-password fa-eye-slash"
                      ></span>

                      <button type="submit" className="btn btn-primary btn-primary2 mr-2 mt-3">Login</button>
                      <span onClick={() => this.resendOtp()} style={{ "cursor": "pointer" }} className="date-clr mt-4 float-left mr-3">{this.props.t('login.resend_otp', { param: 'react' })}</span>
                    </div>

                  </div>
                </div>}

                {!this.props.notificationReducer.openotp && <div className="form-group">
                  <div className="col-sm-12 p-0">
                    <button type="submit" className="btn btn-primary btn-primary2 ">{this.props.t('login.get_OTP', { param: 'react' })}</button>
                  </div>

                </div>}

              </form>

            </div>

          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = store => {
  return store;
};

export default connect(mapStateToProps)(translate(Validation(MobileSignIn)));
