import { SET_RECENT_SERVICES, SET_APPLIED_CANDIDATES, 
  SET_CATEGORY_SERVICE, SET_SCHEDULE_VISITS, SET_SERVICE_FEEDBACK } from '../actions/actionTypes';
  
  const initialState = {
    recentServices:[],
    appliedCandidates:[],
    serviceCategory:[],
    scheduleVisits:[],
    feedbackList:[]
  };

  const setRecentServices = (state, action) => {
    return { ...state, recentServices: action.Services };
  }

  const setAppliedCandidates = (state, action) => {  
    return { ...state, appliedCandidates: action.data };
  }

  const setServiceCategory = (state, action) => {  
    return { ...state, serviceCategory: action.data };
  }

  const setScheduleVisits = (state, action) => {  
    return { ...state, scheduleVisits: action.data };
  }

  const setFeedback = (state, action) => {  
    return { ...state, feedbackList: action.data };
  }
  
  
  
  const LspReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_RECENT_SERVICES: return setRecentServices(state, action);
      case SET_APPLIED_CANDIDATES: return setAppliedCandidates(state, action);
      case SET_CATEGORY_SERVICE: return setServiceCategory(state, action);
      case SET_SCHEDULE_VISITS: return setScheduleVisits(state, action);
      case SET_SERVICE_FEEDBACK: return setFeedback(state, action);
      default: return state;
    }
  };
  
  export default LspReducer;
  