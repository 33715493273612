import React, { Component } from 'react';
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { chunk } from '../../../helpers'
import Pagination from "react-js-pagination";
import ServerSetting from '../../../config.json';
import {
    getAppliedServiceCandidates, setConfirmedCandidates
} from '../../../actions/employerAction'
import { Link } from 'react-router'


const RESULT_PER_PAGE = 25

class AppliedCandidates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
        }
        if (this.props.params && this.props.params.id) {
            store.dispatch(getAppliedServiceCandidates(this.props.params.id));
        }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    componentWillUnmount() {
        store.dispatch(setConfirmedCandidates([]))
    }

    componentDidMount() {
        document.title = this.props.t('dashboard.table.appliedCandidates', { param: 'react' })
    }

    render() {
        let Mainprops = this.props;

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        let searchResults = chunk(Mainprops.EmployerReducer.confirmedCandidates, RESULT_PER_PAGE)
        const { activePage } = this.state


        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            {Mainprops.EmployerReducer.confirmedCandidates.length > 0
                                &&
                                <div>
                                    <div id="home" className=" tab-pane active">
                                        <section className="job-details">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12  mx-auto  tab-content  p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('dashboard.table.appliedCandidates', { param: 'react' })}</h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="col-md-12 col-sm-12 col-lg-12 right-sidebar-scroll">
                                                                {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((job, key) => {
                                                                    return (
                                                                        <div className="right-sidebar" key={key}>

                                                                            <div className="first-job mb-3 p-3">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">

                                                                                        <h4 className="company-name">
                                                                                            {job.image && <Link>
                                                                                                <img alt=""
                                                                                                    src={job.image ? `${SERVER_PATH}${job.image}` : '/images/img-1.png'}
                                                                                                    className="avatar mr-2" />
                                                                                            </Link>}
                                                                                            {job.name && <Link>Name: {job.name}</Link>}
                                                                                        </h4>


                                                                                        <div className="row">
                                                                                            <div className="col-md-4 mb-1"><span className=" date-clr">{this.props.t('profileDetails.district', { param: 'react' })}: <span>{job.district} </span></span></div>

                                                                                            <div className="col-md-4 mb-1"><span className=" date-clr">{this.props.t('dashboard.table.mobile', { param: 'react' })}: <span>{job.mobile}</span><span>
                                                                                                {job.age_preference ? job.age_preference : ''}
                                                                                            </span></span></div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            {job.doc && <div className="col-md-8 mb-1"><span className=" date-clr">Resume:
                                                                                                <a target="_blank"
                                                                                                    rel="noopener noreferrer" className="pl-2"
                                                                                                    href={`${SERVER_PATH}${job.doc}`}
                                                                                                    style={{ "cursor": "pointer" }}><i
                                                                                                        style={{ "marginRight": "6px" }}
                                                                                                        className="fa fa-file-text"
                                                                                                        aria-hidden="true"></i>{this.props.t('jobProviderProfile.download', { param: 'react' })}</a>
                                                                                            </span></div>}
                                                                                            <div className="col-md-4 mb-1"><span className=" date-clr">{this.props.t('profileDetails.communication', { param: 'react' })}: <span>{job.customer_address} </span></span></div>
                                                                                        </div>
                                                                                        <div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {searchResults && searchResults.length > 1 ?
                                                                    <div className='react-pagination'>
                                                                        <Pagination
                                                                            pageRangeDisplayed={10}
                                                                            activePage={activePage}
                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                            totalItemsCount={Mainprops.EmployerReducer.confirmedCandidates.length}
                                                                            onChange={this.handlePageChange}
                                                                        />
                                                                    </div> : ''}
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            }
                            {Mainprops.EmployerReducer.confirmedCandidates.notFound &&
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('dashboard.table.noSelected', { param: 'react' })}
                                                            </h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            <h3>{Mainprops.EmployerReducer.confirmedCandidates.notFound}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>}
                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(AppliedCandidates));
