import React, { Component } from 'react';
import { translate } from 'react-multi-lang'
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { getAppliedJobs, setAppliedJobs, willGoForInterview } from "../../../actions/jobseekerAction";
import Pagination from "react-js-pagination";
import { getJobStatus } from "../../../actions/employerAction";
import { get_User_District } from '../../../actions/accountAction'
import { chunk } from '../../../helpers'
import ReactToPrint from "react-to-print";
import { Link } from 'react-router'

const RESULT_PER_PAGE = 25

class AppliedJobs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            job: '',
            checked: ""
        }
        store.dispatch(getJobStatus())
        store.dispatch(get_User_District())
        store.dispatch(getAppliedJobs())
        this.printContent = React.createRef();
    }

    componentDidMount() {
        document.title = this.props.t('dashboard.table.jobs', { param: 'react' });
    }


    componentWillUnmount() {
        store.dispatch(setAppliedJobs([]))
    }


    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    getJobStatus = (status) => {
        const matchedJob = this.props.EmployerReducer.jobStatus.filter((job) => job.id === status)
        return matchedJob && matchedJob[0] ? matchedJob[0].name : ''
    }

    getLocationFromIds = (location) => {
        const names = []
        if (typeof location !== 'undefined') {
            if (location && location.length) {
                this.props.actionReducer.District && this.props.actionReducer.District.length &&
                    this.props.actionReducer.District.forEach((data) => {
                        if (data.state_id === "3") {
                            location.forEach(element => {
                                let filterVal = data.dist.filter((district) => district.id === element)
                                if (filterVal && filterVal[0]) {
                                    names.push(filterVal[0].name)
                                }
                            });
                        }
                    })
            }
            return names.length ? names.toString() : ''
        }


    }

    handleChange = async (e, job) => {
        console.log('e', e.target.value)
        console.log('job', job)
        this.setState({ checked: e.target.value })
        await store.dispatch(willGoForInterview({
            accepted: e.target.value,
            id: job.id
        }))


        setTimeout(() => {
            const modal = document.getElementById("detailsModal");
            this.setState({ checked: "" })
            modal.click();
        }, 200)

    }

    getTimeWithAmPm = (time) => {
        if (time) {
            let newtime = time.split(":");
            var AmOrPm = newtime[0] >= 12 ? 'PM' : 'AM';
            let hours = (newtime[0] % 12) || 12
            return hours + ":" + newtime[1] + " " + AmOrPm
        }
        return ""
    }


    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        let Mainprops = this.props;
        const { activePage, job } = this.state
        const searchResults = chunk(this.props.JobseekerReducer.appliedJobs, RESULT_PER_PAGE)
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="modal fade bd-example-modal-sm" id="detailsModal" tabIndex="-1"
                                role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-md ">
                                    <div className="modal-content bg-modal">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">{this.props.t('JobPost.int', { param: 'react' })}</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-12" ref={this.printContent}>
                                                    <span className="AppliedJob"><span>{this.props.t('jobProviderProfile.org_name', { param: 'react' })}:</span> <span>{job.org_name}</span></span>
                                                    <span className="AppliedJob"><span>{this.props.t('dashboard.table.job-title', { param: 'react' })}:</span> <span> {job.job_title}</span></span>
                                                    {job.interview_contact_person && job.interview_contact_person !== "null" &&
                                                        <span className="AppliedJob">
                                                            <span>{this.props.t('dashboard.table.contact_person', { param: 'react' })}:</span> <span> {job.interview_contact_person}</span></span>}
                                                    {job.designation && job.designation !== "null" &&
                                                        <span className="AppliedJob"><span>{this.props.t('JobPost.deb', { param: 'react' })}: </span> <span>{job.designation}</span></span>}


                                                    {job.interview_slot_date && job.interview_slot_date !== "null" &&
                                                        <span className="AppliedJob"><span>{this.props.t('JobPost.int_date', { param: 'react' })}:</span> <span> {job.interview_slot_date}</span></span>}
                                                    {job.slot_time_start !== "null" && job.slot_time_end !== "null" &&
                                                        <span className="AppliedJob"><span>{this.props.t('JobPost.int_time', { param: 'react' })}:</span> <span> {this.getTimeWithAmPm(job.slot_time_start) + "-" + this.getTimeWithAmPm(job.slot_time_end)}</span></span>}
                                                    {job.interview_venue && job.interview_venue !== "null" &&
                                                        <span className="AppliedJob"><span>{this.props.t('JobPost.venue', { param: 'react' })}:</span> <span> {job.interview_venue}</span></span>}
                                                    {job.interview_phone && job.interview_phone !== "null" &&
                                                        <span className="AppliedJob"> <span>{this.props.t('JobPost.contact_phone', { param: 'react' })} : </span> <span>{job.interview_phone}</span></span>}
                                                    {!parseInt(job.interview_accepted) &&
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <label data-toggle="tooltip" data-placement="bottom"
                                                                    title={this.props.t('dashboard.table.will_go', { param: 'react' })}>
                                                                    {this.props.t('dashboard.table.will_go', { param: 'react' })}:</label>
                                                            </div>
                                                            <div className="col-sm-12 form-group d-flex ml-3">
                                                                <div>
                                                                    <label className="form-check-label" htmlFor="radio1"
                                                                        style={{ "marginRight": "10px" }}>
                                                                        <input required type="radio"
                                                                            onChange={(event) => this.handleChange(event, job)}
                                                                            checked={this.state.checked === '1'}
                                                                            className="form-check-input" name="will_go"
                                                                            value={"1"} />{this.props.t('profileDetails.yes', { param: 'react' })}
                                                                    </label>
                                                                </div>
                                                                <div className="ml-4">
                                                                    <label className="form-check-label" htmlFor="radio1">
                                                                        <input required type="radio"
                                                                            onChange={(event) => this.handleChange(event, job)}
                                                                            checked={this.state.checked === '0'}
                                                                            className="form-check-input" name="will_go"
                                                                            value={"0"} />{this.props.t('profileDetails.no', { param: 'react' })}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                </div>
                                                <hr></hr>
                                                <ReactToPrint
                                                    trigger={() => <button
                                                        className={"btn btn-primart btn-primary2 btn-print mx-auto d-block mb-2 mt-2"}>
                                                        {this.props.t('hallTicket.print', { param: 'react' })}
                                                    </button>}
                                                    content={() => this.printContent.current}
                                                    copyStyles={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>

                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('dashboard.table.jobs', { param: 'react' })}</h6>
                                                    </div>
                                                    <div className="panel-body">

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.job-title', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.employer', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.salary', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.location', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.status', { param: 'react' })}</th>
                                                                                <th>{this.props.t('dashboard.table.applied_on', { param: 'react' })}</th>
                                                                                <th>{this.props.t('JobPost.int', { param: 'react' })}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {searchResults.length > 0 && searchResults[activePage - 1].map((jobs, key) => {
                                                                                return (<tr key={key}>
                                                                                    <td>{((activePage - 1) * RESULT_PER_PAGE) + key + 1}</td>
                                                                                    <td onClick={() => this.props.router.push(`job-details/${jobs.job_id}`)}>
                                                                                        <span style={{ "cursor": "pointer" }}
                                                                                            className="date-clr">{jobs.job_title}</span>
                                                                                    </td>

                                                                                    <td>{jobs.org_name ? jobs.org_name : ''}</td>

                                                                                    <td>{jobs.salary_min && jobs.salary_max ? jobs.salary_min + '-' + jobs.salary_max : jobs.salary_min} </td>
                                                                                    <td className="fix-col">{jobs.location ? this.getLocationFromIds(jobs.location) : ''}</td>
                                                                                    <td>{this.getJobStatus(jobs.job_status)}</td>
                                                                                    <td>{jobs.applied_on ? jobs.applied_on.split(" ")[0] : ''}</td>
                                                                                    <td>{jobs.applied && parseInt(jobs.applied) > 2 && <span onClick={() => this.setState({ job: jobs })}
                                                                                        data-toggle="modal" data-target="#detailsModal" className="date-clr" style={{ "cursor": "pointer" }}>
                                                                                        {this.props.t('modal.clickHere', { param: 'react' })}
                                                                                    </span>}</td>
                                                                                </tr>)

                                                                            })}
                                                                            {!this.props.JobseekerReducer.appliedJobs.length &&
                                                                                <tr>
                                                                                    <td colSpan="8"
                                                                                        style={{ "textAlign": "center" }}><h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5></td>
                                                                                </tr>}


                                                                        </tbody>
                                                                    </table>
                                                                    {searchResults && searchResults.length > 1 ?
                                                                        <div className='react-pagination'>
                                                                            <Pagination
                                                                                pageRangeDisplayed={10}
                                                                                activePage={activePage}
                                                                                itemsCountPerPage={RESULT_PER_PAGE}
                                                                                totalItemsCount={this.props.JobseekerReducer.appliedJobs.length}
                                                                                onChange={this.handlePageChange}
                                                                            />
                                                                        </div> : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(AppliedJobs));
