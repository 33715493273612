import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'

class UserManual extends Component {

    componentDidMount() {
        document.title = this.props.t('homeContent.userManual', { param: 'react' })
    }

    render() {
        return (
            <div className="tab-content" style={{ border: "none" }}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">{this.props.t('homeContent.userManual', { param: 'react' })}</h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0 p-3">
                                        <h4><a href="/docs/Jobseeker_manual.pdf" target="_blank">{this.props.t('header.jobseekerManual', { param: 'react' })}</a></h4>
                                        <h4><a href="/docs/employer_manual.pdf" target="_blank">{this.props.t('header.employerManual', { param: 'react' })}</a></h4>
                                        <h4><a href="/docs/counsellor.pdf" target="_blank">{this.props.t('header.counsellorManual', { param: 'react' })}</a></h4>
                                        <h4><a href="/docs/Local-service-provider.pdf" target="_blank">{this.props.t('header.lspManual', { param: 'react' })}</a></h4>
                                        <h4><a href="/docs/Job-Mela-Manual.pdf" target="_blank">Job Mela Manual</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(UserManual));
