import React, {Component} from "react";

class PageNotFound extends Component {

    redirectToHome = () => {
        const path = localStorage.getItem('Islogin') && localStorage.getItem('Islogin') === 'true' ? 'myaccount' : '/'
        this.props.router.push(path)
    }

    componentDidMount() {
        document.title = "404";
    }

    render() {
        return (
            <section className="top-inner animatedParent main-content-height mb-5">
                <div className="container">
                    <div className="row">
                        <div className={"col-md-12 col-lg-12 col-sm-12 mt-5"}>
                            <img className={"img-fluid"} alt={"Not found"} src="/images/404.jpg"/>
                        </div>
                        <div className={"col-md-12 col-lg-12 col-sm-12 mb-5"}>
                            <button
                                onClick={() => this.redirectToHome()}
                                className="btn btn-primary btn-primary2 mr-2 mx-auto d-block">Go back
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}

export default PageNotFound;