import React, { Component } from 'react';
import SidebarProvider from './jobprovider/Sidebar';
import SidebarSeeker from './jobseeker/Sidebar';
import store from "../../store";
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { sendFeedback } from '../../actions/accountAction'
import Validation from "../hoc/Validation";

class PortalFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: [],
            error: ''
        }
    }

    componentDidMount() {
        document.title = "Portal feedback";
    }


    submit = async (e) => {
        e.preventDefault()
        if (this.props.validator.allValid() && this.state.formData.availed_result) {
            await store.dispatch(sendFeedback(this.state.formData))
            await this.props.validator.hideMessages()
            this.setState({ formData: [] })
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
            if (!this.state.formData.availed_result) {
                this.setState({ error: 'The rating field is required' })
            }
        }
    }

    change = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        if (name === 'availed_result' && this.state.error) {
            this.setState({ error: "" })
        }
        formData[name] = value;
        this.setState({ formData: formData });
    }

    render() {
        const Sidebar = localStorage.getItem('login_type') === 'Jobseeker'
            ? SidebarSeeker
            : SidebarProvider
        let Mainprops = this.props;
        const { validator } = this.props
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const { formData, error } = this.state

        const radioBtns = [
            {
                label: this.props.t('feedback.verySatisfied', { param: 'react' }),
                value: 1
            },
            {
                label: this.props.t('feedback.satisfied', { param: 'react' }),
                value: 2
            },
            {
                label: this.props.t('feedback.average', { param: 'react' }),
                value: 3
            },
            {
                label: this.props.t('feedback.belowAverage', { param: 'react' }),
                value: 4
            },
            {
                label: this.props.t('feedback.unsatisfied', { param: 'react' }),
                value: 5
            },
            {
                label: this.props.t('feedback.veryUnsatisfied', { param: 'react' }),
                value: 6
            }
        ]
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <a href="/#" className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </a>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12 bg-education  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('feedback.title', { param: 'react' })}
                                                            ({this.props.t('feedback.titleDesc', { param: 'react' })})</h6>
                                                    </div>


                                                    <div className="panel-body">
                                                        <form onSubmit={this.submit}>
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-sm-5 text-right">
                                                                        <label>{this.props.t('feedback.rating', { param: 'react' })} : <span
                                                                            className="ml-2"
                                                                            style={{ "color": "red" }}>*</span></label>
                                                                    </div>
                                                                    <div className="col-sm-5">
                                                                        {radioBtns.map((btn, key) => {
                                                                            return (
                                                                                <div className="row" key={key}>
                                                                                    <div className="col-sm-12">
                                                                                        <div
                                                                                            className="form-check-inline">
                                                                                            <label
                                                                                                className="form-check-label text-center"
                                                                                                htmlFor="radio1">
                                                                                                <input type="radio"
                                                                                                    onChange={this.change}
                                                                                                    className="form-check-input"
                                                                                                    name="availed_result"
                                                                                                    value={btn.value}
                                                                                                    checked={formData.availed_result === btn.value.toString()} />{btn.label}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            )
                                                                        })
                                                                        }
                                                                        {error &&
                                                                            <span style={{ "color": "red" }}>{error}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-sm-5 text-right">
                                                                        <label>{this.props.t('feedback.feedback', { param: 'react' })} : <span
                                                                            className="ml-2"
                                                                            style={{ "color": "red" }}>*</span></label>
                                                                    </div>

                                                                    <div className="col-sm-5">
                                                                        <textarea className="w-100"
                                                                            onChange={this.change}
                                                                            value={formData.feedback ? formData.feedback : ''}
                                                                            placeholder={"Enter feedback"}
                                                                            style={{ "height": "200px !important" }}
                                                                            id="date" name="feedback" rows="4"
                                                                            cols="8" />
                                                                        {validator.message('feedback', formData.feedback, 'required')}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-sm-5 text-right"></div>

                                                                    <div className="col-sm-5">
                                                                        <button type="submit"
                                                                            className="btn btn-primary btn-primary2 "
                                                                            style={{ "marginRight": "10px" }}>{this.props.t('feedback.submit', { param: 'react' })}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(PortalFeedback)));
