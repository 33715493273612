import React, { Component } from 'react'
import { get_User_District, get_education, get_skills } from "../actions/accountAction";
import { getOrganizationType, getAdvanceSearchResults, getJoinByDays } from "../actions/jobseekerAction";
import {
  get_functional_area, get_Sector, get_genders, get_job_shift,
  getCategory, get_User_Coursenature, get_Coursenature
} from "../actions/jobpostAction";
import store from "../store";
import { translate } from 'react-multi-lang'
import { connect } from 'react-redux';

class AdvanceSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      modalState: false
    }
    store.dispatch(getOrganizationType());
    store.dispatch(get_functional_area());
    store.dispatch(get_Sector());
    store.dispatch(get_genders());
    store.dispatch(get_job_shift());
    store.dispatch(getCategory());
    store.dispatch(getJoinByDays());
    store.dispatch(get_User_Coursenature());
    store.dispatch(get_User_District());
    store.dispatch(get_education());
    store.dispatch(get_skills());
    store.dispatch(get_Coursenature());
  }

  handleProfileInput = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let formData = this.state.formData;
    formData[name] = value;
    await this.setState({ formData: formData });


    if (Object.keys(this.state.formData).length > 3 && !this.state.modalState) {
      const neededKeys = ['education_level', 'location', 'experience'];
      if (neededKeys.every(key => Object.keys(this.state.formData).includes(key))) {
        alert('If you select more fields then it will result in less number of search results')
        this.setState({ modalState: true })
      }

    }
  }

  createDistoption = () => {
    let Mainprops = this.props;
    let Distoption = []
    if (Mainprops.actionReducer.District.length > 0) {
      Mainprops.actionReducer.District.map(function (data, index) {
        if (data.state_name === 'Punjab') {
          data.dist.map(function (distdata, distindex) {
            Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
            return Distoption
          })
        }
        return Distoption
      })
    }
    return Distoption
  }

  submitAdvanceSearch = async (e) => {
    e.preventDefault();
    await store.dispatch(getAdvanceSearchResults(this.state.formData));
    this.props.router.push('search-results')
  }

  render() {
    const Mainprops = this.props
    return (
      <div className="tab-content">
        <div className="modal fade" id="modalDesc" tabIndex="-1" role="dialog" aria-labelledby="basicModal"
          aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header" style={{
                'background': 'linear-gradient(to right,#003980 0%,#5265ce 50%,#003980 100%)',
                'color': '#fff'
              }}>
                <h4 className="modal-title"
                  id="myModalLabel">{this.props.t('footer.ggr', { param: 'react' })}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body p-5">
                <p>If you select more fields then it will result in less number of search results.</p>
              </div>

            </div>
          </div>
        </div>
        <div id="home" className=" tab-pane active">
          <section className="login-form mt-2">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                  <div className="panel-heading">
                    <h6 className="mb-0">Search Jobs</h6>
                  </div>
                  <div className="panel-body">
                    <div className="basic"></div>
                    <form onSubmit={this.submitAdvanceSearch}>
                      <section className="advance-body">
                        <h6 className="mb-0">Advance Search</h6>
                        <hr className="mt-2" />
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-2 text-right">
                              <label>Education </label>
                            </div>
                            <div className="col-sm-9">
                              <select required className="form-control"
                                id="higher_edu" onChange={this.handleProfileInput}
                                name="education_level">
                                <option value="">Select</option>
                                {Mainprops.actionReducer.Educations.length > 0 &&
                                  Mainprops.actionReducer.Educations.map(function (data, index) {
                                    return (
                                      <option key={index}
                                        value={data.id}>{data.name}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-2 text-right">
                              <label>Location </label>
                            </div>
                            <div className="col-sm-9">
                              <select required name="location" className="form-control"
                                onChange={this.handleProfileInput}>
                                <option value="">Select District</option>
                                {this.createDistoption()}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-2 text-right">
                              <label>Experience </label>
                            </div>
                            <div className="col-sm-9">

                              <input required type="number"
                                defaultValue={this.state.formData.experience ? this.state.formData.experience : ''}
                                className="form-control"
                                onChange={this.handleProfileInput} name="experience"
                                placeholder="1" min="0" />
                            </div>

                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-2 text-right">
                              <label>Skills </label>
                            </div>
                            <div className="col-sm-9">
                              <select required className="form-control"
                                id="key_skills" onChange={this.handleProfileInput}
                                name="key_skills">
                                <option value="">Select</option>
                                {Mainprops.actionReducer.userSkills.length > 0 &&
                                  Mainprops.actionReducer.userSkills.map(function (data, index) {
                                    return (
                                      <option key={index}
                                        value={data.id}>{data.name}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">

                          </div>
                        </div>

                        <div className="row">

                          <div className="col-md-6 ">
                            <div className="row">
                              <div className="col-sm-4 text-right">
                                <label>Job Nature</label>
                              </div>
                              <div className="col-sm-8 col-lg-8 form-group">
                                <select required onChange={this.handleProfileInput}
                                  defaultValue={this.state.formData.nature_of_job ? this.state.formData.nature_of_job : ''}
                                  className="form-control" id="course_nature_id"
                                  name="nature_of_job">
                                  <option value="">Select Job Nature</option>
                                  {Mainprops.actionReducer.Coursenature.length > 0 &&
                                    Mainprops.actionReducer.Coursenature.map(function (data, index) {
                                      return (
                                        <option key={index}
                                          value={data.id}>{data.name}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 ">
                            <div className="row">
                              <div className="col-sm-2 text-right">
                                <label>Age</label>
                              </div>
                              <div className="col-sm-8 col-lg-8 form-group">
                                <input required type="number"
                                  defaultValue={this.state.formData.age ? this.state.formData.age : ''}
                                  className="form-control"
                                  onChange={this.handleProfileInput} name="age"
                                  placeholder="1" min="0" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 ">
                            <div className="row">
                              <div className="col-sm-4 text-right">
                                <label>Gender</label>
                              </div>
                              <div className="col-sm-8 col-lg-8 form-group ">
                                <select required
                                  defaultValue={this.state.formData.gender_preference ? this.state.formData.gender_preference : ''}
                                  onChange={this.handleProfileInput}
                                  className="form-control" id="course_nature_id"
                                  name="gender_preference">
                                  <option value="">Select Gender</option>
                                  {Mainprops.JobpostReducer.Genders.length > 0 &&
                                    Mainprops.JobpostReducer.Genders.map(function (data, index) {
                                      return (
                                        <option key={index}
                                          value={data.id}>{data.name}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>

                            </div>
                          </div>
                          <div className="col-md-6 ">
                            <div className="row">
                              <div className="col-sm-2 text-right">
                                <label>Casts</label>
                              </div>
                              <div className="col-sm-8 col-lg-8 form-group ">
                                {Mainprops.JobpostReducer.category && Mainprops.JobpostReducer.category.map((cat, index) => {
                                  return (
                                    <div className="form-check-inline" key={index}>
                                      <label
                                        className="form-check-label text-center"
                                        htmlFor="radio1">
                                        <input onClick={this.handleProfileInput}
                                          required
                                          defaultChecked={this.state.formData.category ? this.state.formData.category : ''}
                                          type="radio"
                                          className="form-check-input"
                                          id="radio2" name="category"
                                          value={cat.id} /><p>{cat.name}</p>
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6 ">
                              <div className="row">
                                <div className="col-sm-4 text-right">
                                  <label>For Ex-Serviceman</label>
                                </div>
                                <div className="col-sm-8 form-group">
                                  <select onClick={this.handleProfileInput}
                                    className="form-control" id="sel1"
                                    name="ex_servicemen">
                                    <option>--Select --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                  </select>
                                </div>
                              </div>

                            </div>
                            <div className="col-md-6 ">
                              <div className="row">
                                <div className="col-sm-2 text-right">
                                  <label>Differently abled</label>
                                </div>
                                <div className="col-sm-8 form-group">
                                  <select onClick={this.handleProfileInput}
                                    className="form-control" id="sel1"
                                    name="differently_abled">
                                    <option>--Select --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                  </select>
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>

                        <hr />
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-2 text-right"></div>
                            <div className="col-sm-9 ">
                              <div className="pull-right">
                                <button type="submit"
                                  className="btn btn-primary btn-primary2 mt-1">Search
                                                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return store;
};

export default connect(mapStateToProps)(translate(AdvanceSearch));