import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { browserHistory } from 'react-router'

class Employment extends Component {
    
    componentDidMount(){
        document.title = this.props.t('homeContent.employment', { param: 'react' })
    }

    render() {
        return (
            <div className="tab-content" style={{ "border": "none" }}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">DBEE Helpline No.
                                        <span className="pull-right" onClick={browserHistory.goBack} style={{color:"#fff", cursor:"pointer"}}>{this.props.t('jobSearch.goBack', { param: 'react' })}</span></h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <div className="row">
                                        
                                            <div className="col-md-12">
                                            <img style={{"marginLeft":"13%"}} width="75%" src="./images/helpline-nos.jpeg" 
                                            alt="Employment"/>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Employment));
