import React, { Component } from 'react'
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { Link } from 'react-router'
import { translate } from 'react-multi-lang'
import { applyForeignCounselling, getForeignCounsellings } from '../../../actions/jobseekerAction';

class ForeignCounsellingDetails extends Component {

    applyCounselling = () => {
        store.dispatch(applyForeignCounselling())
    }

    componentDidMount() {
        document.title = this.props.t('counsellingProvider.foreignCounselling', { param: 'react' })
        store.dispatch(getForeignCounsellings())
    }

    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const Mainprops = this.props

        const data = Mainprops.JobseekerReducer.foreignCounselling

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="tab-content">
                                <div id="home" className=" tab-pane active">
                                    <section className="login-form mt-3">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('counsellingProvider.foreignCounselling', { param: 'react' })}</h6>
                                                    </div>
                                                    <div className="panel-body mt-2">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                {!data.applied ? <h5>Do you want to apply for Foreign Counselling  <button className="btn btn-primary btn-primary2 pull-right" onClick={this.applyCounselling}>Apply</button> </h5> : null}
                                                                {data.applied ? <p className="h5">You have applied for the Foreign Counselling. You will find the Foreign Counselling links below:</p> : null}
                                                                {data.applied ?
                                                                    <>
                                                                        {data.links && data.links.length ? data.links.map((link, key) => {
                                                                            return (<a href={link}
                                                                                rel="noopener noreferrer"
                                                                                className="mb-2 date-clr h6 d-block"
                                                                                key={key} target="_blank">{link}
                                                                            </a>)
                                                                        })
                                                                            : <h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6>}
                                                                    </>
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(ForeignCounsellingDetails));