import React, { Component } from 'react'
import store from '../../store';
import { connect } from 'react-redux';
import { getAreaOfInterest } from '../../actions/counsellingproviderAction';
import {  postSkilldevelopmentSearch, getSkilldevelopmentCourses,getsectorlist,getdistrictlist,getAllskilllist,getskillcourselist} from '../../actions/jobseekerAction';
import ServerSetting from '../../config.json';
import {applySkillcourse} from '../../actions/accountAction';
import Pagination from "react-js-pagination";
import {
    Islogin
} from "../../actions/accountAction";
import { chunk } from '../../helpers'
import { getTimeWithAmPm } from '../../helpers'
import { Link } from 'react-router'
import { translate } from 'react-multi-lang'


const RESULT_PER_PAGE = 25

class SearchSkillResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: [],

            activePage: 1,
            submitted: false,
            disabledButton:[],
            hide:"",

        }

        if (localStorage.getItem('Islogin') === 'true' && localStorage.getItem('Islogin')) {
            store.dispatch(Islogin());
        }


        store.dispatch(getAreaOfInterest())
        store.dispatch(applySkillcourse())
        store.dispatch(getSkilldevelopmentCourses())
        store.dispatch(getskillcourselist())
        store.dispatch(getsectorlist())
        store.dispatch(getdistrictlist())
        store.dispatch(getAllskilllist())
        
      


    }

    submit = (e) => {
        e.preventDefault()
        if (!this.state.submitted) {
            this.setState({ activePage: 1 })
            
            store.dispatch( postSkilldevelopmentSearch(this.state.formData, true));
                this.setState({hide:"none"})
        
            this.setState({ submitted: true })
        }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    componentDidMount() {
        document.title = 'Seach Skill Result';
     

       
    }



    handleInput = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
    
    //    const user_id ="user_id";
        let formData = this.state.formData;
        formData[name] = value;
        console.log(this.state.formData);
        store.dispatch( getskillcourselist(this.state.formData));
        // formData[user_id]=localStorage.getItem('user_id')
      
        if (!value) {
            delete formData[name]
        }
        await this.setState({ formData, submitted: false });
    }

    
    applyskillcourse(e,index,start,end){
        
        if (localStorage.getItem('Islogin') != 'true') {
            localStorage.setItem('course_id',e.target.value);
            localStorage.setItem('course_startdate',start);
            localStorage.setItem('course_enddate',end);

            this.props.router.push('/signin');
        }else{

        var arr = this.state.disabledButton.slice();
        arr.push(index);

        this.setState({
            
              disabledButton: arr
            })
            console.log(this.state.disabledButton[index])
        const course=e.target.value;
        const startdate=start
        const enddate=end
        const user_id=localStorage.getItem('user_id');
        const value={user_id:user_id,course_id:course,status:1,start_date:startdate,end_date:enddate};
        store.dispatch(applySkillcourse(value))
        if(course) {
        this.setState({
            isDisabled: true 
        })
    }
    }
      
    }



    componentWillUnmount() {
    
    
    }

    getAreas = list => {
        const areaList = []
        list.split(',').forEach((ar) => {
            const filterList = this.props.counsellingproviderReducer.area_of_interest.filter((res) => res.id === ar)
            if (filterList && filterList[0]) {
                areaList.push(filterList[0].name)
            }
        })
        return areaList.join(',')
    }

  


    render() {
      
        
        const Mainprops = this.props
        const { activePage, formData } = this.state
        const searchResults = chunk(Mainprops.JobseekerReducer.skilldevelopmentResult, RESULT_PER_PAGE)
        const searchallResults = chunk(Mainprops.JobseekerReducer.Allskilllist, RESULT_PER_PAGE)
        // console.log(searchallResults);

        // console.log(this.props.JobseekerReducer.displaydistrict);
   
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                     
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>


                            <div>
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-3">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                        <h6 className="mb-0"><span>Skill Development Courses</span>
                                                        <span class="float-right p-0 mt-0" ><a href="/aboutus"><u style={{'color':'#000', fontSize:'12px'}}>Home-Skill Development</u></a></span></h6>
                                                        </div>
                                                        <div className="panel-body mt-2">
                                                            <form onSubmit={this.submit}>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 col-md-4  col-4 " style={{"textAlign":"center"}}>
                                                                            <label >Sector</label>
                                                                        {/* </div> */}
                                                                        {/* <div className="col-sm-4 col-md-4  col-4" style={{"padding": "1px 70px 3px 100px"}}> */}

                                                                            <select onChange={this.handleInput}
                                                                                value={formData.sector_id ? formData.sector_id : ''}
                                                                                className="form-control" name="sector_id">
                                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' })}</option>
                                                                                {this.props.JobseekerReducer.sectorresultlist.length > 0 &&
                                                                                    this.props.JobseekerReducer.sectorresultlist.map(function (data, key) {
                                                                                        return (
                                                                                            <option value={data.sector_id} key={key}>{data.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>

                                                                        <div className="col-sm-12 col-md-4  col-4 " style={{"textAlign":"center"}}>
                                                                            <label>Job Role</label>
                                                                        {/* </div> */}

                                                                        {/* <div className="col-sm-4 col-md-4  col-4" style={{"padding": "1px 70px 3px 100px"}}> */}
                                                                            <select onChange={this.handleInput}
                                                                                value={formData.course_id ? formData.course_id : ''}
                                                                                className="form-control" name="course_id">
                                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' })}</option>
                                                                                {this.props.JobseekerReducer.courseresultlist.length > 0 &&
                                                                                    this.props.JobseekerReducer.courseresultlist.map(function (data, key) {
                                                                                        return (
                                                                                            <option value={data.course_id} key={key}>{data.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>

                                                                        <div className="col-sm-12 col-md-4  col-4 " style={{"textAlign":"center"}}>
                                                                            <label>District</label>
                                                                        {/* </div> */}
                                                                      
                                                                        {/* <div className="col-sm-4 col-md-4  col-4" style={{"padding": "1px 70px 3px 100px"}}> */}
                                                                            <select onChange={this.handleInput}
                                                                                value={formData.district_id ? formData.district_id : ''}
                                                                                className="form-control" name="district_id">
                                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' })}</option>
                                                                                {this.props.JobseekerReducer.displaydistrict.length > 0 &&
                                                                                    this.props.JobseekerReducer.displaydistrict.map(function (data, key) {
                                                                                        return (
                                                                                            <option value={data.id} key={key}>{data.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 ">
                                                                            <button type="submit"
                                                                                className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                                {this.props.t('jobSearch.search', { param: 'react' })}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                {Mainprops.JobseekerReducer.skilldevelopmentResult.length > 0 &&
                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}
                                                                    {/* <span className="pull-right" style={{ "color": "#fff" }}>
                                                                        {Mainprops.JobseekerReducer.skilldevelopmentResult && Mainprops.JobseekerReducer.skilldevelopmentResult.length ?
                                                                            Mainprops.JobseekerReducer.skilldevelopmentResult.length : 0}{this.props.t('JobPost.foundItems', { param: 'react' })}</span> */}
                                                                </h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((skill,index,key) => {
                                                                    return (
                                                                      
                                                                        <div className="right-sidebar" key={key}>
                                                                            <div className="first-job mb-3 p-3">
                                                                           <button  className='float-right mx-auto d-block btn btn-primary btn-primary2 mt-1' value={skill.id} onClick={e => this.applyskillcourse(e,index,skill.start_date,skill.end_date)}   disabled={this.state.disabledButton[index] == index ? true :false}>{this.state.disabledButton[index]== index ?"Applied":"Apply"}</button>

                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <h4 className="company-name">
                                                                                            
                                                                                            <div className="outr-avtar">
                                                                                                <img alt="avatar"
                                                                                                    src='/images/images.jpg'
                                                                                                    className="avatar mr-2 text-center" />
                                                                                            </div> 
                                                                                        </h4>
                                                                                        <ul className="service-li" style={{ marginLeft: "18%" }}>

                                                                                        
                                                                                        {skill.sector ?  
                                                                                            <li className="pr-5" style={{'fontSize':'18px'}}>  <b>SECTOR</b>: {skill.sector }</li>: null }

                                                                                            {skill.course ?  
                                                                                            <li className="pr-5">  <b>JOB ROLE/TRADE</b>: {skill.course }</li>: null }
                                                                                             {/* {skill.skill_name ?  
                                                                                            <li className="pr-5">  <b>Batch Name</b>: {skill.skill_name }</li>: null } */}
                                                                                            <hr/>
                                                                                            <li className='pr-5' style={{'fontSize':'15px'}}>
                                                                                            <div class="col-md-7" style={{float:"left"}}>   <b >Training Partner</b>:{skill.tp_name} </div>
                                                                                            {/* <b >Training Center</b>: {skill.tc_name}  */}
                                                                                            <div class="col-md-3" style={{float:"left"}}>  <b >District</b>: {skill.district} </div> </li>
                                                                                            
                                                                                        {skill.education ?
                                                                                            <li className="pr-5" style={{'fontSize':'15px'}}>
                                                                                                
                                                                                                 <div class="col-md-7" style={{float:"left"}}> <b >Qualification</b>: {skill.education } </div>
                                                                                                
                                                                                                <div class="col-md-3" style={{float:"left"}}> <b > Duration (hr):  </b>  {skill.duration} </div>
                                                                                                
                                                                                            </li> : null} 

                                                                                        {skill.strength ?
                                                                                            <li className="pr-5" style={{'fontSize':'15px'}}>
                                                                                                
                                                                                                <div class="col-md-7" style={{float:"left"}}> <b >Batch Strength:  </b> {skill.strength} Candidates</div>
                                                                                            </li> : null} 
                                                                                        {/* {skill.skill_name ?
                                                                                                <li className="pr-5">
                                                                                                    <b>Batch Name</b>: {skill.skill_name}
                                                                                                </li> : null}
                                                                                        <li className="pr-5">
                                                                                            <b > Duration (hr):  </b> {skill.duration} 
                                                                                            <b style={{'margin-left':'40%'}}>Batch Strength:  </b> {skill.strength} Candidates
                                                                                        </li>  */}
                                                                                     
                                                                                           
                                                                                            
                                                                                        
                                                                                        {/* {skill.tp_name ?
                                                                                            <li className="pr-5">
                                                                                                <b >Training Partner</b>: {skill.tp_name}
                                                                                                <b >Training Center</b>: {skill.tc_name}
                                                                                            </li> : null} */}
                                                                                        {/* {skill.tc_name ?
                                                                                            <li className="pr-5">
                                                                                                <b >Training Center</b>: {skill.tc_name}
                                                                                            </li> : null} */}
                                                                                           
                                                                                           
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {searchResults && searchResults.length > 1 ? <div className='react-pagination mt-2'>
                                                                    <Pagination
                                                                        pageRangeDisplayed={10}
                                                                        activePage={activePage}
                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                        totalItemsCount={Mainprops.JobseekerReducer.skilldevelopmentResult.length}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}
                                                                <div className="register-bar">
                                                                    {/* <Link className="nav-link registered" to="signup?type=1"><i className="fa fa-registered" aria-hidden="true"></i></Link> */}
                                                                    <Link className="nav-link registered" to="signup?type=1"><span style={{background:"rgb(237 144 23)", padding:"9px 7px 8px 6px" ,fontWeight:"900" ,borderRadius:"10px",fontSize:"10px"}}>REGISTER FOR SKILL TRAINING</span></Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}

                                    {searchResults.length == 0?
                                    <div className="tab-content" style={{'display':this.state.hide}}>
                                        <div id="home" className=" tab-pane active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}
                                                                    {/* <span className="pull-right" style={{ "color": "#fff" }}>
                                                                        {Mainprops.JobseekerReducer.skilldevelopmentallResult && Mainprops.JobseekerReducer.skilldevelopmentallResult.length ?
                                                                            Mainprops.JobseekerReducer.skilldevelopmentallResult.length : 0}{this.props.t('JobPost.foundItems', { param: 'react' })}</span> */}
                                                                </h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                {searchallResults && searchallResults.length > 0 && searchallResults[activePage - 1].map((skill,index,key) => {
                                                                    return (
                                                                       
                                                                        <div className="right-sidebar" key={key}>
                                                                            <div className="first-job mb-3 p-3">
                                                                <button  className='float-right mx-auto d-block btn btn-primary btn-primary2 mt-1' value={skill.id} onClick={e => this.applyskillcourse(e,index,skill.start_date,skill.end_date)}   disabled={this.state.disabledButton[index] === index ? true : false}>{this.state.disabledButton[index]== index ?"Applied":"Apply"}</button>

                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <h4 className="company-name">
                                                                                         
                                                                                            <div className="outr-avtar">
                                                                                                <img alt="avatar"
                                                                                                    src='/images/images.jpg'
                                                                                                    className="avatar mr-2 text-center" />
                                                                                            </div> 
                                                                                        </h4>
                                                                                        <ul className="service-li" style={{ marginLeft: "18%" }}>

                                                                                        
                                                                                        {skill.sector ?  
                                                                                            <li className="pr-5" style={{'fontSize':'18px'}}>  <b>SECTOR</b>: {skill.sector }</li>: null }

                                                                                        {skill.course ?  
                                                                                        <li className="pr-5">  <b>JOB ROLE/TRADE</b>: {skill.course }</li>: null }
                                                                                        {/* {skill.skill_name ?  
                                                                                        <li className="pr-5">  <b>Batch Name</b>: {skill.skill_name }</li>: null } */}
                                                                                        <hr/>
                                                                                        <li className='pr-5' style={{'fontSize':'15px'}}>
                                                                                        <div class="col-md-7" style={{float:"left"}}>  <b >Training Partner</b>:  {skill.tp_name} </div> 
                                                                                        {/* <b >Training Center</b>: {skill.tc_name}  */}
                                                                                        <div class="col-md-3" style={{float:"left"}}> <b>District</b>:   {skill.district} </div> </li>
                                                                                            
                                                                                        {skill.education ?
                                                                                            <li className="pr-5" style={{'fontSize':'15px'}}>
                                                                                                 {/* <b style={{color:"#ed9017"}}>Qualification Required</b> <br/> */}
                                                                                                 <div class="col-md-7" style={{float:"left"}}>   <b >Qualification</b>:  {skill.education } </div>
                                                                                                {/* <b>Batch Name</b>: {skill.skill_name} */}
                                                                                                <div class="col-md-3" style={{float:"left"}}>  <b> Duration (hr): </b>    {skill.duration} </div>
                                                                                               
                                                                                            </li> : null} 
                                                                                        
                                                                                        {skill.strength ?
                                                                                            <li className="pr-5" style={{'fontSize':'15px'}}>
                                                                                                 <div class="col-md-5" style={{float:"left"}}>  <b >Batch Strength:  </b>  {skill.strength} Candidates </div>
                                                                                                
                                                                                            </li> : null} 
                                                                                        {/* {skill.skill_name ?
                                                                                                <li className="pr-5">
                                                                                                    <b>Batch Name</b>: {skill.skill_name}
                                                                                                </li> : null}
                                                                                        <li className="pr-5">
                                                                                            <b > Duration (hr):  </b> {skill.duration} 
                                                                                            <b style={{'margin-left':'40%'}}>Batch Strength:  </b> {skill.strength} Candidates
                                                                                        </li>  */}
                                                                                     
                                                                                           
                                                                                            
                                                                                        
                                                                                        {/* {skill.tp_name ?
                                                                                            <li className="pr-5">
                                                                                                <b >Training Partner</b>: {skill.tp_name}
                                                                                                <b >Training Center</b>: {skill.tc_name}
                                                                                            </li> : null} */}
                                                                                        {/* {skill.tc_name ?
                                                                                            <li className="pr-5">
                                                                                                <b >Training Center</b>: {skill.tc_name}
                                                                                            </li> : null} */}
                                                                                           
                                                                                           
                                                                                        </ul>
                                                                                        
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {searchallResults && searchallResults.length > 1 ? <div className='react-pagination mt-2'>
                                                                    <Pagination
                                                                        pageRangeDisplayed={10}
                                                                        activePage={activePage}
                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                        totalItemsCount={Mainprops.JobseekerReducer.skilldevelopmentallResult.length}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}
                                                               

                                                            </div>
                                                            <div className="register-bar">
                                                                {/* <Link className="nav-link registered" to="signup?type=1"><i className="fa fa-registered" aria-hidden="true"></i></Link> */}
                                                                <Link className="nav-link registered" to="signup?type=1"><span style={{background:"rgb(237 144 23)", padding:"9px 7px 8px 6px" ,fontWeight:"900" ,borderRadius:"10px",fontSize:"10px"}}>REGISTER FOR SKILL TRAINING</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>:""}
                                 
                                {Mainprops.JobseekerReducer.skilldevelopmentResult.status === '401' && <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}
                                                                <span className="pull-right" style={{ "color": "#fff" }}>
                                                                    {Mainprops.JobseekerReducer.skilldevelopmentResult && Mainprops.JobseekerReducer.skilldevelopmentResult.length ?
                                                                        Mainprops.JobseekerReducer.skilldevelopmentResult.length : 0}{this.props.t('JobPost.foundItems', { param: 'react' })}</span></h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            <h3>{Mainprops.JobseekerReducer.skilldevelopmentResult.message}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>}

                            </div>

                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(SearchSkillResult));