import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'

class CopyRight extends Component {

    componentDidMount() {
        document.title = this.props.t('copyright.title', {param: 'react'})
    }
    
    render() {
        return (
            <section className="login-form mt-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12  mx-auto  cover-shadow border-0 mt-3 tab-content  p-0">
                            <div className="panel-heading">
                                <h6 className="mb-0">{this.props.t('copyright.title', { param: 'react' })}</h6>
                            </div>
                            <div className="panel-body">
                                <p>
                                    {this.props.t('copyright.content', { param: 'react' })}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(CopyRight));
