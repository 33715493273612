import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Pagination from "react-js-pagination";
import { chunk } from '../../helpers'
import { Link } from 'react-router'
import store from "../../store"
import { setRedirectionLink, getEducationLevel, get_User_Subistrict, get_User_District } from '../../actions/accountAction'
import {
    setSelfEmploymentSearchResults, postSelfEmploymentSearch
} from
    '../../actions/jobseekerAction';
import { getAllDistricts } from '../../actions/accountAction'
import { get_sector_self_employment } from '../../actions/jobpostAction'
import ServerSetting from '../../config.json'

const RESULT_PER_PAGE = 25

class SelfEmployment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            formData: [],
            submitted: false,
            job: [],
            
        }

        store.dispatch(getEducationLevel());
        store.dispatch(getAllDistricts())
        store.dispatch(get_sector_self_employment())
        store.dispatch(postSelfEmploymentSearch({ ...this.state.formData }))
        store.dispatch(get_User_District())
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map(function (data) {
                if (data.state_id === '3') {
                    data.dist.map(function (distdata, distindex) {
                        if (distindex > 0) {
                            Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
                            return Distoption
                        }
                        return ''
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }

    componentDidMount() {
        document.title = this.props.t('homeContent.selfEmp', { param: 'react' })
    }

    submitSearch = (e) => {
        e.preventDefault()
        if (!this.state.submitted) {
            store.dispatch(postSelfEmploymentSearch({ ...this.state.formData }));
            this.setState({ submitted: true })
        }
    }

    handleInputs = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        if (name === 'district_id') {
            store.dispatch(get_User_Subistrict(value));
            delete formData["subdistrict_id"]
        }
        if (!value) {
            delete formData[name]
        } else {
            formData[name] = value
        }
        this.setState({ formData, submitted: false });
    }

    getToSelfEmp = () => {
        if (this.props.actionReducer.Loginuser && this.props.actionReducer.Loginuser.id) {
            this.props.router.push(`search-self-employment`)
        } else {
            store.dispatch(setRedirectionLink(`search-self-employment`))
            this.props.router.push("/signin")
        }
    }

    componentWillUnmount() {
        store.dispatch(setSelfEmploymentSearchResults([]))
    }

    redirect = () => {
        document.getElementById("close-btn").click()
    }

    render() {
        const searchResult = chunk(this.props.JobseekerReducer.selfEmployment, RESULT_PER_PAGE)
        const Mainprops = this.props
        const { activePage, job, formData } = this.state

        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        return (
            <div>
                <section className="total-job">
                    <div className="container">
                        <div className={`row`}>
                            <div className="modal fade" id="descriptionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <h6>{this.props.t('counsellingProvider.details', { param: 'react' })}</h6>
                                            {job.projects_covered ? <p>{this.props.t('selfEmployment.Project', { param: 'react' })}: {job.projects_covered}</p> : ''}
                                            {job.education_level ? <p>{this.props.t('selfEmployment.Education Level', { param: 'react' })} : {job.education_level}</p> : ''}
                                            {job.qualification ? <p>{this.props.t('selfEmployment.Qualification', { param: 'react' })}  : {job.qualification}</p> : ''}
                                            {job.training_required ? <p>{this.props.t('selfEmployment.Training', { param: 'react' })}: {job.training_required}</p> : ''}
                                            {job.experience ? <p>{this.props.t('selfEmployment.Experience (In Years)', { param: 'react' })} : {job.experience}</p> : ''}
                                            {job.collateral_required ? <p>{this.props.t('selfEmployment.Can you offer collateral', { param: 'react' })}: {job.collateral_required && job.collateral_required === "1" ? "Yes" : "No"}</p> : ''}
                                            {job.defaulter ? <p>{this.props.t('selfEmployment.Have you defaulted against a loan in past', { param: 'react' })}: {job.defaulter && job.defaulter === "1" ? "Yes" : "No"}</p> : ''}
                                            {job.collateral_required ? <p>{this.props.t('selfEmployment.Can you offer collateral', { param: 'react' })}: {job.collateral_required && job.collateral_required === "1" ? "Yes" : "No"}</p> : ''}
                                            {job.loan_type ? <p>{this.props.t('selfEmployment.Type of Loan', { param: 'react' })}: {job.loan_type}</p> : ''}
                                            {job.loan_purpose ? <p>{this.props.t('selfEmployment.Purpose of Loan', { param: 'react' })}: {job.loan_purpose}</p> : ''}
                                            {job.repaying_period ? <p>{this.props.t('selfEmployment.Repaying Period', { param: 'react' })}: {job.repaying_period}</p> : ''}
                                            {job.family_income ? <p>{this.props.t('selfEmployment.Annual Family Income (In INR)', { param: 'react' })}: {job.family_income} </p> : ''}
                                            {job.subdistrict_id ? <p>{this.props.t('selfEmployment.Block Name', { param: 'react' })}: {job.subdistrict_id}</p> : ''}
                                            {job.description ? <p>{this.props.t('dashboard.table.description', { param: 'react' })}: {job.description}</p> : ''}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">
                                                {this.props.t('counsellingProvider.close', { param: 'react' })}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="serviceModal" style={{ 'zIndex': '99999' }}>
                                <div className="modal-dialog modal-dialog-centered modal-lg" >
                                    <div className="modal-content d-block">
                                        <button type="button" style={{ display: "none" }} className="close" id="close-btn" data-dismiss="modal">&times;</button>
                                        <div className="modal-body pt-2  pb-1 text-center">
                                            <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                            <h5 className="mx-auto  text-uppercase mb-3">{this.props.t('homeContent.pgrkamTitle', { param: 'react' })}</h5>
                                            <div className="row d-flex">
                                                <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex ">
                                                    <div className="first-click">
                                                        <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                                                        <p> {this.props.t('homeContent.inst1', { param: 'react' })}</p>
                                                        <a href="/signin" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickToLogin', { param: 'react' })}</a>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                               
                                                    <div className="first-click">
                                                        <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                                                        <p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst2', { param: 'react' })}</p>
                                                        <a href="/signup?type=1" className="btn btn-danger pt-1 pb-1" >{this.props.t('homeContent.clickToRegister', { param: 'react' })}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form className="pb-0" onSubmit={this.submitSearch}>
                                <div className="col-lg-12 col-xl-12 mx-auto mt-5 mb-3">
                                    <div className="row ml-1">
                                        <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                                            <select
                                                value={formData.district_id ? formData.district_id : ""}
                                                className="search-slt"
                                                name="district_id"
                                                onChange={(e) => this.handleInputs(e)}>
                                                <option value="">{this.props.t('forms.selectDis', { param: 'react' })}</option>
                                                {this.createDistoption()}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                                            <select
                                                value={formData.subdistrict_id ? formData.subdistrict_id : ""}
                                                className="search-slt"
                                                name="subdistrict_id"
                                                onChange={(e) => this.handleInputs(e)}>
                                                <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('profileDetails.subdistrict', { param: 'react' })}</option>
                                                {this.props.actionReducer.Subdistrict && this.props.actionReducer.Subdistrict.length ?
                                                    this.props.actionReducer.Subdistrict.map((sub, key) => {
                                                        return <option key={key} value={sub.id}>{sub.name}</option>
                                                    })
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                                            <select
                                                value={formData.education_level ? formData.education_level : ''}
                                                onChange={this.handleInputs}
                                                className="search-slt"
                                                name="education_level">
                                                <option value="">{this.props.t('forms.selectEdu', { param: 'react' })}
                                                </option>
                                                {Mainprops.actionReducer.educationLevel.length > 0 &&
                                                    Mainprops.actionReducer.educationLevel.map(function (data, index) {
                                                        return (
                                                            <option
                                                                value={data.education_id}
                                                                key={index}>{data.education_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                                            <button type="submit" className="btn text-white wrn-btn">{this.props.t('jobSearch.search', { param: 'react' })}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>

                <section className="job-details">
                    <div className="container">
                        <div className="row">
                            <div className={`col-md-12 col-sm-12 col-lg-12 right-sidebar-scroll`}>
                                {this.props.JobseekerReducer.selfEmployment && this.props.JobseekerReducer.selfEmployment.status === '401' &&
                                    <div className="right-sidebar"><h5 className="first-job mb-3 p-3">
                                        {this.props.t('JobPost.notFound', { param: 'react' })}</h5></div>}

                                {searchResult && searchResult.length ?
                                    searchResult[activePage - 1].map((job, key) => {
                                        return (
                                            <div className="right-sidebar" key={key}>
                                                <div className="first-job mb-3 p-3">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <h6 className="pt-1 company-name2 pb-1 mb-0 "><Link>
                                                                {this.props.t('selfEmployment.name', { param: 'react' })}: {job.scheme_name}</Link></h6>

                                                            <div className="row w-100">
                                                                <div className="col-md-6 mb-1">
                                                                    <span className="pr-2 date-clr">{this.props.t('selfEmployment.Margin Money (In %)', { param: 'react' })}: <span>
                                                                        {job.margin_min + "-" + job.margin_max} </span></span>
                                                                </div>
                                                                <div className="col-md-6 mb-1">
                                                                    <span className="pr-2 date-clr">{this.props.t('selfEmployment.Loan Amount (In INR)', { param: 'react' })} : <span>
                                                                        {job.loan_amount_min + "-" + job.loan_amount_max}</span></span>
                                                                </div>
                                                            </div>
                                                            <div className="row w-100">
                                                                <div className="col-md-6 mb-1">
                                                                    <span className="pr-2 date-clr">{this.props.t('selfEmployment.Subsidy Component (In %)', { param: 'react' })} : <span>
                                                                        {job.subsidy ? job.subsidy : '0'}</span></span>
                                                                </div>
                                                                <div className="col-md-6 mb-1">
                                                                    <span className="pr-2 date-clr">{this.props.t('selfEmployment.Whether collateral free loan', { param: 'react' })} : <span>
                                                                        {job.collateral_free && job.collateral_free === "1" ? "Yes" : "No"}</span></span>
                                                                </div>
                                                            </div>
                                                            <div className="row w-100">
                                                                <div className="col-md-4 mb-1">
                                                                    <span className="pr-2">
                                                                    {job.checklist ?
                                                                        <a rel="noopener noreferrer" target="_blank" style={{ color: "#007bff" }} href={`${SERVER_PATH}${job.checklist}`}>{this.props.t('selfEmployment.Checklist', { param: 'react' })}</a>:
                                                                        <a rel="noopener noreferrer" target="_blank" style={{ color: "#aca6a6" }} >{this.props.t('selfEmployment.Checklist', { param: 'react' })}</a>}
                                                                    
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-4 mb-1">
                                                                    <span className="pr-2">
                                                                    {job.application_link ? 
                                                                        <a rel="noopener noreferrer" target="_blank" style={{ color: "#007bff" }} href={`${job.application_link}`}>{this.props.t('selfEmployment.Application Link', { param: 'react' })}</a>:
                                                                    <a rel="noopener noreferrer" target="_blank" style={{ color: "#aca6a6" }} >{this.props.t('selfEmployment.Application Link', { param: 'react' })}</a>}
                                                                    
                                                                        </span>
                                                                </div>
                                                                <div className="col-md-4 mb-1">
                                                                    <span className="pr-2">
                                                                    {job.application_form ?
                                                                        <a rel="noopener noreferrer" target="_blank" style={{ color: "#007bff" }} href={`${SERVER_PATH}${job.application_form}`}>{this.props.t('selfEmployment.Application Form', { param: 'react' })}</a>:
                                                                        <a rel="noopener noreferrer" target="_blank" style={{ color: "#aca6a6" }} >{this.props.t('selfEmployment.Application Form', { param: 'react' })}</a>}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row w-100">
                                                                <div className="col-md-12 mb-1">
                                                                    {job.checklist_desc &&
                                                                        <span className="pr-2">
                                                                            <span className="date-clr">{this.props.t('selfEmployment.Checklist', { param: 'react' })}:<span> {job.checklist_desc}</span></span>
                                                                        </span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="text-right rightalign">
                                                                <button
                                                                    onClick={this.getToSelfEmp}
                                                                    className="btn btn-primary btn-primary2 pull-right">
                                                                    {this.props.t('counsellingProvider.I am interested', { param: 'react' })}
                                                                </button>
                                                                <Link data-target="#descriptionModal"
                                                                    onClick={() => this.setState({ job })}
                                                                    data-toggle="modal" style={{ "cursor": "pointer" }} className="date-clr pull-right" >
                                                                    {this.props.t('jobSearch.viewDetails', { param: 'react' })}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{backgroundColor:"#FD7F20"}} className="col-md-12 mb-1"><div className="col-md-12 mb-1">
                                                                    <span className="pr-2">
                                                                    <h6 className="text-center float-left" style={{color:"white",display:"inline-block"}}>For More details call:</h6>
                                                                        <h6 className="text-center" style={{color:"white",display:"inline-block",marginLeft:"23%"}}>DBEE: {job.dist_name}</h6>
                                                                       <h6 className="text-center float-right" style={{color:"white",display:"inline-block", marginLeft:"200px"}}>CONTACT: {job.contact}</h6>
                                                                    </span>
                                                                </div> 
                                                
                                                                 
                                                            </div>
                                                </div>
                                            </div>
                                        )
                                    }) : ''}

                            </div>
                            {searchResult && searchResult.length > 1 ?
                                <div className='react-pagination pagination-cls'>
                                    <Pagination
                                        pageRangeDisplayed={10}
                                        activePage={activePage}
                                        itemsCountPerPage={RESULT_PER_PAGE}
                                        totalItemsCount={this.props.JobseekerReducer.selfEmployment.length}
                                        onChange={this.handlePageChange}
                                    />
                                </div> : ''}
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(SelfEmployment));
