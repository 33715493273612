import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { get_org_type, get_org_category } from '../../actions/employerAction';
import { getCountry, RegisterJobSeeker } from '../../actions/accountAction'
import Validation from "../hoc/Validation";

import store from '../../store';

class ForgienProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitStatus: false,
            user_subtype: window.location.search.split('=')[1],
            stateId: '',
            selectedCheckboxes: new Set(),
            emp_type: '2',
            basePath: window.location.protocol + '//' + window.location.host + '/'
        }
        store.dispatch(get_org_type());
        store.dispatch(get_org_category());
        store.dispatch(getCountry())
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = this.props.t('forms.forEmpSignup', { param: 'react' })
    }

    handleRadio = (e) => {
        this.setState({ emp_type: e.target.value })
        window.location.href = this.state.basePath + 'signup?type=6'
    }

    async handleUserInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;
        if(type==='text'){
            let regex = /^[A-Za-z0-9 ]+$/
            if(!regex.test(value) && value){
                return false
            }
        }
        if (name === 'state_id') {
            this.setState({
                stateId: value
            })
        }
        if (name === 'country') {
            let country_code = this.props.actionReducer.countries.filter((coun) => coun.id === value)
            country_code = country_code && country_code[0] ? country_code[0].phonecode : ""
            this.setState({ country_code: country_code })
        }
        if (name === 'remember') {
            let selectedCheckboxes = this.state.selectedCheckboxes
            if (selectedCheckboxes.has(value)) {
                selectedCheckboxes.delete(value);
            } else {
                selectedCheckboxes.add(value);
            }
            await this.setState({ selectedCheckboxes }, () => {
                value = Array.from(this.state.selectedCheckboxes)
            })
        }
        this.setState({ [name]: value });
        this.setState({ submitStatus: false });
    }

    handleSubmit(event) {
        event.preventDefault();
        const user = {
            org_category: this.state.org_category,
            org_type: this.state.org_type,
            country_id: this.state.country,
            firstname: this.state.org_name,
            district_id: this.state.district_id,
            org_name: this.state.org_name,
            state_id: this.state.state_id,
            mobile: this.state.mobile,
            website: this.state.website,
            org_pan: this.state.org_pan,
            user_subtype: this.state.user_subtype,
            contact_person: this.state.contact_person,
            email: this.state.contact_email,
        };


        if (!this.state.submitStatus && this.props.validator.allValid()) {
            this.setState({ submitStatus: true });
            store.dispatch(RegisterJobSeeker(user));
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0 && this.state.stateId) {
            Mainprops.actionReducer.District.map((data) => {
                if (data.state_id === this.state.stateId) {
                    data.dist.map(function (distdata, distindex) {
                        Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
                        return Distoption
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }

    createState = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map(function (data, index) {
                Distoption.push(<option key={index} value={data.state_id}>{data.state_name}</option>)
                return Distoption
            })
            return Distoption
        }
    }

    render() {
        const { validator } = this.props

        return (
            <div className={"m-2"}>
                <div className="modal fade bd-example-modal-sm" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.props.t('register_user.help_faq', { param: 'react' })}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><b>Contact Number</b>: 01725011186, 01725011185, 01725011184</p>
                                <p><b>Contact Email</b>: pgrkam.degt@gmail.com</p>
                                <p><b>Contact Address</b>: Ground Floor, Punjab Mandi Board Building, Sector 65A, SAS Nagar</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-12 text-left">
                        <div className="row">
                            <label className={"col-md-12 p-0"}>{this.props.t('register_user.employerType', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span> </label>
                            <div className="form-check-inline" >
                                <label className="form-check-label" htmlFor="radio1">
                                    <input type="radio" name="emp_type"
                                        onChange={(event) => this.handleRadio(event)}
                                        className="form-check-input" value={'1'}
                                        checked={this.state.emp_type === '1'} />{'Yes'}
                                </label>
                            </div>
                            <div className="form-check-inline" >
                                <label className="form-check-label" htmlFor="radio1">
                                    <input type="radio" name="emp_type" onChange={(event) => this.handleRadio(event)}
                                        className="form-check-input" value={'2'}
                                        checked={this.state.emp_type === '2'} />{"No"}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <div className="form-group">
                            <div className="row">

                                <div className="col-sm-12 text-left">
                                    <label> {this.props.t('register_user.organization_name', { param: 'react' })}<span
                                        style={{ color: 'red' }} className="ml-2">*</span></label>
                                    <input type="text" placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('experience.Orgnisation Name', { param: 'react' })}
                                        onChange={(event) => this.handleUserInput(event)}
                                        value={this.state.org_name ? this.state.org_name : ''}
                                        className="form-control" id="org_name" name="org_name" />
                                    {validator.message('Organization name', this.state.org_name, 'required')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('JobPost.cont_person', { param: 'react' })}<span
                                    style={{ color: 'red' }} className="ml-2">*</span></label>
                                <input type="text" onChange={(event) => this.handleUserInput(event)}
                                    value={this.state.contact_person ? this.state.contact_person : ''}
                                    className="form-control" placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " +
                                        this.props.t('JobPost.cont_person', { param: 'react' })} name="contact_person"

                                />
                                {validator.message('email', this.state.contact_person, 'required')}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('JobPost.contactPersonEmail', { param: 'react' })}<span
                                    style={{ color: 'red' }} className="ml-2">*</span></label>
                                <input type="email" onChange={(event) => this.handleUserInput(event)}
                                    className="form-control" placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " +
                                        this.props.t('JobPost.contactPersonEmail', { param: 'react' })} name="contact_email"

                                />
                                {validator.message('email', this.state.contact_email, 'required|email')}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('register_user.organization_website', { param: 'react' })}</label>
                                <input placeholder={this.props.t('forms.website', { param: 'react' })}
                                    onChange={(event) => this.handleUserInput(event)}
                                    className="form-control" id="website" name="website" />
                                {validator.message('website', this.state.website, 'url')}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12">
                                <label> {this.props.t('register_user.country', { param: 'react' })}<span
                                    style={{ color: 'red' }} className="ml-2">*</span></label>
                                <select onChange={(event) => this.handleUserInput(event)} name="country" className="form-control">
                                    <option value={""}>{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('register_user.country', { param: 'react' })}</option>
                                    {this.props.actionReducer.countries && this.props.actionReducer.countries.length ?
                                        this.props.actionReducer.countries.map((con, key) => {
                                            return (
                                                <option value={con.id} key={key}>{con.name}</option>
                                            )
                                        }) : ''}
                                </select>
                                {validator.message('country', this.state.country, 'required')}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-3">
                                <label> {this.props.t('register_user.countryCode', { param: 'react' })}</label>
                                <input
                                    name="country_code"
                                    className="form-control"
                                    readOnly={true}
                                    value={this.state.country_code ? this.state.country_code : ""}
                                    placeholder={this.props.t('register_user.countryCode', { param: 'react' })}
                                />

                                {validator.message('country code', this.state.country_code, 'required')}
                            </div>
                            <div className="col-sm-9">
                                <label> {this.props.t('register_user.mobileOther', { param: 'react' })}<span
                                    style={{ color: 'red' }} className="ml-2">*</span></label>
                                <input onChange={(event) => this.handleUserInput(event)}
                                    type="number" className="form-control" placeholder={this.props.t('register_user.mobileOther', { param: 'react' })}
                                    name="mobile"
                                />
                                {validator.message('mobile', this.state.mobile, 'required')}
                            </div>
                        </div>
                    </div>


                    <div className="form-group">
                        <div className="row m-0">
                            <div className="col-sm-12 ml-2">
                                <input className="form-check-input" type="checkbox" name="remember" onClick={(e) => this.handleUserInput(e)} />
                                {this.props.t('register_user.terms', { param: 'react' })}<span style={{ color: 'red' }} className="ml-2">*</span>
                                <div className="valid-feedback">Valid.</div>
                                <label className="invalid-feedback">Check this checkbox to continue.</label>
                            </div>
                            <div className="row m-0">
                                {validator.message('terms and conditions', this.state.remember, 'required')}
                            </div>
                            <div className="row m-0 w-100">
                                <div className="col-md-3">
                                    <button type="submit" className="btn btn-primary btn-primary2  mt-3" >{this.props.t('profileDetails.submit', { param: 'react' })}</button>
                                </div>
                                <div className="col-md-3 mt-4">
                                    <span style={{ cursor: "pointer" }} data-toggle="modal" data-target=".bd-example-modal-sm"
                                        className="date-clr">{this.props.t('register_user.help_faq', { param: 'react' })}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Validation(ForgienProvider)));
