import React, { Component } from 'react'
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { getAreaOfInterest } from '../../../actions/counsellingproviderAction';
import { postCounsellingSearchByArea, setCounsellingSearchByArea } from '../../../actions/jobseekerAction';
import ServerSetting from '../../../config.json';
import {Requestcounselling} from '../../../actions/accountAction';
import Pagination from "react-js-pagination";
import { chunk } from '../../../helpers'
import { getTimeWithAmPm } from '../../../helpers'
import { Link } from 'react-router'
import { translate } from 'react-multi-lang'

const RESULT_PER_PAGE = 25

class SearchSession extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: [],

            activePage: 1,
            submitted: false,
            disabledButton:[],

        }


        store.dispatch(getAreaOfInterest())
        store.dispatch(Requestcounselling())
      

    }

    submit = (e) => {
        e.preventDefault()
        if (!this.state.submitted) {
            this.setState({ activePage: 1 })
            store.dispatch(postCounsellingSearchByArea(this.state.formData, true));
            this.setState({ submitted: true })
        }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    componentDidMount() {
        document.title = this.props.t('counsellingProvider.searchCounsellor', { param: 'react' })
    }

    handleInput = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        formData[name] = value;
        if (!value) {
            delete formData[name]
        }
        await this.setState({ formData, submitted: false });
    }

    
    requestCon(e,index){
        // console.log(index)
        var arr = this.state.disabledButton.slice();
        arr.push(index);

        this.setState({
            //   inCart: true,
              disabledButton: arr
            })
            console.log(this.state.disabledButton)
        console.log(e.target.value)
        const counsellor_id=e.target.value;
        const user_id=this.props.JobseekerReducer.Profile.user_id;
        const value={user_id:user_id, counsellor_id:counsellor_id};
        store.dispatch(Requestcounselling(value))
        if(counsellor_id) {
        this.setState({
            isDisabled: true 
        })
    }
      
        // console.log(user_id);
    }



    componentWillUnmount() {
        store.dispatch(setCounsellingSearchByArea([]))
    
    }

    getAreas = list => {
        const areaList = []
        list.split(',').forEach((ar) => {
            const filterList = this.props.counsellingproviderReducer.area_of_interest.filter((res) => res.id === ar)
            if (filterList && filterList[0]) {
                areaList.push(filterList[0].name)
            }
        })
        return areaList.join(',')
    }

  


    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const Mainprops = this.props
        const { activePage, formData } = this.state
        const searchResults = chunk(Mainprops.JobseekerReducer.counsellorResults, RESULT_PER_PAGE)
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>


                            <div>
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-3">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('counsellingProvider.searchCounsellor', { param: 'react' })}</h6>
                                                        </div>
                                                        <div className="panel-body mt-2">
                                                            <form onSubmit={this.submit}>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-4 col-md-3  col-5 ">
                                                                            <label>{this.props.t('counsellingProvider.areaOfInterest', { param: 'react' })}</label>
                                                                        </div>

                                                                        <div className="col-sm-8 col-md-7  col-7">
                                                                            <select onChange={this.handleInput}
                                                                                value={formData.interest ? formData.interest : ''}
                                                                                className="form-control" name="interest">
                                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('counsellingProvider.areaOfInterest', { param: 'react' })}</option>
                                                                                {this.props.counsellingproviderReducer.area_of_interest.length > 0 &&
                                                                                    this.props.counsellingproviderReducer.area_of_interest.map(function (data, key) {
                                                                                        return (
                                                                                            <option value={data.id} key={key}>{data.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 ">
                                                                            <button type="submit"
                                                                                className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                                {this.props.t('jobSearch.search', { param: 'react' })}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                {Mainprops.JobseekerReducer.counsellorResults.length > 0 &&
                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}
                                                                    <span className="pull-right" style={{ "color": "#fff" }}>
                                                                        {Mainprops.JobseekerReducer.counsellorResults && Mainprops.JobseekerReducer.counsellorResults.length ?
                                                                            Mainprops.JobseekerReducer.counsellorResults.length : 0}{this.props.t('JobPost.foundItems', { param: 'react' })}</span></h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                {searchResults && searchResults.length && searchResults[activePage - 1].map((job,index,key) => {
                                                                    return (
                                                                      
                                                                        <div className="right-sidebar" key={key}>
                                                                            <div className="first-job mb-3 p-3">
                                                                            <button  className='float-right mx-auto d-block btn btn-primary btn-primary2 mt-1' value={job.user_id} onClick={e => this.requestCon(e,index)}   disabled={this.state.disabledButton[index] === index}  >Request Counselling</button>

                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h4 className="company-name">
                                                                                            <Link>{job.firstname}</Link>
                                                                                            <div className="outr-avtar">
                                                                                                <img alt="avatar"
                                                                                                    src={job.image ? `${SERVER_PATH}${job.image}` : '/images/img-1.png'}
                                                                                                    className="avatar mr-2 text-center" />
                                                                                            </div>
                                                                                        </h4>
                                                                                        <ul className="service-li" style={{ marginLeft: "18%" }}>
                                                                                          
                                                                                            {job.mobile ?
                                                                                                <li className="pr-5">
                                                                                                    <b>{this.props.t('lsp_user.mobile', { param: 'react' })}</b>: {job.mobile}
                                                                                                </li> : null}
                                                                                            {job.email ?
                                                                                                <li className="pr-5">
                                                                                                    <b>{this.props.t('JobPost.email', { param: 'react' })}</b>: {job.email}
                                                                                                </li> : null}
                                                                                                <hr/>
                                                                                                {job.address ?
                                                                                                <li className="pr-5">
                                                                                                    <b style={{color:"#ed9017"}}>{this.props.t('profileDetails.communication', { param: 'react' })}</b>: <br/> {job.address}
                                                                                                </li> : null}

                                                                                            <li className="pr-5">
                                                                                                <b style={{color:"#ed9017"}}>{this.props.t('counsellingProvider.areaOfInterest', { param: 'react' })}</b>: <br/> {this.getAreas(job.interest)}
                                                                                            </li>
                                                                                            {job.qualification ?
                                                                                            <li className="pr-5">
                                                                                                <b style={{color:"#ed9017"}}>{this.props.t('jobSearch.qual', { param: 'react' })}</b>: <br/> {job.qualification}
                                                                                            </li> : null}
                                                                                            
                                                                                            <li className="pr-5">
                                                                                                <b style={{color:"#ed9017"}}>Slot Availability Time</b> <br/>
                                                                                                <b> From:  </b>{job.available_from ? getTimeWithAmPm(job.available_from): "9:00 AM" }
                                                                                                <b> To:  </b> {job.available_to ? getTimeWithAmPm(job.available_to): "5:00 PM" }
                                                                                            </li> 

                                                                                            
                                                                                           
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {searchResults && searchResults.length > 1 ? <div className='react-pagination mt-2'>
                                                                    <Pagination
                                                                        pageRangeDisplayed={10}
                                                                        activePage={activePage}
                                                                        itemsCountPerPage={RESULT_PER_PAGE}
                                                                        totalItemsCount={Mainprops.JobseekerReducer.counsellorResults.length}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div> : ''}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}
                                {Mainprops.JobseekerReducer.counsellorResults.status === '401' && <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('jobSearch.results', { param: 'react' })}
                                                                <span className="pull-right" style={{ "color": "#fff" }}>
                                                                    {Mainprops.JobseekerReducer.counsellorResults && Mainprops.JobseekerReducer.counsellorResults.length ?
                                                                        Mainprops.JobseekerReducer.counsellorResults.length : 0}{this.props.t('JobPost.foundItems', { param: 'react' })}</span></h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            <h3>{Mainprops.JobseekerReducer.counsellorResults.message}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>}

                            </div>

                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(SearchSession));