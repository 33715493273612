import axios from 'axios';
import qs from 'qs';
import { push } from 'react-router-redux';
import ServerSetting from '../config.json';
import {
    SET_LOADER, USER_COURSENATURE, UNSET_LOADER, USER_LANGUAGE, USER_QUALIFICATION, USER_SPECILIZATION,
    USER_INSTITUTION, USER_JOBSEEKER_CITIES, USER_JOBSEEKER_SUBDISTRICT, USER_CITIES, USER_SUBDISTRICT,
    OPEN_OPT, USER_RESET, USER_SUB_TYPE, USER_GENDER, USER_DISTRICT, USER_VERIFIED, EDUCATION_LEVELS, SKILL_EDUCATION_LEVELS,
    GET_LOGIN, CREATE_USER, SET_NOTIFICATION, ORG_TYPE, ORG_CATEGORY, ORG_STATE, USER_SKILLS, SET_EDUCATION_LEVEL,
    SET_REDIRECTION_LINK, SET_HOME_LOGIN, SET_ALL_MELAS, SET_GOVT_JOBS, SET_JOBS_COUNT, SET_PRIVATE_JOBS,
    SET_VISITOR_COUNT, SET_EXP, SET_EMPLOYER, SET_SEEKER_TYPE, RESET_VERIFY, SET_USER_VERIFIED_OBJ,
    LSP_SERVICES, SET_COUNTRY, SET_LOCAL_SERVICES, SET_ALL_LOCAL_SERVICES, SET_ALL_DISTRICTS, SET_NEWS,
    SET_JOB_NOTIFICATION, SET_EVENTS, SET_OTP_VERIFICATION, SELECTED_USER_DISTRICT, SET_APPLIED_EVENT_CANDIDATE,
    SET_CHAT_INVITES, SET_CHAT_MESSAGES, SET_CHATS, SET_SELF_EMPLOYMENT_SECTOR, SET_ACTIVE_JOB_MELA, SET_MOBILE_APP_DATA,
    SET_DBEES_ADDRESS, SET_SERVICE_APPLIED, SET_SERVICE_STATUS, SET_DISTANCE, SET_SERVICE_SUGGESTION, SET_CONSTITUENCY,
    SET_SELF_EMPLOYMENT_DATA,SELF_EMP_DROPDOWN_DATA,REQUEST_COUNSELLING,APPLY_SKILL_COURSE,GET_VEDIO,SET_SCHEME
} from './actionTypes';

let environment = (typeof process.env.NODE_ENV !== 'undefined' && process.env.NODE_ENV === 'production') ? 'production' : 'development';
let API_SERVER = ServerSetting[environment].API_SERVER;
export const setLoginuser = loginuser => {
    return {
        type: GET_LOGIN,
        loginuser
    };
};
export const set_usersubtype = UserSubType => {
    return {
        type: USER_SUB_TYPE,
        UserSubType
    };
};

export const setCountry = countries => {
    return {
        type: SET_COUNTRY,
        countries
    };
};
export const set_gender = Genders => {
    return {
        type: USER_GENDER,
        Genders
    };
};
export const set_Loader = Education => {
    return {
        type: SET_LOADER,
    };
};
export const unset_Loader = Education => {
    return {
        type: UNSET_LOADER,
    };
};
export const set_education = Education => {
    return {
        type: EDUCATION_LEVELS,
        Education
    };
};

export const set_skill_education = Education => {
    return {
        type: SKILL_EDUCATION_LEVELS,
        Education
    };
};

export const set_skills = skills => {
    return {
        type: USER_SKILLS,
        skills
    };
};
export const setVerfiedObject = obj => {
    return {
        type: SET_USER_VERIFIED_OBJ,
        obj
    };
};

export const setAllMelas = results => {
    return {
        type: SET_ALL_MELAS,
        results
    };
}

export const set_JUser_Cities = Cities => {
    return {
        type: USER_JOBSEEKER_CITIES,
        Cities
    };
}
export const set_JUser_SubDistrict = Subdistrict => {
    return {
        type: USER_JOBSEEKER_SUBDISTRICT,
        Subdistrict
    };
};
export const set_User_Cities = Cities => {
    return {
        type: USER_CITIES,
        Cities
    };
}

export const setExperience = exp => {
    return {
        type: SET_EXP,
        exp
    };
}

export const setMobileAppData = data => {
    return {
        type: SET_MOBILE_APP_DATA,
        data
    };
}

export const set_User_SubDistrict = Subdistrict => {
    return {
        type: USER_SUBDISTRICT,
        Subdistrict
    };
};
export const set_User_District = District => {
    return {
        type: USER_DISTRICT,
        District
    };
};
export const set_userverified = user => {
    return {
        type: USER_VERIFIED,
        user
    };
};
export const resetUserVerification = () => {
    return {
        type: RESET_VERIFY

    };
};


export const set_userreset = user => {
    return {
        type: USER_RESET,
        user
    };
};
export const set_OTP = status => {
    return {
        type: OPEN_OPT,
        status
    };
};
export const set_RegisterUser = user => {
    return {
        type: CREATE_USER,
        user
    };
};
export const set_User_Qualification = Qualification => {
    return {
        type: USER_QUALIFICATION,
        Qualification
    };
};
export const set_User_Institution = Institution => {
    return {
        type: USER_INSTITUTION,
        Institution
    };
};
export const set_User_Specilization = Specilization => {
    return {
        type: USER_SPECILIZATION,
        Specilization
    };
};
export const set_User_Coursenature = Coursenature => {
    return {
        type: USER_COURSENATURE,
        Coursenature
    };
};
export const setVisitorCount = count => {
    return {
        type: SET_VISITOR_COUNT,
        count
    };
};
export const set_User_Language = Language => {
    return {
        type: USER_LANGUAGE,
        Language
    };
};
export const set_org_type = Orgtype => {
    return {
        type: ORG_TYPE,
        Orgtype
    };
};
export const set_org_category = Orgcategory => {
    return {
        type: ORG_CATEGORY,
        Orgcategory
    };
};
export const set_org_state = Orgstate => {
    return {
        type: ORG_STATE,
        Orgstate
    };
};
export const setEmployers = data => {
    return {
        type: SET_EMPLOYER,
        data
    };
};
export const setHomeLogin = () => {
    return {
        type: SET_HOME_LOGIN
    };
};
export const setSeekerType = (data) => {
    return {
        type: SET_SEEKER_TYPE,
        data
    };
};
export const setLocalServices = (data) => {
    return {
        type: SET_LOCAL_SERVICES,
        data
    };
};

export const setAllLocalServices = (data) => {
    return {
        type: SET_ALL_LOCAL_SERVICES,
        data
    };
};

export const setAllDistricts = (data) => {
    return {
        type: SET_ALL_DISTRICTS,
        data
    };
};




export const showinterest= (data) => {
    // console.log(data);
        return dispatch => {
            return axios
              .post(API_SERVER + 'ggr-jobseeker-profile/interest',data)
              .then(response => {
                  localStorage.setItem('interest_msg',response.data.message);
                //   dispatch(showInterest(response.data.message));
                  if(response.data.status==200){
                      if(response.data.status==200){ 
                          dispatch(setNotification({ type: "success", message: 'Thank you, your response has been recorded.' }));
                        }
                    //   else{
                    
                    //     dispatch(setNotification({ type: "error", message: 'Sorry, your response has been recorded.' }));
                    //   }
                    }
           
                
              })
             
            .catch(error => {
                // console.log(error);

            });
    };
};
export const isCounselled= (data) => {
    // console.log(data);
        return dispatch => {
            return axios
              .post(API_SERVER + 'searchcounselling/request-counselling-update',data)
              .then(response => {
                  localStorage.setItem('interest_msg',response.data.message);
                //   dispatch(showInterest(response.data.message));
                  if(response.data.status==200){
                      if(response.data.status==200){ 
                          dispatch(setNotification({ type: "success", message: 'Thank you, your response has been recorded.' }));
                        }
                    //   else{
                    
                    //     dispatch(setNotification({ type: "error", message: 'Sorry, your response has been recorded.' }));
                    //   }
                    }
           
                
              })
             
            .catch(error => {
                // console.log(error);

            });
    };
};


// export const showInterest = data => {
//     return {
//         type: SHOW_INTEREST,
//         data
//     };
// }


export const Requestcounselling= (data) => {
    console.log(data);
        return dispatch => {
            return axios
              .post(API_SERVER + 'searchcounselling/request-counselling',data)
              .then(response => {
        
                  
                //   localStorage.setItem('interest_msg',response.data.message);
                  dispatch(requestCounselling(response.data.message));
                  if(response.data.status==200){
                      if(response.data.status==200){ 
                          dispatch(setNotification({ type: "success", message: 'Thank your request has been submitted'}));
                        }
                    //   else{
                    
                    //     dispatch(setNotification({ type: "error", message: 'Sorry, your response has been recorded.' }));
                    //   }
                    }
           
                
              })
             
            .catch(error => {
                // console.log(error);

            });
    };
};

export const requestCounselling = data => {
    return {
        type: REQUEST_COUNSELLING,
        data
    };
}

export const applySkillcourse= (data) => {

        return dispatch => {
            return axios
              .post(API_SERVER + 'skill-courses/apply-course',data)
              .then(response => {
           
                //   localStorage.setItem('interest_msg',response.data.message);
                //   dispatch(ApplySkillcourse(response.data.message));
                  if(response.data.status==200){
                      if(response.data.status==200){ 
                          dispatch(setNotification({ type: "success", message: 'Thank your request has been submitted'}));
                        }
                      else{
                    
                        dispatch(setNotification({ type: "error", message: 'Sorry, your request has not been submitted.' }));
                      }
                    }
           
                
              })
             
            .catch(error => {
                // console.log(error);

            });
    };
};

// export const ApplySkillcourse = data => {
//     return {
//         type: APPLY_SKILL_COURSE,
//         data
//     };
// }


export const get_User_Coursenature = () => {
    return dispatch => {
        axios.get(API_SERVER + 'jobnatures/view')
            .then(resp => {
                dispatch(set_User_Coursenature(resp.data));
            })
            .catch(error => {
                // console.log(error);
            });
    };
};
export const get_User_Specilization = () => {
    return dispatch => {
        axios.get(API_SERVER + 'specialization/view')
            .then(resp => {
                dispatch(set_User_Specilization(resp.data));
            })
            .catch(error => {
                // console.log(error);
            });
    };
};
export const get_User_Qualification = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-user-qualification/view')
            .then(resp => {
                dispatch(set_User_Qualification(resp.data));
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const getExperience = () => {
    return dispatch => {
        axios.get(API_SERVER + 'exp/index')
            .then(resp => {
                dispatch(setExperience(resp.data));
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const getMobileAppData = () => {
    return dispatch => {
        axios.get(API_SERVER + 'api/mobile-app')
            .then(resp => {
                // console.log('resp', resp)
                dispatch(setMobileAppData(resp.data));
            })
            .catch(error => {
                // console.log(error);
            });
    };
};


export const get_User_Institution = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-jobseeker-institution-details/view')
            .then(resp => {
                dispatch(set_User_Institution(resp.data));
            })
            .catch(error => {
                // console.log(error);
            });
    };
};
export const get_User_Language = () => {
    return dispatch => {
        axios.get(API_SERVER + 'language/view')
            .then(resp => {
                dispatch(set_User_Language(resp.data));
            })
            .catch(error => {
                // console.log(error);
            });
    };
};
export const searchLocalServices = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'search-service/index', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setLocalServices(resp.data.data));
                } else {
                    dispatch(setLocalServices(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);

            });
    };
};

export const getAllLocalServices = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'service/view')
            .then(resp => {
                // console.log('rspp', resp)
                dispatch(unset_Loader());
                dispatch(setAllLocalServices(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};



export const getAllDistricts = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'district/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                // console.log('rspp', resp)
                dispatch(unset_Loader());
                dispatch(setAllDistricts(resp.data.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};

export const get_User_Cities = (dist, type = 'currunt') => {
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'ggr-city/view', qs.stringify({ subdistrict_id: dist }))
            .then(resp => {
                // console.log(resp)

                dispatch(unset_Loader(''));
                if (type === 'currunt') {
                    dispatch(set_User_Cities(resp.data));
                } else {
                    dispatch(set_JUser_Cities(resp.data));
                }

            })
            .catch(error => {
                dispatch(unset_Loader(''));
                // console.log(error);
            });
    };
};
export const get_User_Subistrict = (dist, type = 'currunt') => {
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'ggr-user-subdistrict/view', qs.stringify({ district_id: dist }))
            .then(resp => {
                // console.log(resp)

                dispatch(unset_Loader(''));
                if (type === 'currunt') {
                    dispatch(set_User_SubDistrict(resp.data));
                } else {
                    dispatch(set_JUser_SubDistrict(resp.data));
                }
            })
            .catch(error => {
                dispatch(unset_Loader(''));
                // console.log(error);
            });
    };
};

export const get_User_District = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'state/index')
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(set_User_District(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};

export const get_selected_User_District = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'state/view')
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(set_Selected_User_District(resp.data))
            })
            .catch(error => {


            });
    };
};

export const set_Selected_User_District = District => {
    return {
        type: SELECTED_USER_DISTRICT,
        District
    };
};

export const getCountry = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'country/index')
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setCountry(resp.data))
            })
            .catch(error => {
                console.log(error);
                //// dispatch(Logout());
            });
    };
}
export const get_org_type = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-organization-type/view')
            .then(resp => {
                dispatch(set_org_type(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};

export const get_org_category = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-organization-category/view')
            .then(resp => {
                dispatch(set_org_category(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};
export const get_org_state = () => {
    return dispatch => {
        axios.get(API_SERVER + 'state/index')
            .then(resp => {
                dispatch(set_org_state(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};
export const get_User_Type = () => {
    return dispatch => {
        axios.get(API_SERVER + 'user_subtype/index')
            .then(resp => {
                dispatch(set_usersubtype(resp.data))
                // dispatch(get_education(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};


export const get_education = () => {
    return dispatch => {
        axios.get(API_SERVER + 'education_level/index')
            .then(resp => {
                dispatch(set_education(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};

export const get_skill_education = () => {
    return dispatch => {
        axios.get(API_SERVER + 'education_level/create')
            .then(resp => {
                dispatch(set_skill_education(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};

export const get_skills = () => {
    return dispatch => {
        axios.get(API_SERVER + 'ggr-user-skills/view')
            .then(resp => {
                dispatch(set_skills(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};
export const get_genders = () => {
    return dispatch => {
        axios.get(API_SERVER + 'gender/index')
            .then(resp => {
                dispatch(set_gender(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};
export const getSeekerType = () => {
    return dispatch => {
        axios.get(API_SERVER + 'usersubtype/view')
            .then(resp => {
                dispatch(setSeekerType(resp.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};


export const setNews = data => {
    return {
        type: SET_NEWS,
        data
    };
}

export const getSchemeList = () => {
    return dispatch => {
        axios.get(API_SERVER + 'skill-courses/schemes-notification')
            .then(resp => {
                console.log(resp.data)
                dispatch(setSchemeList(resp.data.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};


export const setSchemeList = data => {
    return {
        type: SET_SCHEME,
        data
    };
}

export const getGallaryvedio = () => {
    return dispatch => {
        axios.get(API_SERVER + 'gallery/index')
            .then(resp => {
            
                dispatch(gallaryvedio(resp.data.data))
            })
            .catch(error => {
                // console.log(error);
                //// dispatch(Logout());
            });
    };
};


export const gallaryvedio = data => {
    return {
        type: GET_VEDIO,
        data
    };
}

export const setNotifications = data => {
    return {
        type: SET_JOB_NOTIFICATION,
        data
    };
}

export const setRedirectionLink = link => {
    return {
        type: SET_REDIRECTION_LINK,
        link
    };
}
export const setGovtJobs = jobs => {
    return {
        type: SET_GOVT_JOBS,
        jobs
    };
}
export const setPrivateJobs = jobs => {
    return {
        type: SET_PRIVATE_JOBS,
        jobs
    };
}
export const setJobsCount = jobs => {
    return {
        type: SET_JOBS_COUNT,
        jobs
    };
}
export const setNotification = Notification => {
    return {
        type: SET_NOTIFICATION,
        Notification
    };
}
export const setVerifyWithOtp = status => {
    return {
        type: SET_OTP_VERIFICATION,
        status
    };
}

export const setAppliedEventCandidates = data => {
    return {
        type: SET_APPLIED_EVENT_CANDIDATE,
        data
    };
}


export const Resendotp = (data) => {
    // console.log('data', data);
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/resend-verification-email', qs.stringify(data))
            .then(resp => {

                dispatch(unset_Loader());
                // console.log('resp', resp);
                if (resp.data.status === '200') {
                    dispatch(set_OTP(true));
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
            })
            .catch(error => {
                // console.log('error', error);
                dispatch(unset_Loader());
                if (error.response) {
                    dispatch(setNotification({ type: "error", message: error.response.data }));

                } else if (error.request) {
                    // console.log(error.request);
                } else {
                    // console.log('Error', error.message);
                    dispatch(setNotification({ type: "error", message: error.message }));
                }
            });
    };
}
export const user_forgetpass = (data) => {
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/request-password-reset', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader(''));
                // console.log(resp);
                if (resp.data.status === '200') {
                    dispatch(set_userreset(resp.data.message));
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
            })
            .catch(error => {
                dispatch(unset_Loader(''));
                if (error.response) {
                    dispatch(setNotification({ type: "error", message: error.response.data }));

                } else if (error.request) {
                    // console.log(error.request);
                } else {
                    // console.log('Error', error.message);
                    dispatch(setNotification({ type: "error", message: error.message }));
                }
            });
    };
}
export const user_verify = (data) => {
    // console.log(data);
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/verify-email', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader(''));
                // console.log(resp);
                if (resp.data.status === '200') {
                    dispatch(set_userverified(resp.data.message));
                    dispatch(setVerfiedObject(resp.data));
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
            })
            .catch(error => {
                dispatch(unset_Loader(''));
                if (error.response) {
                    dispatch(setNotification({ type: "error", message: error.response.data }));

                } else if (error.request) {
                    // console.log(error.request);
                } else {
                    // console.log('Error', error.message);
                    dispatch(setNotification({ type: "error", message: error.message }));
                }
            });
    };
}
export const RegisterJobSeeker = (data) => {
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/signup', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader(''));
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    resp.data.firstname = data.firstname
                    if (resp.data.approved_by_dbee) {
                        dispatch(setNotification({ type: "success", message: resp.data.approved_by_dbee }));
                    }
                    dispatch(set_RegisterUser(resp.data));
                }
            })
            .catch(error => {
                dispatch(unset_Loader(''));
                if (error.response) {
                    dispatch(setNotification({ type: "error", message: error.response.data }));

                } else if (error.request) {
                    // console.log(error.request);
                    // dispatch(setNotification({type:"error",error.message}));
                } else {
                    // console.log('Error', error.message);
                    dispatch(setNotification({ type: "error", message: error.message }));
                }
            });
    };
};

export const uploadEventPass = (data) => {
    // data.auth_key = localStorage.getItem('token')
    // http://92.42.110.29/ggr/api/v1/index.php/
    var formData = new FormData();
    for (var key in data) {
        formData.append(key, data[key]);
    }
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'scheduled-event/create', formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then(resp => {
                // console.log('resasdhsjd shdj', resp)
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                    dispatch(getAppliedEventCandidates(data.event_id))
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const setChatInvites = data => {
    return {
        type: SET_CHAT_INVITES,
        data
    };
};
export const getChatInvites = () => {
    return dispatch => {
        axios.post(API_SERVER + 'request-search/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setChatInvites(resp.data.rows));
                } else {
                    dispatch(setChatInvites({ notFound: true }));
                }

            })
            .catch(error => {
                // console.log('error',error)
                // dispatch(Logout());
            });
    };
}

export const blockUnBlockUser = (status, block_id) => {
    const data = []
    data['auth_key'] = localStorage.getItem('token')
    data['block_id'] = block_id
    data['status'] = status
    return dispatch => {
        axios.post(API_SERVER + 'request-search/block', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(getChats());
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }

            })
            .catch(error => { });
    };
}

export const sentChatMessage = (data) => {
    data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        axios.post(API_SERVER + 'request-search/insert-chat-message', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setChatInvites(resp.data.rows));
                    dispatch(getChatMessages({ receiver_id: data.receiver_id }))
                } else if (resp.data.status === '402') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }

            })
            .catch(error => {
                // console.log('error',error)
                // dispatch(Logout());
            });
    };
}
export const getChatMessages = (data) => {
    data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        axios.post(API_SERVER + 'request-search/get-message', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setChatMessages(resp.data.data));
                } else {
                    dispatch(setChatMessages([]));
                }

            })
            .catch(error => {
                // console.log('error',error)
                // dispatch(Logout());
            });
    };
}

export const setChatMessages = data => {
    return {
        type: SET_CHAT_MESSAGES,
        data
    };
}

export const setChats = data => {
    return {
        type: SET_CHATS,
        data
    };
}
export const getChats = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'request-search/view-employer', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                // console.log('resp', resp.data)
                if (resp.data.status === '200') {
                    dispatch(setChats(resp.data.rows))
                    // dispatch(getAppliedEvents())
                } else {
                    dispatch(setChats([]))
                }
                dispatch(unset_Loader())
            })
            .catch(error => {
                // console.log(error);
                // // dispatch(Logout());
            });
    };
}

export const getAllMelas = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'job-melas/index')
            .then(resp => {
                // console.log(resp)
                // debugger
                dispatch(unset_Loader());
                dispatch(setAllMelas(resp.data));
            })
            .catch(error => {
            });
    };
};
export const VerifyUserWithOtp = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'verify-user/index', qs.stringify(data))
            .then(resp => {
                console.log('resp', resp.data)
                // debugger
                if (resp.data.status !== '401') {
                    dispatch(setVerifyWithOtp(true));
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
            });
    };
};


export const getNews = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'news/index')
            .then(resp => {
                dispatch(unset_Loader());
                // console.log('resp', resp)
                if (resp.data.status === '200') {
                    dispatch(setNews(resp.data.data));
                }

            })
            .catch(error => {
                console.log('error', error)
            });
    };
}
export const getNotifications = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'notification/index')
            .then(resp => {
                dispatch(unset_Loader());
                // console.log('resp', resp)
                if (resp.data.status === '200') {
                    dispatch(setNotifications(resp.data.data));
                }

            })
            .catch(error => {
                console.log('error', error)
            });
    };
}


export const getEvents = () => {
    return dispatch => {
        axios.post(API_SERVER + 'foreign-event/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                // console.log('resp', resp)
                if (resp.data.status === '200') {
                    dispatch(setEvents(resp.data.rows));
                }

            })
            .catch(error => {
                // console.log('error',error)
                // dispatch(Logout());
            });
    };
}

export const getSchduledEvents = () => {
    return dispatch => {
        // http://92.42.110.29/ggr/api/v1/index.php/
        axios.post(API_SERVER + 'scheduled-event/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                // console.log('resp', resp)
                if (resp.data.status === '200') {
                    dispatch(setEvents(resp.data.rows));
                }

            })
            .catch(error => {
                // console.log('error',error)
                // dispatch(Logout());
            });
    };
}

export const inviteAction = (data) => {
    data['auth_key'] = localStorage.getItem('token')
    return dispatch => {
        axios.post(API_SERVER + 'request-search/update', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(getChatInvites());
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                }

            })
            .catch(error => {
                // console.log('error',error)
                // dispatch(Logout());
            });
    };
}

export const getAppliedEventCandidates = (event_id) => {
    return dispatch => {
        dispatch(set_Loader(''))
        // http://92.42.110.29/ggr/api/v1/index.php/
        axios.post(API_SERVER + 'scheduled-event/index',
            qs.stringify({ auth_key: localStorage.getItem('token'), event_id }))
            .then(resp => {
                dispatch(unset_Loader())
                if (resp.data.status === '200') {
                    dispatch(setAppliedEventCandidates(resp.data.rows));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};


export const getAllEvents = () => {
    return dispatch => {
        // http://92.42.110.29/ggr/api/v1/index.php/
        axios.post(API_SERVER + 'foreign-event/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                dispatch(unset_Loader());
                // console.log('resp', resp)
                dispatch(setEvents(resp.data));
                // if(resp.data.status ==='200'){

                // }

            })
            .catch(error => {
                // console.log('error',error)
                // dispatch(Logout());
            });
    };
}

export const postEvent = (data) => {
    data['auth_key'] = localStorage.getItem('token')

    return dispatch => {
        dispatch(set_Loader(''))
        // http://151.106.28.89/ggr/api/v1/index.php/
        axios.post(API_SERVER + 'foreign-event/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader())
                // console.log('resp', resp)
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};

export const getGovtJobs = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'govt-job/index')
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setGovtJobs(resp.data));
            })
            .catch(error => {
            });
    };
};
export const getVisitorCount = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'visitor-count/view')
            .then(resp => {
                dispatch(unset_Loader());
                // localStorage.setItem("visitors", resp.data.count)
                dispatch(setVisitorCount(resp.data.count));
            })
            .catch(error => {
            });
    };
};
export const getActiveJobMela = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'job-melas/alert-notification')
            .then(resp => {
                dispatch(unset_Loader());
                // if (resp.data.status === 200) {
                dispatch(setActiveJobMela(resp.data.data));
                // }
            })
            .catch(error => {
                console.log('error', error)
            });
    };
}

export const setActiveJobMela = data => {
    return {
        type: SET_ACTIVE_JOB_MELA,
        data
    };
}

export const setDistance = (data) => {
    return {
        type: SET_DISTANCE,
        data
    };
};


export const getDistance = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'location/index')
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setDistance(resp.data));
            })
            .catch(error => {
                // console.log(error);

            });
    };
};

export const checkForAppliedServiceByMobile = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'verify-user/view', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setApplyServiceResp(resp.data));
            })
            .catch(error => {
                // console.log(error);

            });
    };
};

export const setApplyServiceResp = data => {
    return {
        type: SET_SERVICE_STATUS,
        data
    };
}

export const getPrivateJobs = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'post-jobs/index')
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setPrivateJobs(resp.data));
            })
            .catch(error => {
                // console.log(error)
            });
    };
};

export const getEmployers = (data, mergeData = false) => {
    return (dispatch, getState) => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'mela-employers/index', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '200') {
                    if (mergeData) {
                        let oldData = getState().actionReducer.employers.data;
                        let newData = oldData.concat(resp.data.data)
                        resp.data.data = newData
                        dispatch(setEmployers(resp.data));
                    } else {
                        dispatch(setEmployers(resp.data));
                    }
                    dispatch(unset_Loader())
                } else {
                    dispatch(setEmployers(resp.data));
                }
            })
            .catch(error => {
                // console.log(error);
            });
        dispatch(unset_Loader())
    };
};

export const getJobsCount = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'available-counts/index')
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setJobsCount(resp.data));
            })
            .catch(error => {
            });
    };
};

export const setSelfEmpDropDownData = (data) => {
    return {
        type: SELF_EMP_DROPDOWN_DATA,
        data
    };
};


export const selfEmploymentData = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'career/data', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (resp.data.status === '200') {
                    dispatch(setSelfEmploymentData(resp.data.data[0]));
                }
            })
            .catch(error => {
                // console.log('error',error)

            });
    };
}
export const setSelfEmploymentData = data => {
    return {
        type: SET_SELF_EMPLOYMENT_DATA,
        data
    };
};
export const getSelfEmpDropDownData = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'selfemployment/data')
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setSelfEmpDropDownData(resp.data));
            })
            .catch(error => {
                // console.log(error);

            });
    };
};

export const RegisterForgienJobSeeker = (data) => {
    // console.log(data);
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/signup', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader(''));
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(set_RegisterUser(resp.data));
                }
            })
            .catch(error => {
                dispatch(unset_Loader(''));
                if (error.response) {
                    dispatch(setNotification({ type: "error", message: error.response.data }));

                } else if (error.request) {
                    // console.log(error.request);
                    // dispatch(setNotification({type:"error",error.message}));
                } else {
                    dispatch(setNotification({ type: "error", message: error.message }));
                }
            });
    };
};

export const setServiceSuggestions = (data) => {
    return {
        type: SET_SERVICE_SUGGESTION,
        data
    };
};

export const getServiceSuggestions = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'search-service/suggestion')
            .then(resp => {
                if (resp.data.status === '200') {
                    dispatch(setServiceSuggestions(resp.data.data))
                }
                dispatch(unset_Loader());

            })
            .catch(error => {
                // console.log(error);
                //
            });
    };
};

export const setConstituency = (data) => {
    return {
        type: SET_CONSTITUENCY,
        data
    };
};

export const getConstituency = () => {
    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'state/contituency')
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setConstituency(resp.data));
            })
            .catch(error => {
                // console.log(error);

            });
    };
};


export const Islogin = () => {
    return dispatch => {
        axios.post(API_SERVER + 'site/index', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '401') {
                    // dispatch(Logout());
                } else {
                    // console.log(resp.data)
                    localStorage.setItem('token', resp.data.auth_key);
                    localStorage.setItem('login_type', resp.data.name);
                    localStorage.setItem('showint', resp.data.show_interest);
                    localStorage.setItem('user_id', resp.data.id);
                    localStorage.setItem('Islogin', true);
                    localStorage.setItem('interest', resp.data.interest);
                    dispatch(setLoginuser(resp.data));
                }
            })
            .catch(error => {
                if (error.response) {
                    dispatch(setNotification({ type: "error", message: error.response.data }));
                    // dispatch(Logout());

                } else if (error.request) {
                } else {
                    // // console.log('Error', error.message);
                    dispatch(setNotification({ type: "error", message: error.message }));
                    // dispatch(Logout());
                }
            });
    };
};

export const selfEmploymentSectors = () => {
    return dispatch => {
        axios.get(API_SERVER + 'career/sector')
            .then(resp => {
                dispatch(unset_Loader());
                dispatch(setSelfEmploymentSectors(resp.data));
            })
            .catch(error => {
                // console.log('error',error)

            });
    };
}

export const setSelfEmploymentSectors = data => {
    return {
        type: SET_SELF_EMPLOYMENT_SECTOR,
        data
    };
};

export const Logout = () => {
    return dispatch => {
        localStorage.setItem('token', 'null');
        localStorage.setItem('login_type', 'null');
        localStorage.setItem('Islogin', false);
        localStorage.setItem('progress', 0)
        dispatch(setLoginuser([]));
        dispatch(push('/'));
    };
};


export const Login = (data) => {
    return dispatch => {
        dispatch(set_Loader(''));
        axios.post(API_SERVER + 'site/login', qs.stringify(data))
            .then(async resp => {
                if (resp.data.status === '401' || resp.data.status === '204') {
                    if (resp.data.message === 'Account not verified') {
                        dispatch(set_RegisterUser(resp.data));
                        dispatch(setLoginuser('Account not verified'));
                    }
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    await dispatch(setNotification({ type: "success", message: 'Login is Successful' }));
                    setTimeout(() => {
                        localStorage.setItem('token', resp.data.auth_key);
                        localStorage.setItem('login_type', resp.data.name);
                    
                        localStorage.setItem('Islogin', true);
                        dispatch(setHomeLogin())
                        dispatch(setLoginuser(resp.data));
                    }, 600)
                }
                dispatch(unset_Loader(''));

            })
            .catch(error => {
                dispatch(unset_Loader(''));
                if (error.response) {
                    dispatch(setNotification({ type: "error", message: error.response.data }));

                } else if (error.request) {
                } else {
                    dispatch(setNotification({ type: "error", message: error.message }));
                }
            });
    };
};
export const CreateUser = (newuser) => {
    return dispatch => {
        axios.post('/user/', newuser)
            .then(resp => {
                dispatch(setNotification({ type: "success", message: "User created Successfully.." }));
            })
            .catch(error => {
                if (error.response.data.error === 11000) {
                    dispatch(setNotification({ type: "error", message: "Email already exist..!" }));
                }
                if (error.response.data.error === 11001) {
                    dispatch(setNotification({ type: "error", message: "Urlkey already exist..!" }));
                }
            });
    };
};
export const SignupUser = (newuser) => {
    return dispatch => {
        axios.post('/user/signup', newuser)
            .then(resp => {
                dispatch(setNotification({ type: "success", message: "Login Successfully.." }));
                localStorage.setItem('token', resp.data.token);
                localStorage.setItem('Islogin', true);
                let user = resp.data.user;
                user.role = resp.data.role;
                user.business_id = resp.data.business_id;
                dispatch(setLoginuser(user));
            })
            .catch(error => {
                if (error.response.data.error === 11000) {
                    dispatch(setNotification({ type: "error", message: "Email already exist..!" }));
                }
                if (error.response.data.error === 11001) {
                    dispatch(setNotification({ type: "error", message: "Urlkey already exist..!" }));
                }
            });
    };
};

export const getDbeesAddress = () => {

    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'district/index')
            .then(resp => {

                dispatch(unset_Loader());
                dispatch(setDbeesAddress(resp.data));
            })
            .catch(error => {
            });
    };
}

export const set_service_applied = status => {
    return {
        type: SET_SERVICE_APPLIED,
        status
    };
}

export const applyLocalServices = (data) => {
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'apply-services/create', qs.stringify(data))
            .then(resp => {
                dispatch(unset_Loader());
                if (!resp.data.applied) {
                    dispatch(set_service_applied(1))
                } else {
                    dispatch(set_service_applied(2))
                }

                dispatch(setNotification({ type: resp.data.status === '200' ? "success" : "error", message: resp.data.message }));
            })
            .catch(error => {
                // console.log(error);

            });
    };
};

export const setDbeesAddress = data => {
    return {
        type: SET_DBEES_ADDRESS,
        data
    };
}
export const UpdateUser = (newuser) => {
    return dispatch => {
        axios.post('/user/update', newuser)
            .then(resp => {
                if (resp.data.success === true || resp.data.success === 'true') {
                    dispatch(setNotification({ type: "success", message: "User updated Successfully.." }));
                }
            })
            .catch(error => {
                if (error.response.data.error === 11000) {
                    dispatch(setNotification({ type: "error", message: "Email already exist..!" }));
                }
                if (error.response.data.error === 11001) {
                    dispatch(setNotification({ type: "error", message: "Urlkey already exist..!" }));
                }
            });
    };
};
export const UpdateProfile = (newuser) => {
    return dispatch => {
        axios.post('/user/profileupdate', newuser)
            .then(resp => {
                let user = resp.data.user;
                user.role = resp.data.role;
                user.business_id = resp.data.business_id;
                dispatch(setLoginuser(user));
            })
            .catch(error => {
                if (error.response.data.error === 11000) {
                    dispatch(setNotification({ type: "error", message: "Email already exist..!" }));
                }
                if (error.response.data.error === 11001) {
                    dispatch(setNotification({ type: "error", message: "Urlkey already exist..!" }));
                }
            });
    };
};
export const UpdateProfileImage = (newuser) => {
    return dispatch => {
        axios.post('/user/imageupdate', newuser)
            .then(resp => {
                let user = resp.data.user;
                user.role = resp.data.role;
                user.business_id = resp.data.business_id;
                dispatch(setLoginuser(user));
            })
            .catch(error => {
                if (error.response.data.error === 11000) {
                    dispatch(setNotification({ type: "error", message: "Email already exist..!" }));
                }
                if (error.response.data.error === 11001) {
                    dispatch(setNotification({ type: "error", message: "Url key already exist..!" }));
                }
            });
    };
};
export const UpdatePassword = (newuser) => {
    return dispatch => {
        axios.post('/user/profilepassword', newuser)
            .then(resp => {
                if (resp.data.success === true || resp.data.success === 'true') {
                    dispatch(setNotification({ type: "success", message: "User updated Successfully.." }));
                }
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

export const sendFeedback = (data) => {
    data.auth_key = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'feedback-form/index', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};



export const sendQueries = (data) => {
    data.auth_key = localStorage.getItem('token')
    return dispatch => {
        dispatch(set_Loader());
        axios.post(API_SERVER + 'feedback-form/index', qs.stringify(data))
            .then(resp => {
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setNotification({ type: "success", message: resp.data.message }));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
                // dispatch(Logout());
            });
    };
};
export const setEducationLevel = education => {
    return {
        type: SET_EDUCATION_LEVEL,
        education
    };
};

export const setEvents = data => {
    return {
        type: SET_EVENTS,
        data
    };
};


export const getEducationLevel = () => {

    return dispatch => {
        dispatch(set_Loader());
        axios.get(API_SERVER + 'education_level/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                if (resp.data.status === '401') {
                    // dispatch(setNotification({type:"error",message:resp.data.message}));
                } else {
                    dispatch(setEducationLevel(resp.data));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);

            });
    };
};


// by kantsverma for LSP module
// export const get_lsp_services = () => {
//     return dispatch => {
//         axios.get(API_SERVER + 'service/index')
//             .then(resp => {
//                 console.log('Here we go ... -> '+JSON.stringify(resp.data));                
//                 dispatch(set_services(resp.data))
//             })
//             .catch(error => {
//                 // console.log(error);
//                 //// dispatch(Logout());
//             });
//     };
// };
// by kantsverma for LSP module

export const setServices = Services => {
    //console.log('setServices ---> '+JSON.stringify(services));      
    return {
        type: LSP_SERVICES,
        Services
    };
};

export const getLspServices = () => {

    return dispatch => {
        dispatch(set_Loader());
        //console.log(API_SERVER + 'service/view');
        axios.get(API_SERVER + 'category-service/view', qs.stringify({ auth_key: localStorage.getItem('token') }))
            .then(resp => {
                //console.log('Here we go ... -> '+JSON.stringify(resp.data));        
                if (resp.data.status === '401') {
                    dispatch(setNotification({ type: "error", message: resp.data.message }));
                } else {
                    dispatch(setServices(resp.data));
                }
                dispatch(unset_Loader());
            })
            .catch(error => {
                // console.log(error);
            });
    };
};

// export const get_local_services = () => {
//     return dispatch => {
//         axios.get(API_SERVER + 'service/view')
//             .then(resp => {
//                 console.log('get_local_services ... -> '+JSON.stringify(resp.data)); 
//                 dispatch(set_local_servicesn(resp.data))
//             }).catch(error => {
//                 // console.log(error);
//                 //// dispatch(Logout());
//             });
//     };
// };
// // export const setServices = services => {
// //     //console.log('setServices ---> '+JSON.stringify(services));      
// //     return {
// //         type: LSP_SERVICES,
// //         services
// //     };
// // };
// export const set_local_servicesn = Services => {
//     return {
//         type: LSP_SERVICES,
//         Services
//     };
// };



