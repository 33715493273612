import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import store from '../store';
import { Link } from 'react-router';

import { getOngoingSkillCourses } from '../actions/jobseekerAction';

class ongoingSkillCourses extends Component {
    constructor(props){
        super(props);
        store.dispatch(getOngoingSkillCourses());
    }

    componentDidMount() {
        document.title = 'List';
        
    }


    
    render() {
       var data=this.props.JobseekerReducer.ongoingSkillCoursesResult;
    //    console.log(data);

        return (
            <div className="tab-content">
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0"><span >Ongoing Courses</span>
                                        <span class="float-right p-0 mt-0" ><a href="/aboutus"><u style={{'color':'#000', fontSize:'12px'}}>Home-Skill Development</u></a></span></h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive testable">
                    
                                                        <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>{this.props.t('dashboard.table.sr_no', { param: 'react' })}</th>
                                                                                                        <th>District</th>
                                                                                                        <th style={{width:'20%'}}>Training Partner</th>
                                                                                                        <th>Training Center</th>
                                                                                                        <th>Sector</th>
                                                                                                        <th>Job Role/Trade</th>
                                                                                                        <th>Batch Code/Id</th>
                                                                                                        <th style={{width:'18%'}}>Start Date</th>
                                                                                                        <th style={{width:'12%'}}>End Date</th>
                                                                                                        <th style={{width:'12%'}}>Batch Strength</th>

                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                {data && data.map((d, key) => {
                                                                return (<tr key={key}>
                                                                    <td>{key + 1} </td>
                                                                                                        
                                                                                                            <td>{d.district ? d.district : ''}</td>
                                                                                                            <td>{d.tp_name ? d.tp_name : ''}</td>
                                                                                                            <td>{d.tc_name ? d.tc_name : ''}</td>
                                                                                                            <td>{d.sector ? d.sector : ''}</td>
                                                                                                            <td>{d.course ? d.course : ''}</td>
                                                                                                            <td>{d.skill_name ? d.skill_name : ''}</td>
                                                                                                            <td>{d.start_date}</td>
                                                                                                            <td>{d.end_date}</td>
                                                                                                            <td>{d.strength ? d.strength+' candidates' : ''}</td>

                                                                                                        </tr>)
                                                                                                    })}

                                                                                                    {!this.props.JobseekerReducer.ongoingSkillCoursesResult.length && <tr>
                                                                                                        <td colSpan="6" style={{ "textAlign": "center" }}><h6>{this.props.t('JobPost.notFound', { param: 'react' })}</h6></td>
                                                                                                    </tr>}

                                                                                                </tbody>
                                                                                            </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="register-bar">
                                    {/* <Link className="nav-link registered" to="signup?type=1"><i className="fa fa-registered" aria-hidden="true"></i></Link> */}
                                    <Link className="nav-link registered" to="signup?type=1"><span style={{background:"rgb(237 144 23)", padding:"9px 7px 8px 6px" ,fontWeight:"900" ,borderRadius:"10px",fontSize:"10px"}}>REGISTER FOR SKILL TRAINING</span></Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(ongoingSkillCourses));
