import React, { Component } from 'react';
import { translate } from "react-multi-lang";
import Pagination from "react-js-pagination";
import { chunk } from '../helpers'
import store from "../store";
import { selfEmploymentSectors, selfEmploymentData, setSelfEmploymentData } from '../actions/accountAction'
import { connect } from 'react-redux';
import { Link } from 'react-router'

const RESULT_PER_PAGE = 100

class CareerOpportunities extends Component {

    state = {
        activePage: 1,
        subCat: [],
        sector_id: '',
        catName: ''
    }

    componentDidMount() {
        document.title = this.props.t('sidebar.careerOpportunities', { param: 'react' })
        store.dispatch(selfEmploymentSectors());
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    showSubCat = (sector) => {
        if(sector.subsector && sector.subsector.length){
            this.setState({ subCat: sector.subsector, sector_id: sector.sector_id, catName: sector.sector_name })
        }
    }

    showContent = (cat) => {
        const { sector_id } = this.state
        store.dispatch(selfEmploymentData({ sector_id, sector_profile_id: cat.id }))
        this.setState({ sector_profile_id: cat.id })
    }

    goCat = () => {
        this.setState({ subCat: [], sector_id: '' })
    }

    goSubCat = () => {
        this.setState({ sector_profile_id: '' })
        store.dispatch(setSelfEmploymentData([]))
    }

    render() {
        const { activePage, subCat, sector_profile_id, sector_id, catName } = this.state

        const allSectors = this.props.actionReducer.selfEmpSectors
        const sectors = chunk(allSectors, RESULT_PER_PAGE)
        const content = this.props.actionReducer.careerContent

        return (
            <div className="tab-content" style={{border:"none"}}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0">{this.props.t('sidebar.careerOpportunities', { param: 'react' })}
                                            {sector_profile_id && sector_id &&
                                                <span className="pull-right" style={{ cursor: "pointer", color: "#fff" }} onClick={this.goSubCat}>{this.props.t('jobSearch.goBack', { param: 'react' })}</span>}
                                            {!sector_profile_id && sector_id &&
                                                <span style={{ cursor: "pointer", color: "#fff" }} className="pull-right" onClick={this.goCat}>{this.props.t('jobSearch.goBack', { param: 'react' })}</span>}
                                        </h6>
                                    </div>

                                    <div className="panel-body p-policy pt-0 p-3">
                                        <div className={"row p-3"}>
                                            {sector_profile_id && sector_id && content ? <div className={"col-md-12"}>
                                                <h4>{catName} - {content.sector_profile_name ? content.sector_profile_name : ""}</h4>
                                                <hr className="mb-0"></hr>
                                                <div className="row">
                                                    <div className="col-md-8 p-3 border-0 career-content">
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.JOB DESCRIPTION', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <p className="date-clr">{content.description ? content.description : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.WORK ENVIRONMENT', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <p className="date-clr">{content.work_environment ? content.work_environment : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.KEY COMPETENCIES', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <p className="date-clr">{content.key_competencies ? content.key_competencies : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.DESIRABLE COMPETENCIES', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <p className="date-clr">{content.desirable_competencies ? content.desirable_competencies : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.AVAILABLE SKILL TRAINING AND LEARNING INSTITUTES', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <p className="date-clr">{content.skill_training_institutes ? content.skill_training_institutes : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.AVAILABLE SKILL TRAINING SCHEMES/SCHOLARSHIPS', { param: 'react' })}</h6></div>
                                                            <div className="panel-body">
                                                                <p className="date-clr">{content.skill_training_schemes ? content.skill_training_schemes : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.CAREER PROGRESSION PATH', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <p className="date-clr">{content.career_progress_path ? content.career_progress_path : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.EXPECTED EARNINGS', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body"><p className="date-clr">{content.expected_earnings ? content.expected_earnings : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.REQUIRED WORK EXPERIENCE', { param: 'react' })}</h6></div>
                                                            <div className="panel-body"><p className="date-clr">{content.work_experience ? content.work_experience : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.PROBABLE EMPLOYERS', { param: 'react' })}</h6></div>
                                                            <div className="panel-body"><p className="date-clr">{content.employers ? content.employers : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.KEYWORDS', { param: 'react' })} </h6></div>
                                                            <div className="panel-body"><p className="date-clr">{content.keywords ? content.keywords : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.OCCUPATIONAL CODES AND STANDARDS', { param: 'react' })} </h6>
                                                            </div>
                                                            <div className="panel-body"> <p className="date-clr">{content.desirable_competencies ? content.desirable_competencies : "None"}</p>
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="panel-heading">
                                                                <h6>{this.props.t('selfEmployment.REFERENCES', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body"><p className="date-clr">{content.reference ? content.reference : "None"}</p>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                            </div> :
                                                <>{subCat && subCat.length ?
                                                    <div className="row d-flex">
                                                        {subCat.map((cat, key) => {
                                                            return (
                                                                <div className="col-lg-4 boxoutr"
                                                                    key={key}
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => this.showContent(cat)}>
                                                                    <Link className="date-clr">{cat.name}</Link>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="row d-flex">
                                                            {sectors && sectors.length ? sectors[activePage - 1].map((sector, index) => {
                                                                return (<div className="col-lg-3 px-2" key={index}
                                                                    style={{ cursor: sector.subsector && sector.subsector.length ? "pointer" : "" }}
                                                                    onClick={() => this.showSubCat(sector)}><div className="text-center mb-4 career_opt">
                                                                        <h6 className="m-0">{sector.sector_name}</h6>
                                                                    </div> </div>)
                                                            }) : null}
                                                        </div>

                                                        {sectors && sectors.length > 1 ?
                                                            <div className='react-pagination pagination-cls'>
                                                                <Pagination
                                                                    pageRangeDisplayed={10}
                                                                    activePage={activePage}
                                                                    itemsCountPerPage={RESULT_PER_PAGE}
                                                                    totalItemsCount={allSectors.length}
                                                                    onChange={this.handlePageChange}
                                                                />
                                                            </div> : ''}
                                                    </>}</>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(CareerOpportunities));
