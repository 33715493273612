import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import store from '../../store';
import { Login, Resendotp, user_verify,setRedirectionLink } from '../../actions/accountAction';
import Validation from "../hoc/Validation";

class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitStatus: true,
            username: '',
            password: '',
            basePath: window.location.protocol + '//' + window.location.host + '/'
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
        if(this.props.location.query.action){
             store.dispatch(setRedirectionLink(this.props.location.query.action));
        }
    }

    componentDidMount() {
        document.title = this.props.t('header.sign_in', { param: 'react' })
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
        this.setState({ submitStatus: false });
    }

    handleOtpSubmit(event) {
        event.preventDefault();
        const user = {
            otp: this.state.opt,
            username: this.state.username
        };
        if (!this.state.submitStatus) {
            store.dispatch(user_verify(user));
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const user = {
            username: this.state.username,
            password: this.state.password,
        };
        if (!this.state.submitStatus && this.props.validator.allValid()) {
            this.setState({ submitStatus: true });
            store.dispatch(Login(user));
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleUserSentotp(event) {
        event.preventDefault();
        const user = {
            mobile: this.props.actionReducer.Registeruser.mobile
        };
        this.setState({ submitStatus: false });
        store.dispatch(Resendotp(user));
    }

    handleUserResetPassword(event) {
        event.preventDefault();
        window.location.href = this.state.basePath + 'resetpassword'
    }

    redirectConditionally = () => {
        if (this.props.actionReducer.link && this.props.actionReducer.Loginuser && 
            this.props.actionReducer.Loginuser.user_type === '1') {
               
            window.location.href = this.state.basePath+this.props.actionReducer.link
            // window.location.href = this.state.basePath + 'jobfair';
            return
        }
        if (this.props.actionReducer.link && this.props.actionReducer.Loginuser && 
            this.props.actionReducer.Loginuser.user_type === '6') {
                window.location.href = this.state.basePath + 'myaccount';
        }   
        if ( this.props.actionReducer.Loginuser && this.props.actionReducer.Loginuser.user_type &&
            (this.props.actionReducer.Loginuser.user_type === '7' || this.props.actionReducer.Loginuser.user_type === '10' ||
             this.props.actionReducer.Loginuser.user_type === '12')) {
              
                window.location.href = this.state.basePath + 'myprofile';
                return
                
        }   
        
        if (localStorage.getItem('Islogin') && localStorage.getItem('Islogin') === 'true'){
            if(localStorage.getItem('course_id')){
                window.location.href = this.state.basePath + 'skill-courses';
                return
            }
            window.location.href = this.state.basePath + 'myprofile'
        }
    }
    change = (id, e) => {
        let inputType = document.getElementById(id).type
        if (inputType === 'password') {
            document.getElementById(id).type = 'text'
            e.target.className = e.target.className.substring(0, e.target.className.lastIndexOf("-"));
        } else {
            document.getElementById(id).type = 'password'
            e.target.className += '-slash'
        }

    }



    render() {
        const { validator } = this.props
        let Mainprops = this.props;
        if (Mainprops.notificationReducer.user_verify && !Mainprops.notificationReducer.user_reset && (window.location.pathname === '/signin' || window.location.pathname === '/signin/')) {
            const user = {
                username: this.state.username,
                password: this.state.password,
            };
            if (!this.state.submitStatus) {
                store.dispatch(Login(user));
                this.setState({ submitStatus: true });
                return;
            }
        }
        this.redirectConditionally();
        return (
            <section className="login-form item-center">
                <div className="container">
                    <div className={"d-flex "}>
                        <div className="col-md-12  mx-auto cover cover-shadow justify-content-center align-self-center p-0  border-0">
                            {(!Mainprops.actionReducer.Registeruser.hasOwnProperty('mobile') || Mainprops.actionReducer.Loginuser !== 'Account not verified') &&

                                <div className="row m-0">
                                    <div className="col-lg-6 col-md-6 col-sm-12 p-0 inst-bg d-flex">
                                        <div className="first-click-inst">
                                            <h3>{this.props.t('login.login_head', { param: 'react' })}<i
                                                className="fa fa-sign-in ml-2" aria-hidden="true"></i></h3>
                                            <ul className="jobseeker-benefits">
                                                <li>{this.props.t('login.inst1', { param: 'react' })}</li>
                                                <li>{this.props.t('login.inst2', { param: 'react' })}</li>
                                                <li>{this.props.t('login.inst3', { param: 'react' })}</li>
                                                <li>{this.props.t('login.inst4', { param: 'react' })}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mx-auto">
                                        <form className="col-md-12 mx-auto" onSubmit={this.handleSubmit}>
                                            <h4>{this.props.t('login.login', { param: 'react' })}</h4>
                                            <hr />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-12 ">
                                                        <label style={{"fontSize":"12px"}}>{this.props.t('login.login_username', { param: 'react' })}</label>
                                                        <input type="text"
                                                            onChange={(event) => this.handleUserInput(event)}
                                                            className="form-control" id="username" name="username"
                                                            placeholder={this.props.t('login.login_username', { param: 'react' })}
                                                            defaultValue={this.state.username} />
                                                        {validator.message('username', this.state.username, 'required')}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <label style={{"fontSize":"12px"}}>{this.props.t('login.login_password', { param: 'react' })}</label>
                                                        <input type="password"
                                                            onChange={(event) => this.handleUserInput(event)}
                                                            className="form-control form-eye" id="passwordSignIn"
                                                            name="password"
                                                            placeholder={this.props.t('forms.enterPassword', { param: 'react' })}
                                                            defaultValue={this.state.password}

                                                        />
                                                        <span
                                                            className="input fa fa-fw field-icon toggle-password fa-eye-slash"
                                                            onClick={(e) => this.change('passwordSignIn', e)}
                                                            style={{

                                                            }}></span>
                                                    </div>
                                                    <div className="col-sm-6">

                                                        {validator.message('password', this.state.password, 'required')}

                                                        <button type="submit"
                                                            className="btn btn-primary btn-primary2 mt-3">{this.props.t('jobMela.submit', { param: 'react' })}<i
                                                                className="fa fa-sign-in ml-2" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row">

                                                    <div className="col-sm-12">
                                                        <p onClick={() => this.props.router.push('resetpassword')}
                                                            className="mt-2 float-left mr-3" style={{
                                                                color: 'rgb(237, 144, 23)',
                                                                cursor: 'pointer'
                                                            }}>{this.props.t('login.forgot_pass', { param: 'react' })}</p>
                                                        <p className="mt-2 float-left mr-3"
                                                            style={{ color: 'rgb(237, 144, 23)', cursor: 'pointer' }}
                                                            onClick={() => this.props.router.push('/signin-with-otp')}>{this.props.t('login.login_otp', { param: 'react' })}</p>
                                                        <button onClick={() => this.props.router.push('/signup')}
                                                            className="btn btn-primary btn-primary2" style={{
                                                                color: 'rgb(237, 144, 23)',
                                                                cursor: 'pointer'
                                                            }}>{this.props.t('login.register', { param: 'react' })}</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            }
                            {Mainprops.actionReducer.Loginuser === 'Account not verified' &&
                                <div>
                                    <div className="panel-body">
                                        <div className="row">
                                            <form className="col-md-10 mx-auto" onSubmit={this.handleOtpSubmit}>
                                                <div className="m-5">
                                                    Verify Mobile via OTP
                                                <hr />
                                                    <span> Please enter your   code which send on your mobile No: XXXXXX{Mainprops.actionReducer.Registeruser.mobile.substring(6)}</span>
                                                </div>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-3 text-right">
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input type="text"
                                                                onChange={(event) => this.handleUserInput(event)}
                                                                className="form-control" id="opt" name="opt" required />
                                                            <button type="submit"
                                                                className="btn btn-primary mr-2 mt-3">Submit
                                                        </button>
                                                            <p onClick={(event) => this.handleUserSentotp(event)}
                                                                className="mt-4 float-left mr-3" style={{ color: '#2a69fb', cursor: 'pointer' }}>Resend OTP</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Validation(SignIn)));
