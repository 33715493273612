import React, { Component } from 'react';
import { translate, setLanguage } from 'react-multi-lang';


class LanguageSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basePath: window.location.protocol + '//' + window.location.host + '/'
    }
  }
  changeLanguage = (event) => {
    localStorage.setItem('language', event);
    setLanguage(event)
  }

  getEnglishStyle = () => {
    return !localStorage.getItem('language') || localStorage.getItem('language') === 'en' ? "#b21f2d" : ""
  }

  getPunjabiStyle = () => {
    return localStorage.getItem('language') && localStorage.getItem('language') === 'pu' ? "#b21f2d" : ""
  }
  render() {
    return (
      <li className="list-inline-item">
        <span onClick={() => this.changeLanguage('en')} style={{ "color": this.getEnglishStyle(), "cursor": "pointer" }}>English</span>/
        <span onClick={() => this.changeLanguage('pu')} style={{ "cursor": "pointer", "color": this.getPunjabiStyle() }}>ਪੰਜਾਬੀ</span>
      </li>
    )
  }
}

export default translate(LanguageSelector)

