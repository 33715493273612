import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-multi-lang'
import { RegisterJobSeeker, getCountry} from '../../actions/accountAction';
import Validation from "../hoc/Validation";

import store from '../../store';

class ForgienStudyProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitStatus: false,
            user_subtype: window.location.search.split('=')[1],
            stateId: '',
            selectedCheckboxes: new Set()
        }
        store.dispatch(getCountry())
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = "Ghar Ghar - Sign Up";
    }

    async handleUserInput(e) {
        const name = e.target.name;
        let value = e.target.value;

        if(name === 'remember'){
            let selectedCheckboxes = this.state.selectedCheckboxes
            if (selectedCheckboxes.has(value)) {
              selectedCheckboxes.delete(value);
            } else {
              selectedCheckboxes.add(value);
            }
            await this.setState({selectedCheckboxes}, () => {
              value = Array.from(this.state.selectedCheckboxes)
            })
          }

        this.setState({[name]: value, submitStatus: false});
    }

    handleSubmit(event) {
        event.preventDefault();
        const user = {
            email: this.state.email,
            country_id: this.state.country,
            firstname: this.state.institution_name, 
            institution_name: this.state.institution_name,
            mobile: this.state.mobile,
            password: this.state.password,
            website: this.state.website,
            user_subtype: this.state.user_subtype
        };

        if (!this.state.submitStatus && this.props.validator.allValid()) {
            this.setState({submitStatus: true});
            store.dispatch(RegisterJobSeeker(user));
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

   

    render() {
        const {validator} = this.props
        return (
            <div style={{'marginLeft': '3%'}}>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <div className="form-group">
                            <div className="row">

                                <div className="col-sm-12 text-left">
                                    <label> {this.props.t('register_user.institute_name', {param: 'react'})}<span
                                        style={{color: 'red'}} className="ml-2">*</span></label>
                                    <input type="text" placeholder="Enter institute Name"
                                           onBlur={(event) => this.handleUserInput(event)}
                                           className="form-control" name="institution_name"/>
                                    {validator.message('institute name', this.state.institution_name, 'required')}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('register_user.country', {param: 'react'})}<span
                                    style={{color: 'red'}} className="ml-2">*</span></label>
                                <select onChange={(event) => this.handleUserInput(event)} name="country" className="form-control">
                                    <option value={""}>Select country</option>
                                    {this.props.actionReducer.countries && this.props.actionReducer.countries.length ? 
                                    this.props.actionReducer.countries.map((con, key) => {
                                        return (
                                        <option value={con.id} key={key}>{con.name}</option>
                                        )
                                    }): ''}
                                </select>
                                {validator.message('country', this.state.country, 'required')}
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('register_user.organization_website', {param: 'react'})}</label>
                                <input type="text" placeholder="eg. http://www.ghargharrozgar.gov.in"
                                       onBlur={(event) => this.handleUserInput(event)}
                                       className="form-control" id="website" name="website"/>
                                {validator.message('website', this.state.website, 'url')}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('register_user.email', {param: 'react'})}</label>
                                <input type="email" onBlur={(event) => this.handleUserInput(event)}
                                       className="form-control" placeholder="eg. name@example.com" name="email"
                                       id="exampleInputDOB1"
                                />
                                {validator.message('email', this.state.email, 'email')}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <label> {this.props.t('register_user.mobile', {param: 'react'})}<span
                                    style={{color: 'red'}} className="ml-2">*</span></label>
                                <input onChange={(event) => this.handleUserInput(event)}
                                       type="text" className="form-control" placeholder="10 digit mobile number"
                                       name="mobile" id="mobile"
                                />
                                {validator.message('mobile', this.state.mobile, 'required|phone')}

                            </div>
                        </div>
                    </div>
                    

                    <div className="form-group">
                        <div className="row m-0">


                            
                            <div className="col-sm-12 ml-2" >


                                <input className="form-check-input" type="checkbox" name="remember"
                                       onChange={(e) => this.handleUserInput(e)}/>
                               
                                {this.props.t('register_user.terms', {param: 'react'})}<span style={{color: 'red'}}
                                                                                             className="ml-2">*</span>
                                <div className="valid-feedback">Valid.</div>
                                <label className="invalid-feedback">Check this checkbox to continue.</label>
                            </div>

                              <div className="row m-0">
                               {validator.message('terms and conditions', this.state.remember, 'required')}
                              </div>


                            <div className="row m-0 w-100">
                            <button type="submit" className="btn btn-primary btn-primary2 mt-3">{this.props.t('profileDetails.submit', {param: 'react'})}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Validation(ForgienStudyProvider)));
