import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import {
    get_lsp_profile, lsp_update_profile, getJobProviderAddress
} from '../../../actions/lspAction';
import { get_User_District, get_User_Cities, get_User_Subistrict } from '../../../actions/accountAction';
import store from '../../../store';
import Sidebar from './Sidebar';
import Validation from "../../hoc/Validation";
import ServerSetting from '../../../config.json';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router'

class Providerprofile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitStatus: true,
            user_subtype: window.location.protocol + '//' + window.location.host + '/',
            Addresses: [],
            Profile: [],
            stateId: '',
            sundirectoryload: false,
            profileError: '',
            verificationError: '',
            submitStatus1: true,
            loadData: false
        }

        this.imageFormats = [
            'image/png',
            'image/jpg',
            'image/jpeg'
        ]

        this.allowedDocFormats = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ]

        this.validator1 = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });

        this.imageControl1 = React.createRef()
        this.imageControl2 = React.createRef()
        // store.dispatch(get_org_type());
        store.dispatch(get_lsp_profile());
        store.dispatch(getJobProviderAddress());
        store.dispatch(get_User_District());
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.loadData && Object.keys(nextProps.EmployerReducer.Profile).length && nextProps.EmployerReducer.address.length) {
            const newState = { ...nextProps.EmployerReducer.Profile, ...nextProps.EmployerReducer.address[0] }
            this.setState({ 'Profile': newState, loadData: true });
        }


        if (nextProps.EmployerReducer.address && nextProps.EmployerReducer.address[0] && nextProps.EmployerReducer.address[0].hasOwnProperty('district_id') && !this.state.sundirectoryload) {
            store.dispatch(get_User_Subistrict(nextProps.EmployerReducer.address[0].district_id, 'currunt'));
            store.dispatch(get_User_Cities(nextProps.EmployerReducer.address[0].subdistrict_id));
            this.setState({ sundirectoryload: true });
        }
    }

    handleProfileImage(e, key, name) {
        e.persist();
        const files = e.target.files

        const formatIndex = this.imageFormats.findIndex(format => format === files[0].type)
        const Profile = this.state.Profile
        if (formatIndex !== -1) {
            const fileSize = Math.round((files[0].size / 1024));
            if (fileSize > 1024) {
                this.setState({ [key]: 'Image size is too large' })
                setTimeout(() => this.setState({ [key]: '' }), 3000)
                e.target.value = null;
            } else {
                Profile[name] = files[0]
                this.setState({ Profile, submitStatus: false })
            }
        } else {
            this.setState({ [key]: 'Only .jpg, .jpeg and .png formats are allowed' })
            setTimeout(() => this.setState({ [key]: '' }), 3000)
            e.target.value = null;
        }
    }

    updatePosition = (e) => {
        console.log('Event', e)
    }

    handleDocs(e, key, name) {
        e.persist();
        //Doc size 1mb allowed.
        const files = e.target.files
        const formatIndex = this.allowedDocFormats.findIndex(format => format === files[0].type)
        if (formatIndex !== -1) {
            const fileSize = Math.round((files[0].size / 1024));
            if (fileSize > 1024) {
                this.setState({ [key]: 'File size is too large' })
                setTimeout(() => this.setState({ [key]: '' }), 3000)
                e.target.value = null;
            } else {
                const Profile = this.state.Profile
                Profile[name] = files[0]
                this.setState({ Profile, submitStatus: false })
            }
        } else {
            this.setState({ [key]: 'Only doc, docx and pdf files are allowed' })
            setTimeout(() => this.setState({ [key]: '' }), 3000)
            e.target.value = null;
        }
    }


    async componentDidMount() {
        document.title = this.props.t('sidebar.update_profile', { param: 'react' })
    }


    handleProfileInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;
        if (type === 'text' || type === 'textarea') {
            let regex = /^[A-Za-z0-9 ]+$/
            if (!regex.test(value) && value) {
                return false
            }
        }
        let Profile = this.state.Profile;
        Profile[name] = value;
        this.setState({ Profile, submitStatus: false });
    }

    handleAddressesInput(e) {
        const name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;
        if (type === 'text' || type === 'textarea') {
            let regex = /^[A-Za-z0-9 ]+$/
            if (!regex.test(value) && value) {
                return false
            }
        }
        let Profile = this.state.Profile;
        if (name === 'district_id') {
            store.dispatch(get_User_Subistrict(value));
            delete Profile["subdistrict_id"]
            delete Profile['city_id']
        }
        if (name === 'subdistrict_id') {
            store.dispatch(get_User_Cities(value));
            delete Profile['city_id']
        }

        Profile[name] = value;
        this.setState({ Profile, submitStatus1: false });
    }

    handleSubmitProfile(event) {
        event.preventDefault()
        let Profile = this.state.Profile;
        Profile['auth_key'] = localStorage.getItem('token');
        this.setState({ 'Profile': Profile });

        if (this.props.validator.allValid() && !this.state.submitStatus) {
            store.dispatch(lsp_update_profile(Profile));
            this.props.validator.hideMessages();
            this.imageControl1.current.value = ""
            this.imageControl2.current.value = ""
            this.setState({ submitStatus: true })
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleSubmitAddressInfo(event) {
        event.preventDefault()
        let Profile = this.state.Profile;
        Profile['auth_key'] = localStorage.getItem('token');
        Profile['address_subtype_id'] = 1
        Profile['isSame'] = 1
        this.setState({ 'Profile': Profile });

        if (this.validator1.allValid() && !this.state.submitStatus1) {
            store.dispatch(lsp_update_profile(Profile));
            this.validator1.hideMessages();
            this.setState({ submitStatus1: true })
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.validator1.showMessages();
            this.forceUpdate();
        }
    }

    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map((data, index) => {
                if (data.state_id === this.state.Profile.state_id) {
                    data.dist.map(function (distdata, distindex) {
                        Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
                        return Distoption
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }

    render() {

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const { validator } = this.props

        let Mainprops = this.props;
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        let Mainthis = this;

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">

                <div className="modal fade bd-example-modal-md" id="verificationDetails" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.props.t('lsp_user.police_ver1', { param: 'react' })}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body pt-1">
                                <ol className="px-2 m-0">
                                    <li>{this.props.t('lsp_user.police_ver2', { param: 'react' })} <a className="date-clr" href="http://ppsaanjh.in/" rel="noopener noreferrer" target="_blank">{this.props.t('lsp_user.link', { param: 'react' })}</a>.</li>
                                    <li>{this.props.t('lsp_user.police_ver3', { param: 'react' })}.</li>
                                    <li>{this.props.t('lsp_user.police_ver4', { param: 'react' })}.</li>
                                    <li>{this.props.t('lsp_user.police_ver5', { param: 'react' })}.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="clearfix"></div>

                            <div className="row m-0 bg-white">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li data-placement="bottom"
                                        className="nav-item">
                                        <a className={'nav-link mr-0 active show'} data-toggle="tab" id="menuTab1"
                                            href="#home">{this.props.t('sidebar.profile_details', { param: 'react' })}</a>
                                    </li>

                                    <li
                                        data-placement="bottom"
                                        className="nav-item">
                                        <a className="nav-link mr-0" data-toggle="tab" id="menuTab2"
                                            href="#menu2">{this.props.t('counsellingProvider.addressInfo', { param: 'react' })}</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab-content profile-page">
                                <div id="home" className="tab-pane bg-education active pt-4">
                                    <form onSubmit={(event) => this.handleSubmitProfile(event)}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h6 className="mb-0 mt-2">{this.props.t('sidebar.profile_details', { param: 'react' })}</h6>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row form-group">
                                            <div className="col-sm-6 col-lg-5 text-right">
                                                <label>{this.props.t('lsp_user.provider_name', { param: 'react' })} :</label><span
                                                    style={{ color: 'red' }} className="ml-2">*</span>
                                            </div>
                                            <div className="col-sm-6 col-lg-5">
                                                <input type="text" className="form-control"
                                                    onChange={(event) => Mainthis.handleProfileInput(event)}
                                                    value={this.state.Profile.firstname ? this.state.Profile.firstname : ''}
                                                     name="firstname"
                                                    placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('lsp_user.provider_name', { param: 'react' })}
                                                />
                                                {validator.message('Service Provider name', this.state.Profile.firstname, 'required')}
                                            </div>
                                        </div>
                                        <div className="form-group mb-0 pb-4">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-5 text-right">
                                                    <label>{this.props.t('counsellingProvider.img_upload', { param: 'react' })} : <span
                                                        style={{ color: 'red' }} className="ml-2">*</span></label>
                                                </div>

                                                <div className="col-sm-6 col-lg-5">
                                                    <img alt=""
                                                        src={this.props.EmployerReducer.Profile.profile_image ? `${SERVER_PATH}${this.props.EmployerReducer.Profile.profile_image}` : 'images/img-1.png'}
                                                        className="img-fluid profile-img mb-2" />
                                                    <input name="profile_image" accept={this.imageFormats.toString()}
                                                        type="file"
                                                        ref={this.imageControl1}
                                                        onChange={(e) => Mainthis.handleProfileImage(e, 'profileError', 'profile_image')} />

                                                    {validator.message('profile image', this.state.Profile.profile_image, 'required')}
                                                    {this.state.profileError && <p style={{
                                                        "color": "red",
                                                    }}>{this.state.profileError}</p>}
                                                    <span className={'edit-img d-block'}>
                                                        {this.props.t('profileDetails.uploadInst', { param: 'react' })} </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-0 pb-4">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-5 text-right">
                                                    <label>{this.props.t('lsp_user.police_ver', { param: 'react' })} : <span
                                                        style={{ color: 'red' }} className="ml-2">*</span></label>

                                                </div>

                                                <div className="col-sm-6 col-lg-5">
                                                    {this.props.EmployerReducer.Profile.police_verification ? <a className="break" style={{ wordBreak: "break-all" }}
                                                        rel="noopener noreferrer" target="_blank" href={SERVER_PATH + this.props.EmployerReducer.Profile.police_verification}>
                                                        {SERVER_PATH + this.props.EmployerReducer.Profile.police_verification}
                                                    </a> : null}
                                                    <input name="police_verification" accept={this.allowedDocFormats.toString()}
                                                        type="file"
                                                        ref={this.imageControl2}
                                                        onChange={(e) => Mainthis.handleDocs(e, 'verificationError', 'police_verification')} />

                                                    {validator.message('police_verification', this.state.Profile.police_verification, 'required')}
                                                    {this.state.verificationError && <p style={{
                                                        "color": "red",
                                                    }}>{this.state.verificationError}</p>}
                                                    <span className={'edit-img d-block'}>
                                                        {this.props.t('profileDetails.docsInst', { param: 'react' })} </span>
                                                    <span className={'edit-img d-block'} style={{ cursor: "pointer" }}
                                                        data-toggle="modal" data-target="#verificationDetails"
                                                    >
                                                        {this.props.t('lsp_user.police_ver1', { param: 'react' })}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-6 col-lg-5 text-right">
                                                <label>{this.props.t('jobProviderProfile.website', { param: 'react' })} :</label>
                                            </div>

                                            <div className="col-sm-6 col-lg-5">
                                                <input type="url"
                                                    onChange={(e) => this.handleProfileInput(e)}
                                                    value={this.state.Profile.website ? this.state.Profile.website : ''}
                                                    className="form-control" id="website"
                                                    name="website"
                                                    placeholder="http://example.com" />
                                            </div>
                                        </div>


                                        <div className="row form-group">
                                            <div className="col-sm-6 col-lg-5 text-right">
                                                <label>{this.props.t('lsp_user.service_time_start', { param: 'react' })} :<span
                                                    style={{ color: 'red' }} className="ml-2">*</span></label>
                                            </div>

                                            <div className="col-sm-6 col-lg-5">
                                                <input type="time"
                                                    onChange={(e) => this.handleProfileInput(e)}
                                                    value={this.state.Profile.service_start_time ? this.state.Profile.service_start_time : ""}
                                                    className="form-control" id="address3"
                                                    name="service_start_time"
                                                    placeholder="Enter Service Time Start" />
                                                {validator.message('service_start_time', this.state.Profile.service_start_time, 'required')}
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-6 col-lg-5 text-right">
                                                <label>{this.props.t('lsp_user.service_time_end', { param: 'react' })} :<span
                                                    style={{ color: 'red' }} className="ml-2">*</span></label>
                                            </div>

                                            <div className="col-sm-6 col-lg-5">
                                                <input type="time"
                                                    onChange={(e) => this.handleProfileInput(e)}
                                                    value={this.state.Profile.service_end_time ? this.state.Profile.service_end_time : ""}
                                                    className="form-control"
                                                    name="service_end_time"
                                                    placeholder="Enter Service Time End" />
                                                {validator.message('service_end_time', this.state.Profile.service_end_time, 'required')}
                                            </div>
                                        </div>
                                         {/* <div className="row form-group">
                                                <div className="col-sm-6 col-lg-5 text-right">
                                                    <label>{this.props.t('lsp_user.service_type', { param: 'react' })} :<span
                                                        style={{ color: 'red' }} className="ml-2">*</span></label>
                                                </div>

                                                <div className="col-sm-6 col-lg-5">
                                                    <select
                                                        onChange={(e) => this.handleProfileInput(e)}
                                                        value={this.state.Profile.service_category ? this.state.Profile.service_category : ''}
                                                        className="form-control"
                                                        name={"service_category"}>
                                                        <option value="">{this.props.t('forms.jobType', { param: 'react' })}

                                                        </option>
                                                        {this.props.actionReducer.Orgtype && this.props.actionReducer.Orgtype.length ?
                                                            this.props.actionReducer.Orgtype.map((org, key) => {
                                                                return (
                                                                    <option value={org.id} key={key}>{org.name}</option>
                                                                )
                                                            }) : ''}

                                                    </select>
                                                    {validator.message('service_type', this.state.Profile.service_category, 'required')}
                                                </div>
                                            </div> */}
                                        <hr></hr>
                                        <div className="form-group mb-0 pb-4">
                                            <div className="row">
                                                <div
                                                    className="col-sm-6 col-lg-5 text-right"></div>
                                                <div className=" col-sm-6 col-lg-5">
                                                    <button type="submit"
                                                        className="btn btn-primary btn-primary2 mr-2">{this.props.t('jobProviderProfile.submit', { param: 'react' })}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div id="menu2" className="tab-pane bg-education fade pt-4">

                                    <form onSubmit={(event) => this.handleSubmitAddressInfo(event)}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h6 className="mb-0 mt-2">{this.props.t('counsellingProvider.addressInfo', { param: 'react' })}</h6>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-5 text-right">
                                                    <label>{this.props.t('jobProviderProfile.add1', { param: 'react' })}:</label>
                                                </div>
                                                <div className=" col-sm-6 col-lg-5">
                                                    <input type="text" className="form-control"
                                                        onChange={(e) => this.handleAddressesInput(e)}
                                                        value={this.state.Profile.address_Line1 ? this.state.Profile.address_Line1 : ''}
                                                        id="address1" name="address_Line1"
                                                        placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('jobProviderProfile.add1', { param: 'react' })} />
                                                    {this.validator1.message('address line 1', this.state.Profile.address_Line1, 'required')}

                                                </div>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-6 col-lg-5 text-right">
                                                <label>{this.props.t('jobProviderProfile.add2', { param: 'react' })} :</label>
                                            </div>
                                            <div className="col-sm-6 col-lg-5">
                                                <input type="text" className="form-control"
                                                    onChange={(e) => this.handleAddressesInput(e)}
                                                    value={this.state.Profile.address_Line2 ? this.state.Profile.address_Line2 : ""}
                                                    id="address2" name="address_Line2"
                                                    placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('jobProviderProfile.add2', { param: 'react' })} />
                                                {this.validator1.message('address line 2', this.state.Profile.address_Line2, 'required')}
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-5 text-right">
                                                    <label>{this.props.t('jobProviderProfile.district', { param: 'react' })} :<span
                                                        style={{ color: 'red' }}
                                                        className="ml-2">*</span></label>
                                                </div>

                                                <div className="col-sm-6 col-lg-5">
                                                    <select
                                                        value={this.state.Profile.district_id ? this.state.Profile.district_id : ""}
                                                        className="form-control" id="district"
                                                        name="district_id"
                                                        onChange={(e) => this.handleAddressesInput(e)}>
                                                        <option value="">{this.props.t('forms.selectDis', { param: 'react' })}</option>
                                                        {this.createDistoption()}
                                                    </select>
                                                    {this.validator1.message('district', this.state.Profile.district_id, 'required')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-5 text-right">
                                                    <label>{this.props.t('profileDetails.subDistrict/taluka/tehsil', { param: 'react' })} :</label><span
                                                        style={{ color: 'red' }}
                                                        className="ml-2">*</span>
                                                </div>

                                                <div className="col-sm-6 col-lg-5">
                                                    <select
                                                        value={this.state.Profile.subdistrict_id ? this.state.Profile.subdistrict_id : ""}
                                                        className="form-control" id="subdistrict_id"
                                                        name="subdistrict_id"
                                                        onChange={(e) => this.handleAddressesInput(e)}>
                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('profileDetails.subdistrict', { param: 'react' })}</option>
                                                        {this.props.actionReducer.Subdistrict && this.props.actionReducer.Subdistrict.length ?
                                                            this.props.actionReducer.Subdistrict.map((sub, key) => {
                                                                return <option key={key} value={sub.id}>{sub.name}</option>
                                                            })

                                                            : null}
                                                    </select>
                                                    {this.validator1.message('sub district', this.state.Profile.subdistrict_id, 'required')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-5 text-right">
                                                    <label>{this.props.t('jobProviderProfile.city/village', { param: 'react' })}:<span
                                                        style={{ color: 'red' }}
                                                        className="ml-2">*</span></label>
                                                </div>

                                                <div className=" col-sm-6 col-lg-5">
                                                    <select
                                                        value={this.state.Profile.city_id ? this.state.Profile.city_id : ""}
                                                        onChange={(event) => Mainthis.handleAddressesInput(event)}
                                                        className="form-control"
                                                        name="city_id">
                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('jobProviderProfile.city/village', { param: 'react' })}</option>
                                                        {Mainprops.actionReducer.Cities.length > 0 &&
                                                            Mainprops.actionReducer.Cities.map(function (data, index) {
                                                                return (
                                                                    <option key={index}
                                                                        value={data.id}>{data.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {this.validator1.message('city/village', this.state.Profile.city_id, 'required')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-5 text-right">
                                                    <label>{this.props.t('JobPost.cont_phone', { param: 'react' })}:</label>
                                                </div>

                                                <div className=" col-sm-6 col-lg-5">
                                                    <input
                                                        type="tel"
                                                        placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('JobPost.cont_phone', { param: 'react' })}
                                                        value={this.state.Profile.c_phone ? this.state.Profile.c_phone : ''}
                                                        className="form-control"
                                                        readOnly="readonly"
                                                        name="c_phone" />

                                                </div>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="form-group mb-0 pb-4">
                                            <div className="row">
                                                <div
                                                    className="col-sm-6 col-lg-5 text-right"></div>
                                                <div className=" col-sm-6 col-lg-5">
                                                    <button type="submit"
                                                        className="btn btn-primary btn-primary2 mr-2">{this.props.t('jobProviderProfile.submit', { param: 'react' })}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(Providerprofile)));
