import React, { Component } from 'react'
import JobDetails from './jobseeker/JobDetails'
import { connect } from 'react-redux';
import store from '../../store';
import SidebarProvider from './jobprovider/Sidebar';
import SidebarSeeker from './jobseeker/Sidebar';
import { getPostArchiveView, setPostArchive } from '../../actions/employerAction';
import { applyJob } from '../../actions/jobseekerAction';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router'

class JobDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentJob: []
        }
    }

    componentDidMount() {
        if (this.props.routeParams.jobId) {
            store.dispatch(getPostArchiveView({ job_id: this.props.routeParams.jobId }))
        }
        document.title = this.props.t('header.job_details', { param: 'react' })
    }

    applyForJob = async () => {
        await store.dispatch(applyJob({ job_id: this.props.routeParams.jobId }, false));
        this.props.router.push('/myaccount')
    }

    componentWillUnmount() {
        store.dispatch(setPostArchive([]))
    }

    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        const Sidebar = localStorage.getItem('login_type') === 'Jobseeker'
            ? SidebarSeeker
            : SidebarProvider
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={this.props.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            {this.props.EmployerReducer.postArchive ?
                                <JobDetails
                                    jobData={this.props.EmployerReducer.postArchive}
                                    submit={this.applyForJob}
                                /> : ''}
                        </main>
                    </div>
                </div>
            </section>

        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(JobDetailPage));
