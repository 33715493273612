import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import store from '../../store';
import { Link } from 'react-router';

// import { getschemeslist } from '../../actions/jobseekerAction';
import {getSchemeList} from '../../actions/accountAction';

class schemesList extends Component {

    constructor(props){
        super(props);
        store.dispatch(getSchemeList());
    }


    componentDidMount() {
        document.title = "Scheme List"
    }

    render() {
        var data=this.props.actionReducer.getschemelist;
        // console.log(data)
  
        return (
            <div className="tab-content" style={{ border: "none" }}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading">
                                        <h6 className="mb-0"><span>Schemes/Projects</span>
                                        <span class="float-right p-0 mt-0" ><a href="/aboutus"><u style={{'color':'#000', fontSize:'12px'}}>Home-Skill Development</u></a></span></h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0 p-3">
                                    {/* {data && data.map((d, key) => {
                                                                return (     
                                                                <>
                                                                <div>
                                                                    <span><b style={{fontSize:'18px'}}>{d.scheme_title}</b>-<i> <strong>{d.scheme_subtitle}</strong></i></span>
                                                                 {d.description1 ?   <p style={{marginTop:'2%',textAlign:'justify'}}>
                                                                   {d.description1}
                                                                    </p> :''}
                                                                    {d.description2 ? <p style={{marginTop:'1%',textAlign:'justify'}}>
                                                                        {d.description2}
                                                                    </p> :''}
                                                                    {d.description3 ? <p style={{marginTop:'1%',textAlign:'justify'}}>
                                                                        {d.description3}
                                                                    </p>:''}
                                                                                             
                                                                </div>
                                                                                             <hr/>
                                                                                             </>
                                                                     )})} */}
                                        <div>
                                            <span><b style={{fontSize:'18px'}}>1. DAY-NULM</b>-<i> <strong>Deendayal Antodaya Yojana National Urban Livelihood Mission to train poor urban youth.</strong></i></span>
                                          <p>  The Employment Linked Skill Training and Placement (ESTP ) is one of the important schemes of the Ministry of Housing and Urban Poverty Alleviation and is being administered by the Punjab Skill Development Mission. Employment through Skill Training & Placement (EST&P) Component under NULM is designed to provide skills to the unskilled urban poor as well asto upgrade their existing skills. 
</p><p>The EST&P Programme intends to fill   the   gap   between demand and availability of local skills by providing skill   training programmes as   required   by   the market. The scheme aims on employment (both wage and self) on an annual basis of at least 70% of the successfully certified trainees within three months of completion of training, with at least 50% of the trainees passing out being placed in wage employment.
</p><p>The scheme is funded by Central Government and State Government in the ratio 60:40.
</p><p><b>Eligibility of Candidates: </b></p>
<p>
•	The candidates should be from the urban poor households only. <br/>
•	The age of candidate between 18-35 years<br/>
•	Candidate should not have undergone skill development training under the SJSRY / NULM in any other trade during the last 3 years. However, Candidate can be provided advanced training on the skills acquired in any previous training.<br/>
•	The candidate should meet the minimum qualification as per requirement of the training curriculum as designed by NSDC.</p>

                                           
                                           {/* <p style={{marginTop:'2%',textAlign:'justify'}}>To reduce poverty and vulnerability of the urban poor households by enabling
                                                them to access gainful self-employment and skilled wage employment
                                                opportunities, resulting in an appreciable improvement in their livelihoods on
                                                a sustainable basis, through building strong grassroots level institutions of the
                                                poor. The mission would aim at providing shelter equipped with essential
                                                services to the urban homeless in a phased manner. In addition, the Mission
                                                would also address livelihood concerns of the urban street vendors by
                                                facilitating access to suitable spaces, institutional credit, social security and
                                                skills to the urban street vendors for accessing emerging market
                                                opportunities.
                                            </p>
                                            <p style={{marginTop:'1%',textAlign:'justify'}}>The Employment through skill training & placement(EST&P) program will
                                                provide for skill training of the urban poor to enable them setting up self-
                                                employment ventures and for salaried jobs in the private sector. The EST&P
                                                Program intends to fill the gap between the demand and availability of local
                                                skills by providing skill training programs as required by the market.
                                            </p>
                                            <p style={{marginTop:'1%',textAlign:'justify'}}>
                                                    <b>Target allocation to States</b> <br/>
                                                    The state of Punjab has been allocated a target of 30000 candidates to be trained during the
                                                    Financial Year 2022-23.
                                            </p> */}
                                                                     
                                        </div>
                                                                     <hr/>
                                        <div>
                                            <span><b style={{fontSize:'18px'}}>2. DDUGKY</b>-<i> <strong>Deendayal Upadhyay Gramin Kaushal Yojana to train poor rural youth.</strong></i></span>
                                           <p style={{marginTop:'2%',textAlign:'justify'}}>Deen Dayal Upadhyaya Grameen Kaushalya Yojana (DDU-GKY) aims to skill rural youth
                                                    who are poor and provide them with jobs having regular monthly wages or above the
                                                    minimum wages. It is one of the flagship scheme of the Ministry of Rural Development,
                                                    Government of India that seeks to promote rural livelihoods. It is a part of the National
                                                    Rural Livelihood Mission (NRLM) - the Mission for poverty reduction called Aajeevika. The
                                                    scheme will benefit more than 55 million poor rural youth by 2022 who are ready to be
                                                    skilled by providing sustainable employment. This scheme derives importance from its
                                                    potential to reduce poverty.
                                            </p>
                                            <p style={{marginTop:'1%',textAlign:'justify'}}><b>Implementation model under DDU-GKY</b><br/>
                                                    DDU-GKY follows a 3-tier implementation model. The DDU-GKY National Unit at MoRD functions
                                                    as the policy-making, technical support and facilitation agency. The DDU-GKY State Missions
                                                    provide implementation support; and the Project Implementing Agencies implement the
                                                    programme through skilling and placement projects. Punjab Skill Development Mission is the
                                                    nodal agency for implementation of the scheme in the state of Punjab
                                            </p>
                                            {/* <p style={{marginTop:'1%',textAlign:'justify'}}>
                                                    <b>Target allocation to States</b> <br/>
                                                    The state of Punjab has been allocated a target of 43029 candidates to be trained during the
                                                    Financial Year 2019-23. The category wise target achievement should be 50% SC/ST, 43%
                                                    minorities and 7% special groups. One third of the beneficiaries should be also women
                                                    candidates as per the mandate of the scheme.
                                            </p> */}
                                                                     
                                        </div>

                                        <hr/>
                                        <div>
                                           <p style={{fontSize:'18px'}}><b>3.Pradhan Mantri Kaushal Vikas Yojana (PMKVY)</b><br/>
                                          <p> Pradhan Mantri Kaushal Vikas Yojana (PMKVY) scheme is 100% central funded flagship schemes of Ministry of Skill Development & Entrepreneurship (MSDE) for 2020-21.  
                                           In this scheme, any unemployed candidate or school/college dropout of rural and urban area can get free employment oriented skill training in National Skill Qualification Framework 
                                           (NSQF) aligned job roles. The scheme has 3 sub components as below:<br/></p>
                                           <p>
                                            1.	Short Term Training (STT) <br/>
                                            2.	Recognition of Prior Learning (RPL) <br/>
                                            3.	Special Projects (2020-21)<br/>
                                            </p></p>
                                            <p>
                                            This scheme is applicable to any candidate of Indian nationality who: <br/>
                                            • 	Is aged between 15-45 years (for STT) and 18-59 years (for RPL) <br/>
                                            • 	Possesses an Aadhaar card and an Aadhaar linked bank account <br/>
                                            •	Candidate should also fulfill the basic minimum eligibility criteria for particular job role set up by concerned Sector Skill Council. <br/>
                                            
                                            <p style={{marginTop:'2%',textAlign:'justify'}}><b> i).	Short Term Training (STT):  </b>
                                            The training under the STT courses generally range between 200-600 hours (2 to 6 months). The courses are National Skills Qualification Framework (NSQF) aligned and imparted at accredited & affiliated Training Centres. 
                                            </p>
                                            <p style={{marginTop:'2%',textAlign:'justify'}}><b> ii).	Recognition of Prior Learning programme under PMKVY 3</b>
                                            Under Recognition of Prior Learning programme (RPL), training (3 to 4 days) and certification is provided to the existing workers who are not certified in the concerned job role in which they are working. This certification will not only help the workers to get a recognition/GoI approved certification of their skills but also lead to their vertical mobility and lifelong learning.
                                            </p>
                                            <p style={{marginTop:'2%',textAlign:'justify'}}><b> iii).	Special Projects:-</b>

Special Projects undertake project-based skilling interventions, primarily to meet the skilling needs of marginalized or vulnerable groups ( Scheduled Castes and Tribes, transgenders, persons with disabilities, women, economically backward youth, any other category which identifies as marginalized/vulnerable and is recognized by Government of India and State governments).
<br/>MSDE has just launched PMKVY4 project recently to realign the scheme with a core focus on making the existing ecosystem more flexible, swift and geared to meet the current challenges and emerging needs.
                                            </p>

                                            </p>
                                            {/* <p style={{marginTop:'1%',textAlign:'justify'}}><b>Short Term Training (STT):</b>
                                             The training under the STT courses generally range between
                                                200-600 hours (2 to 6 months). The courses are National Skills Qualification Framework
                                                (NSQF) aligned and imparted at accredited & affiliated Training Centres. School / college
                                                dropouts or unemployed youth of Indian nationality shall benefit from the scheme.
                                                Successfully certified candidates shall be provided placement / entrepreneurship /
                                                apprenticeship assistance

                                            </p>
                                            <p style={{marginTop:'1%',textAlign:'justify'}}>
                                                    <b>Recognition of Prior Learning programme under PMKVY 3</b> <br/>
                                                    
                                                    PSDM has also initiated free upskilling and certification of candidates under Recognition of Prior
                                                    Learning programme wherein the existing workers who are not certified in the concerned job
                                                    role in which they are working can also get training and certification matching their knowledge
                                                    and skills in 3-4 days. This certification will not only help the workers to get a recognition/GoI
                                                    approved certification of their skills but also lead to their vertical mobility and lifelong learning.
                                                    COVID Crash Course Program (CCCP) of health sector under CSSM component of PMKVY 3.0
                                                    The COVID pandemic has brought in multiple challenges with serious implications on lives and
                                                    livelihood, businesses and economy across the country. Given the magnitude of crisis and surge
                                                    in number of cases, the healthcare sector has been under immense pressure to address the
                                                    shortage of COVID frontline workers, essential supplies and trained staff to deliver appropriate
                                                    healthcare delivery to citizens. Availability of more healthcare workers is the need of the hour
                                                    to put up a brave front in our fight against COVID.
                                            </p> */}
                                                                     
                                        </div>
                                        <hr/>
                                        <div>
                                            <span><b style={{fontSize:'18px'}}>4. SANKALP</b>-<i> <strong>Skill Acquisition and Knowledge Awareness for Livelihood Promotion.</strong></i></span>
                                           <p style={{marginTop:'2%',textAlign:'justify'}}>
                                           Skill Acquisition and Knowledge Awareness for Livelihood Promotion (“SANKALP”) is a
                                            programme of the Ministry of Skill Development with loan assistance from the World Bank. It
                                            aims to improve short term skill training qualitatively and quantitatively through strengthening
                                            institutions, bring in better market connectivity and inclusion of marginalised sections of the
                                            society. SANKALP was launched on 19th January 2018 and has a tenure till March 2023.
                                            </p>
                                            <p style={{marginTop:'1%',textAlign:'justify'}}>
                                            The outcomes in the project are measured through the Results Framework, and Disbursement
                                            Linked Indicators (DLIs) agreed between MSDE and World Bank.

                                            </p>

                                        
                                        <hr/>
                                        <div>
                                            <span><b style={{fontSize:'18px'}}>5. National Apprenticeship Promotion Scheme (NAPS)</b></span>
                                          <p>  Ministry of Skill Development & Entrepreneurship is focusing on engaging apprentices in industry through National Apprenticeship Promotion Scheme (NAPS) launched in 2016. PSDM engage candidates as apprentices in Optional Trades under NAPS as per Apprenticeship Act.
                                          </p>
                                            <p>All establishments having work force (regular and contract employees) of 30 or more are mandated to undertake Apprenticeship Programs in a range from 2.5% to 15% of its workforce (including direct contractual employees) every year.
                                            </p>
                                            <p>    For establishments having a workforce between 4 to 29; this is optional.
                                                Establishments having a workforce of 3 or less is not permitted to engage apprentices. It aims to provide financial support to establishments undertaking the apprenticeship training. The scheme has the following two components:
                                                Reimbursement of 25% of prescribed stipend subject to a maximum of Rs. 1500/- per month per apprentice by the Government of India to all employers who engage apprentices.
                                            </p>
                                            <p>Any individual who has completed 14 years of age (18yrs in case of hazardous industries defined under the apprenticeship rules), is a minimum of fifth class pass, passes the standard of physical fitness for the course and have minimum educational qualification prescribed  for a trade can undergo apprenticeship training. Minimum prescribed stipend  ranges from 5000 to 9000 per month under NAPS.
                                            </p>
                                           
                                           {/* <p style={{marginTop:'2%',textAlign:'justify'}}>NAPS was launched in August 2016 by Government of India to promote the Apprenticeship in the
                                                country by providing financial incentives, technology and advocacy support.

                                            </p>
                                            <p style={{marginTop:'1%',textAlign:'justify'}}><b>Key Features</b></p>
                                            <p style={{marginTop:'1%',textAlign:'justify'}}>
                                                1. Wider option for the apprentices-integration with other schemes - Courses approved by State
                                                Government/Central Government such as PMKVY, DDU-GKY etc. shall be linked with
                                                apprenticeship training. These courses will be given status of optional trades & the relevant
                                                practical content for on-the-job training shall be added by respective course approving authority.
                                            </p>
                                            <p style={{marginTop:'1%',textAlign:'justify'}}>
                                                2. Ease of Administering through technology - A specially designed online portal
                                                “www.apprenticeshipindia.org” shall be used for administering the entire implementation of the
                                                Apprenticeship Training online. It shall facilitate the requirements of all key stakeholders such as
                                                Candidates, Industry, DGT, RDSDEs, NSDC, SAA, SSDMs and BTPs.

                                            </p>
                                            <p style={{marginTop:'1%',textAlign:'justify'}}>
                                                 3. Involvement of States/UTs - As per the Apprentices Act, monitoring of apprenticeship training
                                                in State Public Sector Undertakings and Private Sector Establishments is done by the respective
                                                State Governments. States have a very important role in implementing the programme as most
                                                of the smaller Industries and MSMEs come under the State jurisdiction. Hence sensitizing the
                                                states & State Skill Development Missions (SSDMs) & also officers up to the District level, on the
                                                intent of the reforms becomes critical & a matter of priority. The District Skilling Committees
                                                being promoted by both the Central and State governments will be mandated to identify
                                                apprenticeship opportunities in their district and ensure appropriate utilisation of same.

                                            </p>
                                            <p style={{marginTop:'1%',textAlign:'justify'}}>
                                                4. Promoter and Facilitators/Third Party Aggregators (TPA) - Since this scheme involves multiple
                                                stakeholders, the role of facilitators or Third Party Aggregators (TPAs) becomes important for
                                                mobilizing the apprentices, mapping their preferences with the demand from the establishments
                                                for apprenticeship opportunities posted on the portal, and helping the establishment in
                                                identifying Basic Training Providers. TPAs are engaged as per the guidelines issued by MSDE for
                                                their selection.
                                            </p>                      */}
                                        </div>
                                         <hr/>
                                         <div>
                                         <span><b style={{fontSize:'18px'}}>6.	World skills Competitions</b></span>
                                        <p>World Skills organises the world championships of vocational skills, and is held every two years in different parts of the world. The organisation, which hosts conferences about vocational skills, describes itself as the global hub for skills.
                                        </p><p>World Skills brings together young people, industry, government, education, and institutions, to promote the benefits of and need for skilled trade professionals. The aims of the competition include demonstrating the advantages of learning a vocational skill, and encouraging 'parity of esteem' between vocational and academic qualifications.

                                        </p><p>Any individual below the age of 23 years can participate in the competition (Few Skills Exception). No specific academic qualification is required for the participants. It is not necessary that a candidate is studying or qualified. Any candidate who possesses particular skill and fulfils the age criteria is eligible for the competition.

                                        </p><p>Candidates selected at State level further participate in Regional and then National. After National Skill Competitions they are trained by team India Skills of NSDC and further participate in World Skill Competitions. Winners of State, National and World Skill Competitions are awarded at various levels. Competitions are held in 56 trades such as Bakery, Beauty Therapist, Carpentry, CNC Milling, CNC Turning, Cooking, Electronics, Fashion Technology, Hairdressing, Mechatronics, Mechanical Engineering Design(CAD), Painting and Decorating, Plumbing and Heating and Welding etc.
                                        </p>
                                         </div>
                                         <hr/>

                                        <div>
                                        <span><b style={{fontSize:'18px'}}>7.	Implementation of Employment Guaranteed Tech Bee Program in association with  HCL Technologies</b></span>
                                        <p>Punjab Skill Development Mission (PSDM) under the aegis of Department of Employment Generation, Skill Development &Training is collaborating with HCL for an Employment Guarantee Program- “Tech Bee”.  It is an Early Career Program for 12th class passed outs with Mathematics/Business Mathematics who wish to pursue career in IT.

                                        </p><p>PSDM will fund the fee of first 200 candidates (full fee of first 100 candidates and 50% fee of next 100 candidates) who get selected from the meritorious schools of Punjab.

                                        </p><p>The program comprises of two parts- 6 months Class Room Training (CRT) and 6 months Internship. Candidates will be provided laptops during CRT and a stipend of INR 10,000/month during internship by HCL. This training will be on Future Technologies and candidates will get opportunity to work on live projects of HCL under the mentorship of well experienced trainers.

                                        </p><p>Internship will continue across India leading to assured placement in any of HCL campuses at Noida, Chennai, Hydrabad, Bengaluru, Madurai, Vijaywada, Nagpur and Lucknow. HCL has projects in 35 countries such as- USA, Canada, England, Australia, France, Singapore etc. in which candidates may get opportunity to work depending upon their capabilities and performance.
                                        </p><p>On completion of CRT and internship, candidate will be employee of HCL and can pursue higher education partially funded by HCL from renowned universities like BITS Pilani, Amity, IIM Nagpur and SASTRA University.
                                        </p>
                                        </div>
                                        <div>
                                            <span><b style={{fontSize:'18px'}}>8. Training Centers owned by PSDM</b></span>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i> 198 Rural Skill Centers (RSCs)   | <a href="/docs/rsc_list_updated.pdf" target="_blank"><u >Check List</u></a> |</b><br/>
                                                <span style={{marginLeft:'2%'}}> Rural Skill Centers constructed in the premises of Govt. Schools are operated by training partners to cater to poor in rural hinterland.   </span>  
                                            </p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i> 5 Multi Skill Development Centers (MSDCs)</b><br/>
                                                <span style={{marginLeft:'2%'}}> Operational in PPP mode with National Skill Development Corporation (NSDC) affiliated Training Partners across multiple skills. </span>  
                                            </p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i> 3 Health Skill Development Centers (HSDCs)</b><br/>
                                                <span style={{marginLeft:'2%'}}> Operational in PPP mode with National Skill Development Corporation (NSDC) affiliated Training Partners across skills in health sector. </span>  
                                            </p>
                                         </div> 

                                         <hr/>
                                         <div>
                                            <span><b style={{fontSize:'18px'}}>9. Jal Jeevan</b>-<i> <strong>Jal Jeevan Mission to recognise prior learning in Plumber, fitter & electrician jobs.</strong></i></span>                       
                                        </div>

                                        {/* <hr/> */}
                                         {/* <div>
                                            <span><b style={{fontSize:'18px'}}>10. PHTPB</b>-<i> <strong>Punjab Heritage & Tourism Promotion Board scheme to train youth in traditional handicrafts.</strong></i></span>                       
                                        </div> */}

                                        <hr/>
                                        <div>
                                            <span><b style={{fontSize:'18px'}}>10. Skill Training of Sewerage workers</b>-<i> <strong>A special project for training of Sewerage workers in related technologies.</strong></i></span>                       
                                        </div>

                                        {/* <hr/>
                                         <div>
                                            <span><b style={{fontSize:'18px'}}>12. MKMM</b>-<i> <strong>Mera Kamm Mera Maan scheme to assist youth for skilling and then wage/self employment with a subsistence allowance component.</strong></i></span>                       
                                        </div> */}

                                        <hr/>
                                         <div>
                                            <span><b style={{fontSize:'18px'}}>11. Psychometrics</b>-<i> <strong>Psychometric Assessment of candidates with an aim to ensure effective training and to ultimately reduce job attrition.</strong></i></span>                       
                                        </div>

                                        <hr/>
                                         <div>
                                            <span><b style={{fontSize:'18px'}}>12. Skill training of Jail inmates</b>-<i> <strong>Skill training of incarcerated individuals with an aim of economic rehabilitation and societal integration.</strong></i></span>                       
                                        </div>
                                        <hr/>
                                        <div>
                                            <span><b style={{fontSize:'18px'}}>13. CSR Projects</b></span><br/>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  Microsoft Diversity Skilling Program	</b><br/>
                                            <span className='mt-4'>
                                            Punjab Skill Development Mission signed MoU with Microsoft India for skill training of women and PwD in digital literacy and entrepreneurship skills under diversity skilling program in CSR mode. The courses imparted through Microsoft are Digital Literacy, 
                                            Communication skills, Entrepreneurship and Employability.</span>
                                            </p> <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>After the training program, the candidates will be given a chance for internship and Microsoft will facilitate them towards appropriate employment opportunities.</p>
                                            
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  L&T (Construction Skills Training Institutes )</b><br/>
                                            <span className='mt-4'>
                                            Punjab Skill Development Mission has collaborated with L&T CSTI to provide the free residential skill training (45-90 days) of the candidates of Punjab. 
                                            The objective of this collaboration is to provide opportunity to the youth of Punjab to go through structured skill training linked with 
                                            employability in construction sector. L&T-CSTI is providing free residential skill training in 8 trades of construction industry and more 
                                            than 900 candidates are mobilised from various districts of Punjab. Candidates will be assessed by L&T Assessors and certified for the 
                                            level of skill achieved by candidates. Placement assistance will be provided by L&T after successful completion of training to the candidates 
                                            at various construction projects under sub-contractor agencies pan India for their sustainable growth. The training of 15 candidates are 
                                            ongoing.
                                            </span>
                                           </p>

                                           <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  DCF (Deaf Cricket Federation) Skill School</b><br/>
                                            <span className='mt-4'>
                                            PSDM in association with M/S DCF Skills is providing skill training to PwD candidates (Physically challenged like Deaf & Dumb, Visually impaired). 
                                            There is one centre in Ludhiana. The centers are providing the training in Data Entry Operator, Retail sales and Self-employed tailor and at 
                                            the end of the course, all students shall be provided with the placement assistance also. 
                                            </span>
                                           </p>

                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  Mahindra and Mahindra 	</b><br/>
                                            <span className='mt-4'>
                                            Punjab Skill Development Mission and Mahindra & Mahindra (Swaraj Division) has signed MoU on 4th August 2022 and launched Project Hunar 
                                            under the CSR initiative of Mahindra & Mahindra Ltd (Swaraj Division). The project endeavours to provide skill training to youth in the 
                                            region with an objective of creating livelihood opportunities. Project duration is of one year.
                                            </span>
                                            </p> 
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>
                                            The project Hunar is being implemented by the training partner identified by Mahindra & Mahindra; M/S Mentor Skills. A state of the art 
                                            training facility in SAS Nagar, Mohali will train 444 candidates. The Training Partner will provide training in the 
                                            job roles Tractor Operator, Tractor Mechanic, Agriculture Machinery Repair and Maintenance Service Provider, Domestic Data Entry Operator, 
                                            CRM Domestic Non –Voice.
                                            </p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>
                                            The training is free of cost for all the candidates of Punjab with the funds to be transferred from the CSR funds directly by 
                                            Mahindra & Mahindra to the Training Partner.
                                            </p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>
                                            After the training, the candidates will be placed in various organisations. Some of the organisations are Tynor Orthotics Ltd., 
                                            RM Chemicals, Gilard Electronics, Swaraj Engines, Reliance SMSL, Aerial Telecom and Competent Synergies (Fusion Group).
                                            </p>

                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  ICICI Foundation, Khunimajra	</b><br/>
                                            <span className='mt-4'>
                                            PSDM has entered into a tripartite MOU with IKG (Inder Kumar Gujral )-PTU and ICICI Foundation for the setting up of the ICICI Academy for 
                                            Skills in the PIT Campus in Khunimajra, Kharar, SAS Nagar in 2016. The academy was setup in the year 2016 and the initial MOU was signed for 
                                            5 years from 2016 to 2021. They have been providing training to the needy youth of Punjab and training around 2000 candidates every year in 
                                            the following courses: 
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>1. Central Air-conditioning (CAC)</p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>2. Electrical and Home Appliance Repair (EHA)</p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>3. Office Administration (OA)</p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>4. Pumps and Motor (PM) </p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>5. Refrigeration and Air Conditioning Repair (RAC) </p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>6. Selling Skills (SS) </p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>7. Tractor Mechanic (TM)</p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>8. Two Wheeler Three Wheeler Technician (TWST)</p>

                                            </span>
                                           </p>

                                           <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  Jalandhar Sports Association</b><br/>
                                            <span className='mt-4'>
                                            The objective of this program is to help the unemployed youth to increase their earnings after a short term 
                                            training followed by employment to the candidates by the Implementing Agency. The proposal is to get the MoU 
                                            signed between Jalandhar sports Association and PSDM covering 200 candidates in Leather sector. Job roles are 
                                            Stitching operator- Goods and Garments, cutting Operator- Sports Goods and Cutter- Goods and Garments. Total 
                                            project cost is expected Is INR 90 Lakhs to be covered under CSR.
                                            </span>
                                           </p>

                                           <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  Sangati Foundation	</b><br/>
                                            <span className='mt-4'>
                                            The objective is to develop a multi-disciplinary center, primarily for skill development of PWD in the State of Punjab, keeping 
                                            in view the special needs of Divyangjan. A meeting was held under the chairmanship of worthy Secretary, EGSDT to discuss two 
                                            proposals as submitted by M/s Sangati Foundation regarding running Skill development courses for PWD candidates. The Training 
                                            Partner has been asked to submit the revised proposal.
                                            </span>
                                           </p>

                                           <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  HDFC Bank Ltd.		</b><br/>
                                            <span className='mt-4'>
                                            The objective of this program is to provide banking training for candidates of Punjab under“PARIVARTAN”. A meeting was held with the representative 
                                            of HDFC Bank under the chairmanship of MD, PSDM. MoU with HDFC Bank Ltd. was signed on 27.12.202. 300 candidates will be covered under the 
                                            MoU.This is a non-financial project. Target will be covered under CSR. <br/>
                                            Following are the courses to be offered:
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>a) General Duty Assistant</p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>b) Medical Lab Technician</p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>c) Business Correspondent.</p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>d) Junior software developer </p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>e) Search Engine Marketing Executive </p>
                                            </span>
                                           </p>

                                           <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  IBM-CSR BoX Skills Build Project 	</b><br/>
                                            <span className='mt-4'>
                                            The objective of this program is to help the unemployed youth to increase their earnings after a short term online training followed by employment 
                                            to the candidates by the Implementing Agency.7890 candidates have utilized the platform and regular workshop through IBM-CSR BoX are being 
                                            conducted in all colleges of Punjab. More than 5000 candidates have been given access to 7500 learning activities through this program. 
                                            Candidates will be provided digital certificates through this program.
                                            </span>
                                            </p> 
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}>
                                            Project is covered under CSR. The online training modules of IBM-CSR BoX are free of cost. 2000-3000 annually candidates are likely to 
                                            be covered under the program.
                                            </p> 

                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  TCS iON  	</b><br/>
                                            <span className='mt-4'>
                                            A strategic unit of Tata Consultancy Services focused on Manufacturing Industries. The objective is 
                                            to train 3000 youth son FinishingSkillsforNursingExcellence(FINE) in the state of Punjab. Video conference was held on26.12.2022. The TCS iON will share the revised proposal as per discussions.
                                            </span>
                                            </p> 

                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  Swaraj Engine Ltd  	</b><br/>
                                            <span className='mt-4'>
                                            Punjab Skill Development Mission has signed MoU with Swaraj Engine Ltd. for the training of 400 candidates in 2 years in Mohali 
                                            district under CSR programme.87 candidates are under training and 389 candidates are trained.
                                            </span>
                                            </p> 

                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i>  MPACT	</b><br/>
                                            <span className='mt-4'>
                                            PSDM had launched a new initiative ‘Mission Sunheri Shuruwat ‘to provide soft skill training  to the youth of Punjab across 22 districts of 
                                            Punjab. The total duration of the training was of 30 hours, which was conducted for 3 hours in 10 days each per batch. Under this mission, 
                                            training on communication skills, listening skills, soft skills, Personality Development, Team work, Telephonic Skills, Customer Client 
                                            Relations, Calls Inbound & Outbound, Sales Skills, Computer Fundamentals, Time Management were conducted by experienced training professionals. 
                                            The proposal to start new batch is under process. Under this project total 890 candidates are trained and certified. As on date, 
                                            44 candidates have been selected by the BPO sector employers. Cost of the project amountingRS. 7,70,028 was covered under CSR.
                                            </span>
                                            </p> 
                                                                                       

                                        </div>
                                        <hr/>
                                        <div>
                                            <span><b style={{fontSize:'18px'}}>14. Other initiatives at PSDM</b></span>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i> Training to victims of Drug Abuse </b><br/>
                                                <span style={{marginLeft:'2%'}}> Skill training to 1100 victims of Drug Abuse under PMKVY 2.0 </span>  
                                            </p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i> COVID-19 Training  </b><br/>
                                                <span style={{marginLeft:'2%'}}> Training to 7364 Covid- 19 Warrior frontline workers in Healthcare </span>  
                                            </p>
                                            {/* <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i> Skill Training for Sewerage workers</b><br/>
                                                <span style={{marginLeft:'2%'}}>Skill training to 200 sewerage workers for mechanisation of process </span>  
                                            </p> */}
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i> Center of Excellence </b><br/>
                                                <span style={{marginLeft:'2%'}}> In association with AIIMS Bathinda to train 200 candidates as advanced respiratory therapist. </span>  
                                            </p>
                                            <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i> Training of Trainers </b><br/>
                                                <span style={{marginLeft:'2%'}}> Training of 276 Trainers for capacity building in association with MRSPTU Bathinda. </span>  
                                            </p>
                                            {/* <p style={{marginLeft:'2%', marginTop:'1%',textAlign:'justify'}}><b><i class="fa fa-arrow-right" aria-hidden="true"></i> National Apprenticeship Promotion Scheme </b><br/>
                                                <span style={{marginLeft:'2%'}}> 4001 industries have been registered on portal and 19770 contracts have been signed. </span>  
                                            </p> */}
                                         </div>
                                        
                                         <div className="register-bar">
                                            {/* <Link className="nav-link registered" to="signup?type=1"><i className="fa fa-registered" aria-hidden="true"></i></Link> */}
                                            <Link className="nav-link registered" to="signup?type=1"><span style={{background:"rgb(237 144 23)", padding:"9px 7px 8px 6px" ,fontWeight:"900" ,borderRadius:"10px",fontSize:"10px"}}>REGISTER FOR SKILL TRAINING</span></Link>
                                        </div>
                                        
                                           
                                                                     
                                        </div>
                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(schemesList));
