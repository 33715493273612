import {USER_INSTITUTION,SET_JOBPROVIDER_PROFILE,USER_QUALIFICATION,USER_CITIES,
USER_JOBSEEKER_CITIES,USER_JOBSEEKER_SUBDISTRICT,ORG_TYPE,ORG_CATEGORY,FUNCTIONAL_AREA,
SECTOR, SET_SCHEDULED_INTERVIEWS, SET_POST_ARCHIVE, SET_RECENT_JOBS, 
SET_JOB_MELA_PARTICIPATION, SET_EXPIRE_JOB, SET_JOBPROVIDER_ADDRESSES,
SET_PROVIDER_SEARCH_RESULTS, SET_APPLIED_INTERVIEWS, SET_SELECTED_APPLICANTS,
 SET_JOB_STATUS, SET_APPLIED_JOBS, SET_MATCHING,SET_RECENT_SERVICES,
 SET_SERVICE_ARCHIVE, SET_SHORTLISTED, SET_APPLICANTS_ACCEPTED_INVITE, SET_RESUME_DATA,
 SET_CONFIRMED_CANDIDATES, SET_TIME_SLOT, SET_SCHDULED_JOBS_PROVIDER, SET_SERVICE_ARCHIEVE } from '../actions/actionTypes';

const initialState = {
  Loginuser: [],
  Profile: [],
  Registeruser: [],
  Genders: [],
  District: [],
  Specilization: [],
  Language: [],
  Coursenature: [],
  Subdistrict: [],
  JSubdistrict: [],
  Educations: [],
  UserSubType: [],
  Qualification: [],
  Institution: [],
  Cities: [],
  JCities: [],
  Orgtype:[],
  Orgcategory: [],
  Functionalarea: [],
  Sector: [],
  selectedPerson: null,
  scheduledInterviews:[],
  jobMelaParticipation:[],
  recentJobs:[],
  postArchive:[],
  expireJob:false,
  address:[],
  appliedInterviews:[],
  selectedApplicants:[],
  jobStatus:[],
  appliedJobs:[],
  matching:[],
  recentServices:[],
  servicesArchive:[],
  shortListed:[],
  acceptedInvites:[],
  resumeData:[],
  confirmedCandidates:[],
  timeSlot:[],
  schduledJobs:[],
  servicesArchieve:[]
};

const setScheduledJobs = (state, action) => {
  return { ...state, schduledJobs: action.data };
};

const setProfile = (state, action) => {
  return { ...state, Profile: action.Profile };
};


const setCities = (state, action) => {
  return { ...state, Cities: action.Cities };
}
const setjsubdistrict = (state, action) => {
  return { ...state, JSubdistrict: action.Subdistrict };
}
const setjCities = (state, action) => {
  return { ...state, JCities: action.Cities };
}
const setInstitution = (state, action) => {
  return { ...state, Institution: action.Institution };
}
const setQualification = (state, action) => {
  return { ...state, Qualification: action.Qualification };
}
const setOrgtype = (state, action) => {
  return { ...state, Orgtype: action.Orgtype };
}
const setOrgcategory = (state, action) => {
  return { ...state, Orgcategory: action.Orgcategory };
}
const setFunctionalarea = (state, action) => {
  return { ...state, Functionalarea: action.Functionalarea };
}
const setSector = (state, action) => {
  return { ...state, Sector: action.Sector };
}

const setScheduledInterviews = (state, action) => {
  return { ...state, scheduledInterviews: action.interviews };
}
const setJobMelaParticipation = (state, action) => {
  return { ...state, jobMelaParticipation: action.participation };
}
const setRecentJobs = (state, action) => {
  return { ...state, recentJobs: action.jobs };
}
const setPostArchive = (state, action) => {
  return { ...state, postArchive: action.archive };
}
const setExpireJob = (state, action) => {
  return { ...state, expireJob: action.job };
}
const setProviderAddress = (state, action) => {
  return { ...state, address: action.Address };
}
const setSearchResults = (state, action) => {
  return { ...state, searchResults: action.results };
}
const setAppliedInterviews = (state, action) => {
  return { ...state, appliedInterviews: action.interviews };
}
const setSelectedApplicants = (state, action) => {
  return { ...state, selectedApplicants: action.applicants };
}
const setJobStatus = (state, action) => {
  return { ...state, jobStatus: action.status };
}
const setAppliedJobs = (state, action) => {
  return { ...state, appliedJobs: action.jobs };
}
const setMatching = (state, action) => {
  return { ...state, matching: action.results };
}

const setRecentServices = (state, action) => {
  return { ...state, recentServices: action.Services };
}
const setServicesArchive = (state, action) => {
  return { ...state, servicesArchive: action.archive };
}
const setShortlistedCandidates = (state, action) => { 
  return { ...state, shortListed: action.results };

}
const setApplicantsAcceptedIvite = (state, action) => { 
  return { ...state, acceptedInvites: action.data };

}

const setResumeData = (state, action) => {
  return { ...state, resumeData: action.results };
}

const setConfirmedCandidates = (state, action) => {
  return { ...state, confirmedCandidates: action.data };
}

const setTimeSlot = (state, action) => {
	// console.log(action);
  return { ...state, timeSlot: action.data };
};

const setServiceArchieve = (state, action) => {
  return { ...state, servicesArchieve: action.data };
};




const JobseekerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_JOBPROVIDER_PROFILE: return setProfile(state, action);
    case FUNCTIONAL_AREA: return setFunctionalarea(state, action);
    case SECTOR: return setSector(state, action);
    case ORG_CATEGORY: return setOrgcategory(state, action);
    case USER_CITIES: return setCities(state, action);
    case USER_INSTITUTION: return setInstitution(state, action);
    case USER_QUALIFICATION: return setQualification(state, action);
    case USER_JOBSEEKER_SUBDISTRICT: return setjsubdistrict(state, action);
    case USER_JOBSEEKER_CITIES: return setjCities(state, action);
    case ORG_TYPE: return setOrgtype(state, action);
    case SET_SCHEDULED_INTERVIEWS: return setScheduledInterviews(state, action);
    case SET_POST_ARCHIVE: return setPostArchive(state, action);
    case SET_RECENT_JOBS: return setRecentJobs(state, action);
    case SET_JOB_MELA_PARTICIPATION: return setJobMelaParticipation(state, action);
    case SET_EXPIRE_JOB: return setExpireJob(state, action);
    case SET_JOBPROVIDER_ADDRESSES: return setProviderAddress(state, action);
    case SET_PROVIDER_SEARCH_RESULTS: return setSearchResults(state, action);
    case SET_APPLIED_INTERVIEWS: return setAppliedInterviews(state, action);
    case SET_SELECTED_APPLICANTS: return setSelectedApplicants(state, action);
    case SET_JOB_STATUS: return setJobStatus(state, action);
    case SET_APPLIED_JOBS: return setAppliedJobs(state, action);
    case SET_MATCHING: return setMatching(state, action);
    case SET_RECENT_SERVICES: return setRecentServices(state, action);
    case SET_SERVICE_ARCHIVE: return setServicesArchive(state, action);
    case SET_SHORTLISTED: return setShortlistedCandidates(state, action)
    case SET_APPLICANTS_ACCEPTED_INVITE: return setApplicantsAcceptedIvite(state, action)
    case SET_RESUME_DATA: return setResumeData(state, action)
    case SET_CONFIRMED_CANDIDATES: return setConfirmedCandidates(state, action)
    case SET_TIME_SLOT: return setTimeSlot(state, action)
    case SET_SCHDULED_JOBS_PROVIDER : return setScheduledJobs(state, action)
    case SET_SERVICE_ARCHIEVE: return setServiceArchieve(state, action)
    default: return state;
  }
};

export default JobseekerReducer;
