import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { setDefaultTranslations, setDefaultLanguage } from 'react-multi-lang'
import pu from './components/layout/pu.json'
import en from './components/layout/en.json'

setDefaultTranslations({pu, en})
let defaultvalue = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
setDefaultLanguage(defaultvalue)
ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
