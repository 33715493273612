import React, { Component } from 'react';
// import { Link } from 'react-router';
import { connect } from 'react-redux';
import {translate} from "react-multi-lang";
import store from '../../store';

import { getschemeslist } from '../../actions/jobseekerAction';


class shemesdescription extends Component {

    
    constructor(props){
        super(props);
        store.dispatch(getschemeslist());
    }
    componentDidMount() {
        document.title = "Description"
    }
    
    render() {
        var data=this.props.JobseekerReducer.displayshemesresultlist;
        console.log(data)
        return (
            <div>
                <section className="top-inner main-content-height animatedParent">
                    <div className="container" >
                        <div className="first-click1 mb-4 mt-4">
                      <div className={"row"}>

                      
                          <div className={"col-md-8 col-sm-6 col-lg-9"}>
                              <h4 className={""}>Shemes Description</h4>


                          </div>
                          <div className={"clearfix"}></div>

                          <div className={"row m-0"}>
                             
                              {data && data.map((d, key) => {
                                                                return (
                                                                    <div className={"col-md-12 p-4 about-jobseeker"}>
                              <h5>{d.title}</h5>

                                <p style={{textAlign:'justify'}}>{d.title_description}</p>   
                            
                               </div> 

                        
                       )})}
                      
                     
                             
                          </div>
                          </div>
                      

                      </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(shemesdescription));



