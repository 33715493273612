import React, { Component } from 'react'
import { translate } from 'react-multi-lang'
import { Link } from "react-router";
class HowToUse extends Component {

    componentDidMount() {
        document.title = this.props.t('header.howToUse', { param: 'react' })
    }

    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        return (<section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
            <div className="container">
                <div className="row">
                    <Link className="navbar-toggler widget-sidebar-toggler text-white">
                        Sidebar
                    </Link>
                    <main className="col second-side p-0 pb-3 h-100">
                        <div className="clearfix"></div>
                        <div className="">
                            <div id="home" className=" tab-pane active">
                                <section className="top-inner animatedParent">
                                    <div className="container-fluid">
                                        <div className="col-md-12 content-paragraph mt-3">
                                            <div className="row">
                                                <div className="col-md-8 col-md-offset-8 pb-8 mx-auto">
                                                    {/* <h2 className="double ">Jobseeker</h2> */}
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="row d-flex">
                                                                <div
                                                                    className="col-md-12 col-lg-12 col-sm-6 mb-5 d-flex ">
                                                                    <div className="first-click-inst">
                                                                        <h2>{this.props.t('how_to_use.welcome', { param: 'react' })}</h2>
                                                                        <h3>{this.props.t('how_to_use.instruction', { param: 'react' })}</h3>
                                                                        <ul className="jobseeker-benefits"
                                                                            style={{ 'fontSize': '18px' }}>
                                                                            <li>{this.props.t('how_to_use.changePassword', { param: 'react' })}
                                                                                {" "}
                                                                                <Link
                                                                                    to={'change-password'}>{this.props.t('sidebar.change_password', { param: 'react' })}</Link>
                                                                            </li>
                                                                            <li>{this.props.t('how_to_use.updateProfile', { param: 'react' })}</li>
                                                                            <li>{this.props.t('how_to_use.searchJob', { param: 'react' })}</li>
                                                                            <li>{this.props.t('how_to_use.appliedJob', { param: 'react' })}</li>
                                                                            {/* <li>{this.props.t('how_to_use.review', {param: 'react'})}</li> */}
                                                                        </ul>
                                                                        <Link
                                                                            to={'myprofile'}>{this.props.t('sidebar.update_profile', { param: 'react' })}</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>)
    }

}


export default (translate(HowToUse));
