import {SET_NOTIFICATION,USER_VERIFIED,USER_RESET,UNSET_NOTIFICATION,OPEN_OPT, RESET_VERIFY, SET_USER_VERIFIED_OBJ} from '../actions/actionTypes';

const initialState = {
  Notification: [],
  user_verify: false,
  user_reset: false,
  openotp: false,
  verify_message: '',
};
const setNotification = (state, action) => {
  return { ...state, Notification: action.Notification };
};
const setotp = (state, action) => {
  return { ...state, openotp: action.status};
};
const setUserreset = (state, action) => {
  return { ...state, user_reset: true};
};
const setUserverified = (state, action) => {
  return { ...state, user_verify: true,verify_message: action.user};
};
const setVerfiedObject = (state, action) => {
  return { ...state, verfiedObject: action.obj};
};
const setUserVerifyFalse = (state, action) => {
  return { ...state, user_verify: false};
}
const unsetNotification = (state, action) => {
  return { ...state, Notification: [] };
};
const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION: return setNotification(state, action);
    case UNSET_NOTIFICATION: return unsetNotification(state, action);
    case USER_VERIFIED: return setUserverified(state, action);
    case SET_USER_VERIFIED_OBJ: return setVerfiedObject(state, action);
    case USER_RESET: return setUserreset(state, action);
    case OPEN_OPT: return setotp(state, action);
    case RESET_VERIFY: return setUserVerifyFalse(state, action);
    default: return state;
  }
};
export default notificationReducer;
