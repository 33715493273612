import {
  GET_LOGIN, USER_INSTITUTION, USER_LANGUAGE, USER_COURSENATURE, USER_SPECILIZATION, USER_QUALIFICATION,
  USER_CITIES, USER_JOBSEEKER_CITIES, USER_JOBSEEKER_SUBDISTRICT, USER_SUB_TYPE, USER_SUBDISTRICT, 
  EDUCATION_LEVELS, USER_DISTRICT, CREATE_USER, USER_GENDER, ORG_TYPE, ORG_CATEGORY, USER_SKILLS,
  SET_USER_COURSENATURE, SET_EDUCATION_LEVEL, SET_REDIRECTION_LINK, SET_HOME_LOGIN, SET_ALL_MELAS,
  SET_GOVT_JOBS, SET_JOBS_COUNT, SET_PRIVATE_JOBS, SET_VISITOR_COUNT, SET_EXP, SET_EMPLOYER, SET_NEWS,
  SET_SEEKER_TYPE, LSP_SERVICES, SET_COUNTRY, SET_LOCAL_SERVICES, SET_ALL_LOCAL_SERVICES, SET_ALL_DISTRICTS,
  SET_JOB_NOTIFICATION, SET_EVENTS, SET_OTP_VERIFICATION, SELECTED_USER_DISTRICT, SET_CHAT_INVITES,
  SET_CHAT_MESSAGES, SET_CHATS, SET_SELF_EMPLOYMENT_SECTOR, SET_ACTIVE_JOB_MELA, SET_MOBILE_APP_DATA,
  SET_DBEES_ADDRESS, SET_SERVICE_STATUS, SET_DISTANCE, SET_SERVICE_APPLIED, SET_CONSTITUENCY,
  SET_SELF_EMPLOYMENT_DATA, SELF_EMP_DROPDOWN_DATA, SET_SERVICE_SUGGESTION,SKILL_EDUCATION_LEVELS,GET_VEDIO,SET_SCHEME
} from '../actions/actionTypes';

const initialState = {
  Loginuser: [],
  countries:[],
  gotOtpForMobileLogin:'',
  Registeruser: [],
  Genders: [],
  District: [],
  Specilization: [],
  Language: [],
  Coursenature: [],
  Subdistrict: [],
  JSubdistrict: [],
  Educations: [],
  UserSubType: [],
  Qualification: [],
  Institution: [],
  Cities: [],
  JCities: [],
  Orgtype: [],
  Orgcategory: [],
  userSkills: [],
  selectedPerson: null,
  educationLevel:[],
  link:'',
  homeLogin:false,
  allMelas:[],
  govtJobs:[],
  jobsCount:[],
  privateJobs:[],
  exps:[],
  visitorsCount:'',
  employers:[],
  seekerType:[],
  Services: [],
  localServices:[],
  allLocalSeervices:[],
  allDistricts:[],
  newsData:[],
  notifications:[],
  allEvents:[],
  verifyWithOtp:false,
  selectedDistricts:[],
  chatInvites:[],
  chatMessages:[],
  chats:[],
  selfEmpSectors:[],
  activeJobMela:[],
  mobileApp:[],
  addressData:[],
  serviceStatus:0,
  serviceApplied: 0,
  distance:[],
  serviceSuggestions:[],
  constituency:[],
  dropDownData:[],
  careerContent:[],
  getvedio:[],
  getschemelist:[],
};

const setServiceSuggestions = (state, action) => {
  return { ...state, serviceSuggestions: action.data };
};

const setMobileAppData = (state, action) => {
  return { ...state, mobileApp: action.data };
};

const setChatMessages = (state, action) => {
  return { ...state, chatMessages: action.data };
};
const setChats = (state, action) => {
  return { ...state, chats: action.data };
};

const setChatInvites = (state, action) => {
  return { ...state, chatInvites: action.data };
};

const setVerifyWithOtp = (state, action) => {
  return { ...state, verifyWithOtp: action.status };
};

const gallaryvedio = (state, action) => {
  return { ...state, getvedio: action.data };
};
const setNews = (state, action) => {
  return { ...state, newsData: action.data };
};
const setLogin = (state, action) => {
  return { ...state, Loginuser: action.loginuser };
};
const setRigister = (state, action) => {
  return { ...state, Registeruser: action.user };
};
const setUserSubType = (state, action) => {
  return { ...state, UserSubType: action.UserSubType };
}
const setGender = (state, action) => {
  return { ...state, Genders: action.Genders };
}
const setEducation = (state, action) => {
  return { ...state, Educations: action.Education };
}
const setSkillEducation = (state, action) => {
  return { ...state, Educations: action.Education };
}
const setDistrict = (state, action) => {
  return { ...state, District: action.District };
}
const setsubdistrict = (state, action) => {
  return { ...state, Subdistrict: action.Subdistrict };
}
const setCities = (state, action) => {
  return { ...state, Cities: action.Cities };
}
const setjsubdistrict = (state, action) => {
  return { ...state, JSubdistrict: action.Subdistrict };
}
const setjCities = (state, action) => {
  return { ...state, JCities: action.Cities };
}
const setInstitution = (state, action) => {
  return { ...state, Institution: action.Institution };
}
const setQualification = (state, action) => {
  return { ...state, Qualification: action.Qualification };
}
const setSpecilization = (state, action) => {
  return { ...state, Specilization: action.Specilization };
}
const setCoursenature = (state, action) => {
  return { ...state, Coursenature: action.Coursenature };
}
const setCourseNature = (state, action) => {
  return { ...state, Coursenature: action.Coursenature };
}
const setLanguage = (state, action) => {
  return { ...state, Language: action.Language };
}
const setOrgtype = (state, action) => {
  return { ...state, Orgtype: action.Orgtype };
}
const setOrgcategory = (state, action) => {
  return { ...state, Orgcategory: action.Orgcategory };
}
const setSkills = (state, action) => {
  return { ...state, userSkills: action.skills };
}
const setEducationLevel = (state, action) => {
  return { ...state, educationLevel: action.education };
}

// const setSkillEducationLevel = (state, action) => {
//   return { ...state, educationLevel: action.education };
// }
const setLink = (state, action) => {
  return { ...state, link: action.link };
}
const setHomeLogin = (state) => {
  return { ...state, homeLogin: true };
}
const setAllMelas = (state, action) => {
  return { ...state, allMelas: action.results };
}
const setGovtJobs = (state, action) => {
  return { ...state, govtJobs: action.jobs };
}
const setPrivateJobs = (state, action) => {
  return { ...state, privateJobs: action.jobs };
}
const setJobsCount = (state, action) => {
  return { ...state, jobsCount: action.jobs };
}
const setVisitorsCount = (state, action) => {
  return { ...state, visitorsCount: action.count };
}
const setExperience = (state, action) => {
  return { ...state, exps: action.exp };
}
const setEmployer = (state, action) => {
  return { ...state, employers: action.data };
}
const setSeekerType = (state, action) => {
  return { ...state, seekerType: action.data };
}

const setCountry = (state, action) => {
  return { ...state, countries: action.countries };
}

const setLocalServices = (state, action) => {
  return { ...state, localServices: action.data };
}

const setAllLocalServices = (state, action) => {
  return { ...state, allLocalServices: action.data };
}

const setAllDistricts = (state, action) => {
  return { ...state, allDistricts: action.data };
}

const setSchemeList =(state,action) => {
  return { ...state,getschemelist :action.data};
}

const setServices = (state, action) => {
  return { ...state, Services: action.Services };
}

const setNotifications = (state, action) => {
  return { ...state, notifications: action.data };
}

const setEvents = (state, action) => {
  return { ...state, allEvents: action.data };
}

const setSelectedDistrict = (state, action) => {
  return { ...state, selectedDistricts: action.District };
}

const setSelfEmploymentSectors = (state, action) => {
  return { ...state, selfEmpSectors: action.data };
};

const setActiveJobMela = (state, action) => {
  return { ...state, activeJobMela: action.data };
};

const setDbeesAddress = (state, action) => {
  return { ...state, addressData: action.data };
};

const setApplyServiceResp = (state, action) => {
  return { ...state, serviceStatus: action.data };
}

const setServiceApplied = (state, action) => {
  return { ...state, serviceApplied: action.status };
}

const setDistance = (state, action) => {
  return { ...state, distance: action.data };
};

const setConstituency = (state, action) => {
  return { ...state, constituency: action.data };
};

const setSelfEmpDropDownData = (state, action) => {
  return { ...state, dropDownData: action.data };
};

const setSelfEmploymentData = (state, action) => {
  return { ...state, careerContent: action.data };
};



const peopleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERVICE_APPLIED: return setServiceApplied(state, action);
    case SET_SERVICE_STATUS: return setApplyServiceResp(state, action);
    case SET_DBEES_ADDRESS: return setDbeesAddress(state, action);
    case GET_LOGIN: return setLogin(state, action);
    case USER_SUB_TYPE: return setUserSubType(state, action);
    case USER_GENDER: return setGender(state, action);
    case EDUCATION_LEVELS: return setEducation(state, action);
    case SKILL_EDUCATION_LEVELS: return setSkillEducation(state, action);
    case CREATE_USER: return setRigister(state, action);
    case USER_DISTRICT: return setDistrict(state, action);
    case SELECTED_USER_DISTRICT: return setSelectedDistrict(state, action);
    case USER_SPECILIZATION: return setSpecilization(state, action);
    case USER_COURSENATURE: return setCoursenature(state, action);
    case SET_USER_COURSENATURE: return setCourseNature(state, action);
    case USER_SUBDISTRICT: return setsubdistrict(state, action);
    case USER_LANGUAGE: return setLanguage(state, action);
    case ORG_TYPE: return setOrgtype(state, action);
    case ORG_CATEGORY: return setOrgcategory(state, action);
    case USER_CITIES: return setCities(state, action);
    case USER_INSTITUTION: return setInstitution(state, action);
    case USER_QUALIFICATION: return setQualification(state, action);
    case USER_JOBSEEKER_SUBDISTRICT: return setjsubdistrict(state, action);
    case USER_JOBSEEKER_CITIES: return setjCities(state, action);
    case USER_SKILLS: return setSkills(state, action);
    case SET_EDUCATION_LEVEL: return setEducationLevel(state, action);
    // case SET_SKILL_EDUCATION_LEVELS: return setSkillEducationLevel(state, action);
    case SET_REDIRECTION_LINK: return setLink(state, action);
    case SET_HOME_LOGIN: return setHomeLogin(state);
    case SET_ALL_MELAS: return setAllMelas(state, action);
    case SET_GOVT_JOBS: return setGovtJobs(state, action);
    case SET_JOBS_COUNT: return setJobsCount(state, action);
    case SET_PRIVATE_JOBS: return setPrivateJobs(state, action);
    case SET_VISITOR_COUNT: return setVisitorsCount(state, action);
    case SET_EXP: return setExperience(state, action);
    case SET_EMPLOYER: return setEmployer(state, action);
    case SET_SEEKER_TYPE: return setSeekerType(state, action);
    case LSP_SERVICES: return setServices(state, action);
    case SET_COUNTRY: return setCountry(state, action)
    case SET_LOCAL_SERVICES: return setLocalServices(state, action)
    case SET_ALL_LOCAL_SERVICES : return setAllLocalServices(state, action)
    case SET_ALL_DISTRICTS : return setAllDistricts(state, action)
    case SET_NEWS: return setNews(state, action)
    case SET_JOB_NOTIFICATION: return setNotifications(state, action)
    case SET_EVENTS: return setEvents(state, action);
    case SET_OTP_VERIFICATION: return setVerifyWithOtp(state, action);
    case SET_CHAT_INVITES: return setChatInvites(state, action);
    case SET_CHATS: return setChats(state, action);
    case SET_CHAT_MESSAGES: return setChatMessages(state, action);
    case SET_SELF_EMPLOYMENT_SECTOR: return setSelfEmploymentSectors(state, action);
    case SET_ACTIVE_JOB_MELA: return setActiveJobMela(state, action);
    case SET_MOBILE_APP_DATA: return setMobileAppData(state, action);
    case SET_DISTANCE: return setDistance(state, action);
    case SET_CONSTITUENCY: return setConstituency(state, action)
    case SELF_EMP_DROPDOWN_DATA: return setSelfEmpDropDownData(state, action);
    case SET_SELF_EMPLOYMENT_DATA: return setSelfEmploymentData(state, action);
    case SET_SERVICE_SUGGESTION: return setServiceSuggestions(state, action);
    case GET_VEDIO : return gallaryvedio(state,action);
    case SET_SCHEME : return setSchemeList(state,action);
    
    default: return state;
  }
};

export default peopleReducer;
