import React, { Component } from 'react'
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { getAreaOfInterest } from '../../../actions/counsellingproviderAction';
import { postCounsellingSearchByArea, setCounsellingSearchByArea, setPsdmSkillProgram, postPsdmSkillProgram } from '../../../actions/jobseekerAction';
import ServerSetting from '../../../config.json';
import Pagination from "react-js-pagination";
import { chunk } from '../../../helpers'
import { Link } from 'react-router'
import { translate } from 'react-multi-lang'

const RESULT_PER_PAGE = 25

class SkillProgram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: [],

            activePage: 1,
            submitted: false,

        }


        store.dispatch(getAreaOfInterest())

    }

    submit = (e) => {
        e.preventDefault()
        if (!this.state.submitted) {
            this.setState({ activePage: 1 })
            this.state.formData = localStorage.getItem('token');
            console.log(this.state.formData);
            store.dispatch(postCounsellingSearchByArea(this.state.formData, true));
            this.setState({ submitted: true })
        }
    }
     
    handleClick() {
        let data ={}
        data.auth_key = localStorage.getItem('token');
        data.skill = 'skill_courses';
        console.log(data);
        store.dispatch(postPsdmSkillProgram(data));
        // store.dispatch(postCounsellingSearchByArea(aut));
            // this.setState({ submitted: true })
        // }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    componentDidMount() {
        document.title = this.props.t('counsellingProvider.searchCounsellor', { param: 'react' })
    }

    handleInput = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        // this.state.formData = localStorage.getItem('token');
        // console.log(this.state.formData);
        let formData = localStorage.getItem('token');

        // formData[name] = value;
        // if (!value) {
        //     delete formData[name]
        // }
        // console.log(formData);
        await this.setState({ formData, submitted: false });
    }


    componentWillUnmount() {
        store.dispatch(setCounsellingSearchByArea([]))
    }

    getAreas = list => {
        const areaList = []
        list.split(',').forEach((ar) => {
            const filterList = this.props.counsellingproviderReducer.area_of_interest.filter((res) => res.id === ar)
            if (filterList && filterList[0]) {
                areaList.push(filterList[0].name)
            }
        })
        return areaList.join(',')
    }



    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const Mainprops = this.props
        const { activePage, formData } = this.state
        const searchResults = chunk(Mainprops.JobseekerReducer.counsellorResults, RESULT_PER_PAGE)
        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>


                            <div>
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-3">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">Artificial Intelligence and Data Science Upskilling Course.<span class="blinking" style={{'fontSize': '13px', 'marginLeft':'3px'}}>Free of Cost</span></h6>
                                                        </div>
                                                        <div className="panel-body mt-2">
                                                        <form onSubmit={this.submit}>
                                                            <p>IIT Ropar is offering Artificial Intelligence and Data Science upskilling course. It is a module based program having L2 program (4 weeks) and L3 program (12 weeks). </p>
                                                            <p>For further details:<a className="date-clr" href={"/docs/Module-based-program.pdf"}
															rel="noopener noreferrer" target="_blank"><b> click here </b></a> </p>
                                                            <p>Steps involved in registration for A-DSAT (Advanced Data Science Aptitude Test ):<a className="date-clr" href={"/docs/Draft-A-DSAT SOP.pdf"}
															rel="noopener noreferrer" target="_blank"><b> click here </b></a> </p>
                                                            <input type="hidden" name="insert"  value={formData.interest ? formData.interest : ''}></input>
                                                            <p>In case you are eligible and are interested in applying: <a onClick={this.handleClick} className="date-clr" href={"https://www.iitrpr.ac.in/aiupskilling/"}
															rel="noopener noreferrer" target="_blank"><b> click here </b></a></p> 
                                                            
                                                                <hr />
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 ">
                                                                            {/* <button type="submit"
                                                                                className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                                {this.props.t('jobSearch.search', { param: 'react' })}
                                                                            </button> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(SkillProgram));