import React, {Component} from 'react'
import JobDetails from '../components/dashboard/jobseeker/JobDetails'
import {connect} from 'react-redux';
import store from '../store';
import {getPostArchiveView} from '../actions/employerAction';
import {applyJob} from '../actions/jobseekerAction';
import { setRedirectionLink } from '../actions/accountAction';
import {translate} from 'react-multi-lang'

class JobDetailHomePage extends Component {
    
    componentDidMount() {
        if (this.props.routeParams.jobId) {
            store.dispatch(getPostArchiveView({job_id: this.props.routeParams.jobId}))
        }
        document.title = this.props.t('header.job_details', { param: 'react' })
    }

    setRedirectForHomeUser = () => {
    if(this.props.actionReducer.Loginuser &&
        this.props.actionReducer.Loginuser.user_type &&
        this.props.actionReducer.Loginuser.user_type === '1'){
            this.props.router.push(`/job-details/${this.props.routeParams.jobId}`)
        } else {
            store.dispatch(setRedirectionLink(`job-details/${this.props.routeParams.jobId}`))
            this.props.router.push('/signin')
        }
    }

    applyForJob = async () => {
        await store.dispatch(applyJob({job_id: this.props.routeParams.jobId}));
        this.props.router.push('/myaccount')
    }

    render() {
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            {this.props.EmployerReducer.postArchive  ?
                                <JobDetails
                                    jobData={this.props.EmployerReducer.postArchive}
                                    submit={this.applyForJob}
                                    isNotSubmit={true}
                                    apply={this.setRedirectForHomeUser}
                                /> : <h4>{this.props.t('JobPost.notFound', { param: 'react' })}</h4>}
                        </main>
                    </div>
                </div>
            </section>

        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(JobDetailHomePage));
