import React, { Component } from 'react'
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router'
import { selfEmploymentSectors } from '../../../actions/accountAction'
import { getUserProfileInterest, addUserProfileInterest } from '../../../actions/jobseekerAction'
import SimpleReactValidator from 'simple-react-validator';

class Interest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData1: [],
            formData2: [],
            formData3: [],
            sectorProfile1: [],
            sectorProfile2: [],
            sectorProfile3: [],
            loadData: false,
            submitted1: false,
            submitted2: false,
            submitted3: false,
            error1: '',
            error2: ''
        }
        store.dispatch(selfEmploymentSectors())
        store.dispatch(getUserProfileInterest())

        this.validator1 = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });

        this.validator2 = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });

        this.validator3 = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });
    }
    componentDidMount() {
        document.title = this.props.t('interest.title', { param: 'react' })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.actionReducer.selfEmpSectors && nextProps.actionReducer.selfEmpSectors.length &&
            nextProps.JobseekerReducer.profile_interest && nextProps.JobseekerReducer.profile_interest.length) {
            nextProps.JobseekerReducer.profile_interest.forEach((profile) => {
                const key = 'formData' + profile.interest_no
                const keySectorProfile = 'sectorProfile' + profile.interest_no
                const filterSectorProfile = nextProps.actionReducer.selfEmpSectors.filter(sector => sector.sector_id === profile.sector_id)
                if (filterSectorProfile && filterSectorProfile.length) {
                    this.setState({ [keySectorProfile]: filterSectorProfile[0].subsector })
                }
                this.setState({ [key]: profile })
            });
        }
    }


    handleInput = async (e, formKey, formData, sectorProfile = null, submitted, validatorKey) => {
        const name = e.target.name;
        const value = e.target.value;
        if(name ==='profile_id' || name ==='other_interest'){
            const fieldKey = name ==='profile_id' ? 'other_interest' : 'profile_id';
            this[validatorKey].purgeFields()
            delete formData[fieldKey]
        }
       
        if (name === 'sector_id') {
            const filterSectorProfile = this.props.actionReducer.selfEmpSectors.filter(sector => sector.sector_id === value)
            const sectorValue = filterSectorProfile && filterSectorProfile.length ? filterSectorProfile[0].subsector : []
            delete formData['profile_id']
            this.setState({ [sectorProfile]: sectorValue, [formKey]: formData })

        }
        if(name ==='profile_id' && value && this.diableField(value)){
           return false
        }

        formData[name] = value;
        if (!value) {
            delete formData[name]
        }
        await this.setState({ [formKey]: formData, [submitted]: false });
    }

    clicked = (job) => {
        this.setState({ job, jobDetails: true })
    }

    submit = (e, formKey, submitted, validatorKey = null) => {
        e.preventDefault()
        if (!this.state[submitted] && this[validatorKey] && this[validatorKey].allValid()) {
            store.dispatch(addUserProfileInterest({ ...this.state[formKey], interest_no: formKey.slice(-1) }))
            this.setState({ [submitted]: true })
        } else if (!validatorKey) {
            store.dispatch(addUserProfileInterest({ ...this.state[formKey], interest_no: formKey.slice(-1) }))
            this.setState({ [submitted]: true })
        } else {
            this[validatorKey].showMessages();
            this.forceUpdate();
        }
    }

    diableField = (id) => {
        const filterField = this.props.JobseekerReducer.profile_interest.filter((interest) => interest.profile_id === id)
        return filterField && filterField.length ? true: false
    }

    getForm = (formKey, formData, sectorProfile, key, submitted, validatorKey = null, validator = null) => {
        let span = validatorKey && validator ? <span style={{ color: 'red' }} className="ml-2">*</span> : null

        return (<form onSubmit={(e) => this.submit(e, formKey, submitted, validatorKey)}>
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-4 col-md-3 col-5">
                        <label>{this.props.t('interest.sector', { param: 'react' })}{span}</label>
                    </div>
                    <div className="col-sm-8 col-md-7  col-7">
                        <select onChange={(e) => this.handleInput(e, formKey, formData, key, submitted, validatorKey)}
                            value={formData.sector_id ? formData.sector_id : ''}
                            className="form-control" name="sector_id">
                            <option value="">{this.props.t('interest.selectSector', { param: 'react' })}</option>
                            {this.props.actionReducer.selfEmpSectors && this.props.actionReducer.selfEmpSectors.length ?
                                this.props.actionReducer.selfEmpSectors.map((sector, key) => {
                                    return <option value={sector.sector_id} key={key}>{sector.sector_name}</option>
                                }) : null}
                        </select>
                        {validator && validator.message('sector', formData.sector_id, 'required')}
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-4 col-md-3  col-5 ">
                        <label>{this.props.t('interest.sectorProfile', { param: 'react' })}{!formData.other_interest ? span: ''}</label>
                    </div>
                    <div className="col-sm-8 col-md-7  col-7">
                        <select
                            onChange={(e) => this.handleInput(e, formKey, formData, key, submitted, validatorKey)}
                            value={formData.profile_id ? formData.profile_id : ''}
                            className="form-control"
                            name="profile_id">
                            <option value="">{this.props.t('interest.selectSectorProfile', { param: 'react' })}</option>
                            {sectorProfile && sectorProfile.length ?
                                sectorProfile.map((sector, key) => {
                                    const disable = this.diableField(sector.id)
                                    return <option value={sector.id} key={key} className={disable ? 'disabled-option':''} disabled={disable}>{sector.name}</option>
                                }) : null}
                        </select>
                        {!formData.other_interest && validator && validator.message('sector profile', formData.profile_id, 'required')}
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-4 col-md-3 col-5">
                            <label>{this.props.t('dashboard.table.description', { param: 'react' })}</label>
                    </div>
                    <div className="col-sm-8 col-md-7 col-7">
                        <textarea
                            rows="4"
                            cols={"8"}
                            onChange={(e) => this.handleInput(e, formKey, formData, key, submitted, validatorKey)}
                            value={formData.other_interest ? formData.other_interest : ''}
                            className="w-100 "
                            name="other_interest"
                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('dashboard.table.description', { param: 'react' })} />
                        {!formData.profile_id && validator && validator.message('other interest', formData.other_interest, 'required')}
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-12">
                        <button type="submit"
                            className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                            {this.props.t('interest.addInterest', { param: 'react' })}
                        </button>
                    </div>
                </div>
            </div>
        </form>
        )
    }

    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        const { formData1, formData2, formData3,
            sectorProfile1, sectorProfile2, sectorProfile3
        } = this.state

        const Mainprops = this.props

        const { validator1, validator2, validator3 } = this

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div>
                                <div className="tab-content">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-3">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 mx-auto p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('interest.title', { param: 'react' })}</h6>
                                                        </div>
                                                        <div className="panel-body mt-2">
                                                            <h5 className="date-clr">{this.props.t('interest.lookingForJob', { param: 'react' })}</h5>
                                                            <hr></hr>
                                                            {this.getForm("formData1", formData1, sectorProfile1, 'sectorProfile1', 'sumbitted1', "validator1", validator1)}
                                                            <hr></hr>
                                                            <h5><span className="date-clr" data-toggle="collapse" href={formData1.sector_id ? "#panel-interest2": ""} style={{ cursor: "pointer" }}>{this.props.t('interest.addMoreInterest', { param: 'react' })}</span></h5>
                                                            <hr></hr>
                                                            <div id="panel-interest2" className="collapse">
                                                                {this.getForm("formData2", formData2, sectorProfile2, 'sectorProfile2', "sumbitted2", "validator2", validator2)}
                                                                <hr></hr>
                                                            </div>
                                                            <h5><span className="date-clr" data-toggle="collapse" href={formData1.sector_id ? "#panel-interest3": ""} style={{ cursor: "pointer" }}>{this.props.t('interest.addMoreInterest', { param: 'react' })}</span></h5>
                                                            <hr></hr>
                                                            <div id="panel-interest3" className="collapse">
                                                                {this.getForm("formData3", formData3, sectorProfile3, "sectorProfile3", "sumbitted3", "validator3", validator3)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Interest));