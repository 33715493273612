import { UNSET_NOTIFICATION,SET_NOTIFICATION } from './actionTypes';
export const unsetNotification = () => {
  return {
    type: UNSET_NOTIFICATION
  };
};
//Unset Notification
export const UnsetNotification = () => {
	return dispatch => {	
		dispatch(unsetNotification());
	}
}
//set Notification
export const setNotification = (Notification) => {
  return {
    type: SET_NOTIFICATION,
    Notification
  };
};
export const SetNotification = (Notification) => {
	return dispatch => {	
		dispatch(setNotification(Notification));
	}
}