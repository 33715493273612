import React, { Component } from 'react';
import { Link } from 'react-router';

class ForeignEmployementPage extends Component {
    render() {
        return (
            <div>
            <section className="top-inner animatedParent">
                <div className="container-fluid" >
                  <div className="col-md-12 content-paragraph">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-8 pb-5 mx-auto">
                                <div className="row">
                                    <div className="col-md-12">
                                      <div className="row d-flex">
                                      <div className="col-md-12 col-lg-12 col-sm-6 mb-5 d-flex ">
                                        <div className="first-click">
                                        <h5>ABOUT PGRKAM</h5>
                                        <ul className="jobseeker-benefits">
                                          <li>PGRKAM- Punjab Ghar Ghar Rozgar and Karobar Mission.</li>
                                          <li>An Inclusive Mission by Dept.of Employment Generation and Training, Punjab towards augmenting employability and development of skills for the people.</li>
                                          <li>It supports Jobseeker to search and apply for jobs either in their district and outside.</li>
                                          <li>PGRKAM organizes Job Fairs in various district of Punjab every year, where lots of jobseekers and Employers got benefits.</li>
                                          <li>It consists of digital platform, employment exchanges, DBEES, skill development centers.</li>
                                        </ul>
                                          <Link className="btn btn-primary btn-primary2 mr-2 mt-2" to="/">Go to homepage</Link>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        )
    }
}

export default ForeignEmployementPage;
