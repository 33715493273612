import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Pagination from "react-js-pagination";
import store from "../../store"
import { Link } from 'react-router'
import { getPrivateJobs, Islogin, setRedirectionLink, get_User_District } from "../../actions/accountAction";
import { chunk } from '../../helpers'

const RESULT_PER_PAGE = 25

class PrivateJobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            job: '',
            locations: ''
        }
        if (localStorage.getItem('Islogin') === 'true' && localStorage.getItem('Islogin')) {
            store.dispatch(Islogin());
        }
        store.dispatch(getPrivateJobs());
        store.dispatch(get_User_District());
    }

    getQualification = (job) => {

        const qualification = []
        if (job.qualification) {
            qualification.push(job.qualification)
        }
        if (job.qualification2) {
            qualification.push(job.qualification2)
        }
        if (job.qualification3) {
            qualification.push(job.qualification3)
        }
        return qualification.length ? qualification.toString() : job.education_level

    }

    getStates = (job) => {
        const names = []
        if (typeof job.state_id !== "undefined") {
            const stateArray = JSON.parse(job.state_id)
            if (stateArray && stateArray.length && this.props.actionReducer.District && this.props.actionReducer.District.length > 0) {
                stateArray.forEach((state) => {
                    let filterVal = this.props.actionReducer.District.filter((district) => district.state_id === state)
                    if (filterVal && filterVal[0]) {
                        names.push(filterVal[0].state_name)
                    }
                })

            }

        }
        return names.length ? names.toString() : ''
    }

    componentDidMount() {
        document.title = this.props.t('govtJobs.private', { param: 'react' })

    }

    getExperienceToShow = (jobData) => {
        if ((jobData.min_exp && jobData.max_exp && parseInt(jobData.min_exp) > 0 && parseInt(jobData.max_exp) > 0)) {
            return `${jobData.min_exp} - ${jobData.max_exp} ${this.props.t('experience.years', { param: 'react' })}`
        } else {
            return this.props.t('JobPost.fresher', { param: 'react' })
        }
    }

    getLocationFromIds = (location) => {
        const names = []
        if (this.props.actionReducer.District && this.props.actionReducer.District.length > 0 && location && location.length) {
            this.props.actionReducer.District.forEach((data) => {
                if (data.state_id === "3") {
                    location.forEach(element => {
                        let filterVal = data.dist.filter((district) => district.id === element)
                        if (filterVal && filterVal[0]) {
                            names.push(filterVal[0].name)
                        }
                    });
                }
            })
        }
        return names.length ? names.toString() : ''
        // return  ''
    }

    clicked = (job) => {
        this.props.router.push(`/job-details/${job.id}`)
    }

    clickRegister = () => {
        if (this.state.job && this.state.job.id) {
            store.dispatch(setRedirectionLink(`job-details/${this.state.job.id}`))
            this.props.router.push('/signup?type=1')
        }
    }

    clickLogin = () => {
        if (this.state.job && this.state.job.id) {
            store.dispatch(setRedirectionLink(`job-details/${this.state.job.id}`))
            this.props.router.push('/signin')
        }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    render() {

        const { activePage } = this.state
        let searchResults = ''
        if (this.props.actionReducer.privateJobs && this.props.actionReducer.privateJobs.length) {
            searchResults = chunk(this.props.actionReducer.privateJobs, RESULT_PER_PAGE)

        }

        return (
            <div>
                <section className="total-job">
                    <div className="modal fade" id="serviceModal" style={{ 'zIndex': '99999' }}>
                        <div className="modal-dialog modal-dialog-centered modal-lg" >
                            <div className="modal-content d-block">
                                <div className="modal-body pt-2  pb-1 text-center">
                                    <img alt="" src="/images/logo-new2.png" className="img-fluid pb-2 home-top-logo" />
                                    <h5 className="mx-auto  text-uppercase mb-3">
                                        {this.props.t('homeContent.pgrkamTitle', { param: 'react' })}
                                    </h5>
                                    <div className="row d-flex">
                                        <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex ">
                                            <div className="first-click">
                                                <img alt="" src="/images/mod2.png" className="img-fluid pb-2 w-25" />
                                                <p className={"pl-2 pr-2"}> {this.props.t('homeContent.inst1', { param: 'react' })}.</p>
                                                <Link onClick={() => this.clickLogin()} data-dismiss="modal" style={{ "color": "#fff" }} className="btn btn-danger pt-1 pb-1" >
                                                    {this.props.t('homeContent.clickToLogin', { param: 'react' })}</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-6 mb-3 d-flex">
                                            <div className="first-click">
                                                <img alt="" src="/images/mod1.png" className="img-fluid pb-2 w-25" />
                                                <p className={"pl-2 pr-2"}>{this.props.t('homeContent.inst2', { param: 'react' })}.</p>
                                                <Link style={{ "color": "#fff" }} onClick={() => this.clickRegister()} data-dismiss="modal"
                                                    className="btn btn-danger pt-1 pb-1">{this.props.t('homeContent.clickToRegister', { param: 'react' })}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                    <div className="modal fade bd-example-modal-sm" tabIndex="-1" id="locationModal"
                        role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="mySmallModalLabel">{this.props.t('homeContent.locations', { param: 'react' })}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <ul className="loc-lisiting">
                                        {this.state.locations && this.state.locations.length ? this.state.locations.map((loc, key) => {
                                            return (<li key={key}><span >{loc}</span></li>)
                                        })
                                            : ''}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12 col-lg-8">
                                <h4 style={{ 'color': '#fff' }}>{this.props.t('govtJobs.private', { param: 'react' })}</h4>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="job-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-12 right-sidebar-scroll">
                                {searchResults && searchResults.length ?
                                    searchResults[activePage - 1].map((job, key) => {
                                        let locations = []
                                        let locationsArray = []
                                        let states = []
                                        if (job.location.length) {
                                            const locationList = this.getLocationFromIds(job.location)
                                            locationsArray = locationList.split(",")
                                            locations = locationsArray.slice(0, 3)
                                            locations = locations.toString()
                                        }
                                        if (job.state_id !== '' && !job.location.length) {

                                            const locationList1 = this.getStates(job)

                                            locationsArray = locationList1.split(",")
                                            locations = locationsArray.slice(0, 3)
                                            locations = locations.toString()
                                            states = JSON.parse(job.state_id)
                                        }

                                        return (
                                            <div className="right-sidebar" key={key}>

                                                <div className="first-job mb-3 p-3">
                                                    <div className="row">
                                                        <div className="col-md-10">

                                                            <h4 className="company-name">
                                                                <Link><i className="fa fa-globe pr-2"></i>{this.props.t('govtJobs.nameOfPost', { param: 'react' })}: {job.job_title}</Link>
                                                            </h4>
                                                            <h6 className="pt-1 company-name2 pb-1 mb-0 "><Link>{this.props.t('govtJobs.nameOfEmployer', { param: 'react' })}: {job.org_name}</Link></h6>
                                                            <ul className="nav">

                                                                {job.location && job.location.length ?
                                                                    <li className="w-100 pb-2 pt-0 float-left"><Link >
                                                                        <span><span className="float-left pt-2 pb-0  font-weight-bold">{this.props.t('govtJobs.placeOfPosting', 
                                                                        { param: 'react' })} :</span>
                                                                            <span className="ml-1 float-left pt-2 pb-0 "> {locations} {job.location.length > 3 &&
                                                                                <span style={{ "cursor": "pointer" }} className="date-clr" data-toggle="modal"
                                                                                    data-target="#locationModal" onClick={() => this.setState({ locations: locationsArray })}>{this.props.t('homeContent.viewLocations', { param: 'react' })}</span>}
                                                                            </span></span></Link></li>
                                                                    : null}
                                                                {job.state_id && !job.location.length ?
                                                                    <li className="w-100 pb-2 pt-0 float-left"><Link >
                                                                        <span><span className="float-left pt-2 pb-0  font-weight-bold">{this.props.t('govtJobs.placeOfPosting', { param: 'react' })} :</span>
                                                                            <span className="ml-1 float-left pt-2 pb-0 "> {locations} {states.length > 3 &&
                                                                                <span style={{ "cursor": "pointer" }} className="date-clr" data-toggle="modal"
                                                                                    data-target="#locationModal" onClick={() => this.setState({ locations: locationsArray })}>{this.props.t('homeContent.viewLocations', { param: 'react' })}</span>}
                                                                            </span></span></Link></li>
                                                                    : null}

                                                            </ul>

                                                            <p className="w-100 pb-2 pt-0 float-left"><span className="float-left p-0  font-weight-bold">{this.props.t('govtJobs.reqQual', { param: 'react' })}:</span>
                                                                <span className="ml-1 p-0"> {this.getQualification(job)}</span></p>
                                                            <p className="w-100 pb-2 pt-0 float-left"><span className="float-left p-0  font-weight-bold">{this.props.t('dashboard.table.salary', { param: 'react' })}:</span>
                                                                <span className="ml-1 float-left p-0"> {job.salary_min && job.salary_max ? job.salary_min + '-' + job.salary_max : job.salary_min} </span></p>
                                                            {job.salary && <span className="pr-5"><i className="fa fa-inr pr-2"
                                                                aria-hidden="true"></i> <span>{job.salary}</span></span>}




                                                        </div>
                                                        <div className="col-md-2">

                                                            <div className="text-right">
                                                                {
                                                                    this.props.actionReducer.Loginuser
                                                                        && this.props.actionReducer.Loginuser.user_type &&
                                                                        this.props.actionReducer.Loginuser.user_type === '1'
                                                                        ?
                                                                        (<button
                                                                            className="btn btn-primary btn-primary2 mt-2 ml-auto btn-center"
                                                                            onClick={() => this.clicked(job)}> {this.props.t('jobSearch.apply', { param: 'react' })}
                                                                        </button>

                                                                        ) : null

                                                                }
                                                                {
                                                                    !Object.keys(this.props.actionReducer.Loginuser).length ?
                                                                        (<button
                                                                            data-target="#serviceModal"
                                                                            data-toggle="modal"
                                                                            onClick={() => this.setState({ job })}

                                                                            className="btn btn-primary btn-primary2 mt-2 ml-auto btn-center"
                                                                        > {this.props.t('jobSearch.apply', { param: 'react' })}	</button>)
                                                                        : null
                                                                }

                                                            </div>
                                                            <div className="text-right">
                                                                <Link className="date-clr" to={`/job-details-home/${job.id}`}>
                                                                    {this.props.t('jobSearch.viewDetails', { param: 'react' })}
                                                                </Link>
                                                            </div>

                                                        </div>

                                                        <div className="row w-100 m-0 pb-3">

                                                            <div className="col-md-2 mb-1"><span className=" date-clr">{this.props.t('govtJobs.vacancy', { param: 'react' })}: <span>{job.vacancies} </span></span></div>
                                                            {/* <div className="col-md-4 mb-1"><span className=" date-clr">{this.props.t('counsellingProvider.expire_on', { param: 'react' })}: <span>{job.expiring_on} </span></span></div> */}
                                                            <div className="col-md-3 mb-1"><span className=" date-clr">{this.props.t('govtJobs.age', { param: 'react' })}: <span>
                                                                {job.age_preference ? job.age_preference : ''}


                                                            </span></span></div>
                                                            <div className="col-md-3 mb-1"><span className=" date-clr">{this.props.t('jobSearch.exp', { param: 'react' })}: <span>
                                                                {this.getExperienceToShow(job)} </span></span></div>



                                                            <div className="col-md-3 mb-1">{job.gender_preference && <span className=" date-clr">
                                                                {this.props.t('govtJobs.gender', { param: 'react' })}:

                                        <span>{" " + job.gender_preference} </span></span>}</div>

                                                        </div>
                                                        <div className="row w-100">
                                                            <div className="pull-right text-right w-100">
                                                                {!job.hasOwnProperty('apply_link') && <span className="mr-3 disabled-color" >{this.props.t('govtJobs.postedBy', { param: 'react' })}: {job.postedBy ? job.postedBy : "Employer"}</span>}
                                                                {job.hasOwnProperty('apply_link') && <span className="mr-3 disabled-color">{this.props.t('govtJobs.postedBy', { param: 'react' })}: {job.postedBy ? job.postedBy : "Admin"}</span>}
                                                                <span className="mr-3 disabled-color">{this.props.t('homeContent.postedOn', { param: 'react' })} {job.created_at}
                                                                </span>  </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    }) : ''}
                                {searchResults && searchResults.length > 1 ?
                                    <div className='react-pagination'>
                                        <Pagination
                                            pageRangeDisplayed={10}
                                            activePage={activePage}
                                            itemsCountPerPage={RESULT_PER_PAGE}
                                            totalItemsCount={this.props.actionReducer.privateJobs.length}
                                            onChange={this.handlePageChange}
                                        />
                                    </div> : ''}
                            </div>

                        </div>



                    </div>
                </section>
            </div>

        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(PrivateJobs));
