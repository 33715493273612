import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Sidebar from './Sidebar';
import store from "../../../store";
import {
    get_User_District, getEducationLevel,
    getExperience, get_org_type
} from "../../../actions/accountAction";
import {
    getSearchResults,
    get_jobseekerEducations,
    applyJob,
    setSearchResults,
    jobApplied,
    getAdvanceSearchResults,
    sendChatInvite,
    enableChat
} from "../../../actions/jobseekerAction";
import {
    get_genders,
    get_Coursenature
} from "../../../actions/jobpostAction";

import Pagination from "react-js-pagination";
import Validation from "../../hoc/Validation";
import { Link } from 'react-router'
import { chunk, capitalizeFirstLetter, getQualification } from '../../../helpers'
import { Multiselect } from 'multiselect-react-dropdown';

const RESULT_PER_PAGE = 25
const BASIC_SEARCH = 1
const PROFILE_SPECIFIC_SEARCH = 2
const NO_DISABILITY = 28

class Jobsearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            organisationList: [],
            formData: [],
            showDetails: false,
            job: '',
            activePage: 1,
            changed: false,
            modalState: false,
            submitted: false,
            locations: '',
            hasValue: false,
            searchType: 2,
            submittedProfileSearch: false,
            formData1: [],
            hasChanged: false
        }

        store.dispatch(setSearchResults([]));
        store.dispatch(get_genders());
        store.dispatch(get_org_type());
        store.dispatch(get_jobseekerEducations());
        store.dispatch(get_User_District());
        store.dispatch(getEducationLevel());
        store.dispatch(get_Coursenature());
        store.dispatch(getExperience());
    }

    componentDidMount() {
        document.title = this.props.t('sidebar.search_jobs', { param: 'react' })
    }

    getLocationFromIds = (location) => {
        let parsedLocation = this.parseLocation(location)
        const names = []
        if (this.props.actionReducer.District && this.props.actionReducer.District.length > 0 && parsedLocation && parsedLocation.length) {
            this.props.actionReducer.District.forEach((data) => {
                if (data.state_id === "3") {
                    parsedLocation.forEach(element => {
                        let filterVal = data.dist.filter((district) => district.id === element)
                        if (filterVal && filterVal[0]) {
                            names.push(filterVal[0].name)
                        }
                    });
                }
            })
        }
        return names.length ? names.toString() : ''
    }

    parseLocation = (location) => {
        return location ? JSON.parse(location) : []
    }

    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map(function (data, index) {
                if (data.state_name === 'Punjab') {
                    data.dist.map(function (distdata, distindex) {
                        Distoption.push({ name: distdata.name, id: distdata.id })
                        return Distoption
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }

    getExperienceToShow = (jobData) => {
        if ((jobData.min_exp && jobData.max_exp && parseInt(jobData.min_exp) > 0 && parseInt(jobData.max_exp) > 0)) {
            return `${jobData.min_exp} - ${jobData.max_exp} ${this.props.t('experience.years', { param: 'react' })}`
        } else {
            return this.props.t('JobPost.fresher', { param: 'react' })
        }
    }

    onSelectChange = (selectedList) => {
        const { formData } = this.state
        formData['location'] = selectedList
        if (!this.state.hasChanged) {
            this.setState({ hasChanged: true });
        }
        this.setState({ formData, submitted: false })
    }
    onSelectChange1 = (selectedList) => {
        const { formData1 } = this.state
        formData1['preferred_place'] = selectedList
        if (!this.state.changed) {
            this.setState({ changed: true });
        }
        this.setState({ formData1, submittedProfileSearch: false })
    }

    getStates = (job) => {
        const names = []
        if (typeof job.state_id !== "undefined") {
            const stateArray = JSON.parse(job.state_id)
            if (stateArray && stateArray.length && this.props.actionReducer.District && this.props.actionReducer.District.length > 0) {
                stateArray.forEach((state) => {
                    let filterVal = this.props.actionReducer.District.filter((district) => district.state_id === state)
                    if (filterVal && filterVal[0]) {
                        names.push(filterVal[0].state_name)
                    }
                })

            }

        }
        return names.length ? names.toString() : ''
    }

    checkPwd = (job) => {
        const disablityType = parseInt(this.props.JobseekerReducer.Profile.disablity_type_id)
        if(job.pwd ==='1' && disablityType && disablityType !== NO_DISABILITY){
            return true
        } else if(job.pwd ==='0' && (!disablityType || disablityType === NO_DISABILITY)){
            return true
        } else {
            return false
        }
    }

    handleProfileInput1 = async (e) => {
        const name = e.target.name;
        let value = e.target.value;
        let formData1 = this.state.formData1;

        if (e.target.type === 'number') {
            if (value.startsWith('0')) {
                value = ''
                e.target.value = ''
            }
        }
        formData1[name] = value;
        if (!value) {
            delete formData1[name]
        }
        if (name === "job_type" && value === '2') {
            delete formData1['preferred_place']
            delete formData1['exp']
            delete formData1['qualification']
            delete formData1['age']
            delete formData1['gender_preference']
            delete formData1['desired_salary']

        }
        if (name === 'education' && value) {
            delete formData1['qualification']
        }
        if (!this.state.changed) {
            this.setState({ changed: true });
        }

        this.setState({ formData1, submittedProfileSearch: false });
    }

    handleProfileInput = async (e) => {
        const name = e.target.name;
        let value = e.target.value;
        let formData = this.state.formData;

        formData[name] = value;
        if (!value) {
            delete formData[name]
        }
        if (name === "job_type" && value === '2') {
            delete formData['location']
            delete formData['experience']
            delete formData['qualification']
            delete formData['age']
            delete formData['govtType']
            delete formData['techType']
            delete formData['gender_preference']
        }

        if (!this.state.hasChanged) {
            this.setState({ hasChanged: true });
        }

        await this.setState({ formData, submitted: false });
    }


    applyForJob = async (job) => {
        this.setState({ job })
        await store.dispatch(applyJob({ job_id: job.id, pwd: job.pwd }));
    }


    submitAdvanceSearch = async (e) => {
        e.preventDefault();
        if (this.props.validator.allValid() && !this.state.submitted) {

            const formData = this.state.formData
            formData['auth_key'] = localStorage.getItem('token')
            await this.setState({ submitted: true, formData, activePage: 1 })
            store.dispatch(getSearchResults(this.state.formData, false));
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }

    }
    submitProfileSearch = async (e) => {
        e.preventDefault();
        if (!this.state.submittedProfileSearch) {

            const formData1 = this.state.formData1
            formData1['auth_key'] = localStorage.getItem('token')
            this.setState({ submittedProfileSearch: true, formData1, activePage: 1 })
            await store.dispatch(getAdvanceSearchResults(this.state.formData1));
        }

    }

    goToChat = (job) => {
        store.dispatch(enableChat({chat_id:job.employer_id, url:`/forum?chat=${job.employer_id}`}))
    }

    inviteForChat = (job) => {
        const data = this.state.searchType === 1 ? this.state.formData : this.state.formData1
        store.dispatch(sendChatInvite({ request_to: job.org_id, user_type: "6", employer_name: job.org_name }, data, this.state.searchType));
    }


    setDefaults = () => {

        const formData1 = this.state.formData1
        if (this.props.JobseekerReducer.Profile.highest_education_level) {
            formData1['education'] = this.props.JobseekerReducer.Profile.highest_education_level
        }
        if (this.props.JobseekerReducer.Profile.desired_salary) {
            formData1['desired_salary'] = this.props.JobseekerReducer.Profile.desired_salary
        }
        if (this.props.JobseekerReducer.Educations.length > 0) {
            formData1['qualification'] = this.props.JobseekerReducer.Educations[0].education_level_id
        }

        if (this.props.JobseekerReducer.Profile.district_id) {
            let filterDistObj = this.createDistoption()
            filterDistObj = filterDistObj.filter((dist) => dist.id === this.props.JobseekerReducer.Profile.district_id)
            formData1['preferred_place'] = filterDistObj && filterDistObj.length ? filterDistObj : []
        }

        if (this.props.JobseekerReducer.Profile.duration) {
            formData1['exp'] = this.props.JobseekerReducer.Profile.duration
        }

        if (this.props.JobseekerReducer.Profile.age) {
            formData1['age'] = !parseInt(this.props.JobseekerReducer.Profile.age) ? "" : this.props.JobseekerReducer.Profile.age
        }
        formData1['job_type'] = "1"
        this.setState({ formData1 })
    }



    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.changed) {
            this.setDefaults()
        }
        if (!this.state.hasChanged) {
            const formData = this.state.formData
            formData['job_type'] = "1"
            this.setState({ formData })
        }
        if (nextProps.JobseekerReducer.jobApplied) {
            this.setState({ showDetails: true })
            store.dispatch(jobApplied(false))

            if (this.state.searchType === BASIC_SEARCH) {
                store.dispatch(getSearchResults({ ...this.state.formData, auth_key: localStorage.getItem('token') }, false))
            } else {
                store.dispatch(getAdvanceSearchResults(this.state.formData1))
            }

        }
    }

    componentWillUnmount() {
        store.dispatch(setSearchResults([]));
        store.dispatch(jobApplied(false));
    }

    close = () => {
        this.setState({ showDetails: false })
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    setSearchType = (searchType) => {
        if (searchType === PROFILE_SPECIFIC_SEARCH) {
            this.setState({ formData: [] })
        }
        this.setState({ searchType })
    }

    capitalizeFirstLetter = (string) => {
        if (string) {
            const newString = string.toLowerCase()
            return newString.charAt(0).toUpperCase() + newString.slice(1);
        }
    }


    render() {
        const { activePage, showDetails, job, searchType, formData1 } = this.state

        let Mainprops = this.props;
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const searchResults = chunk(Mainprops.JobseekerReducer.searchResults, RESULT_PER_PAGE)

        let distData = []

        distData = this.createDistoption()

        const data = []
        if (searchType === PROFILE_SPECIFIC_SEARCH) {
            const educationLevel = this.props.actionReducer.educationLevel
            if (formData1.education) {
                const selectedEducation = educationLevel.filter((edu) => edu.education_id === formData1.education)
                if (selectedEducation && selectedEducation[0]) {
                    selectedEducation && selectedEducation[0] && selectedEducation[0].qualification.forEach((qual) => {
                        data.push({ label: qual.name, id: qual.id })
                    })
                }
            }
        }

        return (

            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="modal fade bd-example-modal-sm" tabIndex="-1" id="locationModal"
                    role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="mySmallModalLabel">{this.props.t('homeContent.locations', { param: 'react' })}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ul className="loc-lisiting">
                                    {this.state.locations && this.state.locations.length ? this.state.locations.map((loc, key) => {
                                        return (
                                            <li key={key}>
                                                <span >{loc}</span>
                                            </li>)
                                    })
                                        : ''}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">
                            <div className="clearfix"></div>
                            <div className="w-100 mt-1 pull-left">
                                <Link className={searchType === PROFILE_SPECIFIC_SEARCH ? "date-clr font-weight-bold" : ""}
                                    style={{ "cursor": "pointer" }} onClick={() => this.setSearchType(2)}>
                                    {this.props.t('sidebar.profileSearch', { param: 'react' })}</Link> / <Link className={searchType === BASIC_SEARCH ? "date-clr font-weight-bold" : ""}
                                        style={{ "cursor": "pointer" }} onClick={() => this.setSearchType(1)}>
                                    {this.props.t('sidebar.general', { param: 'react' })}</Link>
                            </div>
                            <div className="w-100 pull-left">
                                {!showDetails &&


                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane bg-education active p-0">

                                            {searchType === BASIC_SEARCH && <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('sidebar.search_jobs', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <form onSubmit={this.submitAdvanceSearch}>
                                                                    <section className="advance-body">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className="col-sm-2">
                                                                                        <label>{this.props.t('JobPost.keywordSearch', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-10 col-lg-10 form-group">
                                                                                        <input type="text"
                                                                                            value={this.state.formData.job_title ? this.state.formData.job_title : ''}
                                                                                            className="form-control"
                                                                                            onChange={this.handleProfileInput}
                                                                                            name="job_title" placeholder={this.props.t('forms.jobSearch', { param: 'react' })}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('jobSearch.education', { param: 'react' })}

                                                                                        </label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            value={this.state.formData.education_level ? this.state.formData.education_level : ''}
                                                                                            onChange={this.handleProfileInput}
                                                                                            className="form-control"
                                                                                            name="education_level">
                                                                                            <option value="">{this.props.t('forms.selectEdu', { param: 'react' })}
                                                                                            </option>
                                                                                            {Mainprops.actionReducer.educationLevel.length > 0 &&
                                                                                                Mainprops.actionReducer.educationLevel.map(function (data, index) {
                                                                                                    return (

                                                                                                        <option
                                                                                                            value={data.education_id}
                                                                                                            key={index}>{data.education_name}</option>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 ">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('JobPost.nature', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            onChange={this.handleProfileInput}
                                                                                            value={this.state.formData.job_type ? this.state.formData.job_type : ''}
                                                                                            className="form-control"
                                                                                            name={"job_type"}>
                                                                                            <option value="">{this.props.t('forms.jobType', { param: 'react' })}

                                                                                            </option>
                                                                                            {this.props.actionReducer.Orgtype && this.props.actionReducer.Orgtype.length ?
                                                                                                this.props.actionReducer.Orgtype.map((org, key) => {
                                                                                                    return (
                                                                                                        <option value={org.id} key={key}>{org.name}</option>
                                                                                                    )
                                                                                                }) : ''}

                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            {this.state.formData.job_type === '2' &&
                                                                                <div className="col-md-6 ">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4 ">
                                                                                            <label>{this.props.t('homeContent.govtTypeSelect', { param: 'react' })}

                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-8 col-lg-8 form-group">

                                                                                            <select
                                                                                                name="govtType"
                                                                                                className="form-control"
                                                                                                value={this.state.formData.govtType ? this.state.formData.govtType : ''}
                                                                                                onChange={this.handleProfileInput}
                                                                                            >
                                                                                                <option value={""}>{this.props.t('homeContent.govtType', { param: 'react' })}</option>
                                                                                                <option value={"1"}>{this.props.t('homeContent.gop', { param: 'react' })}</option>
                                                                                                <option value={"2"}>{this.props.t('homeContent.gopOther', { param: 'react' })}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>}
                                                                            {this.state.formData.govtType && this.state.formData.govtType === '2' &&
                                                                                <div className="col-md-6 ">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4 t">
                                                                                            <label>{this.props.t('homeContent.technicality', { param: 'react' })}

                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-8 col-lg-8 form-group">

                                                                                            <select
                                                                                                name="techType"
                                                                                                className="form-control"
                                                                                                value={this.state.formData.techType ? this.state.formData.techType : ''}
                                                                                                onChange={this.handleProfileInput}
                                                                                            >
                                                                                                <option value={""}>{this.props.t('homeContent.tech/nontech', { param: 'react' })}</option>
                                                                                                <option value={"1"}>{this.props.t('homeContent.tech', { param: 'react' })}</option>
                                                                                                <option value={"2"}>{this.props.t('homeContent.nonTech', { param: 'react' })}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>}
                                                                        </div>

                                                                        {this.state.formData.job_type !== '2' &&
                                                                            <div className="row">
                                                                                <div className="col-md-6 ">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4 ">
                                                                                            <label>{this.props.t('jobSearch.experience', { param: 'react' })}
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-8 col-lg-8 form-group">
                                                                                            <select
                                                                                                name="experience"
                                                                                                className="form-control"
                                                                                                value={this.state.formData.experience ? this.state.formData.experience : ''}
                                                                                                onChange={this.handleProfileInput}
                                                                                            >
                                                                                                <option value={""}>{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('jobSearch.exp', { param: 'react' })}</option>
                                                                                                {this.props.actionReducer.exps && this.props.actionReducer.exps.length &&
                                                                                                    this.props.actionReducer.exps.map((exp, key) => {
                                                                                                        return (

                                                                                                            <option key={key} value={exp.id}>{exp.name}</option>
                                                                                                        )
                                                                                                    })
                                                                                                }

                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="col-md-6 ">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4 t">
                                                                                            <label>{this.props.t('jobSearch.location', { param: 'react' })}

                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-8 col-lg-8 form-group">
                                                                                            <Multiselect
                                                                                                showCheckbox={true}
                                                                                                selectionLimit={3}
                                                                                                options={distData} // Options to display in the dropdown
                                                                                                selectedValues={this.state.formData.location ? this.state.formData.location : []} // Preselected value to persist in dropdown
                                                                                                onSelect={this.onSelectChange} // Function will trigger on select event
                                                                                                onRemove={this.onSelectChange} // Function will trigger on remove event
                                                                                                displayValue="name" // Property name to display in the dropdown options
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>}
                                                                        <hr />
                                                                        <div className="form-group">
                                                                            <div className="row">
                                                                                <div className="col-sm-12 ">
                                                                                    <button type="submit"
                                                                                        className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                                        {this.props.t('jobSearch.search', { param: 'react' })}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            }
                                            {searchType === PROFILE_SPECIFIC_SEARCH &&
                                                <section className="login-form mt-2">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                                <div className="panel-heading">
                                                                    <h6 className="mb-0">{this.props.t('sidebar.search_jobs', { param: 'react' })}</h6>
                                                                </div>
                                                                <div className="panel-body">
                                                                    <form onSubmit={this.submitProfileSearch}>
                                                                        <section className="advance-body">
                                                                            <div className="row">
                                                                                <div className="col-md-6 ">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4 ">
                                                                                            <label>{this.props.t('jobSearch.education', { param: 'react' })}

                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-8 col-lg-8 form-group">
                                                                                            <select
                                                                                                value={this.state.formData1.education ? this.state.formData1.education : ''}
                                                                                                onChange={this.handleProfileInput1}
                                                                                                className="form-control"
                                                                                                name="education">
                                                                                                <option value="">{this.props.t('forms.selectEdu', { param: 'react' })}
                                                                                                </option>
                                                                                                {Mainprops.actionReducer.educationLevel.length > 0 &&
                                                                                                    Mainprops.actionReducer.educationLevel.map(function (data, index) {
                                                                                                        return (

                                                                                                            <option
                                                                                                                value={data.education_id}
                                                                                                                key={index}>{data.education_name}</option>
                                                                                                        )
                                                                                                    })
                                                                                                }

                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6 ">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4">
                                                                                            <label>{this.props.t('JobPost.nature', { param: 'react' })}</label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm-8 col-lg-8 form-group">
                                                                                            <select
                                                                                                onChange={this.handleProfileInput1}
                                                                                                value={this.state.formData1.job_type ? this.state.formData1.job_type : ''}
                                                                                                className="form-control"
                                                                                                name={"job_type"}>
                                                                                                <option value="">{this.props.t('forms.jobType', { param: 'react' })}

                                                                                                </option>
                                                                                                {this.props.actionReducer.Orgtype && this.props.actionReducer.Orgtype.length ?
                                                                                                    this.props.actionReducer.Orgtype.map((org, key) => {
                                                                                                        return (
                                                                                                            <option value={org.id} key={key}>{org.name}</option>
                                                                                                        )
                                                                                                    }) : ''}

                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                {this.state.formData1.job_type === '2' &&
                                                                                    <div className="col-md-6 ">

                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 ">
                                                                                                <label>{this.props.t('homeContent.govtTypeSelect', { param: 'react' })}

                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-8 col-lg-8 form-group">

                                                                                                <select
                                                                                                    name="govtType"
                                                                                                    className="form-control"
                                                                                                    value={this.state.formData1.govtType ? this.state.formData1.govtType : ''}
                                                                                                    onChange={this.handleProfileInput1}
                                                                                                >
                                                                                                    <option value={""}>{this.props.t('homeContent.govtType', { param: 'react' })}</option>
                                                                                                    <option value={"1"}>{this.props.t('homeContent.gop', { param: 'react' })}</option>
                                                                                                    <option value={"2"}>{this.props.t('homeContent.gopOther', { param: 'react' })}</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>}
                                                                                {this.state.formData1.govtType && this.state.formData1.govtType === '2' &&
                                                                                    <div className="col-md-6 ">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 t">
                                                                                                <label>{this.props.t('homeContent.technicality', { param: 'react' })}

                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-8 col-lg-8 form-group">

                                                                                                <select
                                                                                                    name="techType"
                                                                                                    className="form-control"
                                                                                                    value={this.state.formData1.techType ? this.state.formData1.techType : ''}
                                                                                                    onChange={this.handleProfileInput1}
                                                                                                >
                                                                                                    <option value={""}>{this.props.t('homeContent.tech/nontech', { param: 'react' })}</option>
                                                                                                    <option value={"1"}>{this.props.t('homeContent.tech', { param: 'react' })}</option>
                                                                                                    <option value={"2"}>{this.props.t('homeContent.nonTech', { param: 'react' })}</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>}
                                                                            </div>
                                                                            {this.state.formData1.job_type !== '2' &&
                                                                                <div className="row">
                                                                                    <div className="col-md-6 ">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <label>{this.props.t('education.course', { param: 'react' })}</label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-8 col-lg-8 form-group">
                                                                                                <select
                                                                                                    onChange={this.handleProfileInput1}
                                                                                                    value={this.state.formData1.qualification ? this.state.formData1.qualification : ''}
                                                                                                    className="form-control"
                                                                                                    name="qualification">
                                                                                                    <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('education.course', { param: 'react' })}

                                                                                                    </option>
                                                                                                    {data && data.length > 0 &&
                                                                                                        data.map(function (d, index) {
                                                                                                            return (

                                                                                                                <option key={index}
                                                                                                                    value={d.id}>{d.label}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }

                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-6 ">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 ">
                                                                                                <label>{this.props.t('profileDetails.desiredSalary', { param: 'react' })}</label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-8 col-lg-8 form-group ">
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    value={this.state.formData1.desired_salary ? this.state.formData1.desired_salary : ''}
                                                                                                    onChange={this.handleProfileInput1}
                                                                                                    type="number"
                                                                                                    name="desired_salary"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}
                                                                            {this.state.formData1.job_type !== '2' &&
                                                                                <div className="row">
                                                                                    <div className="col-md-6 ">

                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 ">
                                                                                                <label>{this.props.t('jobSearch.experience', { param: 'react' })}

                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-8 col-lg-8 form-group">
                                                                                                <select
                                                                                                    name="exp"
                                                                                                    className="form-control"
                                                                                                    value={this.state.formData1.exp ? this.state.formData1.exp : ''}
                                                                                                    onChange={this.handleProfileInput1}
                                                                                                >
                                                                                                    <option value={""}>{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('jobSearch.exp', { param: 'react' })}</option>
                                                                                                    {this.props.actionReducer.exps && this.props.actionReducer.exps.length &&
                                                                                                        this.props.actionReducer.exps.map((exp, key) => {
                                                                                                            return (

                                                                                                                <option key={key} value={exp.id}>{exp.name}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="col-md-6 ">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 t">
                                                                                                <label>{this.props.t('jobSearch.location', { param: 'react' })}

                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-8 col-lg-8 form-group">
                                                                                                <Multiselect
                                                                                                    showCheckbox={true}
                                                                                                    selectionLimit={3}
                                                                                                    options={distData} // Options to display in the dropdown
                                                                                                    selectedValues={this.state.formData1.preferred_place ? this.state.formData1.preferred_place : []} // Preselected value to persist in dropdown
                                                                                                    onSelect={this.onSelectChange1} // Function will trigger on select event
                                                                                                    onRemove={this.onSelectChange1} // Function will trigger on remove event
                                                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>}
                                                                            {this.state.formData1.job_type !== '2' &&
                                                                                <div className="row">
                                                                                    <div className="col-md-6 ">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4">
                                                                                                <label>{this.props.t('jobSearch.age', { param: 'react' })}</label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-sm-8 col-lg-8 form-group">
                                                                                                <input type="number"
                                                                                                    value={this.state.formData1.age ? this.state.formData1.age : ''}
                                                                                                    className="form-control"
                                                                                                    onChange={this.handleProfileInput1}
                                                                                                    name="age" placeholder={this.props.t('forms.age_preference', { param: 'react' })}
                                                                                                    min="0" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}

                                                                            <hr />
                                                                            <div className="form-group">
                                                                                <div className="row">
                                                                                    <div className="col-sm-12 ">
                                                                                        <button type="submit"
                                                                                            className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                                            {this.props.t('jobSearch.search', { param: 'react' })}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </section>
                                                                    </form>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                        </div>
                                    </div>
                                }

                                {Mainprops.JobseekerReducer.searchResults && Mainprops.JobseekerReducer.searchResults.length > 0
                                    && !showDetails &&
                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane  active">
                                            <section className="job-details nogap mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{
                                                                    this.props.t('jobSearch.results', { param: 'react' })}
                                                                    <span className="pull-right" style={{ "color": "#fff" }}>
                                                                        {Mainprops.JobseekerReducer.searchResults.length ?
                                                                            Mainprops.JobseekerReducer.searchResults.length : 0}{this.props.t('JobPost.found', { param: 'react' })}</span></h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="">
                                                                    {searchResults && searchResults.length && searchResults[activePage - 1].map((job, key) => {
                                                                        let locations = []
                                                                        let locationsArray = []
                                                                        let states = ''
                                                                        if (job.state_id) {
                                                                            const locationList = this.getLocationFromIds(job.location)
                                                                            locationsArray = locationList.split(",")
                                                                            locations = locationsArray.slice(0, 3)
                                                                            locations = locations.toString()
                                                                            states = this.getStates(job)
                                                                        }
                                                                        return (

                                                                            <div className="right-sidebar" key={key}>
                                                                                <div className="first-job mb-3 p-3">
                                                                                    <div className="row">
                                                                                        <div className="col-md-8 widcustom">
                                                                                            <h4 className="company-name">
                                                                                                <Link>
                                                                                                    {this.props.t('govtJobs.nameOfPost', { param: 'react' })}:
                                                                                                    {capitalizeFirstLetter(job.job_title)}
                                                                                                </Link>
                                                                                            </h4>
                                                                                            <h6 className="pt-1 company-name2 pb-1 mb-0 "><Link>
                                                                                                {this.props.t('govtJobs.nameOfEmployer', { param: 'react' })}: {capitalizeFirstLetter(job.org_name)}</Link></h6>
                                                                                            <ul className="nav">
                                                                                                {job.state_id &&
                                                                                                    <li className="w-100 pb-2 pt-0 float-left"><Link >
                                                                                                        <span><span className="wid-30 float-left pt-2 pb-0  font-weight-bold">{this.props.t('govtJobs.placeOfPosting', { param: 'react' })} :</span>
                                                                                                            <span className="float-left pt-2 pb-0 "> {states} {this.parseLocation(job.location).length > 0 ? <span>({locations} {this.parseLocation(job.location).length > 3 &&
                                                                                                                <span style={{ "cursor": "pointer" }} className="date-clr" data-toggle="modal"
                                                                                                                    data-target="#locationModal" onClick={() => this.setState({ locations: locationsArray })}>{this.props.t('homeContent.viewLocations', { param: 'react' })}</span>})
                                                                                                                </span> : null}
                                                                                                            </span></span></Link></li>
                                                                                                }
                                                                                            </ul>

                                                                                            {!job.hasOwnProperty('apply_link') && <p className="w-100 pb-2 pt-0 float-left"><span className="wid-30 float-left p-0  font-weight-bold">{this.props.t('govtJobs.reqQual', { param: 'react' })}:</span>
                                                                                                <span className="float-left p-0"> {getQualification(job)}</span></p>}

                                                                                            {job.hasOwnProperty('apply_link') && <p className="w-100 pb-2 pt-0 float-left"><span className="wid-30 float-left p-0  font-weight-bold">{this.props.t('govtJobs.reqQual', { param: 'react' })}:</span>
                                                                                                <span className="float-left p-0"> {job.qualification ? capitalizeFirstLetter(job.qualification) : ''}</span></p>}
                                                                                            {!job.hasOwnProperty('apply_link') &&

                                                                                                <p className="w-100 pb-2 pt-0 float-left"><span className="wid-30 float-left p-0  font-weight-bold">{this.props.t('dashboard.table.salary', { param: 'react' })}:</span>
                                                                                                    <span className="float-left p-0"> {job.salary_min && job.salary_max ? job.salary_min + '-' + job.salary_max : job.salary_min} ({job.salary_type}) </span></p>}
                                                                                            {job.salary && job.hasOwnProperty('apply_link') && <span className="pr-5"> <span>{job.salary}</span></span>}

                                                                                        </div>

                                                                                        <div className="col-md-4">

                                                                                         
                                                                                                <>
                                                                                                    <div className="text-right pull-right w-100">
                                                                                                        <Link className="date-clr " to={`/job-details/${job.id}`}>
                                                                                                            {this.props.t('jobSearch.viewDetails', { param: 'react' })}
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                    <div className="text-right pull-right w-100">

                                                                                                        {!job.status && this.checkPwd(job) ?
                                                                                                            <button
                                                                                                                onClick={() => this.applyForJob(job)}
                                                                                                                className="btn btn-primary btn-primary2 mb-2">
                                                                                                                {this.props.t('jobSearch.apply', { param: 'react' })}
                                                                                                            </button> : null}
                                                                                                        {job.status &&
                                                                                                            <h6 className="date-clr ">{this.props.t('jobSearch.alreadyApplied', { param: 'react' })}
                                                                                                                <br></br>
                                                                                                                {this.props.t('jobSearch.appliedOn', { param: 'react' })} {job.applied_on}
                                                                                                            </h6>}
                                                                                                    </div>
                                                                                                    <div className="text-right pull-right w-100">
                                                                                                        <>
                                                                                                            <button
                                                                                                                onClick={() => this.inviteForChat(job)}
                                                                                                                className="btn btn-primary btn-primary2">
                                                                                                                    {this.props.t('jobSearch.inviteForChat', { param: 'react' })} <i className="fa fa-commenting-o" style={{ color: '#fff' }}></i>
                                                                                                            </button> 
                                                                                                          
                                                                                                                {/* <h6 className="date-clr ">{this.props.t('jobSearch.invitedForChat', { param: 'react' })}</h6> */}
                                                                                                           
                                                                                                                <button
                                                                                                                    onClick={() => this.goToChat(job)}
                                                                                                                    className="btn btn-primary btn-primary2" style={{marginTop:'5%'}}>
                                                                                                                    {this.props.t('jobSearch.chat', { param: 'react' })} <i className="fa fa-commenting-o" style={{ color: '#fff' }}></i>
                                                                                                                </button>
                                                                                                                </> 
                                                                                                    </div>
                                                                                                </>

                                                                                                        
                                                                                        </div>
                                                                                        {job.hasOwnProperty('apply_link') && <div className="row w-100 pl-3">
                                                                                            <div className="col-md-4 mb-1"><span className=" date-clr">{this.props.t('govtJobs.vacancy', { param: 'react' })}: <span>{job.vacancies} </span></span></div>
                                                                                            <div className="col-md-4 mb-1"><span className=" date-clr">{this.props.t('counsellingProvider.expire_on', { param: 'react' })}: <span>{job.expiring_on} </span></span></div>
                                                                                            {job.hasOwnProperty('apply_link') && <div className="col-md-4 mb-1"><span className=" date-clr">{this.props.t('homeContent.minAge', { param: 'react' })}: <span>
                                                                                                {job.age_preference ? job.age_preference : ''}
                                                                                            </span></span></div>}
                                                                                            {!job.hasOwnProperty('apply_link') &&
                                                                                                <div className="col-md-4 mb-1"><span className=" date-clr">{this.props.t('govtJobs.minAge', { param: 'react' })}: <span>
                                                                                                    {job.age_preference ? job.age_preference : ''}
                                                                                                </span></span></div>}
                                                                                        </div>}

                                                                                        {job.hasOwnProperty('apply_link') &&
                                                                                            <div className="row w-100 pl-3">
                                                                                                <div className="col-md-4 mb-1">
                                                                                                    <span className="date-clr">{this.props.t('govtJobs.applyHere', { param: 'react' })}: <span>
                                                                                                        <a rel="noopener noreferrer" className="mb-2" target="_blank" href={job.apply_link}>{this.props.t('govtJobs.clickHere', { param: 'react' })}</a>
                                                                                                    </span></span>
                                                                                                </div>
                                                                                                <div className="col-md-4 mb-1">
                                                                                                    <span className="date-clr">{this.props.t('govtJobs.readNotification', { param: 'react' })}: <span>
                                                                                                        <a rel="noopener noreferrer" className="mb-2" target="_blank" href={job.pdf_link}>{this.props.t('govtJobs.clickHere', { param: 'react' })}</a>
                                                                                                    </span></span>
                                                                                                </div>
                                                                                                <div className="col-md-4 mb-1">
                                                                                                    <span className="date-clr">
                                                                                                        {this.props.t('homeContent.whereToapply', { param: 'react' })}: <span>{this.capitalizeFirstLetter(job.apply_mode)} </span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        }

                                                                                        {!job.hasOwnProperty('apply_link') && <div className="row w-100 pl-3">
                                                                                            <div className="col-md-3 mb-1"><span className=" date-clr">{this.props.t('govtJobs.vacancy', { param: 'react' })}: <span>{job.vacancies} </span></span></div>
                                                                                            <div className="col-md-3 mb-1"><span className=" date-clr">{this.props.t('govtJobs.age', { param: 'react' })}: <span>
                                                                                                {job.age_preference ? job.age_preference : ''}
                                                                                            </span></span></div>
                                                                                            <div className="col-md-3 mb-1"><span className=" date-clr">{this.props.t('jobSearch.exp', { param: 'react' })}: <span>
                                                                                                {this.getExperienceToShow(job)} </span></span></div>
                                                                                            <div className="col-md-3 mb-1">
                                                                                                {job.hasOwnProperty('apply_link') && <div className="col-md-12 p-0"><span className=" date-clr">{this.props.t('govtJobs.age', { param: 'react' })}: <span>
                                                                                                    {job.max_age ? job.max_age : ''}
                                                                                                </span></span></div>}
                                                                                                {job.hasOwnProperty('apply_link') &&
                                                                                                    <div className="col-md-4 mb-1"><span className=" date-clr">{this.props.t('dashboard.table.experience', { param: 'react' })}: <span>
                                                                                                        {job.experience} </span></span></div>
                                                                                                }
                                                                                                {!job.hasOwnProperty('apply_link') &&
                                                                                                    <div className="col-md-12 mb-1">{job.gender_preference && <span className=" date-clr">
                                                                                                        {this.props.t('govtJobs.gender', { param: 'react' })}:

                                                                                                <span>{" " + job.gender_preference} </span></span>}</div>}
                                                                                            </div>
                                                                                        </div>}


                                                                                        <div className="row w-100 pt-3">
                                                                                            <div className="pull-right text-right w-100">
                                                                                                {!job.hasOwnProperty('apply_link') && parseInt(job.pwd) > 0 && <span className="disabled-color mr-3" >{this.props.t('JobPost.pwdInst', { param: 'react' })}</span>}
                                                                                                {!job.hasOwnProperty('apply_link') && <span className="mr-3 disabled-color" >{this.props.t('govtJobs.postedBy', { param: 'react' })}: {job.postedBy ? job.postedBy : "Employer"}</span>}
                                                                                                {job.hasOwnProperty('apply_link') && <span className="mr-3 disabled-color">{this.props.t('govtJobs.postedBy', { param: 'react' })}: {job.postedBy ? job.postedBy : "Admin"}</span>}
                                                                                                <span className="mr-3 disabled-color">{this.props.t('homeContent.postedOn', { param: 'react' })} {job.created_at}
                                                                                                </span>  </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {searchResults && searchResults.length > 1 ?

                                                                        <div className='react-pagination'>
                                                                            <Pagination
                                                                                pageRangeDisplayed={10}
                                                                                activePage={activePage}
                                                                                itemsCountPerPage={RESULT_PER_PAGE}
                                                                                totalItemsCount={Mainprops.JobseekerReducer.searchResults.length}
                                                                                onChange={this.handlePageChange}
                                                                            />
                                                                        </div> : ''}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}
                                {showDetails &&

                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane  active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('jobSearch.appliedJob', { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <p className="text-success">{this.props.t('jobSearch.successInst', { param: 'react' })}</p>
                                                                <ul className="nav d-block">
                                                                    <li className="list-items-inline pt-2">
                                                                        <b>{this.props.t('dashboard.table.job-title', { param: 'react' })}: {job.job_title}</b>
                                                                    </li>
                                                                    {job.org_name ?
                                                                        <li className="list-items-inline pt-2">
                                                                            <i
                                                                                className="fa fa-briefcase pt-2  pr-2"></i>{job.org_name}
                                                                        </li>
                                                                        : ''}
                                                                </ul>
                                                                <p className="pt-2 mb-0">
                                                                    <i
                                                                        className=" fa fa-rupee pr-2"></i>{job.salary_min && job.salary_max ? job.salary_min + '-' + job.salary_max : job.salary_min}

                                                                </p>
                                                                <button onClick={() => this.close()}
                                                                    className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                    {this.props.t('jobSearch.goBack', { param: 'react' })}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                }
                                {Mainprops.JobseekerReducer.searchResults && Mainprops.JobseekerReducer.searchResults.status === '401' &&

                                    <div className="tab-content">
                                        <div id="home" className=" tab-pane  active">
                                            <section className="login-form mt-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{
                                                                    this.props.t('jobSearch.results', { param: 'react' })}
                                                                    <span className="pull-right" style={{ "color": "#fff" }}>
                                                                        {Mainprops.JobseekerReducer.searchResults && Mainprops.JobseekerReducer.searchResults.length ?
                                                                            Mainprops.JobseekerReducer.searchResults.length : 0}{this.props.t('JobPost.found', { param: 'react' })}</span></h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <h5>{Mainprops.JobseekerReducer.searchResults.message}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}

                            </div>
                        </main>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(Validation(Jobsearch)));
