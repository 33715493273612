import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Jobseekersignup from './Jobseekersignup';
import Employersignup from './Employersignup';
import ForgienStudyProvider from './ForgienStudyProvider'
import {
  user_verify, Resendotp, resetUserVerification, get_selected_User_District,
  set_RegisterUser, setRedirectionLink, get_User_Type
} from '../../actions/accountAction';
import store from '../../store';
import { Link } from 'react-router'
import Validation from "../hoc/Validation";
import ForgienProvider from './ForgienProvider'


class Signup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitStatus: false,
      basePath: window.location.protocol + '//' + window.location.host + '/'
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    store.dispatch(get_selected_User_District())
    store.dispatch(get_User_Type());
    if (this.props.location.query.action) {
      store.dispatch(setRedirectionLink(this.props.location.query.action));
    }
  }

  componentDidMount() {
    document.title = this.props.t('header.register', { param: 'react' })
  }

  componentWillUnmount() {
    store.dispatch(resetUserVerification())
    store.dispatch(set_RegisterUser([]))
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    this.setState({ submitStatus: false });
  }

  handleSubmit(event) {
    event.preventDefault();
    const user = {
      otp: this.state.opt,
      username: this.props.actionReducer.Registeruser.username
    };
    if (!this.state.submitStatus && this.props.validator.allValid()) {
      this.setState({ submitStatus: true });
      store.dispatch(user_verify(user));
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      this.props.validator.showMessages();
      this.forceUpdate();
    }
  }
  handleUserSentotp(event) {
    event.preventDefault();
    const user = {
      mobile: this.props.actionReducer.Registeruser.mobile
    };
    store.dispatch(Resendotp(user));
  }
  changeRegister = (event) => {
    this.props.router.push(`signup?type=${event}`)
  }
  render() {

    const { validator } = this.props
    if (this.props.actionReducer.link && this.props.actionReducer.Loginuser &&
      this.props.actionReducer.Loginuser.user_type === '1') {
      window.location.href = this.state.basePath + this.props.actionReducer.link
      return
    }
    if (localStorage.getItem('Islogin') && localStorage.getItem('Islogin') !== 'false') {
      this.props.router.push('/myaccount');
    }



    let Mainprops = this.props;

    return (
      <section className="login-form main-content-height">
        <div className="container">
          <div className="row m-0">

            <div className="col-md-12 mx-auto cover mb-4 cover-shadow border-0 ">
              <div className={"row"}>
                <div className="col-md-6 first-click-inst p-4 ">
                  <h5>{this.props.t('register_user.instructions', { param: 'react' })}.</h5>
                  <ul className="jobseeker-benefits">
                    <li>{this.props.t('register_user.inst1', { param: 'react' })}</li>
                    <li>{this.props.t('register_user.inst2', { param: 'react' })}</li>
                    <li>{this.props.t('register_user.inst3', { param: 'react' })}</li>
                    <li>{this.props.t('register_user.inst4', { param: 'react' })}</li>
                    <li>{this.props.t('register_user.inst5', { param: 'react' })}</li>
                    <li>{this.props.t('register_user.inst6', { param: 'react' })}</li>
                    <li>{this.props.t('register_user.inst7', { param: 'react' })}</li>
                  </ul>
                  <span className={"mr-2"}>{this.props.t('banner.already_registered', { param: 'react' })}</span>
                  <Link to={"signin"} className={"mr-2"}>{this.props.t('banner.login', { param: 'react' })}</Link>


                </div>
                <div className={"col-md-6 p-3"}>
                  {!Mainprops.actionReducer.Registeruser.hasOwnProperty('mobile') &&
                    <div>
                      <div className="col-sm-12 text-left p-0">
                        <label>{this.props.t('register_user.user_type', { param: 'react' })}<span
                          style={{ color: 'red' }} className="ml-2">*</span></label>

                        <select onChange={(e) => this.changeRegister(e.target.value)}
                          value={Mainprops.location.query.type}
                          disabled={Mainprops.location.query.action}
                          className="form-control form-shadow" id="sel1" name="sellist1">
                          <option value="">{this.props.t('placeholder.select', { param: 'react' })}</option>
                          {Mainprops.actionReducer.UserSubType.length > 0 &&
                            Mainprops.actionReducer.UserSubType.map(function (data, index) {
                              return (
                                <option key={index}
                                  value={data.id}>{data.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <hr />
                      
                      {Mainprops.actionReducer.UserSubType.length > 0 &&
                        Mainprops.actionReducer.UserSubType.map( (data, index) => {
                          
                          if (Mainprops.location.query.type === data.id && data.name === 'Indian Employer') {
                            return (
                              <Employersignup key={index} />
                            )
                          }

                         
                          if (Mainprops.location.query.type === data.id && data.name === 'Foreign job Provider') {
                            return (
                              <ForgienProvider key={index} />
                            )
                          }
                          if (Mainprops.location.query.type === data.id && data.name === 'Foreign Study Provider') {
                            return (
                              <ForgienStudyProvider key={index} />
                            )
                          }
                          if ((Mainprops.location.query.type === data.id) && (data.name === 'Jobseeker' || data.name === 'Counselling Provider' || 'Local Service Provider')) {
                            return (
                              <Jobseekersignup locationData={this.props.location.query} key={index} />
                            )
                          }
                          return ('')
                        })
                      }
                    </div>

                  }



                  {Mainprops.actionReducer.Registeruser.hasOwnProperty('mobile') > 0 && !Mainprops.notificationReducer.user_verify &&
                    !Mainprops.actionReducer.Registeruser.approved_by_dbee &&
                    <div className="panel-body">
                      <div className="row">
                        <form className="col-md-12 mx-auto" onSubmit={this.handleSubmit}>
                          <div className="m-0 text-success">
                            <p className=" text-success mb-1">{this.props.t('login.hi', { param: 'react' })}
                              {Mainprops.actionReducer.Registeruser.firstname}, </p>

                            <p className=" text-success mb-1">  {this.props.t('login.registered', { param: 'react' })}.</p>
                            <p className=" text-success mb-1">  {this.props.t('login.id', { param: 'react' })}
                              {Mainprops.actionReducer.Registeruser.username}</p>
                          </div>
                          <div className="mt-3 signup-heading">
                            {this.props.t('login.verify_no', { param: 'react' })}
                            <hr className="mt-1" />
                          </div>
                          <span className="mb-2 d-block">{this.props.t('login.code', { param: 'react' })}{this.props.t('login.substring', { param: 'react' })}{Mainprops.actionReducer.Registeruser.mobile.substring(6)}</span>

                          <div className="form-group">
                            <div className="row">

                              <div className="col-sm-12">
                                <input type="text" onChange={(event) => this.handleUserInput(event)}
                                  value={this.state.opt ? this.state.opt : ''} className="form-control" id="opt" name="opt" />
                                {validator.message('OTP', this.state.opt, 'required')}
                                <button type="submit" className="btn btn-primary2 mr-2 mt-3">{this.props.t('education.Submit', { param: 'react' })}</button>
                                <p onClick={(event) => this.handleUserSentotp(event)} className="mt-4 float-left mr-3 date-clr" style={{ cursor: 'pointer' }}>{this.props.t('login.resend_otp', { param: 'react' })}</p>
                              </div>
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>

                  }
                  {Mainprops.notificationReducer.user_verify &&
                    <div className="panel-body">
                      <div className="row">
                        <form className="col-md-10 mx-auto">
                          <div className="mt-5 text-success">
                            {this.props.t('login.otp_verified', { param: 'react' })} <br /><br />
                            {this.props.t('login.job_rec1', { param: 'react' })}
                            <a className="ml-1 date-clr mr-1" href="/signin">{this.props.t('login.login', { param: 'react' })} </a>
                            {this.props.t('login.job_rec2', { param: 'react' })}<br /><br />
                            {this.props.t('login.otpInst', { param: 'react' })} <a className="ml-1 date-clr mr-1" href="/signin-with-otp">{this.props.t('login.login_otp', { param: 'react' })} </a><br /><br />
                            {this.props.t('login.job_rec3', { param: 'react' })}<br /><br />
                            {this.props.t('login.job_rec4', { param: 'react' })}
                          </div>
                        </form>
                      </div>
                    </div>
                  }
                  {Mainprops.actionReducer.Registeruser.approved_by_dbee &&
                    <div className="panel-body">
                      <div className="row">
                        <form className="col-md-10 mx-auto">

                          <div className="m-0 text-success">
                            <p className=" text-success mb-1">{this.props.t('login.hi', { param: 'react' })}
                              {Mainprops.actionReducer.Registeruser.firstname}, </p>

                            <p className=" text-success mb-1">  {this.props.t('login.registered', { param: 'react' })}.</p>
                            <p className=" text-success mb-1">  {this.props.t('login.notifyDbee', { param: 'react' })}
                            </p>
                          </div>


                        </form>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = store => {
  return store;
};

export default connect(mapStateToProps)(translate(Validation(Signup)));
