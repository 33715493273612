import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import Pagination from "react-js-pagination";
import { chunk } from '../../helpers'
import { Link } from 'react-router'
import store from "../../store"
import { getGovtJobs, Islogin, get_org_type } from "../../actions/accountAction";

const RESULT_PER_PAGE = 25

class GovtJobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
        }
        if (localStorage.getItem('Islogin') === 'true' && localStorage.getItem('Islogin')) {
            store.dispatch(Islogin());
        }
        store.dispatch(getGovtJobs());
        store.dispatch(get_org_type());
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    capitalizeFirstLetter = (string) => {
        if (string) {
            const newString = string.toLowerCase()
            return newString.charAt(0).toUpperCase() + newString.slice(1);
        }
    }

    componentDidMount() {
        document.title = this.props.t('govtJobs.title', {param: 'react'})
    }

    render() {
        const govtJobs = chunk(this.props.actionReducer.govtJobs, RESULT_PER_PAGE)
        const { activePage } = this.state
        return (
            <div>
                <section className="total-job">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12 col-lg-8">
                                <h4 style={{ 'color': '#fff' }}>{this.props.t('govtJobs.title', { param: 'react' })}</h4>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="job-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-12 right-sidebar-scroll">
                                    {govtJobs && govtJobs.length ?
                                        govtJobs[activePage - 1].map((job, key) => {
                                            return (
                                                <div className="right-sidebar" key={key}>
                                                    <div className="first-job mb-3 p-3">
                                                        
                                                        <div className="row">
    
                                                            <div className="col-md-9">
                                                                <h4 className="company-name">
                                                                    <Link><i className="fa fa-globe pr-2"></i>{this.props.t('govtJobs.nameOfPost', { param: 'react' })}: {job.job_title}</Link>
                                                                </h4>
                                                                <h6 className="pt-1 company-name2 pb-1 mb-0 "><Link>
                                                                    {this.props.t('govtJobs.nameOfEmployer', { param: 'react' })}: {job.company_name}</Link></h6>
                                                                <ul className="nav">
    
    
                                                                    {job.location && job.hasOwnProperty('apply_link') &&
                                                                        <li className="w-100 pb-2  pt-0 float-left"><Link ><span className="wid-30 float-left p-0  font-weight-bold">
                                                                        </span>
                                                                            <span className="float-left pt-2 pb-0  font-weight-bold">{this.props.t('govtJobs.placeOfPosting', { param: 'react' })}: </span><span className="ml-1 pt-2 float-left pb-0"> {job.location} </span>
                                                                        </Link></li>
                                                                    }
    
                                                                </ul>
                                                                </div>
    
                                                                <div className="row w-100 px-3 m-0">
                                                                <p className="w-100 pb-2 pt-0 float-left"><span className="float-left p-0  font-weight-bold">{this.props.t('govtJobs.reqQual', { param: 'react' })}: </span><span className="ml-1 float-left p-0"> {job.display_qualification ? job.display_qualification : ''}</span></p>
                                                                {job.salary && <p className="w-100 pb-2 pt-0 float-left"><span className="wid-30 float-left p-0  font-weight-bold">{this.props.t('dashboard.table.salary1', { param: 'react' })}:</span>
                                                                    <span className="float-left p-0"> {job.salary}</span></p>}
                                                                {/* <span className="pr-5"> <span>{job.}</span></span> */}
    </div>
                                                          
                                                            <div className="row w-100 m-0">
                                                                <div className="col-md-3"> <span className="date-clr">{this.props.t('govtJobs.vacancy', { param: 'react' })}: <span>{job.vacancy} </span></span></div>
                                                                <div className="col-md-3"><span className="date-clr">{this.props.t('govtJobs.lastDate', { param: 'react' })}: <span>{job.last_date} </span></span></div>
                                                                <div className="col-md-3"> <span className="date-clr">{this.props.t('dashboard.table.experience', { param: 'react' })}: <span>{job.experience ? job.experience : "Not required"} </span></span></div>
    
                                                                <div className="col-md-3">  {job.gender_preference && <span className="date-clr">{this.props.t('govtJobs.gender', { param: 'react' })}: <span>{job.gender_preference} </span></span>}</div>
                                                            </div>
                                                            <div className="row w-100 m-0">
                                                                <div className="col-md-3">
                                                                    <span className="date-clr">{this.props.t('homeContent.minAge', { param: 'react' })}: <span>
                                                                        {job.min_age ? job.min_age : ''}
    
                                                                    </span></span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span className="date-clr">{this.props.t('govtJobs.age', { param: 'react' })}: <span>
                                                                        {job.max_age ? job.max_age : ''}
    
                                                                    </span></span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    {/* {job.apply_link && <span><span className="date-clr">{this.props.t('govtJobs.applyHere', { param: 'react' })}:   </span>
                                                                        <a rel="noopener noreferrer" className="" target="_blank" href={job.apply_link}>{this.props.t('govtJobs.clickHere', { param: 'react' })}</a>
                                                                    </span>} */}
                                                                    {job.apply_link && <span><span className="date-clr">{this.props.t('govtJobs.applyHere', { param: 'react' })}:   </span><a rel="noopener noreferrer" className="mr-4 mb-2" target="_blank" href={"https://"+job.apply_link.trim().replace(": ", "").replace('http://', "").replace('https://', "")}>{this.props.t('govtJobs.clickHere', { param: 'react' })}</a></span>}
                                                                </div>
                                                                <div className="col-md-3">
                                                                    {job.pdf_link && <span>
                                                                        <span className="date-clr">{this.props.t('govtJobs.readNotification', { param: 'react' })}:  </span>
                                                                        <a rel="noopener noreferrer" className="" target="_blank" href={job.pdf_link}>{this.props.t('govtJobs.clickHere', { param: 'react' })}</a></span>}
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span className="date-clr">{this.props.t('homeContent.whereToapply', { param: 'react' })}: <span>{this.capitalizeFirstLetter(job.apply_mode)} </span></span>
                                                                </div>
    
                                                            </div>
                                                            <div className="row w-100 pt-2">
                                                                <div className="pull-right text-right w-100">
                                                                    {!job.hasOwnProperty('apply_link') && <span className="mr-3 disabled-color" >{this.props.t('govtJobs.postedBy', { param: 'react' })}: {job.postedBy ? job.postedBy : "Employer"}</span>}
                                                                    {job.hasOwnProperty('apply_link') && <span className="mr-3 disabled-color">{this.props.t('govtJobs.postedBy', { param: 'react' })}: {job.postedBy ? job.postedBy : "Admin"}</span>}
                                                                    <span className="mr-3 disabled-color">{this.props.t('homeContent.postedOn', { param: 'react' })} {job.created_at}
                                                                    </span>  </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
    
                                            )
                                        }) : ''}
                            </div>
                            {govtJobs && govtJobs.length > 1 ?
                                <div className='react-pagination pagination-cls'>
                                    <Pagination
                                        pageRangeDisplayed={10}
                                        activePage={activePage}
                                        itemsCountPerPage={RESULT_PER_PAGE}
                                        totalItemsCount={this.props.actionReducer.govtJobs.length}
                                        onChange={this.handlePageChange}
                                    />
                                </div> : ''}
                        </div>



                    </div>
                </section>
            </div>

        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(GovtJobs));
