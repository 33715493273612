import React, { Component } from 'react';
import Sidebar from './Sidebar';
import store from '../../../store';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { chunk } from '../../../helpers'
import Pagination from "react-js-pagination";
import StarRatings from 'react-star-ratings'
import {
    getFeedback
} from '../../../actions/counsellingproviderAction'
import { Link } from 'react-router'

const RESULT_PER_PAGE = 25


class SessionFeedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePage: 1
        }
        store.dispatch(getFeedback());
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage: activePage })
    }

    componentDidMount() {
        document.title = this.props.t('counsellingProvider.sessionFeedBack', { param: 'react' })
    }

    render() {
        let Mainprops = this.props;

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        
        let searchResults = chunk(Mainprops.counsellingproviderReducer.feedbackList, RESULT_PER_PAGE)
        const { activePage } = this.state

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        {Sidebar ? <Sidebar route={Mainprops.route} /> : ''}
                        <main className="col second-side p-0 pb-3 h-100">

                            <div className="clearfix"></div>
                            <div>
                                <div id="home" className=" tab-pane active">
                                    <section className="job-details mt-4">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12  mx-auto  tab-content  p-0">
                                                    <div className="panel-heading">
                                                        <h6 className="mb-0">{this.props.t('counsellingProvider.sessionFeedBack', { param: 'react' })}</h6>
                                                    </div>
                                                    <div className="panel-body">
                                                        {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((jobs, key) => {
                                                            return (<div key={key}>
                                                                <h5>{this.props.t('counsellingProvider.session_name', { param: 'react' })}: {jobs.title}</h5>
                                                                <StarRatings
                                                                    rating={parseInt(jobs.rating)}
                                                                    numberOfStars={5}
                                                                    name='rating'
                                                                    starDimension="20px"
                                                                    starRatedColor="rgb(255, 215, 0)"
                                                                    starHoverColor="rgb(255, 215, 0)"
                                                                />
                                                                <hr></hr>
                                                            </div>)
                                                        })}

                                                        {searchResults && searchResults.length > 1 ?
                                                            <div className='react-pagination'>
                                                                <Pagination
                                                                    pageRangeDisplayed={10}
                                                                    activePage={activePage}
                                                                    itemsCountPerPage={RESULT_PER_PAGE}
                                                                    totalItemsCount={Mainprops.counsellingproviderReducer.feedbackList.length}
                                                                    onChange={this.handlePageChange}
                                                                />
                                                            </div> : ''}
                                                        {searchResults && !searchResults.length && <h5>{this.props.t('JobPost.notFound', { param: 'react' })}</h5>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>)
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(SessionFeedback));
