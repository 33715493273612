import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import { Link } from 'react-router'
import Validation from "../hoc/Validation";
import { sendFeedback, getVisitorCount } from '../../actions/accountAction'
import store from "../../store";
// import preval from 'preval.macro'
import StarRatings from 'react-star-ratings'

class RateEnquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basePath: window.location.protocol + '//' + window.location.host + '/',
            formData: [],
            error: ''
        }
        this.feedbackRef = React.createRef();
        this.disclaimer = React.createRef();
        store.dispatch(getVisitorCount())
    }

    submit = async (e) => {
        e.preventDefault()
        if (this.props.validator.allValid()) {
            await store.dispatch(sendFeedback(this.state.formData))
            await this.props.validator.hideMessages()
            this.setState({ formData: [] })
            this.feedbackRef.current.click()
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    ratingChanged = (val) => {
        const formData = this.state.formData
        formData['rating'] = val
        this.setState({ formData })
    }

    change = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;

        formData[name] = value;
        this.setState({ formData: formData });
    }

    render() {
        const { validator } = this.props
        const { formData } = this.state

        return (
            <div className="clearfix">
                <section>
                    <div className="container">
                        <div className="row footer-border  ml-0">
                            <div className="col-md-12 col-sm-12 col-lg-12 first2">
                            <form onSubmit={this.submit}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <StarRatings
                                                    rating={formData.rating}
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension="80px"
                                                    starRatedColor="rgb(255, 215, 0)"
                                                    starHoverColor="rgb(255, 215, 0)"
                                                    changeRating={this.ratingChanged}

                                                />
                                                {validator.message('rating', formData.rating, 'required')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{this.props.t('feedback.feedback', { param: 'react' })} : <span
                                                    className="ml-2"
                                                    style={{ "color": "red" }}>*</span></label>

                                                <textarea className="w-100 p-1"
                                                    onChange={this.change}
                                                    value={formData.feedback ? formData.feedback : ''}
                                                    placeholder={this.props.t('forms.enterFeedback', { param: 'react' })}
                                                    style={{ "height": "200px !important" }}
                                                    id="date" name="feedback" rows="4"
                                                    cols="8" />
                                                {validator.message('feedback', formData.feedback, 'required')}
                                            </div>


                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <div className="row">


                                            <div className="col-sm-12">
                                                <button type="submit"
                                                    className="btn btn-primary btn-primary2 mx-auto d-block"
                                                    style={{ "marginRight": "10px" }}>{this.props.t('feedback.submit', { param: 'react' })}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>   



                            </div>
                          
                         
                        </div>
                      
                    </div>
                </section>
                <div className="clearfix"></div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(RateEnquiry)));
