import React, { Component } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'

class FreeTraining extends Component {

    componentDidMount() {
        document.title = this.props.t('header.freeTraining', { param: 'react' })
    }

    render() {
        return (
            <div className="tab-content" style={{ border: "none" }}>
                <div id="home" className=" tab-pane active">
                    <section className="login-form mt-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 mx-auto  tab-content border-0 cover-shadow   mb-3 mt-3  ">
                                    <div className="panel-heading mobheading">
                                        <h6 className="mb-0">{this.props.t('header.freeTraining', { param: 'react' })}
                                        </h6>
                                    </div>
                                    <div className="panel-body p-policy pt-0  p-3 ">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="text-center">
                                                    <h3><u>National Skill Training Institute, Bengaluru</u></h3>
                                                    <p style={{ fontSize: "18px" }}>-Govt. of India, Ministry of  Skill Development & Entrepreneurship</p>
                                                    <h4>organises</h4>
                                                    <h3><u>Residential Skill Development Programme</u></h3>
                                                    <h4>in</h4>
                                                        <ul className="free-training">
                                                        <li>Aerospace Welding, and</li>
                                                        <li>Aerospace CNC machining</li>
                                                    </ul>
                                                        Date From <b>22<sup>2nd</sup> March </b> to <b>17<sup>th</sup> April, 2021</b><br></br>
                                                        <b>Register Online at:</b> <a target="_blank" rel="noopener noreferrer" href="https://nsdp.isro.gov.in/nsdp-hq/register.jsp">https://nsdp.isro.gov.in/nsdp-hq/register.jsp</a><br></br>
                                                        <b>Last Date</b> for Online Registration: 12-03-2021 @ 17:00 hrs IST<br></br>
                                                        At NSTI, Tumkur Road, Outer Ring Road, Yeshwantpur, Bengaluru - 560 022
                                                    
                                                    <hr></hr>
                                                    <h5>Eligibility Criteria</h5>
                                                    <ol className="free-training" style={{width:"40%"}}>
                                                        <li>Shall be an Indian citizen,</li>
                                                        <li>Shall possess valid NTC / NAC certificate in Welder, Fitter, Machinist, Turner - trades with or without any work experience.</li>
                                                        <li>Maximum age shall not exceed more than 25 years as on March 01, 2021.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(FreeTraining));
