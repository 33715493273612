import React from 'react';
import {connect} from 'react-redux';
import {getProfile} from '../../actions/counsellingproviderAction'
import store from '../../store'


export default function(ComposedComponent) {
  class ProtectSeekerTypeRoutes extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        basePath : window.location.protocol + '//' + window.location.host + '/',
      }
      if(this.props.counsellingproviderReducer.Profile && !Object.keys(this.props.counsellingproviderReducer.Profile).length){
        store.dispatch(getProfile());  
      }
    }

    getComponent = () => {
  
      const { basePath } = this.state
      if(this.props.counsellingproviderReducer.Profile && this.props.counsellingproviderReducer.Profile.hasOwnProperty("profileCompleted")){
        
        const isAuthenticated = this.props.counsellingproviderReducer.Profile.profileCompleted
        if(isAuthenticated ==='1'){
        return <ComposedComponent {...this.props} />
        } else {
       
              window.location.href = basePath +"access-denied"
            }
        

      } else if(this.props.counsellingproviderReducer.Profile && this.props.counsellingproviderReducer.Profile.hasOwnProperty("profileCompleted") && !this.props.counsellingproviderReducer.Profile.profileCompleted) {
        window.location.href = basePath +"access-denied"
      } 
        return null
    }



    render() {
      return (this.getComponent());
    }
  }

  function mapStateToProps(store) {
    return store
  }

  return connect(mapStateToProps)(ProtectSeekerTypeRoutes);
}
  
  

