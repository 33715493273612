import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import {
    get_org_type, get_org_category, get_jobprovider_profile,
    get_update_profile, get_Sector, getJobProviderAddress
} from '../../../actions/employerAction';
import { get_User_Subistrict, get_User_District } from '../../../actions/accountAction';
import store from '../../../store';
import Sidebar from './Sidebar';
import Validation from "../../hoc/Validation";
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router'

class Providerprofile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitStatus1: false,
            submitStatus2: false,
            submitStatus3: false,
            user_subtype: window.location.protocol + '//' + window.location.host + '/',
            Addresses: [],
            Profile: [],
            stateId: '',
            sundirectoryload: false,
            // additionalInfo: false
        }
        store.dispatch(get_org_type());
        store.dispatch(get_org_category());
        store.dispatch(get_Sector());
        store.dispatch(get_jobprovider_profile());
        store.dispatch(getJobProviderAddress());
        store.dispatch(get_User_District());
        this.validatorOffice = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });
        this.validatorInfo = new SimpleReactValidator({
            element: message => <div style={{ "color": "red" }}>{message}</div>
        });
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        const newState = { ...nextProps.EmployerReducer.Profile, ...nextProps.EmployerReducer.address[0] }
        this.setState({ 'Profile': newState });
        if (nextProps.EmployerReducer.address && nextProps.EmployerReducer.address[1] && nextProps.EmployerReducer.address[1].hasOwnProperty('district_id') && !this.state.sundirectoryload) {
            store.dispatch(get_User_Subistrict(nextProps.EmployerReducer.address[1].district_id, 'permanent'));
            this.setState({ sundirectoryload: true });
        }

    }


    componentDidMount() {
        document.title = this.props.t('jobProviderProfile.profile', { param: 'react' })
    }


    handleProfileInput3(e) {
        const Profile = this.state.Profile
        const name = e.target.name;
        const value = e.target.value;

        Profile[name] = value;
        this.setState({ Profile, submitStatus3: false });
    }

    handleProfileInput2(e) {
        const Profile = this.state.Profile
        const name = e.target.name;
        const value = e.target.value;

        Profile[name] = value;
        this.setState({ Profile, submitStatus2: false });
        // console.log('Profile', this.state.Profile)
    }


    handleProfileInput(e) {

        const name = e.target.name;
        let value = e.target.value;
        let Profile = this.state.Profile;
        // if (e.target.checked && name === 'additional') {
        //     // console.log(e.target.checked)
        //     this.setState({ additionalInfo: true })
        // } else if (!e.target.checked && name === 'additional') {
        //     this.setState({ additionalInfo: false })
        // }

        Profile[name] = value;
        this.setState({ Profile, submitStatus1: false });
    }

    handleAddressesInput(e) {
        this.handleProfileInput2(e)
    }

    handleSubmitProfile(event) {
        event.preventDefault()
        let Profile = this.state.Profile;
        Profile['auth_key'] = localStorage.getItem('token');
        Profile['address_subtype_id'] = 1
        Profile['isSame'] = 1
        this.setState({ Profile });

        if (this.props.validator.allValid() && !this.state.submitStatus1) {
            store.dispatch(get_update_profile(Profile));
            this.props.validator.hideMessages();
            this.setState({ submitStatus1: true })
            // if (nextMenu) {
            //     document.getElementById(nextMenu).click()
            // }
        } else {
            this.props.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleSubmitProfile1(event) {
        event.preventDefault()
        let Profile = this.state.Profile;
        // console.log('this.validatorOffice', this.validatorOffice)
        // console.log('this.state.submitStatus2', this.state.submitStatus2)
        Profile['auth_key'] = localStorage.getItem('token');
        Profile['address_subtype_id'] = 1
        Profile['isSame'] = 1
        this.setState({ Profile });

        if (this.validatorOffice.allValid() && !this.state.submitStatus2) {
            store.dispatch(get_update_profile(Profile));
            this.validatorOffice.hideMessages();
            this.setState({ submitStatus2: true })

        } else {
            this.validatorOffice.showMessages();
            this.forceUpdate();
        }
    }

    handleSubmitProfile2(event, nextMenu = '') {
        event.preventDefault()
        let Profile = this.state.Profile;
        Profile['auth_key'] = localStorage.getItem('token');
        // Profile['address_subtype_id'] = 1
        // Profile['isSame'] = 1
        this.setState({ Profile });

        if (this.validatorInfo.allValid() && !this.state.submitStatus3) {
            store.dispatch(get_update_profile(Profile));
            this.validatorInfo.hideMessages();
            this.setState({ submitStatus3: true })
            // if (nextMenu) {
            //     document.getElementById(nextMenu).click()
            // }
        } else {
            this.validatorInfo.showMessages();
            this.forceUpdate();
        }
    }


    createState = () => {
        let Mainprops = this.props;
        if (Mainprops.actionReducer.District.length > 0) {
            // console.log('Mainprops.actionReducer.District', Mainprops.actionReducer.District)
            let Distoption = []
            Mainprops.actionReducer.District.forEach(function (data, index) {
                Distoption.push(<option key={index} value={data.state_id}>{data.state_name}</option>)
            })
            return Distoption
        }
    }

    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map((data, index) => {
                // if (data.state_id === this.state.Addresses.state_id || data.state_id === id) {
                if (data.state_id === this.state.Profile.state_id) {
                    data.dist.map(function (distdata, distindex) {
                        Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
                        return Distoption
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }

    years = () => {
        const yearList = []
        for (let i = 2000; i <= new Date().getFullYear(); i++) {
            yearList.push(<option key={i} value={i}>{i}</option>)
        }
        return yearList
    }

    render() {

        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }
        const { validator } = this.props

        let Mainprops = this.props;
        const { validatorInfo, validatorOffice } = this

        let Mainthis = this;
        // console.log('this.state.Profile', this.state.Profile)
        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                            </Link>
                        <Sidebar route={Mainprops.route} />
                        {Mainprops.JobseekerReducer.Profile.hasOwnProperty('org_name') &&
                            <main className="col second-side p-0 pb-3 h-100">
                                {/* <div className="clearfix"></div> */}

                                <div>
                                    <div className="row m-0 bg-white">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li data-placement="bottom"
                                                title={this.props.t('sidebar.profile_details', { param: 'react' })}
                                                className="nav-item">
                                                <a className={'nav-link mr-0 active show'} data-toggle="tab" id="menuTab1"
                                                    href="#home">{this.props.t('sidebar.profile_details', { param: 'react' })}</a>
                                            </li>

                                            <li
                                                data-placement="bottom"
                                                title={this.props.t('jobProviderProfile.reg_office', { param: 'react' })}
                                                className="nav-item">
                                                <a className="nav-link mr-0" data-toggle="tab" id="menuTab2"
                                                    href="#menu2">{this.props.t('jobProviderProfile.reg_office', { param: 'react' })}</a>
                                            </li>
                                            <li data-placement="bottom"
                                                title={this.props.t('jobProviderProfile.unique', { param: 'react' })}
                                                className="nav-item">
                                                <a className="nav-link mr-0" data-toggle="tab" id="menuTab3"
                                                    href="#menu3">{this.props.t('jobProviderProfile.unique', { param: 'react' })}</a>
                                            </li>



                                        </ul>
                                    </div>

                                    <div className="clearfix"></div>
                                    <div className="tab-content profile-page">
                                        <div id="home" className="tab-pane bg-education active pt-4">

                                            <form onSubmit={(event) => this.handleSubmitProfile(event, 'menuTab2')}>

                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <h6 className="mb-0 mt-2">{this.props.t('sidebar.profile_details', { param: 'react' })}</h6>
                                                    </div>


                                                </div> <hr></hr>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-5 text-right">
                                                            <label>{this.props.t('JobPost.cont_person', { param: 'react' })}:</label>
                                                        </div>

                                                        <div className=" col-sm-6 col-lg-5">
                                                            <input type="text"
                                                                onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                value={this.state.Profile.c_person ? this.state.Profile.c_person : ''}
                                                                className="form-control"
                                                                name="c_person"
                                                                placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('JobPost.cont_person', { param: 'react' })} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('jobProviderProfile.org_name', { param: 'react' })} :</label><span
                                                            style={{ color: 'red' }} className="ml-2">*</span>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-5">
                                                        <input type="text" className="form-control"
                                                            onChange={(event) => Mainthis.handleProfileInput(event)}
                                                            value={this.state.Profile.org_name ? this.state.Profile.org_name : ''}
                                                            id="org_name" name="org_name"
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('jobProviderProfile.org_name', { param: 'react' })}
                                                        />
                                                        {validator.message('organisation name', this.state.Profile.org_name, 'required')}
                                                    </div>
                                                </div>


                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-5 text-right">
                                                            <label>{this.props.t('profileDetails.mobile', { param: 'react' })}:</label>
                                                        </div>

                                                        <div className=" col-sm-6 col-lg-5">
                                                            <input onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                type="text"
                                                                placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('profileDetails.mobile', { param: 'react' })}
                                                                value={this.state.Profile.mobile ? this.state.Profile.mobile : ''}
                                                                className="form-control"
                                                                name="mobile" />
                                                            {validator.message('mobile', this.state.Profile.mobile, 'numeric|phone')}

                                                        </div>
                                                    </div>

                                                </div>


                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-5 text-right">
                                                            <label>{this.props.t('JobPost.email', { param: 'react' })}:</label>
                                                        </div>

                                                        <div className=" col-sm-6 col-lg-5">
                                                            <input type="text"
                                                                onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                value={this.state.Profile.c_email ? this.state.Profile.c_email : ''}
                                                                className="form-control"
                                                                name="c_email"
                                                                placeholder={this.props.t('forms.egEmail', { param: 'react' })} />
                                                            {validator.message('email', this.state.Profile.c_email, 'email')}

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-0 pb-3">
                                                    <div className="row">
                                                        <div className=" col-sm-12 text-center">
                                                            <hr />
                                                            <label className="form-check-label" htmlFor="check1">
                                                                <a className={"pull-right date-clr"}
                                                                    data-toggle="collapse" href="#my-panel1"
                                                                >{this.props.t('jobProviderProfile.additionalInfo', { param: 'react' })}</a>

                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {this.state.additionalInfo && */}
                                                <div id="my-panel1" className="collapse">
                                                    <div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-lg-5 text-right">
                                                                    <label>{this.props.t('jobProviderProfile.cat', { param: 'react' })} :</label>
                                                                    <span
                                                                        style={{ color: 'red' }}
                                                                        className="ml-2">*</span>
                                                                </div>

                                                                <div className="col-sm-6 col-lg-5">
                                                                    <select className="form-control"
                                                                        onChange={(event) =>
                                                                            Mainthis.handleProfileInput(event)}

                                                                        value={this.state.Profile.org_category ? this.state.Profile.org_category : ''}
                                                                        id="org_category"
                                                                        name="org_category">
                                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('jobProviderProfile.cat', { param: 'react' })}
                                                                        </option>
                                                                        {Mainprops.EmployerReducer.Orgcategory.length > 0 &&
                                                                            Mainprops.EmployerReducer.Orgcategory.map(function (data, index) {
                                                                                return (
                                                                                    <option key={index}
                                                                                        value={data.id}>{data.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {validator.message('organisation category', this.state.Profile.org_category, 'required')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-lg-5 text-right">
                                                                    <label>{this.props.t('JobPost.deg', { param: 'react' })}:</label>
                                                                </div>

                                                                <div className=" col-sm-6 col-lg-5">
                                                                    <input type="text"
                                                                        onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                        value={this.state.Profile.c_desg ? this.state.Profile.c_desg : ''}
                                                                        className="form-control"
                                                                        name="c_desg"
                                                                        placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('JobPost.deg', { param: 'react' })} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-lg-5 text-right">
                                                                    <label>{this.props.t('profileDetails.phone', { param: 'react' })}:</label>
                                                                </div>

                                                                <div className=" col-sm-6 col-lg-5">
                                                                    <input onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                        type="text"
                                                                        placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('JobPost.cont_phone', { param: 'react' })}
                                                                        value={this.state.Profile.c_phone ? this.state.Profile.c_phone : ''}
                                                                        className="form-control"
                                                                        name="c_phone" />
                                                                    {validator.message('phone no', this.state.Profile.c_phone, 'phone')}

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-lg-5 text-right">
                                                                    <label>{this.props.t('jobProviderProfile.sector', { param: 'react' })} :</label>
                                                                    {/* <span
                                                                            style={{color: 'red'}}
                                                                            className="ml-2">*</span> */}
                                                                </div>

                                                                <div className="col-sm-6 col-lg-5">
                                                                    <select className="form-control"
                                                                        value={this.state.Profile.sector ? this.state.Profile.sector : ''}
                                                                        onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                        name="sector">
                                                                        <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('jobProviderProfile.sector', { param: 'react' })}</option>
                                                                        {Mainprops.EmployerReducer.Sector && Mainprops.EmployerReducer.Sector.length > 0 &&
                                                                            Mainprops.EmployerReducer.Sector.map(function (data, index) {
                                                                                return (
                                                                                    <option key={index}
                                                                                        value={data.id}>{data.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {/* {validator.message('organisation name', this.state.Profile.sector, 'required')} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-0 pb-4">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-lg-5 text-right">
                                                                    <label>{this.props.t('jobProviderProfile.desc', { param: 'react' })} :</label>
                                                                    {/* <span style={{color: 'red'}}
                                                                                  className="ml-2">*</span> */}
                                                                </div>

                                                                <div className="col-sm-6 col-lg-5">
                                                                    <textarea
                                                                        rows="4"
                                                                        cols={"8"}

                                                                        onChange={(event) => Mainthis.handleProfileInput(event)}
                                                                        value={this.state.Profile.description ? this.state.Profile.description : ''}
                                                                        className="w-100 "
                                                                        name="description"
                                                                        placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('jobProviderProfile.desc', { param: 'react' })} />
                                                                    {/* {validator.message('description', this.state.Profile.description, 'required')} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* } */}
                                                <hr></hr>
                                                <div className="form-group mb-0 pb-4">
                                                    <div className="row">
                                                        <div
                                                            className="col-sm-6 col-lg-5 text-right"></div>

                                                        <div className=" col-sm-6 col-lg-5">
                                                            <button type="submit"

                                                                className="btn btn-primary btn-primary2 mr-2">{this.props.t('jobProviderProfile.submit', { param: 'react' })}
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6 p-2">
                                                    <div className="text-right">
                                                        <button type="submit"
                                                            className="btn btn-primary btn-primary2 mt-1">{this.props.t('profileDetails.submit', { param: 'react' })}</button>
                                                    </div>

                                                </div> */}

                                            </form>
                                        </div>
                                        <div id="menu2" className="tab-pane bg-education fade pt-4">
                                            <form onSubmit={(event) => this.handleSubmitProfile1(event, 'menuTab3')}>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <h6 className="mb-0 mt-2">{this.props.t('counsellingProvider.addressInfo', { param: 'react' })}</h6>
                                                    </div>
                                                    {/* <div className="col-md-4">
                                                        <div className="text-right">
                                                            <button type="submit"
                                                                className="btn btn-primary btn-primary2 mt-1">{this.props.t('profileDetails.saveNext', { param: 'react' })}</button>
                                                        </div>

                                                    </div> */}

                                                </div>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-5 text-right">
                                                            <label>{this.props.t('jobProviderProfile.add1', { param: 'react' })}:</label>
                                                            {/* <span
                                                                            style={{color: 'red'}}
                                                                            className="ml-2">*</span> */}
                                                        </div>

                                                        <div className=" col-sm-6 col-lg-5">
                                                            <input type="text" className="form-control"
                                                                onChange={(e) => this.handleAddressesInput(e)}
                                                                value={this.state.Profile.address_Line1 ? this.state.Profile.address_Line1 : ''}
                                                                id="address1" name="address_Line1"
                                                                placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('jobProviderProfile.add1', { param: 'react' })} />
                                                            {/* {validator.message('address line 1', this.state.Profile.address_Line1, 'required')} */}
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row form-group">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('jobProviderProfile.add2', { param: 'react' })} :</label>
                                                        {/* <span
                                                                        style={{color: 'red'}} className="ml-2">*</span> */}
                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        <textarea
                                                            rows="4"
                                                            cols={"8"} className="w-100"
                                                            onChange={(e) => this.handleAddressesInput(e)}
                                                            value={this.state.Profile.address_Line2 ? this.state.Profile.address_Line2 : ""}
                                                            id="address2" name="address_Line2"

                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('jobProviderProfile.add2', { param: 'react' })} />
                                                        {/* {validator.message('address line2', this.state.Profile.address_Line2, 'required')} */}
                                                    </div>
                                                </div>

                                                <div className="row form-group" style={{ display: "none" }}>
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('jobProviderProfile.add3', { param: 'react' })} :</label>
                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        <input type="text"
                                                            onChange={(e) => this.handleAddressesInput(e)}
                                                            value={this.state.Profile.address_Line3 ? this.state.Profile.address_Line3 : ""}
                                                            className="form-control" id="address3"
                                                            name="address_Line3"
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('jobProviderProfile.add3', { param: 'react' })} />
                                                    </div>
                                                </div>


                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-5 text-right">
                                                            <label>{this.props.t('jobProviderProfile.state', { param: 'react' })} :</label><span
                                                                style={{ color: 'red' }}
                                                                className="ml-2">*</span>
                                                        </div>

                                                        <div className="col-sm-6 col-lg-5">
                                                            <select
                                                                value={this.state.Profile.state_id ? this.state.Profile.state_id : ""}
                                                                className="form-control" id="state_id"
                                                                name="state_id"

                                                                onChange={(e) => this.handleAddressesInput(e)}>
                                                                <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('jobProviderProfile.state', { param: 'react' })}</option>
                                                                {this.createState()}
                                                            </select>
                                                            {validatorOffice.message('state', this.state.Profile.state_id, 'required')}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row form-group">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('jobProviderProfile.city', { param: 'react' })} :</label>
                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        <input type="text"
                                                            onChange={(e) => this.handleAddressesInput(e)}
                                                            value={this.state.Profile.city ? this.state.Profile.city : ""}
                                                            className="form-control"
                                                            name="city"
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('jobProviderProfile.city', { param: 'react' })} />
                                                    </div>
                                                </div>

                                                {this.state.Profile.state_id === '3' &&
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-sm-6 col-lg-5 text-right">
                                                                <label>{this.props.t('jobProviderProfile.district', { param: 'react' })} :</label><span
                                                                    style={{ color: 'red' }}
                                                                    className="ml-2">*</span>
                                                            </div>

                                                            <div className="col-sm-6 col-lg-5">
                                                                <select
                                                                    value={this.state.Profile.district_id ? this.state.Profile.district_id : ""}
                                                                    className="form-control" id="district"
                                                                    name="district_id"
                                                                    onChange={(e) => this.handleAddressesInput(e)}>
                                                                    <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('jobProviderProfile.district', { param: 'react' })}</option>
                                                                    {this.createDistoption()}
                                                                </select>
                                                                {validatorOffice.message('district', this.state.Profile.district_id, 'required')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row form-group">
                                                    <div className="col-sm-6 col-lg-5 text-right">
                                                        <label>{this.props.t('jobProviderProfile.pin', { param: 'react' })} :</label>
                                                        <span
                                                            style={{ color: 'red' }}
                                                            className="ml-2">*</span>
                                                    </div>

                                                    <div className="col-sm-6 col-lg-5">
                                                        <input type="number"
                                                            onChange={(e) => this.handleAddressesInput(e)}
                                                            value={this.state.Profile.pincode ? this.state.Profile.pincode : ''}
                                                            className="form-control" id="pincode"
                                                            name="pincode"
                                                            placeholder={this.props.t('placeholder.enter', { param: 'react' }) + " " + this.props.t('jobProviderProfile.pin', { param: 'react' })} />
                                                        {validatorOffice.message('pincode', this.state.Profile.pincode, 'required|numeric|min:6|max:8')}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div className="form-group mb-0 pb-4">
                                                    <div className="row">
                                                        <div
                                                            className="col-sm-6 col-lg-5 text-right"></div>

                                                        <div className=" col-sm-6 col-lg-5">
                                                            <button type="submit"

                                                                className="btn btn-primary btn-primary2 mr-2">{this.props.t('jobProviderProfile.submit', { param: 'react' })}
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>


                                            </form>
                                        </div>
                                        <div id="menu3" className="tab-pane bg-education fade pt-4">
                                            <form onSubmit={(event) => this.handleSubmitProfile2(event)}>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <h6 className="mb-0 mt-2">{this.props.t('jobProviderProfile.unique', { param: 'react' })}</h6>
                                                    </div>


                                                </div> <hr></hr>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-5 text-right">
                                                            <label>{this.props.t('jobProviderProfile.pan', { param: 'react' })} :</label>
                                                            <span style={{ color: 'red' }}
                                                                className="ml-2">*</span>
                                                        </div>

                                                        <div className=" col-sm-6 col-lg-5">
                                                            <input type="text"
                                                                onChange={(event) => Mainthis.handleProfileInput3(event)}
                                                                value={this.state.Profile.org_PAN ? this.state.Profile.org_PAN : ''}
                                                                className="form-control" id="org_PAN"
                                                                name="org_PAN"
                                                                placeholder="xxxxxxxxxx" />
                                                            {validatorInfo.message('pan', this.state.Profile.org_PAN, 'required|regex:^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$')}

                                                            {/*{error && <span style={{"color":"red"}}>{error}</span>}*/}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-5 text-right">
                                                            <label>{this.props.t('jobProviderProfile.gstn', { param: 'react' })} :</label>
                                                            {/* <span style={{color: 'red'}}
                                                                                  className="ml-2">*</span> */}
                                                        </div>

                                                        <div className=" col-sm-6 col-lg-5">
                                                            <input type="text"
                                                                onChange={(event) => Mainthis.handleProfileInput3(event)}
                                                                value={this.state.Profile.org_GSTN ? this.state.Profile.org_GSTN : ''}
                                                                className="form-control"
                                                                id="org_GSTN"
                                                                name="org_GSTN"
                                                                placeholder="xxxxxxxxxx" />
                                                            {/* {validator.message('gstin', this.state.Profile.org_GSTN, 'required')} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-5 text-right">
                                                            <label>{this.props.t('jobProviderProfile.yrIncr', { param: 'react' })}:</label>
                                                            {/* <span style={{color: 'red'}}
                                                                                  className="ml-2">*</span> */}
                                                        </div>

                                                        <div className=" col-sm-6 col-lg-5">
                                                            <select className="form-control"
                                                                onChange={(event) => Mainthis.handleProfileInput3(event)}
                                                                value={this.state.Profile.incorporation_year ? this.state.Profile.incorporation_year : ''}
                                                                id="incorporation_year"
                                                                name="incorporation_year">
                                                                <option value={""}>{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('experience.years', { param: 'react' })}</option>
                                                                {this.years()}
                                                            </select>
                                                            {/* {validator.message('incorporation year', this.state.Profile.incorporation_year, 'required')} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div className="form-group mb-0 pb-4">
                                                    <div className="row">
                                                        <div
                                                            className="col-sm-6 col-lg-5 text-right"></div>

                                                        <div className=" col-sm-6 col-lg-5">
                                                            <button type="submit"

                                                                className="btn btn-primary btn-primary2 mr-2">{this.props.t('jobProviderProfile.submit', { param: 'react' })}
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>


                                    </div>
                                </div>



                            </main>
                        }

                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(translate(Validation(Providerprofile)));
