import React, { Component } from 'react'
import Sidebar from './Sidebar';
import store from '../../../store';
import { translate } from 'react-multi-lang'
import { connect } from 'react-redux';
import { chunk } from '../../../helpers'
import {
    setSelfEmploymentSearchResults,
    postSelfEmploymentSearch,
    applySelfEmployment,
    get_jobseekerAddress,
    get_jobseeker_profile
} from
    '../../../actions/jobseekerAction';
import {
    getEducationLevel, get_org_category, get_genders,
    getSelfEmpDropDownData, get_User_District,
    get_User_Subistrict, get_User_Cities, set_User_SubDistrict
} from '../../../actions/accountAction'
import { get_sector_self_employment } from '../../../actions/jobpostAction'
import Pagination from "react-js-pagination";
import { Link } from 'react-router'
import ServerSetting from '../../../config.json'


const RESULT_PER_PAGE = 25
const BASIC_SEARCH = 1
const PROFILE_SPECIFIC_SEARCH = 2

class SearchSelfEmployment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: [],
            formData1: [],
            job: [],
            jobDetails: false,
            activePage: 1,
            submit: false,
            submitProfile: false,
            data: [],
            searchType: 2
        }

        store.dispatch(get_jobseeker_profile());
        store.dispatch(get_jobseekerAddress());
        store.dispatch(get_User_District())
        store.dispatch(getSelfEmpDropDownData())
        store.dispatch(get_genders());
        store.dispatch(get_sector_self_employment());
        store.dispatch(get_org_category());
        store.dispatch(getEducationLevel());
    }

    componentDidMount() {
        document.title = this.props.t('homeContent.selfEmpSearch', { param: 'react' })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.JobseekerReducer.Profile).length > 0 && nextProps.JobseekerReducer.Addresses &&
            Object.keys(nextProps.JobseekerReducer.Addresses.current).length > 0 && nextProps.actionReducer.educationLevel.length
            && !this.state.sundirectoryload) {
            const formData1 = this.state.formData1
            if (nextProps.JobseekerReducer.Addresses.current.district_id) {
                store.dispatch(get_User_Subistrict(nextProps.JobseekerReducer.Addresses.current.district_id));
                store.dispatch(get_User_Cities(nextProps.JobseekerReducer.Addresses.current.subdistrict_id));
                formData1['district_id'] = nextProps.JobseekerReducer.Addresses.current.district_id
            }
            formData1['education_level'] = nextProps.JobseekerReducer.Profile.highest_education_level
            formData1['subdistrict_id'] = nextProps.JobseekerReducer.Addresses.current.subdistrict_id
            const educationLevel = nextProps.actionReducer.educationLevel
            const data = []
            const selectedEducation = educationLevel.filter((edu) => edu.education_id === nextProps.JobseekerReducer.Profile.highest_education_level)
            if (selectedEducation && selectedEducation[0]) {
                selectedEducation && selectedEducation[0] && selectedEducation[0].qualification.forEach((qual) => {
                    data.push({ label: qual.name, id: qual.id })
                })
                this.setState({ data })
            }
            this.setState({ sundirectoryload: true, formData1 });
        }
    }

    handlePageChange = (activePage) => {
        this.setState({ activePage })
    }

    createDistoption = () => {
        let Mainprops = this.props;
        let Distoption = []
        if (Mainprops.actionReducer.District.length > 0) {
            Mainprops.actionReducer.District.map((data) => {
                if (data.state_id === "3") {
                    data.dist.map(function (distdata, distindex) {
                        if (distindex) {
                            Distoption.push(<option key={distindex} value={distdata.id}>{distdata.name}</option>)
                            return Distoption
                        }
                        return ''
                    })
                }
                return Distoption
            })
        }
        return Distoption
    }

    applyForJob = (job) => {
        store.dispatch(applySelfEmployment(job.id))
        this.setState({ job })
    }

    componentWillUnmount() {
        store.dispatch(setSelfEmploymentSearchResults([]))
    }

    handleInput = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData = this.state.formData;
        if (name === 'district_id') {
            store.dispatch(get_User_Subistrict(value));
            delete formData['subdistrict_id']
        }
        if (name === 'subdistrict_id') {
            store.dispatch(get_User_Cities(value));
            delete formData['city']
        }
        formData[name] = value;
        if (!value) {
            delete formData[name]
        }
        await this.setState({ formData, submit: false });
    }

    handleInputProfile = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formData1 = this.state.formData1;
        if (name === 'district_id') {
            store.dispatch(get_User_Subistrict(value));
            delete formData1['subdistrict_id']
        }
        if (name === 'subdistrict_id') {
            store.dispatch(get_User_Cities(value));
            this.setState({ data: [] })
        }
        formData1[name] = value;
        if (!value) {
            delete formData1[name]
        }
        await this.setState({ formData1, submitProfile: false });
    }

    submit = (e) => {
        e.preventDefault()
        if (!this.state.submit) {
            store.dispatch(postSelfEmploymentSearch({ auth_key: localStorage.getItem('token'), ...this.state.formData }));
            this.setState({ submit: true })
        }
    }

    submitProfile = (e) => {
        e.preventDefault()
        if (!this.state.submitProfile) {
            store.dispatch(postSelfEmploymentSearch({ auth_key: localStorage.getItem('token'), ...this.state.formData1 }));
            this.setState({ submitProfile: true })
        }
    }

    setSearchType = (searchType) => {
        if (searchType === BASIC_SEARCH) {
            this.setState({ formData: [] })
            store.dispatch(set_User_SubDistrict([]))
        } else {
            store.dispatch(get_User_Subistrict(this.state.formData1.district_id))
        }
        this.setState({ searchType })
    }

    render() {
        if (localStorage.getItem('Islogin') !== 'true') {
            this.props.router.push('/');
        }

        const Mainprops = this.props
        const { activePage, job, formData, searchType } = this.state
        const searchResults = chunk(Mainprops.JobseekerReducer.selfEmployment, RESULT_PER_PAGE)
        const dropDownData = Mainprops.actionReducer.dropDownData

        const SERVER_PATH = ServerSetting[process.env.NODE_ENV].SERVER_PATH;

        const education = []
        const loan_purpose = []
        const loan_type = []
        const projects_covered = []
        const repaying_period = []
        const scheme_name = []
        const experience = []
        const subsidy = []
        const training_required = []

        if (dropDownData && dropDownData.length) {
            dropDownData.forEach((data) => {
                education.push(data.education)
                loan_purpose.push(data.loan_purpose)
                loan_type.push(data.loan_type)
                projects_covered.push(data.projects_covered)
                repaying_period.push(data.repaying_period)
                scheme_name.push(data.scheme_name)
                subsidy.push(data.subsidy)
                training_required.push(data.training_required)
                experience.push(data.experience)
            })
        }

        const data = []
        if (formData.education_level) {
            const educationLevel = this.props.actionReducer.educationLevel
            const selectedEducation = educationLevel.filter((edu) => edu.education_id === formData.education_level)
            if (selectedEducation && selectedEducation[0]) {
                selectedEducation && selectedEducation[0] && selectedEducation[0].qualification.forEach((qual) => {
                    data.push({ label: qual.name, id: qual.id })
                })
            }
        }

        return (
            <section className="back-dashboard widget-sidebar-context" id="my-sidebar-context">
                <div className="container">
                    <div className="row">
                        <Link className="navbar-toggler widget-sidebar-toggler text-white">
                            Sidebar
                        </Link>
                        <Sidebar route={Mainprops.route} />
                        <main className="col second-side p-0 pb-3 h-100">

                            <div className="clearfix"></div>
                            <div>
                                <div className="w-100 mt-1 pull-left">
                                    <Link className={searchType === PROFILE_SPECIFIC_SEARCH ? "date-clr font-weight-bold" : ""}
                                        style={{ "cursor": "pointer" }} onClick={() => this.setSearchType(2)}>
                                        {this.props.t('sidebar.profileSearch', { param: 'react' })}</Link> / <Link className={searchType === BASIC_SEARCH ? "date-clr font-weight-bold" : ""}
                                            style={{ "cursor": "pointer" }} onClick={() => this.setSearchType(1)}>
                                        {this.props.t('banner.advanceSearch', { param: 'react' })}</Link>
                                </div>
                                <div className="w-100 pull-left">
                                    <div className="tab-content  w-100 float-left mb-3">
                                        <div className="modal fade" id="descriptionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        <h6>{this.props.t('counsellingProvider.details', { param: 'react' })}</h6>
                                                        {job.projects_covered ? <p>{this.props.t('selfEmployment.Project', { param: 'react' })}: {job.projects_covered}</p> : ''}
                                                        {job.education_level ? <p>{this.props.t('selfEmployment.Education Level', { param: 'react' })} : {job.education_level}</p> : ''}
                                                        {job.qualification ? <p>{this.props.t('selfEmployment.Qualification', { param: 'react' })}  : {job.qualification}</p> : ''}
                                                        {job.training_required ? <p>{this.props.t('selfEmployment.Training', { param: 'react' })}: {job.training_required}</p> : ''}
                                                        {job.experience ? <p>{this.props.t('selfEmployment.Experience (In Years)', { param: 'react' })} : {job.experience}</p> : ''}
                                                        {job.collateral_required ? <p>{this.props.t('selfEmployment.Can you offer collateral', { param: 'react' })}: {job.collateral_required && job.collateral_required === "1" ? "Yes" : "No"}</p> : ''}
                                                        {job.defaulter ? <p>{this.props.t('selfEmployment.Have you defaulted against a loan in past', { param: 'react' })}: {job.defaulter && job.defaulter === "1" ? "Yes" : "No"}</p> : ''}
                                                        {job.collateral_required ? <p>{this.props.t('selfEmployment.Can you offer collateral', { param: 'react' })}: {job.collateral_required && job.collateral_required === "1" ? "Yes" : "No"}</p> : ''}
                                                        {job.loan_type ? <p>{this.props.t('selfEmployment.Type of Loan', { param: 'react' })}: {job.loan_type}</p> : ''}
                                                        {job.loan_purpose ? <p>{this.props.t('selfEmployment.Purpose of Loan', { param: 'react' })}: {job.loan_purpose}</p> : ''}
                                                        {job.repaying_period ? <p>{this.props.t('selfEmployment.Repaying Period', { param: 'react' })}: {job.repaying_period}</p> : ''}
                                                        {job.family_income ? <p>{this.props.t('selfEmployment.Annual Family Income (In INR)', { param: 'react' })}: {job.family_income} </p> : ''}
                                                        {job.subdistrict_id ? <p>{this.props.t('selfEmployment.Block Name', { param: 'react' })}: {job.subdistrict_id}</p> : ''}
                                                        {job.description ? <p>{this.props.t('dashboard.table.description', { param: 'react' })}: {job.description}</p> : ''}
                                                        

                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-primart btn-primary2" data-dismiss="modal">
                                                            {this.props.t('counsellingProvider.close', { param: 'react' })}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="home" className=" tab-pane active">
                                            <section className="login-form mt-0">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('homeContent.selfEmpSearch', { param: 'react' })}</h6>
                                                            </div>

                                                            <div className="panel-body">
                                                                {searchType === PROFILE_SPECIFIC_SEARCH ?
                                                                    <form onSubmit={this.submitProfile}>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('JobPost.district', { param: 'react' })}</label>
                                                                                    </div>

                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select onChange={(event) => this.handleInputProfile(event)}
                                                                                            value={this.state.formData1.district_id ? this.state.formData1.district_id : ""}
                                                                                            name="district_id" className="form-control">
                                                                                            <option value={""}>{this.props.t('profileDetails.selectDistrict', { param: 'react' })}</option>
                                                                                            {this.createDistoption()}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('profileDetails.subdistrict', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select className="form-control"
                                                                                            value={this.state.formData1.subdistrict_id ? this.state.formData1.subdistrict_id : ""}
                                                                                            onChange={this.handleInputProfile}
                                                                                            name="subdistrict_id">
                                                                                            <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('profileDetails.subdistrict', { param: 'react' })}</option>
                                                                                            {this.props.actionReducer.Subdistrict && this.props.actionReducer.Subdistrict.length ?
                                                                                                this.props.actionReducer.Subdistrict.map((sub, key) => {
                                                                                                    return <option key={key} value={sub.id}>{sub.name}</option>
                                                                                                })
                                                                                                : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('JobPost.edu', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            value={this.state.formData1.education_level ? this.state.formData1.education_level : ''}
                                                                                            onChange={this.handleInputProfile}
                                                                                            className="form-control"
                                                                                            name="education_level">
                                                                                            <option value="">{this.props.t('forms.selectEdu', { param: 'react' })}
                                                                                            </option>
                                                                                            {Mainprops.actionReducer.educationLevel.length > 0 &&
                                                                                                Mainprops.actionReducer.educationLevel.map(function (data, index) {
                                                                                                    return (
                                                                                                        <option
                                                                                                            value={data.education_id}
                                                                                                            key={index}>{data.education_name}</option>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr></hr>
                                                                        <div className="form-group row">
                                                                            <div className="col-sm-12 ">
                                                                                <button type="submit"
                                                                                    className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                                    {this.props.t('jobSearch.search', { param: 'react' })}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    :
                                                                    <form onSubmit={this.submit}>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('JobPost.district', { param: 'react' })}</label>
                                                                                    </div>

                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select onChange={(event) => this.handleInput(event)}
                                                                                            value={this.state.formData.district_id ? this.state.formData.district_id : ""}
                                                                                            name="district_id" className="form-control">
                                                                                            <option value={""}>{this.props.t('profileDetails.selectDistrict', { param: 'react' })}</option>
                                                                                            {this.createDistoption()}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('profileDetails.subdistrict', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select className="form-control"
                                                                                            value={this.state.formData.subdistrict_id ? this.state.formData.subdistrict_id : ""}
                                                                                            onChange={this.handleInput}
                                                                                            name="subdistrict_id">
                                                                                            <option value="">{this.props.t('placeholder.select', { param: 'react' }) + " " + this.props.t('profileDetails.subdistrict', { param: 'react' })}</option>
                                                                                            {this.props.actionReducer.Subdistrict && this.props.actionReducer.Subdistrict.length ?
                                                                                                this.props.actionReducer.Subdistrict.map((sub, key) => {
                                                                                                    return <option key={key} value={sub.id}>{sub.name}</option>
                                                                                                })
                                                                                                : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Project', { param: 'react' })}</label>
                                                                                    </div>

                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            value={this.state.formData.projects_covered ? this.state.formData.projects_covered : ''}
                                                                                            onChange={this.handleInput}
                                                                                            className="form-control"
                                                                                            name="projects_covered">
                                                                                            <option value=""> {this.props.t('selfEmployment.Select Project', { param: 'react' })}</option>
                                                                                            {projects_covered && projects_covered.length ?
                                                                                                projects_covered.map((val, key) => {
                                                                                                    return (
                                                                                                        <option key={key} value={val}>{val}</option>
                                                                                                    )
                                                                                                })
                                                                                                : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Block Name', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select className="form-control"
                                                                                            value={this.state.formData.city ? this.state.formData.city : ""}
                                                                                            onChange={this.handleInput}
                                                                                            name="city">
                                                                                            <option value=""> {this.props.t('selfEmployment.Select Block Name', { param: 'react' })}</option>
                                                                                            {this.props.actionReducer.Cities && this.props.actionReducer.Cities.length ?
                                                                                                this.props.actionReducer.Cities.map((sub, key) => {
                                                                                                    return <option key={key} value={sub.id}>{sub.name}</option>
                                                                                                })
                                                                                                : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Margin Money (In %)', { param: 'react' })}</label>
                                                                                    </div>

                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            placeholder={this.props.t('selfEmployment.Margin Money (In %)', { param: 'react' })}
                                                                                            value={this.state.formData.margin ? this.state.formData.margin : ''}
                                                                                            onChange={this.handleInput}
                                                                                            className="form-control"
                                                                                            name="margin"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Name of Scheme', { param: 'react' })}</label>
                                                                                    </div>

                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            value={this.state.formData.scheme_name ? this.state.formData.scheme_name : ''}
                                                                                            onChange={this.handleInput}
                                                                                            className="form-control"
                                                                                            name="scheme_name">
                                                                                            <option value="">{this.props.t('selfEmployment.Select Name of Scheme', { param: 'react' })}</option>
                                                                                            {scheme_name && scheme_name.length ?
                                                                                                scheme_name.map((val, key) => {
                                                                                                    return (
                                                                                                        <option key={key} value={val}>{val}</option>
                                                                                                    )
                                                                                                })
                                                                                                : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Education Level', { param: 'react' })} </label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            value={this.state.formData.education_level ? this.state.formData.education_level : ''}
                                                                                            onChange={this.handleInput}
                                                                                            className="form-control"
                                                                                            name="education_level">
                                                                                            <option value="">{this.props.t('forms.selectEdu', { param: 'react' })}
                                                                                            </option>
                                                                                            {Mainprops.actionReducer.educationLevel.length > 0 &&
                                                                                                Mainprops.actionReducer.educationLevel.map(function (data, index) {
                                                                                                    return (

                                                                                                        <option
                                                                                                            value={data.education_id}
                                                                                                            key={index}>{data.education_name}</option>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Qualification', { param: 'react' })} </label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            onChange={this.handleInput}
                                                                                            value={this.state.formData.qualification ? this.state.formData.qualification : ''}
                                                                                            className="form-control"
                                                                                            name="qualification">
                                                                                            <option value="">{this.props.t('banner.select_qualification', { param: 'react' })}

                                                                                            </option>
                                                                                            {data && data.length &&
                                                                                                data.map(function (d, index) {
                                                                                                    return (
                                                                                                        <option key={index}
                                                                                                            value={d.id}>{d.label}</option>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Experience (In Years)', { param: 'react' })}</label>
                                                                                    </div>

                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            value={this.state.formData.experience ?
                                                                                                this.state.formData.experience : ''}
                                                                                            onChange={this.handleInput}
                                                                                            className="form-control"
                                                                                            name="experience">
                                                                                            <option value="">{this.props.t('selfEmployment.Select Experience', { param: 'react' })}</option>
                                                                                            {experience && experience.length ?
                                                                                                experience.map((val, key) => {
                                                                                                    return (
                                                                                                        <option key={key} value={val}>{val}</option>
                                                                                                    )
                                                                                                })
                                                                                                : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Subsidy Component (In %)', { param: 'react' })}</label>
                                                                                    </div>

                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <input type="number"
                                                                                            onChange={this.handleInput}
                                                                                            className="form-control"
                                                                                            placeholder={this.props.t('selfEmployment.Select Subsidy', { param: 'react' })}
                                                                                            name="subsidy"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Whether you want collateral free loan', { param: 'react' })}</label>
                                                                                    </div>

                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <div
                                                                                            className="form-check-inline">
                                                                                            <label
                                                                                                className="form-check-label text-center mr-2"
                                                                                                htmlFor="radio1">
                                                                                                <input type="radio"
                                                                                                    onChange={this.handleInput}
                                                                                                    className="form-check-input"
                                                                                                    name="collateral_free"
                                                                                                    value={'1'}
                                                                                                    checked={this.state.formData.collateral_free === '1'} />{this.props.t('profileDetails.yes', { param: 'react' })}
                                                                                            </label>
                                                                                            <label
                                                                                                className="form-check-label text-center"
                                                                                                htmlFor="radio1">
                                                                                                <input type="radio"
                                                                                                    onChange={this.handleInput}
                                                                                                    className="form-check-input"
                                                                                                    name="collateral_free"
                                                                                                    value={'2'}
                                                                                                    checked={this.state.formData.collateral_free === '2'} />{this.props.t('profileDetails.no', { param: 'react' })}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Can you offer collateral', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <div
                                                                                            className="form-check-inline">
                                                                                            <label
                                                                                                className="form-check-label text-center mr-2"
                                                                                                htmlFor="radio1">
                                                                                                <input type="radio"
                                                                                                    onChange={this.handleInput}
                                                                                                    className="form-check-input"
                                                                                                    name="collateral_required"
                                                                                                    value={'1'}
                                                                                                    checked={this.state.formData.collateral_required === '1'} />{this.props.t('profileDetails.yes', { param: 'react' })}
                                                                                            </label>
                                                                                            <label
                                                                                                className="form-check-label text-center"
                                                                                                htmlFor="radio1">
                                                                                                <input type="radio"
                                                                                                    onChange={this.handleInput}
                                                                                                    className="form-check-input"
                                                                                                    name="collateral_required"
                                                                                                    value={'2'}
                                                                                                    checked={this.state.formData.collateral_required === '2'} />{this.props.t('profileDetails.no', { param: 'react' })}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Have you defaulted against a loan in past', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <div
                                                                                            className="form-check-inline">
                                                                                            <label
                                                                                                className="form-check-label text-center mr-2"
                                                                                                htmlFor="radio1">
                                                                                                <input type="radio"
                                                                                                    onChange={this.handleInput}
                                                                                                    className="form-check-input"
                                                                                                    name="defaulter"
                                                                                                    value={'1'}
                                                                                                    checked={this.state.formData.defaulter === '1'} />{this.props.t('profileDetails.yes', { param: 'react' })}
                                                                                            </label>
                                                                                            <label
                                                                                                className="form-check-label text-center"
                                                                                                htmlFor="radio1">
                                                                                                <input type="radio"
                                                                                                    onChange={this.handleInput}
                                                                                                    className="form-check-input"
                                                                                                    name="defaulter"
                                                                                                    value={'2'}
                                                                                                    checked={this.state.formData.defaulter === '2'} />{this.props.t('profileDetails.no', { param: 'react' })}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Type of Loan', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            value={this.state.formData.loan_type ?
                                                                                                this.state.formData.loan_type : ''}
                                                                                            onChange={this.handleInput}
                                                                                            className="form-control"
                                                                                            name="loan_type">
                                                                                            <option value="">{this.props.t('selfEmployment.Select Loan Type', { param: 'react' })}</option>
                                                                                            {loan_type && loan_type.length ?
                                                                                                loan_type.map((val, key) => {
                                                                                                    return (
                                                                                                        <option key={key} value={val}>{val}</option>
                                                                                                    )
                                                                                                })
                                                                                                : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Purpose of Loan', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            value={this.state.formData.loan_purpose ?
                                                                                                this.state.formData.loan_purpose : ''}
                                                                                            onChange={this.handleInput}
                                                                                            className="form-control"
                                                                                            name="loan_purpose">
                                                                                            <option value="">{this.props.t('selfEmployment.Select Loan Purpose', { param: 'react' })}</option>
                                                                                            {loan_purpose && loan_purpose.length ?
                                                                                                loan_purpose.map((val, key) => {
                                                                                                    return (
                                                                                                        <option key={key} value={val}>{val}</option>
                                                                                                    )
                                                                                                })
                                                                                                : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Repaying Period', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            value={this.state.formData.repaying_period ?
                                                                                                this.state.formData.repaying_period : ''}
                                                                                            onChange={this.handleInput}
                                                                                            placeholder={this.props.t('selfEmployment.Enter Repaying Period', { param: 'react' })}
                                                                                            className="form-control"
                                                                                            name="repaying_period"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Annual Family Income (In INR)', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            value={this.state.formData.family_income ?
                                                                                                this.state.formData.family_income : ''}
                                                                                            onChange={this.handleInput}
                                                                                            placeholder={this.props.t('selfEmployment.Enter Annual Family Income', { param: 'react' })}
                                                                                            className="form-control"
                                                                                            name="family_income"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Training', { param: 'react' })} </label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <select
                                                                                            value={this.state.formData.training_required ?
                                                                                                this.state.formData.training_required : ''}
                                                                                            onChange={this.handleInput}
                                                                                            className="form-control"
                                                                                            name="training_required">
                                                                                            <option value="">{this.props.t('selfEmployment.Select Training', { param: 'react' })}</option>
                                                                                            {training_required && training_required.length ?
                                                                                                training_required.map((val, key) => {
                                                                                                    return (
                                                                                                        <option key={key} value={val}>{val}</option>
                                                                                                    )
                                                                                                })
                                                                                                : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-sm-4">
                                                                                        <label>{this.props.t('selfEmployment.Loan Amount (In INR)', { param: 'react' })}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-8 col-lg-8 form-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            value={this.state.formData.loan_amount ? this.state.formData.loan_amount : ''}
                                                                                            onChange={this.handleInput}
                                                                                            placeholder={this.props.t('selfEmployment.Loan Amount (In INR)', { param: 'react' })}
                                                                                            className="form-control"
                                                                                            name="loan_amount"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr></hr>
                                                                        <div className="form-group row">
                                                                            <div className="col-sm-12 ">
                                                                                <button type="submit"
                                                                                    className="mx-auto d-block btn btn-primary btn-primary2 mt-1">
                                                                                    {this.props.t('jobSearch.search', { param: 'react' })}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                }</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                                <div className="clear-fix"></div>
                                {Mainprops.JobseekerReducer.selfEmployment && Mainprops.JobseekerReducer.selfEmployment.length > 0 &&
                                    <div className="tab-content  w-100 float-left">
                                        <div id="home" className=" tab-pane active">
                                            <section className="login-form mt-0">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12  mx-auto   p-0">
                                                            <div className="panel-heading">
                                                                <h6 className="mb-0">{this.props.t('jobSearch.results',
                                                                    { param: 'react' })}</h6>
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="">
                                                                    {searchResults && searchResults.length > 0 && searchResults[activePage - 1].map((job, key) => {
                                                                        return (
                                                                            <div className="right-sidebar" key={key}>
                                                                                <div className="first-job mb-3 p-3">
                                                                                    <div className="row">
                                                                                        <div className="col-md-10">
                                                                                            <h6 className="pt-1 company-name2 pb-1 mb-0 "><Link>
                                                                                                {this.props.t('selfEmployment.name', { param: 'react' })}: {job.scheme_name}</Link></h6>
                                                                                            <div className="row w-100">
                                                                                                <div className="col-md-6 mb-1">
                                                                                                    <span className="pr-2 date-clr">{this.props.t('selfEmployment.Margin Money (In %)', { param: 'react' })}: <span>
                                                                                                        {job.margin_min + "-" + job.margin_max} </span></span>
                                                                                                </div>
                                                                                                <div className="col-md-6 mb-1 pl-0">
                                                                                                    <span className="pr-2 date-clr">{this.props.t('selfEmployment.Loan Amount (In INR)', { param: 'react' })}: <span>
                                                                                                        {job.loan_amount_min + "-" + job.loan_amount_max}</span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row w-100">
                                                                                                <div className="col-md-6 mb-1">
                                                                                                    <span className="pr-2 date-clr">{this.props.t('selfEmployment.Subsidy Component (In %)', { param: 'react' })} : <span>
                                                                                                        {job.subsidy}</span></span>
                                                                                                </div>
                                                                                                <div className="col-md-6 mb-1 pl-0">
                                                                                                    <span className="pr-2 date-clr">{this.props.t('selfEmployment.Whether collateral free loan', { param: 'react' })} : <span>
                                                                                                        {job.collateral_free && job.collateral_free === "1" ? "Yes" : "No"}</span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row w-100">

                                                                                                <div className="col-md-4 mb-1">
                                                                                                    <span className="pr-2">
                                                                                                        <a rel="noopener noreferrer" target="_blank" style={{ color: "#007bff" }} href={`${job.application_link}`}>{this.props.t('selfEmployment.Application Link', { param: 'react' })}</a>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="col-md-4 mb-1">
                                                                                                    <span className="pr-2">
                                                                                                        <a rel="noopener noreferrer" target="_blank" style={{ color: "#007bff" }} href={`${SERVER_PATH}${job.application_form}`}>{this.props.t('selfEmployment.Application Form', { param: 'react' })}</a>
                                                                                                    </span>
                                                                                                </div>
                                                                                                {job.checklist &&
                                                                                                    <div className="col-md-4 mb-1">
                                                                                                        <span className="pr-2">
                                                                                                            <a rel="noopener noreferrer" target="_blank" style={{ color: "#007bff" }} href={`${SERVER_PATH}${job.checklist}`}>{this.props.t('selfEmployment.Checklist', { param: 'react' })}</a>
                                                                                                        </span>
                                                                                                    </div>}
                                                                                            </div>
                                                                                            <div className="row w-100">
                                                                                                <div className="col-md-12 mb-1">
                                                                                                    {job.checklist_desc &&
                                                                                                        <span className="pr-2">
                                                                                                            <span className="date-clr">{this.props.t('selfEmployment.Checklist', { param: 'react' })}:<span> {job.checklist_desc}</span></span>
                                                                                                        </span>}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-2 pl-0">
                                                                                            <div className="text-right rightalign">
                                                                                                {!job.applied_on ?
                                                                                                    <button
                                                                                                        onClick={() => this.applyForJob(job)}
                                                                                                        className="btn btn-primary intersted btn-primary2 pull-right">

                                                                                                        {this.props.t('counsellingProvider.I am interested', { param: 'react' })}
                                                                                                    </button> : <h6 className="date-clr pull-right">{this.props.t('selfEmployment.interestedAlready', { param: 'react' })} <br></br>
                                                                                                        {this.props.t('selfEmployment.interestShown', { param: 'react' })} {job.applied_on}</h6>}
                                                                                                <Link data-target="#descriptionModal"
                                                                                                    onClick={() => this.setState({ job })}
                                                                                                    data-toggle="modal" style={{ "cursor": "pointer" }} className="date-clr pull-right" >
                                                                                                    {this.props.t('jobSearch.viewDetails', { param: 'react' })}
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {searchResults && searchResults.length > 1 ? <div className='react-pagination'>
                                                                        <Pagination
                                                                            pageRangeDisplayed={10}
                                                                            activePage={activePage}
                                                                            itemsCountPerPage={RESULT_PER_PAGE}
                                                                            totalItemsCount={Mainprops.JobseekerReducer.selfEmployment.length}
                                                                            onChange={this.handlePageChange}
                                                                        />
                                                                    </div> : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}

                                {Mainprops.JobseekerReducer.selfEmployment.status === '401' && <div className="tab-content w-100 float-left">
                                    <div id="home" className=" tab-pane active">
                                        <section className="login-form mt-0">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12  mx-auto   p-0">
                                                        <div className="panel-heading">
                                                            <h6 className="mb-0">{this.props.t('jobSearch.results',
                                                                { param: 'react' })}</h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            <h3>{this.props.t('JobPost.notFound', { param: 'react' })}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>}
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(translate(SearchSelfEmployment));

