import React, {Component} from 'react';
import {translate} from "react-multi-lang";
import { Link } from 'react-router'
class ContactUs extends Component {

    componentDidMount() {
        document.title = this.props.t('menu.contactUs', { param: 'react' })
    }
    render() {
        return (
            <div>
                <section className="top-inner main-content-height d-flex align-items-center animatedParent">
                    <div className="container" >
                        <div className="first-click1 mb-4 mt-4 pt-3 pb-3">
                            <div className={"row m-0 jobseeker-contact"}>

                                <div className={"col-md-6 col-sm-6 col-lg-7 "}>
                                    {/* <iframe
                                        title="PGRKAM location"
                                        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d219913.12011838023!2d76.45237632129455!3d30.545277692684262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sGround%20Floor%20%20Punjab%20Mandi%20Board%20Building%20%20Sector%2065A%2C%20SAS%20Nagar.!5e0!3m2!1sen!2sin!4v1580380730293!5m2!1sen!2sin"
                                        style={{"border":"0", "width":"100%", "height":"350"}}
                                       ></iframe> */}
                                       <iframe
                                        title="PGRKAM location"
                                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3429.2459315487126!2d76.7829616!3d30.739592700000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed0ba598cad7%3A0x806b8faa49754d8f!2sPunjab%20Skill%20Development%20Mission!5e0!3m2!1sen!2sin!4v1673338543775!5m2!1sen!2sin"
                                        style={{"border":"0", "width":"100%", "height":"350px"}}
                                       ></iframe>
                                </div>

                                <div className={"col-md-6 col-sm-6 col-lg-5 "}>
                                    <h4>How To Reach Us</h4>
                                    <hr/>
                                    
                                    <ul className="m-0">
                                      <li className={"d-flex"}><i className="fa fa-map-marker pr-2 pt-1" aria-hidden="true" ></i> <p>Punjab Skill Development Mission,</p></li>
                                      <li className={"d-flex"}><i className="fa fa-map-marker pr-2 pt-1" aria-hidden="true" ></i> <p>SCO 149-152, 2nd Floor, Sector-17C, Chandigarh,</p></li>
                                        <li className={"d-flex "}><i className="fa fa-phone pr-2 pt-1" aria-hidden="true" ></i> <p>0172-2720152</p></li>

                                        <li className={"d-flex "}><i className="fa fa-envelope-o pr-2 pt-1" aria-hidden="true" ></i> <p>secy.skill@psdm.gov.in, pgrkam.degt@gmail.com</p></li>


                                    </ul>
                                    <p>If you want to reach out to the District Bureau offices, <Link style={{color:"#007bff"}} to="http://pgrkam.com/employment">Click Here</Link> for contact details.</p>

                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default translate(ContactUs);
