import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-multi-lang'
import store from '../../store';
import { Resendotp, user_verify, set_OTP, resetUserVerification } from '../../actions/accountAction';
import { changePassword, setMessage } from "../../actions/jobseekerAction";
import Validation from "../hoc/Validation";

class Resetpassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitStatus: false,
      username: '',
      password: '',
      mobile: '',
      userVerify: false,
      formData: [],
      error: '',
      basePath: window.location.protocol + '//' + window.location.host + '/'
    }
    this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
    this.handleUserSentotp = this.handleUserSentotp.bind(this);
  }

  componentDidMount() {
    document.title = this.props.t('login.forgot_pass', { param: 'react' })
  }


  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.JobseekerReducer.msg) {
      this.props.router.push('/signin');
    }

  }

  componentWillUnmount() {
    store.dispatch(set_OTP(false))
    store.dispatch(resetUserVerification())
    store.dispatch(setMessage(''))
  }


  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    this.setState({ submitStatus: false });
  }

  handleOtpSubmit(event) {
    event.preventDefault();
    const user = {
      otp: this.state.opt,
      username: this.state.mobile
    };
    if (!this.state.submitStatus && this.props.validator.fieldValid('OTP')) {
      store.dispatch(user_verify(user));
      this.setState({ submitStatus: true });
      this.setState({ userVerify: true });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      this.props.validator.showMessageFor('OTP')
      this.forceUpdate();
    }
  }

  handleUserSentotp(event) {
    event.preventDefault();
    const user = {
      mobile: this.state.mobile
    };

    if (this.props.validator.fieldValid('mobile') && !this.state.submitStatus) {
      store.dispatch(Resendotp(user));
      this.setState({ submitStatus: true });
    } else {
      this.props.validator.showMessageFor('mobile')
      this.forceUpdate();
    }
  }

  resendOtp() {
    const user = {
      mobile: this.state.mobile
    };
    store.dispatch(Resendotp(user));
  }

  handleInputs = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let formData = this.state.formData;
    formData[name] = value;
    await this.setState({ formData: formData });
  }

  submit = async (e) => {
    e.preventDefault();
    if (this.props.validator.allValid() && this.props.notificationReducer.verfiedObject && this.props.notificationReducer.verfiedObject.auth_key) {
      if (this.state.formData.password === this.state.formData.confirm_password) {
        if (this.state.formData.password !== this.state.formData.current_password) {
          await store.dispatch(changePassword(this.props.notificationReducer.verfiedObject.auth_key, this.state.formData.password, this.state.formData.current_password))
          await this.props.validator.hideMessages()
          this.setState({ formData: [] })
        }
      } else {
        this.setState({ error: 'Password does not match' });
        setTimeout(() => this.setState({ error: '' }), 2000)
      }
    } else {
      this.props.validator.showMessages();
      this.forceUpdate();
    }

  }

  change = (id, e) => {
    let inputType = document.getElementById(id).type
    if (inputType === 'password') {
      document.getElementById(id).type = 'text'
      e.target.className = e.target.className.substring(0, e.target.className.lastIndexOf("-"));
    } else {
      document.getElementById(id).type = 'password'
      e.target.className += '-slash'
    }

  }

  changeRegister = (event) => {

    window.location.href = this.state.basePath + 'signup?type=' + event;
  }
  render() {
    if (localStorage.getItem('Islogin') && localStorage.getItem('Islogin') !== 'false') {
      window.location.href = this.state.basePath + 'myaccount'
    }

    const { validator } = this.props
    const { password, confirm_password } = this.state.formData

    let Mainprops = this.props;
    let Mainstate = this.state;

    return (
      <section className="login-form main-content-height item-center">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12 cover-shadow justify-content-center align-self-center   border-0">
              <div className=" row ">
                <div className="col-sm-6 first-click-inst text-left">
                  <h5>{this.props.t('login.forgotpass_head', { param: 'react' })}</h5>
                  <ul className="jobseeker-benefits">
                    <li>{this.props.t('login.forgotpass_inst1', { param: 'react' })}</li>
                    <li>{this.props.t('login.forgotpass_inst2', { param: 'react' })}</li>
                    <li>{this.props.t('login.forgotpass_inst3', { param: 'react' })}</li>
                  </ul>
                </div>
                {!Mainprops.notificationReducer.openotp &&

                  <form className="col-md-6 col-md-6 pt-5 pl-4 pr-4" onSubmit={this.handleUserSentotp}>
                    <div className="form-group">

                      <div className="col-sm-12 p-0 text-left">
                        <label>{this.props.t('login.forgotpass_inst1', { param: 'react' })}</label>
                        <input onChange={(event) => this.handleUserInput(event)}
                          onBlur={() => this.props.validator.showMessageFor('mobile')}
                          className="form-control" placeholder={this.props.t('forms.enterMobile', { param: 'react' })}
                          name="mobile" id="mobile" />
                        {validator.message('mobile', this.state.mobile, 'required|phone')}
                      </div>
                      <button type="submit" className="btn btn-primary btn-primary2 mt-3">{this.props.t('profileDetails.submit', { param: 'react' })}</button>

                    </div>
                  </form>



                }
                {Mainprops.notificationReducer.user_verify &&
                  <form className="col-md-6 col-md-6 pt-5 pl-4 pr-4 pb-2" onSubmit={this.submit}>
                    <h4>{this.props.t('changePassword.title', { param: 'react' })}</h4>
                    <hr />



                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-12">
                          <label>{this.props.t('changePassword.new', { param: 'react' })}</label>
                          <input type="password" className="form-control form-eye"
                            value={password ? password : ''}
                            onChange={this.handleInputs}
                            id="password_new" name="password"
                            placeholder={this.props.t('placeholder.enter', {param: 'react'}) +' '+this.props.t('changePassword.new', {param: 'react'})} />
                          <span
                            onClick={(e) => this.change('password_new', e)}
                            className="input fa fa-fw field-icon toggle-password fa-eye-slash"
                          ></span>
                          {validator.message('password', password, 'required')}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-12">
                          <label>{this.props.t('changePassword.confirm', { param: 'react' })}</label>
                          <input type="password"
                            onChange={this.handleInputs}
                            value={confirm_password ? confirm_password : ''}
                            className="form-control form-eye"
                            id="confirm_password"
                            name="confirm_password"
                            placeholder={this.props.t('changePassword.confirm', {param: 'react'})} />
                          <span
                            onClick={(e) =>
                              this.change('confirm_password', e)}
                            toggle="#confirm_password"
                            className="input fa fa-fw field-icon toggle-password fa-eye-slash"
                          ></span>
                          {validator.message('confirm password', confirm_password, 'required')}
                        </div>
                      </div>
                    </div>



                    {this.state.error &&
                      <div className="form-group" style={{ 'textAlign': 'center' }}>
                        <span style={{ 'color': 'red' }}>{this.state.error}</span>
                      </div>}
                    <button type="submit" className="btn btn-primary btn-primary2 mt-3">{this.props.t('profileDetails.submit', { param: 'react' })}</button>


                  </form>
                }
                {Mainprops.notificationReducer.openotp && !Mainprops.notificationReducer.user_verify &&
                  <div className="col-md-6">
                    <div className="panel-body">
                      <div className="row">
                        <form className="col-md-12" onSubmit={this.handleOtpSubmit}>
                          <div className="mt-4">
                            {this.props.t('login.verify_no', { param: 'react' })}
                            <hr />
                            <span className="pb-2 d-block">{this.props.t('login.code', { param: 'react' })}{this.props.t('login.substring', { param: 'react' })}{Mainstate.mobile.substring(6)}</span>
                          </div>
                          <div className="form-group">
                            <div className="row">

                              <div className="col-sm-12">
                                <input type="text" onChange={(event) => this.handleUserInput(event)}
                                  className="form-control" id="opt" name="opt" />
                                {validator.message('OTP', this.state.opt, 'required')}
                                <button type="submit" className="btn btn-primary btn-primary2 mr-2 mt-3">{this.props.t('profileDetails.submit', { param: 'react' })}</button>
                                <span onClick={() => this.resendOtp()} className="mt-4 float-left mr-3 date-clr" style={{ cursor: 'pointer' }}>{this.props.t('login.resend_otp', { param: 'react' })}</span>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = store => {
  return store;
};

export default connect(mapStateToProps)(translate(Validation(Resetpassword)));
